import React from 'react'
import { Mutation, Query } from 'react-apollo'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ErrorMessage,
  FormControlLabel,
  FormLabel,
  LoadingMessage,
  MenuItem,
  TextField,
  Radio,
  RadioGroup,
} from '../../components'

import { TRANSFER_STUDENT, GET_CLASSROOM, GET_CLASSROOMS } from './queries'

const TransferStudentDialog = props => {
  const {
    classroom,
    closeDialog,
    enrollmentId,
    handleChange,
    handleTransferStudentSubmission,
    isSameCourse,
    open,
    transferToClassId,
  } = props
  const [courseSubjectCode] = classroom.course.code
  return (
    <Mutation
      mutation={TRANSFER_STUDENT}
      refetchQueries={[
        {
          query: GET_CLASSROOM,
          variables: { id: classroom.id },
        },
      ]}
    >
      {transferStudent => (
        <Dialog open={open} maxWidth={'sm'} onClose={closeDialog}>
          <DialogTitle>Transfer Student</DialogTitle>
          <DialogContent style={{ width: '400px' }}>
            <TextField
              name="enrollmentId"
              label="Student"
              fullWidth
              select
              onChange={handleChange}
              value={enrollmentId}
              style={{ marginBottom: '10px' }}
            >
              {classroom.enrollments.map(enrollment => (
                <MenuItem key={enrollment.id} value={enrollment.id}>
                  {`${enrollment.student.firstName} ${enrollment.student.lastName}`}
                </MenuItem>
              ))}
            </TextField>
            <br />
            <FormLabel component="legend">Permanent Transfer To:</FormLabel>
            <RadioGroup
              name="isSameCourse"
              value={isSameCourse}
              onChange={handleChange}
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <FormControlLabel
                value="same"
                control={<Radio color="primary" />}
                label="Same Course"
              />
              <FormControlLabel
                value="different"
                control={<Radio color="primary" />}
                label="Different Course"
              />
            </RadioGroup>
            {isSameCourse && (
              <Query
                query={GET_CLASSROOMS}
                variables={{
                  filter: {
                    quarter: classroom.quarter,
                    year: classroom.year,
                    course:
                      isSameCourse === 'same'
                        ? { code: classroom.course.code }
                        : {
                            subject: { category: { code: courseSubjectCode } },
                          },
                  },
                }}
              >
                {({ loading, error, data }) => {
                  if (loading) return <LoadingMessage />
                  if (error) return <ErrorMessage error={error} />
                  const classrooms = data.classrooms.filter(classroom => {
                    return classroom.id !== props.classroom.id
                  })
                  return (
                    <TextField
                      name="transferToClassId"
                      label="Transfer to Class"
                      fullWidth
                      select
                      onChange={handleChange}
                      value={transferToClassId}
                    >
                      {classrooms.map(classroom => (
                        <MenuItem key={classroom.id} value={classroom.id}>
                          {classroom.title}
                        </MenuItem>
                      ))}
                    </TextField>
                  )
                }}
              </Query>
            )}
          </DialogContent>
          <DialogActions
            style={{ paddingBottom: '10px', paddingRight: '10px' }}
          >
            <Button
              onClick={async () => {
                handleTransferStudentSubmission(true, true)
                const {
                  data: {
                    transferEnrollment: { message },
                  },
                } = await transferStudent({
                  variables: {
                    enrollmentId,
                    toClassroomId: transferToClassId,
                  },
                })
                handleTransferStudentSubmission(true, false, message)
              }}
              color="primary"
            >
              Transfer
            </Button>
            <Button
              onClick={() => handleTransferStudentSubmission(false)}
              color="secondary"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Mutation>
  )
}

export default TransferStudentDialog
