import React, { Component } from 'react'
import { Mutation, Query } from 'react-apollo'
import Composer from 'react-composer'
import { CountryRegionData } from 'react-country-region-selector'
import { Link, Redirect } from 'react-router-dom'
import update from 'immutability-helper'

import TaskHistory from './TaskHistory'
import EnrollmentHistory from './EnrollmentHistory'
import TrialHistory from './TrialHistory'

import {
  Button,
  Divider,
  ErrorMessage,
  AccordionForm,
  Flex,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormHelperText,
  GradeSelector,
  Input,
  InputLabel,
  DatePicker,
  LoadingMessage,
  MenuItem,
  OpportunityForm,
  Radio,
  RadioGroup,
  SnackbarNotification,
  Tab,
  Tabs,
  Text,
  TextField,
} from '../../components'

import { expansionBarColor } from '../../config'

import { PhoneNumberInput, getRegions, numberInputValidate } from '../../utils'
import { formatBirthday } from '../../utils/datetime'

import {
  GET_OPPORTUNITY,
  GET_OPPORTUNITIES,
  CREATE_LOCATION,
  UPDATE_STUDENT,
  UPDATE_FAMILY_MEMBER,
  UPDATE_FAMILY_ACCOUNT,
  UPDATE_LOCATION,
  UPDATE_OPPORTUNITY,
  GET_FAMILY_ACCOUNT,
  GET_FAMILY_ACCOUNTS,
  ARCHIVE_OPPORTUNITY,
} from '../../queries'

class OpportunityDetailView extends Component {
  render() {
    const { match } = this.props
    return (
      <Query
        fetchPolicy={'cache-and-network'}
        query={GET_OPPORTUNITY}
        variables={{ id: match.params[0] }}
      >
        {({ loading, error, data }) => {
          if (loading) return <LoadingMessage />
          if (error) return <ErrorMessage error={error} />

          return (
            <InnerOpportunityDetailView
              {...this.props}
              opportunity={data.opportunity}
            />
          )
        }}
      </Query>
    )
  }
}

class InnerOpportunityDetailView extends Component {
  constructor(props) {
    super(props)
    let opportunity = props.opportunity
    let primaryFamilyMemberChanged = false
    if (!opportunity.student.familyAccount.primaryFamilyMember) {
      if (opportunity.student.familyAccount.familyMembers.length > 0)
        primaryFamilyMemberChanged = true

      opportunity = {
        ...opportunity,
        student: {
          ...opportunity.student,
          familyAccount: {
            ...opportunity.student.familyAccount,
            primaryFamilyMember:
              opportunity.student.familyAccount.familyMembers.length > 0
                ? opportunity.student.familyAccount.familyMembers[0]
                : {},
          },
        },
      }
    }
    let needsLocationCreation = false
    if (!opportunity.student.familyAccount.location) {
      needsLocationCreation = true
      opportunity = {
        ...opportunity,
        student: {
          ...opportunity.student,
          familyAccount: {
            ...opportunity.student.familyAccount,
            location: {
              country: 'United States',
              state: 'California',
            },
          },
        },
      }
    }
    const birthdate = new Date(opportunity.student.birthdate)
    const ageDifMs = Date.now() - birthdate.getTime()
    const ageDate = new Date(ageDifMs)
    this.state = {
      studentTabValue: 0,
      studentChanged: false,
      familyMemberChanged: false,
      familyAccountChanged: false,
      primaryFamilyMemberChanged,
      opportunityChanged: false,
      needsLocationCreation,
      originalStage: opportunity.stage,
      opportunity: {
        ...opportunity,
        responsibleEmployeeId:
          opportunity.responsibleEmployee && opportunity.responsibleEmployee.id,
        student: {
          ...opportunity.student,
          birthdate:
            opportunity.student.birthdate &&
            formatBirthday(opportunity.student.birthdate),
          over13: Math.abs(ageDate.getUTCFullYear() - 1970) >= 13,
        },
      },
      snackbar: {
        open: false,
        message: '',
        messageType: '',
        willRedirect: false,
      },
      actualCountry: CountryRegionData.find(
        c => c[0] === opportunity.student.familyAccount.location.country
      ),
    }
  }

  handleTabChange = (event, value) => {
    this.setState({
      studentTabValue: value,
    })
  }

  handleStudentChange = event => {
    const { name, value } = event.target
    const birthdate = new Date(value)
    const ageDifMs = Date.now() - birthdate.getTime()
    const ageDate = new Date(ageDifMs) // miliseconds from epoch
    this.setState(state => ({
      studentChanged: true,
      opportunity: {
        ...state.opportunity,
        student: {
          ...state.opportunity.student,
          [name]: name === 'phoneNumber' ? value.replace(/\D/g, '') : value,
          over13:
            name === 'birthdate'
              ? Math.abs(ageDate.getUTCFullYear() - 1970) >= 13
              : state.opportunity.student.over13,
        },
      },
    }))
  }

  handleFamilyMemberChange = index => event => {
    const { name, value } = event.target
    const {
      familyMembers,
      primaryFamilyMember,
    } = this.state.opportunity.student.familyAccount
    if (familyMembers[index].id === primaryFamilyMember.id) {
      this.setState(state => ({
        familyMemberChanged: true,
        opportunity: {
          ...state.opportunity,
          student: {
            ...state.opportunity.student,
            familyAccount: {
              ...state.opportunity.student.familyAccount,
              familyMembers: update(
                state.opportunity.student.familyAccount.familyMembers,
                {
                  [index]: {
                    [name]: {
                      $set:
                        name === 'phoneNumber'
                          ? value.replace(/\D/g, '')
                          : value,
                    },
                  },
                }
              ),
              primaryFamilyMember:
                familyMembers[index].id === primaryFamilyMember.id
                  ? {
                      ...state.opportunity.student.familyAccount
                        .primaryFamilyMember,
                      [name]:
                        name === 'phoneNumber'
                          ? value.replace(/\D/g, '')
                          : value,
                    }
                  : state.opportunity.student.familyAccount.primaryFamilyMember,
            },
          },
        },
      }))
    }
  }

  handleFamilyAccountChange = event => {
    const { name, value } = event.target
    this.setState(state => ({
      familyAccountChanged: true,
      opportunity: {
        ...state.opportunity,
        student: {
          ...state.opportunity.student,
          familyAccount: {
            ...state.opportunity.student.familyAccount,
            [name]: value,
          },
        },
      },
    }))
  }

  handleLocationChange = event => {
    const { name, value } = event.target
    let actualCountry = this.state.actualCountry
    if (name === 'country') {
      actualCountry = CountryRegionData.find(c => c[0] === value)
    }
    this.setState(state => ({
      actualCountry,
      familyAccountChanged: true,
      opportunity: {
        ...state.opportunity,
        student: {
          ...state.opportunity.student,
          familyAccount: {
            ...state.opportunity.student.familyAccount,
            location: {
              ...state.opportunity.student.familyAccount.location,
              [name]: value,
            },
          },
        },
      },
    }))
  }

  handleOpportunityChange = event => {
    const { name, value } = event.target
    const enrolled = value === 'ENROLLED'
    this.setState(state => ({
      opportunityChanged: true,
      opportunity: {
        ...state.opportunity,
        [name]: value,
        student:
          name === 'status' && (value === 'ENROLLED' || value === 'TRIAL')
            ? {
                ...state.opportunity.student,
                enrollmentStatus: enrolled ? 'ACTIVE' : 'TRIAL',
                familyAccount: {
                  ...state.opportunity.student.familyAccount,
                  status: enrolled
                    ? 'ACTIVE'
                    : state.opportunity.student.familyAccount.status,
                },
              }
            : state.opportunity.student,
      },
    }))
  }

  setAsPrimary = familyMember => {
    this.setState(state => ({
      primaryFamilyMemberChanged: true,
      opportunity: {
        ...state.opportunity,
        student: {
          ...state.opportunity.student,
          familyAccount: {
            ...state.opportunity.student.familyAccount,
            primaryFamilyMember: familyMember,
          },
        },
      },
    }))
  }

  returnToOpportunityTable = () => {
    this.setState({
      redirect: true,
      redirectTo: '/opportunity',
    })
  }

  handleTaskHistoryClick = taskId => {
    this.setState({
      redirect: true,
      redirectTo: `/task/${taskId}`,
    })
  }

  handleEnrollmentClick = classroomId => {
    this.setState({
      redirect: true,
      redirectTo: `/classroom/${classroomId}`,
    })
  }

  handleTrialClick = trialId => {
    this.setState({
      redirect: true,
      redirectTo: `/trial/${trialId}`,
    })
  }

  handleFamilyMemberSubmission = async (UFMmutation, UFAmutation) => {
    const { opportunity, primaryFamilyMemberChanged } = this.state
    const { familyAccount } = opportunity.student
    familyAccount.familyMembers.map(async familyMember => {
      await UFMmutation({
        variables: {
          id: familyMember.id,
          input: {
            firstName: familyMember.firstName,
            lastName: familyMember.lastName,
            relation: familyMember.relation,
            phoneNumber: familyMember.phoneNumber,
            preferredLanguage: familyMember.preferredLanguage,
            email: familyMember.email,
            occupation: familyMember.occupation,
            educationLevel: familyMember.educationLevel,
          },
        },
        refetchQueries: [
          {
            query: GET_FAMILY_ACCOUNT,
            variables: {
              id: familyAccount.id,
            },
          },
        ],
      })
    })
    if (primaryFamilyMemberChanged) {
      await UFAmutation({
        variables: {
          id: familyAccount.id,
          input: {
            familyMemberId: familyAccount.primaryFamilyMember.id,
          },
          refetchQueries: [
            {
              query: GET_FAMILY_ACCOUNT,
              variables: {
                id: familyAccount.id,
              },
            },
            {
              query: GET_FAMILY_ACCOUNTS,
            },
          ],
        },
      })
    }
    this.setState({
      primaryFamilyMemberChanged: false,
      familyMemberChanged: false,
    })
  }

  handleFamilyAccountSubmission = async (UFAmutation, locationMutation) => {
    const { needsLocationCreation, opportunity } = this.state
    const { familyAccount } = opportunity.student
    const { location } = familyAccount
    const { city, country, state, street, zip } = location
    const locationReturn = await locationMutation({
      variables: {
        id: location.id,
        input: {
          street: street,
          city: city,
          country: country,
          state: state,
          zip: zip,
        },
      },
      refetchQueries: [
        {
          query: GET_FAMILY_ACCOUNT,
          variables: {
            id: familyAccount.id,
          },
        },
      ],
    })
    if (needsLocationCreation) {
      await UFAmutation({
        variables: {
          id: familyAccount.id,
          input: {
            status: familyAccount.status,
            locationId: locationReturn.data.createLocation.id,
          },
        },
        refetchQueries: [
          {
            query: GET_FAMILY_ACCOUNT,
            variables: {
              id: familyAccount.id,
            },
          },
        ],
      })
    } else {
      await UFAmutation({
        variables: {
          id: familyAccount.id,
          input: {
            status: familyAccount.status,
          },
        },
        refetchQueries: [
          {
            query: GET_FAMILY_ACCOUNT,
            variables: {
              id: familyAccount.id,
            },
          },
        ],
      })
    }
    this.setState({
      familyAccountChanged: false,
      needsLocationCreation: false,
    })
  }

  styles = theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: '5px',
      marginRight: '5px',
      padding: '5px',
      width: '20%',
    },
    menu: {
      width: '20%',
    },
    expSummary: {
      backgroundColor: expansionBarColor,
    },
  })

  render() {
    const { languages, stages, statuses } = this.props
    const {
      actualCountry,
      familyAccountChanged,
      familyMemberChanged,
      needsLocationCreation,
      opportunity,
      opportunityChanged,
      over13,
      primaryFamilyMemberChanged,
      redirect,
      redirectTo,
      snackbar,
      studentChanged,
      studentTabValue,
    } = this.state
    const {
      activities,
      closeDate,
      lastContacted,
      notes,
      primaryInterest,
      responsibleEmployeeId,
      session,
      source,
      stage,
      student,
      trials,
      year,
    } = opportunity
    const {
      birthdate,
      enrollmentStatus,
      familyAccount,
      gender,
      grade,
      nickname,
      school,
      tShirtSize,
      enrollments,
    } = student
    const validAssessments = new Set([
      'Math',
      'Science',
      'Physics',
      'Biology/Chemistry',
      'Writing',
      'Speech and Debate',
    ])
    if (redirect) {
      return <Redirect push to={redirectTo} />
    }
    return (
      <Flex
        column
        grow={1}
        style={{
          textAlign: 'left',
          margin: 'auto',
          width: '67%',
          minWidth: '600px',
          maxWidth: '1000px',
        }}
      >
        <br />
        <Link to="/opportunity">{'< back to Opportunity List'}</Link>
        <br />
        <Text variant="h5">{`${student.firstName} ${student.lastName}`}</Text>
        <br />
        <Flex>
          <Mutation mutation={ARCHIVE_OPPORTUNITY}>
            {(archiveOpportunity, { data }) => (
              <Button
                color="secondary"
                aria-label="Archive"
                onClick={() => {
                  if (
                    window.confirm(
                      'Are you sure want to archive this opportunity?'
                    )
                  ) {
                    archiveOpportunity({
                      variables: {
                        id: opportunity.id,
                      },
                      refetchQueries: [{ query: GET_OPPORTUNITIES }],
                    })
                    this.setState({
                      snackbar: {
                        open: true,
                        message: 'Opportunity archived',
                        messageType: 'success',
                        willRedirect: true,
                      },
                    })
                  }
                }}
              >
                Archive Opportunity
              </Button>
            )}
          </Mutation>
          {validAssessments.has(opportunity.primaryInterest) && (
            <Button
              component={Link}
              style={{ marginLeft: '10px' }}
              to={`/assessment/create-assessment/${opportunity.id}`}
            >
              Schedule Assessment
            </Button>
          )}
          <Button
            component={Link}
            style={{ marginLeft: '10px' }}
            to={`/task/add-new-task-from-opportunity/${opportunity.id}/recommendation`}
          >
            Schedule Consultation
          </Button>
          <Button
            component={Link}
            style={{ marginLeft: '10px' }}
            to={`/trial/create-trial/${opportunity.id}`}
          >
            Schedule Trial
          </Button>
        </Flex>
        <AccordionForm
          expanded
          title="Opportunity Detail"
          form={
            <Flex
              column
              style={{
                align: 'center',
                flexGrow: 1,
              }}
            >
              <Composer
                components={[
                  <Mutation mutation={UPDATE_OPPORTUNITY}>
                    {function() {
                      return ''
                    }}
                  </Mutation>,
                  <Mutation mutation={UPDATE_STUDENT}>
                    {function() {
                      return ''
                    }}
                  </Mutation>,
                  <Mutation mutation={UPDATE_FAMILY_ACCOUNT}>
                    {function() {
                      return ''
                    }}
                  </Mutation>,
                ]}
              >
                {([updateOpportunity, updateStudent, updateFamilyAccount]) => (
                  <OpportunityForm
                    showStatus
                    showCreatedBy
                    stages={stages}
                    statuses={statuses}
                    opportunity={opportunity}
                    handleChange={this.handleOpportunityChange}
                    opportunityChanged={opportunityChanged}
                    showSave={true}
                    handleSave={() => {
                      updateOpportunity({
                        variables: {
                          id: opportunity.id,
                          input: {
                            status: opportunity.status,
                            primaryInterest: primaryInterest,
                            session: session,
                            stage: stage,
                            lastContacted: lastContacted,
                            year: parseInt(year, 10),
                            source: source,
                            notes: notes,
                            responsibleEmployeeId: responsibleEmployeeId,
                            closeDate: closeDate
                              ? closeDate
                              : opportunity.status === 'ENROLLED' ||
                                opportunity.status === 'LOST'
                              ? Date.now()
                              : null,
                          },
                        },
                        refetchQueries: [
                          {
                            query: GET_OPPORTUNITY,
                            variables: {
                              id: opportunity.id,
                            },
                          },
                        ],
                      })
                      if (opportunity.status === 'ENROLLED') {
                        updateStudent({
                          variables: {
                            id: student.id,
                            input: {
                              enrollmentStatus: student.enrollmentStatus,
                            },
                          },
                        })
                        updateFamilyAccount({
                          variables: {
                            id: familyAccount.id,
                            input: {
                              status: familyAccount.status,
                            },
                          },
                        })
                      }
                      this.setState({
                        opportunityChanged: false,
                      })
                      this.returnToOpportunityTable()
                    }}
                  />
                )}
              </Composer>
            </Flex>
          }
        />

        <AccordionForm
          expanded
          title="Student Detail"
          form={
            <Flex
              column
              style={{
                align: 'center',
                flexGrow: 1,
              }}
            >
              <Tabs
                value={studentTabValue}
                onChange={this.handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                centered
                style={{
                  paddingBottom: '10px',
                }}
              >
                <Tab label="Student" />
                <Tab label="Guardians" />
                <Tab label="Family" />
              </Tabs>

              {studentTabValue === 0 && ( //Student tab selected
                <Composer
                  components={[
                    <Mutation mutation={UPDATE_STUDENT}>
                      {function() {
                        return ''
                      }}
                    </Mutation>,
                    <Mutation mutation={UPDATE_OPPORTUNITY}>
                      {function() {
                        return ''
                      }}
                    </Mutation>,
                  ]}
                >
                  {([updateStudent, updateOpportunity]) => (
                    <Flex
                      column
                      style={{
                        align: 'center',
                        flexGrow: 1,
                      }}
                    >
                      <form
                        id="editStudentForm"
                        className={this.styles.container}
                        onSubmit={e => {
                          e.preventDefault()
                          const input = {
                            enrollmentStatus: enrollmentStatus,
                            firstName: student.firstName,
                            lastName: student.lastName,
                            nickname: nickname,
                            birthdate: birthdate ? new Date(birthdate) : null,
                            gender: gender,
                            grade: grade,
                            school: school,
                            email: student.email,
                            phoneNumber: student.phoneNumber,
                            tShirtSize: tShirtSize,
                          }
                          updateStudent({
                            variables: {
                              id: student.id,
                              input,
                            },
                            refetchQueries: [
                              {
                                query: GET_OPPORTUNITY,
                                variables: {
                                  id: opportunity.id,
                                },
                              },
                            ],
                          })
                          this.setState({
                            studentChanged: false,
                          })
                        }}
                      >
                        <Flex justify="space-evenly">
                          <Flex
                            grow={2}
                            m="20px"
                            style={{ maxWidth: '300px' }}
                            column
                          >
                            <TextField
                              required
                              name="firstName"
                              label="First Name"
                              value={student.firstName}
                              className={this.styles.textField}
                              fullWidth
                              onChange={this.handleStudentChange}
                            />
                            <br />
                            <TextField
                              required
                              name="lastName"
                              label="Last Name"
                              value={student.lastName}
                              className={this.styles.textField}
                              fullWidth
                              onChange={this.handleStudentChange}
                            />
                            <br />
                            <TextField
                              name="nickname"
                              label="Nickname"
                              value={nickname || ''}
                              className={this.styles.textField}
                              fullWidth
                              onChange={this.handleStudentChange}
                            />
                            <br />
                            <DatePicker
                              name="birthdate"
                              label="Birthdate"
                              value={birthdate || null}
                              onChange={this.handleStudentChange}
                            />
                            <br />
                            <FormControl
                              component="fieldset"
                              className={this.styles.formControl}
                            >
                              <FormLabel component="legend">Gender</FormLabel>
                              <RadioGroup
                                aria-label="gender"
                                name="gender"
                                className={this.styles.group}
                                value={gender}
                                onChange={this.handleStudentChange}
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                }}
                              >
                                <FormControlLabel
                                  value="MALE"
                                  control={<Radio color="primary" />}
                                  label="Male"
                                />
                                <FormControlLabel
                                  value="FEMALE"
                                  control={<Radio color="secondary" />}
                                  label="Female"
                                />
                              </RadioGroup>
                            </FormControl>
                            <Flex>
                              <GradeSelector
                                name="grade"
                                className={this.styles.textField}
                                value={grade !== null ? grade : ''}
                                onChange={this.handleStudentChange}
                                SelectProps={{
                                  MenuProps: {
                                    className: this.styles.menu,
                                  },
                                }}
                                style={{
                                  minWidth: '75px',
                                  paddingRight: '15px',
                                }}
                              />
                              <TextField
                                name="school"
                                label="School"
                                value={school || ''}
                                className={this.styles.textField}
                                fullWidth
                                onChange={this.handleStudentChange}
                              />
                            </Flex>
                          </Flex>
                          <Flex
                            grow={2}
                            m="20px"
                            style={{ maxWidth: '300px' }}
                            column
                          >
                            <TextField
                              required
                              name="enrollmentStatus"
                              label="Enrollment Status"
                              value={enrollmentStatus || ''}
                              className={this.styles.textField}
                              select
                              onChange={this.handleStudentChange}
                              SelectProps={{
                                MenuProps: {
                                  className: this.styles.menu,
                                },
                              }}
                              InputLabelProps={{
                                shrink:
                                  !!enrollmentStatus &&
                                  enrollmentStatus !== undefined,
                              }}
                            >
                              {[
                                'PROSPECT',
                                'ACTIVE',
                                'INACTIVE',
                                'GRADUATED',
                                'CLOSED',
                              ].map(option => (
                                <MenuItem key={option} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                            </TextField>
                            <br />
                            <TextField
                              name="tShirtSize"
                              label="T-Shirt Size"
                              className={this.styles.textField}
                              select
                              value={tShirtSize || ''}
                              onChange={this.handleStudentChange}
                              SelectProps={{
                                MenuProps: {
                                  className: this.styles.menu,
                                },
                              }}
                              InputLabelProps={{
                                shrink:
                                  !!tShirtSize && tShirtSize !== undefined,
                              }}
                            >
                              <MenuItem key={'selectTSSNull'} value={null}>
                                N/A
                              </MenuItem>
                              {[
                                'Youth Small',
                                'Youth Medium',
                                'Youth Large',
                                'Adult Small',
                                'Adult Medium',
                                'Adult Large',
                              ].map(option => (
                                <MenuItem
                                  key={option}
                                  value={option
                                    .toUpperCase()
                                    .replace(/ /g, '_')}
                                >
                                  {option}
                                </MenuItem>
                              ))}
                            </TextField>
                            <br />
                            <FormControl className={this.styles.textField}>
                              <InputLabel shrink>Student Phone #</InputLabel>
                              <Input
                                disabled={!over13}
                                value={student.phoneNumber}
                                onChange={this.handleStudentChange}
                                name="phoneNumber"
                                inputComponent={PhoneNumberInput}
                              />
                              <FormHelperText>
                                Optional; only if the student is 13 or older
                              </FormHelperText>
                            </FormControl>
                            <br />
                            <TextField
                              name="email"
                              label="Student Email"
                              value={student.email}
                              className={this.styles.textField}
                              fullWidth
                              disabled={!this.state.over13}
                              onChange={this.handleStudentChange}
                              helperText="Optional; only if the student is 13 or older"
                            />
                          </Flex>
                        </Flex>
                        <Flex direction={'row-reverse'} align={'center'}>
                          <Button
                            disabled={!studentChanged}
                            aria-label="Submit"
                            type="submit"
                          >
                            Save Changes
                          </Button>
                          <Text style={{ marginRight: '10px' }}>
                            Make sure to verify all information before hitting
                            save!
                          </Text>
                        </Flex>
                      </form>
                    </Flex>
                  )}
                </Composer>
              )}

              {studentTabValue === 1 && (
                <Flex
                  column
                  style={{
                    align: 'center',
                    flexGrow: 1,
                  }}
                >
                  <Composer
                    components={[
                      <Mutation mutation={UPDATE_FAMILY_MEMBER}>
                        {function() {
                          return ''
                        }}
                      </Mutation>,
                      <Mutation mutation={UPDATE_FAMILY_ACCOUNT}>
                        {function() {
                          return ''
                        }}
                      </Mutation>,
                    ]}
                  >
                    {([UFMmutation, UFAmutation]) => (
                      <form
                        id="editFamilyMemberForm"
                        className={this.styles.container}
                        onSubmit={e => {
                          e.preventDefault()
                          if (
                            familyAccount.familyMembers.reduce(
                              (accumulator, currentValue) => {
                                return (
                                  accumulator &&
                                  (currentValue.phoneNumber.length === 0 ||
                                    currentValue.phoneNumber.length >= 10)
                                )
                              }
                            )
                          ) {
                            this.handleFamilyMemberSubmission(
                              UFMmutation,
                              UFAmutation
                            )
                          } else {
                            this.setState({
                              snackbar: {
                                open: true,
                                message:
                                  'At least one phone number has an improper length',
                                messageType: 'error',
                                willRedirect: false,
                              },
                            })
                          }
                        }}
                      >
                        {familyAccount.familyMembers.map(
                          (familyMember, index) => {
                            const isPrimary =
                              familyMember.id ===
                              familyAccount.primaryFamilyMember.id
                            return (
                              <div key={familyMember}>
                                <Flex justify="space-evenly">
                                  <Flex
                                    grow={2}
                                    m="20px"
                                    style={{ maxWidth: '300px' }}
                                    column
                                  >
                                    {isPrimary ? (
                                      <Button aria-label="MakePrimary">
                                        Primary Guardian
                                      </Button>
                                    ) : (
                                      <Button
                                        color="default"
                                        aria-label="MakePrimary"
                                        onClick={() =>
                                          this.setAsPrimary(familyMember)
                                        }
                                      >
                                        Set As Primary Guardian
                                      </Button>
                                    )}
                                    <br />
                                    <TextField
                                      required
                                      name="firstName"
                                      label="First Name"
                                      value={familyMember.firstName}
                                      className={this.styles.textField}
                                      fullWidth
                                      onChange={this.handleFamilyMemberChange(
                                        index
                                      )}
                                    />
                                    <br />
                                    <TextField
                                      required
                                      name="lastName"
                                      label="Last Name"
                                      value={familyMember.lastName}
                                      className={this.styles.textField}
                                      fullWidth
                                      onChange={this.handleFamilyMemberChange(
                                        index
                                      )}
                                    />
                                    <br />
                                    <FormControl
                                      component="fieldset"
                                      className={this.styles.formControl}
                                    >
                                      <FormLabel component="legend">
                                        Relationship
                                      </FormLabel>
                                      <RadioGroup
                                        aria-label="relationship"
                                        name="relation"
                                        className={this.styles.group}
                                        value={familyMember.relation}
                                        onChange={this.handleFamilyMemberChange(
                                          index
                                        )}
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                          flexWrap: 'nowrap',
                                        }}
                                      >
                                        <FormControlLabel
                                          value="Father"
                                          control={<Radio color="primary" />}
                                          label="Father"
                                        />
                                        <FormControlLabel
                                          value="Mother"
                                          control={<Radio color="secondary" />}
                                          label="Mother"
                                        />
                                        <FormControlLabel
                                          value="Guardian"
                                          control={<Radio color="default" />}
                                          label="Guardian"
                                        />
                                      </RadioGroup>
                                    </FormControl>
                                  </Flex>
                                  <Flex
                                    grow={2}
                                    m="20px"
                                    style={{ maxWidth: '300px' }}
                                    column
                                  >
                                    <TextField
                                      name="email"
                                      label="Email"
                                      value={familyMember.email}
                                      className={this.styles.textField}
                                      fullWidth
                                      onChange={this.handleFamilyMemberChange(
                                        index
                                      )}
                                    />
                                    <br />
                                    <FormControl
                                      className={this.styles.textField}
                                    >
                                      <InputLabel shrink>Phone #</InputLabel>
                                      <Input
                                        value={familyMember.phoneNumber}
                                        onChange={this.handleFamilyMemberChange(
                                          index
                                        )}
                                        name="phoneNumber"
                                        inputComponent={PhoneNumberInput}
                                      />
                                    </FormControl>
                                    <br />
                                    <TextField
                                      name="preferredLanguage"
                                      label="Preferred Language"
                                      value={familyMember.preferredLanguage}
                                      className={this.styles.textField}
                                      select
                                      onChange={this.handleFamilyMemberChange(
                                        index
                                      )}
                                    >
                                      {languages.map(language => (
                                        <MenuItem
                                          key={language}
                                          id={language}
                                          value={language}
                                        >
                                          {language}
                                        </MenuItem>
                                      ))}
                                    </TextField>
                                    <br />
                                    <TextField
                                      name="occupation"
                                      label="Occupation"
                                      value={familyMember.occupation || ''}
                                      className={this.styles.textField}
                                      fullWidth
                                      onChange={this.handleFamilyMemberChange(
                                        index
                                      )}
                                    />
                                    <br />
                                    <TextField
                                      name="educationLevel"
                                      label="Education"
                                      value={familyMember.educationLevel || ''}
                                      className={this.styles.textField}
                                      fullWidth
                                      onChange={this.handleFamilyMemberChange(
                                        index
                                      )}
                                    />
                                  </Flex>
                                </Flex>
                                <Divider />
                                <br />
                              </div>
                            )
                          }
                        )}
                        <Flex direction={'row-reverse'} align={'center'}>
                          <Button
                            disabled={
                              !familyMemberChanged &&
                              !primaryFamilyMemberChanged
                            }
                            aria-label="Submit"
                            type="submit"
                          >
                            Save Changes
                          </Button>
                          <Text style={{ marginRight: '10px' }}>
                            Make sure to verify all information before hitting
                            save!
                          </Text>
                        </Flex>
                      </form>
                    )}
                  </Composer>
                </Flex>
              )}

              {studentTabValue === 2 && (
                <Flex
                  column
                  style={{
                    align: 'center',
                    flexGrow: 1,
                  }}
                >
                  <Composer
                    components={[
                      <Mutation mutation={UPDATE_FAMILY_ACCOUNT}>
                        {function() {
                          return ''
                        }}
                      </Mutation>,
                      <Mutation
                        mutation={
                          needsLocationCreation
                            ? CREATE_LOCATION
                            : UPDATE_LOCATION
                        }
                      >
                        {function() {
                          return ''
                        }}
                      </Mutation>,
                    ]}
                  >
                    {([UFAmutation, locationMutation]) => (
                      <form
                        id="editFamilyAccountForm"
                        className={this.styles.container}
                        onSubmit={e => {
                          e.preventDefault()
                          this.handleFamilyAccountSubmission(
                            UFAmutation,
                            locationMutation
                          )
                        }}
                      >
                        <Flex justify="space-evenly">
                          <Flex
                            grow={2}
                            m="20px"
                            style={{ maxWidth: '300px' }}
                            column
                          >
                            <TextField
                              name="status"
                              label="Status"
                              value={familyAccount.status || ''}
                              className={this.styles.textField}
                              select
                              onChange={this.handleFamilyAccountChange}
                              SelectProps={{
                                MenuProps: {
                                  className: this.styles.menu,
                                },
                              }}
                              InputLabelProps={{
                                shrink:
                                  !!familyAccount.status &&
                                  familyAccount.status !== undefined,
                              }}
                            >
                              {['ACTIVE', 'INACTIVE', 'PROSPECT', 'CLOSED'].map(
                                option => (
                                  <MenuItem key={option} value={option}>
                                    {option}
                                  </MenuItem>
                                )
                              )}
                            </TextField>
                            <br />
                            <TextField
                              required={needsLocationCreation}
                              name="street"
                              label="Address"
                              value={familyAccount.location.street}
                              className={this.styles.textField}
                              fullWidth
                              onChange={this.handleLocationChange}
                            />
                            <br />
                            <TextField
                              required={needsLocationCreation}
                              name="city"
                              label="City"
                              value={familyAccount.location.city}
                              className={this.styles.textField}
                              fullWidth
                              onChange={this.handleLocationChange}
                            />
                          </Flex>
                          <Flex
                            grow={2}
                            m="20px"
                            style={{ maxWidth: '300px' }}
                            column
                          >
                            <TextField
                              required={needsLocationCreation}
                              name="state"
                              label="State/Region"
                              value={familyAccount.location.state || ''}
                              className={this.styles.textField}
                              select
                              onChange={this.handleLocationChange}
                              SelectProps={{
                                MenuProps: {
                                  className: this.styles.menu,
                                },
                              }}
                              InputLabelProps={{
                                shrink:
                                  !!familyAccount.location.state &&
                                  familyAccount.location.state !== undefined,
                              }}
                            >
                              {getRegions(actualCountry).map(
                                (option, index) => (
                                  <MenuItem key={option} value={option}>
                                    {option}
                                  </MenuItem>
                                )
                              )}
                            </TextField>
                            <br />
                            <TextField
                              required={needsLocationCreation}
                              name="zip"
                              label="Zip Code"
                              value={familyAccount.location.zip}
                              className={this.styles.textField}
                              fullWidth
                              inputProps={{
                                maxLength: 5,
                                onKeyDown: numberInputValidate,
                              }}
                              pattern="[0-9]"
                              onChange={this.handleLocationChange}
                            />
                            <br />
                            <TextField
                              required={needsLocationCreation}
                              name="country"
                              label="Country"
                              value={familyAccount.location.country || ''}
                              className={this.styles.textField}
                              select
                              onChange={this.handleLocationChange}
                              SelectProps={{
                                MenuProps: {
                                  className: this.styles.menu,
                                },
                              }}
                              InputLabelProps={{
                                shrink:
                                  !!familyAccount.location.country &&
                                  familyAccount.location.country !== undefined,
                              }}
                            >
                              {CountryRegionData.map((option, index) => (
                                <MenuItem key={option[0]} value={option[0]}>
                                  {option[0]}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Flex>
                        </Flex>
                        <Flex direction={'row-reverse'} align={'center'}>
                          <Button
                            disabled={!familyAccountChanged}
                            aria-label="Submit"
                            type="submit"
                          >
                            Save Changes
                          </Button>
                          <Text style={{ marginRight: '10px' }}>
                            Make sure to verify all information before hitting
                            save!
                          </Text>
                        </Flex>
                      </form>
                    )}
                  </Composer>
                </Flex>
              )}
            </Flex>
          }
        />
        <AccordionForm
          expanded
          title="Task History"
          form={
            <TaskHistory
              tasks={activities}
              onClick={this.handleTaskHistoryClick}
            />
          }
        />
        <AccordionForm
          expanded
          title="Enrollment History"
          form={
            <EnrollmentHistory
              enrollments={enrollments}
              onClick={this.handleEnrollmentClick}
            />
          }
        />

        <AccordionForm
          expanded
          title="Trial History"
          form={<TrialHistory trial={trials} onClick={this.handleTrialClick} />}
        />

        <SnackbarNotification
          open={snackbar.open}
          handleClose={() => {
            this.setState({
              snackbar: {
                ...snackbar,
                open: false,
              },
            })
            if (snackbar.willRedirect) {
              this.returnToOpportunityTable()
            }
          }}
          message={snackbar.message}
          messageType={snackbar.messageType}
        />
      </Flex>
    )
  }
}

export default OpportunityDetailView
