import gql from 'graphql-tag'

export const GET_ISSUE_STATUS_OPTIONS = gql`
  query getIssueStatusOptions {
    __type(name: "IssueStatus") {
      enumValues {
        name
      }
    }
  }
`

export const GET_ISSUE_TYPE_OPTIONS = gql`
  query getIssueTypeOptions {
    __type(name: "IssueType") {
      enumValues {
        name
      }
    }
  }
`

export const GET_ISSUE_SEVERITY_OPTIONS = gql`
  query getIssueSeverityOptions {
    __type(name: "IssueSeverity") {
      enumValues {
        name
      }
    }
  }
`

export const GET_ISSUE_PRIORITY_OPTIONS = gql`
  query getIssuePriorityOptions {
    __type(name: "IssuePriority") {
      enumValues {
        name
      }
    }
  }
`

export const COUNT_ISSUES = gql`
  query countIssues($filter: Filters) {
    countIssues(filter: $filter)
  }
`

export const GET_ISSUES = gql`
  query GetIssues($filter: Filters, $sort: Sorts, $page: Pagination) {
    issues(filter: $filter, sort: $sort, page: $page) {
      id
      issueStatus
      createdOn
      issueType
      dateAssigned
      dateEscalated
      dateResolved
      deadline
      lapCode
      responsibleEmployee {
        id
        firstName
        lastName
      }
      description
      resolution
      response
      student {
        id
        firstName
        lastName
      }
      classroom {
        id
        code
      }
      attendee {
        id
        lesson {
          id
        }
      }
    }
  }
`

export const UPDATE_ISSUE = gql`
  mutation updateIssues($id: ID!, $input: UpdateIssueInput!) {
    updateIssue(id: $id, input: $input) {
      id
    }
  }
`

export const UPDATE_SUBMISSION = gql`
  mutation updateSubmission($id: ID!, $input: UpdateSubmissionInput!) {
    updateSubmission(id: $id, input: $input) {
      id
    }
  }
`

export const GET_TECH_EMPLOYEES = gql`
  query GetTechEmployees {
    employees(filter: { isTechSupport: true }) {
      id
      firstName
      lastName
    }
  }
`

export const GIVE_FREE_POINT = gql`
  mutation GiveFreePoint($id: ID!) {
    giveFreePoint(problemId: $id)
  }
`

export const ARCHIVE_ISSUE = gql`
  mutation archiveIssue($id: ID!) {
    archiveIssue(id: $id) {
      id
    }
  }
`
