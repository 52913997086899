import React, { Component } from 'react'
import { withApollo } from 'react-apollo'
import { Link, withRouter } from 'react-router-dom'
import AsyncSelect from 'react-select/async'
import Cookies from 'js-cookie'
import { idName } from '../../config'

import {
  Button,
  Flex,
  Paper,
  SnackbarNotification,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Text,
  TextField,
} from '../../components'

import { convertPhoneNumber } from '../../utils'
import { GET_FAMILY_ACCOUNTS } from '../StudentView/queries'
import { CREATE_CREDIT } from '../InvoiceView/queries'

class ReferralForm extends Component {
  state = {
    currentFromValue: '',
    currentToValue: '',
    selectedFromAccount: null,
    selectedToAccount: null,
    openingAmount: 0,
    loading: false,
    notes: '',
    snackbar: {
      open: false,
      message: '',
      messageType: '',
      windowReload: false,
    },
  }

  matchesPrimaryFamilyMember = (useFrom, familyAccount) => {
    const { firstName, lastName } = useFrom
      ? this.state.selectedFromAccount.primaryFamilyMember
      : this.state.selectedToAccount.primaryFamilyMember
    const {
      firstName: otherFirstName,
      lastName: otherLastName,
    } = familyAccount.primaryFamilyMember

    return firstName === otherFirstName && lastName === otherLastName
  }

  getFamilyAccounts = async useFrom => {
    const { client } = this.props
    const { currentFromValue, currentToValue } = this.state
    const {
      data: { familyAccounts },
    } = await client.query({
      query: GET_FAMILY_ACCOUNTS,
      variables: {
        filter: {
          primaryFamilyMember: {
            $name: useFrom ? currentFromValue : currentToValue,
          },
        },
        sort: {
          primaryFamilyMember: {
            firstName: 1,
            lastName: 2,
          },
        },
      },
    })
    if (useFrom) {
      this.setState({
        fromFamilyAccounts: familyAccounts,
      })
    } else {
      this.setState({ toFamilyAccounts: familyAccounts })
    }

    if (familyAccounts.length === 0) {
      return [{ value: null, label: 'No Result' }]
    }

    return familyAccounts.map(familyAccount => {
      const { firstName, lastName } = familyAccount.primaryFamilyMember
      return {
        value: familyAccount,
        label: `${firstName} ${lastName}`,
      }
    })
  }

  createCreditHandler = async () => {
    const { client } = this.props
    const { selectedToAccount, selectedFromAccount, notes } = this.state

    if (!selectedToAccount || !selectedFromAccount) {
      alert('select two family first!')
      return
    }
    this.openSnack('Creating...', 'other')
    this.setState({ loading: true })

    const employeeId = Cookies.get(idName)
    const { data: dataFrom, error: errorFrom } = await client.mutate({
      mutation: CREATE_CREDIT,
      variables: {
        input: {
          familyAccountId: selectedFromAccount.id,
          amount: 50,
          balance: 50,
          issueDate: new Date().toISOString(),
          creditType: 'REFERRAL',
          responsibleEmployeeId: employeeId,
          notes: notes,
        },
      },
    })
    const { data: dataTo, error: errorTo } = await client.mutate({
      mutation: CREATE_CREDIT,
      variables: {
        input: {
          familyAccountId: selectedToAccount.id,
          amount: 50,
          balance: 50,
          issueDate: new Date().toISOString(),
          creditType: 'REFERRAL',
          responsibleEmployeeId: employeeId,
          notes: notes,
        },
      },
    })

    if (dataTo && dataFrom && !errorFrom && !errorTo) {
      this.openSnack('Success!', 'success')
      setTimeout(() => {
        this.props.history.push('/credit/' + dataTo.createCredit.creditNumber)
      }, 1000)
    } else {
      this.openSnack('Failed!', 'error')
      this.setState({ loading: false })
    }
  }

  openSnack = (message, messageType, windowReload) => {
    const { snackbar } = this.state
    const newSnackbar = {
      ...snackbar,
      open: true,
      messageType,
      message,
      windowReload,
    }
    this.setState({ snackbar: newSnackbar })
  }

  render() {
    const {
      currentFromValue,
      currentToValue,
      selectedFromAccount,
      selectedToAccount,
      fromFamilyAccounts,
      toFamilyAccounts,
      snackbar,
      loading,
      notes,
    } = this.state

    return (
      <Flex style={{ height: '80vh' }}>
        <Paper
          style={{
            width: '50%',
            margin: 'auto',
            marginTop: '10px',
            padding: '10px',
          }}
        >
          <Flex
            column
            style={{
              align: 'center',
              flexGrow: 1,
            }}
            p="20px"
          >
            <Text variant="subtitle1" style={{ textAlign: 'center' }}>
              Choose From family account
            </Text>
            <br />
            <AsyncSelect
              autoFocus={!selectedFromAccount}
              isLoading={!selectedFromAccount}
              loadOptions={
                currentFromValue.length >= 2 &&
                (() =>
                  new Promise(resolve => {
                    setTimeout(() => {
                      resolve(this.getFamilyAccounts(true))
                    }, 1000)
                  }))
              }
              menuIsOpen={currentFromValue.length >= 2}
              placeholder={'Search for existing primary family member...'}
              onChange={option =>
                this.setState({ selectedFromAccount: option.value })
              }
              onInputChange={v => this.setState({ currentFromValue: v })}
              inputValue={currentFromValue}
              value={
                selectedFromAccount
                  ? {
                      value: selectedFromAccount,
                      label: `${selectedFromAccount.primaryFamilyMember.firstName} ${selectedFromAccount.primaryFamilyMember.lastName}`,
                    }
                  : null
              }
            />
            <br />
            {selectedFromAccount && (
              <div>
                <Text variant="subtitle1">Search Results</Text>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>First Name</TableCell>
                      <TableCell>Last Name</TableCell>
                      <TableCell>Phone Number</TableCell>
                      <TableCell>Email</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fromFamilyAccounts.map(familyAccount => {
                      if (
                        this.matchesPrimaryFamilyMember(true, familyAccount)
                      ) {
                        const {
                          firstName,
                          lastName,
                          phoneNumber,
                          email,
                        } = familyAccount.primaryFamilyMember
                        return (
                          <TableRow
                            hover
                            onClick={() =>
                              this.setState({
                                selectedToAccount: familyAccount,
                              })
                            }
                            selected={
                              familyAccount.id === selectedFromAccount.id
                            }
                            key={familyAccount.id}
                          >
                            <TableCell>{firstName}</TableCell>
                            <TableCell>{lastName}</TableCell>
                            <TableCell>
                              {convertPhoneNumber(phoneNumber)}
                            </TableCell>
                            <TableCell>{email}</TableCell>
                          </TableRow>
                        )
                      }
                      return null
                    })}
                  </TableBody>
                </Table>
                <br />
              </div>
            )}
            <Text variant="subtitle1" style={{ textAlign: 'center' }}>
              Choose From family account
            </Text>
            <br />
            <AsyncSelect
              autoFocus={!selectedToAccount}
              isLoading={!selectedToAccount}
              loadOptions={
                currentToValue.length >= 2 &&
                (() =>
                  new Promise(resolve => {
                    setTimeout(() => {
                      resolve(this.getFamilyAccounts(false))
                    }, 1000)
                  }))
              }
              menuIsOpen={currentToValue.length >= 2}
              placeholder={'Search for existing primary family member...'}
              onChange={option =>
                this.setState({ selectedToAccount: option.value })
              }
              onInputChange={v => this.setState({ currentToValue: v })}
              inputValue={currentToValue}
              value={
                selectedToAccount
                  ? {
                      value: selectedToAccount,
                      label: `${selectedToAccount.primaryFamilyMember.firstName} ${selectedToAccount.primaryFamilyMember.lastName}`,
                    }
                  : null
              }
            />
            <br />
            {selectedToAccount && (
              <div>
                <Text variant="subtitle1">Search Results</Text>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>First Name</TableCell>
                      <TableCell>Last Name</TableCell>
                      <TableCell>Phone Number</TableCell>
                      <TableCell>Email</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {toFamilyAccounts.map(familyAccount => {
                      if (
                        this.matchesPrimaryFamilyMember(false, familyAccount)
                      ) {
                        const {
                          firstName,
                          lastName,
                          phoneNumber,
                          email,
                        } = familyAccount.primaryFamilyMember
                        return (
                          <TableRow
                            hover
                            onClick={() =>
                              this.setState({
                                selectedToAccount: familyAccount,
                              })
                            }
                            selected={familyAccount.id === selectedToAccount.id}
                            key={familyAccount.id}
                          >
                            <TableCell>{firstName}</TableCell>
                            <TableCell>{lastName}</TableCell>
                            <TableCell>
                              {convertPhoneNumber(phoneNumber)}
                            </TableCell>
                            <TableCell>{email}</TableCell>
                          </TableRow>
                        )
                      }
                      return null
                    })}
                  </TableBody>
                </Table>
                <br />
              </div>
            )}
          </Flex>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}
          >
            <TextField
              multiline
              minRows={4}
              variant="filled"
              value={notes}
              onChange={v => this.setState({ notes: v.target.value })}
              label="notes"
              fullWidth
            />
          </div>
          <br />
          <Flex direction={'row-reverse'} align={'center'}>
            <Button onClick={this.createCreditHandler} disabled={loading}>
              Create
            </Button>
            <Button
              color="secondary"
              component={Link}
              to={'/credit'}
              disabled={loading}
              style={{ marginRight: '10px' }}
            >
              Cancel
            </Button>
          </Flex>
          <SnackbarNotification
            open={snackbar.open}
            handleClose={() => {
              this.setState({
                snackbar: {
                  ...snackbar,
                  open: false,
                },
              })
              if (snackbar.windowReload) window.location.reload()
            }}
            message={snackbar.message}
            messageType={snackbar.messageType}
          />
        </Paper>
      </Flex>
    )
  }
}
export default withApollo(withRouter(ReferralForm))
