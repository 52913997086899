import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

import StudentView from '../screens/StudentView/StudentView'
import FamilyView from '../screens/FamilyView/FamilyView'
import OpportunityView from '../screens/OpportunityView/OpportunityView'
import TaskView from '../screens/TaskView/TaskView'
import ClassViewRouter from '../screens/ClassView/ClassViewRouter'
import LessonViewRouter from '../screens/LessonView/LessonViewRouter'
import GradeView from '../screens/GradeView/GradeView'
import IssueView from '../screens/IssueView/IssueTableView'
import TrialView from '../screens/TrialView/TrialView'
import MakeupView from '../screens/MakeupView/MakeupView'
import CounselingView from '../screens/CounselingView/CounselingView'
import AttendanceView from '../screens/Attendance/AttendanceView'
import AssessmentView from '../screens/Assessment/AssessmentView'
import IssueViewRouter from '../screens/IssueView/IssueViewRouter'
import NewsViewRouter from '../screens/NewsView/NewsViewRouter'
import InvoiceView from '../screens/InvoiceView/InvoiceView'
import PaymentView from '../screens/PaymentView/PaymentView'
import CreditView from '../screens/CreditView/CreditView'
import PostsViewRouter from '../screens/PostsView/PostsViewRouter'
import ReconcileView from '../screens/ReconcileView/ReconcileView'
import TutorClassViewRouter from '../screens/TutorClassView/TutorClassViewRouter'
import TutorStudentView from '../screens/TutorStudentView'
import TutorEmployeeView from '../screens/TutorEmployeeView'
import ReferralViewRouter from '../screens/ReferralView/ReferralViewRouter'
import HomeView from '../screens/HomeView/HomeView'
import LoginView from '../screens/LoginView/LoginView'

class MainView extends Component {
  render() {
    const session = JSON.parse(localStorage.getItem('ardentSession'))
    if (session && session.loggedIn) {
      return (
        <Switch>
          <Route path="/opportunity" component={OpportunityView} />
          <Route path="/family" component={FamilyView} />
          <Route path="/student" component={StudentView} />
          <Route path="/task" component={TaskView} />
          <Route path="/classroom" component={ClassViewRouter} />
          <Route path="/trial" component={TrialView} />
          <Route path="/makeup" component={MakeupView} />
          <Route path="/grade" component={GradeView} />
          <Route path="/issue" component={IssueView} />
          <Route path="/counseling" component={CounselingView} />
          <Route path="/attendance" component={AttendanceView} />
          <Route path="/assessment" component={AssessmentView} />
          <Route path="/lesson" component={LessonViewRouter} />
          <Route path="/issue" component={IssueViewRouter} />
          <Route path="/announcement" component={NewsViewRouter} />
          <Route path="/invoice" component={InvoiceView} />
          <Route path="/payment" component={PaymentView} />
          <Route path="/credit" component={CreditView} />
          <Route path="/posts" component={PostsViewRouter} />
          <Route path="/reconciliation" component={ReconcileView} />
          <Route path="/tutor_class" component={TutorClassViewRouter} />
          <Route path="/tutor_student" component={TutorStudentView} />
          <Route path="/tutor_employees" component={TutorEmployeeView} />
          <Route path="/referral" component={ReferralViewRouter} />
          <Route path="/" component={HomeView} />
        </Switch>
      )
    } else {
      return <LoginView {...this.props} />
    }
  }
}

export default MainView
