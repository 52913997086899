import React, { Component } from 'react'
import ReactTable from 'react-table'
import 'react-table/react-table.css'

import { dateFilterMethod, convertToMDY } from '../../utils/datetime'

class CounselingTable extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { checked, statuses } = this.props
    const columns = [
      {
        Cell: row => <input type="checkbox" checked={checked[row.index]} />,
        sortable: false,
        filterable: false,
        resizable: false,
        width: 28,
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: 135,
        Cell: props => {
          if (props.value === 'ACTIVE') {
            return <span style={{ color: '#27AE60' }}>{'ACTIVE'}</span>
          }
          if (props.value === 'INACTIVE') {
            return <span style={{ color: '#EB5757' }}>{'INACTIVE'}</span>
          }
          if (props.value === 'GRADUATED') {
            return <span style={{ color: '#00ADEF' }}>{'GRADUATED'}</span>
          }
          return <span>{props.value}</span>
        },
        filterMethod: (filter, row) => {
          if (filter.value !== 'ALL') {
            return row[filter.id] === filter.value
          }
          return true
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: '100%' }}
            value={filter ? filter.value : 'ALL'}
          >
            <option value="ALL">All</option>
            {statuses.map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        ),
      },
      {
        Header: 'First Name',
        accessor: 'firstName',
      },
      {
        Header: 'Last Name',
        accessor: 'lastName',
      },
      {
        Header: 'Grade',
        accessor: 'grade',
        width: 64,
        Cell: props => <span>{props.value === 0 ? 'K' : props.value}</span>,
        filterMethod: (filter, row) => {
          if (isNaN(filter.value)) {
            if (filter.value === 'ALL') {
              return true
            }
            if (filter.value === '-') {
              return row[filter.id] === null
            }
          }
          return row[filter.id] === parseInt(filter.value, 10)
        },
        Filter: ({ filter, onChange }) => {
          return (
            <select
              onChange={event => onChange(event.target.value)}
              style={{ width: '100%' }}
              value={filter ? filter.value : 'All'}
            >
              <option value="All">All</option>
              {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(i => (
                <option key={'gradeFilter'.concat(i)} value={i}>
                  {i === 0 ? 'K' : i}
                </option>
              ))}
              <option value="-">-</option>
            </select>
          )
        },
      },
      {
        Header: 'School',
        accessor: 'school',
      },
      {
        Header: 'From',
        accessor: 'startDate',
        Cell: props => (
          <span>
            {props.value && convertToMDY(props.value).substring(6, 10)}
          </span>
        ),
        filterMethod: dateFilterMethod,
      },
      {
        Header: 'To',
        accessor: 'endDate',
        Cell: props => (
          <span>
            {props.value && convertToMDY(props.value).substring(6, 10)}
          </span>
        ),
        filterMethod: dateFilterMethod,
      },
      {
        Header: 'Last Contact Time',
        accessor: 'lastContactTime',
        Cell: props => <span>{props.value && convertToMDY(props.value)}</span>,
        filterMethod: dateFilterMethod,
      },
      {
        Header: 'Next Contact Time',
        accessor: 'nextContactTime',
        Cell: props => <span>{props.value && convertToMDY(props.value)}</span>,
        filterMethod: dateFilterMethod,
      },
      {
        Header: 'Counselor',
        accessor: 'counselor',
      },
    ]

    const {
      filtered,
      filterable,
      handleSingleCheckboxChange,
      onFilteredChange,
      onRowClick,
      sorted,
      counselingEnrollments,
    } = this.props
    return (
      <div align="left">
        <ReactTable
          filterable={filterable}
          data={counselingEnrollments}
          columns={columns}
          className="-highlight"
          defaultPageSize={25}
          defaultSorted={sorted}
          defaultFiltered={filtered}
          onFilteredChange={onFilteredChange}
          style={{ fontSize: '10pt' }}
          defaultFilterMethod={(filter, row) =>
            row[filter.id] &&
            row[filter.id]
              .toString()
              .toLowerCase()
              .includes(filter.value.toString().toLowerCase())
          }
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              onClick: (e, handleOriginal) => {
                if (column.Header && column.Header !== 'Email') {
                  onRowClick(rowInfo)
                }
                if (column.Header === undefined) {
                  //only the checkbox has an undefined header
                  handleSingleCheckboxChange(rowInfo.index)
                }
                if (handleOriginal) {
                  handleOriginal()
                }
              },
            }
          }}
          getTheadProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                fontWeight: 'bold',
                borderBottom: '1px solid #E5E5E5',
              },
            }
          }}
        />
      </div>
    )
  }
}

export default CounselingTable
