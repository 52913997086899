import React, { Fragment } from 'react'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'

import {
  Button,
  DialogActions,
  ErrorMessage,
  Flex,
  DatePicker,
  LoadingMessage,
  MenuItem,
  Text,
  TextField,
} from './'

import {
  GET_OPPORTUNITY_STAGE_OPTIONS_QUERY,
  GET_OPPORTUNITY_STATUS_OPTIONS_QUERY,
} from '../screens/OpportunityView/queries'
import { GET_ACTIVITY_CONTACT_METHOD_OPTIONS_QUERY } from '../screens/TaskView/queries'
import withStyles from './Styles'
import { expansionBarColor, interests } from '../config'

const OpportunityForm = props => {
  let stages = props.stages
  let statuses = props.statuses

  if (stages && statuses) return <InnerOpportunityForm {...props} />
  return (
    <Query query={GET_OPPORTUNITY_STAGE_OPTIONS_QUERY}>
      {({ loading: stagesLoading, error: stagesError, data: stagesData }) => (
        <Query query={GET_OPPORTUNITY_STATUS_OPTIONS_QUERY}>
          {({
            loading: statusesLoading,
            error: statusesError,
            data: statusesData,
          }) => {
            if (stagesLoading || statusesLoading) return <LoadingMessage />
            if (stagesError || statusesError)
              return <ErrorMessage error={stagesError || statusesError} />
            stages = stagesData.__type.enumValues.map(({ name }) => name)
            statuses = statusesData.__type.enumValues.map(({ name }) => name)
            return (
              <InnerOpportunityForm
                {...props}
                stages={stages}
                statuses={statuses}
              />
            )
          }}
        </Query>
      )}
    </Query>
  )
}

const InnerOpportunityForm = props => {
  const styles = props.classes
  const {
    handleChange,
    handleSave,
    opportunity,
    opportunityChanged,
    readOnly,
    showSave,
    showCreatedBy,
    showStatus,
    stages,
    statuses,
  } = props
  const {
    createdBy,
    lastActivity,
    lastContacted,
    notes,
    primaryInterest,
    responsibleEmployee,
    responsibleEmployeeId,
    session,
    source,
    stage,
    status,
  } = opportunity

  const SaveButton = () => {
    return (
      <DialogActions style={{ paddingBottom: '10px', paddingRight: '10px' }}>
        <Text style={{ marginRight: '5px' }}>
          Make sure to verify all information before saving!
        </Text>
        <Button
          onClick={handleSave}
          disabled={!opportunityChanged}
          color="primary"
        >
          Save Changes
        </Button>
      </DialogActions>
    )
  }

  return (
    <Flex
      column
      style={{
        align: 'center',
        flexGrow: 1,
      }}
    >
      <form id="addOpportunityForm">
        <Flex justify="space-evenly">
          <Flex grow={2} m="20px" style={{ maxWidth: '300px' }} column>
            {showStatus && (
              <Fragment>
                <TextField
                  required
                  InputProps={{
                    readOnly: readOnly,
                  }}
                  name="status"
                  label="Status"
                  value={status}
                  className={styles.textField}
                  select
                  onChange={handleChange}
                  disabled={readOnly}
                  SelectProps={{
                    MenuProps: {
                      className: styles.menu,
                    },
                  }}
                  InputLabelProps={{
                    formlabelclasses: {
                      asterisk: styles.asterisk,
                    },
                    shrink: !!status && status !== undefined,
                  }}
                >
                  {statuses.map(option => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
                <br />
              </Fragment>
            )}
            <TextField
              required
              InputProps={{
                readOnly: readOnly,
              }}
              name="primaryInterest"
              label="Primary Interest"
              value={primaryInterest || ''}
              className={styles.textField}
              select
              onChange={handleChange}
              disabled={readOnly}
              SelectProps={{
                MenuProps: {
                  className: styles.menu,
                },
              }}
              InputLabelProps={{
                formlabelclasses: {
                  asterisk: styles.asterisk,
                },
                shrink: !!primaryInterest && primaryInterest !== undefined,
              }}
            >
              {interests.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <br />
            <Flex>
              <TextField
                name="session"
                label="Session"
                InputProps={{
                  readOnly: readOnly,
                }}
                value={session || ''}
                className={styles.textField}
                select
                fullWidth
                onChange={handleChange}
                disabled={readOnly}
                SelectProps={{
                  MenuProps: {
                    className: styles.menu,
                  },
                }}
                InputLabelProps={{
                  shrink: !!session && session !== undefined,
                }}
                style={{ paddingRight: '15px' }}
              >
                {[
                  'Fall',
                  'Winter',
                  'Spring',
                  'PreSummer',
                  'Summer',
                  'OnDemand',
                  'Tutoring',
                  'Contest',
                ].map(option => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                name="year"
                label="Year"
                type="number"
                value={
                  new Date().getFullYear() +
                  1 * (session === 'Winter' && new Date().getMonth() > 8)
                }
                className={styles.textField}
                fullWidth
                onChange={handleChange}
                disabled={readOnly}
              />
            </Flex>
            <br />
            {showCreatedBy && (
              <Fragment>
                <TextField
                  InputProps={{
                    readOnly: true,
                  }}
                  id="createdBy"
                  label="Created By"
                  value={
                    createdBy && `${createdBy.firstName} ${createdBy.lastName}`
                  }
                  className={styles.textField}
                  disabled={readOnly}
                  InputLabelProps={{
                    shrink: !!createdBy && createdBy !== undefined,
                  }}
                />
                <br />
              </Fragment>
            )}
            <TextField
              name="source"
              label="Source"
              InputProps={{
                readOnly: readOnly,
              }}
              value={source || ''}
              className={styles.textField}
              fullWidth
              onChange={handleChange}
              disabled={readOnly}
            />
            <br />
            <TextField
              name="notes"
              label="Notes"
              InputProps={{
                readOnly: readOnly,
              }}
              multiline
              maxRows="4"
              value={notes || ''}
              className={styles.textField}
              fullWidth
              onChange={handleChange}
              disabled={readOnly}
            />
          </Flex>
          <Flex grow={2} m="20px" style={{ maxWidth: '300px' }} column>
            <Query query={GET_OFFICE_EMPLOYEES}>
              {({ loading, error, data }) => {
                if (loading) return <LoadingMessage />
                if (error) return <ErrorMessage error={error} />

                const responsibleEmployees = data.employees
                  .filter(employee => {
                    return (
                      employee.id === responsibleEmployeeId ||
                      employee.id ===
                        (responsibleEmployee && responsibleEmployee.id) ||
                      employee.status === 'ACTIVE'
                    )
                  })
                  .map(employee => {
                    return {
                      name: `${employee.firstName} ${employee.lastName}`,
                      value: employee.id,
                    }
                  })

                return (
                  <TextField
                    name="responsibleEmployeeId"
                    label="Assign To"
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    value={
                      responsibleEmployeeId ||
                      (responsibleEmployee && responsibleEmployee.id) ||
                      ''
                    }
                    className={styles.textField}
                    select
                    onChange={handleChange}
                    disabled={readOnly}
                    SelectProps={{
                      MenuProps: {
                        className: styles.menu,
                      },
                    }}
                    InputLabelProps={{
                      shrink:
                        (responsibleEmployeeId &&
                          responsibleEmployeeId !== undefined) ||
                        (responsibleEmployee &&
                          responsibleEmployee.id &&
                          responsibleEmployee.id !== undefined),
                    }}
                  >
                    {responsibleEmployees.map(option => (
                      <MenuItem key={option.name} value={option.value}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )
              }}
            </Query>
            <br />
            <TextField
              name="stage"
              label="Stage"
              InputProps={{
                readOnly: readOnly,
              }}
              value={stage}
              className={styles.textField}
              select
              onChange={handleChange}
              disabled={readOnly}
              SelectProps={{
                MenuProps: {
                  className: styles.menu,
                },
              }}
              InputLabelProps={{
                shrink: !!stage && stage !== undefined,
              }}
            >
              {stages.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <br />
            <DatePicker
              name="lastContacted"
              label="Last Contacted"
              InputProps={{
                readOnly: readOnly,
              }}
              disabled={readOnly}
              value={lastContacted || null}
              onChange={handleChange}
            />
            <br />
            <Query query={GET_ACTIVITY_CONTACT_METHOD_OPTIONS_QUERY}>
              {({ loading, error, data }) => {
                if (loading) return <LoadingMessage />
                if (error) return <ErrorMessage error={error} />
                return (
                  <TextField
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    disabled
                    name="lastActivityContactMethod"
                    label="Last Activity Contact Method"
                    value={(lastActivity && lastActivity.method) || ''}
                    className={styles.textField}
                    select
                    onChange={handleChange}
                    SelectProps={{
                      MenuProps: {
                        className: styles.menu,
                      },
                    }}
                    InputLabelProps={{
                      formlabelclasses: {
                        asterisk: styles.asterisk,
                      },
                      shrink: !!lastActivity && lastActivity !== undefined,
                    }}
                  >
                    {data.__type.enumValues.map(
                      ({ name: lastActivityContactMethod }) => (
                        <MenuItem
                          key={lastActivityContactMethod}
                          value={lastActivityContactMethod}
                        >
                          {lastActivityContactMethod}
                        </MenuItem>
                      )
                    )}
                  </TextField>
                )
              }}
            </Query>
          </Flex>
        </Flex>
        {showSave && <SaveButton />}
      </form>
    </Flex>
  )
}

const styles = theme => ({
  menu: {
    width: '20%',
  },
  expSummary: {
    backgroundColor: expansionBarColor,
  },
  asterisk: {
    color: 'red',
  },
})

const GET_OFFICE_EMPLOYEES = gql`
  query getOfficeEmployees {
    employees(filter: { isOfficeUser: true }) {
      id
      firstName
      lastName
      status
    }
  }
`

export default withStyles(styles)(OpportunityForm)
