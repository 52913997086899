import React, { Component } from 'react'
import { Query, withApollo } from 'react-apollo'
import {
  GET_CLASSROOMS,
  GET_LESSONS,
  GET_LOCATIONS,
  GET_CENTER_LOCATION_ID,
} from './queries'
import AttendanceTable from './AttendanceTable'
import {
  Button,
  MenuItem,
  MenuList,
  Flex,
  LoadingMessage,
  ErrorMessage,
  Select,
  FormControl,
  InputLabel,
  ErrorBoundary,
} from '../../components'
import moment from 'moment'
import publicIP from 'react-native-public-ip'
import RepairAttendeeDialog from './RepairAttendeeDialog'

class AttendanceView extends Component {
  constructor(props) {
    super(props)

    this.today = new Date()
    //Persist state after refetch
    this.state = {
      selected: localStorage.getItem('attendeeSelected') || -1,
      classroomId: localStorage.getItem('attendeeClassroomId') || '',
      dayOfWeek:
        localStorage.getItem('attendeeDayOfWeek') ||
        moment(this.today).format('dddd'),
      location: localStorage.getItem('attendeeLocation') || '',
      useDateFilter: false,
    }
  }

  getLocation = ip => {
    switch (ip) {
      case '184.185.101.82':
        return 'UTC'
      case '24.249.35.180':
        return 'Northwood'
      case '174.66.87.207':
        return 'Woodbridge'
      default:
        return 'ALL'
    }
  }

  async componentDidMount() {
    if (localStorage.getItem('attendeeClassroomId')) localStorage.clear()
    const ip = await publicIP()
    const locationName = this.getLocation(ip)
    const {
      data: { centerLocations },
    } = await this.props.client.query({
      query: GET_CENTER_LOCATION_ID,
      variables: { filter: { name: locationName } },
    })
    await this.setState({
      location: centerLocations.length === 1 ? centerLocations[0].id : 'ALL',
    })
  }

  handleSelect = (classroom, index) => {
    this.setState({
      selected: index,
      classroomId: classroom.id,
    })
  }

  toggleDateFilter = () => {
    this.setState(({ useDateFilter }) => ({
      useDateFilter: !useDateFilter,
    }))
  }

  constructClassroomFilter = () => {
    const { dayOfWeek, location, useDateFilter } = this.state

    const classroomFilter = {
      status: 'OPENED',
      [`meetsOn${dayOfWeek}`]: true,
    }
    if (location !== null && location !== 'ALL') {
      classroomFilter.centerLocationId = location
    }
    if (useDateFilter) {
      const startOfToday = new Date()
      startOfToday.setHours(0, 0, 0, 0)
      const endOfToday = new Date()
      endOfToday.setHours(24, 0, 0, 0)

      classroomFilter.startDate = { $lte: endOfToday }
      classroomFilter.endDate = { $gte: startOfToday }
    }

    return classroomFilter
  }

  refreshWithState = () => {
    const { selected, classroomId, dayOfWeek, location } = this.state
    localStorage.setItem('attendeeSelected', selected)
    localStorage.setItem('attendeeClassroomId', classroomId)
    localStorage.setItem('attendeeDayOfWeek', dayOfWeek)
    localStorage.setItem('attendeeLocation', location)
    window.location.reload()
  }

  render() {
    return (
      <Flex>
        <Flex column style={{ width: '15em' }}>
          <Button
            variant="text"
            color="default"
            style={{
              margin: '10% 25% 10% 10%',
            }}
            onClick={this.toggleDateFilter}
          >
            {this.state.useDateFilter
              ? moment(this.today).format('LL')
              : 'Date filter off'}
          </Button>
          <Flex style={{ padding: '0 10% 10% 10%' }}>
            <FormControl>
              <InputLabel>Day of the Week</InputLabel>
              <Select
                value={this.state.dayOfWeek}
                onChange={event =>
                  this.setState({
                    dayOfWeek: event.target.value,
                    classroomId: '',
                  })
                }
                style={{ width: '10em' }}
              >
                {moment.weekdays().map(day => (
                  <MenuItem key={day} value={day} name={'dayOfWeek'}>
                    {day}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Flex>
          <Query query={GET_LOCATIONS}>
            {({ data, error, loading }) => {
              if (loading) {
                return <LoadingMessage />
              }
              if (error) {
                return <ErrorMessage error={error} />
              }
              return (
                <Flex style={{ padding: '0 10% 10% 10%' }}>
                  <FormControl>
                    <InputLabel>Select Location</InputLabel>
                    <Select
                      value={this.state.location}
                      onChange={event => {
                        const newLocation = event.target.value
                        this.setState({ location: newLocation })
                      }}
                      style={{ width: '10em' }}
                    >
                      <MenuItem key={0} value={'ALL'} name={'location'}>
                        ALL
                      </MenuItem>
                      {data.centerLocations.map((location, index) => (
                        <MenuItem
                          key={index}
                          value={location.id}
                          name={'location'}
                        >
                          {location.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Flex>
              )
            }}
          </Query>
          <Query
            query={GET_CLASSROOMS}
            variables={{ filter: this.constructClassroomFilter() }}
          >
            {({ data, error, loading }) => {
              if (loading) {
                return <LoadingMessage />
              }
              if (error) {
                return <ErrorMessage error={error} />
              }
              return (
                <MenuList>
                  {data.classrooms
                    .sort((a, b) => (a.code > b.code ? 1 : -1))
                    .map((classroom, index) => (
                      <MenuItem
                        key={classroom.id}
                        value={classroom.id}
                        name={'classroom'}
                        selected={classroom.id === this.state.classroomId}
                        onClick={_ => this.handleSelect(classroom, index)}
                      >
                        {classroom.code}
                      </MenuItem>
                    ))}
                </MenuList>
              )
            }}
          </Query>
          <RepairAttendeeDialog
            classroomId={this.state.classroomId}
            refreshWithState={this.refreshWithState}
          />
        </Flex>
        <ErrorBoundary>
          {this.state.classroomId && (
            <Query
              query={GET_LESSONS}
              variables={{ id: this.state.classroomId }}
            >
              {({ data, error, loading, refetch }) => {
                if (loading) {
                  return <LoadingMessage />
                }
                if (error) {
                  return <ErrorMessage error={error} />
                }
                return (
                  <AttendanceTable
                    data={data}
                    id={this.state.classroomId}
                    refetch={refetch}
                  />
                )
              }}
            </Query>
          )}
        </ErrorBoundary>
      </Flex>
    )
  }
}

export default withApollo(AttendanceView)
