import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

import TutorClassDetailTableView from './TutorClassDetailTableView'
import TutorClassTableView from './TutorClassTableView'
import EnrollStudentView from './EnrollStudentView'

class TutorClassViewRouter extends Component {
  render() {
    const url = this.props.match.url
    return (
      <Switch>
        <Route path={`${url}/enroll-student/*`} component={EnrollStudentView} />
        <Route path={`${url}/*`} component={TutorClassDetailTableView} />
        <Route path={url} component={TutorClassTableView} />
      </Switch>
    )
  }
}

export default TutorClassViewRouter
