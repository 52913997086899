import React from 'react'
import ReactTable from 'react-table'

import { convertPhoneNumber } from '../../utils'
import { dateFilterMethod } from '../../utils/datetime'
import { convertToMDY } from '../../utils/datetime'

const ReferralTable = React.memo(props => {
  const table = React.createRef()

  const {
    selected,
    fetchReferrals,
    handleSelect,
    filterable,
    filtered,
    sorted,
    referrals,
  } = props

  const columns = [
    {
      Cell: row => (
        <input
          type="checkbox"
          defaultChecked={(selected && selected.index === row.index) || false}
          onClick={() => handleSelect(row)}
        />
      ),
      sortable: false,
      filterable: false,
      resizable: false,
      width: 28,
    },
    {
      Header: 'Status',
      accessor: 'status',
      width: 110,
      Cell: props => {
        return (
          <span
            style={{
              color:
                props.value === 'ENROLLED'
                  ? '#27AE60'
                  : props.value === 'SIGNEDUP'
                  ? 'orange'
                  : props.value === 'EXPIRED'
                  ? '#EB5757'
                  : '#000000',
            }}
          >
            {props.value}
          </span>
        )
      },
      filterMethod: (filter, row) => {
        if (filter.value !== 'INITIATED') {
          return row[filter.id] === filter.value
        }
        return true
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: '100%' }}
          value={filter ? filter.value : 'ALL'}
        >
          <option value="INITIATED">Initiated</option>
          <option value="SIGNEDUP">Signed Up</option>
          <option value="ENROLLED">Enrolled</option>
          <option value="EXPIRED">Expired</option>
          <option value="CLOSED">Closed</option>
          <option value="CANCELLED">Cancelled</option>
        </select>
      ),
    },
    {
      Header: 'First Name',
      accessor: 'firstName',
    },
    {
      Header: 'Last Name',
      accessor: 'lastName',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Phone Number',
      accessor: 'phoneNumber',
      Cell: props => {
        if (!isNaN(props.value)) {
          return (
            <span className="number">{convertPhoneNumber(props.value)}</span>
          )
        } else {
          return props.value
        }
      },
      minWidth: 110,
    },
    {
      Header: 'Referrer Name',
      accessor: 'referrerName',
    },
    {
      Header: 'Creation Date',
      accessor: 'createdOn',
      width: 110,
      Cell: props => <span>{props.value && convertToMDY(props.value)}</span>,
      filterMethod: dateFilterMethod,
    },
    {
      Header: 'Modified Date',
      accessor: 'lastModified',
      width: 110,
      Cell: props => <span>{props.value && convertToMDY(props.value)}</span>,
      filterMethod: dateFilterMethod,
    },
  ]

  return (
    <div align="left">
      <ReactTable
        ref={table}
        filterable={filterable}
        data={referrals}
        columns={columns}
        className="-highlight"
        defaultPageSize={50}
        defaultSorted={sorted}
        defaultFiltered={filtered}
        onFilteredChange={fetchReferrals}
        style={{ fontSize: '10pt' }}
        defaultFilterMethod={(filter, row) =>
          row[filter.id] &&
          row[filter.id]
            .toString()
            .toLowerCase()
            .includes(filter.value.toString().toLowerCase())
        }
        getTheadProps={_ => {
          return {
            style: {
              fontWeight: 'bold',
              borderBottom: '1px solid #E5E5E5',
            },
          }
        }}
      />
    </div>
  )
})

export default ReferralTable
