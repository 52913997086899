import React, { Component } from 'react'
import { Query } from 'react-apollo'
import {
  ErrorMessage,
  Flex,
  LoadingMessage,
  SidebarText,
  Divider,
} from '../../components'

import { COUNT_ISSUES } from './queries'

class IssueSidebar extends Component {
  render() {
    const show = true
    const { statuses, totalCount } = this.props
    return (
      <Flex
        column
        grow={0}
        shrink={0}
        overflow="hidden"
        p="10px"
        style={{
          width: show ? '15.38%' : 0,
          borderRight: `${show ? '1px' : 0} ${'#aaaaaa'} solid`,
          maxWidth: show ? '200px' : 0,
          overflowX: show ? undefined : 'hidden',
          flexShrink: 0,
          textAlign: 'left',
          backgroundColor: '#e5e5e5',
        }}
      >
        {
          <Flex column justify="space-between">
            <SidebarText title="Summary View" />
            <Divider />
            <SidebarText title="Total Issues:" value={totalCount} />
            {statuses.map(status => (
              <Query
                key={status}
                query={COUNT_ISSUES}
                variables={{
                  filter: {
                    issueStatus: status,
                    issueType: 'ATTENDANCE',
                  },
                }}
              >
                {({ loading, error, data }) => {
                  if (loading) return <LoadingMessage />
                  if (error) return <ErrorMessage error={error} />
                  return (
                    <SidebarText
                      title={`${status} Issues:`}
                      value={data.countIssues}
                    />
                  )
                }}
              </Query>
            ))}
          </Flex>
        }
      </Flex>
    )
  }
}

export default IssueSidebar
