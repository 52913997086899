import gql from 'graphql-tag'

export const GET_PAYMENTS = gql`
  query getPayments($filter: Filters!) {
    payments(filter: $filter) {
      id
      method
      payee
      identification
      createdOn
      dateReceived
      amount
      invoice {
        invoiceNumber
        status
        price
        balance
        createdOn
        issueDate
        familyAccount {
          id
          primaryFamilyMember {
            id
            firstName
            lastName
          }
        }
        responsibleEmployee {
          id
          firstName
          lastName
        }
      }
    }
  }
`

export const GET_PAYMENT = gql`
  query getPayment($filter: Filters!) {
    payments(filter: $filter) {
      id
      createdOn
      identification
      dateReceived
      amount
      method
      payee
      note
      invoice {
        invoiceNumber
        issueDate
        familyAccount {
          id
          balance
          primaryFamilyMember {
            id
            firstName
            lastName
            email
            phoneNumber
          }
          customerProfile
          customerId
        }
        balance
        price
        summary
      }
    }
  }
`

export const CREATE_INVOICE = gql`
  mutation createInvoice($input: CreateInvoiceInput!) {
    createInvoice(input: $input) {
      id
      invoiceNumber
    }
  }
`

export const GET_PAYMENT_METHODS = gql`
  query getPaymentMethods {
    __type(name: "PaymentMethod") {
      enumValues {
        name
      }
    }
  }
`

export const GET_CLASSROOM = gql`
  query getCourse($filter: Filters!, $filter2: Filters) {
    courses(filter: $filter) {
      classrooms(filter: $filter2) {
        id
        status
        code
        title
        startDate
        endDate
        centerLocation {
          id
          name
        }
        teachingStyle
        earlyBirdtuitionCents
        tuitionCents
      }
    }
  }
`

export const GET_FAMILY_STUDENTS = gql`
  query getFamilyStudents($id: ID!) {
    familyAccount(id: $id) {
      students {
        id
        firstName
        lastName
        enrollments {
          classroom {
            code
          }
        }
      }
    }
  }
`

export const CREATE_ITEM = gql`
  mutation createItem($input: CreateItemInput!) {
    createItem(input: $input) {
      id
    }
  }
`

export const UPDATE_ITEM = gql`
  mutation updateItem($id: ID!, $input: UpdateItemInput!) {
    updateItem(id: $id, input: $input) {
      id
    }
  }
`

export const ARCHIVE_ITEM = gql`
  mutation archiveItem($id: ID!) {
    archiveItem(id: $id) {
      id
    }
  }
`
// ZLI
export const ARCHIVE_PAYMENT = gql`
  mutation archivePayment($id: ID!) {
    archivePayment(id: $id) {
      id
    }
  }
`
export const GET_DISCOUNT = gql`
  query getDiscount {
    discounts {
      id
      label
      amount
    }
  }
`
export const UPDATE_FAMILY_ACCOUNT = gql`
  mutation updateFamilyAccount($id: ID!, $input: UpdateFamilyAccountInput!) {
    updateFamilyAccount(id: $id, input: $input) {
      id
    }
  }
`
export const CREATE_PAYMENT = gql`
  mutation createPayment($input: CreatePaymentInput!) {
    createPayment(input: $input) {
      id
    }
  }
`

export const UPDATE_PAYMENT = gql`
  mutation updatePayment($id: ID!, $input: UpdatePaymentInput!) {
    updatePayment(id: $id, input: $input) {
      id
    }
  }
`

export const GET_OFFERS = gql`
  query getOffers {
    offers {
      id
      name
      offerType
      unitPrice
    }
  }
`
export const CREATE_ENROLLMENT = gql`
  mutation enrollStudent($input: CreateEnrollmentInput!) {
    createEnrollment(input: $input) {
      id
    }
  }
`

export const UPDATE_INVOICE = gql`
  mutation updateInvoice($id: ID!, $input: UpdateInvoiceInput!) {
    updateInvoice(id: $id, input: $input) {
      id
    }
  }
`

export const CREATE_CREDIT = gql`
  mutation createCredit($input: CreateCreditInput!) {
    createCredit(input: $input) {
      id
      creditNumber
    }
  }
`
