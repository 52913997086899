import React, { Component } from 'react'
import gql from 'graphql-tag'
import { Mutation, Query } from 'react-apollo'
import { Redirect } from 'react-router-dom'

import {
  Button,
  ChooseStudentPanel,
  ErrorMessage,
  Flex,
  LoadingMessage,
  Paper,
  SnackbarNotification,
  Text,
  withStyles,
} from '../../components'

const styles = theme => ({
  root: {
    textAlign: 'left',
    margin: 'auto',
    width: '67%',
    minWidth: '600px',
    maxWidth: '1000px',
  },
  display1: {
    textAlign: 'left',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  expansionDetail: {
    overflow: 'visible',
  },
})
const CREATE_ENROLLMENT = gql`
  mutation enrollStudent($input: CreateEnrollmentInput!) {
    createEnrollment(input: $input) {
      id
    }
  }
`
const GET_CLASSROOM = gql`
  query getClassroom($id: ID!) {
    classroom(id: $id) {
      id
      code
      enrollments {
        student {
          id
        }
      }
    }
  }
`

class EnrollStudentView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      redirect: false,
      redirectTo: '',
      selectedStudent: null,
      snackbar: {
        open: false,
        message: '',
        messageType: '',
      },
    }
  }

  returnToClassTable = () => {
    const classroomId = this.props.match.params[0]
    this.setState({
      ...this.state,
      redirect: true,
      redirectTo: `/classroom/${classroomId}`,
    })
  }

  handleChange = event => {
    const { name, value } = event.target
    this.setState({
      [name]: value,
    })
  }

  render() {
    const { redirect, redirectTo, selectedStudent, snackbar } = this.state
    const classroomId = this.props.match.params[0]
    const { classes } = this.props

    if (redirect) {
      return <Redirect push to={redirectTo} />
    }

    const handleSubmission = async CEmutation => {
      await CEmutation({
        variables: {
          input: {
            studentId: selectedStudent && selectedStudent.id,
            classroomId: classroomId,
            status: 'PAID',
          },
        },
      })
      this.returnToClassTable()
    }

    return (
      <Flex column grow={1} className={classes.root}>
        <br />
        <Query query={GET_CLASSROOM} variables={{ id: classroomId }}>
          {({ loading, error, data }) => {
            if (loading) return <LoadingMessage />
            if (error) return <ErrorMessage error={error} />

            const enrolledStudentIds = data.classroom.enrollments.map(
              enrollment => enrollment.student.id
            )

            return (
              <Paper>
                <Text
                  className={classes.display1}
                  variant="h6"
                  style={{ textAlign: 'center' }}
                >
                  {`Enroll Student in ${data.classroom.code}`}
                </Text>

                <Flex
                  column
                  p="20px"
                  style={{
                    align: 'center',
                    flexGrow: 1,
                  }}
                >
                  <ChooseStudentPanel
                    display
                    selectedStudent={selectedStudent}
                    onChange={this.handleChange}
                    ignoreList={enrolledStudentIds}
                  />
                </Flex>

                <Flex direction={'row-reverse'} p="10px">
                  <Mutation mutation={CREATE_ENROLLMENT}>
                    {CEFunction => (
                      <Button
                        onClick={() => {
                          if (!selectedStudent) {
                            this.setState({
                              snackbar: {
                                open: true,
                                message: 'Please select a student',
                                messageType: 'error',
                              },
                            })
                          } else {
                            if (
                              window.confirm(
                                'Is this the student you want to enroll?'
                              )
                            ) {
                              handleSubmission(CEFunction)
                            }
                          }
                        }}
                      >
                        Submit
                      </Button>
                    )}
                  </Mutation>
                  <Button
                    color="secondary"
                    className={classes.button}
                    onClick={() => {
                      if (
                        window.confirm(
                          'Do you want to return to the class table?'
                        )
                      ) {
                        this.returnToClassTable()
                      }
                    }}
                    style={{ marginRight: '10px' }}
                  >
                    Cancel
                  </Button>
                </Flex>

                <SnackbarNotification
                  open={snackbar.open}
                  handleClose={() => {
                    this.setState({
                      snackbar: {
                        ...snackbar,
                        open: false,
                      },
                    })
                  }}
                  message={snackbar.message}
                  messageType={snackbar.messageType}
                />
              </Paper>
            )
          }}
        </Query>
        <br />
      </Flex>
    )
  }
}
export default withStyles(styles)(EnrollStudentView)
