import React, { Component } from 'react'
import { Query } from 'react-apollo'
import {
  ErrorMessage,
  Flex,
  LoadingMessage,
  SnackbarNotification,
} from '../../components'
import DetailedReconcilePage from './DetailedReconcilePage'
import { GET_RECONCILIATIONS } from './queries'
import ReconcileSidebar from './ReconcileSidebar'
import ReconcileTable from './ReconcileTable'

export default class ReconcileTableView extends Component {
  constructor(props) {
    super(props)

    this.state = {
      snackbar: {
        open: false,
        message: '',
        messageType: '',
        windowReload: false,
        sorted: [],
        filtered: [],
      },
      queryFilter: {},
      sidebar: {
        startDate: new Date().toISOString(),
        endDate: new Date().toISOString(),
        showReconciled: false,
      },
    }
  }

  onQueryFilterChange = (queryFilter, sidebar) => {
    this.setState({ queryFilter, sidebar })
  }

  onFilteredChange = filtered => {
    this.setState({
      filtered,
    })
  }

  onSortedChange = sorted => {
    this.setState({
      sorted,
    })
  }

  openSnack = (message, messageType, windowReload) => {
    const { snackbar } = this.state
    const newSnackbar = {
      ...snackbar,
      open: true,
      messageType,
      message,
      windowReload,
    }
    this.setState({ snackbar: newSnackbar })
  }
  rowClick = rowInfo => {
    rowInfo &&
      this.setState({
        detailVisible: true,
        selectedRecon: rowInfo.original,
      })
  }

  render() {
    const { snackbar, detailVisible, selectedRecon } = this.state
    if (detailVisible) {
      return (
        <DetailedReconcilePage
          onClose={() => this.setState({ detailVisible: false })}
          reconciliation={selectedRecon}
        />
      )
    }
    return (
      <Query
        query={GET_RECONCILIATIONS}
        fetchPolicy={'no-cache'}
        variables={{ filter: { deposited: false, ...this.state.queryFilter } }}
      >
        {({ data, loading, error, refetch }) => {
          if (loading) return <LoadingMessage />
          if (error) return <ErrorMessage error={error} />

          return (
            <Flex>
              <ReconcileSidebar
                onQueryFilterChange={this.onQueryFilterChange}
                queryFilter={this.state.queryFilter}
                sidebar={this.state.sidebar}
              />
              <Flex align="left" column grow={1}>
                <ReconcileTable
                  recons={data.reconciliations}
                  sorted={this.state.sorted}
                  filtered={this.state.filtered}
                  onFilteredChange={this.onFilteredChange}
                  onSortedChange={this.onSortedChange}
                  onRowClick={this.rowClick}
                />
              </Flex>
              <SnackbarNotification
                open={snackbar.open}
                handleClose={() => {
                  this.setState({
                    snackbar: {
                      ...snackbar,
                      open: false,
                    },
                  })
                  if (snackbar.windowReload) refetch()
                }}
                message={snackbar.message}
                messageType={snackbar.messageType}
              />
            </Flex>
          )
        }}
      </Query>
    )
  }
}
