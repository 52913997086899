import React, { Component } from 'react'
import { Mutation, withApollo } from 'react-apollo'
import Composer from 'react-composer'
import { Redirect } from 'react-router-dom'

import moment from 'moment'
import TaskForm from './TaskForm'
import {
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Flex,
  OpportunityForm,
  SnackbarNotification,
  StudentForm,
  Paper,
  Text,
} from '../../components'
import Cookies from 'js-cookie'
import { idName } from '../../config'
import { CREATE_ACTIVITY } from './queries'
import { UPDATE_OPPORTUNITY, GET_CENTER_LOCATION } from '../../queries'

class AddTaskPanels extends Component {
  constructor(props) {
    super(props)
    const defaultDueDate = moment
      .tz(new Date(), 'America/Los_Angeles')
      .add(3, 'days')
    const { student, opportunity } = this.props
    const employeeId = Cookies.get(idName)
    this.state = {
      redirect: false,
      redirectTo: '',
      task: {
        priority: 'MED',
        method: 'CALL',
        type: 'OTHER',
        responsibleEmployeeId: employeeId,
        endDate: defaultDueDate,
        notes: opportunity ? opportunity.notes : '',
        classroom: '',
        ...this.props.defaultState,
      },
      opportunity: opportunity,
      snackbar: {
        open: false,
        message: '',
        messageType: '',
      },
      student: student || (opportunity && opportunity.student),
      logged: this.props.logged,
      employeeId,
    }
  }

  toggleLogged = event => {
    this.setState({
      logged: event.target.checked,
    })
  }

  returnToTaskTable = () => {
    this.setState({
      redirect: true,
      redirectTo: '/task',
    })
  }

  handleTaskChange = async event => {
    const { client } = this.props
    const { name, value } = event.target
    const taskState = { task: { ...this.state.task, [name]: value } }
    if (name === 'centerLocation') {
      const {
        data: {
          centerLocation: { managers },
        },
      } = await client.query({
        query: GET_CENTER_LOCATION,
        variables: {
          id: value,
        },
      })
      if (managers.length > 0) {
        taskState.task.responsibleEmployeeId = managers[0]
      }
    }
    this.setState(taskState)
  }

  render() {
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirectTo} />
    }

    const { classes } = this.props
    const { employeeId, opportunity, snackbar, student, task } = this.state
    const {
      endDate,
      method,
      type,
      priority,
      description,
      responsibleEmployeeId,
    } = this.state.task

    const handleSubmission = async (CreateActivityMutation, OpporMutation) => {
      await CreateActivityMutation({
        variables: {
          input: {
            method: method,
            type: type,
            startDate: new Date(),
            endDate: new Date(endDate),
            priority: priority,
            description: description,
            responsibleEmployeeId: responsibleEmployeeId,
            resolvedDate: this.state.logged ? Date() : null,
            contactId: student && student.familyAccount.primaryFamilyMember.id,
            studentId: student
              ? student.id
              : opportunity && opportunity.student.id,
            opportunityId: opportunity && opportunity.id,
            createdById: employeeId,
          },
        },
      })
      if (opportunity) {
        await OpporMutation({
          variables: {
            id: opportunity.id,
            input: {
              stage: this.state.task.stage,
              lastContacted: new Date().toISOString(),
            },
          },
        })
      }
      this.returnToTaskTable()
    }

    return (
      <Flex column grow={1} className={classes.root}>
        <br />
        <Text className={classes.display1} variant="h4">
          {`${this.state.logged ? 'Log a Task' : 'Create New Task'} ${
            student ? `for ${student.firstName} ${student.lastName}` : ''
          }`}
        </Text>
        <br />
        <Paper>
          <Accordion defaultExpanded>
            <AccordionSummary title="Task Information" />
            <AccordionDetails>
              <TaskForm
                task={task}
                opportunity={this.state.opportunity}
                handleChange={this.handleTaskChange}
                toggleLogged={this.toggleLogged}
              />
            </AccordionDetails>
          </Accordion>
          {opportunity && (
            <Accordion defaultExpanded>
              <AccordionSummary title="Opportunity Detail (Read Only)" />
              <AccordionDetails>
                <OpportunityForm
                  readOnly
                  showStatus
                  showCreatedBy
                  opportunity={opportunity}
                  handleChange={() => {}}
                />
              </AccordionDetails>
            </Accordion>
          )}
          {student && (
            <Accordion defaultExpanded={!opportunity}>
              <AccordionSummary title="Student Detail (Read Only)" />
              <AccordionDetails>
                <StudentForm
                  readOnly
                  student={student}
                  handleChange={() => {}}
                />
              </AccordionDetails>
            </Accordion>
          )}

          <Flex direction={'row-reverse'} p="10px">
            <Composer
              components={[
                <Mutation mutation={CREATE_ACTIVITY}>
                  {function() {
                    return
                  }}
                </Mutation>,
                <Mutation mutation={UPDATE_OPPORTUNITY}>
                  {function() {
                    return
                  }}
                </Mutation>,
              ]}
            >
              {([CreateActivityFunction, OpporFunction]) => (
                <Button
                  color="primary"
                  onClick={() => {
                    if (
                      !this.state.task.method ||
                      (!this.state.logged && !this.state.task.priority)
                    ) {
                      this.setState({
                        snackbar: {
                          open: true,
                          message: 'At least one required field is missing',
                          messageType: 'error',
                        },
                      })
                    } else {
                      if (window.confirm('Are you sure you want to submit?')) {
                        handleSubmission(CreateActivityFunction, OpporFunction)
                      }
                    }
                  }}
                >
                  Submit
                </Button>
              )}
            </Composer>
            <Button
              color="secondary"
              className={classes.button}
              onClick={() => {
                this.returnToTaskTable()
              }}
              style={{ marginRight: '10px' }}
            >
              Cancel
            </Button>
          </Flex>
        </Paper>
        <SnackbarNotification
          open={snackbar.open}
          handleClose={() => {
            this.setState({
              snackbar: {
                ...snackbar,
                open: false,
              },
            })
          }}
          message={snackbar.message}
          messageType={snackbar.messageType}
        />
      </Flex>
    )
  }
}

export default withApollo(AddTaskPanels)
