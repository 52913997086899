import moment from 'moment-timezone'

const customformatTime = (start, end) => {
  return `${moment(start).format('LT')} to ${moment(end).format('LT')}`
}

export const counselingSummaryEmailTemplate = data => {
  const {
    startDateTime,
    endDateTime,
    resolution,
    studentName,
    employeeName,
  } = data
  return `
  <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html>
  <head>
    <meta name="viewport" content="width=device-width,initial-scale=1">
    <meta http-equiv="Content-Type" content="text/html charset=UTF-8" />
    <title>Trial Confirmation</title>
      <style>
        html{font-size:62.5%}
        body
        {
          font-family: "Verdana","Geneva","Helvetica",sans-serif;
        }
        #main_container{
          background:white;
          text-align: left;
          font-size:1.2em;
          padding: 1em;
          width:100%;
          max-width: 60em;
          height:auto;
          
        }
        
        p{
          position:relative;
          top:1.2em;
          /*text-indent:25px;*/
        }
        p.opening{
          color:#009ad6;
        }
        
        .closing{
          position:relative;
          top:2.8em;
          line-height: 1.9em;
        }
        
        .footer{
          position:relative;
          top:8em;
          line-height:1.4em;
          font-size:0.8em;
          text-align:center;
        }
        .footer img{ /*currently unused since no footer graphic*/
          width:50%;
          max-width:38em;
        }
         
      </style>
  </head>
  <body><div id="main_container">Counseling Department,
      <p>A meeting was held for ${studentName} from ${customformatTime(
    startDateTime,
    endDateTime
  )} on ${moment(startDateTime).format(
    'LL'
  )} by ${employeeName}. The meeting resulted in the following resolution:
    </p>
      <p>
        ${resolution}
      </p>
             <p> Regards,</p><br/><span class="closing">${employeeName}</span>
          </div><div class="footer"><br/>Copyright &copy; 2019 Ardent Academy For Gifted Youth. All Rights Reserved.</div>
  </body>
</html>
    `
}
