import React from 'react'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '../../components'

import { convertToMDY, formatTime } from '../../utils/datetime'

const TrialHistory = props =>
  props.trial.length > 0 ? (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            {[
              'Status',
              'Class',
              'Date',
              'Start Time',
              'Location',
              'Student Name',
              'Phone Number',
            ].map(cell => (
              <TableCell>{cell}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.trial.map(trial => {
            const lesson = trial.toLesson
            const student = trial.opportunity.student
            return (
              <TableRow
                hover
                key={trial.id}
                onClick={() => props.onClick(trial.id)}
              >
                <TableCell>{trial.status}</TableCell>
                <TableCell>{lesson.classroom.code}</TableCell>
                <TableCell>{convertToMDY(lesson.startOn)}</TableCell>
                <TableCell>{formatTime(new Date(lesson.startOn))}</TableCell>
                <TableCell>{lesson.classroom.centerLocation.name}</TableCell>
                <TableCell>
                  {`${student.firstName} ${student.lastName}`}
                </TableCell>
                <TableCell>{student.phoneNumber}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </div>
  ) : (
    <div>
      <p variant="h4">There are no trials asscociated with this student</p>
    </div>
  )

export default TrialHistory
