import gql from 'graphql-tag'

export const ARCHIVE_FAMILY_ACCOUNT = gql`
  mutation archiveFamilyAccount($id: ID!) {
    archiveFamilyAccount(id: $id) {
      id
    }
  }
`

export const GET_LANGUAGE_PREFERENCE_OPTIONS_QUERY = gql`
  query getLanguagePreferenceOptions {
    __type(name: "LanguagePreference") {
      enumValues {
        name
      }
    }
  }
`

export const COUNT_FAMILY_ACCOUNTS = gql`
  query countFamilyAccounts($filter: Filters) {
    countFamilyAccounts(filter: $filter)
  }
`

export const COUNT_FAMILY_MEMBERS = gql`
  query countFamilyMembers($filter: Filters) {
    countFamilyMembers(filter: $filter)
  }
`

export const GET_FAMILY_MEMBERS = gql`
  query getFamilyMemberss($filter: Filters, $sort: Sorts, $page: Pagination) {
    familyMembers(filter: $filter, sort: $sort, page: $page) {
      id
      createdOn
      firstName
      lastName
      relation
      preferredLanguage
      phoneNumber
      email
      lastLogin
      familyAccount {
        status
        primaryFamilyMember {
          id
        }
        location {
          id
          city
        }
      }
    }
  }
`

export const GET_FAMILY_ACCOUNTS = gql`
  query getFamilyAccounts($filter: Filters, $sort: Sorts, $page: Pagination) {
    familyAccounts(filter: $filter, sort: $sort, page: $page) {
      id
      createdOn
      status
      primaryFamilyMember {
        id
        firstName
        lastName
        relation
        preferredLanguage
        phoneNumber
        email
      }
      location {
        id
        city
      }
    }
  }
`

export const GET_FAMILY_ACCOUNT = gql`
  query getFamilyMember($id: ID!) {
    familyMember(id: $id) {
      familyAccount {
        id
        status
        location {
          id
          street
          city
          zip
          state
          country
        }
        centerLocation {
          id
        }
        primaryFamilyMember {
          id
          firstName
          lastName
          relation
          email
          phoneNumber
          occupation
          preferredLanguage
          educationLevel
        }
        familyMembers {
          id
          firstName
          lastName
          relation
          email
          phoneNumber
          occupation
          preferredLanguage
          educationLevel
        }
        students {
          id
          firstName
          lastName
          grade
          enrollmentStatus
          enrollments(sort: { createdOn: -1 }) {
            id
            classroom {
              id
            }
          }
        }
      }
    }
  }
`

export const GET_TEMP_TOKEN = gql`
  mutation getTempToken($userId: ID!, $userType: String!) {
    generateTemporaryToken(userId: $userId, userType: $userType) {
      token
      errorMessage
    }
  }
`
