import React from 'react'
import { sideBarStyles } from '../../utils/style'
import {
  Button,
  Divider,
  Flex,
  SidebarText,
  withStyles,
} from '../../components'

const AssessmentSidebar = props => {
  const { assessments, classes } = props
  const statuses = ['SCHEDULED', 'COMPLETED', 'ABANDONED']

  const countStatuses = status => {
    const assessmentsCount = assessments.reduce((accumulator, assessment) => {
      return accumulator + (assessment.status === status)
    }, 0)
    return assessmentsCount
  }

  return (
    <Flex
      column
      grow={1}
      shrink={0}
      overflow="hidden"
      p="10px"
      style={{
        width: '15.38%',
        borderRight: '1px #aaaaaa solid',
        maxWidth: '200px',
        overflowX: undefined,
        flexShrink: 0,
        textAlign: 'left',
        backgroundColor: '#e5e5e5',
      }}
    >
      <Flex column grow={1} justify="space-between">
        <Flex column>
          <Button
            aria-label="ArchieveQualify"
            color="secondary"
            onClick={props.archiveAssessments}
          >
            Archive Assessment
          </Button>
          <SidebarText
            classes={{ root: classes.headline }}
            title="Detail View"
          />
          <Divider />
          <SidebarText
            classes={{ root: classes.subheadingOne }}
            title="Total Assessments:"
            value={assessments.length}
          />
          {statuses.map(status => {
            return (
              <SidebarText
                classes={{ root: classes.subheadingOne }}
                key={status}
                title={`${status}:`}
                value={countStatuses(status)}
              />
            )
          })}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default withStyles(sideBarStyles)(AssessmentSidebar)
