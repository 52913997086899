// locaton date is the date at the location where a class is offered
// currently, we assume all classes are offered at PST
// TODO (ZLI): make it a generic module for all projects
import moment from 'moment-timezone'

const DEFAULT_TIMEZONE = 'America/Los_Angeles'

const locationDate = localDate => {
  const centerLocationOffset = -8 // TODO (DRLI): Hard code for PST, replace with local office offset and day light saving
  return new Date(
    localDate.getTime() +
      localDate.getTimezoneOffset() * 60000 +
      centerLocationOffset * 3600000
  )
}

export const formatDateHMA = (start, end) => {
  return `${moment(start).format('hh:mm a')} - ${moment(end).format('hh:mm a')}`
}

export const formatMMDD = day => {
  return moment(day).format('MM/DD')
}

export const formatDayDMY = day => {
  return moment(day).format('dddd, MMMM Do YYYY')
}

export const convertToMDY = utcDate => {
  const localDate = moment.tz(utcDate, DEFAULT_TIMEZONE)
  if (!localDate) return null

  return localDate.format('L')
}

export const convertToHMA = utcDate => {
  const localDate = moment.tz(utcDate, DEFAULT_TIMEZONE)
  if (!localDate) return null

  return localDate.format('LT')
}
export const convertToDateTimeString = utcDate => {
  const localDate = moment.tz(utcDate, DEFAULT_TIMEZONE)
  if (!localDate) return null

  return localDate.format('LLLL')
}

export const convertToYMD = utcDate => {
  const localDate = moment.tz(utcDate, DEFAULT_TIMEZONE)
  if (!localDate) return null

  return localDate.format().slice(0, 10)
}

export const convertToYMDHM = utcDate => {
  const localDate = moment.tz(utcDate, DEFAULT_TIMEZONE)
  if (!localDate) return null

  return localDate.format().slice(0, 10) + ' ' + localDate.format('LT')
}

export const formatBirthday = utcDate => {
  const localDate = new Date(utcDate)
  if (!localDate) return null

  let month = '' + (localDate.getUTCMonth() + 1)
  let day = '' + localDate.getUTCDate()
  let year = '' + localDate.getUTCFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day
  while (year.length < 4) year = '0' + year

  return [year, month, day].join('-')
}

export const formatYMDUTC = utcDate => {
  const localDate = new Date(utcDate)
  if (!localDate) return null

  let month = '' + (localDate.getUTCMonth() + 1)
  let day = '' + localDate.getUTCDate()
  let year = '' + localDate.getUTCFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day
  while (year.length < 4) year = '0' + year

  return [year, month, day].join('-')
}

export const convertToDateString = utcDate => {
  const localDate = new Date(utcDate)
  if (!localDate) return null

  return locationDate(localDate).toDateString()
}

export const convertToCenterDate = utcDate => {
  const localDate = new Date(utcDate)
  if (!localDate) return null
  return locationDate(localDate)
}

export const formatTime = utcDate => {
  const localDate = moment.tz(utcDate, DEFAULT_TIMEZONE)
  if (!localDate) return null

  return localDate.format('hh:mm A')
}

// formatTimeRange takes two Date objects and returns a time range string.
// (e.g. "4:20 PM — 4:40 PM")
export const formatTimeRange = (utcStartDate, utcEndDate) => {
  return formatTime(utcStartDate) + ' - ' + formatTime(utcEndDate)
}

export const formatDay = utcDate => {
  const localDate = moment.tz(utcDate, DEFAULT_TIMEZONE)
  switch (localDate.day()) {
    case 0:
      return 'SUN'
    case 1:
      return 'MON'
    case 2:
      return 'TUE'
    case 3:
      return 'WED'
    case 4:
      return 'THU'
    case 5:
      return 'FRI'
    case 6:
      return 'SAT'
    default:
      return ''
  }
}

export const formatDayTimeRange = (utcStartDate, utcEndDate) => {
  const dayOfWeek = formatDay(utcStartDate)
  const timeRange = formatTimeRange(utcStartDate, utcEndDate)
  return dayOfWeek + ' ' + timeRange
}

export const dateFilterMethod = (filter, row) =>
  row[filter.id] &&
  convertToMDY(row[filter.id])
    .toLowerCase()
    .startsWith(filter.value)
