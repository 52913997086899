import React, { useState, useRef } from 'react'
import moment from 'moment-timezone'

import { Droppable, Draggable } from 'react-beautiful-dnd'

import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { makeStyles } from '@mui/styles'
import { StatusIcon } from '../Attendance/Icons'

import { ArrowDropDownIcon, EditIcon } from '../../components'

import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Divider,
  Grow,
  MenuItem,
  MenuList,
  Popper,
  Paper,
  Typography,
} from '@mui/material'

const DoTW = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

const useStyles = makeStyles(() => ({
  iconBtn: {
    width: '40px',
    height: '32.5px',
  },
  icon: {
    width: '22px',
    height: '22px',
  },
}))

const formatDateBetweenString = tenthDay => {
  return `${moment(tenthDay).format('MMMM')}`
}

const SessionButton = props => {
  const { tutors, handleChangeTutor, session } = props

  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = e => {
    if (anchorRef.current && anchorRef.current.contains(e.target)) {
      return
    }
    setOpen(false)
  }

  const tutorName = session.tutor ? session.tutor.firstName : 'UNASSIGNED'
  const tutorFirstName = session.tutor ? session.tutor.firstName : 'UNASSIGNED'
  const tutorLastName = session.tutor ? session.tutor.firstName : 'UNASSIGNED'
  return (
    <>
      <ButtonGroup
        style={{ width: '180px', height: '35px', zIndex: 1 }}
        variant="text"
        ref={anchorRef}
        aria-label="split button"
      >
        <Button style={{ width: '140px', zIndex: 0 }}>{tutorName}</Button>
        <Button
          style={{ width: '40px' }}
          color="primary"
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        style={{
          zIndex: 3,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {tutors.map(option => (
                    <MenuItem
                      key={`${option.id}-${session.id}`}
                      value={option}
                      selected={
                        option.firstName === tutorFirstName &&
                        option.lastName === tutorLastName
                      }
                      onClick={e => {
                        //don't need to update state since it's an object
                        //and we don't want to rerender
                        session.tutor = option
                        handleClose(e)
                        handleChangeTutor(session.id, option)
                      }}
                    >
                      {option.firstName} {option.lastName}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

const DraggableCalender = props => {
  const {
    tutors,
    tutorSessions,
    dates,
    handleSelectSession,
    handleChangeTutor,
    handleOpenAttendeeDialog,
  } = props
  const classes = useStyles()

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    background: isDragging && 'lightgreen',
    width: '180px',
    maxWidth: '180px',
    height: '100px',
    maxHeight: '100px',
    margin: '10px',
    alignSelf: 'center',
    justifySelf: 'center',
    ...draggableStyle,
  })

  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'lightblue' : 'lightgrey',
    padding: 5,
    minHeight: '160px',
    maxWidth: '210px',
    minWidth: '210px',
    maxHeight: '160px',
    flexDirection: 'column',
    alignItems: 'space-evenly',
    justifyContent: 'space-evenly',
    overflowY: 'auto',
  })

  return (
    <Box
      sx={{
        display: 'flex',
        width: '1600px',
        height: '640px',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
      }}
    >
      {dates.map(date => (
        <Droppable key={date} droppableId={date.toISOString()}>
          {(provided, snapshot) => (
            <Box
              sx={{
                maxWidth: '220px',
                minWidth: '220px',
                marginBottom: '10px',
                border: '1px solid #f1f1f1',
                alignItems: 'space-evenly',
                justifyContent: 'space-evenly',
              }}
            >
              {provided.placeholder}
              <Paper square variant={'outlined'}>
                <Typography
                  sx={{
                    color: 'black',
                    textAlign: 'center',
                    fontWeight: 'bold',
                  }}
                >
                  {moment(date).format('MM/DD')}
                </Typography>
              </Paper>
              <Box
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {tutorSessions.get(date.toISOString()).map((item, index) => {
                  return (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          {provided.placeholder}
                          <Paper
                            variant={'outlined'}
                            style={{ width: '180px', height: '10  0px' }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                height: '65px',
                              }}
                            >
                              <Box
                                sx={{
                                  flexDirection: 'column',
                                  width: '139px',
                                  height: '65px',
                                  justifyContent: 'space-evenly',
                                }}
                              >
                                <Typography
                                  sx={{
                                    textAlign: 'center',
                                    fontSize: 16,
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {`Sesson ${item.order
                                    .toString()
                                    .padStart(2, '0')}`}
                                </Typography>
                                <Typography
                                  sx={{ textAlign: 'center', fontSize: 14 }}
                                >
                                  {item.startDateTime
                                    ? new Date(
                                        item.startDateTime
                                      ).toLocaleString('en-US', {
                                        hour: 'numeric',
                                        minute: 'numeric',
                                        hour12: true,
                                      })
                                    : 'N/A'}{' '}
                                  {`for ${item.duration} min`}
                                </Typography>
                              </Box>
                              <Divider
                                color="lightgray"
                                orientation="vertical"
                              />
                              <ButtonGroup
                                style={{ width: '40px', height: '65px' }}
                                variant="text"
                                aria-label="split button"
                                orientation="vertical"
                              >
                                <Button
                                  className={classes.iconButton}
                                  style={{ width: '40px', height: '32.5px' }}
                                  value={JSON.stringify({
                                    ...item,
                                    droppableId: date.toISOString(),
                                  })}
                                  color="warning"
                                  variant="text"
                                  aria-label="attendance-tutorsession-btn"
                                  onClick={handleOpenAttendeeDialog}
                                >
                                  <StatusIcon status={item.status} />
                                </Button>
                                <Button
                                  className={classes.iconButton}
                                  value={JSON.stringify({
                                    ...item,
                                    droppableId: date.toISOString(),
                                  })}
                                  color="primary"
                                  variant="text"
                                  aria-label="edit-tutorsession-btn"
                                  onClick={handleSelectSession}
                                >
                                  <EditIcon className={classes.icon} />
                                </Button>
                              </ButtonGroup>
                            </Box>
                            <Divider color="lightgray" />
                            <SessionButton
                              tutors={tutors}
                              session={item}
                              handleChangeTutor={handleChangeTutor}
                            />
                          </Paper>
                        </Box>
                      )}
                    </Draggable>
                  )
                })}
              </Box>
            </Box>
          )}
        </Droppable>
      ))}
    </Box>
  )
}

const TutorStudentSchedule = props => {
  const {
    student,
    tutors,
    calendarItems,
    handleNextMonth,
    handlePreviousMonth,
    selectedMonth,
    handleSelectSession,
    handleChangeTutor,
    handleOpenAttendeeDialog,
  } = props

  const firstName = student ? student.firstName : ''
  const lastName = student ? student.lastName : ''
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          width: '1500px',
          height: '100px',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Typography
          color="primary"
          style={{ fontSize: '20px', fontWeight: 'bold' }}
        >
          Tutoring Schedule for {firstName} {lastName}
        </Typography>
        <ButtonGroup
          size={'large'}
          aria-label={'large-week-button'}
          style={{ marginTop: '10px', marginLeft: 'auto' }}
        >
          <Button
            color={'primary'}
            aria-label={'previous-week'}
            onClick={handlePreviousMonth}
          >
            <NavigateBeforeIcon />
          </Button>
          <Button color={'primary'}>
            {formatDateBetweenString(selectedMonth[10])}
          </Button>
          <Button
            color={'primary'}
            aria-label={'next-week'}
            onClick={handleNextMonth}
          >
            <NavigateNextIcon />
          </Button>
        </ButtonGroup>
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '1600px',
          height: '60px',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {DoTW.map(day => (
          <Paper
            key={day}
            square
            variant={'outlined'}
            style={{
              padding: 5,
              minHeight: '30px',
              minWidth: '210px',
              maxHeight: '30px',
              flexDirection: 'column',
              border: '1px solid #f1f1f1',
            }}
          >
            <Typography
              color="primary"
              sx={{
                textAlign: 'center',
                fontWeight: 'bold',
              }}
            >
              {day}
            </Typography>
          </Paper>
        ))}
      </Box>
      <DraggableCalender
        tutors={tutors}
        tutorSessions={calendarItems}
        dates={selectedMonth}
        handleChangeTutor={handleChangeTutor}
        handleSelectSession={handleSelectSession}
        handleOpenAttendeeDialog={handleOpenAttendeeDialog}
      />
    </>
  )
}

export default TutorStudentSchedule
