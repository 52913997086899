import React, { Component } from 'react'
import ReactTable from 'react-table'
import { convertToMDY, dateFilterMethod } from '../../utils/datetime'

class CreditTable extends Component {
  componentDidMount() {
    let checked = this.props.credits.map(() => {
      return false
    })
    this.props.updateChecked(checked)
  }

  handleSingleCheckboxChange = index => {
    const { checked, updateChecked, credits } = this.props
    let newChecked = checked
    newChecked[index] = checked[index] ? false : credits[index].id
    updateChecked(newChecked)
  }

  render() {
    const columns = [
      {
        Cell: row => (
          <input
            type="checkbox"
            checked={
              this.props.checked[row.index] === undefined
                ? false
                : this.props.checked[row.index]
            }
            onChange={() => {
              this.handleSingleCheckboxChange(row.index)
            }}
          />
        ),
        sortable: false,
        filterable: false,
        resizable: false,
        width: 28,
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: 100,
        Cell: props => {
          return (
            <span
              style={{
                color: props.value === 'OPEN' ? '#27AE60' : '#EB5757',
              }}
            >
              {props.value}
            </span>
          )
        },
        filterMethod: (filter, row) => {
          if (filter.value !== 'ALL') {
            return row[filter.id] === filter.value
          }
          return true
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: '100%' }}
            value={filter ? filter.value : 'all'}
          >
            <option value="ALL">All</option>
            <option value="OPEN">Open</option>
            <option value="PAID">Paid</option>
            <option value="VOID">Void</option>
            <option value="EXPIRED">Expired</option>
            <option value="USED">Used</option>
            <option value="REFUNDED">Refunded</option>
          </select>
        ),
      },
      {
        id: 'first',
        Header: 'First Name',
        accessor: row => row.familyAccount.primaryFamilyMember.firstName,
        //width: 100,
      },
      {
        id: 'last',
        Header: 'Last Name',
        accessor: row => row.familyAccount.primaryFamilyMember.lastName,
        //width: 100,
      },
      {
        id: 'employee',
        Header: 'Responsible Employee',
        accessor: row =>
          `${row.responsibleEmployee.firstName} ${row.responsibleEmployee.lastName}`,
      },
      {
        Header: 'Date',
        accessor: 'issueDate',
        Cell: props => <span>{props.value && convertToMDY(props.value)}</span>,
        width: 100,
        filterMethod: dateFilterMethod,
      },
      {
        Header: 'Credit Number',
        accessor: 'creditNumber',
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        Cell: props => <span>{`$${props.value.toFixed(2)}`}</span>,
      },
      {
        Header: 'Balance',
        accessor: 'balance',
        Cell: props => <span>{`$${props.value.toFixed(2)}`}</span>,
      },
    ]
    const { credits, sorted, filtered, onFilteredChange } = this.props
    return (
      <div align="left">
        <ReactTable
          filterable={true}
          data={credits}
          columns={columns}
          className="-highlight"
          defaultPageSize={25}
          defaultSorted={sorted}
          defaultFiltered={filtered}
          onFilteredChange={onFilteredChange}
          style={{ fontSize: '10pt' }}
          defaultFilterMethod={(filter, row) =>
            row[filter.id] !== null &&
            row[filter.id]
              .toString()
              .toLowerCase()
              .includes(filter.value.toString().toLowerCase())
          }
          getTrProps={(state, rowInfo, column) => {
            let background = '#ffffff'
            return {
              style: {
                background,
              },
            }
          }}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              onClick: (e, handleOriginal) => {
                if (column.Header !== undefined) {
                  this.props.onRowClick(rowInfo)
                }
                if (handleOriginal) {
                  handleOriginal()
                }
              },
            }
          }}
          getTheadProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                fontWeight: 'bold',
                borderBottom: '1px solid #E5E5E5',
              },
            }
          }}
        />
      </div>
    )
  }
}

export default CreditTable
