import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'

import { Divider, Flex, SidebarText, withStyles } from '../../components'

import { sideBarStyles } from '../../utils/style'

class LessonViewSidebar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      redirect: false,
      classId: '',
    }
  }

  updateClassId = event => {
    this.setState({ ...this.state, classId: event.target.value })
  }

  render() {
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirectTo} />
    }

    const { classes, show } = this.props

    return (
      <Flex
        column
        grow={0}
        shrink={0}
        overflow="hidden"
        p="10px"
        style={{
          width: show ? '15.38%' : 0,
          borderRight: `${show ? '1px' : 0} ${'#aaaaaa'} solid`,
          maxWidth: show ? '200px' : 0,
          overflowX: show ? undefined : 'hidden',
          flexShrink: 0,
          textAlign: 'left',
          backgroundColor: '#e5e5e5',
        }}
      >
        <Flex column grow={1} justify="space-between">
          <Flex column>
            <SidebarText
              classes={{
                root: classes.headline,
              }}
              title="Summary View"
            />
            <Divider />
          </Flex>
          {this.props.menu()}
        </Flex>
      </Flex>
    )
  }
}

export default withStyles(sideBarStyles)(LessonViewSidebar)
