import React, { Component } from 'react'
import ReactTable from 'react-table'
import { convertToYMDHM, dateFilterMethod } from '../../utils/datetime'

class PaymentTable extends Component {
  componentDidMount() {
    let checked = this.props.payments.map(() => {
      return false
    })
    this.props.updateChecked(checked)
  }

  handleSingleCheckboxChange = index => {
    const { checked, updateChecked, payments } = this.props
    let newChecked = checked
    newChecked[index] = checked[index] ? false : payments[index].id
    updateChecked(newChecked)
  }

  render() {
    const columns = [
      {
        Cell: row => (
          <input
            type="checkbox"
            checked={this.props.checked[row.index]}
            onChange={() => {
              this.handleSingleCheckboxChange(row.index)
            }}
          />
        ),
        sortable: false,
        filterable: false,
        resizable: false,
        width: 28,
      },
      {
        Header: 'Method',
        accessor: 'method',
        width: 100,
        Cell: props => {
          return (
            <span
              style={{
                color: props.value === 'AUTHORIZE' ? '#27AE60' : '#EB5757',
              }}
            >
              {props.value}
            </span>
          )
        },
        filterMethod: (filter, row) => {
          if (filter.value !== 'ALL') {
            return row[filter.id] === filter.value
          }
          return true
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: '100%' }}
            value={filter ? filter.value : 'all'}
          >
            <option value="ALL">All</option>
            <option value="AUTHORIZE">Credit Cards</option>
            <option value="CREDIT">Credit</option>
            <option value="CHECK">Check</option>
            <option value="CASH">Cash</option>
          </select>
        ),
      },
      {
        Header: 'Trans ID',
        accessor: 'identification',
      },
      {
        id: 'invoiceNumber',
        Header: 'Invoice ID',
        accessor: row => `${row.invoice.invoiceNumber}`,
      },
      {
        Header: 'Submit Time',
        accessor: 'createdOn',
        Cell: props => (
          <span>{props.value && convertToYMDHM(props.value)}</span>
        ),
        width: 200,
        filterMethod: dateFilterMethod,
      },
      {
        id: 'firstName',
        Header: 'First Name',
        accessor: row =>
          `${row.invoice.familyAccount.primaryFamilyMember.firstName}`,
      },
      {
        id: 'lastName',
        Header: 'Last Name',
        accessor: row =>
          `${row.invoice.familyAccount.primaryFamilyMember.lastName}`,
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        Cell: props => <span>{`$${props.value.toFixed(2)}`}</span>,
      },

      {
        id: 'employee',
        Header: 'Responsible Employee',
        accessor: row =>
          row.invoice.responsibleEmployee
            ? `${row.invoice.responsibleEmployee.firstName} ${row.invoice.responsibleEmployee.lastName}`
            : 'N/A',
      },
    ]
    const { payments, sorted, filtered, onFilteredChange } = this.props
    return (
      <div align="left">
        <ReactTable
          filterable={true}
          data={payments}
          columns={columns}
          className="-highlight"
          defaultPageSize={25}
          defaultSorted={sorted}
          defaultFiltered={filtered}
          onFilteredChange={onFilteredChange}
          style={{ fontSize: '10pt' }}
          defaultFilterMethod={(filter, row) =>
            row[filter.id] !== null &&
            row[filter.id]
              .toString()
              .toLowerCase()
              .includes(filter.value.toString().toLowerCase())
          }
          getTrProps={(state, rowInfo, column) => {
            let background = '#ffffff'
            return {
              style: {
                background,
              },
            }
          }}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              onClick: (e, handleOriginal) => {
                if (column.Header !== undefined) {
                  this.props.onRowClick(rowInfo)
                }
                if (handleOriginal) {
                  handleOriginal()
                }
              },
            }
          }}
          getTheadProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                fontWeight: 'bold',
                borderBottom: '1px solid #E5E5E5',
              },
            }
          }}
        />
      </div>
    )
  }
}

export default PaymentTable
