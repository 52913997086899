import React, { Component, Fragment } from 'react'
import { Mutation } from 'react-apollo'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '../components'

import { SEND_SMS } from '../queries'

class SMSDialogButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      textMessage: '',
    }
  }

  handleChange = event => {
    const { name, value } = event.target
    this.setState({
      [name]: value,
    })
  }

  handleClickOpen = () => {
    this.setState({
      open: true,
    })
  }

  handleClose = () => {
    this.setState({
      open: false,
    })
  }

  render() {
    const { label, phoneNumbers, style, title } = this.props
    const { open, textMessage } = this.state
    return (
      <Fragment>
        <Button style={style} onClick={this.handleClickOpen}>
          {title}
        </Button>
        <Mutation mutation={SEND_SMS}>
          {(sendSMS, { data }) => (
            <Dialog open={open} onClose={this.handleClose}>
              <DialogTitle id="form-dialog-title">{title}</DialogTitle>
              <DialogContent>
                <DialogContentText>{label}</DialogContentText>
                <TextField
                  name="textMessage"
                  label="Text Message"
                  multiline
                  rowMax="4"
                  fullWidth
                  value={textMessage || ''}
                  onChange={this.handleChange}
                  margin="normal"
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleClose} color="primary">
                  Cancel
                </Button>
                <Button
                  onClick={async () => {
                    let SMSReturn = []
                    if (
                      window.confirm(
                        'Are you sure you want to send this text message?'
                      )
                    ) {
                      await Promise.all(
                        phoneNumbers.map(async phoneNumber => {
                          const sendReturn = await sendSMS({
                            variables: {
                              phoneNumber: phoneNumber,
                              message: textMessage,
                            },
                          })
                          const data = sendReturn.data.sendSMS
                          SMSReturn.push(
                            !data.success
                              ? ` ${phoneNumber} ${data.message}`
                              : ` Sent to ${phoneNumber}`
                          )
                        })
                      )
                      alert(SMSReturn)
                      this.handleClose()
                    }
                  }}
                >
                  Send
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </Mutation>
      </Fragment>
    )
  }
}
export default SMSDialogButton
