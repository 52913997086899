import React, { Component } from 'react'
import { Query } from 'react-apollo'
import { Redirect } from 'react-router-dom'

import {
  Button,
  Divider,
  ErrorMessage,
  Flex,
  LoadingMessage,
  SidebarText,
  withStyles,
} from '../../components'

import { sideBarStyles } from '../../utils/style'
import { GET_ACTIVITY_TYPES } from './queries'

class TaskSidebar extends Component {
  constructor(props) {
    super(props)
    this.state = { redirect: false }
  }

  addTask = () => {
    this.setState({
      redirect: true,
      redirectTo: '/task/add-new-task',
    })
  }

  countTasks = taskType => {
    const { tasks } = this.props
    const tasksCount = tasks.reduce((accumulator, task) => {
      return accumulator + (task.type === taskType ? 1 : 0)
    }, 0)
    return tasksCount
  }

  render() {
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirectTo} />
    }

    const { classes, tasks } = this.props

    const unknownTasks = tasks.reduce((accumulator, task) => {
      return accumulator + (task.type === null ? 1 : 0)
    }, 0)
    return (
      <Flex
        column
        grow={0}
        shrink={0}
        overflow="hidden"
        p="10px"
        style={{
          width: '15.38%',
          borderRight: `1px #aaaaaa solid`,
          maxWidth: '200px',
          overflowX: undefined,
          flexShrink: 0,
          textAlign: 'left',
          backgroundColor: '#e5e5e5',
        }}
      >
        <Flex column grow={1} justify="space-between">
          <Query query={GET_ACTIVITY_TYPES}>
            {({ loading, error, data }) => {
              if (loading) return <LoadingMessage />
              if (error) return <ErrorMessage error={error} />
              return (
                <Flex column>
                  <Button aria-label="AddTask" onClick={this.addTask}>
                    Add New Task
                  </Button>
                  <SidebarText
                    classes={{ root: classes.headline }}
                    title="Detail View"
                  />
                  <Divider />
                  <SidebarText
                    classes={{ root: classes.subheadingOne }}
                    title="Total Tasks:"
                    value={tasks.length}
                  />

                  {data.__type.enumValues.map(({ name: option }) =>
                    option !== 'REFUND' || option !== 'CREDIT' ? (
                      <SidebarText
                        key={option}
                        title={
                          option
                            .replace(/(\B)[^ ]*/g, match => match.toLowerCase())
                            .replace(/_/g, ' ')
                            .replace(/^[^ ]/g, match => match.toUpperCase()) +
                          ':'
                        }
                        value={this.countTasks(option)}
                      />
                    ) : (
                      ''
                    )
                  )}

                  <SidebarText title="Unknown:" value={unknownTasks} />
                </Flex>
              )
            }}
          </Query>

          {this.props.menu()}
        </Flex>
      </Flex>
    )
  }
}

export default withStyles(sideBarStyles)(TaskSidebar)
