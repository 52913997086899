import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'

import {
  Button,
  Divider,
  Flex,
  SidebarText,
  withStyles,
} from '../../components'
import { sideBarStyles } from '../../utils/style'

class FamilySidebar extends Component {
  constructor(props) {
    super(props)
    this.state = { redirect: false }
  }

  addFamily = () => {
    this.setState({
      redirect: true,
      redirectTo: '/family/add-new-family',
    })
  }

  render() {
    const { classes, menu, show } = this.props
    const { redirect, redirectTo } = this.state

    if (redirect) {
      return <Redirect push to={redirectTo} />
    }

    return (
      <Flex
        column
        grow={0}
        shrink={0}
        overflow="hidden"
        p="10px"
        style={{
          width: show ? '15.38%' : 0,
          borderRight: `${show ? '1px' : 0} ${'#aaaaaa'} solid`,
          maxWidth: show ? '200px' : 0,
          overflowX: show ? undefined : 'hidden',
          flexShrink: 0,
          textAlign: 'left',
          backgroundColor: '#e5e5e5',
        }}
      >
        <Flex column grow={1} justify="space-between">
          <Flex column>
            <Button
              aria-label="AddFamily"
              //disabled={noneTrue(checkedIds)}
              onClick={this.addFamily}
            >
              Add New Family
            </Button>
            <br />
            <Button
              aria-label="showRecent"
              onClick={() => {
                this.props.toggleRecent()
              }}
            >
              {this.props.filtered[
                this.props.filtered.findIndex(f => f.id === 'recent')
              ].value
                ? 'Show All'
                : 'Show Recent Signups'}
            </Button>
            <SidebarText
              classes={{ root: classes.headline }}
              title="Detail View"
            />
            <Divider />
            <SidebarText
              classes={{
                root: classes.subheadingOne,
              }}
              title="Total Families:"
              value={this.props.totalFamiliesCount}
            />
            <SidebarText
              title="Active:"
              value={this.props.statusCounts['ACTIVE']}
            />
            <SidebarText
              title="Inactive:"
              value={this.props.statusCounts['INACTIVE']}
            />
            <SidebarText
              title="Prospective:"
              value={this.props.statusCounts['PROSPECT']}
            />
            <SidebarText
              title="Closed:"
              value={this.props.statusCounts['CLOSED']}
            />
          </Flex>
          {menu()}
        </Flex>
      </Flex>
    )
  }
}

export default withStyles(sideBarStyles)(FamilySidebar)
