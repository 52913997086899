import React from 'react'
import MaskedInput from 'react-text-mask'

export const convertPhoneNumber = phoneNumber => {
  if (!phoneNumber) {
    return null
  }
  const cleanedNumber = phoneNumber.replace(/\D/g, '')
  let formattedNumber = ''
  if (cleanedNumber.length > 10) {
    formattedNumber = cleanedNumber
      .slice(0, cleanedNumber.length - 10)
      .concat(' (')
      .concat(
        cleanedNumber.slice(cleanedNumber.length - 10, cleanedNumber.length - 7)
      )
      .concat(') ')
      .concat(
        cleanedNumber.slice(cleanedNumber.length - 7, cleanedNumber.length - 4)
      )
      .concat('-')
      .concat(cleanedNumber.slice(cleanedNumber.length - 4))
  } else {
    formattedNumber = '('
      .concat(cleanedNumber.slice(0, cleanedNumber.length - 7))
      .concat(') ')
      .concat(
        cleanedNumber.slice(cleanedNumber.length - 7, cleanedNumber.length - 4)
      )
      .concat('-')
      .concat(cleanedNumber.slice(cleanedNumber.length - 4))
  }
  return formattedNumber
}

export function PhoneNumberInput(props) {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
      showMask
    />
  )
}

export const getRegions = country => {
  if (!country) {
    return []
  }
  return country[2].split('|').map(regionPair => {
    let [regionName] = regionPair.split('~')
    return regionName
  })
}

export const numberInputValidate = event => {
  if (
    !(
      (event.keyCode >= 48 && event.keyCode <= 57) ||
      event.keyCode === 8 ||
      event.keyCode === 46
    )
  ) {
    //allow backspace, delete, and 0-9
    event.preventDefault()
    event.returnValue = false
  }
}

export const sortByName = (aFirstName, aLastName, bFirstName, bLastName) => {
  const aFirstNameLowerCase = aFirstName.toLowerCase()
  const bFirstNameLowerCase = bFirstName.toLowerCase()
  const aLastNameLowerCase = aLastName.toLowerCase()
  const bLastNameLowerCase = bLastName.toLowerCase()
  if (aLastNameLowerCase !== bLastNameLowerCase) {
    return aLastNameLowerCase > bLastNameLowerCase ? 1 : -1
  } else if (aFirstNameLowerCase !== bFirstNameLowerCase) {
    return aFirstNameLowerCase > bFirstNameLowerCase ? 1 : -1
  } else {
    return 0
  }
}

export const arrayToObjectFilter = array =>
  array.reduce((obj, item) => {
    switch (item.id) {
      case 'status':
      case 'enrollmentStatus':
      case 'gender':
      case 'isTutor':
      case 'isTutored':
        if (item.value !== 'ALL') {
          obj[item.id] = item.value
        }
        return obj
      case 'grade':
        if (item.value !== 'ALL') {
          obj[item.id] = parseInt(item.value, 10)
        }
        return obj
      case 'email':
      case 'phoneNumber':
        return (obj = {
          ...obj,
          familyAccount: {
            primaryFamilyMember: { [item.id]: { $match: `(?i)${item.value}` } },
          },
        })
      case 'newImage':
        obj[item.id] = item.value === 'OFF' ? {} : { $exists: true }
        return obj
      default:
        obj[item.id] = { $match: `(?i)${item.value}` }
        return obj
    }
  }, {})

export const arrayToEnrollmentFilter = array =>
  array.reduce(
    (obj, item) => {
      switch (item.id) {
        case 'classCode':
          return (obj = {
            ...obj,
            classroom: {
              code: { $match: `(?i)${item.value}` },
            },
          })
        case 'isTutored': {
          return obj
        }
        case 'gender':
          if (item.value !== 'ALL') {
            return (obj = {
              ...obj,
              student: {
                ...obj.student,
                [item.id]: item.value,
              },
            })
          }
          return obj
        case 'firstName':
        case 'lastName':
        case 'school': {
          return (obj = {
            ...obj,
            student: {
              ...obj.student,
              [item.id]: { $match: `(?i)${item.value}` },
            },
          })
        }
        case 'grade': {
          if (item.value !== 'ALL') {
            return (obj = {
              ...obj,
              student: {
                ...obj.student,
                [item.id]: parseInt(item.value, 10),
              },
            })
          } else {
            return obj
          }
        }
        case 'email':
        case 'phoneNumber':
          return (obj = {
            ...obj,
            student: {
              ...obj.student,
              familyAccount: {
                primaryFamilyMember: {
                  [item.id]: { $match: `(?i)${item.value}` },
                },
              },
            },
          })
        case 'completionStatus':
        case 'status':
          if (item.value !== 'ALL') {
            obj[item.id] = item.value
          }
          return obj
        case 'enrollmentStatus':
        default:
          obj[item.id] = { $match: `(?i)${item.value}` }
          return obj
      }
    },
    { isTutored: true }
  )

export const arrayToObjectEnrollmentSort = array =>
  array.reduce((obj, item) => {
    switch (item.id) {
      case 'nextSession':
        return (obj = {
          ...obj,
          nextSessionDateTime: item.desc ? -1 : 1,
        })
      case 'classCode':
        obj = {
          ...obj,
          classroom: { code: item.desc ? -1 : 1 },
        }
        return obj
      case 'gender':
      case 'firstName':
      case 'lastName':
      case 'school':
        obj = {
          ...obj,
          student: { ...obj.student, [item.id]: item.desc ? -1 : 1 },
        }
        return obj
      case 'phoneNumber':
      case 'email':
        obj = {
          ...obj,
          student: {
            ...obj.student,
            familyAccount: {
              primaryFamilyMember: { [item.id]: item.desc ? -1 : 1 },
            },
          },
        }
        return obj
      default:
        obj[item.id] = item.desc ? -1 : 1
        return obj
    }
  }, {})

//filtering for family account
export const arrayToObjectFamilyFilter = array =>
  array.reduce((obj, item) => {
    switch (item.id) {
      case 'status':
        if (item.value !== 'ALL') {
          obj[item.id] = item.value
        }
        return obj
      case 'firstName':
      case 'lastName':
      case 'relation':
      case 'preferredLanguage':
      case 'phoneNumber':
      case 'email':
        obj = {
          ...obj,
          primaryFamilyMember: {
            [item.id]: { $match: `(?i)${item.value}` },
          },
        }
        return obj
      case 'city':
        obj = {
          ...obj,
          location: {
            [item.id]: { $match: `(?i)${item.value}` },
          },
        }
        return obj
      default:
        obj[item.id] = { $match: `(?i)${item.value}` }
        return obj
    }
  }, {})

//filtering for family account
export const arrayToObjectFamilyMemberFilter = array =>
  array.reduce((obj, item) => {
    switch (item.id) {
      case 'status':
        if (item.value !== 'ALL') {
          obj = {
            ...obj,
            familyAccount: {
              [item.id]: item.value,
            },
          }
        }
        return obj
      case 'firstName':
      case 'lastName':
      case 'relation':
      case 'preferredLanguage':
      case 'phoneNumber':
      case 'email':
        obj = {
          ...obj,
          [item.id]: { $match: `(?i)${item.value}` },
        }
        return obj
      case 'city':
        obj = {
          ...obj,
          familyAccount: {
            location: {
              [item.id]: { $match: `(?i)${item.value}` },
            },
          },
        }
        return obj
      case 'recent':
        if (item.value) {
          let afterDate = new Date()
          afterDate.setDate(afterDate.getDate() - 7)
          obj = {
            ...obj,
            createdOn: { $gtDate: afterDate },
          }
        }
        return obj
      case 'createdOn':
      case 'lastLogin':
        return obj
      default:
        obj[item.id] = { $match: `(?i)${item.value}` }
        return obj
    }
  }, {})

export const arrayToObjectSort = array =>
  array.reduce((obj, item) => {
    switch (item.id) {
      case 'email':
      case 'phoneNumber':
        return (obj = {
          ...obj,
          familyAccount: {
            primaryFamilyMember: { [item.id]: item.desc ? -1 : 1 },
          },
        })
      default:
        obj[item.id] = item.desc ? -1 : 1
        return obj
    }
  }, {})

//sorting for family account
export const arrayToObjectFamilySort = array =>
  array.reduce((obj, item) => {
    switch (item.id) {
      case 'firstName':
      case 'lastName':
      case 'relation':
      case 'preferredLanguage':
      case 'phoneNumber':
      case 'email':
        obj = { ...obj, primaryFamilyMember: { [item.id]: item.desc ? -1 : 1 } }
        return obj
      case 'city':
        obj = { ...obj, location: { [item.id]: item.desc ? -1 : 1 } }
        return obj
      default:
        obj[item.id] = item.desc ? -1 : 1
        return obj
    }
  }, {})

//sorting for family member
export const arrayToObjectFamilyMemberSort = array =>
  array.reduce((obj, item) => {
    switch (item.id) {
      case 'firstName':
      case 'lastName':
      case 'relation':
      case 'preferredLanguage':
      case 'phoneNumber':
      case 'email':
        obj = { ...obj, [item.id]: item.desc ? -1 : 1 }
        return obj
      case 'city':
        obj = {
          ...obj,
          familyAccount: { location: { [item.id]: item.desc ? -1 : 1 } },
        }
        return obj
      default:
        obj[item.id] = item.desc ? -1 : 1
        return obj
    }
  }, {})

export const getNextClassCode = classCode => {
  const nextClassCode = `${classCode.substring(0, 4)}${String.fromCharCode(
    classCode.charCodeAt(4) + 1
  )}`
  return nextClassCode
}

export const getNextSession = classroom => {
  const { quarter, year } = classroom
  switch (quarter) {
    case 'Winter':
      return { quarter: 'Spring', year }
    case 'Spring':
      return { quarter: 'Summer', year }
    case 'Summer':
      return { quarter: 'Fall', year }
    case 'Fall':
      return { quarter: 'Winter', year: `${parseInt(year, 10) + 1}` }
    default:
      return { quarter, year }
  }
}
