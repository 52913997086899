import React from 'react'
import { Query } from 'react-apollo'

import ClassDetailDialog from './ClassroomDetailDialog'

import { ErrorMessage, LoadingMessage } from '../../components'

import { GET_CLASSROOM } from './queries'

const EditClassroomDialog = props => {
  const { classroomId } = props

  return (
    classroomId && (
      <Query query={GET_CLASSROOM} variables={{ id: classroomId }}>
        {({ loading, error, data }) => {
          if (loading) return <LoadingMessage />
          if (error) return <ErrorMessage error={error} />
          return (
            <ClassDetailDialog
              {...props}
              title="Edit Classroom"
              classroom={data.classroom}
              classroomId={classroomId}
              isEdit={true}
            />
          )
        }}
      </Query>
    )
  )
}

export default EditClassroomDialog
