import React, { useState } from 'react'

import { TimePicker } from '../../components'

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Divider,
  Stack,
  TextField,
  Typography,
} from '@mui/material'

const TutorStudentDialog = props => {
  const {
    dialog,
    tutorSession,
    student,
    handleTutorSessionChange,
    handleCloseDialog,
    handleSubmit,
    handleArchive,
  } = props

  const { open } = dialog

  const [checked, setChecked] = useState(tutorSession.updateAll)

  const handleUpdateAllChange = event => {
    setChecked(event.target.checked)
    handleTutorSessionChange(event)
  }

  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      fullWidth={true}
      maxWidth={'sm'}
    >
      <DialogTitle>
        <Box
          style={{
            flexDirection: 'column',
          }}
        >
          <Typography style={{ marginBottom: '1em' }}>
            Edit Tutoring Session {tutorSession.order} for {student.firstName}{' '}
            {student.lastName}
          </Typography>
        </Box>
        <Divider />
      </DialogTitle>
      <DialogContent>
        <Stack direction="row" justifyContent="space-between">
          <TextField
            style={{ width: '20%' }}
            label={'Order'}
            name={'order'}
            variant="standard"
            value={tutorSession.order}
            onChange={handleTutorSessionChange}
            type={'number'}
          />
          <TimePicker
            label={'Start Time'}
            name={'startTime'}
            value={tutorSession.startTime}
            onChange={handleTutorSessionChange}
            style={{ width: '30%' }}
          />
          <TextField
            style={{ width: '20%' }}
            label={'Duration'}
            name={'duration'}
            variant="standard"
            onChange={handleTutorSessionChange}
            value={tutorSession.duration}
          />
          <Typography sx={{ mt: 1.5 }}>Update All</Typography>
          <Checkbox
            name={'updateAll'}
            checked={checked}
            onChange={handleUpdateAllChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </Stack>
        <TextField
          name={'summary'}
          label="Summary"
          value={tutorSession.summary || ''}
          onChange={handleTutorSessionChange}
          multiline
          minRows={6}
          fullWidth={true}
          style={{ marginTop: '1em' }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color={'warning'} onClick={handleArchive}>
          Archive
        </Button>
        <Button variant="contained" onClick={handleCloseDialog}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit}>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default TutorStudentDialog
