import React from 'react'
import HomeClassTable from './HomeClassTable'
import DaySelector from './DaySelector'
import HomeTuteeTable from './HomeTuteeTable'

const LoadingTable = props => {
  return (
    <React.Fragment>
      <DaySelector
        type={props.type}
        today={props.goToday}
        prev={props.goPrev}
        next={props.goNext}
        day={props.startDate}
        getDayEasy={props.getDayEasy}
      />

      {props.type === 'Classes' ? (
        <HomeClassTable classes={['Loading']} />
      ) : (
        <HomeTuteeTable classes={['Loading']} />
      )}
    </React.Fragment>
  )
}

export default LoadingTable
