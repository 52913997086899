import { createTheme } from '@mui/material/styles'
import { blue, red } from '@mui/material/colors'

export const theme = createTheme({
  palette: {
    primary: blue,
    error: red,
    tonalOffset: 0.2,
    contrastThreshold: 3,
  },
  typography: {
    fontFamily: ['Roboto'],
  },
})
