import React, { Component } from 'react'

import HomeClassTableView from './HomeClassTableView'
import HomeTuteeTableView from './HomeTuteeTableView'
// import ChatView from './ChatView'
// import Cookies from 'js-cookie'
// import { idName } from '../../config'

class HomeView extends Component {
  render() {
    return (
      <div className="row">
        <div className="column">
          <HomeClassTableView />
        </div>
        <div className="column">
          <HomeTuteeTableView />
        </div>
        {/* <div className="column">
          <ChatView
            empId={Cookies.get(idName)}
            chatId={'5d4ee4e4-177e-4e1b-876f-01488d5f4e62'}
          />
        </div> */}
      </div>
    )
  }
}

export default HomeView
