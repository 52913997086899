import React from 'react'
import ReactTable from 'react-table'
import { convertPhoneNumber } from '../../utils'
import { convertToMDY, convertToHMA } from '../../utils/datetime'

const AssessmentTable = props => {
  const statuses = ['COMPLETED', 'SCHEDULED', 'ABANDONED']
  const { assessments } = props

  const onRowClick = rowInfo => {
    if (rowInfo) props.redirectTo(rowInfo.original.id, [rowInfo.original])
  }

  const columns = [
    {
      Cell: row => (
        <input
          type="checkbox"
          checked={props.checked[row.index]}
          onChange={() => props.handleSingleCheckboxChange(row.index)}
        />
      ),
      sortable: false,
      filterable: false,
      resizable: false,
      width: 28,
    },
    {
      Header: 'Status',
      accessor: 'status',
      width: 135,
      Cell: props => {
        if (props.value === 'COMPLETED') {
          return <span style={{ color: '#27AE60' }}>{'COMPLETED'}</span>
        }
        if (props.value === 'SCHEDULED') {
          return <span style={{ color: '#00ADEF' }}>{'SCHEDULED'}</span>
        }
        if (props.value === 'ABANDONED') {
          return <span style={{ color: '#EB5757' }}>{'ABANDONED'}</span>
        }
        return <span>{props.value}</span>
      },
      filterMethod: (filter, row) => {
        if (filter.value !== 'ALL') {
          return row[filter.id] === filter.value
        }
        return true
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: '100%' }}
          value={filter ? filter.value : 'ALL'}
        >
          <option value="ALL">ALL</option>
          {statuses.map(option => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      ),
    },
    {
      Header: 'First Name',
      accessor: 'firstName',
      width: 135,
    },
    {
      Header: 'Last Name',
      accessor: 'lastName',
      width: 135,
    },
    {
      Header: 'Grade',
      accessor: 'grade',
      style: { textAlign: 'right' },
      width: 64,
      Cell: props => <span>{props.value === 0 ? 'K' : props.value}</span>,
      filterMethod: (filter, row) => {
        if (isNaN(filter.value)) {
          if (filter.value === 'ALL') {
            return true
          }
          if (filter.value === '-') {
            return row[filter.id] === null
          }
        }
        return row[filter.id] === parseInt(filter.value, 10)
      },
      Filter: ({ filter, onChange }) => {
        return (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: '100%' }}
            value={filter ? filter.value : 'ALL'}
          >
            <option value="ALL">ALL</option>
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(i => (
              <option key={'gradeFilter'.concat(i)} value={i}>
                {i === 0 ? 'K' : i}
              </option>
            ))}
            <option value="-">-</option>
          </select>
        )
      },
    },
    {
      Header: 'School',
      accessor: 'school',
      width: 135,
    },
    {
      Header: 'Resolve Date',
      accessor: 'resolveDate',
      Cell: props => (
        <span>
          {props.value &&
            convertToMDY(props.value) + ' ' + convertToHMA(props.value)}
        </span>
      ),
      width: 100,
    },
    {
      Header: 'Class Code',
      accessor: 'code',
      width: 105,
    },
    {
      Header: 'Score',
      accessor: 'score',
      style: { textAlign: 'right' },
      width: 64,
    },
    {
      Header: 'Guardian',
      accessor: 'parentName',
      width: 200,
    },
    {
      Header: 'Phone Number',
      accessor: 'phoneNumber',
      Cell: props => (
        <span className="number">{convertPhoneNumber(props.value)}</span>
      ),
      minWidth: 110,
    },
    {
      Header: 'Email',
      accessor: 'email',
      width: 135,
    },
    {
      Header: 'Created By',
      accessor: 'createdBy',
      width: 200,
    },
    {
      Header: 'Location',
      accessor: 'location',
      width: 200,
    },
  ]
  return (
    <div align="left">
      <ReactTable
        columns={columns}
        data={assessments}
        filterable={true}
        className="-highlight"
        defaultSorted={[{ id: 'createdOn' }]}
        style={{ fontSize: '10pt' }}
        defaultPageSize={25}
        getTheadProps={(state, rowInfo, column, instance) => {
          return {
            style: {
              fontWeight: 'bold',
              borderBottom: '1px solid #E5E5E5',
            },
          }
        }}
        getTdProps={(state, rowInfo, column, instance) => {
          return {
            onClick: e => {
              if (column.Header !== undefined) {
                onRowClick(rowInfo)
              }
            },
          }
        }}
      />
    </div>
  )
}

export default AssessmentTable
