import gql from 'graphql-tag'

export const GET_STUDENT = gql`
  query students($filter: Filters) {
    students(filter: $filter) {
      id
    }
  }
`

export const GET_OPPORTUNITY = gql`
  query getOpportunity($id: ID!) {
    opportunity(id: $id) {
      id
      status
      primaryInterest
      notes
      stage
      lastContacted
      session
      year
      centerLocation {
        id
      }
      student {
        id
        firstName
        lastName
        familyAccount {
          id
          status
          primaryFamilyMember {
            id
            firstName
            lastName
          }
        }
        enrollments {
          id
          classroom {
            id
          }
        }
      }
      source
      createdBy {
        id
        firstName
        lastName
      }
      responsibleEmployee {
        id
        firstName
        lastName
      }
    }
  }
`

export const GET_OPPORTUNITIES = gql`
  query getOpportunities($filter: Filters) {
    opportunities(filter: $filter) {
      id
      createdOn
      status
      openDate
      closeDate
      lastContacted
      source
      student {
        id
        firstName
        lastName
      }
      stage
      primaryInterest
      session
      year
      notes
      responsibleEmployee {
        id
        firstName
        lastName
      }
      createdBy {
        id
        firstName
        lastName
      }
    }
  }
`

export const GET_OPPORTUNITY_STATUS_OPTIONS_QUERY = gql`
  query getOpportunityStatusOptions {
    __type(name: "OpportunityStatus") {
      enumValues {
        name
      }
    }
  }
`

export const GET_OPPORTUNITY_STAGE_OPTIONS_QUERY = gql`
  query getOpportunityStageOptions {
    __type(name: "OpportunityStage") {
      enumValues {
        name
      }
    }
  }
`

// CREATION QUERIES

export const CREATE_OPPORTUNITY = gql`
  mutation createOpportunity($input: CreateOpportunityInput!) {
    createOpportunity(input: $input) {
      id
    }
  }
`
