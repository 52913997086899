import React, { Component, Fragment } from 'react'
import { Mutation } from 'react-apollo'
import { Redirect } from 'react-router-dom'

import {
  Button,
  Flex,
  DatePicker,
  MenuItem,
  SnackbarNotification,
  Text,
  TextField,
  withStyles,
} from '../../components'
import {
  CREATE_COUNSELING_ENROLLMENT,
  UPDATE_COUNSELING_ENROLLMENT,
} from './queries'

class CounselingForm extends Component {
  constructor(props) {
    super(props)
    const defaultState = {
      status: 'ACTIVE',
      snackbar: {
        open: false,
        message: '',
        messageType: '',
      },
      ...this.props,
    }
    this.state = defaultState
  }

  returnToCounselingTable = () => {
    this.setState({
      redirect: true,
      redirectTo: '/counseling',
    })
  }

  handleChange = event => {
    const { name, value } = event.target
    this.setState({
      [name]: value,
    })
  }

  handleSave = async UpdateCounselingEnrollment => {
    const { student } = this.props
    const {
      counselorId,
      counselingEnrollmentId,
      endDate,
      startDate,
      status,
    } = this.state
    await UpdateCounselingEnrollment({
      variables: {
        id: counselingEnrollmentId,
        input: {
          studentId: student.id,
          counselorId: counselorId,
          status,
          startDate,
          endDate,
        },
      },
    })
    this.returnToCounselingTable()
  }

  handleSubmit = async CreateCounselingEnrollment => {
    const { student } = this.props
    const { counselorId, endDate, startDate, status } = this.state
    await CreateCounselingEnrollment({
      variables: {
        input: {
          studentId: student.id,
          counselorId: counselorId,
          status,
          startDate,
          endDate,
        },
      },
    })
    this.returnToCounselingTable()
  }

  render() {
    const {
      counselorId,
      endDate,
      lastContactDate,
      nextContactDate,
      redirect,
      redirectTo,
      snackbar,
      startDate,
      status,
    } = this.state
    const {
      counselors,
      fromCounselingDetailView,
      statuses,
      student,
    } = this.props

    if (redirect) {
      return <Redirect push to={redirectTo} />
    }

    return (
      <Flex column style={{ align: 'center', flexGrow: 1 }}>
        <Flex justify="space-evenly">
          <Flex grow={2} m="20px" style={{ maxWidth: '300px' }} column>
            <TextField
              name="counselorId"
              label="Counselor"
              value={counselorId}
              select
              required
              fullWidth
              onChange={this.handleChange}
            >
              {counselors.map(counselor => (
                <MenuItem key={counselor.id} value={counselor.id}>
                  {`${counselor.firstName} ${counselor.lastName}`}
                </MenuItem>
              ))}
            </TextField>
            <br />
            <TextField
              name="status"
              label="Status"
              value={status}
              select
              fullWidth
              onChange={this.handleChange}
            >
              {statuses.map(status => (
                <MenuItem key={status} value={status}>
                  {status}
                </MenuItem>
              ))}
            </TextField>
            {status === 'GRADUATED' && (
              <Fragment>
                <br />
                <TextField
                  required
                  name="college"
                  label="College"
                  value={student.college || ''}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <br />
                <TextField
                  required
                  name="major"
                  label="Major"
                  value={student.major || ''}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <br />
              </Fragment>
            )}
          </Flex>
          <Flex grow={2} m="20px" style={{ maxWidth: '300px' }} column>
            <DatePicker
              name="startDate"
              label="Start Date"
              value={startDate || null}
              onChange={this.handleChange}
            />
            <br />
            <DatePicker
              name="endDate"
              label="End Date"
              value={endDate || null}
              onChange={this.handleChange}
            />
            {fromCounselingDetailView && (
              <Fragment>
                <br />
                <DatePicker
                  id="lastContactDate"
                  label="Last Contact Date"
                  disabled
                  value={lastContactDate || null}
                />
                <br />
                <DatePicker
                  id="nextContactDate"
                  label="Next Contact Date"
                  disabled
                  value={nextContactDate || null}
                />
              </Fragment>
            )}
          </Flex>
        </Flex>
        <Flex direction={'row-reverse'} align={'center'} p="10px">
          {fromCounselingDetailView ? (
            <Mutation mutation={UPDATE_COUNSELING_ENROLLMENT}>
              {UpdateCounselingEnrollment => (
                <Fragment>
                  <Button
                    onClick={() => {
                      this.handleSave(UpdateCounselingEnrollment)
                    }}
                    style={{ margin: '5px' }}
                  >
                    Save Changes
                  </Button>
                  <Text style={{ marginRight: '10px' }}>
                    Make sure to verify all information before hitting save!
                  </Text>
                </Fragment>
              )}
            </Mutation>
          ) : (
            <Fragment>
              <Mutation mutation={CREATE_COUNSELING_ENROLLMENT}>
                {CreateCounselingEnrollment => (
                  <Button
                    onClick={() => {
                      if (!this.state.counselorId) {
                        this.setState({
                          snackbar: {
                            open: true,
                            message: 'At least one required field is missing',
                            messageType: 'error',
                          },
                        })
                      } else {
                        if (
                          window.confirm('Are you sure you want to submit?')
                        ) {
                          this.handleSubmit(CreateCounselingEnrollment)
                        }
                      }
                    }}
                    style={{ margin: '5px' }}
                  >
                    Submit
                  </Button>
                )}
              </Mutation>
              <Button
                color="secondary"
                style={{ margin: '5px' }}
                onClick={() => {
                  if (
                    window.confirm(
                      'Do you want to return to the counseling table?'
                    )
                  ) {
                    this.returnToCounselingTable()
                  }
                }}
              >
                Cancel
              </Button>
            </Fragment>
          )}
        </Flex>
        <SnackbarNotification
          open={snackbar.open}
          handleClose={() => {
            this.setState({
              snackbar: {
                ...snackbar,
                open: false,
              },
            })
          }}
          message={snackbar.message}
          messageType={snackbar.messageType}
        />
      </Flex>
    )
  }
}

const styles = theme => ({
  menu: {
    width: '20%',
  },
  asterisk: {
    color: 'red',
  },
})

export default withStyles(styles)(CounselingForm)
