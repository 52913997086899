import React, { Component } from 'react'
import { Mutation, Query, withApollo } from 'react-apollo'
import { Redirect } from 'react-router-dom'
import { makeUpEmailTemplate } from './Email'

import {
  Button,
  ChooseStudentPanel,
  ErrorMessage,
  AccordionForm,
  Flex,
  LoadingMessage,
  MakeupForm,
  Paper,
  SnackbarNotification,
  Text,
  withStyles,
} from '../../components'

import {
  CREATE_MAKEUP,
  GET_EMPLOYEE,
  GET_ENROLLMENTS,
  GET_FAMILY_MEMBER_ID,
  GET_LESSON,
  GET_MAKEUPS,
  GET_STUDENT,
  generateTemporaryToken,
  SIMPLE_GET_CLASSROOMS,
} from './queries'

import { SEND_EMAIL_TO_FAMILY_MEMBER } from '../../queries'
import Cookies from 'js-cookie'
import { debug, idName, tokenName } from '../../config'

const styles = theme => ({
  root: {
    textAlign: 'left',
    margin: 'auto',
    width: '67%',
    minWidth: '600px',
    maxWidth: '1000px',
  },
  display1: {
    textAlign: 'left',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  expansionDetail: {
    overflow: 'visible',
  },
})

class AddMakeupView extends Component {
  render() {
    return this.props.student ? (
      <Query
        fetchPolicy={'cache-and-network'}
        query={GET_STUDENT}
        variables={{ id: this.props.match.params[0] }}
      >
        {({ loading, error, data }) => {
          if (loading) {
            return <LoadingMessage />
          }
          if (error) {
            return <ErrorMessage error={error} />
          }
          return <InnerAddMakeupView {...this.props} student={data.student} />
        }}
      </Query>
    ) : (
      <InnerAddMakeupView {...this.props} />
    )
  }
}

class InnerAddMakeupView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      redirect: false,
      redirectTo: '',
      student: props.student,
      selectedStudent: null,
      makeup: {
        status: 'SCHEDULED',
      },
      match: props.match,
      dateString: new Date().toISOString(),
      emailInfo: null,
      sendConfirmation: true,
      snackbar: {
        open: false,
        message: '',
        messageType: '',
      },
    }
  }

  toggleConfirmation = event => {
    this.setState({
      sendConfirmation: event.target.checked,
    })
  }

  returnToMakeupTable = () => {
    this.setState({
      redirect: true,
      redirectTo: '/makeup',
    })
  }

  handleMakeupChange = event => {
    const { name, value } = event.target
    this.setState(state => ({
      makeup: {
        ...state.makeup,
        [name]: value,
      },
    }))
  }

  handleChange = event => {
    const { name, value } = event.target
    this.setState(state => ({
      [name]: value,
      makeup:
        name === 'selectedStudent'
          ? {
              ...state.makeup,
              fromClassId: null,
              fromLessonId: null,
              toClassId: null,
              toLessonId: null,
            }
          : {
              ...state.makeup,
            },
    }))
  }

  sendEmailMutation = async id => {
    const { client, cookies, match } = this.props
    const { makeup, selectedStudent } = this.state
    const [
      { data: studentData },
      { data: employeeInfo },
      { data: lessonData },
      { data: absentLesson },
    ] = await Promise.all([
      client.query({
        query: GET_FAMILY_MEMBER_ID,
        variables: {
          id: selectedStudent ? selectedStudent.id : `${match.params[0]}`,
        },
      }),
      client.query({
        query: GET_EMPLOYEE,
        variables: { id: Cookies.get(idName) },
      }),
      makeup.toLessonId
        ? client.query({
            query: GET_LESSON,
            variables: {
              id: makeup.toLessonId,
            },
          })
        : { data: null },
      makeup.fromLessonId
        ? client.query({
            query: GET_LESSON,
            variables: {
              id: makeup.fromLessonId,
            },
          })
        : { data: null },
    ])

    const familyMemberId =
      studentData.student.familyAccount.primaryFamilyMember.id
    const studentName = studentData.student.firstName

    const { data: tokenData } = await client.mutate({
      mutation: generateTemporaryToken,
      variables: {
        token: cookies.get(tokenName),
        userType: 'FamilyMember',
        userId: familyMemberId,
      },
    })

    await client.mutate({
      mutation: SEND_EMAIL_TO_FAMILY_MEMBER,
      variables: {
        familyMemberId: familyMemberId,
        subject: 'Confirmation for Makeup Class',
        textMessage: 'Confirmation for Makeup Class',
        htmlMessage: makeUpEmailTemplate({
          studentName,
          ...employeeInfo,
          ...lessonData,
          absentLesson,
          status: makeup.status,
          makeupId: id,
          token: tokenData.generateTemporaryToken.token,
        }),
        testRecipient: debug ? 'dev@ardentlabs.io' : '',
        bccAddresses: [employeeInfo.employee.workEmail],
      },
    })
  }

  render() {
    const { classes, student } = this.props
    const {
      makeup,
      match,
      redirect,
      redirectTo,
      selectedStudent,
      snackbar,
    } = this.state
    const {
      status,
      fromClassId,
      fromLessonId,
      toLessonId,
      week,
      notes,
    } = makeup
    if (redirect) {
      return <Redirect push to={redirectTo} />
    }

    const handleSubmission = async createMakeupMutation => {
      const { data } = await createMakeupMutation({
        variables: {
          input: {
            studentId: student
              ? student.id
              : selectedStudent && selectedStudent.id,
            status: status,
            fromLessonId: fromLessonId,
            toLessonId: toLessonId === 'absent' ? null : toLessonId,
            week: week,
            notes: notes,
            createdById: Cookies.get(idName),
          },
        },
        refetchQueries: [
          {
            query: GET_MAKEUPS,
          },
        ],
      })
      if (this.state.sendConfirmation)
        this.sendEmailMutation(data.createMakeup.id)
      this.returnToMakeupTable()
    }

    const studentId = selectedStudent ? selectedStudent.id : match.params[0]

    return (
      <Flex column grow={1} className={classes.root}>
        <br />
        <Text className={classes.display1} variant="h4">
          {`Schedule Makeup ${
            student ? `for ${student.firstName} ${student.lastName}` : ''
          }`}
        </Text>
        <br />
        <Paper>
          {!student && (
            <AccordionForm
              className={classes.expansionDetail}
              expanded
              overflow
              title="Lookup Student (Required)"
              form={
                <Flex
                  column
                  pt="20px"
                  style={{
                    align: 'center',
                    flexGrow: 1,
                  }}
                >
                  <ChooseStudentPanel
                    display={'makeup'}
                    selectedStudent={selectedStudent}
                    onChange={this.handleChange}
                  />
                </Flex>
              }
            />
          )}
          {studentId && (
            <AccordionForm
              expanded
              title="Makeup Information"
              form={
                <Query query={GET_ENROLLMENTS} variables={{ id: studentId }}>
                  {({ loading, error, data }) => {
                    if (loading) return <LoadingMessage />
                    if (error) return <ErrorMessage error={error} />

                    const studentOpenClassrooms = data.student.enrollments
                      .map(enrollment => {
                        return enrollment.classroom
                      })
                      .filter(
                        classroom =>
                          classroom.status === 'OPENED' ||
                          classroom.status === 'PUBLISHED'
                      )

                    if (!fromClassId && studentOpenClassrooms.length !== 0) {
                      this.handleMakeupChange({
                        target: {
                          name: 'fromClassId',
                          value: studentOpenClassrooms[0].id,
                        },
                      })
                    }
                    const fromClass = studentOpenClassrooms.find(
                      classroom => classroom.id === fromClassId
                    )
                    return !fromClass ? (
                      <Text>This student is not enrolled in any classes</Text>
                    ) : (
                      <Query
                        query={SIMPLE_GET_CLASSROOMS}
                        variables={{
                          filter: {
                            $or: [
                              { status: 'OPENED' },
                              { status: 'PUBLISHED' },
                            ],
                            courseId: fromClass && fromClass.course.id,
                          },
                        }}
                      >
                        {({ loading, error, data }) => {
                          if (loading) return <LoadingMessage />
                          if (error) return <ErrorMessage error={error} />

                          const classrooms = data.classrooms
                          return (
                            <MakeupForm
                              makeup={makeup}
                              handleChange={this.handleMakeupChange}
                              fromClasses={studentOpenClassrooms}
                              toClasses={classrooms}
                              sendConfirmation={this.state.sendConfirmation}
                              toggleConfirmation={this.toggleConfirmation}
                            />
                          )
                        }}
                      </Query>
                    )
                  }}
                </Query>
              }
            />
          )}
          <Flex direction={'row-reverse'} p="10px">
            <Mutation mutation={CREATE_MAKEUP}>
              {createMakeupMutation => (
                <Button
                  onClick={() => {
                    if (
                      (!selectedStudent && !student) ||
                      !fromLessonId ||
                      !status
                    ) {
                      this.setState({
                        snackbar: {
                          open: true,
                          message: 'At least one required field is missing',
                          messageType: 'error',
                        },
                      })
                    } else {
                      if (
                        window.confirm(
                          'Are you sure you want to submit? All empty fields will be filled with default or null values'
                        )
                      ) {
                        handleSubmission(createMakeupMutation)
                      }
                    }
                  }}
                >
                  Submit
                </Button>
              )}
            </Mutation>
            <Button
              color="secondary"
              aria-label="Cancel"
              className={classes.button}
              onClick={() => {
                if (
                  window.confirm(
                    'Do you want to return to the table of makeups?'
                  )
                ) {
                  this.returnToMakeupTable()
                }
              }}
              style={{ marginRight: '10px' }}
            >
              Cancel
            </Button>
          </Flex>
        </Paper>
        <SnackbarNotification
          open={snackbar.open}
          handleClose={() => {
            this.setState({
              snackbar: {
                ...snackbar,
                open: false,
              },
            })
          }}
          message={snackbar.message}
          messageType={snackbar.messageType}
        />
      </Flex>
    )
  }
}

export default withApollo(withStyles(styles)(AddMakeupView))
