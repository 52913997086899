import React, { useState } from 'react'
import { useQuery, useMutation } from 'react-apollo'
import { useHistory } from 'react-router-dom'

import { Paper } from '@mui/material'

import {
  ErrorMessage,
  LoadingMessage,
  SnackbarNotification,
} from '../../components'
import AddRefundForm from './AddRefundForm'
import CreditDetailedPanel from './CreditDetailedPanel'
import CreditDetailSidebar from './CreditDetailSidebar'
import { CREATE_REFUND, GET_CREDIT, UPDATE_CREDIT } from './queries'

const CreditDetailedView = props => {
  const [openAddRefund, setOpenAddRefund] = useState(false)
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    messageType: '',
    windowReload: false,
  })

  const [updateCredit] = useMutation(UPDATE_CREDIT)
  const [createRefund] = useMutation(CREATE_REFUND)

  const history = useHistory()

  const creditNumber = props.match.params[0]
  const { loading, error, data } = useQuery(GET_CREDIT, {
    fetchPolicy: 'cache-and-network',
    variables: { filter: { creditNumber: creditNumber } },
  })

  if (loading) return <LoadingMessage />
  if (error) return <ErrorMessage error={error} />
  if (!data) return null
  if (data.credits.length !== 1) {
    return history.push('/credit')
  }

  const creditInfo = formatCreditInfo(data.credits[0])

  const openSnack = (message, messageType, windowReload) => {
    const newSnackbar = {
      ...snackbar,
      open: true,
      messageType,
      message,
      windowReload,
    }
    setSnackbar(newSnackbar)
  }

  const handleUpdateCredit = async (id, input) => {
    console.log('handleUpdateCredit', id, input)
    await updateCredit({
      variables: { id, input },
    })
    window.location.reload(false)
  }

  const handleCreateRefund = async inputs => {
    for (let input of inputs) {
      await createRefund({
        variables: { input },
      })
    }
  }

  return (
    <Paper sx={{ display: 'flex' }}>
      <AddRefundForm
        open={openAddRefund}
        credit={creditInfo}
        openSnack={openSnack}
        close={() => setOpenAddRefund(false)}
        updateCredit={handleUpdateCredit}
        createRefund={handleCreateRefund}
      />
      <CreditDetailSidebar
        credit={creditInfo}
        openAddRefund={() => setOpenAddRefund(true)}
      />
      <Paper sx={{ width: '100%' }}>
        <CreditDetailedPanel
          creditInfo={creditInfo}
          updateCredit={handleUpdateCredit}
        />
      </Paper>
      <SnackbarNotification
        open={snackbar.open}
        handleClose={() => {
          setSnackbar({ ...snackbar, open: false })
          // if (snackbar.windowReload) refetch()
        }}
        message={snackbar.message}
        messageType={snackbar.messageType}
      />
    </Paper>
  )
}

const formatCreditInfo = ({
  id,
  amount,
  balance,
  creditNumber,
  creditType,
  refundable,
  issueDate,
  notes,
  status,
  familyAccount,
  refunds,
  invoice,
}) => ({
  id: id,
  amount: amount,
  balance: balance,
  creditType: creditType,
  creditNumber: creditNumber,
  refundable: refundable,
  issueDate: issueDate,
  notes: notes,
  status: status,

  guardian: `${familyAccount.primaryFamilyMember.firstName} ${familyAccount.primaryFamilyMember.lastName}`,
  email: `${familyAccount.primaryFamilyMember.email || 'UNKNOWN'}`,
  phone: `${familyAccount.primaryFamilyMember.phoneNumber || 'UNKNOWN'}`,
  totalBalance: familyAccount.balance,
  refunds: refunds,
  invoice: invoice,
})

export default CreditDetailedView
