import React from 'react'
import Select from 'react-select'
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  DatePicker,
  TextField,
  withStyles,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '../../components'
import { convertPhoneNumber } from '../../utils'
import { expansionBarColor } from '../../config'

const REFERRAL_STATUSES = ['INITIATED', 'SIGNEDUP', 'ENROLLED', 'EXPIRED']

const styles = theme => ({
  menu: {
    width: '20%',
  },
  expSummary: {
    backgroundColor: expansionBarColor,
  },
})

const ReferralInfoDialog = props => {
  const { dialog, data, handleCloseDialog, handleChangeSelected } = props

  const {
    firstName,
    lastName,
    createdOn,
    email,
    phoneNumber,
    status,
    expirationDate,
    referrer,
  } = data

  return (
    <Dialog
      open={dialog.open}
      onClose={handleCloseDialog}
      fullWidth={true}
      maxWidth={'sm'}
    >
      <DialogTitle>Referral Information</DialogTitle>
      <Divider style={{ width: '100%' }} />
      <DialogContent>
        <Accordion defaultExpanded>
          <AccordionSummary title="Referral Information" />
          <AccordionDetails>
            <Flex flex={1} direction="column" justify="space-evenly">
              <Flex flex={1} justify="space-between">
                <TextField
                  name="First Name"
                  label="First Name"
                  InputProps={{
                    readOnly: true,
                  }}
                  style={{ width: '48%' }}
                  value={firstName}
                  disabled
                />
                <TextField
                  name="Last Name"
                  label="Last Name"
                  InputProps={{
                    readOnly: true,
                  }}
                  style={{ width: '48%' }}
                  value={lastName}
                  disabled
                />
              </Flex>
              <Flex flex={1} justify="space-between" mt="12px">
                <DatePicker
                  name="Created On"
                  label="Created On"
                  value={new Date(createdOn)}
                  style={{ width: '48%' }}
                  disabled
                />
                <DatePicker
                  name="expirationDate"
                  label="Expires On"
                  value={new Date(expirationDate)}
                  style={{ width: '48%' }}
                  disabled={dialog.type !== 'edit'}
                />
              </Flex>
              <Flex flex={1} justify="space-between">
                <FormControl
                  component="fieldset"
                  className={styles.formControl}
                  style={{
                    display: 'inline-flex',
                    paddingLeft: '10px',
                  }}
                >
                  <FormLabel component="legend">Status</FormLabel>
                  <Select
                    name={'status'}
                    onChange={(v, e) => {
                      console.log(v)
                      console.log(e)
                    }}
                    options={REFERRAL_STATUSES}
                    disabled={dialog.type !== 'edit'}
                    placeholder="Status"
                    value={status}
                  />
                </FormControl>
              </Flex>
              <Flex flex={1} justify="space-between">
                <TextField
                  name="email"
                  label="Email"
                  value={email}
                  disabled={dialog.type !== 'edit'}
                  onChange={handleChangeSelected}
                  fullWidth
                />
              </Flex>
              <Flex flex={1} justify="space-between">
                <TextField
                  name="phoneNumber"
                  label="Phone Number"
                  value={convertPhoneNumber(phoneNumber)}
                  onChange={handleChangeSelected}
                  disabled={dialog.type !== 'edit'}
                  fullWidth
                />
              </Flex>
            </Flex>
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded>
          <AccordionSummary title="Referrer Information" />
          <AccordionDetails>
            <Flex flex={1} direction="column" justify="space-evenly">
              <Flex flex={1} justify="space-between">
                <TextField
                  name="First Name"
                  label="First Name"
                  InputProps={{
                    readOnly: true,
                  }}
                  style={{ width: '48%' }}
                  value={referrer.primaryFamilyMember.firstName}
                  disabled
                />
                <TextField
                  name="Last Name"
                  label="Last Name"
                  InputProps={{
                    readOnly: true,
                  }}
                  style={{ width: '48%' }}
                  value={referrer.primaryFamilyMember.lastName}
                  disabled
                />
              </Flex>
              <Flex flex={1} justify="space-between">
                <TextField
                  name="Email"
                  label="Email"
                  InputProps={{
                    readOnly: true,
                  }}
                  value={referrer.primaryFamilyMember.email}
                  disabled
                  fullWidth
                />
              </Flex>
              <Flex flex={1} justify="space-between">
                <TextField
                  name="Phone Number"
                  label="Phone Number"
                  InputProps={{
                    readOnly: true,
                  }}
                  value={convertPhoneNumber(
                    referrer.primaryFamilyMember.phoneNumber
                  )}
                  disabled
                  fullWidth
                />
              </Flex>
            </Flex>
          </AccordionDetails>
        </Accordion>
      </DialogContent>
      <DialogActions>
        {dialog.type === 'edit' && (
          <Button
            style={{ marginBottom: '1em' }}
            onClick={() => {
              console.log('edit')
            }}
          >
            Update
          </Button>
        )}
        <Button
          style={{ marginBottom: '1em' }}
          color={'secondary'}
          onClick={handleCloseDialog}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(styles)(ReferralInfoDialog)
