import React, { useState, useEffect } from 'react'
import { useMutation } from 'react-apollo'
import { useHistory } from 'react-router'
import validate from 'validate.js'
import { login as loginAction } from '../../actions'
import { useSelector, useDispatch } from 'react-redux'

import {
  Button,
  Grid,
  LinearProgress,
  TextField,
  Typography,
} from '@mui/material'

import Cookies from 'js-cookie'
import { domain, tokenName, idName } from '../../config'
import { defaultImageUrl } from '../../utils/defaultImage'

import { EMPLOYEE_LOGIN } from './queries'

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
  },
}

function LoginForm({ className, ...rest }) {
  const history = useHistory()
  const dispatch = useDispatch()
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  })
  const sessionState = useSelector(state => state.session)

  const [login, { loading: loginLoading }] = useMutation(EMPLOYEE_LOGIN, {
    onCompleted: data => {
      const { employeeLogin } = data

      if (!employeeLogin.success) {
        dispatch(
          loginAction({
            loggedIn: false,
            errorMessage: employeeLogin.message,
          })
        )
      }
      try {
        Cookies.set(idName, employeeLogin.employee.id, {
          domain,
          path: '/',
        })
        Cookies.set(tokenName, employeeLogin.token, {
          domain,
          path: '/',
        })
      } catch (error) {
        alert(error)
      }

      const { firstName, lastName } = employeeLogin.employee
      const userName =
        lastName.length === 0 ? `${firstName}` : `${firstName} ${lastName}`
      dispatch(
        loginAction({
          loggedIn: true,
          user: {
            id: employeeLogin.employee.id,
            first_name: firstName,
            last_name: lastName,
            user_name: userName,
            email:
              employeeLogin.employee.workEmail ||
              employeeLogin.employee.personalEmail ||
              employeeLogin.employee.email,
            avatar: employeeLogin.employee.imageUrl || defaultImageUrl,
            position: employeeLogin.employee.position,
          },
        })
      )
      history.push('/')
    },
    onError: e => {
      dispatch(
        loginAction({
          loggedIn: false,
          errorMessage: 'Failed to log in, please try again later.',
        })
      )
    },
  })

  const handleChange = event => {
    event.persist()

    setFormState(prevFormState => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true,
      },
    }))
  }

  const handleSubmit = async event => {
    event.preventDefault()
    login({
      variables: {
        email: formState.values.email,
        password: formState.values.password,
        checkType: 'Office',
      },
    })
  }

  const hasError = field =>
    !!(formState.touched[field] && formState.errors[field])

  useEffect(() => {
    const errors = validate(formState.values, schema)

    setFormState(prevFormState => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {},
    }))
  }, [formState.values])

  return (
    <form onSubmit={handleSubmit}>
      <Grid container alignItems="center" justify="center" direction="column">
        <Grid item>
          <TextField
            sx={{
              width: 1,
              my: 2,
            }}
            error={hasError('email')}
            helperText={hasError('email') ? formState.errors.email[0] : null}
            label="Email Address"
            name="email"
            onChange={handleChange}
            value={formState.values.email || ''}
            variant="outlined"
          />
          <TextField
            sx={{
              width: 1,
              mb: 2,
            }}
            error={hasError('password')}
            helperText={
              hasError('password') ? formState.errors.password[0] : null
            }
            label="Password"
            name="password"
            onChange={handleChange}
            type="password"
            value={formState.values.password || ''}
            variant="outlined"
          />
          {sessionState && sessionState.errorMessage && (
            <Typography sx={{ color: 'red' }}>
              {sessionState.errorMessage}
            </Typography>
          )}
          <Button
            sx={{
              width: 1,
            }}
            color="primary"
            disabled={!formState.isValid}
            size="large"
            type="submit"
            variant="contained"
          >
            Sign in
          </Button>
          {loginLoading && <LinearProgress />}
        </Grid>
      </Grid>
    </form>
  )
}

export default LoginForm
