import React from 'react'
import { convertToMDY } from '../../utils/datetime'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '../../components'

const TaskHistory = props => (
  <div>
    <Table>
      <TableHead>
        <TableRow>
          {[
            'Status',
            'Type',
            'Priority',
            'Method',
            'Assigned To',
            'Due Date',
            'Close Date',
          ].map(cell => (
            <TableCell>{cell}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {props.tasks.map(task => {
          const {
            responsibleEmployee,
            type,
            method,
            priority,
            endDate,
            resolvedDate,
          } = task
          return (
            <TableRow key={task.id} onClick={() => props.onClick(task.id)}>
              <TableCell>{resolvedDate ? 'CLOSED' : 'OPEN'}</TableCell>
              <TableCell>{type}</TableCell>
              <TableCell>{priority}</TableCell>
              <TableCell>{method}</TableCell>
              <TableCell>{`${responsibleEmployee.firstName} ${responsibleEmployee.lastName}`}</TableCell>
              <TableCell>{endDate ? convertToMDY(endDate) : ''}</TableCell>
              <TableCell>
                {resolvedDate ? convertToMDY(resolvedDate) : ''}
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  </div>
)

export default TaskHistory
