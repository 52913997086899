import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import AddInvoiceView from './AddInvoiceView'
import InvoiceDetailedView from './InvoiceDetailedView'
import InvoiceTableView from './InvoiceTableView'

class InvoiceView extends Component {
  render() {
    const url = this.props.match.url
    return (
      <Switch>
        <Route path={url + '/add-new-invoice'} component={AddInvoiceView} />
        <Route path={url + '/*'} component={InvoiceDetailedView} />
        <Route path={url} component={InvoiceTableView} />
      </Switch>
    )
  }
}

export default InvoiceView
