import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'

import PostsViewCard from './PostsViewCard'
import { Flex } from '../../components'

const PostsViewFeed = props => {
  const [redirect, setRedirect] = useState(null)
  const { postsData, handleOpenEditDialog } = props

  if (redirect) {
    return <Redirect push to={`posts/${redirect}`} />
  }
  return (
    <Flex
      style={{
        flexWrap: 'wrap',
      }}
    >
      {postsData.map(post => {
        return (
          <PostsViewCard
            key={post.id}
            post={post}
            handleOpenEditDialog={handleOpenEditDialog}
            setRedirect={setRedirect}
          />
        )
      })}
    </Flex>
  )
}

export default PostsViewFeed
