import gql from 'graphql-tag'

// RETRIEVAL QUERIES

export const GET_CLASSROOMS = gql`
  query getClasses($filter: Filters!) {
    classrooms(filter: $filter) {
      id
      status
      title
      startDate
      endDate
      quarter
      course {
        id
        code
      }
    }
  }
`

export const GET_EMPLOYEE = gql`
  query getEmployee($id: ID!) {
    employee(id: $id) {
      id
      firstName
      lastName
      workEmail
    }
  }
`

export const GET_ENROLLMENTS = gql`
  query getEnrollmentsFromStudent($id: ID!) {
    student(id: $id) {
      id
      firstName
      lastName
      enrollments {
        id
        classroom {
          id
          status
          title
          course {
            id
            code
            title
          }
        }
      }
    }
  }
`

export const GET_ENROLLMENTS_FROM_STUDENT = gql`
  query getEnrollmentsFromStudent($id: ID!) {
    student(id: $id) {
      id
      firstName
      lastName
      enrollments {
        id
        classroom {
          id
          status
          title
          course {
            id
            code
            title
          }
        }
      }
    }
  }
`

export const GET_FAMILY_MEMBER_ID = gql`
  query getFamilyMemberIdFromStudent($id: ID!) {
    student(id: $id) {
      id
      firstName
      familyAccount {
        id
        primaryFamilyMember {
          id
        }
      }
    }
  }
`

export const GET_LESSON = gql`
  query getLessonFromId($id: ID!) {
    lesson(id: $id) {
      id
      startOn
      endOn
      classroom {
        id
        title
        code
        centerLocation {
          id
          name
          streetLine1
          city
          state
          isVirtual
        }
      }
    }
  }
`

export const GET_MAKEUPS = gql`
  query getMakeups($filter: Filters) {
    makeups(filter: $filter) {
      id
      createdOn
      notes
      status
      createdBy {
        id
        firstName
        lastName
      }
      student {
        id
        firstName
        lastName
        phoneNumber
        email
        enrollmentStatus
      }
      fromLesson {
        id
        open
        startOn
        endOn
        lessonPlan {
          id
          order
          title
        }
        classroom {
          id
          code
          centerLocation {
            id
            name
          }
        }
      }
      toLesson {
        id
        open
        startOn
        endOn
        lessonPlan {
          id
          order
          title
        }
        classroom {
          id
          code
          centerLocation {
            id
            name
          }
        }
      }
    }
  }
`

export const GET_MAKEUP = gql`
  query getMakeup($id: ID!) {
    makeup(id: $id) {
      id
      notes
      status
      createdBy {
        id
        firstName
        lastName
      }
      student {
        id
        firstName
        lastName
        gender
        birthdate
        grade
        school
        phoneNumber
        email
      }
      fromLesson {
        id
        classroom {
          id
        }
      }
      toLesson {
        id
        classroom {
          id
          course {
            id
          }
        }
      }
    }
  }
`

export const GET_STUDENT = gql`
  query getStudent($id: ID!) {
    student(id: $id) {
      id
      enrollmentStatus
      firstName
      middleName
      lastName
      nickname
      gender
      grade
      school
      username
      phoneNumber
      email
      tShirtSize
      learningStyle
      birthdate
      imageUrl
      enrollments(sort: { createdOn: -1 }) {
        id
        classroom {
          id
          quarter
          year
          centerLocation {
            id
            name
          }
          course {
            id
            code
            title
          }
        }
      }
      familyAccount {
        id
        status
        location {
          id
          street
          city
          zip
          state
          country
        }
        primaryFamilyMember {
          id
          firstName
          lastName
          relation
          email
          phoneNumber
          occupation
          educationLevel
        }
        familyMembers {
          id
          firstName
          lastName
          relation
          email
          phoneNumber
          occupation
          educationLevel
        }
      }
      opportunities {
        id
        status
        primaryInterest
        session
        year
        closeDate
        source
        notes
        createdBy {
          id
          firstName
          lastName
        }
        responsibleEmployee {
          id
          firstName
          lastName
        }
      }
    }
  }
`
export const SIMPLE_GET_CLASSROOMS = gql`
  query getClasses($filter: Filters!) {
    classrooms(filter: $filter) {
      id
      status
      title
      startDate
      endDate
      quarter
      course {
        id
        code
      }
    }
  }
`
// CREATION QUERIES

export const CREATE_MAKEUP = gql`
  mutation createMakeup($input: CreateMakeupInput!) {
    createMakeup(input: $input) {
      id
    }
  }
`

export const generateTemporaryToken = gql`
  mutation generateTemporaryToken(
    $token: String!
    $userId: ID!
    $userType: String!
  ) {
    generateTemporaryToken(
      token: $token
      userType: $userType
      userId: $userId
    ) {
      token
      errorMessage
    }
  }
`

// UPDATE QUERIES
export const UPDATE_MAKEUP = gql`
  mutation updateMakeup($id: ID!, $input: UpdateMakeupInput!) {
    updateMakeup(id: $id, input: $input) {
      id
    }
  }
`

// ARCHIVE QUERIES

export const ARCHIVE_MAKEUP = gql`
  mutation archiveMakeup($id: ID!) {
    archiveMakeup(id: $id) {
      id
    }
  }
`
