import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

import LessonFormContainer from './LessonEditForm'
import LessonDetailView from './LessonDetailView'
import LessonView from './LessonView'

class LessonViewRouter extends Component {
  render() {
    const url = this.props.match.url
    return (
      <Switch>
        <Route path={`${url}/*/*`} component={LessonFormContainer} />
        <Route path={`${url}/*`} component={LessonDetailView} />
        <Route path={url} component={LessonView} />
      </Switch>
    )
  }
}

export default LessonViewRouter
