import React, { Component } from 'react'

import ReactTable from 'react-table'

import { AutorenewIcon } from '../../components'
import { convertPhoneNumber } from '../../utils'
import { convertToMDY } from '../../utils/datetime'
class ClassDetailTable extends Component {
  render() {
    const { showRenewals } = this.props
    const columns = [
      {
        Cell: row => (
          <input type="checkbox" checked={this.props.checked[row.index]} />
        ),
        sortable: false,
        filterable: false,
        resizable: false,
        width: 28,
      },
      {
        Header: 'Type',
        accessor: 'type',
        width: 135,
        Cell: props => {
          if (props.value === 'Enrolled') {
            return <span style={{ color: '#27AE60' }}>{'Enrolled'}</span>
          }
          if (props.value === 'Trial') {
            return <span style={{ color: '#EB5757' }}>{'Trial'}</span>
          }
          if (props.value === 'Make-up') {
            return <span style={{ color: '#00ADEF' }}>{'Make-up'}</span>
          }
          return <span>{props.value ? props.value : 'UNKNOWN'}</span>
        },
        filterMethod: (filter, row) => {
          if (filter.value !== 'ALL' && filter.value !== 'UNKNOWN') {
            return row[filter.id] === filter.value
          }
          if (filter.value === 'UNKNOWN') {
            return row[filter.id] === null
          }
          return true
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: '100%' }}
            value={filter ? filter.value : 'ALL'}
          >
            <option value="ALL">All</option>
            <option value="Enrolled">Enrolled</option>
            <option value="Trial">Trial</option>
            <option value="Make-up">Make-up</option>
            <option value="UNKNOWN">UNKNOWN</option>
          </select>
        ),
      },
      {
        Header: 'First Name',
        accessor: 'firstName',
      },
      {
        Header: 'Last Name',
        accessor: 'lastName',
        filterMethod: (filter, row) => {
          return (
            row[filter.id] &&
            row[filter.id].toLowerCase().startsWith(filter.value.toLowerCase())
          )
        },
      },
      {
        Header: 'Gender',
        accessor: 'gender',
        width: 66,
        Cell: props => (
          <span>
            {props.value === 'MALE'
              ? 'M'
              : props.value === 'FEMALE'
              ? 'F'
              : '-'}
          </span>
        ),
        filterMethod: (filter, row) => {
          if (filter.value === 'M') {
            return row[filter.id] === 'MALE'
          }
          if (filter.value === 'F') {
            return row[filter.id] === 'FEMALE'
          }
          if (filter.value === '-') {
            return row[filter.id] !== 'MALE' && row[filter.id] !== 'FEMALE'
          }
          return true
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: '100%' }}
            value={filter ? filter.value : 'ALL'}
          >
            <option value="ALL">All</option>
            <option value="M">M</option>
            <option value="F">F</option>
            <option value="-">-</option>
          </select>
        ),
      },
      {
        Header: 'Primary Guardian',
        accessor: 'primaryGuardian',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Phone Number',
        accessor: 'phoneNumber',
        Cell: props => (
          <span className="number">{convertPhoneNumber(props.value)}</span>
        ),
        minWidth: 110,
      },
      {
        Header: 'Enroll Date',
        accessor: 'createdOn',
        Cell: props => <span>{props.value && convertToMDY(props.value)}</span>,
        width: 100,
      },
      {
        Header: 'Grade',
        accessor: 'overallGrade',
        getProps: (state, rowInfo) => ({
          style: {
            backgroundColor:
              rowInfo &&
              (rowInfo.row.overallGrade === null ||
                rowInfo.row.overallGrade === undefined)
                ? 'White'
                : rowInfo &&
                  80 <= rowInfo.row.overallGrade &&
                  rowInfo.row.overallGrade <= 100
                ? 'White'
                : rowInfo &&
                  70 <= rowInfo.row.overallGrade &&
                  rowInfo.row.overallGrade < 80
                ? 'LightYellow'
                : rowInfo
                ? 'LightPink'
                : 'White',
          },
        }),
      },
    ]
    if (showRenewals) {
      columns.splice(3, 0, {
        Header: <AutorenewIcon />,
        accessor: 'renewal',
        width: 45,
        Cell: row => <div style={{ textAlign: 'center' }}>{row.value}</div>,
      })
    }

    const {
      filterable,
      filtered,
      onFilteredChange,
      sorted,
      students,
    } = this.props

    return (
      <div align="left">
        <ReactTable
          filterable={filterable}
          data={students}
          columns={columns}
          className="-highlight"
          defaultPageSize={students.length}
          defaultSorted={sorted}
          defaultFiltered={filtered}
          onFilteredChange={onFilteredChange}
          style={{ fontSize: '10pt' }}
          defaultFilterMethod={(filter, row) =>
            row[filter.id] &&
            row[filter.id]
              .toString()
              .toLowerCase()
              .includes(filter.value.toString().toLowerCase())
          }
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              onClick: (e, handleOriginal) => {
                if (column.Header !== undefined) {
                  this.props.onRowClick(rowInfo)
                }
                if (column.Header === undefined) {
                  //only the checkbox has an undefined header
                  this.props.handleSingleCheckboxChange(rowInfo.index)
                }
                if (handleOriginal) {
                  handleOriginal()
                }
              },
            }
          }}
          getTrProps={(state, rowInfo, column) => {
            const backgroundWhite = '#ffffff'
            const backgroundLightGreen = '#effff4'
            return {
              style: {
                background:
                  rowInfo && rowInfo.original.newStudent
                    ? backgroundLightGreen
                    : backgroundWhite,
              },
            }
          }}
          getTheadProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                fontWeight: 'bold',
                borderBottom: '1px solid #E5E5E5',
              },
            }
          }}
        />
      </div>
    )
  }
}

export default ClassDetailTable
