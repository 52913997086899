import React, { Component } from 'react'
import Composer from 'react-composer'

import { Mutation, withApollo } from 'react-apollo'
import { Redirect } from 'react-router-dom'

import TrialForm from './TrialForm'
import Cookies from 'js-cookie'
import { debug, expansionBarColor, idName, tokenName } from '../../config'

import {
  Button,
  ChooseOpportunityPanel,
  Accordion,
  AccordionDetails,
  ExpandMoreIcon,
  AccordionSummary,
  Flex,
  Paper,
  SnackbarNotification,
  Text,
} from '../../components'

import {
  CREATE_TRIAL,
  UPDATE_OPPORTUNITY,
  GET_FAMILY_MEMBER_ID_FROM_OPPORTUNITY,
  GET_EMPLOYEE,
  GET_LESSON_FROM_ID,
  GET_CLASSROOM_FROM_ID,
  SEND_EMAIL_TO_FAMILY_MEMBER,
  generateTemporaryToken,
} from '../../queries'

import { TrialEmailTemplate } from './Email'
import { GET_LESSON } from '../MakeupView/queries'

class CreateTrialViewPanels extends Component {
  constructor(props) {
    super(props)
    this.state = {
      redirect: false,
      redirectTo: '',
      opportunity: props.opportunity,
      selectedOpportunity: null,
      trial: {
        status: 'SCHEDULED',
        session: 'CURRENT',
      },
      match: props.match,
      dateString: new Date().toISOString(),
      emailInfo: null,
      open: false, //snackbar
    }
  }

  returnToTrialTable = () => {
    this.setState({
      redirect: true,
      redirectTo: '/trial',
    })
  }

  handleTrialChange = event => {
    const { name, value } = event.target
    this.setState({
      trial: {
        ...this.state.trial,
        [name]: value,
      },
    })
  }

  changeSelectedOpportunity = value => {
    this.setState({
      selectedOpportunity: value,
    })
  }

  SendEmailMutation = async id => {
    const { client } = this.props
    const employeeId = Cookies.get(idName)
    const [
      { data: opportunity },
      { data: employee },
      { data: lesson },
      { data: classroom },
    ] = await Promise.all([
      client.query({
        query: GET_FAMILY_MEMBER_ID_FROM_OPPORTUNITY,
        variables: {
          id: this.state.selectedOpportunity
            ? this.state.selectedOpportunity.id
            : this.state.match.params[0],
        },
      }),
      client.query({
        query: GET_EMPLOYEE,
        variables: { id: employeeId },
      }),
      client.query({
        query: GET_LESSON_FROM_ID,
        variables: {
          id: this.state.trial ? this.state.trial.toLessonId : '',
        },
      }),
      client.query({
        query: GET_CLASSROOM_FROM_ID,
        variables: {
          id: this.state.trial.toClassId ? this.state.trial.toClassId : '',
        },
      }),
    ])

    const familyMemberId =
      opportunity.opportunity.student.familyAccount.primaryFamilyMember.id
    const studentName = opportunity.opportunity.student.firstName

    const { data: tokenData } = await client.mutate({
      mutation: generateTemporaryToken,
      variables: {
        token: Cookies.get(tokenName),
        userType: 'FamilyMember',
        userId: familyMemberId,
      },
    })

    await client.mutate({
      mutation: SEND_EMAIL_TO_FAMILY_MEMBER,
      variables: {
        familyMemberId: familyMemberId,
        subject: 'Confirmation of Trial Class at Ardent Academy',
        textMessage: 'Confirmation of Trial',
        htmlMessage: TrialEmailTemplate({
          studentName,
          ...lesson,
          ...classroom,
          ...employee,
          token: tokenData.generateTemporaryToken.token,
          trialId: id,
        }),
        testRecipient: debug ? 'dev@ardentlabs.io' : '',
        bccAddresses: [employee.employee.workEmail],
      },
    })
  }

  render() {
    const { trial } = this.state
    const { notes, status, toClassId, toLessonId } = trial

    if (this.state.redirect) {
      return <Redirect push to={this.state.redirectTo} />
    }
    const { classes } = this.props
    const employeeId = Cookies.get(idName)
    const handleSubmission = async (OpporMutation, CreateTrialMutation) => {
      if (toClassId && toLessonId) {
        const { client } = this.props
        const { data } = await CreateTrialMutation({
          variables: {
            input: {
              status: this.state.trial.status,
              toLessonId: this.state.trial.toLessonId,
              opportunityId: this.state.selectedOpportunity
                ? this.state.selectedOpportunity.id
                : this.state.match.params[0],
              createdById: employeeId,
              notes: this.state.trial.notes,
            },
          },
        })
        await OpporMutation({
          variables: {
            id: this.state.selectedOpportunity
              ? this.state.selectedOpportunity.id
              : this.state.match.params[0],
            input: {
              stage: 'TRIAL',
              lastContacted: new Date().toISOString(),
            },
          },
        })
        const {
          data: { lesson },
        } = await client.query({
          query: GET_LESSON,
          variables: { id: this.state.trial.toLessonId },
        })
        if (new Date(lesson.startOn) > new Date())
          await this.SendEmailMutation(data.createTrial.id)
        this.returnToTrialTable()
        window.location.reload()
      }
    }

    return (
      <Flex column grow={1} className={classes.root}>
        <br />
        <Text className={classes.display1} variant="h4">
          {'Create Trial'.concat(
            this.state.opportunity
              ? ' for '
                  .concat(this.state.opportunity.student.firstName)
                  .concat(' ')
                  .concat(this.state.opportunity.student.lastName)
              : ''
          )}
        </Text>
        <br />
        <Paper>
          {!this.props.opportunity && (
            <Accordion
              defaultExpanded
              collapseprops={{ style: { overflow: 'visible' } }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                style={{
                  backgroundColor: expansionBarColor,
                }}
                title="Lookup Opportunity (Required)"
              />
              <AccordionDetails className={classes.expansionDetail}>
                <Flex
                  column
                  pt="20px"
                  style={{
                    align: 'center',
                    flexGrow: 1,
                  }}
                >
                  <ChooseOpportunityPanel
                    selectedOpportunity={this.state.selectedOpportunity}
                    changeSelectedOpportunity={this.changeSelectedOpportunity}
                  />
                </Flex>
              </AccordionDetails>
            </Accordion>
          )}

          <Accordion
            defaultExpanded
            collapseprops={{ style: { overflow: 'visible' } }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              style={{
                backgroundColor: expansionBarColor,
              }}
              title="Trial Class Information"
            />
            <AccordionDetails>
              <Flex column grow={1}>
                <TrialForm
                  trial={this.state.trial}
                  handleChange={this.handleTrialChange}
                  toCreateTrial={true}
                  notes={notes}
                  status={status}
                  statusOptions={this.props.trialStatuses}
                  classId={toClassId}
                  lessonId={toLessonId}
                  studentId={this.state.opportunity.student.id}
                />
                <Flex direction={'row-reverse'} p="10px">
                  <Composer
                    components={[
                      <Mutation mutation={UPDATE_OPPORTUNITY}>
                        {() => {}}
                      </Mutation>,
                      <Mutation mutation={CREATE_TRIAL}>{() => {}}</Mutation>,
                    ]}
                  >
                    {([OpporFunction, CTFunction]) => (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          if (
                            !this.state.trial.status ||
                            !this.state.trial.toLessonId ||
                            (!this.props.opportunity &&
                              !this.state.selectedOpportunity)
                          ) {
                            this.setState({ open: true })
                          } else {
                            if (
                              window.confirm(
                                'Are you sure you want to submit? All empty fields will be filled with default or null values'
                              )
                            ) {
                              handleSubmission(OpporFunction, CTFunction)
                            }
                          }
                        }}
                      >
                        Submit
                      </Button>
                    )}
                  </Composer>
                  <Button
                    color="secondary"
                    aria-label="Cancel"
                    className={classes.button}
                    onClick={() => {
                      if (
                        window.confirm(
                          'Do you want to return to the table of trials?'
                        )
                      ) {
                        this.returnToTrialTable()
                      }
                    }}
                    style={{ marginRight: '10px' }}
                  >
                    Cancel
                  </Button>
                  <SnackbarNotification
                    open={this.state.open}
                    handleClose={() => this.setState({ open: false })}
                    message="At least one required field is missing"
                    messageType="error"
                    color="firebrick"
                  />
                </Flex>
              </Flex>
            </AccordionDetails>
          </Accordion>
        </Paper>
      </Flex>
    )
  }
}

export default withApollo(CreateTrialViewPanels)
