import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import ReactTable from 'react-table'
import {
  Button,
  Text,
  Typography,
  TextField,
  MenuItem,
  DateTimePicker,
} from '../../components'
import { GET_EMPLOYEES } from './queries'
import { convertToYMD, convertToYMDHM } from '../../utils/datetime'
import './InvoiceDetail.css'
import { Query } from 'react-apollo'

class InvoiceDetailedPanel extends Component {
  constructor(props) {
    super(props)

    let sortedItems = props.invoice.items
    let ordering = { CLASSROOM: 0, OFFER: 1, DISCOUNT: 2 }
    sortedItems.sort((a, b) => {
      return (
        ordering[a.itemType] - ordering[b.itemType] ||
        a.itemType.localeCompare(b.itemType)
      )
    })

    this.state = {
      sortedItems,
      input: {
        responsibleEmployeeId: this.props.invoice.responsibleEmployee?.id,
        issueDate: this.props.invoice.issueDate,
        status: this.props.invoice.status,
        notes: this.props.invoice.notes,
      },
      editingInfo: false,
      employee: this.props.invoice.responsibleEmployee
        ? `${this.props.invoice.responsibleEmployee.firstName} ${this.props.invoice.responsibleEmployee.lastName}`
        : '',
    }
  }

  render() {
    let invoice = this.props.invoice
    const { editingInfo } = this.state
    const itemColumns = [
      {
        Header: '#',
        Cell: props => props.index + 1,
        width: 30,
      },
      {
        id: 'student',
        Header: 'Student',
        accessor: row =>
          row.student
            ? row.student.firstName + ' ' + row.student.lastName
            : 'Account',
      },
      {
        id: 'item',
        Header: 'Item',
        accessor: row => row.description,
      },
      {
        Header: 'Early Bird',
        accessor: 'earlyBird',
        Cell: props => (props.value === null ? 'N/A' : props.value ? 'Y' : 'N'),
        width: 100,
        style: {
          textAlign: 'center',
        },
      },
      {
        Header: 'Withdrawn',
        accessor: 'withdrawal',
        Cell: props => (props.value === null ? 'N/A' : props.value ? 'Y' : 'N'),
        width: 100,
        style: {
          textAlign: 'center',
        },
      },
      {
        id: 'unitPrice',
        Header: 'Unit Price',
        accessor: row => row.unitPrice.toFixed(2),
        width: 100,
        style: {
          textAlign: 'right',
        },
      },
      {
        Header: 'Quantity',
        accessor: 'quantity',
        width: 100,
        style: {
          textAlign: 'center',
        },
      },
      {
        id: 'price',
        Header: 'Price',
        accessor: 'price',
        Cell: props => <span>{`${props.value.toFixed(2)}`}</span>,
        width: 100,
        style: {
          textAlign: 'right',
        },
      },
      {
        Header: 'Enrolled',
        accessor: 'enrolled',
        width: 100,
        Cell: props => {
          if (props.original.itemType === 'CLASSROOM') {
            return (
              <span style={{ color: props.value ? 'green' : 'red' }}>
                {props.value ? 'Yes' : 'No'}
              </span>
            )
          } else {
            return <span>{'n/a'}</span>
          }
        },
        style: {
          textAlign: 'center',
        },
      },
    ]

    const paymentColumns = [
      {
        Header: '#',
        Cell: props => props.index + 1,
        width: 30,
      },
      {
        Header: 'Transac #',
        accessor: 'identification',
      },
      {
        Header: 'Payee',
        accessor: 'payee',
      },
      {
        Header: 'Payment Method',
        accessor: 'method',
      },
      {
        id: 'amount',
        Header: 'Amount',
        accessor: 'amount',
        Cell: props => <span>{`$${props.value.toFixed(2)}`}</span>,
      },
      {
        id: 'date',
        Header: 'Payment Settlement Date',
        accessor: row => convertToYMD(new Date(row.dateReceived)),
      },
      {
        Header: 'Note',
        accessor: 'note',
      },
    ]
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '5px',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Text variant="h4">Invoice</Text>
          <Button onClick={() => this.props.history.push('/invoice')}>
            back
          </Button>
        </div>
        <div className="innerRowDiv">
          {editingInfo ? (
            <div style={{ display: 'flex' }}>
              <Text>{'Date: '}</Text>
              <DateTimePicker
                name="issueDate"
                label=""
                value={this.state.input.issueDate || null}
                onChange={e =>
                  this.setState({
                    input: { ...this.state.input, issueDate: e.target.value },
                  })
                }
              />
            </div>
          ) : (
            <Text className="innerRowText">
              {'Date: ' + convertToYMDHM(invoice.issueDate)}
            </Text>
          )}

          <Text className="innerRowText">{`ID: ${invoice.invoiceNumber}`}</Text>

          <Text className="innerRowText">
            {`User: ${
              invoice.primaryFamilyMember
                ? `${invoice.primaryFamilyMember.firstName} ${invoice.primaryFamilyMember.lastName}`
                : 'UNKNOWN'
            }`}
          </Text>

          <Text className="innerRowText">
            {`Email: ${
              invoice.primaryFamilyMember
                ? `${invoice.primaryFamilyMember.email || 'UNKNOWN'}`
                : 'UNKNOWN'
            }`}
          </Text>
          <Text className="innerRowText">
            {`Phone: ${
              invoice.primaryFamilyMember
                ? `${invoice.primaryFamilyMember.phoneNumber || 'UNKNOWN'}`
                : 'UNKNOWN'
            }`}
          </Text>
          {editingInfo ? (
            <div style={{ display: 'flex' }}>
              <Text>{'Responsible employee: '}</Text>
              <Query query={GET_EMPLOYEES}>
                {({ data, loading, error }) => {
                  if (loading) return <Text>Loading</Text>
                  if (error) return <Text>{`${error}`}</Text>

                  return (
                    <TextField
                      select
                      defaultValue={''}
                      inputProps={{ style: { paddingTop: '0px' } }}
                      onChange={e => {
                        this.setState({
                          employee: e.target.value,
                        })
                      }}
                      value={this.state.employee}
                      style={{ minWidth: '200px' }}
                    >
                      {data.employees.map((emp, ind) => (
                        <MenuItem
                          key={ind}
                          onClick={e => {
                            this.setState({
                              input: {
                                ...this.state.input,
                                responsibleEmployeeId: emp.id,
                              },
                            })
                          }}
                          value={`${emp.firstName} ${emp.lastName}`}
                        >{`${emp.firstName} ${emp.lastName}`}</MenuItem>
                      ))}
                    </TextField>
                  )
                }}
              </Query>
            </div>
          ) : (
            <Text className="innerRowText">
              {`Responsible employee: ${
                invoice.responsibleEmployee
                  ? `${invoice.responsibleEmployee.firstName} ${invoice.responsibleEmployee.lastName}`
                  : 'n/a'
              }`}
            </Text>
          )}
          {editingInfo ? (
            <div style={{ display: 'flex' }}>
              <Text>{'Invoice Status: '}</Text>
              <TextField
                select
                value={this.state.input.status}
                inputProps={{ style: { paddingTop: '0px' } }}
                onChange={e => {
                  this.setState({
                    input: {
                      ...this.state.input,
                      status: e.target.value,
                    },
                  })
                }}
                style={{ minWidth: '100px' }}
              >
                {[
                  'OPEN',
                  'PAID',
                  'CHARTER',
                  'VOID',
                  'PENDING',
                  'ABANDONED',
                ].map((stat, ind) => (
                  <MenuItem key={ind} value={stat}>
                    {stat}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          ) : (
            <Text className="innerRowText">
              {`Invoice Status: ${invoice.status}`}
            </Text>
          )}
          <div style={{ display: 'flex' }}>
            <Text>{'Notes: '}</Text>
            <TextField
              multiline
              maxRows={4}
              disabled={!editingInfo}
              value={this.state.input.notes}
              inputProps={{ style: { paddingTop: '0px' } }}
              onChange={e => {
                this.setState({
                  input: {
                    ...this.state.input,
                    notes: e.target.value,
                  },
                })
              }}
              style={{ width: '300px' }}
            />
          </div>
          <br />
          <div style={{ display: 'flex' }}>
            <Button
              onClick={() => {
                if (editingInfo) {
                  this.props.updateInvoice(
                    this.props.client,
                    this.props.invoice.id,
                    this.state.input,
                    true
                  )
                } else {
                  this.setState({ editingInfo: true })
                }
              }}
            >
              {editingInfo ? 'Save' : 'Edit'}
            </Button>
            {editingInfo && (
              <Button onClick={() => this.setState({ editingInfo: false })}>
                Cancel
              </Button>
            )}
          </div>
        </div>

        <div>
          {invoice.items.length > 0 && (
            <div className="tableDiv">
              <Typography variant="h4" style={{ padding: '5px' }}>
                {'Items & Discounts'}
              </Typography>
              <ReactTable
                data={this.state.sortedItems}
                columns={itemColumns}
                defaultPageSize={Math.min(10, invoice.items.length)}
                getTdProps={(state, rowInfo, column, instance) => {
                  return {
                    onClick: (e, handleOriginal) => {
                      this.props.setSelectedItem(rowInfo.original)
                    },
                    style: {
                      textAlign: 'left',
                    },
                  }
                }}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                }}
              >
                <Text className="innerRowText">
                  {`Order Total: $${invoice.price.toFixed(2)}`}
                </Text>
                <Text className="innerRowText">{`Balance Total: $${invoice.balance.toFixed(
                  2
                )}`}</Text>
              </div>
            </div>
          )}
          {invoice.payments.length > 0 && (
            <div className="tableDiv">
              <Typography variant="h4" style={{ padding: '5px' }}>
                {'Payment Record'}
              </Typography>
              <ReactTable
                data={invoice.payments}
                columns={paymentColumns}
                defaultPageSize={Math.min(10, invoice.payments.length)}
                getTdProps={(state, rowInfo, column, instance) => {
                  return {
                    onClick: (e, handleOriginal) => {
                      if (rowInfo.original.reconciled) {
                        return null
                      }
                      this.props.setSelectedItem(rowInfo.original)
                    },
                    style: {
                      textAlign: 'left',
                    },
                  }
                }}
              />
            </div>
          )}
          {false && (
            <div className="tableDiv">
              <Typography variant="h4" style={{ padding: '5px' }}>
                {'Refunds'}
              </Typography>
              <ReactTable
                data={invoice.items}
                columns={itemColumns}
                defaultPageSize={Math.min(10, invoice.items.length)}
                getTdProps={(state, rowInfo, column, instance) => {
                  return {
                    onClick: (e, handleOriginal) => {
                      this.props.setSelectedItem(rowInfo.original)
                    },
                    style: {
                      textAlign: 'left',
                    },
                  }
                }}
              />
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default withRouter(InvoiceDetailedPanel)
