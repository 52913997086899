import React, { Component } from 'react'
import ReactTable from 'react-table'
import { convertToMDY } from '../../utils/datetime'

export default class ReconcileTable extends Component {
  recalculateTotals(recons) {
    for (let i = 0; i < recons.length; i++) {
      recons[i].total =
        i === 0
          ? recons[i].amount
          : recons[i - 1].total + recons[i].amount - recons[i].deposit
    }

    return recons
  }

  render() {
    const columns = [
      {
        Header: 'Date',
        accessor: 'dateReconciled',
        Cell: props => (
          <span>{props.value ? convertToMDY(props.value) : 'N/A'}</span>
        ),
      },
      {
        Header: 'Account',
        accessor: 'account',
      },
      {
        Header: 'Items',
        accessor: 'payments',
        Cell: row => <div>{row.value.length}</div>,
      },
      {
        Header: 'Payments',
        accessor: 'amount',
        Cell: row => <span>{row.value ? row.value.toFixed(2) : ''}</span>,
      },
      {
        Header: 'Deposits',
        accessor: 'deposit',
        Cell: row => <span>{row.value ? row.value.toFixed(2) : ''}</span>,
      },
      {
        Header: 'Total',
        accessor: 'total',
        Cell: props => <span>{props.value ? props.value.toFixed(2) : ''}</span>,
      },
    ]
    const { recons } = this.props

    this.recalculateTotals(recons)

    return (
      <div align="left">
        <ReactTable
          sortable={false}
          filterable={false}
          data={recons}
          columns={columns}
          className="-highlight"
          defaultPageSize={25}
          style={{ fontSize: '10pt' }}
          getTrProps={(state, rowInfo, column) => {
            let background = '#ffffff'
            return {
              style: {
                background,
              },
            }
          }}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              onClick: e => {
                if (column.Header !== undefined) {
                  this.props.onRowClick(rowInfo)
                }
              },
            }
          }}
          getTheadProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                fontWeight: 'bold',
                borderBottom: '1px solid #E5E5E5',
              },
            }
          }}
        />
      </div>
    )
  }
}
