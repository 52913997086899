import React, { useState, useCallback } from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'
import { Mutation } from 'react-apollo'

import {
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControlLabel,
} from '../../components'
import { REPAIR_ATTENDEE } from './queries'

const RepairAttendeeDialog = ({ classroomId, refreshWithState }) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [closeAfterSuccess, setCloseAfterSuccess] = useState(true)

  const onCompleted = useCallback(() => {
    refreshWithState()
    if (closeAfterSuccess) {
      setDialogOpen(false)
    }
  }, [closeAfterSuccess, setDialogOpen, refreshWithState])

  if (!classroomId) return null

  return (
    <React.Fragment>
      <Button color="default" onClick={() => setDialogOpen(true)}>
        Repair Attendance Records
      </Button>
      <Mutation
        mutation={REPAIR_ATTENDEE}
        variables={{ id: classroomId }}
        onCompleted={onCompleted}
      >
        {(repairAttendee, { loading }) => (
          <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
            <DialogTitle>Repair Attendance Records</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Attempt to repair attendance records using the class enrollment
                records.
                <br />
                This may take a moment.
              </DialogContentText>
              <FormControlLabel
                control={
                  <Checkbox
                    name="close-after-success"
                    checked={closeAfterSuccess}
                    onClick={() => setCloseAfterSuccess(value => !value)}
                  />
                }
                label="Close when done"
              />
              {loading ? <LinearProgress /> : null}
            </DialogContent>
            <DialogActions>
              <Button
                variant="text"
                color="secondary"
                onClick={() => setDialogOpen(false)}
              >
                Cancel
              </Button>
              <Button
                variant="text"
                color="primary"
                onClick={repairAttendee}
                disabled={loading}
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Mutation>
    </React.Fragment>
  )
}

export default RepairAttendeeDialog
