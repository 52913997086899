import React, { Component } from 'react'

import AddMakeupView from './AddMakeupView'
import MakeupDetailView from './MakeupDetailView'
import MakeupTableView from './MakeupTableView'

import { Route, Switch } from 'react-router-dom'

class MakeupView extends Component {
  render() {
    const { url } = this.props.match
    return (
      <Switch>
        <Route
          path={`${url}/schedule-makeup/*`}
          render={props => <AddMakeupView {...props} student />}
        />
        <Route path={`${url}/schedule-makeup`} component={AddMakeupView} />
        <Route path={`${url}/*`} component={MakeupDetailView} />
        <Route path={url} component={MakeupTableView} />
      </Switch>
    )
  }
}

export default MakeupView
