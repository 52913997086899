import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { Mutation } from 'react-apollo'
import {
  Button,
  Divider,
  Flex,
  SidebarText,
  withStyles,
  SnackbarNotification,
} from '../../components'

import { sideBarStyles } from '../../utils/style'
import { UPDATE_MAKEUP } from './queries'

class MakeupSidebar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      redirect: false,
      snackbar: {
        open: false,
        message: '',
        messageType: '',
        willReload: 0, //0 false, 1 reload, 2 redirect
      },
    }
  }

  scheduleMakeup = () => {
    this.setState({
      redirect: true,
      redirectTo: '/makeup/schedule-makeup',
    })
  }

  render() {
    const { redirect, redirectTo, snackbar } = this.state
    if (redirect) {
      return <Redirect push to={redirectTo} />
    }

    const { classes, makeups, menu, show } = this.props

    const scheduledMakeups = makeups.reduce((accumulator, makeup) => {
      return accumulator + (makeup.status === 'SCHEDULED' ? 1 : 0)
    }, 0)

    const attendedMakeups = makeups.reduce((accumulator, makeup) => {
      return accumulator + (makeup.status === 'ATTENDED' ? 1 : 0)
    }, 0)

    const absentMakeups = makeups.reduce((accumulator, makeup) => {
      return accumulator + (makeup.status === 'ABSENT' ? 1 : 0)
    }, 0)

    return (
      <Flex
        column
        grow={0}
        shrink={0}
        overflow="hidden"
        p="10px"
        style={{
          width: show ? '15.38%' : 0,
          borderRight: `${show ? '1px' : 0} ${'#aaaaaa'} solid`,
          maxWidth: show ? '200px' : 0,
          overflowX: show ? undefined : 'hidden',
          flexShrink: 0,
          textAlign: 'left',
          backgroundColor: '#e5e5e5',
        }}
      >
        <Flex column grow={1} justify="space-between">
          <Flex column>
            <Button aria-label="ScheduleMakeup" onClick={this.scheduleMakeup}>
              Schedule Makeup
            </Button>
            <br />
            <Mutation mutation={UPDATE_MAKEUP}>
              {markAttended => (
                <Button
                  onClick={async () => {
                    if (
                      window.confirm(
                        "Are you sure you want to mark every marked student Attended? This can't be undone."
                      )
                    ) {
                      if (this.props.checkedIds.length === 0) {
                        this.setState({
                          snackbar: {
                            open: true,
                            message: 'Error: You did not check any student',
                            messageType: 'error',
                            willReload: 0,
                          },
                        })
                      } else {
                        await Promise.all(
                          this.props.checkedIds.map(async studentID => {
                            await markAttended({
                              variables: {
                                id: studentID,
                                input: {
                                  status: 'ATTENDED',
                                },
                              },
                            })
                          })
                        )
                        this.setState({
                          snackbar: {
                            open: true,
                            message: 'Marked Attended Successfully',
                            messageType: 'success',
                            willReload: 1,
                          },
                        })
                      }
                    }
                  }}
                >
                  Mark Attended
                </Button>
              )}
            </Mutation>

            <SidebarText
              classes={{
                root: classes.headline,
              }}
              title="Detail View"
            />
            <Divider />
            <SidebarText
              classes={{ root: classes.subheadingOne }}
              title="Total Makeups:"
              value={makeups.length}
            />
            <SidebarText title="Scheduled:" value={scheduledMakeups} />
            <SidebarText title="Attended:" value={attendedMakeups} />
            <SidebarText title="Absent:" value={absentMakeups} />
          </Flex>
          {menu()}
        </Flex>
        <SnackbarNotification
          open={snackbar.open}
          handleClose={() => {
            this.setState({
              snackbar: {
                ...snackbar,
                open: false,
              },
            })
            switch (snackbar.willReload) {
              case 0: //do nothing
                break
              case 1: //reload
                window.location.reload()
                break
              default:
                break
            }
          }}
          message={snackbar.message}
          messageType={snackbar.messageType}
        />
      </Flex>
    )
  }
}

export default withStyles(sideBarStyles)(MakeupSidebar)
