import React, { useState } from 'react'
import { Query, withApollo } from 'react-apollo'
import AssessmentForm from './AssessmentForm'
import { GET_QUALIFY } from './queries'
import {
  Button,
  ErrorMessage,
  AccordionForm,
  Flex,
  LoadingMessage,
  OpportunityForm,
  Paper,
  withStyles,
  StudentDetailForm,
} from '../../components'
import { formatBirthday } from '../../utils/datetime'

/**
 * even though rowinfo is already contains the data, requery so that
 * we can just use assessment id on the url to access the assessment data
 * by going to the url rather than going to the previous view
 * this can be better implemented by using context probably...
 */
const AssessmentDetailView = props => {
  const assessmentId = props.match.params[0]

  return (
    <Query
      fetchPolicy={'cache-and-network'}
      query={GET_QUALIFY}
      variables={{ id: assessmentId }}
    >
      {({ loading, error, data }) => {
        if (loading) return <LoadingMessage />
        if (error) return <ErrorMessage error={error} />
        return <InnerAssessmentDetailView {...props} qualify={data.qualify} />
      }}
    </Query>
  )
}

const InnerAssessmentDetailView = props => {
  const { opportunity } = props.qualify
  //  const [setQualifyInfoChanged] = useState(false)
  const [qualify] = useState({
    ...props.qualify,
    opportunity: {
      ...opportunity,
      student: {
        ...opportunity.student,
        birthdate: formatBirthday(opportunity.student.birthdate),
      },
    },
  })

  // const handleQualifyInfoChange = event => {
  //   const { name, value } = event.target
  //   setQualifyInfoChanged(true)
  //   setQualify({
  //     ...qualify,
  //     [name]: value,
  //   })
  // }

  const returnToAssessmentTable = () => {
    props.history.push('/assessment')
  }

  return (
    <Flex
      column
      grow={1}
      style={{
        textAlign: 'left',
        margin: 'auto',
        width: '67%',
        minWidth: '600px',
        maxWidth: '1000px',
      }}
    >
      <Flex>
        <Button
          variant="text"
          color="primary"
          onClick={returnToAssessmentTable}
        >
          Back to assessments
        </Button>
      </Flex>
      <Paper>
        <div>
          <AccordionForm
            expanded
            overflow
            title="Assessment Info"
            form={
              <Flex
                column
                style={{
                  align: 'center',
                  flexGrow: 1,
                }}
              >
                <AssessmentForm qualify={qualify} />
              </Flex>
            }
          ></AccordionForm>
          {/** Evaluation is merged with form...
          <AccordionForm
            expanded
            overflow
            title="Assessment Evaluation Info (Read Only)"
            form={<AssessmentEvaluationForm qualify={qualify} />}
          />
          */}
        </div>
        {opportunity && (
          <AccordionForm
            expanded
            title="Opportunity Info"
            form={
              <Flex
                column
                style={{
                  align: 'center',
                  flexGrow: 1,
                }}
              >
                <OpportunityForm
                  showStatus
                  opportunity={qualify.opportunity}
                  //handleChange={this.handleOpportunityChange}
                  //opportunityChanged={trialOpportunityChanged}
                  //handleSave={handleOpportunitySubmission}
                  //showSave={true}
                />
              </Flex>
            }
          />
        )}
        {opportunity.student && (
          <AccordionForm
            expanded
            title="Student Info"
            form={<StudentDetailForm student={qualify.opportunity.student} />}
          />
        )}
      </Paper>
    </Flex>
  )
}

const styles = theme => ({
  root: {
    textAlign: 'left',
    margin: 'auto',
    width: '67%',
    minWidth: '600px',
    maxWidth: '1000px',
  },
  display1: {
    textAlign: 'left',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  expansionDetail: {
    overflow: 'visible',
  },
})

export default withApollo(withStyles(styles)(AssessmentDetailView))
