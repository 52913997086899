import React from 'react'
import { MenuItem, TextField } from '../../components'

function CustomSelector(props) {
  return (
    <TextField
      required
      InputProps={{
        readOnly: props.readOnly,
      }}
      name="status"
      label={props.label}
      value={props.selectedOptionId}
      className={props.styles.textField}
      select
      onChange={props.handleChange}
      disabled={props.readOnly}
      SelectProps={{
        MenuProps: {
          className: props.styles.menu,
        },
      }}
      InputLabelProps={{
        formlabelclasses: {
          asterisk: props.styles.asterisk,
        },
        shrink: props.trial.status !== undefined,
      }}
      style={props.styleProps}
    >
      {props.statusOptions
        .filter(status => status !== 'RESCHEDULED')
        .map(option => (
          <MenuItem
            key={option
              .replace(/(\B)[^ ]*/g, match => match.toLowerCase())
              .replace(/_/g, ' ')
              .replace(/^[^ ]/g, match => match.toUpperCase())}
            value={option.toUpperCase().replace(/ /g, '_')}
          >
            {option
              .replace(/(\B)[^ ]*/g, match => match.toLowerCase())
              .replace(/_/g, ' ')
              .replace(/^[^ ]/g, match => match.toUpperCase())}
          </MenuItem>
        ))}
    </TextField>
  )
}

export default CustomSelector
