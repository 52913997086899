export const getDefaultSession = () => {
  const month = new Date().getMonth()
  switch (month) {
    case 1:
    case 2:
    case 3:
      return 'Spring'
    case 4:
    case 5:
    case 6:
      return 'Summer'
    case 7:
    case 8:
    case 9:
      return 'Fall'
    case 10:
    case 11:
    case 0:
      return 'Winter'
    default:
      return ''
  }
}
