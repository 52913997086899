import React from 'react'

import {
  Flex,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '../components'

const StudentTable = props => {
  const students = props.students
  const studentDescriptions = [
    'Status',
    'Name',
    'Grade',
    'Enrolled Courses',
    'Completed Courses',
  ]
  return (
    <Flex
      column
      justify="space-evenly"
      style={{
        align: 'center',
        flexGrow: 1,
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            {studentDescriptions.map(studentDescription => (
              <TableCell
                key={studentDescription}
                style={
                  studentDescription === 'Status' ||
                  studentDescription === 'Name'
                    ? {}
                    : { textAlign: 'center' }
                }
              >
                {studentDescription}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {students.map(student => {
            const enrolledCourses = student.enrollments.reduce(
              (accumulator, enrollment) => {
                return accumulator + (enrollment.classroom.open ? 1 : 0)
              },
              0
            )

            return (
              <TableRow
                hover
                onClick={() => props.onRedirectTo(`/student/${student.id}`)}
                key={student.id}
              >
                <TableCell>
                  {student.enrollmentStatus === 'ACTIVE' ? (
                    <span style={{ color: '#27AE60' }}>{'ACTIVE'}</span>
                  ) : student.enrollmentStatus === 'INACTIVE' ? (
                    <span style={{ color: '#EB5757' }}>{'INACTIVE'}</span>
                  ) : student.enrollmentStatus === 'GRADUATED' ? (
                    <span style={{ color: '#00ADEF' }}>{'GRADUATED'}</span>
                  ) : (
                    <span>{student.enrollmentStatus}</span>
                  )}
                </TableCell>
                <TableCell>{`${student.firstName} ${student.lastName}`}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {student.grade === 0 ? 'K' : student.grade}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {enrolledCourses}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {student.enrollments.length - enrolledCourses}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </Flex>
  )
}
export default StudentTable
