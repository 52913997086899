import React, { Component } from 'react'
import { Query } from 'react-apollo'

import NewsViewSidebar from './NewsViewSidebar'
import NewsTable from './NewsTable'

import { Flex, LoadingMessage, ErrorMessage, Redirect } from '../../components'

import { GET_CLASSROOMS } from './queries'

import { formatTimeRange, convertToMDY } from '../../utils/datetime'

class NewsView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      redirect: false,
      showFilters: true,
      startDateString: new Date().toISOString(),
      endDateString: new Date(
        new Date().setDate(new Date().getDate() - 30)
      ).toISOString(),
      sorted: [
        {
          id: 'code',
        },
      ],
      filtered: [
        {
          id: 'status',
          value: 'OPENED',
        },
      ],
    }
    this.classesByOtherFilters = []
    this.classesFilteredByStatus = []
    this.onlyFilterByStatus = true
    this.previousStatus = this.state.filtered[0].value
  }

  getStatusFilter = () => {
    for (const filter of this.state.filtered) {
      if (filter.id === 'status') {
        return filter.value === 'ALL' ? {} : { status: filter.value }
      }
    }
    return {}
  }

  toggleFilters = () => {
    this.setState(state => ({
      showFilters: !state.showFilters,
      menuAnchor: null,
    }))
  }

  onFilteredChange = filtered => {
    this.setState({
      filtered,
    })
  }

  rowClick = rowInfo => {
    rowInfo &&
      this.setState({
        redirect: true,
        redirectTo: `/announcement/${rowInfo.original.id}`,
      })
  }

  isStatusFilterChanged = currentStatus => {
    return currentStatus !== this.previousStatus
  }

  changeToFilterByStatusOnly = currentFilters => {
    let statusFilter = currentFilters.filter(filter => filter.id === 'status')
    if (this.isStatusFilterChanged(statusFilter[0].value)) {
      currentFilters = statusFilter
      this.onlyFilterByStatus = true
    }
    this.previousStatus = statusFilter[0].value
    return currentFilters
  }

  tableChangeHandler = (filters, filteredClasses) => {
    this.onlyFilterByStatus = false
    this.classesByOtherFilters = filteredClasses
    filters = this.changeToFilterByStatusOnly(filters)
    this.setState({
      filtered: filters,
    })
  }

  render() {
    const { filtered, redirect, redirectTo, showFilters, sorted } = this.state
    if (redirect) {
      return <Redirect push to={redirectTo} />
    }
    return (
      <Query
        fetchPolicy={'cache-and-network'}
        query={GET_CLASSROOMS}
        variables={{
          filter: this.getStatusFilter(),
        }}
      >
        {({ loading, error, data }) => {
          if (loading) return <LoadingMessage />
          if (error) return <ErrorMessage error={error} />

          this.classesFilteredByStatus = data.classrooms.map(classroom => {
            const {
              status,
              title,
              code,
              endDate,
              id,
              centerLocation,
              quarter,
              session,
              allowTrial,
              year,
              startDate,
              finalAccessDate,
              meets,
              enrollmentCount,
              startTime,
              endTime,
              instructorDisplayNames,
            } = classroom

            const instructorName = instructorDisplayNames

            return {
              id: id,
              status: status,
              title: title.slice(title.indexOf('-') + 2, title.length),
              code: code,
              centerLocation: centerLocation && centerLocation.name,
              startDate: convertToMDY(startDate),
              endDate: convertToMDY(endDate),
              year: year,
              finalAccessDate: convertToMDY(finalAccessDate),
              quarter: quarter,
              session: session,
              day: meets,
              time: formatTimeRange(new Date(startTime), new Date(endTime)),
              enrollmentCount: enrollmentCount,
              instructor: instructorName,
              allowTrial: allowTrial ? '✓' : '',
            }
          })

          return (
            <Flex>
              <NewsViewSidebar
                classrooms={
                  this.onlyFilterByStatus
                    ? this.classesFilteredByStatus
                    : this.classesByOtherFilters
                }
              />
              <Flex align="left" column grow={1}>
                <NewsTable
                  classes={this.classesFilteredByStatus}
                  filterable={showFilters}
                  onRowClick={this.rowClick}
                  sorted={sorted}
                  filtered={filtered}
                  onFilteredChange={this.onFilteredChange}
                  updateClasses={this.tableChangeHandler}
                />
              </Flex>
            </Flex>
          )
        }}
      </Query>
    )
  }
}

export default NewsView
