import React from 'react'
import { Query } from 'react-apollo'
import { convertToMDY, formatDayTimeRange } from '../../utils/datetime'
import {
  ErrorMessage,
  LoadingMessage,
  MenuItem,
  TextField,
} from '../../components'

import gql from 'graphql-tag'

const GET_LESSONS_FROM_CLASS = gql`
  query getClassroom($id: ID!) {
    classroom(id: $id) {
      id
      lessons(filter: { lessonPlan: { lessonPlanType: "INSTRUCTION" } }) {
        id
        startOn
        endOn
        lessonPlan {
          id
          order
          title
        }
      }
    }
  }
`

function LessonSelector(props) {
  return (
    <Query
      query={GET_LESSONS_FROM_CLASS}
      skip={!props.classId}
      variables={{
        id: props.classId,
      }}
    >
      {({ loading, error, data }) => {
        if (loading) return <LoadingMessage />
        if (error) return <ErrorMessage error={error} />

        let toLessonChoices = []
        if (props.toClassId) {
          const lessonsCopy = [...data.classroom.lessons]
          if (lessonsCopy.length <= 0) {
            alert(
              'The classroom you selected has no lessons. Please select another one.'
            )
          }
          const SortedToLessons = lessonsCopy.sort(
            (a, b) => a.lessonPlan.order - b.lessonPlan.order
          )
          toLessonChoices = SortedToLessons.map(lesson => {
            return {
              key: lesson.id,
              value: lesson,
            }
          })
        }
        return (
          <TextField
            required
            InputProps={{
              readOnly: props.readOnly,
            }}
            name="toLessonId"
            label={props.toClassId ? 'To Lesson' : 'Select Class First'}
            value={props.selectedLessonId}
            className={props.styles.textField}
            select
            onChange={props.handleChange}
            disabled={
              props.toClassId === undefined ||
              props.trial.status === 'ATTENDED' ||
              props.trial.status === 'NOSHOW' ||
              props.fromTrialDetailView
            }
            SelectProps={{
              MenuProps: {
                className: props.styles.menu,
              },
            }}
            InputLabelProps={{
              formlabelclasses: {
                asterisk: props.styles.asterisk,
              },
              shrink: props.trial.toLessonId,
            }}
            style={props.styleProps}
          >
            {toLessonChoices.map(lessonChoice => (
              <MenuItem key={lessonChoice.key} value={lessonChoice.key}>
                {`${lessonChoice.value.lessonPlan.order}.
                ${convertToMDY(lessonChoice.value.startOn)},
                ${formatDayTimeRange(
                  new Date(lessonChoice.value.startOn),
                  new Date(lessonChoice.value.endOn)
                )}`}
              </MenuItem>
            ))}
          </TextField>
        )
      }}
    </Query>
  )
}

export default LessonSelector
