import React, { useState, useCallback } from 'react'
import { useQuery, useMutation } from 'react-apollo'
import { makeStyles } from '@material-ui/core/styles'
import {
  Flex,
  Paper,
  LoadingMessage,
  ErrorMessage,
  TextField,
  Button,
  DatePicker,
  DateTimePicker,
  SnackbarNotification,
  Redirect,
} from '../../components'
import { GET_LESSON, UPDATE_LESSON } from './queries'

const useStyles = makeStyles(() => ({
  formContainer: {
    width: '65%',
    height: '100%',
  },
  buttonContainer: {
    marginTop: '1.5em',
  },
  inputRowContainer: {
    marginBottom: '1.5em',
  },
  inputRowEndContainer: {
    marginBottom: '2em',
  },
  defaultInputs: {
    width: '12em',
    marginRight: '1em',
  },
  dateTimeInputs: {
    width: '24em',
    marginRight: '1em',
  },
  textInputs: {
    width: '50em',
  },
  longInput: {
    width: '38em',
  },
  endInputs: {
    width: '12em',
  },
  announcementPanel: {
    width: '64em',
    height: '30em',
  },
}))

const LessonFormContainer = React.memo(({ classId, lessonId }) => {
  const processLessonData = useCallback(lesson => {
    return {
      id: lesson.id,
      lessonPlanId: lesson.lessonPlan.id,
      title: lesson.lessonPlan.title,
      summary: lesson.lessonPlan.summary,
      description: lesson.lessonPlan.description,
      order: lesson.lessonPlan.order,
      lessonPlanType: lesson.lessonPlan.lessonPlanType,
      startOn: lesson.startOn,
      dueOn: lesson.dueOn,
      endOn: lesson.endOn,
      previewOn: lesson.previewOn,
      webinarId: lesson.webinarId,
      webinarUrl: lesson.webinarUrl,
      webinarReplay: lesson.webinarReplay,
      officeHourDuration: lesson.officeHourDuration,
      officeHourMeetingId: lesson.officeHourMeetingId,
      primaryOfficeHourOn: lesson.primaryOfficeHourOn,
      makeupOfficeHourOn: lesson.makeupOfficeHourOn,
      // instructors: instructors,
    }
  }, [])

  const { loading, error, data } = useQuery(GET_LESSON, {
    variables: { id: lessonId },
    fetchPolicy: 'network-only',
  })
  if (loading) return <LoadingMessage />
  if (error) return <ErrorMessage error={error} />
  return (
    <LessonEditForm
      classId={classId}
      lessonId={lessonId}
      data={processLessonData(data.lesson)}
    />
  )
})

//Maybe swap to reducer...
const LessonEditForm = ({ classId, lessonId, data }) => {
  const [redirect, setRedirect] = useState(null)
  const [startOn, setStartOn] = useState(data.startOn)
  const [endOn, setEndOn] = useState(data.endOn)
  const [dueOn, setDueOn] = useState(data.dueOn)
  const [previewOn, setPreviewOn] = useState(data.previewOn)
  const [webinarId, setWebinarId] = useState(data.webinarId)
  const [webinarUrl, setWebinarUrl] = useState(data.webinarUrl)
  const [webinarReplay, setWebinarReplay] = useState(data.webinarReplay)
  const [officeHourDuration, setOfficeHourDuration] = useState(
    data.officeHourDuration
  )
  const [officeHourMeetingId, setOfficeHourMeetingId] = useState(
    data.officeHourMeetingId
  )
  const [primaryOfficeHourOn, setPrimaryOfficeHourOn] = useState(
    data.primaryOfficeHourOn
  )
  const [makeupOfficeHourOn, setMakeupOfficeHourOn] = useState(
    data.makeupOfficeHourOn
  )
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    messageType: '',
  })
  const classes = useStyles()

  const handleReset = () => {
    setStartOn(data.startOn)
    setEndOn(data.endOn)
    setDueOn(data.dueOn)
    setPreviewOn(data.previewOn)
    setWebinarId(data.webinarId)
    setWebinarUrl(data.webinarUrl)
    setWebinarReplay(data.webinarReplay)
    setOfficeHourDuration(data.officeHourDuration)
    setOfficeHourMeetingId(data.officeHourMeetingId)
    setPrimaryOfficeHourOn(data.primaryOfficeHourOn)
    setMakeupOfficeHourOn(data.makeupOfficeHourOn)
  }

  const saveLesson = () => {
    setSnackbar({
      open: true,
      message: 'Updating Lesson',
      messageType: 'loading',
    })
    handleSave()
  }

  const handleCancel = () => {
    setRedirect(`/lesson/`)
  }

  const [handleSave] = useMutation(UPDATE_LESSON, {
    variables: {
      id: lessonId,
      input: {
        previewOn: previewOn,
        startOn: startOn,
        dueOn: dueOn,
        endOn: endOn,
        primaryOfficeHourOn: primaryOfficeHourOn,
        makeupOfficeHourOn: makeupOfficeHourOn,
        officeHourDuration: Number(officeHourDuration),
        officeHourMeetingId: officeHourMeetingId,
        webinarId: webinarId,
        webinarUrl: webinarUrl,
        webinarReplay: webinarReplay,
      },
    },
    onError: err => {
      setSnackbar({
        open: true,
        message: err.message,
        messageType: 'error',
      })
    },
    onCompleted: () => {
      setSnackbar({
        open: true,
        message: 'Updated Lesson',
        messageType: 'success',
      })
    },
  })

  return (
    <Paper elevation={0} className={classes.formContainer}>
      {redirect && <Redirect push to={redirect} />}
      <Flex column style={{ width: '64em' }}>
        <Flex className={classes.inputRowContainer}>
          <DateTimePicker
            id={`startOn-${data.id}`}
            label="Start On"
            name={'startOn'}
            value={startOn || ''}
            onChange={e => setStartOn(e.target.value)}
            className={classes.dateTimeInputs}
          />
          <DateTimePicker
            id={`endOn-${data.id}`}
            label="End On"
            value={endOn || ''}
            onChange={e => setEndOn(e.target.value)}
            className={classes.dateTimeInputs}
          />
          <DatePicker
            id={`previewOn-${data.id}`}
            label="Preview Date"
            value={previewOn || ''}
            onChange={e => setPreviewOn(e.target.value)}
            className={classes.defaultInputs}
          />
          <DatePicker
            id={`dueOn-${data.id}`}
            label="Due Date"
            value={dueOn || ''}
            onChange={e => setDueOn(e.target.value)}
            className={classes.endInputs}
          />
        </Flex>
        <Flex>
          <DateTimePicker
            id={`primaryOfficeHourOn-${data.id}`}
            label="Office Hours"
            value={primaryOfficeHourOn || ''}
            onChange={e => setPrimaryOfficeHourOn(e.target.value)}
            className={classes.dateTimeInputs}
            helperText="Optional"
          />
          <DateTimePicker
            id={`makeupOfficeHourOn-${data.id}`}
            label="Makeup Office Hours"
            value={makeupOfficeHourOn || ''}
            onChange={e => setMakeupOfficeHourOn(e.target.value)}
            className={classes.dateTimeInputs}
            helperText="Optional"
          />
          <TextField
            id={`officeHourDuration-${data.id}`}
            label="Duration"
            type="number"
            value={officeHourDuration || ''}
            onChange={e => setOfficeHourDuration(e.target.value)}
            className={classes.defaultInputs}
            helperText="Optional"
          />
          <TextField
            id={`zoomId-${data.id}`}
            label="Zoom ID"
            value={officeHourMeetingId || ''}
            onChange={e => setOfficeHourMeetingId(e.target.value)}
            className={classes.endInputs}
          />
        </Flex>
        <Flex className={classes.inputRowEndContainer}>
          <TextField
            id={`webinarId-${data.id}`}
            label="Webinar ID"
            value={webinarId || ''}
            onChange={e => setWebinarId(e.target.value)}
            className={classes.defaultInputs}
          />
          <TextField
            id={`webinarUrl-${data.id}`}
            label="Webinar Url"
            value={webinarUrl || ''}
            onChange={e => setWebinarUrl(e.target.value)}
            className={classes.longInput}
          />
          <TextField
            id={`webinarReplay-${data.id}`}
            label="Replay Link"
            value={webinarReplay || ''}
            onChange={e => setWebinarReplay(e.target.value)}
            className={classes.longInput}
          />
        </Flex>
        <Flex className={classes.buttonContainer}>
          <Button variant="outlined" color="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            style={{ marginLeft: '1em' }}
            variant="outlined"
            onClick={handleReset}
          >
            Reset
          </Button>
          <Button
            variant="outlined"
            style={{ marginLeft: 'auto' }}
            onClick={saveLesson}
          >
            Save
          </Button>
        </Flex>
      </Flex>
      <SnackbarNotification
        open={snackbar.open}
        handleClose={() =>
          setSnackbar({
            snackbar: {
              ...snackbar,
              open: false,
            },
          })
        }
        message={snackbar.message}
        messageType={snackbar.messageType}
      />
    </Paper>
  )
}

export default LessonFormContainer
