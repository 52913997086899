import React, { useState } from 'react'
import { Query, withApollo } from 'react-apollo'
import { ErrorMessage, LoadingMessage } from '../../components'
import { UPDATE_CLASSROOM, GET_STATUS_ENUM } from './queries'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Flex,
  MenuItem,
  Paper,
  TextField,
} from '../../components'

const ChangeStatusDialog = props => {
  const { classrooms, closeStatusDialog, open, refetch } = props
  const [status, setStatus] = useState('CLOSED')

  const handleSubmit = async () => {
    const { client } = props
    const mutations = classrooms.map(classroom =>
      client.mutate({
        mutation: UPDATE_CLASSROOM,
        variables: {
          id: classroom.id,
          input: {
            status: status,
          },
        },
      })
    )
    await Promise.all(mutations)
    refetch()
    closeStatusDialog()
  }

  return (
    <Dialog open={open} fullWidth={true} maxWidth={'xs'}>
      <DialogTitle>Change Status</DialogTitle>
      <DialogContent>
        <Flex p={'10px'} justify="center">
          <Paper align="center" style={{ padding: '15px', width: '45%' }}>
            <Query query={GET_STATUS_ENUM}>
              {({ loading, data, error }) => {
                if (loading) return <LoadingMessage />
                if (error) return <ErrorMessage error={error} />

                return (
                  <TextField
                    id="status"
                    label="Status"
                    style={{ width: '80%' }}
                    select
                    value={status || ''}
                    name="status"
                    onChange={event => setStatus(event.target.value)}
                  >
                    {data.__type.enumValues.map(option => (
                      <MenuItem key={option.name} value={option.name}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )
              }}
            </Query>
          </Paper>
        </Flex>
      </DialogContent>
      <DialogActions style={{ paddingBottom: '10px', paddingRight: '10px' }}>
        <Button onClick={handleSubmit} color="primary">
          Save
        </Button>
        <Button onClick={closeStatusDialog} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withApollo(ChangeStatusDialog)
