import React from 'react'

import {
  Divider,
  Flex,
  Button,
  SidebarText,
  withStyles,
} from '../../components'
import { sideBarStyles } from '../../utils/style'

const NewsDetailSidebar = React.memo(
  ({ code, classes, handleCreateDialog }) => {
    return (
      <Flex
        column
        grow={1}
        shrink={0}
        p="10px"
        style={{
          width: '15.38%',
          borderRight: `1px '#aaaaaa' solid`,
          maxWidth: '200px',
          overflowX: undefined,
          flexShrink: 0,
          textAlign: 'left',
          backgroundColor: '#e5e5e5',
          height: '100vh',
        }}
      >
        <Flex column grow={1} justify="space-between">
          <Flex column>
            <SidebarText
              classes={{
                root: classes.headline,
              }}
              title={code}
            />
            <Divider style={{ marginBottom: '1em' }} />
            <Button onClick={handleCreateDialog}>Create News</Button>
          </Flex>
        </Flex>
      </Flex>
    )
  }
)

export default withStyles(sideBarStyles)(NewsDetailSidebar)
