import moment from 'moment-timezone'

const customformatDate = (start, end) => {
  return `${moment(start).format('LLLL')} - ${moment(end).format('LT')}`
}

const formatLocation = location => {
  const { name, streetLine1, city, state, isVirtual } = location
  if (isVirtual) return `Ardent ${name}`
  else return `Ardent ${name} (${streetLine1}, ${city}, ${state})`
}

const formatInstruction = location => {
  const { isVirtual } = location
  if (isVirtual) return `ZOOM login credentials`
  return `a reminder`
}

export const TrialEmailTemplate = data => {
  const { studentName, employee, lesson, classroom } = data

  return `
  <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
  <html>
    <head>
      <meta name="viewport" content="width=device-width,initial-scale=1">
      <meta http-equiv="Content-Type" content="text/html charset=UTF-8" />
      <title>Trial Confirmation</title>
        <style>
            html{font-size:62.5%}
            body
            {
              font-family: "Verdana","Geneva","Helvetica",sans-serif;
            }
            #main_container{
              background:white;
              text-align: left;
              font-size:1.2em;
              padding: 1em;
              width:100%;
              max-width: 60em;
              height:auto;
              
            }
            #trial{
              background:lightskyblue;
              border:2px dotted whitesmoke;
              position:relative;
              top:1.5em;
              padding: 1em 2em 1em 2em;
              border-radius:1.5em;
              text-indent:0;
              text-align: left;
              line-height:1.9em;
              width:45%;
            }
            /*div[id="trial"]:first-line{
              font-weight: bold;
            }*/
            
            
            p{
              position:relative;
              top:1.2em;
              /*text-indent:25px;*/
            }
            p.confirm{
              font-style:italic;
              color:dimgray;
            }
            p.opening{
              color:#009ad6;
            }
            
            .closing{
              position:relative;
              top:2.8em;
              line-height: 1.9em;
            }
            
            .footer{
              position:relative;
              top:8em;
              line-height:1.4em;
              font-size:0.8em;
              text-align:center;
            }
            .footer img{ /*currently unused since no footer graphic*/
              width:50%;
              max-width:38em;
            }
          
        </style>
    </head>
    <body>
          <div id="main_container">Dear parents of ${studentName},
            <p class="opening">
              Welcome to Ardent Academy - a vibrant community of motivated students, 
              supportive parents, and dedicated educators. We are thrilled to have you onboard!
            </p>
            <p class="confirm">
              This is to confirm the following trial class for ${studentName}:
            </p>
            <center>
              <div id="trial">
                <strong>
                  ${classroom.title}
                </strong><br/>
                  ${customformatDate(lesson.startOn, lesson.endOn)} <br/>
                  ${formatLocation(classroom.centerLocation)}
              </div>
            </center>
            <p>
              ${classroom.code} is a ${classroom.lessons.length}-lesson course. 
              You are trying lesson ${lesson.lessonPlan.order} of ${
    classroom.code
  }. 
              Per our trial class policy, if you would like to enroll in the class, you will have to pay 
              for the trial class. If you decide not to enroll, you do not need to pay for the trial.
            </p>
            <p>
              We will send you ${formatInstruction(
                classroom.centerLocation
              )} approximately 24 hours before your trial date. 
              Please have your student prepared to have pencils, eraser, and a notebook to take notes during the class. 
              We look forward to working with ${studentName}!<br/><br/>
              Ardently Yours,</p><br/>
              <span class="closing">
                ${employee.firstName} ${employee.lastName}<br/>
                Ardent Care Team
              </span>
          </div><div class="footer">
            <br/>Copyright &copy; 2006-2021 Ardent Academy For Gifted Youth. All Rights Reserved.
          </div>
    </body>
  </html>
    `
}
