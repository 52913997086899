import React, { Component } from 'react'
import { Query, withApollo } from 'react-apollo'
import Cookies from 'js-cookie'
import { idName } from '../config'

import { expansionBarColor } from '../config'

import {
  Button,
  ErrorMessage,
  Flex,
  FormControl,
  FormLabel,
  FormControlLabel,
  InputAdornment,
  LoadingMessage,
  Radio,
  RadioGroup,
  Text,
  TextField,
} from './'
import Select from 'react-select'
import withStyles from './Styles'

import { UPDATE_TRIAL } from '../screens/TrialView/queries'
import { SIMPLE_GET_CLASSROOMS } from '../queries'

const EvaluationForm = props => {
  const dateString = undefined
  return (
    <Query
      query={SIMPLE_GET_CLASSROOMS}
      variables={{
        filter: {
          startDate: { $lteDate: dateString },
          endDate: { $gtDate: dateString },
        },
        sort: { code: 1 },
      }}
    >
      {({ loading, error, data }) => {
        if (loading) return <LoadingMessage />
        if (error) return <ErrorMessage error={error} />
        const sortedCourses = [
          ...new Set(data.classrooms.map(toClass => toClass.course)),
        ]
        return <InnerEvaluationForm {...props} toCourses={sortedCourses} />
      }}
    </Query>
  )
}

class InnerEvaluationForm extends Component {
  constructor(props) {
    super(props)
    this.state = { ...this.props, evaluationChanged: false }
  }

  altHandleChange = name => event => {
    this.handleChange({ target: { name, value: event.value } })
  }

  handleChange = event => {
    const { name, value } = event.target
    this.setState(state => ({
      evaluationChanged: true,
      trial: {
        ...state.trial,
        [name]: value,
      },
    }))
  }

  handleSubmission = async () => {
    const client = this.props.client
    const { trial } = this.state
    const {
      evaluationMentalMath,
      evaluationSkillBuilder,
      evaluationProblemSolving,
      evaluationMotivation,
      evaluationDiscipline,
      quizScore,
      quizTotal,
      quizGrade,
      instructorComment,
      recommendation,
      alternateCourse,
    } = trial
    const employeeId = Cookies.get(idName)
    await client.mutate({
      mutation: UPDATE_TRIAL,
      variables: {
        id: trial.id,
        input: {
          evaluationMentalMath: parseInt(evaluationMentalMath, 10),
          evaluationSkillBuilder: parseInt(evaluationSkillBuilder, 10),
          evaluationProblemSolving: parseInt(evaluationProblemSolving, 10),
          evaluationMotivation: parseInt(evaluationMotivation, 10),
          evaluationDiscipline: parseInt(evaluationDiscipline, 10),
          quizScore: parseInt(quizScore, 10),
          quizTotal: parseInt(quizTotal, 10),
          quizGrade: parseFloat(quizGrade),
          instructorComment: instructorComment,
          recommendation:
            recommendation === 'Yes'
              ? true
              : recommendation === 'No'
              ? false
              : null,
          alternateCourseId: alternateCourse ? alternateCourse.id : '',
          dataEntryById: employeeId,
        },
      },
    })
    this.setState({
      evaluationChanged: false,
    })
  }

  render() {
    const styles = this.props.classes
    const { readOnly, toCourses } = this.props
    const { evaluationChanged, trial } = this.state
    const {
      alternateCourse,
      evaluationDiscipline,
      evaluationMentalMath,
      evaluationMotivation,
      evaluationProblemSolving,
      evaluationSkillBuilder,
      instructorComment,
      quizScore,
      quizTotal,
      recommendation,
    } = trial

    return (
      <Flex
        column
        style={{
          align: 'center',
          flexGrow: 1,
        }}
      >
        <form id="evaluationForm">
          <Flex justify="space-evenly">
            <Flex grow={2} m="20px" style={{ maxWidth: '400px' }} column>
              {[
                {
                  title: 'Mental Math',
                  name: 'evaluationMentalMath',
                  value: evaluationMentalMath,
                },
                {
                  title: 'Skill Builder',
                  name: 'evaluationSkillBuilder',
                  value: evaluationSkillBuilder,
                },
                {
                  title: 'Problem Solving',
                  name: 'evaluationProblemSolving',
                  value: evaluationProblemSolving,
                },
                {
                  title: 'Motivation',
                  name: 'evaluationMotivation',
                  value: evaluationMotivation,
                },
                {
                  title: 'Discipline',
                  name: 'evaluationDiscipline',
                  value: evaluationDiscipline,
                },
              ].map(evaluation => (
                <FormControl
                  component="fieldset"
                  className={styles.formControl}
                >
                  <FormLabel component="legend">{evaluation.title}</FormLabel>
                  <RadioGroup
                    aria-label={evaluation.name}
                    name={evaluation.name}
                    className={styles.group}
                    value={evaluation.value}
                    onChange={this.handleChange}
                    style={{ display: 'flex', flexDirection: 'row' }}
                    disabled={readOnly}
                  >
                    {[...Array(5).keys()].map(num => (
                      <FormControlLabel
                        key={`${evaluation.name}${num}`}
                        value={(num + 1).toString()}
                        control={<Radio color="primary" />}
                        label={(num + 1).toString()}
                      />
                    ))}
                    <FormControlLabel
                      value="-1"
                      control={<Radio color="primary" />}
                      label="N/A"
                    />
                  </RadioGroup>
                  <br />
                </FormControl>
              ))}
            </Flex>
            <Flex grow={2} m={'20px'} style={{ maxWidth: '400px' }} column>
              <FormControl
                component="fieldset"
                className={styles.formControl}
                style={{ paddingLeft: '10px', paddingTop: '10px' }}
              >
                <FormLabel component="legend">Quiz Grade</FormLabel>

                <Flex>
                  <TextField
                    name="quizScore"
                    label="Correct"
                    defaultValue="0"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment variant="filled" position="end">
                          /
                        </InputAdornment>
                      ),
                    }}
                    value={quizScore}
                    onChange={this.handleChange}
                    type="number"
                    className={styles.textField}
                    style={{ maxWidth: '80px', paddingRight: '10px' }}
                    disabled={readOnly}
                  />
                  <TextField
                    name="quizTotal"
                    label="Total"
                    defaultValue="100"
                    value={quizTotal}
                    onChange={this.handleChange}
                    type="number"
                    className={styles.textField}
                    style={{ maxWidth: '80px' }}
                    disabled={readOnly}
                  />
                  <Flex style={{ paddingLeft: '30px' }}>
                    <TextField
                      name=""
                      label="Percent"
                      defaultValue="0"
                      className={styles.textField}
                      style={{ maxWidth: '80px' }}
                      InputProps={{
                        readOnly: true,
                        endAdornment: (
                          <InputAdornment variant="filled" position="end">
                            %
                          </InputAdornment>
                        ),
                      }}
                      value={((quizScore / quizTotal) * 100).toFixed(2)}
                      onChange={this.handleChange}
                      variant="outlined"
                    />
                  </Flex>
                </Flex>
              </FormControl>
              <br />
              <FormControl
                component="fieldset"
                className={styles.formControl}
                style={{ paddingLeft: '10px' }}
              >
                <TextField
                  name="instructorComments"
                  label="Instructor's Comment"
                  InputProps={{
                    readOnly: readOnly,
                  }}
                  multiline
                  maxRows="4"
                  value={instructorComment}
                  className={styles.textField}
                  onChange={this.handleChange}
                />
              </FormControl>
              <br />
              <br />

              <FormControl
                component="fieldset"
                className={styles.formControl}
                style={{ paddingLeft: '10px' }}
              >
                <FormLabel component="legend">Recommended?</FormLabel>
                <RadioGroup
                  aria-label="recommendation"
                  name="recommendation"
                  className={styles.group}
                  value={recommendation}
                  onChange={this.handleChange}
                  style={{ display: 'flex', flexDirection: 'row' }}
                  disabled={readOnly}
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio color="primary" />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="No"
                    control={<Radio color="secondary" />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
              <br />
              <br />
              <FormControl
                component="fieldset"
                className={styles.formControl}
                style={{
                  display: recommendation === 'Yes' ? 'none' : 'inline-flex',
                  paddingLeft: '10px',
                }}
              >
                <FormLabel component="legend">Alternate Course</FormLabel>
                <br />
                <Select
                  onChange={this.altHandleChange('alternateCourse')}
                  options={toCourses
                    .sort(function(a, b) {
                      const alc = a.code.toLowerCase(),
                        blc = b.code.toLowerCase()
                      if (alc === blc) return 0
                      return alc > blc ? 1 : -1
                    })
                    .map(toCourse => ({
                      value: toCourse,
                      label: toCourse.code,
                    }))}
                  placeholder="Search..."
                  value={
                    alternateCourse
                      ? {
                          value: alternateCourse,
                          label: alternateCourse.code,
                        }
                      : null
                  }
                />
              </FormControl>
            </Flex>
          </Flex>
        </form>
        <Flex direction={'row-reverse'} align={'center'}>
          <Button onClick={this.handleSubmission} disabled={!evaluationChanged}>
            Save Changes
          </Button>
          <Text style={{ marginRight: '10px' }}>
            Make sure to verify all information before hitting save!
          </Text>
        </Flex>
      </Flex>
    )
  }
}

const styles = theme => ({
  menu: {
    width: '20%',
  },
  expSummary: {
    backgroundColor: expansionBarColor,
  },
  asterisk: {
    color: 'red',
  },
})

export default withApollo(withStyles(styles)(EvaluationForm))
