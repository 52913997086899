import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'

import { SidebarText, SidebarContainer } from '../../components'

import { sideBarStyles } from '../../utils/style'

const useStyles = makeStyles(sideBarStyles)

const TutorStudentSidebar = ({
  enrollments,
  statusCounts,
  totalStudentsCount,
  selectedEnrollment,
}) => {
  const classes = useStyles()

  const [totalEnrollment, setTotalEnrollment] = useState(0)
  const [totalScheduled, setTotalScheduled] = useState(0)
  const [totalCompleted, setTotalCompleted] = useState(0)
  const [totalToBeScheduled, setTotalToBeScheduled] = useState(0)

  useEffect(() => {
    const totalScheduled = enrollments.reduce((accumulator, enrollment) => {
      return accumulator + (enrollment.scheduledTutorSessionCount !== 0 ? 1 : 0)
    }, 0)
    const totalCompleted = enrollments.reduce((accumulator, enrollment) => {
      return accumulator + (enrollment.scheduledTutorSessionCount === 0 ? 1 : 0)
    }, 0)
    const totalToBeScheduled = enrollments.reduce((accumulator, enrollment) => {
      return (
        accumulator +
        (enrollment.scheduledTutorSessionCount ===
        enrollment.totalTutorSessionCount
          ? 1
          : 0)
      )
    }, 0)

    setTotalEnrollment(enrollments.length)
    setTotalScheduled(totalScheduled)
    setTotalCompleted(totalCompleted)
    setTotalToBeScheduled(totalToBeScheduled)
  }, [enrollments])

  return (
    <SidebarContainer>
      <SidebarText
        classes={{ root: classes.subheadingOne }}
        title="Total Enrollments:"
        value={totalEnrollment}
      />
      <SidebarText title="Inprogress:" value={totalScheduled} />
      <SidebarText title="To be Scheduled:" value={totalToBeScheduled} />
      <SidebarText title="To be Renewed:" value={totalCompleted} />

      {/* <SidebarText title="Active:" value={statusCounts['ACTIVE']} />
            <SidebarText title="Completed:" value={statusCounts['COMPLETED']} />
            <SidebarText
              title="Incomplete:"
              value={statusCounts['INCOMPLET']}
            /> */}
    </SidebarContainer>
  )
}

export default TutorStudentSidebar
