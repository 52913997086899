import React, { Component } from 'react'
import { Mutation, Query, withApollo } from 'react-apollo'
import { CountryRegionData } from 'react-country-region-selector'
import { Link, Redirect } from 'react-router-dom'
import update from 'immutability-helper'

import {
  Button,
  ErrorMessage,
  AccordionForm,
  FamilyDetailForm,
  Flex,
  LoadingMessage,
  SnackbarNotification,
  StudentTable,
  Text,
} from '../../components'

import { isValidPhoneNumber } from '../../utils/phonenumber'

import {
  ARCHIVE_FAMILY_ACCOUNT,
  COUNT_FAMILY_MEMBERS,
  GET_FAMILY_ACCOUNTS,
  GET_FAMILY_ACCOUNT,
  GET_TEMP_TOKEN,
} from './queries'
import { CREATE_INVOICE } from '../InvoiceView/queries'
import Cookies from 'js-cookie'
import { idName, debug } from '../../config'

class FamilyDetailView extends Component {
  render() {
    const familyMemberId = this.props.match.params[0]
    return (
      <Query
        fetchPolicy={'cache-and-network'}
        query={GET_FAMILY_ACCOUNT}
        variables={{ id: familyMemberId }}
      >
        {({ loading, error, data }) => {
          if (loading) return <LoadingMessage />
          if (error) return <ErrorMessage error={error} />

          return (
            <InnerFamilyDetailView
              {...this.props}
              family={data.familyMember.familyAccount}
            />
          )
        }}
      </Query>
    )
  }
}

class InnerFamilyDetailView extends Component {
  constructor(props) {
    super(props)
    let family = props.family
    let primaryFamilyMemberChanged = false
    if (!family.primaryFamilyMember) {
      if (family.familyMembers.length > 0) {
        family = {
          ...family,
          primaryFamilyMember: family.familyMembers[0],
        }
        primaryFamilyMemberChanged = true
      } else {
        family = {
          ...family,
          primaryFamilyMember: {},
        }
      }
    }
    let needsLocationCreation = false
    if (!family.location) {
      needsLocationCreation = true
      family = {
        ...family,
        location: {},
      }
    }
    this.state = {
      familyTabValue: 0,
      familyMemberChanged: false,
      primaryFamilyMemberChanged,
      familyAccountChanged: false,
      needsLocationCreation,
      family: {
        ...family,
        ardentCenter: family.centerLocation && family.centerLocation.id,
      },
      redirect: false,
      deletedFamilyMembers: [],
      actualCountry: CountryRegionData.find(
        c => c[0] === family.location.country
      ),
      snackbar: {
        open: false,
        message: '',
        messageType: '',
      },
      validation: {
        email: {
          error: false,
          message: '',
        },
        phoneNumber: {
          error: false,
          message: '',
        },
      },
    }
  }

  validate = async (field, value) => {
    const { client } = this.props

    if (field === 'email') {
      if (value === '') {
        this.setState(state => ({
          validation: {
            ...state.validation,
            email: {
              error: true,
              message: 'Email is a required field',
            },
          },
        }))
      } else {
        const { data } = await client.query({
          query: COUNT_FAMILY_MEMBERS,
          variables: {
            filter: {
              email: value,
            },
          },
        })
        this.setState(state => ({
          validation: {
            ...state.validation,
            email: {
              error: data.countFamilyMembers || null,
              message: data.countFamilyMembers ? 'Email is already in use' : '',
            },
          },
        }))
      }
    }

    if (field === 'phoneNumber') {
      const phoneNumberString = value.replace(/\D+/g, '')

      if (phoneNumberString === '') {
        this.setState(state => ({
          validation: {
            ...state.validation,
            phoneNumber: {
              error: true,
              message: 'Phone Number is a required field',
            },
          },
        }))
      } else if (!isValidPhoneNumber(phoneNumberString)) {
        this.setState(state => ({
          validation: {
            ...state.validation,
            phoneNumber: {
              error: true,
              message: 'Improper phone number length',
            },
          },
        }))
      } else {
        const { data } = await client.query({
          query: COUNT_FAMILY_MEMBERS,
          variables: {
            filter: {
              phoneNumber: phoneNumberString,
            },
          },
        })
        this.setState(state => ({
          validation: {
            ...state.validation,
            phoneNumber: {
              error: data.countFamilyMembers || null,
              message: data.countFamilyMembers
                ? 'Phone Number is already in use'
                : '',
            },
          },
        }))
      }
    }
  }

  isValid = () => {
    const { validation } = this.state
    for (const field of Object.values(validation)) {
      if (field.error) {
        return false
      }
    }
    return true
  }

  handleTabChange = (event, value) => {
    this.setState({
      familyTabValue: value,
    })
  }

  handleChange = event => {
    const { name, value } = event.target
    this.setState(state => ({
      familyAccountChanged: true,
      family: {
        ...state.family,
        [name]: value,
      },
    }))
  }

  handleLocationChange = event => {
    const { name, value } = event.target
    const { family } = this.state
    let actualCountry = this.state.actualCountry
    if (name === 'country') {
      actualCountry = CountryRegionData.find(c => c[0] === event.target.value)
    }
    this.setState({
      actualCountry,
      familyAccountChanged: true,
      family: {
        ...family,
        location: {
          ...family.location,
          [name]: value,
        },
      },
    })
  }

  handleFamilyMemberChange = index => event => {
    const { name, value } = event.target
    const { family } = this.state
    const { familyMembers, primaryFamilyMember } = family
    this.validate(name, value)
    if (familyMembers[index].id === primaryFamilyMember.id) {
      this.setState({
        familyMemberChanged: true,
        family: {
          ...family,
          familyMembers: update(familyMembers, {
            [index]: {
              [name]: {
                $set: name === 'phoneNumber' ? value.replace(/\D/g, '') : value,
              },
            },
          }),
          primaryFamilyMember: {
            ...primaryFamilyMember,
            [name]: name === 'phoneNumber' ? value.replace(/\D/g, '') : value,
          },
        },
      })
    } else {
      this.setState({
        familyMemberChanged: true,
        family: {
          ...family,
          familyMembers: update(familyMembers, {
            [index]: {
              [name]: {
                $set: name === 'phoneNumber' ? value.replace(/\D/g, '') : value,
              },
            },
          }),
        },
      })
    }
  }

  handleRedirectTo = redirectTo => {
    this.setState({
      redirect: true,
      redirectTo: redirectTo,
    })
  }

  setAsPrimary = familyMember => {
    this.setState(state => ({
      primaryFamilyMemberChanged: true,
      family: {
        ...state.family,
        primaryFamilyMember: familyMember,
      },
    }))
  }

  addFamilyMember = () => {
    const { family } = this.state
    this.setState({
      familyMemberChanged: true,
      family: {
        ...family,
        familyMembers: [...family.familyMembers, { phoneNumber: '' }],
      },
    })
  }

  deleteFamilyMember = index => {
    const { deletedFamilyMembers, family } = this.state
    const newFamilyMembers = [...family.familyMembers]
    const deletedFamilyMember = newFamilyMembers.splice(index, 1)

    this.setState({
      familyMemberChanged: true,
      deletedFamilyMembers: deletedFamilyMember[0].id
        ? deletedFamilyMembers.concat([deletedFamilyMember[0].id])
        : deletedFamilyMembers,
      family: {
        ...family,
        familyMembers: newFamilyMembers,
      },
    })
  }

  returnToFamilyTable() {
    this.setState({
      redirect: true,
      redirectTo: '/family',
    })
  }

  handleFamilyMemberSubmission = async (
    CFMmutation,
    UFMmutation,
    UFAmutation,
    AFMmutation,
    USmutation
  ) => {
    const {
      deletedFamilyMembers,
      family,
      primaryFamilyMemberChanged,
    } = this.state
    const { email, phoneNumber } = family.primaryFamilyMember
    let familyMembers = family.familyMembers
    let primaryFamilyMemberId = family.primaryFamilyMember.id
    await Promise.all(
      familyMembers.map(async (familyMember, index) => {
        const {
          id,
          firstName,
          lastName,
          relation,
          phoneNumber,
          email,
          occupation,
          preferredLanguage,
          educationLevel,
        } = familyMember
        if (id) {
          await UFMmutation({
            variables: {
              id: id,
              input: {
                firstName: firstName,
                lastName: lastName,
                relation: relation,
                phoneNumber: phoneNumber,
                email: email,
                occupation: occupation,
                preferredLanguage: preferredLanguage,
                educationLevel: educationLevel,
              },
            },
            refetchQueries: [
              {
                query: GET_FAMILY_ACCOUNT,
                variables: {
                  id: family.id,
                },
              },
            ],
          })
        } else {
          const isPrimary =
            JSON.stringify(familyMember) ===
            JSON.stringify(family.primaryFamilyMember)
          let CFMreturn = await CFMmutation({
            variables: {
              input: {
                familyAccountId: family.id,
                firstName: firstName,
                lastName: lastName,
                relation: relation,
                phoneNumber: phoneNumber,
                email: email,
                occupation: occupation,
                preferredLanguage: preferredLanguage,
                educationLevel: educationLevel,
              },
            },
            refetchQueries: [
              {
                query: GET_FAMILY_ACCOUNT,
                variables: {
                  id: family.id,
                },
              },
            ],
          })
          familyMembers[index].id = CFMreturn.data.createFamilyMember.id
          if (isPrimary) {
            primaryFamilyMemberId = CFMreturn.data.createFamilyMember.id
          }
        }
      })
    )
    if (primaryFamilyMemberChanged) {
      await UFAmutation({
        variables: {
          id: family.id,
          input: {
            primaryFamilyMemberId,
          },
        },
        refetchQueries: [
          {
            query: GET_FAMILY_ACCOUNT,
            variables: {
              id: family.id,
            },
          },
          {
            query: GET_FAMILY_ACCOUNTS,
          },
        ],
      })
      // change each student's email and phone number to reflect the new primary family member's phone number and email
      family.students.map(student => {
        return USmutation({
          variables: {
            id: student.id,
            input: {
              phoneNumber: phoneNumber,
              email: email,
            },
          },
        })
      })
    }
    deletedFamilyMembers.map(async deletedfamilyMemberId => {
      await AFMmutation({
        variables: {
          id: deletedfamilyMemberId,
        },
        refetchQueries: [
          {
            query: GET_FAMILY_ACCOUNT,
            variables: {
              id: family.id,
            },
          },
        ],
      })
    })
    this.setState({
      family: {
        ...family,
        familyMembers,
        primaryFamilyMember: {
          ...family.primaryFamilyMember,
          id: primaryFamilyMemberId,
        },
      },
      primaryFamilyMemberChanged: false,
      familyMemberChanged: false,
      deletedFamilyMembers: [],
    })
  }

  handleFamilyAccountSubmission = async (UFAmutation, locationMutation) => {
    const { family, needsLocationCreation } = this.state
    const { ardentCenter, location, status } = family
    const { street, city, country, state, zip } = location
    const locationReturn = await locationMutation({
      variables: {
        id: location.id,
        input: {
          street: street,
          city: city,
          country: country,
          state: state,
          zip: zip,
        },
      },
      refetchQueries: [
        {
          query: GET_FAMILY_ACCOUNT,
          variables: {
            id: family.id,
          },
        },
      ],
    })
    if (needsLocationCreation) {
      await UFAmutation({
        variables: {
          id: family.id,
          input: {
            status: status,
            locationId: locationReturn.data.createLocation.id,
            centerLocationId: ardentCenter,
          },
        },
        refetchQueries: [
          {
            query: GET_FAMILY_ACCOUNT,
            variables: {
              id: family.id,
            },
          },
        ],
      })
    } else {
      await UFAmutation({
        variables: {
          id: family.id,
          input: {
            status: status,
            centerLocationId: ardentCenter,
          },
        },
        refetchQueries: [
          {
            query: GET_FAMILY_ACCOUNT,
            variables: {
              id: family.id,
            },
          },
        ],
      })
    }
    this.setState({
      familyAccountChanged: false,
      needsLocationCreation: false,
    })
  }

  createInvoice = async () => {
    const employeeId = Cookies.get(idName)
    const { data } = await this.props.client.mutate({
      mutation: CREATE_INVOICE,
      variables: {
        input: {
          familyAccountId: this.state.family.id,
          price: 0,
          responsibleEmployeeId: employeeId,
        },
      },
    })

    if (data && data.createInvoice.invoiceNumber) {
      this.setState({
        snackbar: {
          open: true,
          message: 'Invoice created, redirecting..',
          messageType: 'success',
        },
      })
      setTimeout(() => {
        this.props.history.push('/invoice/' + data.createInvoice.invoiceNumber)
      }, 1000)
    } else {
      this.setState({
        snackbar: {
          open: true,
          message: 'Error occurred while creating invoice',
          messageType: 'error',
        },
      })
    }
  }

  handleRequestLogin = async id => {
    if (debug) {
      alert('not supported in develop')
      return
    }
    const { data } = await this.props.client.mutate({
      mutation: GET_TEMP_TOKEN,
      variables: { userId: id, userType: 'FamilyMember' },
    })
    if (!data.generateTemporaryToken.errorMessage) {
      window.open(
        `http://solve.ardentlabs.io/landing?token=${data.generateTemporaryToken.token}&familyAcc=${this.state.family.id}`
      )
    } else {
      alert(data.errorMessage)
    }
  }

  render() {
    const {
      actualCountry,
      family,
      familyAccountChanged,
      familyMemberChanged,
      familyTabValue,
      needsLocationCreation,
      primaryFamilyMemberChanged,
      redirect,
      redirectTo,
      snackbar,
      validation,
    } = this.state
    const { primaryFamilyMember, students } = family
    const { firstName, lastName } = primaryFamilyMember
    if (redirect) {
      return <Redirect push to={redirectTo} />
    }

    return (
      <Flex
        column
        grow={1}
        style={{
          textAlign: 'left',
          margin: 'auto',
          width: '67%',
          minWidth: '600px',
          maxWidth: '1000px',
        }}
      >
        <br />
        <Link to="/family">{'< back to Family List'}</Link>
        <br />
        <Text variant="h4">{`${firstName} ${lastName}`}</Text>
        <br />
        <Flex>
          <Mutation mutation={ARCHIVE_FAMILY_ACCOUNT}>
            {(archiveFamilyAccount, { data }) => (
              <Button
                color="secondary"
                aria-label="Archive"
                onClick={() => {
                  if (
                    window.confirm('Are you sure want to archive this family?')
                  ) {
                    archiveFamilyAccount({
                      variables: {
                        id: family.id,
                      },
                      refetchQueries: [{ query: GET_FAMILY_ACCOUNTS }],
                    })
                    this.setState({
                      snackbar: {
                        open: true,
                        message: 'Family archived',
                        messageType: 'success',
                      },
                    })
                  }
                }}
              >
                Archive Family
              </Button>
            )}
          </Mutation>
          <Button
            aria-label="Add Family Member"
            style={{ marginLeft: '10px' }}
            onClick={() => this.addFamilyMember()}
          >
            Add Family Member
          </Button>
          <Button
            aria-label="Add Student"
            style={{ marginLeft: '10px' }}
            component={Link}
            to={`/student/add-new-student/${family.id}`}
          >
            Add Student
          </Button>
          <Button
            aria-label="Create Invoice"
            style={{ marginLeft: '10px' }}
            onClick={this.createInvoice}
          >
            Create Invoice
          </Button>
        </Flex>
        <AccordionForm
          expanded
          title="Students"
          form={
            <StudentTable
              students={students}
              onRedirectTo={this.handleRedirectTo}
            />
          }
        />
        <AccordionForm
          expanded
          title="Family Information"
          form={
            <FamilyDetailForm
              actualCountry={actualCountry}
              family={family}
              familyAccountChanged={familyAccountChanged}
              familyMemberChanged={familyMemberChanged}
              familyTabValue={familyTabValue}
              needsLocationCreation={needsLocationCreation}
              primaryFamilyMemberChanged={primaryFamilyMemberChanged}
              validation={validation}
              // functions
              deleteFamilyMember={this.deleteFamilyMember}
              onChange={this.handleChange}
              onFamilyAccountSubmission={this.handleFamilyAccountSubmission}
              onFamilyMemberChange={this.handleFamilyMemberChange}
              onFamilyMemberSubmission={this.handleFamilyMemberSubmission}
              onLocationChange={this.handleLocationChange}
              onTabChange={this.handleTabChange}
              setAsPrimary={this.setAsPrimary}
              isValid={this.isValid}
              onRequestLogin={this.handleRequestLogin}
            />
          }
        />
        <AccordionForm
          title="Task History"
          form={
            <Flex
              column
              justify="space-evenly"
              style={{
                align: 'center',
                flexGrow: 1,
              }}
            >
              <Text>Under Construction</Text>
            </Flex>
          }
        />
        <SnackbarNotification
          open={snackbar.open}
          handleClose={() => {
            this.setState(state => ({
              ...state,
              snackbar: {
                ...state.snackbar,
                open: false,
              },
              redirect: true,
              redirectTo: '/family',
            }))
          }}
          message={snackbar.message}
          messageType={snackbar.messageType}
        />
      </Flex>
    )
  }
}

export default withApollo(FamilyDetailView)
