import React, { useState, useCallback } from 'react'
import { useQuery, useMutation } from 'react-apollo'
import { withRouter } from 'react-router-dom'

import moment from 'moment-timezone'
import { DragDropContext } from 'react-beautiful-dnd'

import TutorEmployeeDetailSidebar from './TutorEmployeeDetailSidebar'
import TutorEmployeeSchedule from './TutorEmployeeSchedule'
import TutorSessionAttendanceDialog from '../TutorStudentView/TutorSessionAttendanceDialog'
import TutorStudentDialog from '../TutorStudentView/TutorStudentDialog'

import { Flex, SnackbarNotification } from '../../components'
import {
  GET_TUTOR_SESSIONS,
  UPDATE_TUTOR_SESSION,
  GET_TUTOR_EMPLOYEES,
  ARCHIVE_TUTOR_SESSION,
} from './queries'

const DEFAULT_DIALOG_STATE = {
  open: false,
}

const DEFAULT_SNACKBAR_STATE = {
  open: false,
  message: '',
  messageType: '',
}

//todo: export these global funcs to a util file

//return an array of date objects starting from the start of the current week
const getWeek = startDate => {
  const week = []
  const endOfWeek = moment(startDate).endOf('week')
  const startOfWeek = moment(startDate).startOf('week')

  while (startOfWeek.isBefore(endOfWeek)) {
    week.push(startOfWeek.toDate())
    startOfWeek.add(1, 'day')
  }

  return week
}

//return an array of datetime objects between 8am and 8pm of the given day
const getTimes = day => {
  const times = []
  const start = moment(day)
    .startOf('day')
    .add(8, 'hours')
  const end = moment(day)
    .startOf('day')
    .add(20, 'hours')

  while (start.isBefore(end)) {
    times.push(start.toDate())
    start.add(1, 'hour')
  }
  return times
}

//combine the date and given time to a single date object
const getDateTime = (date, time) => {
  const dateTime = new Date(date)
  const timeObject = new Date(time)
  dateTime.setHours(timeObject.getHours())
  dateTime.setMinutes(timeObject.getMinutes())
  dateTime.setSeconds(timeObject.getSeconds())
  return dateTime
}

//return datetime object of the start of the given day
const getStartOfDay = day => {
  return moment(day).startOf('day')
}

//return the datetime of the start of the given hour
const getStartOfHour = hour => {
  return moment(hour).startOf('hour')
}

const TutorEmployeeDetailView = props => {
  const currentEmployeeId = props.match.params[0] || undefined

  const [scheduleType, setScheduleType] = useState('week') //week or day

  const [week] = useState(getWeek(new Date()))
  const [time] = useState(getTimes(new Date()))

  const [tutors, setTutors] = useState(null)
  const [tutorSession, setTutorSession] = useState(null)

  const [unscheduledItems, setUnscheduledItems] = useState(null)
  const [scheduleItems, setScheduleItems] = useState(null)

  const [dialog, setDialog] = useState(DEFAULT_DIALOG_STATE)
  const [attendanceDialog, setAttendanceDialog] = useState(DEFAULT_DIALOG_STATE)
  const [snackbar, setSnackbar] = useState(DEFAULT_SNACKBAR_STATE)

  const [updateTutorSession] = useMutation(UPDATE_TUTOR_SESSION)
  const [archiveTutorSession] = useMutation(ARCHIVE_TUTOR_SESSION)

  const handleSnackbarOperation = async (
    loadingMsg,
    SuccessMsg,
    ErrorMsg,
    operation
  ) => {
    setSnackbar({
      open: true,
      message: loadingMsg,
      messageType: 'loading',
    })
    const success = await operation()
    if (success) {
      setSnackbar({
        open: true,
        message: SuccessMsg,
        messageType: 'success',
      })
    } else {
      setSnackbar({
        open: true,
        message: ErrorMsg,
        messageType: 'error',
      })
    }
  }

  const formatScheduleItems = useCallback(
    sessions => {
      if (sessions) {
        if (scheduleType === 'week') {
          // ds for multiple students
          // object
          // key: studentId
          // value:
          //   student: student object
          //   schedule: map of dates for current week
          //     key: date
          //     value: array of sessions
          const unscheduledItems = []
          const reduced = sessions.reduce((pv, session) => {
            //createing k/v for map
            if (!pv[session.student.id]) {
              pv[session.student.id] = {
                schedule: new Map(week.map(d => [d.toISOString(), []])),
                student: session.student,
              }
            }

            //add to either unscheduled or scheduled
            if (session.status === 'UNKNOWN') {
              unscheduledItems.push(session)
            } else {
              pv[session.student.id].schedule
                .get(getStartOfDay(session.startDateTime).toISOString())
                .push(session)
            }

            return pv
          }, {})
          setScheduleItems(reduced)
          setUnscheduledItems(unscheduledItems)
        } else {
          const unscheduledItems = []
          const reduced = sessions.reduce((pv, session) => {
            //creating k/v for map
            if (!pv[session.student.id]) {
              pv[session.student.id] = {
                schedule: new Map(time.map(d => [d.toISOString(), []])),
                student: session.student,
              }
            }

            //add to either unscheduled or scheduled
            if (session.status === 'UNKNOWN') {
              unscheduledItems.push(session)
            } else {
              pv[session.student.id].schedule
                .get(getStartOfHour(session.startDateTime).toISOString())
                .push(session)
            }

            return pv
          }, {})
          setScheduleItems(reduced)
          setUnscheduledItems(unscheduledItems)
        }
      }
    },
    [time, week, scheduleType]
  )

  const onTutorSessionFetch = useCallback(
    data => {
      formatScheduleItems((data && data.tutorSessions) || [])
    },
    [formatScheduleItems]
  )

  const { loading, error, refetch } = useQuery(GET_TUTOR_SESSIONS, {
    variables: {
      filter: {
        $or: [
          scheduleType === 'week'
            ? {
                $and: [
                  {
                    startDateTime: {
                      $lteDate: week[week.length - 1].toISOString(),
                    },
                  },
                  {
                    startDateTime: { $gteDate: week[0].toISOString() },
                  },
                  { employeeId: currentEmployeeId },
                ],
              }
            : {
                $and: [
                  {
                    startDateTime: {
                      $lteDate: time[time.length - 1].toISOString(),
                    },
                  },
                  {
                    startDateTime: { $gteDate: time[0].toISOString() },
                  },
                  { employeeId: currentEmployeeId },
                ],
              },
          {
            $and: [{ status: 'UNKNOWN' }, { employeeId: currentEmployeeId }],
          },
        ],
      },
    },
    fetchPolicy: 'network-only',
    onCompleted: onTutorSessionFetch,
  })

  const onTutorEmployeeFetch = useCallback(data => {
    setTutors((data && data.employees) || [])
  }, [])

  const { loading: tutorLoading, error: tutorError } = useQuery(
    GET_TUTOR_EMPLOYEES,
    {
      fetchPolicy: 'network-only',
      onCompleted: onTutorEmployeeFetch,
    }
  )

  const handleTutorSessionChange = (e, status = undefined) => {
    //hacky way to handle status with material-ui's menu item component
    if (status) {
      setTutorSession({
        ...tutorSession,
        status: status,
      })
    } else {
      setTutorSession({
        ...tutorSession,
        [(e.currentTarget && e.currentTarget.name) || e.target.name]:
          (e.currentTarget && e.currentTarget.value) || e.target.value,
      })
    }
  }

  const handleAssignTutorSession = async (id, dateTime, unassign = false) => {
    handleSnackbarOperation(
      'Updating tutor session status',
      'Successfully updated tutor session status',
      'Error updating tutor session status',
      async () =>
        await updateTutorSession({
          variables: {
            id: id,
            input: {
              startDateTime: new Date(dateTime).toISOString(), //change later
              status: unassign ? 'UNKNOWN' : 'SCHEDULED',
              duration: 50,
              updateAll: false,
            },
          },
        })
    )
  }

  const handleNextDay = () => {
    if (time) {
      for (let i = 0; i < time.length; i++) {
        time[i] = moment(time[i])
          .add(1, 'days')
          .toDate()
      }
      refetchHandler()
    }
  }

  const handlePreviousDay = () => {
    if (time) {
      for (let i = 0; i < time.length; i++) {
        time[i] = moment(time[i])
          .subtract(1, 'days')
          .toDate()
      }
      refetchHandler()
    }
  }

  const handleNextWeek = () => {
    if (week) {
      //don't really need to set state and rerender
      for (let i = 0; i < week.length; i++) {
        week[i] = moment(week[i])
          .add(1, 'weeks')
          .toDate()
      }
      refetchHandler()
    }
  }

  const handlePreviousWeek = () => {
    if (week) {
      //don't really need to set state and rerender
      for (let i = 0; i < week.length; i++) {
        week[i] = moment(week[i])
          .subtract(1, 'weeks')
          .toDate()
      }
      refetchHandler()
    }
  }

  //need this because putting refetch in useeffect will crash the app
  const refetchHandler = () => {
    refetch()
    setScheduleItems(null)
  }

  const handleScheduleChange = () => {
    refetchHandler()
    setScheduleType(scheduleType === 'week' ? 'day' : 'week')
  }

  const handleOpenDialog = useCallback(e => {
    const parsedSession = JSON.parse(e.currentTarget.value)
    parsedSession.startTime = parsedSession.startDateTime
    setDialog({ open: true })
    setTutorSession(parsedSession)
  }, [])

  const handleCloseDialog = useCallback(() => {
    setDialog({ open: false })
  }, [])

  const handleSubmitDialog = async () => {
    //combine the date and time for the updated tutorSession
    tutorSession.startDateTime = getDateTime(
      tutorSession.startDateTime,
      tutorSession.startTime
    ).toISOString()

    const res = await updateTutorSession({
      variables: {
        id: tutorSession.id,
        input: {
          startDateTime: tutorSession.startDateTime,
          status: tutorSession.status,
          duration: tutorSession.duration,
          summary: tutorSession.summary,
          updateAll: true,
        },
      },
    })

    //local update to avoid network call
    const selectedSession = scheduleItems[tutorSession.student.id].schedule
      .get(tutorSession.droppableId)
      .find(s => s.id === tutorSession.id)
    selectedSession.startDateTime = tutorSession.startDateTime
    selectedSession.status = tutorSession.status
    selectedSession.duration = tutorSession.duration
    selectedSession.summary = tutorSession.summary
    setScheduleItems({ ...scheduleItems })
    handleCloseDialog()
    return !!res
  }

  const handleArchiveTutorSession = async () => {
    const res = await archiveTutorSession({
      variables: {
        id: tutorSession.id,
      },
    })

    //local update to avoid network call'
    const containerItems = scheduleItems[tutorSession.student.id].schedule.get(
      tutorSession.droppableId
    )
    scheduleItems[tutorSession.student.id].schedule.set(
      tutorSession.droppableId,
      containerItems.filter(s => s.id !== tutorSession.id)
    )
    setScheduleItems({ ...scheduleItems })
    handleCloseDialog()
    return !!res
  }

  const handleOpenAttendanceDialog = useCallback(e => {
    const parsedSession = JSON.parse(e.currentTarget.value)
    setAttendanceDialog({ open: true })
    setTutorSession(parsedSession)
  }, [])

  const handleCloseAttendeeDialog = useCallback(() => {
    setAttendanceDialog({ open: false })
  }, [])

  const handleSubmitAttendanceDialog = async () => {
    handleSnackbarOperation(
      'Updating tutor session status',
      'Successfully updated tutor session',
      'Error updating tutor session',
      async () =>
        await updateTutorSession({
          variables: {
            id: tutorSession.id,
            input: {
              status: tutorSession.status,
              summary: tutorSession.summary,
            },
          },
        })
    )
    //local update to avoid network call
    const selectedSessions = scheduleItems[
      tutorSession.student.id
    ].schedule.get(tutorSession.droppableId)
    selectedSessions.find(s => s.id === tutorSession.id).status =
      tutorSession.status
    handleCloseAttendeeDialog()
  }

  const onDragEnd = async result => {
    const { source, destination, draggableId } = result
    if (!destination) {
      return null
    }

    const droppableSrc = source.droppableId
    const droppableDest = destination.droppableId

    if (droppableSrc !== droppableDest) {
      if (droppableSrc === 'unscheduled') {
        const parsedDest = droppableDest.split('~')
        const selectedSession = unscheduledItems.find(a => a.id === draggableId)

        if (selectedSession.student.id === parsedDest[1]) {
          //updating locally
          scheduleItems[parsedDest[1]].schedule
            .get(parsedDest[0])
            .push(selectedSession)
          setUnscheduledItems(
            unscheduledItems.filter(a => a.id !== draggableId)
          )

          selectedSession.startDateTime =
            scheduleType === 'week'
              ? getDateTime(parsedDest[0], selectedSession.startDateTime)
              : parsedDest[0]

          //updating api
          handleAssignTutorSession(
            draggableId,
            scheduleItems[parsedDest[1]].schedule.get(parsedDest[0])[
              scheduleItems[parsedDest[1]].schedule.get(parsedDest[0]).length -
                1
            ].startDateTime
          )
        } else {
          setSnackbar({
            open: true,
            message: 'You can only move sessions between same students',
            messageType: 'error',
          })
        }

        //update here
      } else if (droppableDest === 'unscheduled') {
        const parsedSrc = droppableSrc.split('~')
        const selectedSession = scheduleItems[parsedSrc[1]].schedule
          .get(parsedSrc[0])
          .find(a => a.id === draggableId)

        //updating locally
        unscheduledItems.push(selectedSession)
        scheduleItems[parsedSrc[1]].schedule.set(
          parsedSrc[0],
          scheduleItems[parsedSrc[1]].schedule
            .get(parsedSrc[0])
            .filter(a => a.id !== draggableId)
        )

        //updating api
        handleAssignTutorSession(
          draggableId,
          unscheduledItems[unscheduledItems.length - 1].startDateTime,
          true
        )
      } else {
        const parsedDest = droppableDest.split('~')
        const parsedSrc = droppableSrc.split('~')
        const selectedSessionMap = scheduleItems[parsedSrc[1]].schedule

        const selectedSession = selectedSessionMap
          .get(parsedSrc[0])
          .find(a => a.id === draggableId)

        if (selectedSession.student.id === parsedDest[1]) {
          //updating locally
          selectedSessionMap.get(parsedDest[0]).push(selectedSession)
          selectedSessionMap.set(
            parsedSrc[0],
            selectedSessionMap
              .get(parsedSrc[0])
              .filter(a => a.id !== draggableId)
          )

          selectedSessionMap.get(parsedDest[0])[
            selectedSessionMap.get(parsedDest[0]).length - 1
          ].startDateTime =
            scheduleType === 'week'
              ? getDateTime(
                  parsedDest[0],
                  selectedSessionMap.get(parsedDest[0])[
                    selectedSessionMap.get(parsedDest[0]).length - 1
                  ].startDateTime
                )
              : parsedDest[0]

          selectedSessionMap.get(parsedDest[0])[
            selectedSessionMap.get(parsedDest[0]).length - 1
          ].status = 'SCHEDULED'

          //updating api
          handleAssignTutorSession(
            draggableId,
            selectedSessionMap.get(parsedDest[0])[
              selectedSessionMap.get(parsedDest[0]).length - 1
            ].startDateTime
          )
        } else {
          setSnackbar({
            open: true,
            message: 'You can only move sessions between same students',
            messageType: 'error',
          })
        }
      }
    }
  }

  const handleChangeTutor = async (id, tutor) => {
    handleSnackbarOperation(
      'Updating tutor session',
      'Successfully updated tutor session',
      'Error updating tutor session',
      async () =>
        await updateTutorSession({
          variables: {
            id: id,
            input: {
              employeeId: tutor.id,
              updateAll: true,
            },
          },
        })
    )
  }

  const handleCloseSnackbar = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    })
  }

  if (tutorError && error) {
    return <div>Error...</div>
  }

  if (tutorLoading && loading) {
    return <div>Loading...</div>
  }

  return (
    <Flex flex={1} basis={1} height={'100%'}>
      <SnackbarNotification
        open={snackbar.open}
        handleClose={handleCloseSnackbar}
        message={snackbar.message}
        messageType={snackbar.messageType}
      />
      <DragDropContext onDragEnd={onDragEnd}>
        {unscheduledItems && (
          <TutorEmployeeDetailSidebar
            scheduleType={scheduleType}
            handleScheduleChange={handleScheduleChange}
            unscheduledItems={unscheduledItems}
          />
        )}
        <Flex
          grow={1}
          basis={1}
          ml={'30px'}
          style={{
            display: 'flex',
            align: 'left',
            flexDirection: 'column',
          }}
        >
          {scheduleItems && (
            <TutorEmployeeSchedule
              tutors={tutors}
              week={week}
              time={time}
              scheduleType={scheduleType}
              scheduleItems={scheduleItems}
              handleOpenDialog={handleOpenDialog}
              handleOpenAttendanceDialog={handleOpenAttendanceDialog}
              handleChangeTutor={handleChangeTutor}
              handleNextDay={handleNextDay}
              handlePreviousDay={handlePreviousDay}
              handleNextWeek={handleNextWeek}
              handlePreviousWeek={handlePreviousWeek}
            />
          )}
        </Flex>
      </DragDropContext>
      {tutorSession && dialog.open && (
        <TutorStudentDialog
          dialog={dialog}
          tutorSession={tutorSession}
          student={tutorSession.student}
          handleTutorSessionChange={handleTutorSessionChange}
          handleSubmit={handleSubmitDialog}
          handleArchive={handleArchiveTutorSession}
          handleCloseDialog={handleCloseDialog}
        />
      )}
      {tutorSession && attendanceDialog.open && (
        <TutorSessionAttendanceDialog
          open={attendanceDialog.open}
          tutorSession={tutorSession}
          student={tutorSession.student}
          handleClose={handleCloseAttendeeDialog}
          handleTutorSessionChange={handleTutorSessionChange}
          handleSubmit={handleSubmitAttendanceDialog}
        />
      )}
    </Flex>
  )
}

export default withRouter(TutorEmployeeDetailView)
