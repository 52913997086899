import React from 'react'
import { Query } from 'react-apollo'
import {
  ErrorMessage,
  LoadingMessage,
  MenuItem,
  TextField,
} from '../../components'
import gql from 'graphql-tag'
import Composer from 'react-composer'

const GET_CLASSROOMS = gql`
  query getClasses($filter: Filters, $sort: Sorts) {
    classrooms(filter: $filter, sort: $sort) {
      code
      endDate
      id
      startDate
      status
      title
    }
  }
`

const GET_ENROLLMENTS = gql`
  query getEnrollments($id: ID!) {
    student(id: $id) {
      id
      enrollments {
        id
        classroom {
          id
        }
      }
    }
  }
`

function ClassSelector(props) {
  const { session } = props
  const studentId =
    props.studentId === undefined
      ? props.trial.opportunity.student.id
      : props.studentId
  return (
    <Composer
      components={[
        <Query
          query={GET_CLASSROOMS}
          variables={{
            filter: props.toCreateTrial
              ? {
                  allowTrial: true,
                  status:
                    session === 'CURRENT'
                      ? 'OPENED'
                      : session === 'NEXT'
                      ? 'PUBLISHED'
                      : '',
                }
              : {},
            sort: { code: 1 },
          }}
        >
          {() => {}}
        </Query>,
        <Query query={GET_ENROLLMENTS} variables={{ id: studentId }}>
          {() => {}}
        </Query>,
      ]}
    >
      {([
        {
          loading: classroomsLoading,
          error: classroomsError,
          data: classroomsData,
        },
        {
          loading: enrollmentsLoading,
          error: enrollmentsError,
          data: enrollmentsData,
        },
      ]) => {
        if (classroomsLoading || enrollmentsLoading) return <LoadingMessage />
        if (classroomsError || enrollmentsError)
          return <ErrorMessage error={classroomsError || enrollmentsError} />
        const label = 'To Class'
        const enrolledClasses = new Set()
        enrollmentsData.student.enrollments.forEach(enrollment => {
          enrolledClasses.add(enrollment.classroom.id)
        })
        const data = classroomsData.classrooms.filter(classroom => {
          return !enrolledClasses.has(classroom.id)
        })
        return (
          <TextField
            required
            name="toClassId"
            label={label}
            value={
              props.trial && props.toClassId ? props.toClassId : props.classId
            }
            className={props.styles.textField}
            select
            onChange={props.handleChange}
            disabled={
              props.trial.status === 'ATTENDED' ||
              props.trial.status === 'NOSHOW' ||
              props.fromTrialDetailView
            }
            SelectProps={{
              MenuProps: {
                className: props.styles.menu,
              },
            }}
            InputLabelProps={{
              formlabelclasses: {
                asterisk: props.styles.asterisk,
              },
              shrink: props.classId,
            }}
            style={props.styleProps}
          >
            {props.fromTrialDetailView && (
              <MenuItem key={props.toClassId} value={props.toClassId}>
                {props.toLesson.classroom.title}
              </MenuItem>
            )}
            {data.map(classroom => (
              <MenuItem key={classroom.id} value={classroom.id}>
                {classroom.title}
              </MenuItem>
            ))}
          </TextField>
        )
      }}
    </Composer>
  )
}

export default ClassSelector
