import React, { useState, useRef } from 'react'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import { makeStyles } from '@material-ui/core/styles'
import { StatusIcon } from '../Attendance/Icons'

import { ArrowDropDownIcon, EditIcon, Text } from '../../components'

import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Divider,
  Grow,
  MenuItem,
  MenuList,
  Popper,
  Paper,
  Typography,
} from '@mui/material'

const dotw = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

const useStyles = makeStyles(() => ({
  iconBtn: {
    width: '40px',
    height: '32.5px',
  },
}))

const getWeekItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  background: isDragging && 'lightgreen',
  width: '180px',
  maxWidth: '180px',
  height: '100px',
  maxHeight: '100px',
  alignSelf: 'center',
  justifySelf: 'center',
  ...draggableStyle,
})

const getWeekListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  height: '120px',
  maxHeight: '120px',
  maxWidth: '200px',
  minWidth: '200px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
})

export const DaySlot = props => {
  const {
    id,
    tutors,
    handleChangeTutor,
    session,
    handleOpenDialog,
    handleOpenAttendanceDialog,
  } = props

  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = e => {
    if (anchorRef.current && anchorRef.current.contains(e.target)) {
      return
    }
    setOpen(false)
  }

  const classes = useStyles()

  const tutorName = session.tutor ? session.tutor.firstName : 'UNASSIGNED'
  const tutorFirstName = session.tutor ? session.tutor.firstName : 'UNASSIGNED'
  const tutorLastName = session.tutor ? session.tutor.firstName : 'UNASSIGNED'
  return (
    <Paper variant={'outlined'} style={{ width: '180px', height: '10  0px' }}>
      {' '}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          height: '65px',
        }}
      >
        <Box
          sx={{
            flexDirection: 'column',
            width: '139px',
            height: '65px',
            justifyContent: 'space-evenly',
          }}
        >
          <Typography
            sx={{
              textAlign: 'center',
              fontSize: 16,
              fontWeight: 'bold',
            }}
          >
            {`${
              session.classroom.courseCode
            } - ${session.order.toString().padStart(2, '0')}`}
          </Typography>
          <Typography sx={{ textAlign: 'center', fontSize: 14 }}>
            {session.startDateTime
              ? new Date(session.startDateTime).toLocaleString('en-US', {
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true,
                })
              : 'N/A'}
          </Typography>
        </Box>
        <Divider color="lightgray" orientation="vertical" />
        <ButtonGroup
          style={{ width: '40px', height: '65px' }}
          variant="text"
          aria-label="split button"
          orientation="vertical"
        >
          <Button
            className={classes.iconButton}
            style={{ width: '40px', height: '32.5px' }}
            value={JSON.stringify({
              ...session,
              droppableId: id,
            })}
            color="primary"
            variant="text"
            aria-label="attendance-tutorsession-btn"
            onClick={handleOpenAttendanceDialog}
          >
            <StatusIcon status={session.status} />
          </Button>
          <Button
            className={classes.iconButton}
            value={JSON.stringify({
              ...session,
              droppableId: id,
            })}
            color="primary"
            variant="text"
            aria-label="edit-tutorsession-btn"
            onClick={handleOpenDialog}
          >
            <EditIcon className={classes.icon} />
          </Button>
        </ButtonGroup>
      </Box>
      <Divider />
      <ButtonGroup
        style={{ width: '180px', height: '35px' }}
        variant="text"
        ref={anchorRef}
        aria-label="split button"
      >
        <Button style={{ width: '140px' }}>{tutorName}</Button>
        <Button
          style={{ width: '40px' }}
          color="primary"
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {tutors.map(option => (
                    <MenuItem
                      key={`${option.id}-${session.id}`}
                      value={option}
                      selected={
                        option.firstName === tutorFirstName &&
                        option.lastName === tutorLastName
                      }
                      onClick={e => {
                        //don't need to update state since it's an object
                        //and we don't want to rerender
                        session.tutor = option
                        handleClose(e)
                        handleChangeTutor(session.id, option)
                      }}
                    >
                      {option.firstName} {option.lastName}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Paper>
  )
}

// week schedule
// week container for schedules

// props:
// -> items: map of datetime to tutorsessions

// Container key:
// -> dateTime in ISOString~studentId

export const WeekSchedule = props => {
  const {
    items,
    tutors,
    week,
    handleChangeTutor,
    handleOpenDialog,
    handleOpenAttendanceDialog,
  } = props
  return (
    <div
      style={{
        display: 'flex',
        width: '1600px',
        flexDirection: 'column',
        alignItems: 'center',
        flexWrap: 'wrap',
      }}
    >
      <div
        style={{
          display: 'flex',
          width: '1600px',
          height: '40px',
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: '10px',
        }}
      >
        <Paper
          square
          variant={'outlined'}
          style={{
            width: '200px',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Text color="primary" style={{ fontWeight: 'bold' }}>
            Student
          </Text>
        </Paper>
        {dotw.map((date, index) => (
          <Paper
            key={date}
            square
            variant={'outlined'}
            style={{
              width: '200px',
              height: '40px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Text color="primary" style={{ fontWeight: 'bold' }}>
              {date}{' '}
              {week[index].toLocaleString('default', {
                month: 'numeric',
                day: 'numeric',
              })}
            </Text>
          </Paper>
        ))}
      </div>
      {Object.keys(items).map(studentId => {
        const itemKeys = Array.from(items[studentId].schedule.keys())
        return (
          <div
            key={studentId}
            style={{
              display: 'flex',
              width: '1600px',
              flexDirection: 'row',
              alignItems: 'center',
              marginBottom: '10px',
            }}
          >
            <div
              style={{
                width: '198px',
                height: '120px',
                border: '1px solid #f1f1f1',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {/* extra student info here if needed */}
              <Text color="primary" style={{ fontWeight: 'bold' }}>
                {items[studentId].student.firstName}{' '}
                {items[studentId].student.lastName}
              </Text>
            </div>
            {dotw.map((_date, index) => (
              <Droppable
                key={`${itemKeys[index]}~${studentId}`}
                droppableId={`${itemKeys[index]}~${studentId}`}
              >
                {(provided, snapshot) => (
                  <div
                    style={{
                      display: 'flex',
                      maxWidth: '198px',
                      minWidth: '198px',
                      border: '1px solid #f1f1f1',
                      alignItems: 'space-evenly',
                      justifyContent: 'space-evenly',
                      flexDirection: 'column',
                    }}
                  >
                    <div
                      ref={provided.innerRef}
                      style={getWeekListStyle(snapshot.isDraggingOver)}
                    >
                      {provided.placeholder}
                      {items[studentId].schedule
                        .get(itemKeys[index])
                        .map(item => {
                          return (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getWeekItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                >
                                  {provided.placeholder}
                                  <DaySlot
                                    id={itemKeys[index]}
                                    tutors={tutors}
                                    session={item}
                                    handleChangeTutor={handleChangeTutor}
                                    handleOpenDialog={handleOpenDialog}
                                    handleOpenAttendanceDialog={
                                      handleOpenAttendanceDialog
                                    }
                                  />
                                </div>
                              )}
                            </Draggable>
                          )
                        })}
                    </div>
                  </div>
                )}
              </Droppable>
            ))}
          </div>
        )
      })}
    </div>
  )
}

export default WeekSchedule
