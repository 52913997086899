import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  LoadingMessage,
  ErrorMessage,
  Flex,
  Typography,
  ImageFileUploader,
} from '../../components'

import { Button } from '@material-ui/core'
import { Query, withApollo } from 'react-apollo'
import {
  GET_ATTENDEE,
  UPDATE_ATTENDEE,
  UPDATE_STUDENT,
  GET_STATUS_OPTIONS,
  UPDATE_ATTENDANCE_ISSUE,
  ARCHIVE_ATTENDANCE_ISSUE,
} from './queries'
import { StatusIcon } from './Icons'
import { mapStatusToLabel } from './utils'
import { defaultImageUrl } from '../../utils/defaultImage'
import { s3Config } from '../../config'
import { TextField } from '@material-ui/core'

export const AttendeeHeader = ({ student, client, setImage, image }) => {
  return (
    <Flex style={{ position: 'relative' }}>
      <Flex style={{ left: 0 }}>
        <ImageFileUploader
          src={student.imageUrl ? student.imageUrl : defaultImageUrl}
          alt="student profile"
          style={{ width: '200ppx', height: '200px' }}
          client={client}
          setImage={setImage}
          imageKey={image}
        />
      </Flex>
      <Flex column style={{ right: 0, paddingLeft: '2em' }}>
        <Flex column style={{ position: 'absolute', top: 0 }}>
          <Typography variant="h3">{student.firstName}</Typography>
          <Typography variant="h3">{student.lastName}</Typography>
        </Flex>
        <Flex column style={{ position: 'absolute', bottom: 0 }}>
          <Typography>Grade: {student.grade}</Typography>
          <Typography>School: {student.school}</Typography>
        </Flex>
      </Flex>
    </Flex>
  )
}

const AttendeeForm = ({
  attendee,
  open,
  handleClose,
  refetch,
  client,
  issue,
}) => {
  const { student } = attendee
  const [status, setStatus] = useState(attendee.status)
  const [timeAdjustment, setTimeAdjustment] = React.useState(
    attendee.timeAdjustment
  )
  const [note, setNote] = useState(attendee.note)
  const [image, setImage] = useState(student.image)

  useEffect(() => {
    if (!image) {
      const fileKeyRe = RegExp(`^${s3Config.url}/(\\S+)$`)
      const fileKey = fileKeyRe.exec(defaultImageUrl)[1]
      setImage(fileKey)
    }
  }, [image])

  const handleSubmit = async () => {
    await client.mutate({
      mutation: UPDATE_ATTENDEE,
      variables: {
        id: attendee.id,
        input: {
          status: status,
          note: note,
          timeAdjustment: parseInt(timeAdjustment),
        },
      },
    })

    //Only need to update the student if the student profile picture has been changed
    if (image !== student.image) {
      await client.mutate({
        mutation: UPDATE_STUDENT,
        variables: {
          id: student.id,
          input: {
            image: image,
          },
        },
      })
    }

    //If this is an attendance dialog related to attendance issues...
    if (issue) {
      await client.mutate({
        mutation: UPDATE_ATTENDANCE_ISSUE,
        variables: {
          id: issue.id,
          input: {
            issueStatus: 'RESOLVED',
            description: `Your attendance status has been changed to ${status}`,
          },
        },
      })
    }

    refetch()
    handleClose()
  }

  const handleArchive = async () => {
    if (issue) {
      await client.mutate({
        mutation: ARCHIVE_ATTENDANCE_ISSUE,
        variables: {
          id: issue.id,
        },
      })
      refetch()
      handleClose()
    }
  }

  return (
    <Query query={GET_STATUS_OPTIONS}>
      {({ data, loading, error }) => {
        if (loading) {
          return (
            <Dialog>
              <LoadingMessage />
            </Dialog>
          )
        }
        if (error) {
          return <ErrorMessage error={error} />
        }

        return (
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>
              <AttendeeHeader
                student={student}
                client={client}
                setImage={setImage}
                image={image}
              />
              <hr />
            </DialogTitle>
            <DialogContent>
              <Flex column>
                <Flex>
                  {data.__type.enumValues.map((op, index) => (
                    <MenuItem
                      value={op.name}
                      key={index}
                      selected={op.name === status ? true : false}
                      onClick={() => setStatus(op.name)}
                    >
                      <Flex column align="center">
                        <StatusIcon status={op.name} />
                        {mapStatusToLabel(op.name)}
                      </Flex>
                    </MenuItem>
                  ))}
                </Flex>
                <Flex>
                  <TextField
                    onChange={event => setNote(event.target.value)}
                    value={note}
                    placeholder="Additional Notes"
                    multiline
                    minRows={5}
                    maxRows={5}
                    variant="outlined"
                    fullWidth
                    style={{ marginTop: '2em' }}
                  />
                </Flex>
                {(status === 'LATE' || status === 'EARLY') && (
                  <Flex>
                    <TextField
                      onChange={event => setTimeAdjustment(event.target.value)}
                      value={timeAdjustment}
                      style={{ width: '25%', marginTop: '1em' }}
                      type="number"
                      label={
                        status === 'LATE'
                          ? 'Minutes Late'
                          : 'Minutes Left Early'
                      }
                    />
                  </Flex>
                )}
              </Flex>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleArchive} color="primary">
                Archive
              </Button>
              <Button onClick={handleSubmit} color="primary">
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        )
      }}
    </Query>
  )
}

const AttendanceDialog = ({
  open,
  handleClose,
  attendeeId,
  classroomId,
  data,
  refetch,
  client,
  issue,
  ...rest
}) => {
  return (
    <Query
      query={GET_ATTENDEE}
      variables={{ id: attendeeId }}
      fetchPolicy="no-cache"
    >
      {({ data, loading, error }) => {
        if (loading) {
          return (
            <Dialog>
              <LoadingMessage />
            </Dialog>
          )
        }
        if (error) {
          return <ErrorMessage error={error} />
        }
        return (
          <AttendeeForm
            attendee={data.attendee}
            open={open}
            handleClose={handleClose}
            refetch={refetch}
            client={client}
            issue={issue}
          />
        )
      }}
    </Query>
  )
}

export default withApollo(AttendanceDialog)
