import React, { Component } from 'react'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  TextField,
  Flex,
} from '../../components'
import { FormControl, Input, InputAdornment, InputLabel } from '@mui/material'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Cookies from 'js-cookie'
import { idName } from '../../config'
import { withRouter } from 'react-router-dom'
class AddRefundForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      errorMsg: '',
      filteredItems: [],
      checkedItems: [],
      loading: false,
      withdrawAmount: 0,
      withdrawMethod: '',
      grossAmount: 0,
      notes: '',
    }
  }

  componentDidMount() {
    const filteredItems = this.props.invoice.items.filter(
      item => !item.withdrawal && item.unitPrice > 0
    )
    let checkedItems = filteredItems.map(() => false)
    this.setState({ filteredItems, checkedItems })
  }

  setClicked = ind => {
    const { grossAmount, filteredItems, loading } = this.state
    if (loading) {
      return
    }
    let newCheckedItems = this.state.checkedItems
    newCheckedItems[ind] = !newCheckedItems[ind]

    let newFilteredItems = filteredItems
    newFilteredItems[ind].withdrawalAmount = filteredItems[ind].price
    this.setState({
      checkedItems: newCheckedItems,
      grossAmount: newCheckedItems[ind]
        ? grossAmount + filteredItems[ind].price
        : grossAmount - filteredItems[ind].price,
    })
  }

  handleSubmit = async () => {
    const { filteredItems, checkedItems, grossAmount, notes } = this.state

    const { client, invoice } = this.props
    const employeeId = Cookies.get(idName)
    this.props.openSnack('Creating credit...', 'other', false)

    if (!grossAmount) {
      this.setState({ errorMsg: 'Nothing selected.' })
      return
    }
    this.setState({ errorMsg: '' })
    this.setState({ loading: true })
    let selectedItems = []

    for (let i = 0; i < checkedItems.length; i++) {
      if (checkedItems[i]) {
        selectedItems.push({
          id: filteredItems[i].id,
          withdrawalAmount: filteredItems[i].withdrawalAmount || 0,
        })
      }
    }
    if (grossAmount > 0) {
      await this.props.withdrawItems(client, selectedItems)
      const num = await this.props.createCredit(client, {
        invoiceId: invoice.id,
        familyAccountId: invoice.familyAccount.id,
        responsibleEmployeeId: employeeId,
        issueDate: new Date().toISOString(),
        amount: grossAmount,
        balance: grossAmount,
        creditType: 'WITHDRAWAL',
        status: 'PAID',
        notes,
      })
      this.props.history.push(`/credit/${num}`)
    }

    this.props.close()
    this.props.openSnack('Credit created, refreshing...', 'success', true)
  }

  render() {
    const {
      errorMsg,
      editing,
      loading,
      filteredItems,
      checkedItems,
      grossAmount,
      notes,
    } = this.state
    return (
      <Dialog open={this.props.open} fullWidth={true} maxWidth={'lg'}>
        <DialogTitle>{'Credit Memo'}</DialogTitle>
        <DialogContent>
          <Flex direction="column" align="flex-start">
            <div style={{ color: 'red' }}>{errorMsg}</div>
            <TableContainer component={Paper}>
              <Table style={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Selected</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Unit Price</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Total</TableCell>
                    <TableCell>Withdrawal</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredItems.map((item, ind) => (
                    <TableRow
                      hover
                      key={`${item.id}`}
                      onClick={() => this.setClicked(ind)}
                    >
                      <TableCell component="th" scope="row">
                        <Checkbox checked={checkedItems[ind]} />
                      </TableCell>
                      <TableCell>{item.description}</TableCell>
                      <TableCell>{item.unitPrice}</TableCell>
                      <TableCell>{item.quantity}</TableCell>
                      <TableCell>{item.price}</TableCell>
                      <TableCell
                        onClick={e => {
                          e.stopPropagation()
                        }}
                      >
                        <FormControl
                          fullWidth
                          variant="standard"
                          style={{ width: '25%' }}
                        >
                          {/* <InputLabel htmlFor="standard-adornment-amount">
                            Credit Amount
                          </InputLabel> */}
                          <Input
                            id="standard-adornment-amount"
                            value={filteredItems[ind].withdrawalAmount || 0}
                            onChange={event => {
                              const temp = filteredItems
                              const v = event.target.value

                              const old =
                                filteredItems[ind].withdrawalAmount || 0

                              temp[ind].withdrawalAmount = v

                              this.setState({
                                filteredItems: temp,
                                grossAmount: this.state.grossAmount + (v - old),
                              })
                            }}
                            disabled={!checkedItems[ind]}
                            startAdornment={
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <br />
            <TextField
              multiline
              label="Notes"
              minRows={4}
              variant="filled"
              value={notes}
              onChange={v => this.setState({ notes: v.target.value })}
            />
            <br />
            <FormControl
              fullWidth
              sx={{ m: 1 }}
              variant="standard"
              style={{ width: '10%' }}
            >
              <InputLabel htmlFor="standard-adornment-amount">
                Credit Amount
              </InputLabel>
              <Input
                id="standard-adornment-amount"
                value={grossAmount}
                disabled
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
              />
            </FormControl>
          </Flex>
        </DialogContent>
        <DialogActions style={{ paddingBottom: '10px', paddingRight: '10px' }}>
          <Button
            color="primary"
            disabled={loading}
            onClick={this.handleSubmit}
          >
            {loading ? 'loading...' : editing ? 'Update' : 'Create'}
          </Button>
          <Button
            onClick={this.props.close}
            disabled={loading}
            color="secondary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withRouter(AddRefundForm)
