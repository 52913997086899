import React from 'react'
import { Avatar } from '../components'
import { makeStyles } from '@material-ui/core/styles'

export const ProfilePicture = ({
  src,
  gender,
  firstName = '',
  lastName = '',
  text = '',
  ...rest
}) => {
  const defaultImageLink =
    'https://solve-app-dev.s3-us-west-1.amazonaws.com/f7024393-8d2c-4d07-8c02-d01d0c89e0ab_generic-07.png'
  const useStyles = makeStyles(() => ({
    male: {
      color: '#FFFFFF',
      backgroundColor: '#21B5FF',
    },
    female: {
      color: '#FFFFFF',
      backgroundColor: '#FF89F5',
    },
    other: {
      color: '#FFFFFF',
    },
  }))
  const classes = useStyles()

  if (src !== defaultImageLink)
    return (
      <Avatar src={src} {...rest}>
        {text}
      </Avatar>
    )
  else {
    let initials = ''

    if (firstName.length > 0 && lastName.length > 0) {
      initials = firstName[0].toUpperCase() + lastName[0].toUpperCase()
      return (
        <Avatar
          className={
            gender === 'MALE'
              ? classes.male
              : gender === 'FEMALE'
              ? classes.female
              : classes.other
          }
          {...rest}
        >
          {initials}
        </Avatar>
      )
    } else return <Avatar src="defaultImageLink">{text}</Avatar>
  }
}

export default ProfilePicture
