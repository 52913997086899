import React, { Component } from 'react'
import { Mutation, withApollo } from 'react-apollo'
import { Link, Redirect } from 'react-router-dom'
import gql from 'graphql-tag'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import AsyncSelect from 'react-select/async'

import {
  Button,
  Flex,
  Paper,
  SnackbarNotification,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Text,
} from '../../components'

import { convertPhoneNumber } from '../../utils'
import { GET_FAMILY_ACCOUNTS } from './queries'

const UPDATE_STUDENT = gql`
  mutation updateStudent($id: ID!, $input: UpdateStudentInput!) {
    updateStudent(id: $id, input: $input) {
      id
      familyAccount {
        id
      }
    }
  }
`

class ChangeFamilyView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentValue: '',
      selectedAccount: null,
      redirect: false,
      snackbar: {
        open: false,
        message: '',
        messageType: '',
        willRedirect: false,
      },
    }
  }

  returnToDetailView = () => {
    this.setState({
      redirect: true,
      redirectTo: `/student/${this.props.match.params[0]}`,
    })
  }

  changeSelectedAccount = value => {
    this.setState({
      selectedAccount: value,
    })
  }

  handleInputChange = value => {
    this.setState({
      currentValue: value,
    })
  }

  matchesPrimaryFamilyMember = familyAccount => {
    const {
      firstName,
      lastName,
    } = this.state.selectedAccount.primaryFamilyMember
    const {
      firstName: otherFirstName,
      lastName: otherLastName,
    } = familyAccount.primaryFamilyMember

    return firstName === otherFirstName && lastName === otherLastName
  }

  getFamilyAccounts = async () => {
    const { client } = this.props
    const { currentValue } = this.state
    const {
      data: { familyAccounts },
    } = await client.query({
      query: GET_FAMILY_ACCOUNTS,
      variables: {
        filter: {
          primaryFamilyMember: {
            $name: currentValue,
          },
        },
        sort: {
          primaryFamilyMember: {
            firstName: 1,
            lastName: 2,
          },
        },
        page: {
          limit: 8,
        },
      },
    })

    this.setState({
      familyAccounts,
    })

    return familyAccounts.map(familyAccount => {
      const { firstName, lastName } = familyAccount.primaryFamilyMember
      return {
        value: familyAccount,
        label: `${firstName} ${lastName}`,
      }
    })
  }

  render() {
    const {
      currentValue,
      familyAccounts,
      redirect,
      redirectTo,
      selectedAccount,
      snackbar,
    } = this.state

    if (redirect) {
      return <Redirect push to={redirectTo} />
    }
    const { match } = this.props

    return (
      <Container>
        <Text variant="h6" style={{ textAlign: 'center' }}>
          Change Family this Student is associated with
        </Text>
        <Paper>
          <Flex
            column
            style={{
              align: 'center',
              flexGrow: 1,
            }}
            p="20px"
          >
            <Text variant="subtitle1" style={{ textAlign: 'center' }}>
              Search for Existing Family Member to link to the Student
            </Text>
            <br />
            <AsyncSelect
              autoFocus={!selectedAccount}
              isLoading={!selectedAccount}
              loadOptions={currentValue.length >= 2 && this.getFamilyAccounts}
              menuIsOpen={currentValue.length >= 2}
              placeholder="Search..."
              onChange={option => this.changeSelectedAccount(option.value)}
              onInputChange={this.handleInputChange}
              inputValue={currentValue}
              value={
                selectedAccount
                  ? {
                      value: selectedAccount,
                      label: `${selectedAccount.primaryFamilyMember.firstName} ${selectedAccount.primaryFamilyMember.lastName}`,
                    }
                  : null
              }
            />
            <br />
            {selectedAccount && (
              <div>
                <Text variant="subtitle1">Search Results</Text>
                <StyledTable>
                  <TableHead>
                    <TableRow>
                      <TableCell>First Name</TableCell>
                      <TableCell>Last Name</TableCell>
                      <TableCell>Phone Number</TableCell>
                      <TableCell>Email</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {familyAccounts.map(familyAccount => {
                      if (this.matchesPrimaryFamilyMember(familyAccount)) {
                        const {
                          firstName,
                          lastName,
                          phoneNumber,
                          email,
                        } = familyAccount.primaryFamilyMember
                        return (
                          <TableRow
                            hover
                            onClick={() =>
                              this.changeSelectedAccount(familyAccount)
                            }
                            selected={familyAccount.id === selectedAccount.id}
                            key={familyAccount.id}
                          >
                            <TableCell>{firstName}</TableCell>
                            <TableCell>{lastName}</TableCell>
                            <TableCell>
                              {convertPhoneNumber(phoneNumber)}
                            </TableCell>
                            <TableCell>{email}</TableCell>
                          </TableRow>
                        )
                      }
                      return null
                    })}
                  </TableBody>
                </StyledTable>
                <Flex direction="row-reverse" align="center">
                  <Mutation mutation={UPDATE_STUDENT}>
                    {(updateStudent, data) => (
                      <Button
                        onClick={async () => {
                          if (
                            // TODO(AFD-422): Replace confirms with dialogs
                            window.confirm(
                              'Do you want to assign this student to the currently selected family?'
                            )
                          ) {
                            await updateStudent({
                              variables: {
                                id: match.params[0],
                                input: {
                                  familyAccountId: selectedAccount.id,
                                  phoneNumber:
                                    selectedAccount.primaryFamilyMember
                                      .phoneNumber,
                                  email:
                                    selectedAccount.primaryFamilyMember.email,
                                },
                              },
                            })
                            this.setState({
                              snackbar: {
                                open: true,
                                message: 'Student reassigned',
                                messageType: 'other',
                                willRedirect: true,
                              },
                            })
                          }
                        }}
                      >
                        Confirm
                      </Button>
                    )}
                  </Mutation>
                  <Button
                    color="secondary"
                    component={Link}
                    to={`/student/${match.params[0]}`}
                    style={{ marginRight: '10px' }}
                  >
                    Cancel
                  </Button>
                </Flex>
              </div>
            )}
            {!selectedAccount && (
              <Flex direction="row-reverse" align="center">
                <Button
                  color="secondary"
                  aria-label="Cancel"
                  component={Link}
                  to={`/student/${match.params[0]}`}
                  style={{ marginRight: '10px' }}
                >
                  Cancel
                </Button>
              </Flex>
            )}
          </Flex>
          <SnackbarNotification
            open={snackbar.open}
            handleClose={() => {
              this.setState({
                snackbar: {
                  ...snackbar,
                  open: false,
                },
              })
              if (snackbar.willRedirect) this.returnToDetailView()
            }}
            message={snackbar.message}
            messageType={snackbar.messageType}
          />
        </Paper>
      </Container>
    )
  }
}

ChangeFamilyView.propTypes = {
  match: PropTypes.any,
}

const Container = styled(Flex).attrs({
  column: true,
  grow: 1,
})`
  text-align: left;
  margin: auto;
  width: 67%;
  min-width: 600px;
  max-width: 1000px;
  padding-top: 1em;
`

const StyledTable = styled(Table)`
  margin-bottom: 1em;
`

export default withApollo(ChangeFamilyView)
