import React, { useState, useCallback } from 'react'
import { Query, ApolloConsumer } from 'react-apollo'
import Composer from 'react-composer'
import draftToHtml from 'draftjs-to-html'
import { convertToRaw } from 'draft-js'

import { ErrorMessage, Flex, LoadingMessage } from '../../components'
import PostsViewFeed from './PostsViewFeed'
import PostsViewSidebar from './PostsViewSidebar'
import PostsDialog from './PostsDialog'
import {
  GET_POSTS,
  GET_EMPLOYEES,
  GET_POST_TYPE_OPTIONS,
  CREATE_POST,
  UPDATE_POST,
  ARCHIVE_POST,
} from './queries'

const PostsView = props => {
  return (
    <ApolloConsumer>
      {client => (
        <Composer
          components={[
            <Query query={GET_POSTS}>
              {function() {
                return ''
              }}
            </Query>,
            <Query query={GET_EMPLOYEES}>
              {function() {
                return ''
              }}
            </Query>,
            <Query query={GET_POST_TYPE_OPTIONS}>
              {function() {
                return ''
              }}
            </Query>,
          ]}
        >
          {([
            { loading: postsLoading, error: postsError, data: postsData },
            {
              loading: employeeLoading,
              error: employeeError,
              data: employeeData,
            },
            {
              loading: postTypesLoading,
              error: postTypesError,
              data: postTypesData,
            },
          ]) => {
            if (postsLoading || employeeLoading || postTypesLoading)
              return <LoadingMessage />
            if (postsError || employeeError || postTypesError)
              return (
                <ErrorMessage
                  error={postsError || employeeError || postTypesError}
                />
              )
            const postTypes = postTypesData.__type.enumValues.map(
              enumValues => enumValues.name
            )
            return (
              <InnerPostView
                client={client}
                employeeData={employeeData}
                postTypes={postTypes}
                postsData={postsData.posts}
              />
            )
          }}
        </Composer>
      )}
    </ApolloConsumer>
  )
}

const InnerPostView = ({
  client,
  employeeData,
  audienceStatuses,
  postTypes,
  postsData,
}) => {
  const [dialog, setDialog] = useState({
    open: false,
    state: 'create',
  })
  const [typeFilter, setTypeFilter] = useState('ALL')
  const [activeFilter, setActiveFilter] = useState(false)

  const handleOpenCreateDialog = () => {
    setDialog({ state: 'create', open: true })
  }

  const handleOpenEditDialog = data => {
    setDialog({ state: 'edit', open: true, data: data })
  }

  const handleCloseDialog = () => {
    setDialog({ ...dialog, open: false })
  }

  const createPartialPost = useCallback(async (postInput, client) => {
    const { content } = postInput
    await client.mutate({
      mutation: CREATE_POST,
      variables: {
        input: {
          ...postInput,
          content: draftToHtml(convertToRaw(content.getCurrentContent())),
          published: true,
        },
      },
      refetchQueries: [
        {
          query: GET_POSTS,
        },
      ],
    })
  }, [])

  const createPost = useCallback(
    async (postInput, client) => {
      await createPartialPost(postInput, client)
    },
    [createPartialPost]
  )

  const updatePartialPost = useCallback(async (postId, postInput, client) => {
    const { content } = postInput
    await client.mutate({
      mutation: UPDATE_POST,
      variables: {
        id: postId,
        input: {
          ...postInput,
          content: draftToHtml(convertToRaw(content.getCurrentContent())),
          published: true,
        },
        refetchQueries: [
          {
            query: GET_POSTS,
          },
        ],
      },
    })
  }, [])

  const updatePost = useCallback(
    async (postId, postInput, client) => {
      await updatePartialPost(postId, postInput, client)
    },
    [updatePartialPost]
  )

  const archivePost = useCallback(async (postId, client) => {
    await client.mutate({
      mutation: ARCHIVE_POST,
      variables: { id: postId },
      refetchQueries: [{ query: GET_POSTS }],
    })
  }, [])

  return (
    <Flex>
      <PostsDialog
        client={client}
        dialog={dialog}
        employees={employeeData.employees}
        postTypes={postTypes}
        handleCloseDialog={handleCloseDialog}
        createPost={createPost}
        updatePost={updatePost}
        archivePost={archivePost}
      />
      <PostsViewSidebar
        handleOpenCreateDialog={handleOpenCreateDialog}
        handleOpenEditDialog={handleOpenEditDialog}
        setTypeFilter={setTypeFilter}
        setActiveFilter={setActiveFilter}
        postTypes={postTypes}
        typeFilter={typeFilter}
        activeFilter={activeFilter}
      />
      <Flex align="left" column grow={1}>
        <PostsViewFeed
          postsData={postsData
            .sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn))
            .filter(post => {
              return (
                (typeFilter === 'ALL' ? true : post.postType === typeFilter) &&
                (activeFilter
                  ? new Date(post.expirationDate) - new Date() >= 0
                  : true)
              )
            })}
          handleOpenEditDialog={handleOpenEditDialog}
        />
      </Flex>
    </Flex>
  )
}

export default PostsView
