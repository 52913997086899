import React from 'react'
import Flex from '../../components/Flex'
import GradeAssignmentSelector from './GradeAssignmentSelector'
import GradeButtons from './GradeButtons'
import { sideBarShow, sideBarNotShow } from '../../utils/style'

function GradeDetailSidebar(props) {
  return (
    <Flex
      column
      grow={0}
      shrink={0}
      overflow="hidden"
      p="10px"
      style={props.show ? sideBarShow : sideBarNotShow}
    >
      <GradeAssignmentSelector
        classrooms={props.classrooms}
        onSideBarChange={props.onSideBarChange}
        classId={props.classId}
        lessonId={props.lessonId}
        assignmentId={props.assignmentId}
        dayOfWeek={props.dayOfWeek}
        display={props.display}
      />
      <GradeButtons
        onSubmit={props.handleSubmission}
        edited={props.edited}
        disabled={props.disableButtons}
        onReset={props.handleReset}
        onRefetch={props.handleRefetch}
      />
    </Flex>
  )
}

export default GradeDetailSidebar
