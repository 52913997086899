import React, { useState, useEffect, useCallback } from 'react'
import { useLazyQuery, useMutation } from 'react-apollo'

import { Flex, SnackbarNotification } from '../../components'
import NewsDetailSidebar from './NewsDetailSidebar'
import NewsDetailContainer from './NewsDetailContainer'
import NewsDialog from './NewsDialog'
import {
  GET_NEWS,
  CREATE_NEWS,
  UPDATE_NEWS,
  ARCHIVE_NEWS,
  GET_CLASS,
  SEND_NEWS_WITH_EMAIL,
  SEND_SMS,
} from './queries'
import Cookies from 'js-cookie'
import { idName } from '../../config'
import { rawToContentState } from './utils'

const INITIAL_NEWS = {
  id: null,
  headline: false,
  subject: '',
  message: '',
  announcerId: '',
  publishDate: new Date(),
  isTextMessage: false,
  isEmailMessage: false,
}

const NewsDetailView = React.memo(props => {
  const classId = props.match.params[0]
  const employeeId = Cookies.get(idName)
  const [classData, setClassData] = useState([])
  const [newsData, setNewsData] = useState([])
  const [dialog, setDialog] = useState({
    open: false,
    state: null,
    news: INITIAL_NEWS,
  })
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    messageType: '',
  })

  const [createNews] = useMutation(CREATE_NEWS)
  const [updateNews] = useMutation(UPDATE_NEWS)
  const [archiveNews] = useMutation(ARCHIVE_NEWS)
  const [sendEmail] = useMutation(SEND_NEWS_WITH_EMAIL)
  const [sendSMS] = useMutation(SEND_SMS)

  //Fetches classroom and resets classroom data
  const [getClassroom] = useLazyQuery(GET_CLASS, {
    variables: { id: classId },
    onError: err => {
      console.error(err.message)
    },
    onCompleted: res => {
      setClassData({
        code: res.classroom.code,
        studentEmails: res.classroom.enrollments.map(student => {
          return student.student.familyAccount.primaryFamilyMember.email
        }),
        studentPhoneNumbers: res.classroom.enrollments.map(student => {
          return student.student.familyAccount.primaryFamilyMember.phoneNumber
        }),
      })
    },
  })

  //Fetches news and resets news data
  const [getNews] = useLazyQuery(GET_NEWS, {
    variables: { filter: { classroom: { id: classId } } },
    fetchPolicy: 'network-only',
    onError: err => {
      console.error(err.message)
    },
    onCompleted: res => {
      setNewsData(res.news)
    },
  })

  //updates dialog with selected news state
  const handleUpdateDialog = useCallback(
    (
      id,
      headline,
      subject,
      message,
      announcerId,
      publishDate,
      isTextMessage,
      isEmailMessage
    ) => {
      setDialog({
        open: true,
        state: 'update',
        news: {
          id: id,
          headline: headline,
          subject: subject,
          message: message,
          announcerId: announcerId,
          publishDate: publishDate,
          isTextMessage: isTextMessage,
          isEmailMessage: isEmailMessage,
        },
      })
    },
    []
  )

  //creates news with initial news state
  const handleCreateDialog = useCallback(_ => {
    setDialog({
      open: true,
      state: 'create',
      news: INITIAL_NEWS,
    })
  }, [])

  //closes dialog, sets new state with initial news state
  const handleCloseDialog = useCallback(_ => {
    setDialog({
      open: false,
      state: null,
      news: INITIAL_NEWS,
    })
  }, [])

  /**
   * creates new news
   * sets snackbar transition cycle
   * on completed, updates local news data state with new news
   * does not refetch on create, just updates locally
   */
  const createNewsMutation = useCallback(
    input => {
      setSnackbar({
        open: true,
        message: 'Creating new annoucement...',
        messageType: 'loading',
      })
      createNews({
        variables: {
          input: input,
        },
      }).then(res => {
        if (res.errors) {
          setSnackbar({
            open: true,
            message: 'Error...',
            messageType: 'error',
          })
        } else if (res.data) {
          newsData.unshift(res.data.createNews)
          setNewsData([...newsData])
          setSnackbar({
            open: true,
            message: 'Successfully created annoucement',
            messageType: 'success',
          })
        }
      })
    },
    [newsData, createNews]
  )

  /**
   * updates new news
   * sets snackbar transition cycle
   * on completed, updates local news data state with new news
   * does not refetch on update, just updates locally
   */
  const updateNewsMutation = useCallback(
    (id, input) => {
      setSnackbar({
        open: true,
        message: 'Updating annoucement...',
        messageType: 'loading',
      })
      updateNews({
        variables: {
          id: id,
          input: input,
        },
      }).then(res => {
        if (res.errors) {
          setSnackbar({
            open: true,
            message: 'Error...',
            messageType: 'error',
          })
        } else if (res.data) {
          const index = newsData.findIndex(news => news.id === id)
          newsData[index] = res.data.updateNews
          setNewsData([...newsData])
          setSnackbar({
            open: true,
            message: 'Successfully updated annoucement',
            messageType: 'success',
          })
        }
      })
    },
    [newsData, updateNews]
  )

  /**
   * deletes selected news
   * sets snackbar transition cycle
   * on completed, updates local news data state and removes selected news
   * does not refetch on archive, just updates locally
   */
  const handleArchive = useCallback(
    id => {
      setSnackbar({
        open: true,
        message: 'Archiving annoucement...',
        messageType: 'loading',
      })
      archiveNews({
        variables: {
          id: id,
        },
      }).then(res => {
        if (res.errors) {
          setSnackbar({
            open: true,
            message: 'Error...',
            messageType: 'error',
          })
        } else if (res.data) {
          const index = newsData.findIndex(news => news.id === id)
          newsData.splice(index, 1)
          setNewsData([...newsData])
          setSnackbar({
            open: true,
            message: 'Successfully archived annoucement',
            messageType: 'success',
          })
        }
      })
    },
    [newsData, archiveNews]
  )

  /**
   * sends news using email
   * this hanle func is for existing news only
   * updates news with isEmailMessage = true
   * sets snackbar
   * sends email...
   */
  const handleSendEmail = useCallback(
    (id, subject, rawMessage, message) => {
      setSnackbar({
        open: true,
        message: 'Sending annoucement by email...',
        messageType: 'loading',
      })
      updateNews({
        variables: {
          id: id,
          input: {
            isEmailMessage: true,
          },
        },
      }).then(res => {
        if (res.errors) {
          setSnackbar({
            open: true,
            message: 'Error...',
            messageType: 'error',
          })
        } else if (res.data) {
          const index = newsData.findIndex(news => news.id === id)
          newsData[index] = res.data.updateNews
          setNewsData([...newsData])
          if (classData.studentEmails && classData.studentEmails.length > 0) {
            sendEmail({
              variables: {
                recipients: ['care@ardentacademy.com'],
                subject: `${classData.code} announcement: ${subject}`,
                text: rawToContentState(rawMessage).getPlainText('\u0001'),
                html: message,
                bccAddresses: classData.studentEmails,
              },
            })
            sendEmail({
              variables: {
                recipients: ['care@ardentacademy.com'],
                subject: `Verify Student Emails for ${classData.code}`,
                text: `Above message send to class ${classData.code}: ${classData.studentEmails}`,
                html: `Message send to class ${classData.code}: ${classData.studentEmails}`,
                bccAddresses: [],
              },
            })
          } else {
            alert('No emails found')
          }
          setSnackbar({
            open: true,
            message: 'Successfully emailed annoucement',
            messageType: 'success',
          })
        }
      })
    },
    [newsData, updateNews, classData, sendEmail]
  )

  /**
   * sends news using SMS
   * this hanle func is for existing news only
   * updates news with isTextMessage = true
   * sets snackbar
   * sends SMS...
   */
  const handleSendSMS = useCallback(
    (id, rawMessage) => {
      setSnackbar({
        open: true,
        message: 'Sending annoucement by text...',
        messageType: 'loading',
      })
      updateNews({
        variables: {
          id: id,
          input: {
            isTextMessage: true,
          },
        },
      }).then(res => {
        if (res.errors) {
          setSnackbar({
            open: true,
            message: 'Error...',
            messageType: 'error',
          })
        } else if (res.data) {
          const index = newsData.findIndex(news => news.id === id)
          newsData[index] = res.data.updateNews
          setNewsData([...newsData])
          const message = rawToContentState(rawMessage).getPlainText('\u0001')
          if (
            classData.studentPhoneNumbers &&
            classData.studentPhoneNumbers.length > 0
          ) {
            classData.studentPhoneNumbers.forEach(number => {
              sendSMS({
                variables: {
                  phoneNumber: number,
                  message: message,
                },
              })
            })
            sendEmail({
              variables: {
                recipients: ['care@ardentacademy.com'],
                subject: `Verify Student Phones for ${classData.code}`,
                text: `Messages texted to class ${classData.code}: ${classData.studentPhoneNumbers}`,
                html: `Messages texted to class ${classData.code}: ${classData.studentPhoneNumbers}`,
                bccAddresses: [],
              },
            })
          } else {
            alert('No student phone numbers found.')
          }
          setSnackbar({
            open: true,
            message: 'Successfully texted annoucement',
            messageType: 'success',
          })
        }
      })
    },
    [
      updateNews,
      newsData,
      classData.studentPhoneNumbers,
      classData.code,
      sendEmail,
      sendSMS,
    ]
  )

  /**
   * on render, fetches data...
   */
  useEffect(() => {
    getNews()
    getClassroom()
  }, [getNews, getClassroom])

  return (
    <Flex grow={1} style={{ height: '100%' }}>
      <NewsDetailSidebar
        code={classData.code}
        handleCreateDialog={handleCreateDialog}
      />
      {newsData && (
        <NewsDetailContainer
          news={newsData}
          handleArchive={handleArchive}
          handleUpdateDialog={handleUpdateDialog}
          handleSendEmail={handleSendEmail}
          handleSendSMS={handleSendSMS}
        />
      )}
      <NewsDialog
        dialogState={dialog}
        classId={classId}
        employeeId={employeeId}
        recipients={{
          emails: classData.studentEmails,
          phoneNumbers: classData.studentPhoneNumbers,
          code: classData.code,
        }}
        handleCloseDialog={handleCloseDialog}
        createNewsMutation={createNewsMutation}
        updateNewsMutation={updateNewsMutation}
        sendEmail={sendEmail}
        sendSMS={sendSMS}
      />
      <SnackbarNotification
        open={snackbar.open}
        handleClose={() =>
          setSnackbar({
            snackbar: {
              ...snackbar,
              open: false,
            },
          })
        }
        message={snackbar.message}
        messageType={snackbar.messageType}
      />
    </Flex>
  )
})

export default NewsDetailView
