import React, { Component } from 'react'
import { Mutation, withApollo } from 'react-apollo'
import Composer from 'react-composer'
import { CountryRegionData } from 'react-country-region-selector'
import { Redirect } from 'react-router-dom'
import gql from 'graphql-tag'

import {
  Button,
  DemographicForm,
  AccordionForm,
  Flex,
  GuardianForm,
  Paper,
  SnackbarNotification,
  StudentForm,
  Text,
  withStyles,
} from '../../components'

import { isValidPhoneNumber } from '../../utils/phonenumber'
import { userName, randomPassword } from '../../utils/username'
import { COUNT_FAMILY_MEMBERS } from './queries'

const CREATE_LOCATION = gql`
  mutation createLocation($input: CreateLocationInput!) {
    createLocation(input: $input) {
      id
    }
  }
`

const CREATE_FAMILY_ACCOUNT = gql`
  mutation createFamilyAccount($input: CreateFamilyAccountInput!) {
    createFamilyAccount(input: $input) {
      id
    }
  }
`

const CREATE_FAMILY_MEMBER = gql`
  mutation createFamilyMember($input: CreateFamilyMemberInput!) {
    createFamilyMember(input: $input) {
      id
    }
  }
`

const CREATE_STUDENT = gql`
  mutation createStudent($input: CreateStudentInput!) {
    createStudent(input: $input) {
      id
      firstName
      lastName
    }
  }
`

const UPDATE_FAMILY_ACCOUNT = gql`
  mutation updateFamilyAccount($id: ID!, $input: UpdateFamilyAccountInput!) {
    updateFamilyAccount(id: $id, input: $input) {
      id
    }
  }
`

const GET_FAMILY_ACCOUNTS = gql`
  query getFamilyAccounts {
    familyAccounts {
      id
      createdOn
      status
      primaryFamilyMember {
        id
        firstName
        lastName
        relation
        phoneNumber
        email
      }
      location {
        id
        city
      }
    }
  }
`

const GET_STUDENTS = gql`
  query getStudents($filter: Filters) {
    students(filter: $filter) {
      id
      createdOn
      enrollmentStatus
      firstName
      middleName
      lastName
      username
      gender
      grade
      phoneNumber
      email
      school
      familyAccount {
        id
      }
    }
  }
`

const GET_FAMILY_MEMBER_BY_FILTER = gql`
  query getFamilyMemberByFilter($filter: Filters) {
    familyMembers(filter: $filter) {
      id
    }
  }
`

const styles = theme => ({
  root: {
    textAlign: 'left',
    margin: 'auto',
    width: '67%',
    minWidth: '600px',
    maxWidth: '1000px',
  },
  display1: {
    textAlign: 'left',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
})

const defaultState = {
  tabValue: 0,
  redirectToFamilyTable: false,
  guardians: {
    primary: { phonenumber: '', preferredLanguage: 'ENGLISH' },
    secondary: { phonenumber: '', preferredLanguage: 'ENGLISH' },
  },
  demographic: {
    status: 'PROSPECT',
    city: 'Irvine',
    zip: '92612',
    country: CountryRegionData.find(c => c[1] === 'US'),
    region: 'California',
  },
  student: {
    enrollmentStatus: 'PROSPECT',
    tShirtSize: 'N/A',
    over13: false,
    phoneNumber: '',
    preferredLanguage: 'ENGLISH',
  },
  snackbar: {
    open: false,
    message: '',
    messageType: '',
  },
  validation: {
    guardianEmail: {
      error: false,
      message: '',
    },
    guardianPhoneNumber: {
      error: false,
      message: '',
    },
  },
}

class AddFamilyView extends Component {
  constructor(props) {
    super(props)
    this.state = defaultState
  }

  validate = async (field, value, guardian = false) => {
    const { client } = this.props

    // ensure that phoneNumber and Email are not currently being used
    if (guardian && field === 'phoneNumber') {
      const phoneNumberString = value.replace(/\D+/g, '')
      if (phoneNumberString === '') {
        this.setState(state => ({
          validation: {
            ...state.validation,
            guardianPhoneNumber: {
              error: true,
              message: 'Phone Number is a required field',
            },
          },
        }))
      } else {
        const { data } = await client.query({
          query: COUNT_FAMILY_MEMBERS,
          variables: {
            filter: {
              phoneNumber: phoneNumberString,
            },
          },
        })

        this.setState(state => ({
          validation: {
            ...state.validation,
            guardianPhoneNumber: {
              error: data.countFamilyMembers || null,
              message: data.countFamilyMembers
                ? 'Phone Number is already in use'
                : '',
            },
          },
        }))
      }
    }
    if (guardian && field === 'email') {
      if (value === '') {
        this.setState(state => ({
          validation: {
            ...state.validation,
            guardianEmail: {
              error: true,
              message: 'Email is a required field',
            },
          },
        }))
      } else {
        const { data } = await client.query({
          query: COUNT_FAMILY_MEMBERS,
          variables: {
            filter: {
              email: value,
            },
          },
        })
        this.setState(state => ({
          validation: {
            ...state.validation,
            guardianEmail: {
              error: data.countFamilyMembers || null,
              message: data.countFamilyMembers ? 'Email is already in use' : '',
            },
          },
        }))
      }
    }
  }

  isValid = () => {
    const { validation } = this.state
    for (const field of Object.values(validation)) {
      if (field.error) {
        return false
      }
    }
    return true
  }

  returnToFamilyTable = () => {
    this.setState({
      ...this.state,
      redirectToFamilyTable: true,
    })
  }

  getStepContent = step => {
    const { demographic, guardians, student, validation } = this.state
    const { primary, secondary } = guardians
    switch (step) {
      case 0:
        return (
          <StudentForm
            required={false}
            student={student}
            primaryGuardian={primary}
            handleChange={this.handleStudentChange}
          />
        )
      case 1:
        return (
          <DemographicForm
            demographic={demographic}
            handleChange={this.handleDemographicChange}
          />
        )
      case 2:
        return (
          <GuardianForm
            primary={primary}
            secondary={secondary}
            handleChange={this.handleGuardianChange}
            validation={validation}
          />
        )
      default:
        return 'Unknown step'
    }
  }

  handleGuardianChange = guardian => event => {
    const { name, value } = event.target
    const { guardians } = this.state
    const { primary, secondary } = guardians
    this.validate(name, value, true)

    if (guardian === 'primary') {
      this.setState({
        ...this.state,
        guardians: {
          ...guardians,
          primary: {
            ...primary,
            [name]: name === 'phoneNumber' ? value.replace(/\D/g, '') : value,
          },
        },
      })
    }
    if (guardian === 'secondary') {
      this.setState({
        ...this.state,
        guardians: {
          ...guardians,
          secondary: {
            ...secondary,
            [name]: name === 'phoneNumber' ? value.replace(/\D/g, '') : value,
          },
        },
      })
    }
  }

  handleDemographicChange = event => {
    const { name, value } = event.target
    const { demographic } = this.state
    this.setState({
      ...this.state,
      demographic: {
        ...demographic,
        [name]: value,
      },
    })
  }

  handleStudentChange = event => {
    const { name, value } = event.target
    const { student } = this.state
    if (name === 'birthdate') {
      const birthdate = new Date(value)
      const ageDifMs = Date.now() - birthdate.getTime()
      const ageDate = new Date(ageDifMs) // miliseconds from epoch
      this.setState({
        ...this.state,
        student: {
          ...student,
          [name]: value,
          over13: Math.abs(ageDate.getUTCFullYear() - 1970) >= 13,
        },
      })
    } else {
      this.setState({
        ...this.state,
        student: {
          ...student,
          [name]: name === 'phoneNumber' ? value.replace(/\D/g, '') : value,
        },
      })
    }
  }

  handleTabChange = (event, value) => {
    this.setState({
      ...this.state,
      tabValue: value,
    })
  }

  checkErrors = async () => {
    const { client } = this.props
    const { guardians, student } = this.state
    const { primary, secondary } = guardians
    const { birthdate } = student
    if (
      !(
        primary.firstName &&
        primary.lastName &&
        primary.phoneNumber !== '' &&
        primary.email
      )
    ) {
      this.setState({
        snackbar: {
          open: true,
          message: 'At least one required field is missing',
          messageType: 'error',
        },
      })
      return true
    }

    if (
      birthdate &&
      (!birthdate._isValid ||
        birthdate._pf.parsedDateParts[0] < 1900 ||
        birthdate._pf.parsedDateParts[0] > new Date().getFullYear() + 1)
    ) {
      this.setState({
        snackbar: {
          open: true,
          message: 'Please enter a valid birthdate',
          messageType: 'error',
        },
      })
      return true
    }

    if (
      !(
        isValidPhoneNumber(primary.phoneNumber) &&
        isValidPhoneNumber(secondary.phoneNumber) &&
        isValidPhoneNumber(student.phoneNumber)
      )
    ) {
      this.setState({
        snackbar: {
          open: true,
          message: 'At least one phone number is an improper length',
          messageType: 'error',
        },
      })
      return true
    }

    //Check if number and email are already in use in primary guardian
    const { data: phoneNumbers } = await client.query({
      query: GET_FAMILY_MEMBER_BY_FILTER,
      variables: {
        filter: { phoneNumber: primary.phoneNumber },
      },
    })
    if (phoneNumbers.familyMembers.length !== 0) {
      this.setState({
        snackbar: {
          open: true,
          message: `${primary.phoneNumber} is already in use. Please choose a different number`,
          messageType: 'error',
        },
      })
      return true
    }

    const { data: emails } = await client.query({
      query: GET_FAMILY_MEMBER_BY_FILTER,
      variables: { filter: { email: primary.email } },
    })
    if (emails.familyMembers.length !== 0) {
      this.setState({
        snackbar: {
          open: true,
          message: `${primary.email} is already in use. Please choose a different email.`,
          messageType: 'error',
        },
      })
      return true
    }

    //Check if number and email are already in use in secondary guardian - if entered!
    if (secondary && secondary.phoneNumber && secondary.email) {
      const { data: phoneNumbers } = client.query({
        query: { GET_FAMILY_MEMBER_BY_FILTER },
        variables: {
          filter: { phoneNumber: secondary.phoneNumber },
        },
      })
      if (phoneNumbers.familyMembers.length !== 0) {
        this.setState({
          snackbar: {
            open: true,
            message: `${secondary.phoneNumber} is already in use. Please choose a different number.`,
            messageType: 'error',
          },
        })
        return true
      }

      const { data: emails } = client.query({
        query: { GET_FAMILY_MEMBER_BY_FILTER },
        variables: { filter: { email: secondary.email } },
      })
      if (emails.familyMembers.length !== 0) {
        this.setState({
          snackbar: {
            open: true,
            message: `${secondary.email} is already in use. Please choose a different email.`,
            messageType: 'error',
          },
        })
        return true
      }
    }
  }

  render() {
    const {
      demographic,
      guardians,
      redirectToFamilyTable,
      snackbar,
      student,
    } = this.state
    const {
      address,
      ardentCenter,
      city,
      country,
      maritalStatus,
      referrals,
      region,
      status,
      zip,
    } = demographic
    const { primary, secondary } = guardians
    if (redirectToFamilyTable) {
      return <Redirect push to="/family" />
    }

    const { classes } = this.props

    const handleSubmission = async (
      CLmutation,
      CFAmutation,
      CFMmutation,
      CSmutation,
      UFAmutation
    ) => {
      const CLreturn = await CLmutation({
        variables: {
          input: {
            street: address || '',
            city: city || '',
            state: region || '',
            country: country ? country[0] : '',
            zip: zip,
          },
        },
      })
      const CFAreturn = await CFAmutation({
        variables: {
          input: {
            status: status,
            maritalStatus: maritalStatus,
            locationId: CLreturn.data.createLocation.id,
            referrals: referrals,
            centerLocationId: ardentCenter,
          },
        },
      })
      const familyAccountId = CFAreturn.data.createFamilyAccount.id
      const PrimaryCFMreturn = await CFMmutation({
        variables: {
          input: {
            familyAccountId,
            firstName: primary.firstName,
            lastName: primary.lastName,
            relation: primary.relationship,
            phoneNumber: primary.phoneNumber,
            preferredLanguage: primary.preferredLanguage,
            email: primary.email,
          },
        },
      })
      // let SecondaryCFMreturn = null;
      if (secondary.firstName && secondary.lastName) {
        /*SecondaryCFMreturn = */ await CFMmutation({
          variables: {
            input: {
              familyAccountId,
              firstName: secondary.firstName,
              lastName: secondary.lastName,
              relation: secondary.relationship,
              phoneNumber: secondary.phoneNumber,
              preferredLanguage: secondary.preferredLanguage,
              email: secondary.email,
            },
          },
        })
      }
      if (student.firstName && student.lastName) {
        await CSmutation({
          variables: {
            input: {
              familyAccountId,
              enrollmentStatus: student.enrollmentStatus,
              firstName: student.firstName,
              middleName: student.middleName,
              lastName: student.lastName,
              birthdate: new Date(student.birthdate),
              gender: student.gender,
              grade: student.grade,
              school: student.school,
              email: student.email,
              phoneNumber: student.phoneNumber,
              tShirtSize:
                student.tShirtSize !== 'N/A' ? student.tShirtSize : null,
              username: userName(student.firstName, student.lastName),
              password: randomPassword(),
            },
          },
          refetchQueries: [
            {
              query: GET_FAMILY_ACCOUNTS,
            },
            {
              query: GET_STUDENTS,
            },
          ],
        })
      }
      /*const UFAreturn = */ await UFAmutation({
        variables: {
          id: familyAccountId,
          input: {
            primaryFamilyMemberId: PrimaryCFMreturn.data.createFamilyMember.id,
            familyMemberId: PrimaryCFMreturn.data.createFamilyMember.id,
            // SecondaryCFMreturn &&
            // SecondaryCFMreturn.data.createFamilyMember.id
          },
        },
        refetchQueries: [
          {
            query: GET_FAMILY_ACCOUNTS,
          },
          {
            query: GET_STUDENTS,
          },
        ],
      })
      // alert("Family created!");
      this.returnToFamilyTable()
    }

    return (
      <Flex column grow={1} className={classes.root}>
        <br />
        <Text className={classes.display1} variant="h4">
          Create New Family Account
        </Text>
        <br />
        <Paper>
          <AccordionForm
            expanded
            form={this.getStepContent(2)}
            title="Guardian"
          />
          <AccordionForm
            expanded
            form={this.getStepContent(1)}
            title="Address (optional)"
          />
          <AccordionForm
            expanded
            form={this.getStepContent(0)}
            title="Student (Optional)"
          />
          <Flex direction={'row-reverse'} p="10px">
            <Composer
              components={[
                <Mutation mutation={CREATE_LOCATION} />,
                <Mutation mutation={CREATE_FAMILY_ACCOUNT} />,
                <Mutation mutation={CREATE_FAMILY_MEMBER} />,
                <Mutation mutation={CREATE_STUDENT} />,
                <Mutation mutation={UPDATE_FAMILY_ACCOUNT} />,
              ]}
            >
              {([
                CLFunction,
                CFAFunction,
                CFMFunction,
                CSFunction,
                UFAFunction,
              ]) => (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  disabled={!this.isValid()}
                  onClick={() => {
                    this.checkErrors().then(error => {
                      if (
                        !error &&
                        window.confirm(
                          'Are you sure you want to submit? All empty fields will be filled with default or null values'
                        )
                      ) {
                        handleSubmission(
                          CLFunction,
                          CFAFunction,
                          CFMFunction,
                          CSFunction,
                          UFAFunction
                        )
                      }
                    })
                  }}
                >
                  Submit
                </Button>
              )}
            </Composer>
            <Button
              variant="contained"
              color="secondary"
              aria-label="Cancel"
              className={classes.button}
              onClick={() => {
                if (
                  window.confirm(
                    'Do you want to return to the table of families?'
                  )
                ) {
                  this.returnToFamilyTable()
                }
              }}
              style={{ marginRight: '10px' }}
            >
              Cancel
            </Button>
          </Flex>
        </Paper>
        <SnackbarNotification
          open={snackbar.open}
          handleClose={() => {
            this.setState({
              snackbar: {
                ...snackbar,
                open: false,
              },
            })
          }}
          message={snackbar.message}
          messageType={snackbar.messageType}
        />
      </Flex>
    )
  }
}

export default withApollo(withStyles(styles)(AddFamilyView))
