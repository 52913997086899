import React, { useState, useEffect } from 'react'
import { Query, withApollo } from 'react-apollo'
import AssessmentSidebar from './AssessmentSidebar'
import AssessmentTable from './AssessmentTable'
import {
  GET_QUALIFIES,
  ARCHIVE_ATTENDEE,
  ARCHIVE_ENROLLMENT,
  ARCHIVE_QUALIFY,
} from './queries'
import {
  ErrorMessage,
  Flex,
  LoadingMessage,
  SnackbarNotification,
} from '../../components'

const AssessmentTableView = props => {
  const dataResolver = assessments => {
    let resolvedAssessments = []
    assessments.forEach(assessment => {
      if (assessment.enrollment) {
        //this is to fix bad data on develop
        let resolvedAssessment = {
          status: assessment.status,
          id: assessment.id,
          enrollmentId: assessment.enrollment && assessment.enrollment.id,
          attendeeId: assessment.attendee && assessment.attendee.id,
          resolveData: assessment.resolvedDate
            ? assessment.resolvedDate
            : 'N/A',
          code:
            assessment.enrollment.classroom &&
            assessment.enrollment.classroom.code,
          firstName: assessment.student.firstName,
          lastName: assessment.student.lastName,
          grade: assessment.student.grade,
          school: assessment.student.school,
          score: assessment.attendee ? assessment.attendee.overallGrade : 'N/A',
          parentName: `${assessment.guardian.firstName} ${assessment.guardian.lastName}`,
          phoneNumber: assessment.guardian.phoneNumber,
          email: assessment.student.email,
          createdBy: `${assessment.createdBy.firstName} ${assessment.createdBy.lastName}`,
          location: assessment.centerLocation && assessment.centerLocation.name,
        }
        resolvedAssessments.push(resolvedAssessment)
      }
    })
    return resolvedAssessments
  }

  return (
    <Query
      query={GET_QUALIFIES}
      variables={{
        sort: { resolvedDate: 1 },
      }}
      fetchPolicy={'network-only'}
    >
      {({ data, loading, error, refetch }) => {
        if (loading) return <LoadingMessage />
        if (error) return <ErrorMessage error={error} />
        const assessments = data.qualifies
        const resolvedData = dataResolver(assessments)
        return (
          <AssessmentInnerTableView
            assessments={resolvedData}
            dataResolver={dataResolver}
            refetch={refetch}
            {...props}
          />
        )
      }}
    </Query>
  )
}

const AssessmentInnerTableView = props => {
  const [data, setData] = useState(props.assessments)
  const [selectAll, setAll] = useState(false)
  const [checked, setChecked] = useState(
    props.assessments.map(() => {
      return false
    })
  )
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    messageType: '',
    willReload: 0, //0 false, 1 reload,
  })

  useEffect(() => {
    if (snackbar.willReload) {
      props.refetch().then(response => {
        setData(props.dataResolver(response.data.qualifies))
        setSnackbar({ ...snackbar, willReload: 0 })
      })
    }
  }, [snackbar, props])

  const handleSingleCheckboxChange = index => {
    let newChecked = checked
    newChecked[index] = !checked[index]
    setAll(newChecked[index] === false && selectAll ? false : selectAll)
    setChecked([...newChecked])
  }

  const redirectTo = (assessmentId, data) => {
    props.history.push(`/assessment/${assessmentId}`, data)
  }

  const archiveAssessments = async () => {
    const { client, assessments } = props
    const toArchive = []
    let success = false
    setSnackbar({
      open: true,
      message: 'Archiving Assessments',
      messageType: 'loading',
    })
    for (let i = 0; i < assessments.length; i++) {
      if (checked[i]) {
        if (assessments[i].attendeeId) {
          toArchive.push(
            client.mutate({
              mutation: ARCHIVE_ATTENDEE,
              variables: { id: assessments[i].attendeeId },
            })
          )
        }
        if (assessments[i].enrollmentId) {
          toArchive.push(
            client.mutate({
              mutation: ARCHIVE_ENROLLMENT,
              variables: { id: assessments[i].enrollmentId },
            })
          )
        }
        if (assessments[i].id) {
          toArchive.push(
            client.mutate({
              mutation: ARCHIVE_QUALIFY,
              variables: { id: assessments[i].id },
            })
          )
        }
      }
    }
    await Promise.all(toArchive).then(resolve => (success = true))
    setSnackbar({
      open: true,
      message: success ? 'Assessments Archived' : 'error',
      messageType: success ? 'success' : 'error',
      willReload: 1,
    })
  }

  return (
    <Flex>
      <AssessmentSidebar
        assessments={data}
        archiveAssessments={archiveAssessments}
      />
      <Flex pl={'0px'} align="left" column grow={1}>
        <AssessmentTable
          assessments={data}
          redirectTo={redirectTo}
          checked={checked}
          handleSingleCheckboxChange={handleSingleCheckboxChange}
        />
      </Flex>
      <SnackbarNotification
        open={snackbar.open}
        handleClose={() => {
          setSnackbar({
            snackbar: {
              ...snackbar,
              open: false,
            },
          })
        }}
        message={snackbar.message}
        messageType={snackbar.messageType}
      />
    </Flex>
  )
}

export default withApollo(AssessmentTableView)
