import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import TutorEmployeeTableView from './TutorEmployeeTableView'
import TutorEmployeeDetailView from './TutorEmployeeDetailView'

class TutorEmployeeView extends Component {
  render() {
    const url = this.props.match.url
    return (
      <Switch>
        <Route
          path={`${url}/*`}
          render={props => <TutorEmployeeDetailView {...props} />}
        />
        <Route
          path={url}
          render={props => <TutorEmployeeTableView {...props} />}
        />
      </Switch>
    )
  }
}

export default TutorEmployeeView
