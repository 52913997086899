import React, { Component } from 'react'
import { Query } from 'react-apollo'
import { Redirect } from 'react-router-dom'

import CounselingSidebar from './CounselingSidebar'
import CounselingTable from './CounselingTable'
import { GET_COUNSELING_ENROLLMENTS } from './queries'

import {
  Button,
  ErrorMessage,
  Flex,
  LoadingMessage,
  Menu,
  MenuItem,
  SettingsIcon,
  Tooltip,
} from '../../components'

class CounselingTableView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sidebar: true,
      showFilters: true,
      selectAll: false,
      menuAnchor: null,
      checked: {},
      redirect: false,
      sorted: [
        {
          id: 'lastName',
        },
      ],
      filtered: [
        {
          id: 'status',
          value: 'ACTIVE',
        },
      ],
    }
  }

  handleSingleCheckboxChange = index => {
    const { checked, selectAll } = this.state
    let newChecked = checked
    newChecked[index] = !checked[index]
    this.setState(
      newChecked[index] === false && selectAll
        ? {
            selectAll: false,
            checked: newChecked,
          }
        : { checked: newChecked }
    )
  }

  toggleSidebar = () => {
    this.setState(state => ({
      sidebar: !state.sidebar,
      menuAnchor: null,
    }))
  }

  toggleFilters = () => {
    this.setState(state => ({
      showFilters: !state.showFilters,
      menuAnchor: null,
    }))
  }

  onFilteredChange = filtered => {
    this.setState({
      filtered,
      menuAnchor: null,
    })
  }

  menuOpen = event => {
    this.setState({
      menuAnchor: event.currentTarget,
    })
  }

  menuClose = () => {
    this.setState({
      menuAnchor: null,
    })
  }

  rowClick = rowInfo => {
    rowInfo &&
      this.setState({
        redirect: true,
        redirectTo: `/counseling/${rowInfo.original.id}`,
      })
  }

  menuButton = data => {
    const { menuAnchor } = this.state
    return (
      <Flex p="5px">
        <Flex p="5px">
          <Tooltip>
            <Button color="default" aria-label="Menu" onClick={this.menuOpen}>
              <SettingsIcon />
            </Button>
          </Tooltip>
        </Flex>
        <Menu
          id="simple-menu"
          anchorEl={menuAnchor}
          open={Boolean(menuAnchor)}
          onClose={this.menuClose}
        >
          <MenuItem onClick={this.toggleSidebar}>Toggle Sidebar</MenuItem>
          <MenuItem onClick={this.toggleFilters}>Toggle Filters</MenuItem>
        </Menu>
      </Flex>
    )
  }

  getStatusFilter = () => {
    for (const filter of this.state.filtered) {
      if (filter.id === 'status') {
        return filter.value === 'ALL' ? {} : { status: filter.value }
      }
    }
    return {}
  }

  render() {
    const {
      checked,
      filtered,
      redirect,
      redirectTo,
      showFilters,
      sidebar,
      sorted,
    } = this.state
    const { statuses } = this.props

    if (redirect) {
      return <Redirect push to={redirectTo} />
    }
    return (
      <Query
        fetchPolicy={'cache-and-network'}
        query={GET_COUNSELING_ENROLLMENTS}
        variables={{
          filter: this.getStatusFilter(),
        }}
      >
        {({ loading, error, data }) => {
          if (loading) return <LoadingMessage />
          if (error) return <ErrorMessage error={error} />

          const updatedCounselingEnrollments = data.counselingEnrollments.map(
            counselingEnrollment => {
              return {
                id: counselingEnrollment.id,
                status: counselingEnrollment.status,
                firstName: counselingEnrollment.student.firstName,
                lastName: counselingEnrollment.student.lastName,
                grade: counselingEnrollment.student.grade,
                school: counselingEnrollment.student.school,
                startDate: counselingEnrollment.startDate,
                endDate: counselingEnrollment.endDate,
                lastContactTime:
                  counselingEnrollment.lastMeeting &&
                  counselingEnrollment.lastMeeting.startDateTime,
                nextContactTime:
                  counselingEnrollment.nextMeeting &&
                  counselingEnrollment.nextMeeting.startDateTime,
                counselor: `${counselingEnrollment.counselor.firstName} ${counselingEnrollment.counselor.lastName}`,
              }
            }
          )

          const checkedRows = Object.keys(checked).filter(key => checked[key])

          return (
            <Flex>
              {sidebar ? (
                <CounselingSidebar
                  show={sidebar}
                  counselingEnrollments={data.counselingEnrollments}
                  menu={() => this.menuButton(data)}
                  checkedRows={checkedRows}
                  statuses={statuses}
                />
              ) : null}
              <Flex pl={sidebar ? '0px' : '20px'} align="left" column grow={1}>
                <CounselingTable
                  counselingEnrollments={updatedCounselingEnrollments}
                  filterable={showFilters}
                  onRowClick={this.rowClick}
                  sorted={sorted}
                  filtered={filtered}
                  onFilteredChange={this.onFilteredChange}
                  checked={checked}
                  handleSingleCheckboxChange={this.handleSingleCheckboxChange}
                  statuses={statuses}
                />
              </Flex>
            </Flex>
          )
        }}
      </Query>
    )
  }
}

export default CounselingTableView
