import gql from 'graphql-tag'

export const GET_STUDENTS = gql`
  query getStudents($filter: Filters) {
    students(filter: $filter) {
      id
      createdOn
      enrollmentStatus
      firstName
      middleName
      lastName
      username
      gender
      grade
      phoneNumber
      email
      school
      familyAccount {
        id
      }
    }
  }
`

export const GET_STUDENT = gql`
  query getStudent($id: ID!) {
    student(id: $id) {
      id
      enrollmentStatus
      firstName
      middleName
      lastName
      nickname
      gender
      grade
      school
      username
      phoneNumber
      email
      tShirtSize
      learningStyle
      birthdate
      image
      imageUrl
      newImage
      newImageUrl
      enrollments(sort: { createdOn: -1 }) {
        id
        classroom {
          id
          quarter
          year
          status
          centerLocation {
            id
            name
          }
          course {
            id
            code
            title
          }
        }
      }
      familyAccount {
        id
        status
        location {
          id
          street
          city
          zip
          state
          country
        }
        primaryFamilyMember {
          id
          firstName
          lastName
          relation
          email
          phoneNumber
          preferredLanguage
          occupation
          educationLevel
        }
        familyMembers {
          id
          firstName
          lastName
          relation
          email
          phoneNumber
          preferredLanguage
          occupation
          educationLevel
        }
      }
      opportunities {
        id
        status
        primaryInterest
        session
        year
        closeDate
        source
        notes
        createdBy {
          id
          firstName
          lastName
        }
        responsibleEmployee {
          id
          firstName
          lastName
        }
      }
    }
  }
`

export const GET_FAMILY_ACCOUNTS = gql`
  query getFamilyAccounts {
    familyAccounts {
      id
      createdOn
      status
      primaryFamilyMember {
        id
        firstName
        lastName
        relation
        phoneNumber
        email
      }
      location {
        id
        city
      }
    }
  }
`

export const GET_FAMILY_ACCOUNT = gql`
  query getFamilyAccount($id: ID!) {
    familyAccount(id: $id) {
      id
      status
      location {
        id
        street
        city
        zip
        state
        country
      }
      centerLocation {
        id
        name
      }
      maritalStatus
      referrals
      primaryFamilyMember {
        id
        firstName
        lastName
        relation
        email
        phoneNumber
        occupation
        educationLevel
      }
      familyMembers {
        id
        firstName
        lastName
        relation
        email
        phoneNumber
        preferredLanguage
        occupation
        educationLevel
      }
      students {
        id
        firstName
        lastName
        grade
        enrollmentStatus
        enrollments(sort: { createdOn: -1 }) {
          id
          classroom {
            id
          }
        }
      }
    }
  }
`

export const GET_FAMILY_STATUS_OPTIONS = gql`
  query getFamilyStatusOptions {
    __type(name: "FamilyStatus") {
      enumValues {
        name
      }
    }
  }
`

export const GET_FILTERED_FAMILY_ACCOUNTS = gql`
  query getFilteredFamilyAccounts($filter: Filters!) {
    familyAccounts(filter: $filter) {
      id
      primaryFamilyMember {
        id
        firstName
        lastName
        phoneNumber
        email
      }
    }
  }
`

export const GET_OPPORTUNITIES = gql`
  query getOpportunities {
    opportunities {
      id
      createdOn
      status
      openDate
      closeDate
      source
      student {
        id
        firstName
        lastName
      }
      stage
      primaryInterest
      session
      year
      notes
      responsibleEmployee {
        id
        firstName
        lastName
      }
      createdBy {
        id
        firstName
        lastName
      }
    }
  }
`

export const GET_OPPORTUNITY = gql`
  query getOpportunity($id: ID!) {
    opportunity(id: $id) {
      id
      status
      openDate
      closeDate
      lastContacted
      lastActivity {
        id
        method
      }
      activities {
        id
        responsibleEmployee {
          id
          firstName
          lastName
        }
        type
        method
        priority
        endDate
        resolvedDate
      }
      primaryInterest
      session
      year
      source
      notes
      stage
      responsibleEmployee {
        id
        firstName
        lastName
      }
      createdBy {
        id
        firstName
        lastName
      }
      trials {
        id
        status
        toLesson {
          open
          startOn
          classroom {
            code
            centerLocation {
              name
            }
          }
        }
        followupStatus
        opportunity {
          id
          student {
            id
            firstName
            lastName
            phoneNumber
          }
        }
      }
      student {
        id
        enrollments {
          id
          classroom {
            id
            quarter
            year
            course {
              id
              code
              title
            }
            centerLocation {
              id
              name
            }
          }
        }
        enrollmentStatus
        firstName
        lastName
        nickname
        gender
        grade
        school
        phoneNumber
        email
        tShirtSize
        learningStyle
        birthdate
        opportunities {
          id
          status
          closeDate
        }
        familyAccount {
          id
          status
          location {
            id
            street
            city
            zip
            state
            country
          }
          primaryFamilyMember {
            id
            firstName
            lastName
            relation
            email
            phoneNumber
            preferredLanguage
            occupation
            educationLevel
          }
          familyMembers {
            id
            firstName
            lastName
            relation
            email
            phoneNumber
            preferredLanguage
            occupation
            educationLevel
          }
        }
      }
    }
  }
`

export const GET_ASSIGNMENTS_FROM_LESSON = gql`
  query getAssignments($id: ID!) {
    lesson(id: $id) {
      assignments {
        id
        problemSet {
          id
          title
          order
          subOrder
          kind
        }
      }
    }
  }
`

export const GET_CONTACT_METHOD_OPTIONS_QUERY = gql`
  query getContactMethodOptions {
    __type(name: "ContactMethod") {
      enumValues {
        name
      }
    }
  }
`

export const GET_MAKEUPS = gql`
  query getMakeups {
    makeups {
      id
      createdOn
      notes
      status
      createdBy {
        id
        firstName
        lastName
      }
      student {
        id
        firstName
        lastName
        phoneNumber
        email
        enrollmentStatus
      }
      fromLesson {
        id
        open
        startOn
        endOn
        lessonPlan {
          id
          order
          title
        }
        classroom {
          id
          code
          centerLocation {
            id
            name
          }
        }
      }
      toLesson {
        id
        open
        startOn
        endOn
        lessonPlan {
          id
          order
          title
        }
        classroom {
          id
          code
          centerLocation {
            id
            name
          }
        }
      }
    }
  }
`

export const GET_MAKEUP = gql`
  query getMakeup($id: ID!) {
    makeup(id: $id) {
      id
      createdOn
      notes
      status
      createdBy {
        id
        firstName
        lastName
      }
      student {
        id
        firstName
        lastName
        nickname
        gender
        birthdate
        grade
        school
        phoneNumber
        email
      }
      fromLesson {
        id
        open
        startOn
        endOn
        lessonPlan {
          id
          order
          title
        }
        classroom {
          id
          title
          centerLocation {
            id
            name
          }
        }
      }
      toLesson {
        id
        open
        startOn
        endOn
        lessonPlan {
          id
          order
          title
        }
        classroom {
          id
          title
          centerLocation {
            id
            name
          }
        }
      }
    }
  }
`

export const GET_PFM_PHONE_EMAIL_LANG = gql`
  query getPfmPhoneEmail($id: ID!) {
    familyAccount(id: $id) {
      id
      primaryFamilyMember {
        id
        phoneNumber
        preferredLanguage
        email
      }
    }
  }
`

export const GET_EMPLOYEE = gql`
  query getEmployee($id: ID!) {
    employee(id: $id) {
      id
      firstName
      lastName
      workEmail
      isCollegeCounselor
      isOfficeUser
    }
  }
`

export const GET_CLASSROOMS = gql`
  query getClasses($filter: Filters!) {
    classrooms(filter: $filter) {
      id
      status
      startDate
      endDate
      quarter
      year
      code
      title
      enrollmentCount
      meets
      startTime
      endTime
      centerLocation {
        id
        name
      }
      instructors {
        id
        isAssistant
        employee {
          id
          firstName
          lastName
        }
      }
    }
  }
`

export const SIMPLE_GET_CLASSROOMS = gql`
  query getClasses($filter: Filters, $sort: Sorts) {
    classrooms(filter: $filter, sort: $sort) {
      id
      status
      title
      startDate
      endDate
      quarter
      course {
        id
        code
      }
    }
  }
`

export const GET_ENROLLMENTS_FROM_STUDENT = gql`
  query getEnrollmentsFromStudent($id: ID!) {
    student(id: $id) {
      id
      firstName
      lastName
      enrollments {
        id
        classroom {
          id
          status
          title
          teachingStyle
          course {
            id
            code
            title
          }
        }
      }
    }
  }
`

export const GET_LESSONS_FROM_CLASS = gql`
  query getClassroom($id: ID!) {
    classroom(id: $id) {
      id
      title
      centerLocation {
        id
        name
      }
      lessons(filter: { lessonPlan: { lessonPlanType: "INSTRUCTION" } }) {
        id
        startOn
        endOn
        lessonPlan {
          id
          order
          title
        }
      }
    }
  }
`

export const GET_TRIALS = gql`
  query getTrials {
    trials {
      id
      createdOn
      status
      toLesson {
        id
        lessonPlan {
          id
          order
        }
        classroom {
          id
          centerLocation {
            id
            name
          }
          code
        }
        startOn
        endOn
      }
      opportunity {
        id
        status
        student {
          id
          firstName
          lastName
          email
          phoneNumber
        }
      }
      createdBy {
        id
        firstName
        lastName
      }
    }
  }
`

export const GET_TRIAL = gql`
  query getTrial($id: ID!) {
    trial(id: $id) {
      id
      createdOn
      status
      instructorComment
      quizGrade
      quizScore
      quizTotal
      evaluationMentalMath
      evaluationSkillBuilder
      evaluationProblemSolving
      evaluationMotivation
      evaluationDiscipline
      recommendation
      notes
      alternateCourse {
        id
        title
        code
      }
      toLesson {
        id
        lessonPlan {
          id
          title
          order
        }
        classroom {
          id
          centerLocation {
            id
            name
          }
          code
          title
        }
        startOn
        endOn
      }
      opportunity {
        id
        status
        openDate
        closeDate
        primaryInterest
        lastActivity {
          id
          method
        }
        lastContacted
        session
        year
        source
        notes
        stage
        student {
          id
          familyAccount {
            id
            primaryFamilyMember {
              id
              preferredLanguage
            }
          }
          firstName
          middleName
          lastName
          nickname
          gender
          birthdate
          grade
          school
          phoneNumber
          email
        }
        responsibleEmployee {
          id
          firstName
          lastName
        }
        createdBy {
          id
          firstName
          lastName
        }
      }
      createdBy {
        id
        firstName
        lastName
      }
      dataEntryBy {
        id
        firstName
        lastName
      }
      followupStatus
      followupDate
      followupEmployee {
        id
        firstName
        lastName
      }
      followupResult
    }
  }
`

export const GET_MAKEUP_LESSONS = gql`
  query getMakeupLessons($id: ID!) {
    getMakeupLessons(lessonId: $id) {
      success
      message
      makeupLessons {
        id
        open
        startOn
        endOn
        classroom {
          id
          code
          centerLocation {
            id
            name
          }
        }
        lessonPlan {
          id
          order
          title
        }
      }
    }
  }
`

export const GET_OFFICE_EMPLOYEES = gql`
  query getOfficeEmployees {
    employees(filter: { isOfficeUser: true }) {
      id
      firstName
      lastName
      status
    }
  }
`

export const GET_CENTER_LOCATION = gql`
  query getCenterLocation($id: ID!) {
    centerLocation(id: $id) {
      id
      managers {
        id
        firstName
      }
    }
  }
`

export const GET_CENTER_LOCATIONS = gql`
  query getCenterLocations($filter: Filters) {
    centerLocations(filter: $filter) {
      id
      name
    }
  }
`

export const GET_FAMILY_MEMBER_BY_FILTER = gql`
  query getFamilyMemberByFilter($filter: Filters) {
    familyMembers(filter: $filter) {
      id
    }
  }
`
export const GET_FAMILY_MEMBER_ID_FROM_OPPORTUNITY = gql`
  query getFamilyMemberIdFromOpportunity($id: ID!) {
    opportunity(id: $id) {
      id
      student {
        firstName
        id
        familyAccount {
          id
          primaryFamilyMember {
            id
          }
        }
      }
    }
  }
`

export const GET_LESSON_FROM_ID = gql`
  query getLessonFromId($id: ID!) {
    lesson(id: $id) {
      id
      startOn
      endOn
      lessonPlan {
        id
        order
      }
    }
  }
`

export const GET_CLASSROOM_FROM_ID = gql`
  query getClassroomFromId($id: ID!) {
    classroom(id: $id) {
      id
      title
      code
      centerLocation {
        id
        name
        streetLine1
        city
        state
        isVirtual
      }
      lessons {
        id
        lessonPlan {
          lessonPlanType
        }
      }
    }
  }
`

export const COUNT_FAMILY_ACCOUNTS = gql`
  query countFamilyAccounts($filter: Filters) {
    countFamilyAccounts(filter: $filter)
  }
`

// CREATION QUERIES

export const CREATE_LOCATION = gql`
  mutation createLocation($input: CreateLocationInput!) {
    createLocation(input: $input) {
      id
    }
  }
`

export const CREATE_FAMILY_ACCOUNT = gql`
  mutation createFamilyAccount($input: CreateFamilyAccountInput!) {
    createFamilyAccount(input: $input) {
      id
    }
  }
`

export const CREATE_FAMILY_MEMBER = gql`
  mutation createFamilyMember($input: CreateFamilyMemberInput!) {
    createFamilyMember(input: $input) {
      id
    }
  }
`

export const CREATE_STUDENT = gql`
  mutation createStudent($input: CreateStudentInput!) {
    createStudent(input: $input) {
      id
      firstName
      lastName
    }
  }
`

export const CREATE_OPPORTUNITY = gql`
  mutation createOpportunity($input: CreateOpportunityInput!) {
    createOpportunity(input: $input) {
      id
    }
  }
`

export const CREATE_MAKEUP = gql`
  mutation createMakeup($input: CreateMakeupInput!) {
    createMakeup(input: $input) {
      id
    }
  }
`

export const CREATE_ENROLLMENT = gql`
  mutation enrollStudent($input: CreateEnrollmentInput!) {
    createEnrollment(input: $input) {
      id
    }
  }
`

export const CREATE_TRIAL = gql`
  mutation createTrial($input: CreateTrialInput!) {
    createTrial(input: $input) {
      id
    }
  }
`

export const generateTemporaryToken = gql`
  mutation generateTemporaryToken(
    $token: String!
    $userId: ID!
    $userType: String!
  ) {
    generateTemporaryToken(
      token: $token
      userType: $userType
      userId: $userId
    ) {
      token
      errorMessage
    }
  }
`

export const UPDATE_STUDENT = gql`
  mutation updateStudent($id: ID!, $input: UpdateStudentInput!) {
    updateStudent(id: $id, input: $input) {
      id
      familyAccount {
        id
      }
    }
  }
`

export const UPDATE_FAMILY_MEMBER = gql`
  mutation updateFamilyMember($id: ID!, $input: UpdateFamilyMemberInput!) {
    updateFamilyMember(id: $id, input: $input) {
      id
    }
  }
`

export const UPDATE_FAMILY_ACCOUNT = gql`
  mutation updateFamilyAccount($id: ID!, $input: UpdateFamilyAccountInput!) {
    updateFamilyAccount(id: $id, input: $input) {
      id
    }
  }
`

export const UPDATE_LOCATION = gql`
  mutation updateLocation($id: ID!, $input: UpdateLocationInput!) {
    updateLocation(id: $id, input: $input) {
      id
    }
  }
`

export const UPDATE_OPPORTUNITY = gql`
  mutation updateOpportunity($id: ID!, $input: UpdateOpportunityInput!) {
    updateOpportunity(id: $id, input: $input) {
      id
    }
  }
`

export const UPDATE_MAKEUP = gql`
  mutation updateMakeup($id: ID!, $input: UpdateMakeupInput!) {
    updateMakeup(id: $id, input: $input) {
      id
    }
  }
`

// DELETION/ARCHIVAL QUERIES

export const ARCHIVE_STUDENT = gql`
  mutation archiveStudent($id: ID!) {
    archiveStudent(id: $id) {
      id
    }
  }
`

export const ARCHIVE_FAMILY_ACCOUNT = gql`
  mutation archiveFamilyAccount($id: ID!) {
    archiveFamilyAccount(id: $id) {
      id
    }
  }
`

export const ARCHIVE_OPPORTUNITY = gql`
  mutation archiveOpportunity($id: ID!) {
    archiveOpportunity(id: $id) {
      id
    }
  }
`

export const ARCHIVE_FAMILY_MEMBER = gql`
  mutation archiveFamilyMember($id: ID!) {
    archiveFamilyMember(id: $id) {
      id
    }
  }
`

export const ARCHIVE_MAKEUP = gql`
  mutation archiveMakeup($id: ID!) {
    archiveMakeup(id: $id) {
      id
    }
  }
`

export const ARCHIVE_ENROLLMENT = gql`
  mutation archiveEnrollment($id: ID!) {
    archiveEnrollment(id: $id) {
      id
    }
  }
`

// OTHER QUERIES

export const SUBMIT_GRADES = gql`
  mutation gradeAssignment(
    $aid: ID!
    $pids: [ID]
    $input: [GradeAssignmentInput!]
  ) {
    gradeAssignment(assignmentId: $aid, problemIds: $pids, inputs: $input) {
      success
      message
    }
  }
`

export const SEND_SMS = gql`
  mutation sendSMS($phoneNumber: String!, $message: String!) {
    sendSMS(phoneNumber: $phoneNumber, message: $message) {
      success
      message
    }
  }
`

export const SEND_EMAIL_TO_FAMILY_MEMBER = gql`
  mutation sendEmailToFamilyMember(
    $familyMemberId: ID!
    $subject: String!
    $textMessage: String!
    $htmlMessage: String
    $scheduledDate: DateTime
    $testRecipient: String
    $ccAddresses: [String]
    $bccAddresses: [String]
  ) {
    sendEmailToFamilyMember(
      familyMemberId: $familyMemberId
      subject: $subject
      textMessage: $textMessage
      htmlMessage: $htmlMessage
      scheduledDate: $scheduledDate
      testRecipient: $testRecipient
      ccAddresses: $ccAddresses
      bccAddresses: $bccAddresses
    ) {
      message
      familyMember {
        id
        email
      }
    }
  }
`
