import React from 'react'
import Box from '@mui/material/Box'

const SidebarContainer = ({ children }) => {
  return (
    <Box
      sx={{
        p: 1,
        minWidth: '210px',
        maxWidth: '210px',
        backgroundColor: 'grey.300',
        border: 1,
        borderColor: 'grey.400',
      }}
    >
      {children}
    </Box>
  )
}

export default SidebarContainer
