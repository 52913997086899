import React, { useEffect, useRef, useState } from 'react'
import { Query, withApollo } from 'react-apollo'

import {
  AddIcon,
  Button,
  Checkbox,
  ClearIcon,
  DatePicker,
  DateTimePicker,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ErrorMessage,
  Flex,
  FormLabel,
  IconButton,
  LoadingMessage,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  FormControlLabel,
} from '../../components'
import { FormControl, Input, InputAdornment, InputLabel } from '@mui/material'

import {
  CREATE_CLASSROOM,
  GET_COURSES,
  GET_EMPLOYEES,
  GET_CENTER_LOCATIONS,
  GET_STATUS_ENUM,
  GET_TEACHING_STYLE_ENUM,
  UPDATE_CLASSROOM,
  CREATE_INSTRUCTOR,
  UPDATE_INSTRUCTOR,
  ARCHIVE_INSTRUCTOR,
} from './queries'

const TutorClassDetailDialog = props => {
  const { open, closeEditDialog, classroom, classroomId, isEdit } = props

  const [status, setStatus] = useState(classroom.status || 'DRAFT')
  const [centerLocationId, setCenterLocationId] = useState(
    classroom.centerLocation && classroom.centerLocation.id
  )
  const [course, setCourse] = useState(null)
  const [courseCode, setCourseCode] = useState(
    classroom.course && classroom.course.code
  )
  const [title, setTitle] = useState(classroom.title)
  const [instanceNumber, setInstanceNumber] = useState(classroom.instanceNumber)
  const [code, setCode] = useState(classroom.code)
  const [year, setYear] = useState(classroom.year)
  const [quarter, setQuarter] = useState(classroom.quarter)
  const [session, setSession] = useState(classroom.session)
  const [enrollmentMax, setEnrollmentMax] = useState(
    classroom.enrollmentMax || 15
  )
  const [instructors, setInstructors] = useState(classroom.instructors || [])
  const [startDate, setStartDate] = useState(classroom.startDate || null)
  const [endDate, setEndDate] = useState(classroom.endDate || null)
  const [finalDate, setFinalDate] = useState(classroom.finalAccessDate || null)
  const [videoId, setVideoId] = useState(classroom.videoId || null)
  const [meetsOnMonday, setMeetsOnMonday] = useState(
    classroom.meetsOnMonday || false
  )
  const [meetsOnTuesday, setMeetsOnTuesday] = useState(
    classroom.meetsOnTuesday || false
  )
  const [meetsOnWednesday, setMeetsOnWednesday] = useState(
    classroom.meetsOnWednesday || false
  )
  const [meetsOnThursday, setMeetsOnThursday] = useState(
    classroom.meetsOnThursday || false
  )
  const [meetsOnFriday, setMeetsOnFriday] = useState(
    classroom.meetsOnFriday || false
  )
  const [meetsOnSaturday, setMeetsOnSaturday] = useState(
    classroom.meetsOnSaturday || false
  )
  const [meetsOnSunday, setMeetsOnSunday] = useState(
    classroom.meetsOnSunday || false
  )
  const [meetIsCustom, setMeetIsCustom] = useState(
    classroom.meetIsCustom || false
  )

  const [onSolve, setOnSolve] = useState(classroom.onSolve || false)
  const [allowTrial, setAllowTrial] = useState(classroom.allowTrial || false)

  const [onCatalog, setOnCatalog] = useState(classroom.onCatalog || false)
  const [discountable, setDiscountable] = useState(
    classroom.discountable || false
  )

  const [teachingStyle, setTeachingStyle] = useState(
    classroom.teachingStyle || 'BLENDED'
  )
  const [meetingsPerWeek, setMeetingsPerWeek] = useState(
    classroom.meetingsPerWeek
  )

  const [tuition, setTuition] = useState(
    classroom.tuitionCents ? classroom.tuitionCents / 100 : 0
  )

  const [earlyBirdTuition, setEarlyBirdTuition] = useState(
    classroom.earlyBirdtuitionCents
      ? classroom.earlyBirdtuitionCents / 100
      : classroom.tuition
      ? classroom.tuition > 100000
        ? classroom.tuition / 100 - 200
        : classroom.tuition / 100 - 50
      : 0
  )

  const firstRender = useRef(true)

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      return
    }
  })

  const getClassroomTitle = (year, quarter, code) => {
    year = new Date().getUTCFullYear() + (new Date().getUTCMonth() > 8 ? 1 : 0)

    let title = ''
    if (quarter) {
      title += quarter + ' ' + year + ': '
    }
    if (code) {
      title += code + ' - '
    }
    if (course) {
      title += course.code
      if (instanceNumber) {
        if (instanceNumber < 10) {
          title += '0'
        }
        title += instanceNumber
      }
      title += ' - ' + course.title
    }
    return title
  }

  const formValidation = () => {
    if (!course || !centerLocationId || !quarter || !startDate || !endDate) {
      return true
    }
    return false
  }

  const handleSubmit = async () => {
    const { client } = props
    const mutations = []
    let classId = classroomId

    if (isEdit) {
      await client.mutate({
        mutation: UPDATE_CLASSROOM,
        variables: {
          id: classId,
          input: {
            status,
            quarter,
            session,
            enrollmentMax,
            centerLocationId,
            startDate: new Date(startDate).toISOString(),
            startTime: new Date(startDate).toISOString(),
            endDate: new Date(endDate).toISOString(),
            endTime: new Date(endDate).toISOString(),
            finalAccessDate: new Date(finalDate).toISOString(),
            meetsOnMonday,
            meetsOnTuesday,
            meetsOnWednesday,
            meetsOnThursday,
            meetsOnFriday,
            meetsOnSaturday,
            meetsOnSunday,
            meetIsCustom,
            teachingStyle,
            meetingsPerWeek,
            onSolve,
            onCatalog,
            allowTrial,
            discountable,
            title,
            videoId,
            tuitionCents: tuition * 100,
            earlyBirdtuitionCents: earlyBirdTuition * 100,
          },
        },
      })
    } else {
      const { data } = await client.mutate({
        mutation: CREATE_CLASSROOM,
        variables: {
          input: {
            status,
            courseId: course.id,
            instanceNumber: parseInt(instanceNumber),
            quarter,
            session,
            enrollmentMax,
            centerLocationId,
            startDate,
            startTime: startDate,
            endDate,
            endTime: endDate,
            meetsOnMonday,
            meetsOnTuesday,
            meetsOnWednesday,
            meetsOnThursday,
            meetsOnFriday,
            meetsOnSaturday,
            meetsOnSunday,
            meetIsCustom,
            onSolve,
            onCatalog,
            discountable,
            allowTrial,
            teachingStyle,
            meetingsPerWeek,
            videoId,
            tuitionCents: tuition * 100,
            earlyBirdtuitionCents: earlyBirdTuition * 100,
          },
        },
      })
      classId = data.createClassroom.id
    }

    for (const instructor of instructors) {
      if (!instructor.employee) continue
      if (!instructor.id) {
        // Added instructors
        if (instructor.archived) continue // Added then deleted; do nothing in db
        mutations.push(
          client.mutate({
            mutation: CREATE_INSTRUCTOR,
            variables: {
              input: {
                classroomId: classId,
                employeeId: instructor.employee.id,
              },
            },
          })
        )
      } else if (instructor.archived) {
        mutations.push(
          client.mutate({
            mutation: ARCHIVE_INSTRUCTOR,
            variables: {
              id: instructor.id,
            },
          })
        )
      } else {
        mutations.push(
          client.mutate({
            mutation: UPDATE_INSTRUCTOR,
            variables: {
              id: instructor.id,
              input: {
                employeeId: instructor.employee.id,
              },
            },
          })
        )
      }
    }

    await Promise.all(mutations)

    props.refetch()
    closeEditDialog()
  }

  return (
    <Dialog open={open} fullWidth={true} maxWidth={'lg'}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <Flex p={'20px'} grow={2} justify="space-between">
          <Paper style={{ padding: '15px', width: '45%' }}>
            <Flex>
              <Query query={GET_STATUS_ENUM}>
                {({ loading, data, error }) => {
                  if (loading) return <LoadingMessage />
                  if (error) return <ErrorMessage error={error} />

                  return (
                    <TextField
                      id="status"
                      label="Status"
                      style={{ width: '80%' }}
                      select
                      value={status || ''}
                      name="status"
                      onChange={event => setStatus(event.target.value)}
                    >
                      {data.__type.enumValues.map(option => (
                        <MenuItem key={option.name} value={option.name}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )
                }}
              </Query>
            </Flex>
            <br />
            <Flex direction="row" justify="space-between">
              <TextField
                label="Quarter"
                required
                style={{ width: '100%' }}
                value={quarter}
                select
                onChange={event => setQuarter(event.target.value)}
              >
                {[
                  'Summer',
                  'Fall',
                  'Winter',
                  'PreSummer',
                  'Spring',
                  'OnDemand',
                  'Tutoring',
                  'Contest',
                ].map(ele => (
                  <MenuItem key={ele} value={ele}>
                    {ele}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                disabled
                label="Year"
                style={{ width: '100%', marginLeft: '1em', marginRight: '1em' }}
                value={
                  year ||
                  new Date().getUTCFullYear() +
                    (new Date().getUTCMonth() > 8 ? 1 : 0)
                }
                onChange={event => setYear(event.target.value)}
              />
              <TextField
                label="Session"
                style={{ width: '100%' }}
                value={session}
                select
                onChange={event => setSession(event.target.value)}
              >
                {[
                  'N/A',
                  '0',
                  '1',
                  '2',
                  '3',
                  '4',
                  '5',
                  '6',
                  '7',
                  '8',
                  '9',
                  '10',
                ].map(ele => (
                  <MenuItem key={ele} value={ele}>
                    {ele}
                  </MenuItem>
                ))}
              </TextField>
            </Flex>
            <br />
            <Flex direction="row" justify="space-between">
              {isEdit ? (
                <TextField
                  disabled
                  label="Course Code"
                  style={{ width: '100%' }}
                  value={courseCode}
                  onChange={event => setCourseCode(event.target.value)}
                />
              ) : (
                <Query query={GET_COURSES}>
                  {({ loading, error, data }) => {
                    if (loading) return <LoadingMessage />
                    if (error) return <ErrorMessage error={error} />
                    const { courses } = data
                    return (
                      <TextField
                        label="Course"
                        required
                        style={{ width: '100%' }}
                        value={course ? course : ''}
                        onChange={event => setCourse(event.target.value)}
                        select
                      >
                        {courses.map(course => (
                          <MenuItem key={course.id} value={course}>
                            {course.code}
                          </MenuItem>
                        ))}
                      </TextField>
                    )
                  }}
                </Query>
              )}
              <TextField
                disabled={isEdit}
                label="Instance"
                type="number"
                style={{
                  width: '100%',
                  marginLeft: '1em',
                  marginRight: '1em',
                }}
                value={instanceNumber}
                onChange={event => setInstanceNumber(event.target.value)}
              />
              <TextField
                disabled
                label="Class Code"
                style={{ width: '100%' }}
                value={
                  isEdit
                    ? code
                    : `${(course && course.code) || ''}${instanceNumber || ''}`
                }
                onChange={event => setCode(event.target.value)}
                InputLabelProps={{
                  shrink: course || instanceNumber ? true : false,
                }}
              />
            </Flex>
            <br />

            <Flex direction="row" justify="space-between">
              <TextField
                label="Classroom Title"
                style={{ width: '100%' }}
                value={isEdit ? title : getClassroomTitle(year, quarter, code)}
                onChange={event => setTitle(event.target.value)}
                InputLabelProps={{
                  shrink: quarter ? true : false,
                }}
              />
            </Flex>
            <br />
            <Flex direction="row" justify="space-between">
              <TextField
                label="Maximum"
                value={enrollmentMax}
                style={{ flex: 1 }}
                onChange={event =>
                  setEnrollmentMax(
                    event.target.value && parseInt(event.target.value)
                  )
                }
              />
              <FormControl
                fullWidth
                variant="standard"
                style={{ marginLeft: '0.7em', flex: 1 }}
              >
                <InputLabel htmlFor="standard-adornment-amount">
                  Tuition
                </InputLabel>
                <Input
                  id="standard-adornment-amount"
                  value={tuition}
                  onChange={event => {
                    setTuition(event.target.value)
                  }}
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                />
              </FormControl>
              <FormControl
                fullWidth
                variant="standard"
                style={{ marginLeft: '0.7em', flex: 1 }}
              >
                <InputLabel htmlFor="standard-adornment-amount">
                  Early Bird
                </InputLabel>
                <Input
                  id="standard-adornment-amount"
                  value={earlyBirdTuition}
                  onChange={event => {
                    setEarlyBirdTuition(event.target.value)
                  }}
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                />
              </FormControl>
              <Query query={GET_TEACHING_STYLE_ENUM}>
                {({ loading, error, data }) => {
                  if (loading) return <LoadingMessage />
                  if (error) return <ErrorMessage error={error} />
                  return (
                    <TextField
                      label="Teaching Style"
                      style={{ flex: 1.5, marginLeft: '0.7em' }}
                      value={teachingStyle}
                      select
                      onChange={event => setTeachingStyle(event.target.value)}
                    >
                      {data.__type.enumValues.map(style => (
                        <MenuItem key={style.name} value={style.name}>
                          {style.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )
                }}
              </Query>
            </Flex>
            <br />

            <Query query={GET_EMPLOYEES}>
              {({ loading, error, data }) => {
                if (loading) return <LoadingMessage />
                if (error) return <ErrorMessage error={error} />
                const responsibleEmployees = data.employees.map(emp => {
                  return {
                    name: `${emp.firstName} ${emp.lastName}`,
                    id: emp.id,
                  }
                })

                return (
                  <Flex column align="stretch">
                    {instructors.map(
                      (instructor, i) =>
                        !instructor.archived && (
                          <Flex key={`${instructor}_${i}`}>
                            <TextField
                              label="Instructor"
                              name="instructor"
                              value={
                                (instructor.employee &&
                                  instructor.employee.id) ||
                                ''
                              }
                              style={{ width: '100%' }}
                              select
                              onChange={event => {
                                const instrIndex = instructors.findIndex(
                                  instr =>
                                    instr.employee &&
                                    instr.employee.id === event.target.value
                                )
                                const tempInstructors = [...instructors]
                                if (instrIndex === -1) {
                                  tempInstructors[i] = {
                                    ...tempInstructors[i],
                                    employee: { id: event.target.value },
                                  }
                                } else {
                                  tempInstructors[instrIndex].archived = false
                                }
                                setInstructors(tempInstructors)
                              }}
                            >
                              {responsibleEmployees.map((option, i) => (
                                <MenuItem
                                  key={`${option}_${i}`}
                                  value={option.id}
                                >
                                  {option.name}
                                </MenuItem>
                              ))}
                            </TextField>
                            <IconButton
                              color="secondary"
                              onClick={() => {
                                const tempInstructors = [...instructors]
                                tempInstructors[i].archived = true
                                setInstructors(tempInstructors)
                              }}
                            >
                              <ClearIcon />
                            </IconButton>
                          </Flex>
                        )
                    )}
                    <br />
                    <Flex alignSelf={'center'}>
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => setInstructors([...instructors, {}])}
                      >
                        <AddIcon />
                        Add Instructor
                      </Button>
                    </Flex>
                  </Flex>
                )
              }}
            </Query>
          </Paper>
          <Paper style={{ padding: '15px', width: '45%' }}>
            <Flex direction="row" justify="space-between">
              <Query query={GET_CENTER_LOCATIONS}>
                {({ loading, error, data }) => {
                  if (loading) return <LoadingMessage />
                  if (error) return <ErrorMessage error={error} />

                  const locations = data.centerLocations.map(location => {
                    return {
                      name: location.name,
                      value: location.id,
                    }
                  })

                  return (
                    <TextField
                      label="Center Location"
                      style={{ width: '48%' }}
                      required
                      name="centerLocationID"
                      value={centerLocationId || ''}
                      onChange={event =>
                        setCenterLocationId(event.target.value)
                      }
                      select
                    >
                      {locations.map(option => (
                        <MenuItem key={option.name} value={option.value}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )
                }}
              </Query>
              <TextField
                label="Video Id"
                style={{ width: '48%' }}
                value={videoId ? videoId : ''}
                onChange={event => setVideoId(event.target.value)}
              />
            </Flex>
            <br />
            <br />
            <Flex direction="row" justify="space-between">
              <DateTimePicker
                style={{ width: '48%' }}
                label="Start Date & Time"
                required
                value={startDate}
                onChange={event => setStartDate(event.target.value)}
              />
              <DateTimePicker
                style={{ width: '48%' }}
                label="End Date & Time"
                required
                value={endDate}
                onChange={event => setEndDate(event.target.value)}
              />
            </Flex>
            <br />
            <Flex direction="row" justify="space-between">
              <DatePicker
                style={{ width: '48%' }}
                label="Final Access Date"
                required
                value={finalDate}
                onChange={event => setFinalDate(event.target.value)}
              />
              <TextField
                label="Tutor Meetings Per Week"
                value={meetingsPerWeek}
                style={{ width: '48%' }}
                onChange={event =>
                  setMeetingsPerWeek(
                    event.target.value && parseInt(event.target.value)
                  )
                }
              />
            </Flex>
            <FormLabel style={{ fontSize: '.75em', opacity: '.7' }}>
              Days of the Week
            </FormLabel>
            <Flex direction="row" justify="space-evenly">
              <Flex direction="column">
                {[
                  {
                    day: 'Monday',
                    value: meetsOnMonday,
                    onChange: setMeetsOnMonday,
                  },
                  {
                    day: 'Tuesday',
                    value: meetsOnTuesday,
                    onChange: setMeetsOnTuesday,
                  },
                  {
                    day: 'Wednesday',
                    value: meetsOnWednesday,
                    onChange: setMeetsOnWednesday,
                  },
                ].map(current => (
                  <FormControlLabel
                    // disabled
                    key={current.day}
                    control={
                      <Checkbox
                        checked={current.value}
                        onChange={event =>
                          current.onChange(event.target.checked)
                        }
                      />
                    }
                    label={current.day}
                  />
                ))}
              </Flex>
              <Flex direction="column">
                {[
                  {
                    day: 'Thursday',
                    value: meetsOnThursday,
                    onChange: setMeetsOnThursday,
                  },
                  {
                    day: 'Friday',
                    value: meetsOnFriday,
                    onChange: setMeetsOnFriday,
                  },
                  {
                    day: 'Saturday',
                    value: meetsOnSaturday,
                    onChange: setMeetsOnSaturday,
                  },
                ].map(current => (
                  <FormControlLabel
                    key={current.day}
                    control={
                      <Checkbox
                        checked={current.value}
                        onChange={event =>
                          current.onChange(event.target.checked)
                        }
                      />
                    }
                    label={current.day}
                  />
                ))}
              </Flex>
              <Flex direction="column">
                {[
                  {
                    day: 'Sunday',
                    value: meetsOnSunday,
                    onChange: setMeetsOnSunday,
                  },
                  {
                    day: 'Custom',
                    value: meetIsCustom,
                    onChange: setMeetIsCustom,
                  },
                ].map(current => (
                  <FormControlLabel
                    key={current.day}
                    control={
                      <Checkbox
                        checked={current.value}
                        onChange={event =>
                          current.onChange(event.target.checked)
                        }
                      />
                    }
                    label={current.day}
                  />
                ))}
              </Flex>
            </Flex>
            <br />
            <Flex direction="row" justify="space-between">
              <p style={{ opacity: '.45' }}>On Solve: </p>
              <RadioGroup
                aria-label="on solve"
                name="onSolve"
                value={onSolve && onSolve.toString() === 'true' ? true : false}
                style={{ display: 'flex', flexDirection: 'row' }}
                onChange={event =>
                  setOnSolve(
                    event.target.value.toString() === 'true' ? true : false
                  )
                }
              >
                <FormControlLabel
                  value={true}
                  control={<Radio color="primary" />}
                  label="Yes"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio color="secondary" />}
                  label="No"
                />
              </RadioGroup>

              <p style={{ opacity: '.45' }}>Allow Trial: </p>
              <RadioGroup
                aria-label="allow Trial"
                name="AllowTrial"
                value={
                  allowTrial && allowTrial.toString() === 'true' ? true : false
                }
                onChange={event =>
                  setAllowTrial(
                    event.target.value.toString() === 'true' ? true : false
                  )
                }
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio color="primary" />}
                  label="Yes"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio color="secondary" />}
                  label="No"
                />
              </RadioGroup>
            </Flex>
            <Flex direction="row" justify="space-between">
              <p style={{ opacity: '.45' }}>On Catalog: </p>
              <RadioGroup
                aria-label="on catalog"
                name="onCatalog"
                value={
                  onCatalog && onCatalog.toString() === 'true' ? true : false
                }
                style={{ display: 'flex', flexDirection: 'row' }}
                onChange={event =>
                  setOnCatalog(
                    event.target.value.toString() === 'true' ? true : false
                  )
                }
              >
                <FormControlLabel
                  value={true}
                  control={<Radio color="primary" />}
                  label="Yes"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio color="secondary" />}
                  label="No"
                />
              </RadioGroup>

              <p style={{ opacity: '.45' }}>Allow Discount: </p>
              <RadioGroup
                aria-label="allow discount"
                name="Discountable"
                value={
                  discountable && discountable.toString() === 'true'
                    ? true
                    : false
                }
                onChange={event =>
                  setDiscountable(
                    event.target.value.toString() === 'true' ? true : false
                  )
                }
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio color="primary" />}
                  label="Yes"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio color="secondary" />}
                  label="No"
                />
              </RadioGroup>
            </Flex>
          </Paper>
        </Flex>
      </DialogContent>
      <DialogActions style={{ paddingBottom: '10px', paddingRight: '10px' }}>
        <Button
          onClick={handleSubmit}
          color="primary"
          disabled={isEdit ? firstRender.current : formValidation()}
        >
          {isEdit ? 'Save' : 'Create'}
        </Button>
        <Button onClick={closeEditDialog} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withApollo(TutorClassDetailDialog)
