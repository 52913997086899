import React, { Component } from 'react'
import { Mutation } from 'react-apollo'
import { Link, Redirect } from 'react-router-dom'

import { ARCHIVE_ACTIVITY, UPDATE_ACTIVITY } from './queries'
import TaskForm from './TaskForm'

import { PhoneNumberInput } from '../../utils'
import { convertToYMD } from '../../utils/datetime'

import {
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Flex,
  FormControl,
  Input,
  InputLabel,
  OpportunityForm,
  Paper,
  SnackbarNotification,
  StudentForm,
  Text,
  TextField,
} from '../../components'

class TaskDetailPanels extends Component {
  constructor(props) {
    super(props)
    const { task } = this.props
    const { endDate, responsibleEmployee } = task
    this.state = {
      redirect: false,
      redirectTo: '',
      task: {
        ...task,
        responsibleEmployeeId: responsibleEmployee && responsibleEmployee.id,
        endDate: endDate && convertToYMD(endDate),
      },
      taskChanged: false,
      snackbar: {
        open: false,
        message: '',
        messageType: '',
      },
    }
  }

  returnToTaskTable = () => {
    this.setState({
      redirect: true,
      redirectTo: '/task',
    })
  }

  handleTaskChange = event => {
    const { name, value } = event.target
    this.setState(state => ({
      taskChanged: true,
      task: {
        ...state.task,
        [name]: value,
      },
    }))
  }

  processMethod = () => {
    switch (this.state.task.method) {
      case 'CALL':
        return 'Phone Call to '
      case 'EMAIL':
        return 'Email '
      case 'IN_PERSON':
        return 'In person meeting with '
      default:
        return ''
    }
  }

  isReadOnly = () => {
    const { task } = this.state
    return task.resolvedDate && task.type
  }

  render() {
    const { task, taskChanged, opportunity, redirect, redirectTo } = this.state
    const { contact, student } = task
    if (redirect) {
      return <Redirect push to={redirectTo} />
    }
    const { snackbar } = this.state
    return (
      <Flex
        column
        grow={1}
        style={{
          textAlign: 'left',
          margin: 'auto',
          width: '67%',
          minWidth: '600px',
          maxWidth: '1000px',
        }}
      >
        <br />
        <Link to="/task">{'< back to Task List'}</Link>
        <br />
        <Text variant="h4">{`${
          student ? `${student.firstName} ${student.lastName},` : ''
        } ${
          contact
            ? `Primary Contact: ${contact.firstName} ${contact.lastName}`
            : ''
        }`}</Text>
        <br />
        <Flex>
          <Mutation mutation={ARCHIVE_ACTIVITY}>
            {(archiveActivity, { data }) => (
              <Button
                color="secondary"
                onClick={() => {
                  if (
                    window.confirm('Are you sure want to archive this task?')
                  ) {
                    archiveActivity({
                      variables: {
                        id: task.id,
                      },
                    })
                    this.setState({
                      snackbar: {
                        open: true,
                        message: 'Task archived',
                        messageType: 'success',
                      },
                    })
                  }
                }}
              >
                Archive Task
              </Button>
            )}
          </Mutation>
          {!task.resolvedDate && (
            <Mutation mutation={UPDATE_ACTIVITY}>
              {(updateActivity, { data }) => (
                <Button
                  style={{ marginLeft: '10px' }}
                  onClick={() => {
                    if (
                      window.confirm(
                        'Are you sure want to mark this Task as completed?'
                      )
                    ) {
                      updateActivity({
                        variables: {
                          id: task.id,
                          input: {
                            resolvedDate: Date.now(),
                          },
                        },
                      })
                      this.setState({
                        snackbar: {
                          open: true,
                          message: 'Task resolved',
                          messageType: 'success',
                        },
                      })
                    }
                  }}
                >
                  Complete Task
                </Button>
              )}
            </Mutation>
          )}
        </Flex>
        <br />
        <Paper>
          <Accordion defaultExpanded>
            <AccordionSummary title="Task Information" />
            <AccordionDetails>
              <Flex
                column
                style={{
                  align: 'center',
                  flexGrow: 1,
                }}
              >
                <TaskForm
                  readOnly={this.isReadOnly()}
                  task={task}
                  handleChange={this.handleTaskChange}
                />
                <Flex direction={'row-reverse'} align={'center'} p="10px">
                  <Mutation mutation={UPDATE_ACTIVITY}>
                    {(updateActivity, { data }) => (
                      <Button
                        disabled={!taskChanged}
                        onClick={() => {
                          updateActivity({
                            variables: {
                              id: task.id,
                              input: {
                                method: task.method,
                                type: task.type,
                                endDate: task.endDate && new Date(task.endDate),
                                priority: task.priority,
                                responsibleEmployeeId:
                                  task.responsibleEmployeeId,
                                description: task.description,
                                resolution: task.resolution,
                              },
                            },
                          })
                          this.setState({
                            taskChanged: false,
                          })
                        }}
                      >
                        Save Changes
                      </Button>
                    )}
                  </Mutation>
                  <Text style={{ marginRight: '10px' }}>
                    {this.isReadOnly()
                      ? 'This task is closed and no further changes can be made.'
                      : 'Make sure to verify all information before hitting save!'}
                  </Text>
                </Flex>
              </Flex>
            </AccordionDetails>
          </Accordion>

          {task.contact && (
            <Accordion defaultExpanded>
              <AccordionSummary title="Contact Information (Read Only)" />
              <AccordionDetails>
                <Flex
                  style={{
                    align: 'center',
                    flexGrow: 1,
                  }}
                  justify="space-evenly"
                >
                  <Flex grow={2} m="20px" style={{ maxWidth: '300px' }} column>
                    <TextField
                      readOnly
                      id="firstName"
                      label="First Name"
                      value={this.props.task.contact.firstName || ''}
                      fullWidth
                      disabled={true}
                    />
                    <br />
                    <TextField
                      readOnly
                      id="lastName"
                      label="Last Name"
                      value={this.props.task.contact.lastName || ''}
                      fullWidth
                      disabled={true}
                    />
                  </Flex>
                  <Flex grow={2} m="20px" style={{ maxWidth: '300px' }} column>
                    <TextField
                      readOnly
                      id="email"
                      label="Email"
                      value={this.props.task.contact.email || ''}
                      fullWidth
                      disabled={true}
                    />
                    <br />
                    <FormControl>
                      <InputLabel shrink>Phone #</InputLabel>
                      <Input
                        readOnly
                        value={this.props.task.contact.phoneNumber || ''}
                        id="phone"
                        inputComponent={PhoneNumberInput}
                        disabled={true}
                      />
                    </FormControl>
                  </Flex>
                </Flex>
              </AccordionDetails>
            </Accordion>
          )}

          {task.opportunity && (
            <Accordion defaultExpanded>
              <AccordionSummary title="Opportunity Detail (Read Only)" />
              <AccordionDetails>
                <OpportunityForm
                  readOnly
                  showStatus
                  showCreatedBy
                  opportunity={task.opportunity}
                  handleChange={() => {}}
                />
              </AccordionDetails>
            </Accordion>
          )}
          {task.student && (
            <Accordion defaultExpanded={!opportunity}>
              <AccordionSummary title="Student Detail (Read Only)" />
              <AccordionDetails>
                <StudentForm
                  readOnly
                  student={task.student}
                  handleChange={() => {}}
                />
              </AccordionDetails>
            </Accordion>
          )}
        </Paper>
        <SnackbarNotification
          open={snackbar.open}
          handleClose={() => {
            this.setState(state => ({
              snackbar: {
                ...state.snackbar,
                open: false,
              },
              redirect: true,
              redirectTo: '/task',
            }))
          }}
          message={snackbar.message}
          messageType={snackbar.messageType}
        />
      </Flex>
    )
  }
}

export default TaskDetailPanels
