import React, { Fragment } from 'react'
import { Query } from 'react-apollo'

import {
  ErrorMessage,
  Flex,
  LoadingMessage,
  MenuItem,
  TextField,
} from '../../components'

import withStyles from '../../components/Styles'
import { GET_OFFICE_EMPLOYEES, GET_CLASSROOMS } from './queries'
import { GET_CENTER_LOCATIONS } from '../../queries'

const AddAssessmentForm = props => {
  const styles = props.classes
  const { handleChange, opportunity, assessment } = props
  const {
    responsibleEmployeeId,
    centerLocation,
    method,
    results,
    status,
  } = assessment
  const methods = ['ONLINE', 'CENTER']
  const statuses = ['SCHEDULED', 'COMPLETED', 'ABANDONED']
  const classOptions = {
    M: 'Math',
    S: 'Science',
    N: 'Physics',
    B: 'Biology/Chemistry',
    C: 'Biology/Chemistry',
    E: 'Writing',
    D: 'Speech and Debate',
  }

  return (
    <Flex
      column
      style={{
        align: 'center',
        flexGrow: 1,
      }}
    >
      <form id="taskForm">
        <Flex justify="space-evenly">
          <Flex grow={2} m="20px" style={{ maxWidth: '300px' }} column>
            <TextField
              name="status"
              label="Status"
              value={status}
              className={styles.textField}
              select
              onChange={handleChange}
              style={{ marginBottom: 20 }}
              disabled
              SelectProps={{
                MenuProps: {
                  className: styles.menu,
                },
              }}
              InputLabelProps={{
                shrink: !!status && status !== undefined,
              }}
            >
              {statuses.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <Query query={GET_OFFICE_EMPLOYEES}>
              {({ loading, error, data }) => {
                if (loading) return <LoadingMessage />
                if (error) return <ErrorMessage error={error} />
                const responsibleEmployees = data.employees
                  .filter(employee => employee.status === 'ACTIVE')
                  .map(employee => {
                    return {
                      name: `${employee.firstName} ${employee.lastName}`,
                      value: employee.id,
                    }
                  })

                return (
                  <TextField
                    name="responsibleEmployeeId"
                    label="Assign To"
                    value={responsibleEmployeeId}
                    className={styles.textField}
                    select
                    onChange={handleChange}
                    style={{ marginBottom: 20 }}
                    SelectProps={{
                      MenuProps: {
                        className: styles.menu,
                      },
                    }}
                    InputLabelProps={{
                      shrink:
                        !!responsibleEmployeeId &&
                        responsibleEmployeeId !== undefined,
                    }}
                  >
                    {responsibleEmployees.map(option => (
                      <MenuItem key={option.name} value={option.value}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )
              }}
            </Query>
            <TextField
              name="results"
              label="Results"
              multiline
              maxRows="5"
              value={results || ''}
              className={styles.textField}
              fullWidth
              onChange={handleChange}
              style={{ marginBottom: 20 }}
            />
          </Flex>
          <Flex grow={2} m="20px" style={{ maxWidth: '300px' }} column>
            <TextField
              name="method"
              label="Method"
              value={method}
              className={styles.textField}
              select
              onChange={handleChange}
              style={{ marginBottom: 20 }}
              SelectProps={{
                MenuProps: {
                  className: styles.menu,
                },
              }}
              InputLabelProps={{
                shrink: !!method && method !== undefined,
              }}
            >
              {methods.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            {method === 'CENTER' && (
              <Query
                query={GET_CENTER_LOCATIONS}
                variables={{ filter: { isPublished: true } }}
              >
                {({ data, loading, error }) => {
                  if (loading) return <LoadingMessage />
                  if (error) return <ErrorMessage error={error} />
                  const ardentLocations = data.centerLocations
                  return (
                    <Fragment>
                      <TextField
                        name="centerLocation"
                        label="Center Location"
                        required
                        value={centerLocation}
                        className={styles.textField}
                        select
                        style={{ marginBottom: 20 }}
                        onChange={handleChange}
                        SelectProps={{
                          MenuProps: {
                            className: styles.menu,
                          },
                        }}
                        InputLabelProps={{
                          shrink: centerLocation,
                        }}
                      >
                        {ardentLocations.map(option => (
                          <MenuItem key={option.name} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Fragment>
                  )
                }}
              </Query>
            )}
            {opportunity && (
              <Query
                query={GET_CLASSROOMS}
                variables={{
                  filter: {
                    $or: [{ status: 'OPENED' }, { status: 'PUBLISHED' }],
                  },
                }}
              >
                {({ error, loading, data }) => {
                  if (loading) return <LoadingMessage />
                  if (error) return <ErrorMessage error={error} />
                  const enrolledClasses = new Set()
                  opportunity.student.enrollments.forEach(enrollment => {
                    enrolledClasses.add(enrollment.classroom.id)
                  })
                  const classrooms = data.classrooms
                    .filter(classroom => {
                      return (
                        classOptions[classroom.code.charAt(0)] ===
                          opportunity.primaryInterest &&
                        !enrolledClasses.has(classroom.id) &&
                        classroom.year === opportunity.year &&
                        classroom.quarter === opportunity.session
                      )
                    })
                    .sort((a, b) => a.code.localeCompare(b.code))
                  return (
                    <Fragment>
                      <TextField
                        name="classroom"
                        label="Classroom"
                        required
                        className={styles.textField}
                        select
                        onChange={handleChange}
                        style={{ marginBottom: 10 }}
                        SelectProps={{
                          MenuProps: {
                            className: styles.menu,
                          },
                        }}
                        InputLabelProps={{
                          shrink: !!classrooms && classrooms !== undefined,
                        }}
                      >
                        {classrooms.map(option => {
                          let classTitle = option.title
                          classTitle = classTitle.substring(
                            classTitle.indexOf(':') + 2,
                            classTitle.length
                          )
                          return (
                            <MenuItem key={classTitle} value={option.id}>
                              {classTitle}
                            </MenuItem>
                          )
                        })}
                      </TextField>
                    </Fragment>
                  )
                }}
              </Query>
            )}
          </Flex>
        </Flex>
      </form>
    </Flex>
  )
}

const styles = theme => ({
  menu: {
    width: '20%',
  },
  asterisk: {
    color: 'red',
  },
})

export default withStyles(styles)(AddAssessmentForm)
