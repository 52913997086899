import React, { Component } from 'react'
import { Query, withApollo } from 'react-apollo'
import { Redirect } from 'react-router-dom'
import ClassDetailTable from './ClassDetailTable'
import ClassDetailSidebar from './ClassDetailSidebar'

import { GET_CLASSROOM, GET_ENROLLMENTS_FROM_STUDENT } from './queries'

import {
  Button,
  ErrorMessage,
  Flex,
  LoadingMessage,
  Menu,
  MenuItem,
  SettingsIcon,
  SnackbarNotification,
  Tooltip,
} from '../../components'
import { getNextClassCode, getNextSession } from '../../utils'

class ClassTableView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      redirect: false,
      sidebar: true,
      showFilters: true,
      menuAnchor: null,
      week: null,
      checked: {},
      sorted: [
        {
          id: 'lastName',
          desc: false,
        },
      ],
      showRenewals: false,
      renewedStudents: [],
      snackbar: {
        open: false,
        message: '',
        messageType: '',
      },
    }
  }

  handleSingleCheckboxChange = index => {
    let newChecked = this.state.checked
    newChecked[index] = !this.state.checked[index]
    this.setState(state => ({
      selectAll: !newChecked[index] && state.selectAll ? false : null,
      checked: newChecked,
    }))
  }

  toggleSidebar = () => {
    this.setState(state => ({
      sidebar: !this.state.sidebar,
      menuAnchor: null,
    }))
  }

  toggleFilters = () => {
    this.setState(state => ({
      showFilters: !state.showFilters,
      menuAnchor: null,
    }))
  }

  toggleRenewals = async (classroom, students) => {
    const { client } = this.props
    const nextClassCode = getNextClassCode(classroom.code)
    const { quarter: nextQuarter, year: nextYear } = getNextSession(classroom)
    const renewedStudents = []
    this.setState({
      snackbar: {
        open: true,
        message: 'Loading Renewals',
        messageType: 'loading',
      },
    })
    await Promise.all(
      students.map(async student => {
        const studentData = await client.query({
          query: GET_ENROLLMENTS_FROM_STUDENT,
          variables: { id: student.id },
        })
        for (const enrollment of studentData.data.student.enrollments) {
          const { code, quarter, year } = enrollment.classroom
          if (
            code.startsWith(nextClassCode) &&
            quarter === nextQuarter &&
            year === nextYear
          )
            renewedStudents.push(student.id)
        }
      })
    )
    this.setState({
      snackbar: {
        open: true,
        message: 'Finished Loading Renewals',
        messageType: 'success',
      },
      renewedStudents,
      showRenewals: true,
    })
  }

  onFilteredChange = filtered => {
    this.setState({
      filtered,
    })
  }

  menuOpen = event => {
    this.setState({
      menuAnchor: event.currentTarget,
    })
  }

  menuClose = () => {
    this.setState({
      menuAnchor: null,
    })
  }

  rowClick = rowInfo => {
    rowInfo &&
      this.setState({
        redirect: true,
        redirectTo: `/student/${rowInfo.original.id}`,
      })
  }

  setWeek = event => {
    this.setState({
      week: event.target.value,
    })
  }

  handleEnrollmentTypes = type => {
    switch (type) {
      case 'PAID':
        return 'Enrolled'
      case 'TRIAL':
        return 'Trial'
      case 'QUALIFY':
        return 'Qualifying'
      default:
        console.error('Invalid enrollment type')
        break
    }
  }

  menuButton = () => {
    const { menuAnchor } = this.state
    return (
      <Flex p="5px">
        <Flex p="5px">
          <Tooltip>
            <Button color="default" label="Menu" onClick={this.menuOpen}>
              <SettingsIcon />
            </Button>
          </Tooltip>
        </Flex>
        <Menu
          id="simple-menu"
          anchorEl={menuAnchor}
          open={Boolean(menuAnchor)}
          onClose={this.menuClose}
        >
          <MenuItem onClick={this.toggleFilters}>Toggle Filters</MenuItem>
        </Menu>
      </Flex>
    )
  }

  render() {
    const {
      checked,
      filtered,
      redirect,
      redirectTo,
      renewedStudents,
      showFilters,
      showRenewals,
      snackbar,
      sidebar,
      sorted,
    } = this.state
    if (redirect) {
      return <Redirect push to={redirectTo} />
    }

    return (
      <Query
        fetchPolicy={'cache-and-network'}
        query={GET_CLASSROOM}
        variables={{ id: this.props.match.params[0] }}
      >
        {({ loading, error, data }) => {
          if (loading) return <LoadingMessage />
          if (error) return <ErrorMessage error={error} />
          const processedStudents = data.classroom.enrollments.map(
            enrollment => {
              const {
                id,
                username,
                firstName,
                lastName,
                gender,
                familyAccount,
                newStudent,
              } = enrollment.student
              return {
                id: id,
                type: this.handleEnrollmentTypes(enrollment.status),
                enrollmentId: enrollment.id,
                username,
                renewal: renewedStudents.includes(id) ? '✓' : '',
                firstName: firstName,
                lastName: lastName,
                gender: gender,
                newStudent: newStudent,
                primaryGuardian:
                  familyAccount &&
                  familyAccount.primaryFamilyMember &&
                  `${familyAccount.primaryFamilyMember.firstName} ${familyAccount.primaryFamilyMember.lastName}`,
                familyMemberId:
                  familyAccount &&
                  familyAccount.primaryFamilyMember &&
                  familyAccount.primaryFamilyMember.id,
                phoneNumber:
                  familyAccount &&
                  familyAccount.primaryFamilyMember &&
                  familyAccount.primaryFamilyMember.phoneNumber,
                createdOn: enrollment.createdOn,
                email:
                  familyAccount &&
                  familyAccount.primaryFamilyMember &&
                  familyAccount.primaryFamilyMember.email,
                overallGrade: enrollment.overallGrade,
              }
            }
          )

          const checkedStudents = processedStudents.filter(
            (student, index) => this.state.checked[index]
          )

          return (
            <Flex>
              {sidebar ? (
                <ClassDetailSidebar
                  classroom={data.classroom}
                  show={sidebar}
                  submitEdits={this.submitClassEdits}
                  students={processedStudents}
                  checkedStudents={checkedStudents}
                  menu={this.menuButton}
                  toggleRenewals={this.toggleRenewals}
                />
              ) : null}
              <Flex pl={sidebar ? '0px' : '20px'} align="left" column grow={1}>
                <ClassDetailTable
                  students={processedStudents}
                  showRenewals={showRenewals}
                  filterable={showFilters}
                  onRowClick={this.rowClick}
                  sorted={sorted}
                  filtered={filtered}
                  onFilteredChange={this.onFilteredChange}
                  checked={checked}
                  handleSingleCheckboxChange={this.handleSingleCheckboxChange}
                />
              </Flex>
              <SnackbarNotification
                open={snackbar.open}
                handleClose={() => {
                  this.setState({
                    snackbar: {
                      ...snackbar,
                      open: false,
                    },
                  })
                }}
                message={snackbar.message}
                messageType={snackbar.messageType}
              />
            </Flex>
          )
        }}
      </Query>
    )
  }
}

export default withApollo(ClassTableView)
