import React, { Component } from 'react'
import { Mutation } from 'react-apollo'
import { Redirect } from 'react-router-dom'

import {
  Button,
  Divider,
  Flex,
  SidebarText,
  SnackbarNotification,
  withStyles,
  MessageButton,
} from '../../components'

import { ARCHIVE_COUNSELING_ENROLLMENT } from './queries'
import { sideBarStyles } from '../../utils/style'

class CounselingSidebar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      redirect: false,
      snackbar: {
        open: false,
        message: '',
        messageType: '',
        windowReload: false,
      },
    }
  }

  addStudent = () => {
    this.setState({
      redirect: true,
      redirectTo: '/counseling/add-student',
    })
  }

  countCounselingEnrollmentStatuses = counselingEnrollmentStatuses => {
    const { counselingEnrollments } = this.props
    const counselingEnrollmentCount = counselingEnrollments.reduce(
      (accumulator, counselingEnrollment) => {
        return (
          accumulator +
          (counselingEnrollment.status === counselingEnrollmentStatuses ? 1 : 0)
        )
      },
      0
    )
    return counselingEnrollmentCount
  }

  render() {
    const { redirect, redirectTo, snackbar } = this.state
    if (redirect) {
      return <Redirect push to={redirectTo} />
    }

    const {
      checkedRows,
      classes,
      counselingEnrollments,
      menu,
      statuses,
      show,
    } = this.props

    return (
      <Flex
        column
        grow={0}
        shrink={0}
        overflow="hidden"
        p="10px"
        style={{
          width: show ? '15.38%' : 0,
          borderRight: `${show ? '1px' : 0} ${'#aaaaaa'} solid`,
          maxWidth: show ? '200px' : 0,
          overflowX: show ? undefined : 'hidden',
          flexShrink: 0,
          textAlign: 'left',
          backgroundColor: '#e5e5e5',
        }}
      >
        <Flex column grow={1} justify="space-between">
          <Flex column>
            <Button aria-label="AddNewStudent" onClick={this.addStudent}>
              Add Student
            </Button>
            <br />
            <Mutation mutation={ARCHIVE_COUNSELING_ENROLLMENT}>
              {archiveCounselingEnrollment => (
                <Button
                  style={{ marginBottom: 20 }}
                  onClick={async () => {
                    if (
                      window.confirm(
                        'Are you sure you want to remove every marked student from the counseling program?'
                      )
                    ) {
                      await Promise.all(
                        checkedRows.map(async rowIndex => {
                          await archiveCounselingEnrollment({
                            variables: {
                              id: counselingEnrollments[rowIndex].id,
                            },
                          })
                        })
                      )
                      this.setState({
                        snackbar: {
                          open: true,
                          message: 'Counseling Enrollments archived',
                          messageType: 'success',
                          windowReload: true,
                        },
                      })
                    }
                  }}
                >
                  Remove Students
                </Button>
              )}
            </Mutation>
            <MessageButton
              title="Message Students"
              label={'Enter a message you would like to send'}
              students={(function() {
                if (checkedRows.length === 0) {
                  return counselingEnrollments.map(enrollment => {
                    return {
                      firstName: enrollment.student.firstName,
                      lastName: enrollment.student.lastName,
                      familyMemberId:
                        enrollment.student.familyAccount.primaryFamilyMember.id,
                      phoneNumber: enrollment.student.phoneNumber,
                      username: enrollment.student.username,
                    }
                  })
                } else {
                  return counselingEnrollments
                    .filter((enrollment, index) => {
                      return checkedRows[index]
                    })
                    .map(enrollment => {
                      return {
                        firstName: enrollment.student.firstName,
                        lastName: enrollment.student.lastName,
                        familyMemberId:
                          enrollment.student.familyAccount.primaryFamilyMember
                            .id,
                        phoneNumber: enrollment.student.phoneNumber,
                        username: enrollment.student.username,
                      }
                    })
                }
              })()}
              instructorName={'Ardent Academy'}
            />
            <SidebarText
              classes={{ root: classes.headline }}
              title="Detail View"
            />
            <Divider />
            <SidebarText
              classes={{ root: classes.subheadingOne }}
              title="Total Students:"
              value={counselingEnrollments.length}
            />
            {statuses.map(option => (
              <SidebarText
                key={option}
                title={`${option}:`}
                value={this.countCounselingEnrollmentStatuses(option)}
              />
            ))}
          </Flex>
          {menu()}
        </Flex>
        <SnackbarNotification
          open={snackbar.open}
          handleClose={() => {
            this.setState({
              snackbar: {
                ...snackbar,
                open: false,
              },
            })
            if (snackbar.windowReload) window.location.reload()
          }}
          message={snackbar.message}
          messageType={snackbar.messageType}
        />
      </Flex>
    )
  }
}

export default withStyles(sideBarStyles)(CounselingSidebar)
