import React, { Fragment, useState } from 'react'
import { Query, Mutation } from 'react-apollo'
import {
  GET_OFFICE_EMPLOYEES,
  GET_CENTER_LOCATIONS,
  UPDATE_QUALIFY,
} from './queries'
import { debug } from '../../config'
import {
  DatePicker,
  ErrorMessage,
  Flex,
  MenuItem,
  TextField,
  withStyles,
  LoadingMessage,
  Button,
  Text,
  Typography,
} from '../../components'

const styles = theme => ({
  menu: {
    width: '20%',
  },
  asterisk: {
    color: 'red',
  },
  percentContainer: {
    borderRadius: '4px',
    border: '1px solid rgb(187, 187, 187)',
  },
})

const AssessmentForm = props => {
  const { qualify } = props
  const {
    id,
    createdOn,
    resolvedDate,
    employee,
    method,
    status,
    centerLocation,
    results,
    attendee,
    enrollment,
  } = qualify

  const [employeeId, setEmployeeId] = useState(employee.id)
  const [resolved, setResolved] = useState(resolvedDate)
  const [assessmentResults, setAssessmentResults] = useState(results)
  const [assessmentMethod, setAssessmentMethod] = useState(method)
  const [assessmentStatus, setAssessmentStatus] = useState(status)
  const [centerLocationId, setCenterLocationId] = useState(
    centerLocation && centerLocation.id
  )

  return (
    <Flex column style={{ align: 'center', flexGrow: 1 }}>
      <Flex justify="space-evenly">
        <Flex grow={2} m="20px" style={{ maxWidth: '400px' }} column>
          <TextField
            name="classroom"
            label="Classroom"
            style={{ marginBottom: 20 }}
            value={(function() {
              let classTitle = enrollment.classroom.title
              return classTitle.substring(
                classTitle.indexOf(':') + 2,
                classTitle.length
              )
            })()}
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            name="status"
            label="Status"
            style={{ marginBottom: 20 }}
            select
            fullWidth
            value={assessmentStatus}
            onChange={e => setAssessmentStatus(e.target.value)}
          >
            {['SCHEDULED', 'COMPLETED', 'ABANDONED'].map(statusValue => (
              <MenuItem key={statusValue} value={statusValue}>
                {statusValue}
              </MenuItem>
            ))}
          </TextField>
          <Query query={GET_OFFICE_EMPLOYEES}>
            {({ loading, error, data }) => {
              if (loading) return <LoadingMessage />
              if (error) return <ErrorMessage error={error} />
              return (
                <Fragment>
                  <TextField
                    name="responsibleEmployee"
                    label="Responsible Employee"
                    style={{ marginBottom: 20 }}
                    select
                    fullWidth
                    value={employeeId}
                    onChange={e => setEmployeeId(e.target.value)}
                  >
                    {data.employees.map(employee => (
                      <MenuItem
                        key={employee.id}
                        value={employee.id}
                      >{`${employee.firstName} ${employee.lastName}`}</MenuItem>
                    ))}
                  </TextField>
                </Fragment>
              )
            }}
          </Query>
          <TextField
            name="results"
            label="Results"
            multiline
            maxRows="4"
            style={{ marginBottom: 20 }}
            value={assessmentResults ? assessmentResults : ''}
            onChange={e => setAssessmentResults(e.target.value)}
          />
          <Flex style={{ marginTop: 10 }}>
            <Typography
              style={{
                marginRight: 10,
                marginTop: 5,
              }}
            >
              Assessment Completion:
            </Typography>
            <Flex
              style={{
                borderRadius: '4px',
                border: '1px solid rgb(187, 187, 187)',
                padding: '5px 10px',
                marginBottom: 20,
              }}
            >
              <Typography>
                {attendee && attendee.percentCompleted
                  ? `${attendee.percentCompleted}%`
                  : `0%`}
              </Typography>
            </Flex>
          </Flex>
          <Flex>
            <Typography
              style={{
                marginRight: 10,
                marginTop: 5,
              }}
            >
              Assessment Grade:
            </Typography>
            <Flex
              style={{
                borderRadius: '4px',
                border: '1px solid rgb(187, 187, 187)',
                padding: '5px 10px',
                marginBottom: 20,
              }}
            >
              <Typography>
                {attendee && attendee.overallGrade
                  ? `${attendee.overallGrade}%`
                  : `0%`}
              </Typography>
            </Flex>
          </Flex>
        </Flex>
        <Flex grow={2} m="20px" style={{ maxWidth: '400px' }} column>
          <DatePicker
            name="createdOn"
            label="Created Date"
            style={{ marginBottom: 20 }}
            disabled //for now
            value={createdOn}
          />
          <DatePicker
            name="resolvedDate"
            label="Resolved Date"
            style={{ marginBottom: 20 }}
            value={resolvedDate}
            onChange={e => setResolved(e.target.value.toISOString())}
          />
          <TextField
            name="method"
            label="Assessment Method"
            style={{ marginBottom: 20 }}
            select
            fullWidth
            value={assessmentMethod}
            onChange={e => setAssessmentMethod(e.target.value)}
          >
            {['ONLINE', 'CENTER'].map(methodValue => (
              <MenuItem key={methodValue} value={methodValue}>
                {methodValue}
              </MenuItem>
            ))}
          </TextField>
          {assessmentMethod === 'CENTER' && (
            <Query query={GET_CENTER_LOCATIONS}>
              {({ loading, error, data }) => {
                if (loading) return <LoadingMessage />
                if (error) return <ErrorMessage error={error} />
                return (
                  <Fragment>
                    <TextField
                      name="centerLocation"
                      label="Center Location"
                      style={{ marginBottom: 20 }}
                      select
                      fullWidth
                      value={centerLocationId}
                      onChange={e => setCenterLocationId(e.target.value)}
                    >
                      {data.centerLocations.map(center => {
                        return (
                          <MenuItem key={center.id} value={center.id}>
                            {center.name}
                          </MenuItem>
                        )
                      })}
                    </TextField>
                  </Fragment>
                )
              }}
            </Query>
          )}
        </Flex>
      </Flex>
      <Flex direction={'row-reverse'} align={'center'}>
        <Button
          onClick={() => {
            if (debug)
              window.open(
                `https://instructor-next.ardentlabs.io/` +
                  `grade/${enrollment.classroom.id}`
              )
            else
              window.open(
                `https://instructor.ardentlabs.io/` +
                  `grade/${enrollment.classroom.id}`
              )
          }}
        >
          Assessment Details
        </Button>
        <Mutation mutation={UPDATE_QUALIFY}>
          {(updateQualify, { data }) => (
            <Fragment>
              <Button
                style={{ marginRight: '10px' }}
                onClick={() => {
                  updateQualify({
                    variables: {
                      id: id,
                      input: {
                        employeeId: employeeId,
                        centerLocationId: centerLocationId,
                        method: assessmentMethod,
                        resolvedDate: resolved && resolved.toISOString(),
                        status: assessmentStatus,
                        results: assessmentResults && assessmentResults,
                      },
                    },
                  })
                }}
              >
                Save Changes
              </Button>
              <Text style={{ marginRight: '10px' }}>
                Make sure to verify all information before hitting save!
              </Text>
            </Fragment>
          )}
        </Mutation>
      </Flex>
    </Flex>
  )
}

export default withStyles(styles)(AssessmentForm)
