import React, { Component } from 'react'
import { Mutation, Query, withApollo } from 'react-apollo'
import { Redirect, Link } from 'react-router-dom'
import Composer from 'react-composer'
import gql from 'graphql-tag'
import TrialForm from './TrialForm'
import { formatBirthday } from '../../utils/datetime'

import {
  Button,
  ErrorMessage,
  EvaluationForm,
  AccordionForm,
  Flex,
  LoadingMessage,
  OpportunityForm,
  Paper,
  Text,
  withStyles,
  StudentDetailForm,
} from '../../components'
import { GET_TRIAL, GET_TRIALS, UPDATE_OPPORTUNITY } from '../../queries'

const GET_ENROLLMENTS = gql`
  query getEnrollments($id: ID!) {
    student(id: $id) {
      enrollments {
        id
        classroom {
          id
        }
      }
    }
  }
`

const GET_ATTENDEE = gql`
  query getAttendee($id: ID!) {
    lesson(id: $id) {
      id
      attendees {
        id
        student {
          id
        }
      }
    }
  }
`

const UPDATE_TRIAL = gql`
  mutation updateTrial($id: ID!, $input: UpdateTrialInput!) {
    updateTrial(id: $id, input: $input) {
      id
    }
  }
`

const ARCHIVE_TRIAL = gql`
  mutation archiveTrial($id: ID!) {
    archiveTrial(id: $id) {
      id
    }
  }
`

const ARCHIVE_ATTENDEE = gql`
  mutation archiveAttendeeRecord($id: ID!) {
    archiveAttendee(id: $id) {
      id
    }
  }
`

const ARCHIVE_ENROLLMENT = gql`
  mutation archiveEnrollmentRecord($id: ID!) {
    archiveEnrollment(id: $id) {
      id
    }
  }
`

class TrialDetailView extends Component {
  render() {
    const trialId = this.props.match.params[0]
    return (
      <Query
        fetchPolicy={'cache-and-network'}
        query={GET_TRIAL}
        variables={{ id: trialId }}
      >
        {({ loading, error, data }) => {
          if (loading) return <LoadingMessage />
          if (error) return <ErrorMessage error={error} />

          return <InnerTrialDetailView {...this.props} trial={data.trial} />
        }}
      </Query>
    )
  }
}

class InnerTrialDetailView extends Component {
  constructor(props) {
    super(props)
    const {
      alternateCourse,
      evaluationDiscipline,
      evaluationMentalMath,
      evaluationMotivation,
      evaluationProblemSolving,
      evaluationSkillBuilder,
      followupEmployee,
      instructorComment,
      notes,
      opportunity,
      quizGrade,
      quizScore,
      quizTotal,
      recommendation,
      toLesson,
      followupStatus,
    } = props.trial
    this.state = {
      dateString: new Date().toISOString(),
      redirect: false,
      redirectTo: '',
      trialInfoChanged: false,
      trialOpportunityChanged: false,
      trial: {
        ...props.trial,
        quizScore: quizScore ? quizScore : 0,
        quizTotal: quizTotal ? quizTotal : 100,
        quizGrade: quizGrade && quizGrade.toFixed(2),
        toClassId: toLesson && toLesson.classroom.id,
        toLessonId: toLesson && toLesson.id,
        evaluationMentalMath:
          evaluationMentalMath && evaluationMentalMath.toString(),
        evaluationSkillBuilder:
          evaluationSkillBuilder && evaluationSkillBuilder.toString(),
        evaluationProblemSolving:
          evaluationProblemSolving && evaluationProblemSolving.toString(),
        evaluationMotivation:
          evaluationMotivation && evaluationMotivation.toString(),
        evaluationDiscipline:
          evaluationDiscipline && evaluationDiscipline.toString(),
        instructorComment: instructorComment,
        recommendation: recommendation ? 'Yes' : 'No',
        alternateCourse: alternateCourse,
        notes: notes,
        opportunity: {
          ...opportunity,
          student: {
            ...opportunity.student,
            birthdate: formatBirthday(opportunity.student.birthdate),
          },
        },
        followupEmployeeId: followupEmployee && followupEmployee.id,
        followupStatus: followupStatus ? 'Yes' : 'No',
      },
    }
  }

  returnToTrialTable = () => {
    this.setState({
      redirect: true,
      redirectTo: '/trial',
    })
  }

  /**
   *
   * @param {function} archiveTrial - mutation for archiving trial
   * @param {function} archiveAttendee - mutation for archiving attendee
   * @param {function} archiveEnrollment - mutation for archiving enrollment
   *
   * Creating a trial also creates attendee and enrollment records
   * We need to archive these when trial is archived
   */

  handleArchive = async (archiveTrial, archiveAttendee, archiveEnrollment) => {
    const { client, trial } = this.props
    if (trial.toLesson) {
      const studentId = trial.opportunity.student.id
      const lessonId = trial.toLesson.id
      const classroomId = trial.toLesson.classroom.id
      let attendeeId, enrollmentId

      //Need this query to fetch attendee records from trial
      const attendeeData = await client.query({
        query: GET_ATTENDEE,
        variables: { id: lessonId },
      })
      const attendee = attendeeData.data.lesson.attendees.find(
        attendee => attendee.student.id === studentId
      )
      attendeeId = attendee ? attendee.id : null

      //Need this query to fetch enrollment records from trial
      const enrollmentData = await client.query({
        query: GET_ENROLLMENTS,
        variables: { id: studentId },
      })
      const enrollment = enrollmentData.data.student.enrollments.find(
        enrollment => enrollment.classroom.id === classroomId
      )
      enrollmentId = enrollment ? enrollment.id : null

      /**
       * Trials may or may not have an attendeeId and enrollmentId attached
       * This is because previously attendee and enrollment records were created on trial creation
       * So we need to archive these just in case the trials have them
       */
      if (attendeeId && enrollmentId) {
        await archiveAttendee({
          variables: {
            id: attendeeId,
          },
        })
        await archiveEnrollment({
          variables: {
            id: enrollmentId,
          },
        })
      }
      await archiveTrial({
        variables: {
          id: trial.id,
        },
      })
      alert('Trial archived')
      this.returnToTrialTable()
    }
  }

  handleTrialEvalChange = name => event => {
    const value =
      name === 'alternateCourse'
        ? event.value
        : name === 'followupDate'
        ? new Date(event.target.value)
        : event.target.value

    this.setState(state => ({
      trialEvalChanged: true,
      trial: {
        ...state.trial,
        [name]: value,
      },
    }))
  }

  handleTrialInfoChange = event => {
    const { name, value } = event.target
    this.setState(state => ({
      trialInfoChanged: true,
      trial: {
        ...state.trial,
        [name]: value,
      },
    }))
  }

  handleOpportunityChange = event => {
    const { name, value } = event.target
    this.setState(state => ({
      trialOpportunityChanged: true,
      trial: {
        ...state.trial,
        opportunity: {
          ...state.trial.opportunity,
          [name]: value,
        },
      },
    }))
  }

  render() {
    const {
      redirect,
      redirectTo,
      trial,
      trialInfoChanged,
      trialOpportunityChanged,
    } = this.state
    const {
      followupStatus,
      followupDate,
      followupEmployeeId,
      followupResult,
      notes,
      opportunity,
      opportunityId,
      status,
      toClassId,
      toLessonId,
    } = trial
    const {
      primaryInterest,
      session,
      year,
      source,
      responsibleEmployeeId,
      stage,
      lastContacted,
    } = opportunity
    if (redirect) {
      return <Redirect push to={redirectTo} />
    }

    const handleTrialInfoSubmission = async () => {
      const client = this.props.client
      const { trial } = this.state

      await client.mutate({
        mutation: UPDATE_TRIAL,
        variables: {
          id: trial.id,
          input: {
            status: status,
            notes: notes,
            followupStatus:
              followupStatus === 'Yes'
                ? true
                : followupStatus === 'No'
                ? false
                : null,
            followupDate,
            followupEmployeeId,
            followupResult,
          },
        },
        refetchQueries: [
          {
            query: GET_TRIALS,
          },
        ],
      })
      this.setState({
        trialInfoChanged: false,
      })
    }

    const handleOpportunitySubmission = async () => {
      const client = this.props.client
      const { opportunity } = this.state.trial

      await client.mutate({
        mutation: UPDATE_OPPORTUNITY,
        variables: {
          id: opportunity.id,
          input: {
            status: opportunity.status,
            primaryInterest,
            session,
            year: parseInt(year, 10),
            source,
            notes: opportunity.notes,
            responsibleEmployeeId,
            stage,
            lastContacted,
          },
        },
      })
      this.setState({
        trialOpportunityChanged: false,
      })
    }

    const { student } = opportunity
    return (
      <Flex
        column
        grow={1}
        style={{
          textAlign: 'left',
          margin: 'auto',
          width: '67%',
          minWidth: '600px',
          maxWidth: '1000px',
        }}
      >
        <br />
        <Link to="/trial">{'< back to Trial List'}</Link>
        <br />
        <Text variant="h4">
          {`Trial for ${student.firstName} ${student.lastName}`}
        </Text>
        <br />
        <Flex>
          <Composer
            components={[
              <Mutation mutation={ARCHIVE_TRIAL}>{() => {}}</Mutation>,
              <Mutation mutation={ARCHIVE_ATTENDEE}>{() => {}}</Mutation>,
              <Mutation mutation={ARCHIVE_ENROLLMENT}>{() => {}}</Mutation>,
            ]}
          >
            {([archiveTrial, archiveAttendee, archiveEnrollment]) => {
              return (
                <Button
                  color="secondary"
                  aria-label="Archive"
                  onClick={() => {
                    if (
                      window.confirm('Are you sure want to archive this trial?')
                    ) {
                      this.handleArchive(
                        archiveTrial,
                        archiveAttendee,
                        archiveEnrollment
                      )
                    }
                  }}
                >
                  Archive Trial
                </Button>
              )
            }}
          </Composer>
        </Flex>
        <br />
        <Paper>
          <div>
            <AccordionForm
              expanded
              overflow
              title="Trial Info"
              form={
                <Flex
                  column
                  style={{
                    align: 'center',
                    flexGrow: 1,
                  }}
                >
                  <TrialForm
                    trial={trial}
                    opportunityId={opportunityId}
                    trialId={trial.id}
                    classId={toClassId}
                    followupStatus={followupStatus}
                    lessonId={toLessonId}
                    status={status}
                    statusOptions={this.props.trialStatuses}
                    notes={notes}
                    handleChange={this.handleTrialInfoChange}
                    fromTrialDetailView
                  />
                  <Flex direction={'row-reverse'} align={'center'}>
                    <Button
                      onClick={handleTrialInfoSubmission}
                      disabled={!trialInfoChanged}
                    >
                      Save Changes
                    </Button>
                    <Text style={{ marginRight: '10px' }}>
                      Make sure to verify all information before hitting save!
                    </Text>
                  </Flex>
                </Flex>
              }
            />
            <AccordionForm
              expanded
              overflow
              title="Trial Evaluation Info"
              form={<EvaluationForm trial={trial} />}
            />
          </div>

          {opportunity && (
            <AccordionForm
              expanded
              title="Opportunity Info"
              form={
                <Flex
                  column
                  style={{
                    align: 'center',
                    flexGrow: 1,
                  }}
                >
                  <OpportunityForm
                    showStatus
                    opportunity={opportunity}
                    handleChange={this.handleOpportunityChange}
                    opportunityChanged={trialOpportunityChanged}
                    handleSave={handleOpportunitySubmission}
                    showSave={true}
                  />
                </Flex>
              }
            />
          )}

          {student && (
            <AccordionForm
              expanded
              title="Student Info"
              form={<StudentDetailForm student={student} />}
            />
          )}
        </Paper>
      </Flex>
    )
  }
}

const styles = theme => ({
  root: {
    textAlign: 'left',
    margin: 'auto',
    width: '67%',
    minWidth: '600px',
    maxWidth: '1000px',
  },
  display1: {
    textAlign: 'left',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  expansionDetail: {
    overflow: 'visible',
  },
})

export default withApollo(withStyles(styles)(TrialDetailView))
