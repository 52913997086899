import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import { Button, Text, AccordionForm, Flex } from '../../components'
import { convertToYMD, formatTime } from '../../utils/datetime'
import './PaymentDetail.css'

class PaymentDetailedPanel extends Component {
  render() {
    let payment = this.props.payment

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '5px',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Text variant="h4">Payment</Text>
          <Button onClick={() => this.props.history.push('/payment')}>
            back
          </Button>
        </div>
        <div className="innerRowDiv">
          <Text className="innerRowText">{`Transaction ID: ${payment.identification}`}</Text>
          <Text className="innerRowText">{`Transaction Status: ${'SUCCESS'}`}</Text>

          <AccordionForm
            expanded
            title="Settlement Information"
            form={
              <Flex
                column
                style={{
                  align: 'center',
                  flexGrow: 1,
                }}
              >
                <Text className="innerRowText">
                  {'Settlement Date: ' +
                    convertToYMD(payment.dateReceived) +
                    ' ' +
                    formatTime(payment.dateReceived)}
                </Text>
                <Text className="innerRowText">{`Authorizatoin Amount: $${payment.amount}`}</Text>

                <Text className="innerRowText">
                  {'Submit Date: ' +
                    convertToYMD(payment.createdOn) +
                    ' ' +
                    formatTime(payment.createdOn)}
                </Text>
              </Flex>
            }
          />

          <AccordionForm
            expanded
            title="Invoice Information"
            form={
              <Flex
                column
                style={{
                  align: 'center',
                  flexGrow: 1,
                }}
              >
                <Text className="innerRowText">{`Invoice No.: ${payment.invoice.invoiceNumber}`}</Text>
                <Text className="innerRowText">{`Description: ${payment.invoice.summary}`}</Text>
              </Flex>
            }
          />

          <AccordionForm
            expanded
            title="Parent Information"
            form={
              <Flex
                column
                style={{
                  align: 'center',
                  flexGrow: 1,
                }}
              >
                <Text className="innerRowText">
                  {`User: ${
                    payment.primaryFamilyMember
                      ? `${payment.primaryFamilyMember.firstName} ${payment.primaryFamilyMember.lastName}`
                      : 'UNKNOWN'
                  }`}
                </Text>

                <Text className="innerRowText">
                  {`Email: ${
                    payment.primaryFamilyMember
                      ? `${payment.primaryFamilyMember.email || 'UNKNOWN'}`
                      : 'UNKNOWN'
                  }`}
                </Text>
                <Text className="innerRowText">
                  {`Phone: ${
                    payment.primaryFamilyMember
                      ? `${payment.primaryFamilyMember.phoneNumber ||
                          'UNKNOWN'}`
                      : 'UNKNOWN'
                  }`}
                </Text>
              </Flex>
            }
          />
        </div>
      </div>
    )
  }
}

export default withRouter(PaymentDetailedPanel)
