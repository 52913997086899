import React, { Component } from 'react'
import { Query } from 'react-apollo'
import Composer from 'react-composer'
import { Route, Switch } from 'react-router-dom'

import AddMeetingView from './AddMeetingView'
import CounselingAddStudentView from './CounselingAddStudentView'
import CounselingDetailView from './CounselingDetailView'
import CounselingTableView from './CounselingTableView'

import {
  GET_COUNSELORS,
  GET_COUNSELING_ENROLLMENT_STATUS_OPTIONS,
} from './queries'
import { ErrorMessage, LoadingMessage } from '../../components'
import CounselingMeetingDetailView from './CounselingMeetingDetailView'
import { GET_CONTACT_METHOD_OPTIONS_QUERY } from '../../queries'

class CounselingView extends Component {
  render() {
    const { url } = this.props.match
    return (
      <Composer
        components={[
          <Query query={GET_COUNSELORS}>
            {function() {
              return ''
            }}
          </Query>,
          <Query query={GET_COUNSELING_ENROLLMENT_STATUS_OPTIONS}>
            {function() {
              return ''
            }}
          </Query>,
          <Query query={GET_CONTACT_METHOD_OPTIONS_QUERY}>
            {function() {
              return ''
            }}
          </Query>,
        ]}
      >
        {([
          {
            loading: counselorLoading,
            error: counselorError,
            data: counselorData,
          },
          { loading: statusLoading, status: statusError, data: statusData },
          { loading: methodLoading, status: methodError, data: methodData },
        ]) => {
          if (counselorLoading || statusLoading || methodLoading)
            return <LoadingMessage />
          if (counselorError || statusError || methodError)
            return (
              <ErrorMessage
                error={counselorError || statusError || methodError}
              />
            )
          const counselors = counselorData.employees
          const statuses = statusData.__type.enumValues.map(({ name }) => name)
          const contactMethods = methodData.__type.enumValues.map(
            ({ name }) => name
          )
          return (
            <Switch>
              <Route
                path={`${url}/add-student`}
                render={props => (
                  <CounselingAddStudentView
                    {...props}
                    statuses={statuses}
                    counselors={counselors}
                  />
                )}
              />
              <Route
                path={`${url}/*/meeting/*`}
                render={props => (
                  <CounselingMeetingDetailView
                    {...props}
                    contactMethods={contactMethods}
                  />
                )}
              />
              <Route
                path={`${url}/*/add-meeting`}
                render={props => (
                  <AddMeetingView {...props} contactMethods={contactMethods} />
                )}
              />
              <Route
                path={`${url}/*`}
                render={props => (
                  <CounselingDetailView
                    {...props}
                    statuses={statuses}
                    counselors={counselors}
                  />
                )}
              />
              <Route
                path={url}
                render={props => (
                  <CounselingTableView {...props} statuses={statuses} />
                )}
              />
            </Switch>
          )
        }}
      </Composer>
    )
  }
}

export default CounselingView
