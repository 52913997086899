import React, { Component } from 'react'
import { ARCHIVE_INVOICE, GET_INVOICES } from './queries'
import { Query, withApollo } from 'react-apollo'
import {
  ErrorMessage,
  Flex,
  LoadingMessage,
  Redirect,
  SnackbarNotification,
} from '../../components'
import InvoiceTable from './InvoiceTable'
import InvoiceSidebar from './InvoiceSidebar'
class InvoiceTableView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      redirect: false,
      sorted: [
        {
          id: 'issueDate',
          desc: true,
        },
      ],
      filtered: [
        {
          id: 'status',
          value: '!PAID',
        },
      ],
      checked: [],
      invoices: [],
      snackbar: {
        open: false,
        message: '',
        messageType: '',
        windowReload: false,
      },
      queryFilter: { reconciled: false },
      sidebar: {
        startDate: new Date().toISOString(),
        endDate: new Date().toISOString(),
        showReconciled: true,
      },
    }
  }

  openSnack = (message, messageType, windowReload) => {
    const { snackbar } = this.state
    const newSnackbar = {
      ...snackbar,
      open: true,
      messageType,
      message,
      windowReload,
    }
    this.setState({ snackbar: newSnackbar })
  }

  onFilteredChange = filtered => {
    this.setState({
      filtered,
    })
  }

  onQueryFilterChange = (queryFilter, sidebar) => {
    this.setState({ queryFilter, sidebar })
  }

  rowClick = rowInfo => {
    rowInfo &&
      this.setState({
        redirect: true,
        redirectTo: `/invoice/${rowInfo.original.invoiceNumber}`,
      })
  }

  updateChecked = newChecked => {
    this.setState({ checked: newChecked })
  }

  archiveChecked = async () => {
    const { checked } = this.state

    let chked = checked.filter(ch => ch)
    if (chked.length === 0) {
      this.openSnack('No invoice selected', 'other', false)
      return
    }

    for (let id of chked) {
      await this.props.client.mutate({
        mutation: ARCHIVE_INVOICE,
        variables: { id },
      })
    }

    this.setState({ checked: [] })

    this.openSnack('Invoices archived, reloading..', 'success', true)
  }

  render() {
    const { redirect, checked, snackbar, filtered } = this.state
    if (redirect) {
      return <Redirect push to={this.state.redirectTo} />
    }
    return (
      <Query
        query={GET_INVOICES}
        variables={{
          filter:
            filtered[0].value === 'ALL'
              ? { ...this.state.queryFilter }
              : filtered[0].value === '!PAID'
              ? { $not: { status: 'PAID' }, ...this.state.queryFilter }
              : { status: filtered[0].value, ...this.state.queryFilter },
        }}
      >
        {({ loading, error, data, refetch }) => {
          if (loading) return <LoadingMessage />
          if (error) return <ErrorMessage error={error} />
          const processedInvoices = data.invoices.map(invoice => {
            const { payments, items, familyAccount } = invoice
            return {
              ...invoice,
              paymentDate:
                payments.length !== 0 ? payments[0].dateReceived : null,
              numItems: items.length,
              location: familyAccount.centerLocation
                ? familyAccount.centerLocation.name
                : null,
            }
          })
          return (
            <Flex>
              <InvoiceSidebar
                show={true}
                invoices={processedInvoices}
                checked={checked}
                archiveCheckedHandler={this.archiveChecked}
                sidebar={this.state.sidebar}
                queryFilter={this.state.queryFilter}
                onQueryFilterChange={this.onQueryFilterChange}
              />
              <Flex align="left" column grow={1}>
                <InvoiceTable
                  invoices={processedInvoices}
                  // filterable={true}
                  checked={checked}
                  updateChecked={this.updateChecked}
                  onRowClick={this.rowClick}
                  sorted={this.state.sorted}
                  filtered={this.state.filtered}
                  onFilteredChange={this.onFilteredChange}
                />
              </Flex>
              <SnackbarNotification
                open={snackbar.open}
                handleClose={() => {
                  this.setState({
                    snackbar: {
                      ...snackbar,
                      open: false,
                    },
                  })
                  if (snackbar.windowReload) refetch()
                }}
                message={snackbar.message}
                messageType={snackbar.messageType}
              />
            </Flex>
          )
        }}
      </Query>
    )
  }
}

export default withApollo(InvoiceTableView)
