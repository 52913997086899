import gql from 'graphql-tag'

export const GET_RECONCILIATIONS = gql`
  query getReconciliations($filter: Filters) {
    reconciliations(filter: $filter, sort: { dateReconciled: 1 }) {
      id
      dateReconciled
      account
      amount
      deposit
      payments {
        id
        identification
        invoice {
          id
          invoiceNumber
        }
        dateReceived
        amount
        payee
        method
      }
    }
  }
`

export const GET_PAYMENTS = gql`
  query getPayments($filter: Filters) {
    payments(filter: $filter, sort: { dateReceived: 1 }) {
      id
      identification
      invoice {
        id
        invoiceNumber
      }
      dateReceived
      amount
      payee
      method
    }
  }
`
export const CREATE_RECONCILIATION = gql`
  mutation createReconciliation($input: CreateReconciliationInput!) {
    createReconciliation(input: $input) {
      id
    }
  }
`

export const UPDATE_PAYMENT = gql`
  mutation updatePayment($id: ID!, $input: UpdatePaymentInput!) {
    updatePayment(id: $id, input: $input) {
      id
    }
  }
`
