import React from 'react'
import { Button, ButtonGroup, Typography, Box } from '../../components'

const DaySelector = props => {
  return (
    <React.Fragment>
      <table style={{ width: '100%' }}>
        <thead>
          <tr>
            <th align="left">
              <Box m={3}>
                <Typography
                  variant="h5"
                  component="h5"
                  onClick={() => props.today()}
                  style={{ cursor: 'pointer' }}
                >
                  {props.type} Today
                </Typography>
              </Box>
            </th>
            <th align="right">
              <ButtonGroup
                variant="text"
                color="default"
                aria-label="text primary button group"
              >
                <Button onClick={() => props.prev()}>←</Button>
                <Button style={{ pointerEvents: 'none' }}>
                  {props.getDayEasy(
                    props.day.getDay(),
                    props.day.toLocaleString()
                  )}
                </Button>
                <Button onClick={() => props.next()}>→</Button>
              </ButtonGroup>
            </th>
          </tr>
        </thead>
      </table>
    </React.Fragment>
  )
}

export default DaySelector
