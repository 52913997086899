import gql from 'graphql-tag'

export const GET_ACTIVITY = gql`
  query getActivity($id: ID!) {
    activity(id: $id) {
      id
      priority
      method
      type
      endDate
      resolvedDate
      description
      resolution
      contact {
        id
        firstName
        lastName
        phoneNumber
        email
      }
      responsibleEmployee {
        id
        firstName
        lastName
      }
      opportunity {
        id
        status
        primaryInterest
        session
        year
        stage
        source
        lastContacted
        notes
        responsibleEmployee {
          id
          firstName
          lastName
        }
        createdBy {
          id
          firstName
          lastName
        }
      }
      student {
        id
        firstName
        lastName
        username
        gender
        birthdate
        grade
        school
        phoneNumber
        email
      }
    }
  }
`

export const GET_ACTIVITIES = gql`
  query getActivities($filter: Filters!) {
    activities(filter: $filter) {
      id
      resolvedDate
      type
      priority
      method
      contact {
        id
        firstName
        lastName
      }
      student {
        id
        firstName
        lastName
      }
      createdBy {
        id
        firstName
        lastName
      }
      responsibleEmployee {
        id
        firstName
        lastName
      }
      startDate
      endDate
      description
      resolution
    }
  }
`

export const CREATE_ACTIVITY = gql`
  mutation createActivity($input: CreateActivityInput!) {
    createActivity(input: $input) {
      id
    }
  }
`

export const ARCHIVE_ACTIVITY = gql`
  mutation archiveActivity($id: ID!) {
    archiveActivity(id: $id) {
      id
    }
  }
`

export const UPDATE_ACTIVITY = gql`
  mutation updateActivity($id: ID!, $input: UpdateActivityInput!) {
    updateActivity(id: $id, input: $input) {
      id
    }
  }
`

export const GET_ACTIVITY_CONTACT_METHOD_OPTIONS_QUERY = gql`
  query getActivityContactMethodOptions {
    __type(name: "ContactMethod") {
      enumValues {
        name
      }
    }
  }
`
export const GET_ACTIVITY_TYPES = gql`
  query getActivityTypes {
    __type(name: "ActivityType") {
      enumValues {
        name
      }
    }
  }
`

export const GET_ACTIVITY_PRIORITY_OPTIONS_QUERY = gql`
  query getActivityPriorityOptions {
    __type(name: "Priority") {
      enumValues {
        name
      }
    }
  }
`

export const GET_OFFICE_EMPLOYEES = gql`
  query getOfficeEmployees {
    employees(filter: { isOfficeUser: true }) {
      id
      firstName
      lastName
      status
    }
  }
`
export const CREATE_ENROLLMENT = gql`
  mutation enrollStudent($input: CreateEnrollmentInput!) {
    createEnrollment(input: $input) {
      id
      classroom {
        id
        lessons {
          id
          lessonPlan {
            id
            order
          }
        }
      }
    }
  }
`
export const CREATE_QUALIFY = gql`
  mutation createQualifying($input: CreateQualifyInput!) {
    createQualify(input: $input) {
      id
    }
  }
`

export const CREATE_ATTENDEE = gql`
  mutation makeAttendee($input: CreateAttendeeInput!) {
    createAttendee(input: $input) {
      id
    }
  }
`

export const GET_CLASSROOMS = gql`
  query getClassroooms($filter: Filters) {
    classrooms(filter: $filter) {
      id
      title
      code
    }
  }
`
