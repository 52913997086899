import * as actionTypes from '../actions'

const initialState = {
  loggedIn: false,
  user: {
    first_name: '',
    last_name: '',
    email: '',
    avatar: '',
    bio: '',
    role: '', // ['GUEST', 'USER', 'ADMIN']
  },
}

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SESSION_LOGIN: {
      return {
        ...action.payload,
      }
    }

    case actionTypes.SESSION_LOGOUT: {
      return {
        ...state,
        loggedIn: false,
        user: {
          role: 'GUEST',
        },
      }
    }

    default: {
      return state
    }
  }
}

export default sessionReducer
