import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Button } from '../../components'
import PaymentTable from './PaymentTable'

class DetailedReconcilePage extends Component {
  render() {
    return (
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            flex: 1,
          }}
        >
          <Button
            onClick={() => this.props.onClose()}
            style={{ alignSelf: 'flex-start' }}
          >
            Back
          </Button>
          <div>{`id: ${this.props.reconciliation.id}`}</div>
        </div>
        <PaymentTable payments={this.props.reconciliation.payments} readOnly />
      </div>
    )
  }
}

export default withRouter(DetailedReconcilePage)
