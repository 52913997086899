import { safeHtml } from 'common-tags'

const UsernameEmailTemplate = data => {
  const { studentName, username } = data
  return safeHtml`
    <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">

    <html>

    <head>
      <meta name="viewport" content="width=device-width,initial-scale=1">
      <meta http-equiv="Content-Type" content="text/html charset=UTF-8" />
      <title>Trial Confirmation</title>
      <style>
        html {
          font-size: 62.5%
        }

        body {
          font-family: "Verdana", "Geneva", "Helvetica", sans-serif;
        }

        p {
          position: relative;
          top: 1em;
        }

        p.opening {
          color: #009ad6;
        }

        .closing {
          position: relative;
          top: 2.8em;
          line-height: 1.9em;
        }

        .footer {
          position: relative;
          top: 8em;
          line-height: 1.4em;
          font-size: 0.8em;
          text-align: center;
        }

        .footer img {
          /*currently unused since no footer graphic*/
          width: 50%;
          max-width: 38em;
        }
      </style>
    </head>

    <body>
      <div id="main_container">
        Dear parents of <strong>${studentName}</strong>,

        <div>
          <p class="opening">
            Our AI-Enabled Learning Platform, <strong>Ardent SOLVE</strong>, is designed to be a convenient and flexible
            learning option for our enrolled students.
          </p>

          <ul>
            <li>
              Click <a href="http://solve.ardentlabs.io"><strong>HERE</strong></a> to visit Ardent SOLVE*
            </li>
            <li>
              Username: <strong>${username}</strong>
            </li>
            <li>
              Password: <strong>student</strong>
            </li>
          </ul>
        </div>


        <div>
          <p>
            <strong>
              Features and Functions:
            </strong>
          </p>
          <ul>
            <li>
              Access to problem sets including lectures, homework, and weekly quiz both online and offline
            </li>
            <li>
              Submit homework and quiz electronically
            </li>
            <li>
              Interactive virtual office hours
            </li>
            <li>
              Monitor progress
            </li>
            <li>
              Make corrections and review solutions
            </li>
            <li>
              Recordings available for review
            </li>
          </ul>
        </div>

        <div>
          <p>
            <strong>Online Live Class:</strong> Please have student log in to Ardent SOLVE 5 minutes before the live webinar
            schedule or visit <a href="https://zoom.us/join">zoom.us/join</a> with a Zoom meeting ID (to be sent prior to
            the online class).
          </p>
          <p>
            Please call <a href="tel:949-861-2211">(949) 861-2211</a>, Monday-Sunday 10am-6pm or
            email <a href="mailto:care@ardentacademy.com">Care@ArdentAcademy.com</a> for any questions or assistance.
            We are very excited to work with your student on Ardent SOLVE!
          </p>
        </div>
      </div>

      <div class="footer">
        <br />Copyright &copy; 2019 Ardent Academy For Gifted Youth. All Rights Reserved.
      </div>
    </body>

    </html>
  `
}

export default UsernameEmailTemplate
