import React, { Component } from 'react'
import { Query, ApolloConsumer } from 'react-apollo'
import Composer from 'react-composer'
import { Redirect } from 'react-router-dom'
import FamilyTable from './FamilyTable'
import FamilySidebar from './FamilySidebar'

import {
  Button,
  ErrorMessage,
  Flex,
  LoadingMessage,
  Menu,
  MenuItem,
  SettingsIcon,
  Tooltip,
} from '../../components'

import { COUNT_FAMILY_ACCOUNTS } from './queries'

class FamilyTableView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      resolvedStatusCounts: {},
    }
  }

  getStatusCounts = async client => {
    const statusCounts = {}
    await Promise.all(
      this.props.statuses.map(async status => {
        const { data } = await client.query({
          query: COUNT_FAMILY_ACCOUNTS,
          variables: { filter: { status: status } },
        })
        statusCounts[status] = data.countFamilyAccounts
      })
    )
    return statusCounts
  }

  onStatusCountsChange = value => {
    this.setState({
      resolvedStatusCounts: value,
    })
  }

  render() {
    return (
      <ApolloConsumer>
        {client => (
          <InnerFamilyTableView
            {...this.props}
            statusCounts={this.getStatusCounts(client)}
            resolvedStatusCounts={this.state.resolvedStatusCounts}
            onStatusCountsChange={this.onStatusCountsChange}
          />
        )}
      </ApolloConsumer>
    )
  }
}

class InnerFamilyTableView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      redirect: false,
      sidebar: true,
      showFilters: true,
      menuAnchor: null,
      checkedIds: {},
      sorted: [
        {
          id: 'lastName',
          desc: false,
        },
      ],
      filtered: [
        {
          id: 'status',
          value: 'ALL',
        },
        {
          id: 'recent',
          value: false,
        },
      ],
    }
  }

  toggleRecent = () => {
    let newFiltered = this.state.filtered
    let i = newFiltered.findIndex(f => f.id === 'recent')
    newFiltered[i].value = !newFiltered[i].value
    i = newFiltered.findIndex(f => f.id === 'status')
    newFiltered[i].value = newFiltered[i].value === 'ALL' ? 'ACTIVE' : 'ALL'
    this.setState({ filtered: newFiltered })
  }

  toggleSidebar = () => {
    this.setState(state => ({
      sidebar: !state.sidebar,
      menuAnchor: null,
    }))
  }

  toggleFilters = () => {
    this.setState(state => ({
      showFilters: !state.showFilters,
      menuAnchor: null,
    }))
  }

  onFilteredChange = filtered => {
    this.setState({
      filtered,
    })
  }

  menuOpen = event => {
    this.setState({
      menuAnchor: event.currentTarget,
    })
  }

  menuClose = () => {
    this.setState({
      menuAnchor: null,
    })
  }

  rowClick = rowInfo => {
    rowInfo &&
      this.setState({
        redirect: true,
        redirectTo: `/family/${rowInfo.original.id}`,
      })
  }

  setCheckedId = ids => {
    this.setState({
      checkedIds: ids,
    })
  }

  menuButton = () => {
    const { menuAnchor } = this.state
    return (
      <Flex p="5px">
        <Flex p="5px">
          <Tooltip>
            <Button color="default" aria-label="Menu" onClick={this.menuOpen}>
              <SettingsIcon />
            </Button>
          </Tooltip>
        </Flex>
        <Menu
          id="simple-menu"
          anchorEl={menuAnchor}
          open={Boolean(menuAnchor)}
          onClose={this.menuClose}
        >
          <MenuItem onClick={this.toggleFilters}>Toggle Filters</MenuItem>
        </Menu>
      </Flex>
    )
  }

  getStatusFilter = () => {
    const statusFilterValue = this.state.filtered.find(filter => {
      return filter.id === 'status'
    }).value
    return statusFilterValue === 'ALL' || statusFilterValue === 'UNKNOWN'
      ? {}
      : { status: statusFilterValue }
  }

  async componentDidMount() {
    await Promise.resolve(this.props.statusCounts).then(value => {
      this.props.onStatusCountsChange(value)
    })
  }

  render() {
    const {
      checkedIds,
      filtered,
      redirect,
      redirectTo,
      sorted,
      showFilters,
      sidebar,
    } = this.state
    const statusCounts = this.props.resolvedStatusCounts
    if (redirect) {
      return <Redirect push to={redirectTo} />
    }

    return (
      <Composer
        components={[
          <Query
            fetchPolicy="cache-and-network"
            query={COUNT_FAMILY_ACCOUNTS}
            variables={{ filter: this.getStatusFilter() }}
          >
            {() => {}}
          </Query>,
          <Query
            fetchPolicy="cache-and-network"
            query={COUNT_FAMILY_ACCOUNTS}
            variables={{ filter: {} }}
          >
            {() => {}}
          </Query>,
        ]}
      >
        {([
          { loading: countLoading, error: countError, data: countData },
          { loading: totalLoading, error: totalError, data: totalData },
        ]) => {
          if (countLoading || totalLoading) return <LoadingMessage />
          if (countError || totalError)
            return <ErrorMessage error={countError || totalError} />

          return (
            <Flex>
              {sidebar ? (
                <FamilySidebar
                  show={sidebar}
                  menu={this.menuButton}
                  checkedIds={checkedIds}
                  statusCounts={statusCounts}
                  totalFamiliesCount={totalData.countFamilyAccounts}
                  toggleRecent={this.toggleRecent}
                  filtered={filtered}
                />
              ) : null}
              <Flex pl={sidebar ? '0px' : '20px'} align="left" column grow={1}>
                <FamilyTable
                  filterable={showFilters}
                  onRowClick={this.rowClick}
                  statuses={this.props.statuses}
                  sorted={sorted}
                  pages={Math.ceil(countData.countFamilyAccounts / 25)}
                  filtered={filtered}
                  onFilteredChange={this.onFilteredChange}
                  setCheckedId={this.setCheckedId}
                />
              </Flex>
            </Flex>
          )
        }}
      </Composer>
    )
  }
}

export default FamilyTableView
