import React from 'react'

import { Divider, Flex, SidebarText, withStyles } from '../../components'

import { sideBarStyles } from '../../utils/style'

const LessonDetailSidebar = React.memo(({ className, classes }) => {
  return (
    <Flex
      column
      grow={0}
      shrink={0}
      p="10px"
      style={{
        width: '15.38%',
        borderRight: `1px '#aaaaaa' solid`,
        maxWidth: '200px',
        overflowX: undefined,
        flexShrink: 0,
        textAlign: 'left',
        backgroundColor: '#e5e5e5',
      }}
    >
      <Flex column grow={1} justify="space-between">
        <Flex column>
          <SidebarText
            classes={{
              root: classes.headline,
            }}
            title={className}
          />
          <Divider />
        </Flex>
      </Flex>
    </Flex>
  )
})

export default withStyles(sideBarStyles)(LessonDetailSidebar)
