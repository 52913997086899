import React from 'react'

import {
  Button,
  Divider,
  Flex,
  Select,
  MenuItem,
  SidebarText,
  withStyles,
} from '../../components'

import { sideBarStyles } from '../../utils/style'

const PostsViewSidebar = React.memo(
  ({
    handleOpenCreateDialog,
    handleOpenEditDialog,
    classes,
    postTypes,
    typeFilter,
    setTypeFilter,
    setActiveFilter,
    activeFilter,
  }) => {
    return (
      <Flex
        column
        grow={1}
        shrink={0}
        p="10px"
        style={{
          width: '15.38%',
          borderRight: `1px '#aaaaaa' solid`,
          maxWidth: '200px',
          overflowX: undefined,
          flexShrink: 0,
          textAlign: 'left',
          backgroundColor: '#e5e5e5',
          height: '100vh',
        }}
      >
        <Flex column grow={1} justify="space-between">
          <Flex column>
            <SidebarText
              classes={{
                root: classes.headline,
              }}
              title={'Summary'}
            />
            <Divider style={{ marginBottom: '1em' }} />
            <Button onClick={handleOpenCreateDialog}>Create Post</Button>
            <br />
            <Select
              value={typeFilter}
              onChange={e => {
                setTypeFilter(e.target.value)
              }}
            >
              <MenuItem id={'ALL'} key={'ALL'} value={'ALL'}>
                ALL
              </MenuItem>
              {postTypes.map(type => {
                return (
                  <MenuItem id={type} key={type} value={type}>
                    {type}
                  </MenuItem>
                )
              })}
            </Select>
            <br />
            <Button onClick={() => setActiveFilter(!activeFilter)}>
              Show {activeFilter ? 'All' : 'Active Only'}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    )
  }
)

export default withStyles(sideBarStyles)(PostsViewSidebar)
