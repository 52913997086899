import moment from 'moment-timezone'

const customformatDate = (start, end) => {
  return `${moment(start).format('LLLL')} - ${moment(end).format('LT')}`
}

const formatLocation = location => {
  const { name, streetLine1, city, state } = location
  return `Ardent ${name} (${streetLine1}, ${city}, ${state})`
}

export const makeUpEmailTemplate = data => {
  const { studentName, employee, lesson, absentLesson, status } = data
  const isAbsent = status === 'ABSENT'
  const currentLesson = isAbsent ? absentLesson.lesson : lesson
  const isLessonAlreadyTaught = new Date(currentLesson.endOn) < new Date()
  const ArdentBlue = '#4EACE9'
  return `
  <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html>
  <head>
    <meta name="viewport" content="width=device-width,initial-scale=1">
    <meta http-equiv="Content-Type" content="text/html charset=UTF-8" />
    <title>Trial Confirmation</title>
      <style>
        html{font-size:62.5%}
        body
        {
          font-family: "Verdana","Geneva","Helvetica",sans-serif;
        }
        #main_container{
          background:white;
          text-align: left;
          font-size:1.2em;
          padding: 1em;
          width:100%;
          max-width: 60em;
          height:auto;
          
        }
        #makeup{
          background:${ArdentBlue};
          border:2px dotted whitesmoke;
          position:relative;
          top:1.5em;
          padding: 1em 2em 1em 2em;
          border-radius:1.5em;
          text-indent:0;
          text-align: left;
          line-height:1.9em;
          width:45%;
        }
        /*div[id="trial"]:first-line{
          font-weight: bold;
        }*/
        
        
        p{
          position:relative;
          top:1.2em;
          /*text-indent:25px;*/
        }
        p.confirm{
          font-style:italic;
          color:dimgray;
        }
        p.opening{
          color:#009ad6;
        }
        
        .closing{
          position:relative;
          top:2.8em;
          line-height: 1.9em;
        }
        
        .footer{
          position:relative;
          top:8em;
          line-height:1.4em;
          font-size:0.8em;
          text-align:center;
        }
        .footer img{ /*currently unused since no footer graphic*/
          width:50%;
          max-width:38em;
        }
         
      </style>
  </head>
  <body><div id="main_container">Dear parents of ${studentName},
      <p class="confirm">
      ${
        isAbsent
          ? `We are sorry that ${studentName} cannot attend the following class:`
          : isLessonAlreadyTaught
          ? `This is to confirm ${studentName} has attended the following makeup class:`
          : `This is to confirm the following makeup class for ${studentName}:`
      }
    </p>
      <center>
        <div id="makeup">
        <strong>
            ${currentLesson.classroom.title
              .split(':')[1]
              .trim()
              .replace('-', ': ')}
        </strong><br/>
            ${customformatDate(currentLesson.startOn, currentLesson.endOn)}<br/>
            ${!currentLesson.classroom.centerLocation.isVirtual &&
              formatLocation(currentLesson.classroom.centerLocation)}
        </div>
      </center>
      <p>
        ${
          isAbsent
            ? // ? `We will send you the class materials after the class. Please let us know if you have further questions.`
              `You can take the class in self-paced mode using class video and complete quiz and homework before next lesson.`
            : isLessonAlreadyTaught
            ? // ? `We will transfer ${studentName}'s homework to his/her regular class.`
              `Please complete lesson quiz and homework before next lesson.`
            : `Please let us know if you have further questions.  We look forward to working with ${studentName}!`
        }
      </p>
             <p> Ardently Yours,</p><br/><span class="closing">${
               employee.firstName
             } ${employee.lastName}<br/>Ardent Care Team</span>
          </div><div class="footer"><br/>Copyright &copy; 2020 Ardent Academy For Gifted Youth. All Rights Reserved.</div>
  </body>
</html>
    `
}
