import React, { Component, Fragment } from 'react'
import { withApollo } from 'react-apollo'

import UsernameEmailTemplate from '../screens/ClassView/UsernameEmailTemplate'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Flex,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  SimpleTextEditor,
  TextField,
  Text,
} from '.'

import { SEND_EMAIL_TO_FAMILY_MEMBER, SEND_SMS } from '../queries'

class MessageButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      placeholderText: 'Type here...',
      message: '',
      subject: '',
      open: false,
      method: 'email',
    }
  }

  handleChange = event => {
    const { name, value } = event.target
    this.setState({
      [name]: value,
    })
  }

  handleClickOpen = () => {
    this.setState({
      open: true,
    })
  }
  handleClose = () => {
    this.setState({
      open: false,
    })
  }

  handleSend = async (key = '') => {
    const { client, students } = this.props
    const { message, method, subject } = this.state

    const emailReturn = [],
      SMSReturn = []

    let sendEmailPromises = []
    let sendSMSPromises = []

    if (method !== 'text') {
      sendEmailPromises = students.map(async student => {
        const emailSubject =
          key === 'username'
            ? 'Your Login Information for Ardent Solve'
            : subject
        const emailMessage =
          key === 'username'
            ? UsernameEmailTemplate({
                studentName: `${student.firstName} ${student.lastName}`,
                username: student.username,
              })
            : message
        const textMessage =
          key === 'username'
            ? `Welcome to Ardent SOLVE at solve.ardentlabs.io. Your username for SOLVE is ${student.username}. Password is student. Lesson 1 and 2 are available NOW!`
            : message.replace(/<[^>]*>?/gm, '')
        const sendReturn = await client.mutate({
          mutation: SEND_EMAIL_TO_FAMILY_MEMBER,
          variables: {
            familyMemberId: student.familyMemberId,
            subject: emailSubject,
            textMessage: textMessage,
            htmlMessage: emailMessage,
          },
        })

        const data = sendReturn.data.sendEmailToFamilyMember
        emailReturn.push(
          data.message === 'SUCCESS'
            ? ` Sent to ${data.familyMember.email}`
            : ` ${data.familyMember.email} ${data.message}`
        )
      })
    }

    if (method !== 'email') {
      sendSMSPromises = students.map(async student => {
        const textMessage =
          key === 'username'
            ? `Welcome to Ardent SOLVE at solve.ardentlabs.io. Your username for SOLVE is ${student.username} and password is student.`
            : message.replace(/(<([^>]+)>)/g, '')
        const sendReturn = await client.mutate({
          mutation: SEND_SMS,
          variables: {
            phoneNumber: student.phoneNumber,
            message: textMessage,
          },
        })

        const data = sendReturn.data.sendSMS
        SMSReturn.push(
          !data.success
            ? ` ${student.phoneNumber} ${data.message}`
            : ` Sent to ${student.phoneNumber}`
        )
      })
    }

    await Promise.all([...sendEmailPromises, ...sendSMSPromises])

    alert(emailReturn + '\n' + SMSReturn)
    this.handleClose()
  }

  render() {
    const { style, title } = this.props
    const { message, method, open, placeholderText, subject } = this.state

    return (
      <Fragment>
        <Button style={style} onClick={this.handleClickOpen}>
          {title}
        </Button>
        <Dialog
          open={open}
          onClose={this.handleClose}
          fullWidth
          maxWidth={'md'}
        >
          <DialogTitle id="form-dialog-title">{title}</DialogTitle>
          <DialogContent style={{ width: '900px', height: '450px' }}>
            <Flex>
              <FormControl component="fieldset">
                <RadioGroup
                  name="method"
                  value={method}
                  onChange={this.handleChange}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  {[
                    { label: 'email', color: 'primary' },
                    { label: 'text', color: 'secondary' },
                    { label: 'both', color: 'default' },
                  ].map(({ label, color }, i) => (
                    <FormControlLabel
                      key={i}
                      value={label}
                      control={<Radio color={color} />}
                      label={label.charAt(0).toUpperCase() + label.slice(1)}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              <Flex direction={'row-reverse'} grow={1}>
                <Button
                  style={{ align: 'right' }}
                  disabled={!method}
                  onClick={() => {
                    if (
                      window.confirm(
                        'Are you sure you want to send username(s) to the selected student(s)?'
                      )
                    ) {
                      this.handleSend('username')
                    }
                  }}
                >
                  SEND USERNAME
                </Button>
              </Flex>
            </Flex>
            <Text style={{ paddingBottom: '10px', paddingTop: '10px' }}>
              To:{' '}
              {this.props.students
                .map(
                  student => student.firstName + ' ' + student.lastName + ', '
                )
                .sort()}
            </Text>
            <Text style={{ paddingBottom: '10px' }}>
              From: {this.props.instructorName}
            </Text>
            <TextField
              name="subject"
              label="Subject"
              fullWidth
              required
              style={{ paddingBottom: '10px' }}
              value={subject}
              disabled={!method || method === 'text'}
              onChange={this.handleChange}
            />
            <SimpleTextEditor
              name="message"
              readOnly={!method}
              placeholder={placeholderText}
              value={message}
              onChange={this.handleChange}
            />
          </DialogContent>
          <DialogActions
            style={{ paddingBottom: '10px', paddingRight: '10px' }}
          >
            <Button onClick={this.handleClose} color="secondary">
              Cancel
            </Button>

            {method && (
              <Button
                onClick={async () => {
                  if (
                    window.confirm(
                      `Are you sure you want to send this ${method} message?`
                    )
                  ) {
                    this.handleSend()
                  }
                }}
              >
                Send
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </Fragment>
    )
  }
}

export default withApollo(MessageButton)
