import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'

import {
  Button,
  Divider,
  Flex,
  SidebarText,
  withStyles,
  DatePicker,
} from '../../components'
import Cookies from 'js-cookie'
import { idName } from '../../config'
import { sideBarStyles } from '../../utils/style'

class InvoiceSidebar extends Component {
  constructor(props) {
    super(props)
    this.state = { redirect: false, sidebar: { ...this.props.sidebar } }
  }

  componentDidMount() {
    const employeeId = Cookies.get(idName)
    this.setState({
      enableArchive: employeeId === '7460b01e-59bc-4e0b-aac5-44e423aa46d7',
    })
  }

  addInvoice = () => {
    this.setState({
      redirect: true,
      redirectTo: '/invoice/add-new-invoice',
    })
  }

  countInvoices = (targetValue, field) => {
    const { invoices } = this.props
    const invoicesCount = invoices.reduce((accumulator, invoice) => {
      return accumulator + (invoice[field] === targetValue ? 1 : 0)
    }, 0)
    return invoicesCount
  }

  countValues = targetField => {
    const { invoices } = this.props
    let result = {}

    invoices.forEach(element => {
      let elementValue =
        element[targetField] == null ? 'UNKNOWN' : element[targetField]
      result[elementValue] = result[elementValue] ? result[elementValue] + 1 : 1
    })
    return result
  }

  handleClearDateFilter = () => {
    const { queryFilter, onQueryFilterChange } = this.props
    const newFilter = { ...queryFilter }
    if (queryFilter['issueDate']) {
      const { $gteDate, $lteDate, ...rest } = queryFilter['issueDate']
      newFilter['issueDate'] = rest
      onQueryFilterChange(newFilter, this.state.sidebar)
    }
  }

  handleApplyDateFilter = () => {
    const sDate = new Date(this.state.sidebar.startDate)
    const eDate = new Date(this.state.sidebar.endDate)
    const startDate = new Date(
      sDate.getFullYear(),
      sDate.getMonth(),
      sDate.getDate(),
      7,
      0,
      0,
      0
    )
    const endDate = new Date(
      eDate.getFullYear(),
      eDate.getMonth(),
      eDate.getDate() + 1,
      7,
      0,
      0,
      0
    )

    const { queryFilter, onQueryFilterChange } = this.props
    const newFilter = {
      ...queryFilter,
      issueDate: {
        $gteDate: startDate,
        $lteDate: endDate,
      },
    }

    onQueryFilterChange(newFilter, this.state.sidebar)
  }

  handleShowReconciled = () => {
    const { queryFilter, onQueryFilterChange } = this.props
    const newFilter = { ...queryFilter }
    const newSidebar = { ...this.state.sidebar }

    if (Object.keys(queryFilter).includes('reconciled'))
      delete newFilter['reconciled']
    else newFilter['reconciled'] = false

    newSidebar['showReconciled'] = !newSidebar['showReconciled']
    this.setState({ sidebar: { ...newSidebar } })
    onQueryFilterChange(newFilter, newSidebar)
  }

  render() {
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirectTo} />
    }

    const { classes, invoices } = this.props

    const status = this.countValues('status')

    return (
      <Flex
        column
        grow={0}
        shrink={0}
        overflow="hidden"
        p="10px"
        style={{
          width: '15.38%',
          borderRight: `1px #aaaaaa solid`,
          maxWidth: '200px',
          overflowX: undefined,
          flexShrink: 0,
          textAlign: 'left',
          backgroundColor: '#e5e5e5',
        }}
      >
        <Flex column grow={1} justify="space-between">
          <Flex column>
            <Button aria-label="AddInvoice" onClick={this.addInvoice}>
              Add New Invoice
            </Button>
            <SidebarText
              classes={{ root: classes.headline }}
              title="Detail View"
            />
            <SidebarText
              classes={{ root: classes.subheadingOne }}
              title="Filtered total:"
              value={invoices.length}
            />
            <Divider />
            <SidebarText
              classes={{ root: classes.subheadingOne }}
              title="Status"
            />
            {Object.keys(status).map(key => (
              <SidebarText
                key={key}
                classes={{ root: classes.subheadingTwo }}
                title={key}
                value={status[key]}
              />
            ))}
            <Divider />

            <Button
              color="secondary"
              onClick={this.props.archiveCheckedHandler}
              disabled={!this.state.enableArchive}
            >
              Archive
            </Button>
            <br />
            <Button
              aria-label="ShowReconciled"
              onClick={() => this.handleShowReconciled()}
            >
              {this.state.sidebar.showReconciled
                ? 'Show All'
                : 'Show Reconciled'}
            </Button>
            <br />
            <DatePicker
              fullWidth
              name="startDate"
              label="Start Date"
              value={this.state.sidebar.startDate}
              onChange={e =>
                this.setState({
                  sidebar: {
                    ...this.state.sidebar,
                    startDate: e.target.value.toISOString(),
                  },
                })
              }
              required
            />
            <br />
            <DatePicker
              fullWidth
              name="endDate"
              label="End Date"
              value={this.state.sidebar.endDate}
              onChange={e =>
                this.setState({
                  sidebar: {
                    ...this.state.sidebar,
                    endDate: e.target.value.toISOString(),
                  },
                })
              }
              required
            />
            <br />
            <Button
              aria-label="ApplyDateFilter"
              onClick={() => this.handleApplyDateFilter()}
            >
              Apply Date Filter
            </Button>
            <br />
            <Button
              aria-label="ClearDateFilter"
              onClick={() => this.handleClearDateFilter()}
            >
              Clear Date Filter
            </Button>
          </Flex>
        </Flex>
      </Flex>
    )
  }
}

export default withStyles(sideBarStyles)(InvoiceSidebar)
