import React, { Component } from 'react'
import { Mutation, Query } from 'react-apollo'
import Composer from 'react-composer'
import { Link, Redirect } from 'react-router-dom'
import Cookies from 'js-cookie'
import { expansionBarColor, idName } from '../../config'
import { PhoneNumberInput } from '../../utils'
import { isValidPhoneNumber } from '../../utils/phonenumber'

import {
  Button,
  ErrorMessage,
  AccordionForm,
  Flex,
  FormControl,
  FormControlLabel,
  FormLabel,
  GradeSelector,
  Input,
  InputLabel,
  DatePicker,
  LoadingMessage,
  MenuItem,
  OpportunityForm,
  Radio,
  RadioGroup,
  SnackbarNotification,
  Text,
  TextField,
} from '../../components'

import {
  CREATE_STUDENT,
  CREATE_OPPORTUNITY,
  GET_PFM_PHONE_EMAIL_LANG,
  GET_STUDENTS,
  GET_OPPORTUNITIES,
} from '../../queries'
import { userName, randomPassword } from '../../utils/username'

const defaultState = {
  enrollmentStatus: 'PROSPECT',
  tShirtSize: 'N/A', //"Select T-Shirt Size"
  over13: false,
  redirectToStudentTable: false,
  opportunity: {
    status: 'OPEN',
    openDate: new Date(),
    openDays: 0,
    stage: 'ASSESSMENT',
    stageDate: new Date(),
    stageDays: 0,
    year: new Date().getUTCFullYear(),
  },
  snackbar: {
    open: false,
    message: '',
    messageType: '',
    willRedirect: false,
  },
}

class AddStudentView extends Component {
  constructor(props) {
    super(props)
    this.state = defaultState
  }

  returnToStudentTable = () => {
    this.setState({
      redirectToStudentTable: true,
    })
  }

  handleChange = event => {
    const { name, value } = event.target
    const birthdate = new Date(value)
    const ageDifMs = Date.now() - birthdate.getTime()
    const ageDate = new Date(ageDifMs) // miliseconds from epoch
    this.setState(
      name === 'birthdate'
        ? {
            [name]: value,
            over13: Math.abs(ageDate.getUTCFullYear() - 1970) >= 13,
          }
        : {
            [name]: name === 'phoneNumber' ? value.replace(/\D/g, '') : value,
          }
    )
  }

  handleOpportunityChange = event => {
    const { name, value } = event.target
    this.setState(state => ({
      opportunity: {
        ...state.opportunity,
        [name]: value,
      },
    }))
  }

  render() {
    const {
      birthdate,
      email,
      enrollmentStatus,
      firstName,
      gender,
      grade,
      lastName,
      opportunity,
      over13,
      phoneNumber,
      redirectToStudentTable,
      school,
      snackbar,
      tShirtSize,
    } = this.state
    const {
      status,
      primaryInterest,
      session,
      stage,
      year,
      openDate,
      source,
      notes,
      responsibleEmployeeId,
    } = opportunity
    const { match } = this.props
    if (redirectToStudentTable) {
      return <Redirect push to="/student" />
    }

    const styles = theme => ({
      container: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      textField: {
        marginLeft: '5px',
        marginRight: '5px',
        padding: '5px',
        width: '20%',
      },
      menu: {
        width: '20%',
      },
      expSummary: {
        backgroundColor: expansionBarColor,
      },
    })
    const employeeId = Cookies.get(idName)
    return (
      <Query
        fetchPolicy={'cache-and-network'}
        query={GET_PFM_PHONE_EMAIL_LANG}
        variables={{ id: match.params[0] }}
      >
        {({ loading, error, data }) => {
          if (loading) return <LoadingMessage />
          if (error) return <ErrorMessage error={error} />
          return (
            <Composer
              components={[
                <Mutation mutation={CREATE_STUDENT} />,
                <Mutation mutation={CREATE_OPPORTUNITY} />,
              ]}
            >
              {([createStudent, createOpportunity]) => (
                <Flex
                  column
                  grow={1}
                  style={{
                    textAlign: 'left',
                    margin: 'auto',
                    width: '67%',
                    minWidth: '600px',
                    maxWidth: '1000px',
                  }}
                >
                  <br />
                  <Text variant="h4">
                    Add New Student to Existing Family Account
                  </Text>
                  <br />
                  <Flex />

                  <AccordionForm
                    expanded
                    title="Opportunity Information (Optional)"
                    form={
                      <OpportunityForm
                        opportunity={opportunity}
                        handleChange={this.handleOpportunityChange}
                        showSave={false}
                      />
                    }
                  />

                  <AccordionForm
                    expanded
                    title="Student Information"
                    form={
                      <Flex
                        column
                        style={{
                          align: 'center',
                          flexGrow: 1,
                        }}
                      >
                        <form
                          id="addStudentForm"
                          className={styles.container}
                          onSubmit={async e => {
                            e.preventDefault()
                            if (!firstName || !lastName) {
                              this.setState({
                                snackbar: {
                                  open: true,
                                  message:
                                    'At least one required field is missing',
                                  messageType: 'error',
                                  willRedirect: false,
                                },
                              })
                            } else {
                              if (!isValidPhoneNumber(phoneNumber)) {
                                this.setState({
                                  snackbar: {
                                    open: true,
                                    message:
                                      "Student's phone number is an improper length",
                                    messageType: 'error',
                                    willRedirect: false,
                                  },
                                })
                              } else if (
                                birthdate &&
                                (!birthdate._isValid ||
                                  birthdate._pf.parsedDateParts[0] < 1900 ||
                                  birthdate._pf.parsedDateParts[0] >
                                    new Date().getFullYear() + 1)
                              ) {
                                this.setState({
                                  snackbar: {
                                    open: true,
                                    message: 'Please enter a valid birthdate',
                                    messageType: 'error',
                                    willRedirect: false,
                                  },
                                })
                              } else {
                                if (
                                  window.confirm(
                                    'Are you sure you want to create this student?'
                                  )
                                ) {
                                  const input = {
                                    familyAccountId: match.params[0],
                                    enrollmentStatus: enrollmentStatus,
                                    firstName: firstName,
                                    lastName: lastName,
                                    birthdate: new Date(birthdate),
                                    gender: gender,
                                    grade: grade,
                                    school: school,
                                    email: email,
                                    phoneNumber: phoneNumber,
                                    tShirtSize:
                                      tShirtSize !== 'N/A' ? tShirtSize : null,
                                    username: userName(firstName, lastName),
                                    password: randomPassword(),
                                  }
                                  const csReturn = await createStudent({
                                    variables: {
                                      input,
                                    },
                                    refetchQueries: [{ query: GET_STUDENTS }],
                                  })
                                  if (
                                    this.state.opportunity.primaryInterest &&
                                    csReturn.data.createStudent
                                  ) {
                                    await createOpportunity({
                                      variables: {
                                        input: {
                                          status: status,
                                          primaryInterest: primaryInterest,
                                          session: session,
                                          stage: stage,
                                          year: parseInt(year, 10),
                                          openDate: new Date(openDate),
                                          source: source,
                                          notes: notes,
                                          studentId:
                                            csReturn.data.createStudent.id,
                                          responsibleEmployeeId: responsibleEmployeeId,
                                          createdById: employeeId,
                                        },
                                      },
                                      refetchQueries: [
                                        {
                                          query: GET_OPPORTUNITIES,
                                        },
                                      ],
                                    })
                                  }
                                  this.setState({
                                    snackbar: {
                                      open: true,
                                      message: 'Student created',
                                      messageType: 'success',
                                      willRedirect: true,
                                    },
                                  })
                                }
                              }
                            }
                          }}
                        >
                          <Flex justify="space-evenly">
                            <Flex
                              grow={2}
                              m="20px"
                              style={{ maxWidth: '300px' }}
                              column
                            >
                              <TextField
                                required
                                name="firstName"
                                label="First Name"
                                value={firstName || ''}
                                className={styles.textField}
                                fullWidth
                                onChange={this.handleChange}
                              />
                              <br />
                              <TextField
                                required
                                name="lastName"
                                label="Last Name"
                                value={lastName || ''}
                                className={styles.textField}
                                fullWidth
                                onChange={this.handleChange}
                              />
                              <br />
                              <TextField
                                name="username"
                                label="Username"
                                value={
                                  firstName && lastName
                                    ? userName(firstName, lastName)
                                    : ''
                                }
                                className={styles.textField}
                                fullWidth
                                onChange={this.handleChange}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <br />
                              <FormControl
                                component="fieldset"
                                className={styles.formControl}
                              >
                                <FormLabel component="legend">Gender</FormLabel>
                                <RadioGroup
                                  aria-label="gender"
                                  name="gender"
                                  className={styles.group}
                                  value={gender}
                                  onChange={this.handleChange}
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                  }}
                                >
                                  <FormControlLabel
                                    value="MALE"
                                    control={<Radio color="primary" />}
                                    label="Male"
                                  />
                                  <FormControlLabel
                                    value="FEMALE"
                                    control={<Radio color="secondary" />}
                                    label="Female"
                                  />
                                </RadioGroup>
                              </FormControl>
                              <DatePicker
                                name="birthdate"
                                label="Birthdate"
                                value={birthdate || null}
                                onChange={this.handleChange}
                              />
                              <br />
                              <TextField
                                name="preferredLanguage"
                                label="Preferred Language"
                                value={
                                  data.familyAccount.primaryFamilyMember
                                    .preferredLanguage
                                }
                                className={styles.textField}
                                onChange={this.handleChange}
                                disabled
                              />
                            </Flex>
                            <Flex
                              grow={2}
                              m="20px"
                              style={{ maxWidth: '300px' }}
                              column
                            >
                              <GradeSelector
                                className={styles.textField}
                                value={grade}
                                onChange={this.handleChange}
                                SelectProps={{
                                  MenuProps: {
                                    className: styles.menu,
                                  },
                                }}
                              />
                              <br />
                              <TextField
                                // required
                                name="school"
                                label="School"
                                value={school || ''}
                                className={styles.textField}
                                fullWidth
                                onChange={this.handleChange}
                              />
                              <br />
                              <TextField
                                name="tShirtSize"
                                label="T-Shirt Size"
                                className={styles.textField}
                                select
                                value={tShirtSize || ''}
                                onChange={this.handleChange}
                                SelectProps={{
                                  MenuProps: {
                                    className: styles.menu,
                                  },
                                }}
                                InputLabelProps={{
                                  shrink: tShirtSize !== undefined,
                                }}
                              >
                                <MenuItem key={'selectTSSNull'} value={'N/A'}>
                                  N/A
                                </MenuItem>
                                {[
                                  'Youth Small',
                                  'Youth Medium',
                                  'Youth Large',
                                  'Adult Small',
                                  'Adult Medium',
                                  'Adult Large',
                                ].map(option => (
                                  <MenuItem
                                    key={option}
                                    value={option
                                      .toUpperCase()
                                      .replace(/ /g, '_')}
                                  >
                                    {option}
                                  </MenuItem>
                                ))}
                              </TextField>
                              <br />
                              <FormControl className={styles.textField}>
                                <InputLabel shrink>Phone #</InputLabel>
                                <Input
                                  disabled={!over13}
                                  value={phoneNumber}
                                  onChange={this.handleChange}
                                  name="phoneNumber"
                                  inputComponent={PhoneNumberInput}
                                />
                              </FormControl>
                              <br />
                              <TextField
                                name="email"
                                label="Email"
                                value={email}
                                className={styles.textField}
                                fullWidth
                                disabled={!over13}
                                onChange={this.handleChange}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                              <br />
                              <Text variant="caption">
                                Adding a custom phone number or email for
                                Students is optional and only available if the
                                student is 13 or older
                              </Text>
                            </Flex>
                          </Flex>
                          <Flex
                            direction={'row-reverse'}
                            align={'center'}
                            m="10px"
                          >
                            <Button aria-label="Submit" type="submit">
                              Submit
                            </Button>
                            <Button
                              aria-label="Back"
                              component={Link}
                              to={'/student/choose-family-account'}
                              style={{ marginRight: '10px' }}
                            >
                              Back
                            </Button>
                            <Button
                              color="secondary"
                              aria-label="Cancel"
                              component={Link}
                              to={'/student'}
                              style={{ marginRight: '10px' }}
                            >
                              Cancel
                            </Button>
                            <Text
                              style={{
                                marginLeft: '10px',
                                marginRight: '10px',
                              }}
                            >
                              Make sure to verify all information before hitting
                              submit!
                            </Text>
                          </Flex>
                        </form>
                      </Flex>
                    }
                  />
                  <SnackbarNotification
                    open={snackbar.open}
                    handleClose={() => {
                      this.setState({
                        snackbar: {
                          ...snackbar,
                          open: false,
                        },
                      })
                      if (snackbar.willRedirect) this.returnToStudentTable()
                    }}
                    message={snackbar.message}
                    messageType={snackbar.messageType}
                  />
                </Flex>
              )}
            </Composer>
          )
        }}
      </Query>
    )
  }
}

export default AddStudentView
