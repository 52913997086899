import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useLazyQuery } from 'react-apollo'
import { Paper } from '@mui/material'

import {
  arrayToEnrollmentFilter,
  arrayToObjectEnrollmentSort,
} from '../../utils'
import TutorStudentTable from './TutorStudentTable'
import TutorStudentSidebar from './TutorStudentSidebar'
import { convertToYMDHM } from '../../utils/datetime'
import { COUNT_STUDENTS, GET_TUTOR_ENROLLMENTS } from './queries'

const TutorStudentTableView = props => {
  const history = useHistory()

  const [tuteeCount, setTuteeCount] = useState(0)
  const [tutorEnrollments, setTutorEnrollments] = useState([])
  const [filtered, setFiltered] = useState([
    { id: 'completionStatus', value: 'ACTIVE' },
  ])
  const [sorted, setSorted] = useState([
    {
      id: 'lastName',
      desc: false,
    },
  ])
  const [page, setPage] = useState(0)
  const [pages, setPages] = useState(1)
  const [pageSize] = useState(25)

  const [getPages] = useLazyQuery(COUNT_STUDENTS, {
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        ...arrayToEnrollmentFilter(filtered),
      },
    },
    onCompleted: results => {
      const pages = Math.ceil(results.countEnrollments / pageSize)
      setPages(pages)
      setTuteeCount(results.countEnrollments)
    },
  })

  const [getTutees] = useLazyQuery(GET_TUTOR_ENROLLMENTS, {
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        ...arrayToEnrollmentFilter(filtered),
      },
      sort: arrayToObjectEnrollmentSort(sorted),
      page: { skip: page * pageSize, limit: pageSize },
    },
    onCompleted: results => {
      const tutorEnrollments = results.enrollments.map(enrollment =>
        formatEnrollment(enrollment)
      )
      setTutorEnrollments(tutorEnrollments)
    },
  })

  useEffect(() => {
    getPages()
    getTutees()
  }, [getPages, getTutees])

  const handlePageChange = pageIndex => {
    setPage(pageIndex)
    getTutees()
  }

  const handleRowClick = rowInfo => {
    history.push(
      `/tutor_student/tutor-schedule/${rowInfo.original.id} ${rowInfo.original.classroomId} ${rowInfo.original.enrollmentId}`
    )
  }

  const handleFetchData = (_, table) => {
    const { page, filtered, sorted } = table.state
    setPage(page)
    setFiltered(filtered)
    setSorted(sorted)
    getTutees()
  }

  return (
    <Paper sx={{ display: 'flex' }}>
      <TutorStudentSidebar
        enrollments={tutorEnrollments}
        totalStudentsCount={tuteeCount}
        filtered={filtered}
        // onFilteredChange={onFilteredChange}
      />
      <Paper sx={{ width: '100%' }}>
        <TutorStudentTable
          filterable={true}
          onRowClick={handleRowClick}
          students={tutorEnrollments}
          sorted={sorted}
          pages={pages}
          filtered={filtered}
          handleFetchData={handleFetchData}
          handlePageChange={handlePageChange}
        />
      </Paper>
    </Paper>
  )
}

const formatEnrollment = ({
  id,
  student,
  status,
  classroom,
  totalTutorSessionCount,
  scheduledTutorSessionCount,
  nextSessionDateTime,
  completionStatus,
}) => ({
  id: student.id,
  enrollmentId: id,
  classroomId: classroom.id,
  completionStatus: completionStatus,
  classCode: classroom.code,
  sessions: `${scheduledTutorSessionCount} / ${totalTutorSessionCount}`,
  nextSession:
    status === 'RENEW'
      ? 'RENEW INVOICE SENT'
      : nextSessionDateTime
      ? convertToYMDHM(nextSessionDateTime)
      : scheduledTutorSessionCount
      ? 'TO BE SCHEDULED'
      : 'TO BE RENEWED',
  firstName: student.firstName,
  lastName: student.lastName,
  gender: student.gender,
  email: student.familyAccount.primaryFamilyMember.email || '',
  phoneNumber: student.familyAccount.primaryFamilyMember.phoneNumber || '',
  grade: student.grade,
  school: student.school,
  totalTutorSessionCount: totalTutorSessionCount,
  scheduledTutorSessionCount: scheduledTutorSessionCount,
})

export default TutorStudentTableView
