import React from 'react'

import AddIcon from '@mui/icons-material/Add'
import AttachmentIcon from '@mui/icons-material/Attachment'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import CheckIcon from '@mui/icons-material/Check'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ClearIcon from '@mui/icons-material/Clear'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import ErrorIcon from '@mui/icons-material/Error'
import IconButton from '@material-ui/core/IconButton'
import InfoIcon from '@mui/icons-material/Info'
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg'
import SettingsIcon from '@mui/icons-material/SettingsApplications'
import WatchLaterIcon from '@mui/icons-material/WatchLater'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import SpellcheckIcon from '@mui/icons-material/Spellcheck'
import ReplayIcon from '@mui/icons-material/Replay'
import NotificationsIcon from '@mui/icons-material/Notifications'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import LockIcon from '@mui/icons-material/Lock'

const IconMaker = ({ src, width, height, ...rest }) => (
  <img src={src} width={width} height={height} alt={rest.alt} {...rest} />
)

export {
  AddIcon,
  AttachmentIcon,
  AutorenewIcon,
  CheckIcon,
  CheckCircleIcon,
  ClearIcon,
  DeleteIcon,
  EditIcon,
  ErrorIcon,
  IconButton,
  InfoIcon,
  PermPhoneMsgIcon,
  SettingsIcon,
  WatchLaterIcon,
  IconMaker,
  CheckCircleOutlineIcon,
  HighlightOffIcon,
  RadioButtonUncheckedIcon,
  CheckBoxIcon,
  CheckBoxOutlineBlankIcon,
  SpellcheckIcon,
  ReplayIcon,
  NotificationsIcon,
  ArrowForwardIosIcon,
  ArrowDropUpIcon,
  VisibilityOutlinedIcon,
  LockIcon,
}
