import React, { Component } from 'react'
import { Query, withApollo } from 'react-apollo'
import { Redirect } from 'react-router-dom'
import GradeSidebar from './GradeSidebar'
import GradeTable from './GradeTable'
import { GET_CLASSROOMS } from '../../queries'
import { ErrorMessage, Flex, LoadingMessage } from '../../components'
import Cookies from 'js-cookie'
import { idName } from '../../config'

class GradeTableView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filter: { status: 'OPENED' },
      filterByInstructor: true,
    }
  }

  rowClick = rowInfo => {
    rowInfo &&
      this.setState({
        redirect: true,
        redirectTo: `/grade/${rowInfo.original.id}`,
      })
  }

  setFilteredByInstructor = value => {
    this.setState({
      filterByInstructor: value,
    })
  }

  render() {
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirectTo} />
    }
    localStorage.clear()
    const filter = this.state.filter
    return (
      <Query
        query={GET_CLASSROOMS}
        variables={{
          filter,
          sort: { code: 1 },
        }}
      >
        {({ loading, error, data }) => {
          if (loading) return <LoadingMessage />
          if (error) return <ErrorMessage error={error} />
          let classrooms = data.classrooms
          if (this.state.filterByInstructor) {
            classrooms = data.classrooms.filter(
              classroom =>
                !classroom.instructors.every(
                  instructor => instructor.employee.id !== Cookies.get(idName)
                )
            )
          }
          const {
            assignmentId,
            autoGrading,
            classId,
            lessonId,
            problems,
            grades,
            dayOfWeek,
            filterByInstructor,
          } = this.state
          return (
            <Flex>
              <GradeSidebar
                classrooms={classrooms}
                onSideBarChange={this.onSideBarChange}
                classId={classId}
                lessonId={lessonId}
                assignmentId={assignmentId}
                handleSubmission={this.handleSubmission}
                handleReset={this.handleReset}
                disableButtons={!(assignmentId && problems) || autoGrading}
                dayOfWeek={dayOfWeek}
                filterByInstructor={filterByInstructor}
                onFilterByInstructorChanged={this.setFilteredByInstructor}
              />
              <Flex pl={'0px'} align="left" column grow={1}>
                <GradeTable
                  classrooms={classrooms}
                  assignmentId={assignmentId}
                  grades={grades}
                  problems={problems}
                  onChange={this.onChange}
                  onRowClick={this.rowClick}
                  readOnly={autoGrading}
                  dayOfWeek={dayOfWeek}
                />
              </Flex>
            </Flex>
          )
        }}
      </Query>
    )
  }
}

export default withApollo(GradeTableView)
