import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'

import {
  Button,
  Divider,
  Flex,
  SidebarText,
  withStyles,
} from '../../components'

import { interests } from '../../config'
import { sideBarStyles } from '../../utils/style'

class OpportunitySidebar extends Component {
  constructor(props) {
    super(props)
    this.state = { redirect: false }
  }

  addOpportunity = () => {
    this.setState({
      redirect: true,
      redirectTo: '/opportunity/add-new-opportunity',
    })
  }

  countOpportunityInterests = opportunityInterests => {
    const { opportunities } = this.props
    const opportunityCount = opportunities.reduce(
      (accumulator, opportunity) => {
        return (
          accumulator +
          (opportunity.primaryInterest === opportunityInterests ? 1 : 0)
        )
      },
      0
    )
    return opportunityCount
  }

  countOpportunityStage = opportunityStage => {
    const { opportunities } = this.props
    const opportunityCount = opportunities.reduce(
      (accumulator, opportunity) => {
        return accumulator + (opportunity.stage === opportunityStage ? 1 : 0)
      },
      0
    )
    return opportunityCount
  }

  render() {
    const { redirect, redirectTo } = this.state
    if (redirect) {
      return <Redirect push to={redirectTo} />
    }
    const { classes, menu, opportunities, show, stages } = this.props

    return (
      <Flex
        column
        grow={1}
        shrink={0}
        overflow="hidden"
        p="10px"
        style={{
          width: show ? '15.38%' : 0,
          borderRight: `${show ? '1px' : 0} ${'#aaaaaa'} solid`,
          maxWidth: show ? '200px' : 0,
          overflowX: show ? undefined : 'hidden',
          flexShrink: 0,
          textAlign: 'left',
          backgroundColor: '#e5e5e5',
        }}
      >
        <Flex column grow={1} justify="space-between">
          <Flex column>
            <Button
              aria-label="AddOpportunity"
              disabled
              onClick={this.addOpportunity}
            >
              Add New Opportunity
            </Button>
            <SidebarText
              classes={{ root: classes.headline }}
              title="Detail View"
            />
            <Divider />
            <SidebarText
              classes={{ root: classes.subheadingOne }}
              title="Total Opportunities:"
              value={opportunities.length}
            />
            {interests.map(option => (
              <SidebarText
                key={option}
                title={`${option}:`}
                value={this.countOpportunityInterests(option)}
              />
            ))}
            <br />
            <SidebarText
              classes={{ root: classes.subheadingTwo }}
              title="Opportunity Stages:"
            />
            {stages.map(option => (
              <SidebarText
                key={option}
                title={`${option}:`}
                value={this.countOpportunityStage(option)}
              />
            ))}
          </Flex>
          {menu()}
        </Flex>
      </Flex>
    )
  }
}

export default withStyles(sideBarStyles)(OpportunitySidebar)
