import React, { Component } from 'react'
import { withApollo } from 'react-apollo'
import { Link, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import AsyncSelect from 'react-select/async'
import Cookies from 'js-cookie'
import { idName } from '../../config'

import {
  Button,
  Flex,
  Paper,
  SnackbarNotification,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Text,
  withStyles,
} from '../../components'

import { convertPhoneNumber } from '../../utils'
import { GET_FAMILY_ACCOUNTS } from '../StudentView/queries'
import { CREATE_INVOICE } from './queries'

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  input: {
    display: 'flex',
    padding: 0,
  },
  valueContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  noOptionsMessage: {
    fontSize: 16,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
})

class AddInvoiceView extends Component {
  state = {
    currentValue: '',
    selectedAccount: null,
    loading: false,
    snackbar: {
      open: false,
      message: '',
      messageType: '',
      windowReload: false,
    },
  }

  changeSelectedAccount = value => {
    this.setState({
      selectedAccount: value,
    })
  }

  matchesPrimaryFamilyMember = familyAccount => {
    const {
      firstName,
      lastName,
    } = this.state.selectedAccount.primaryFamilyMember
    const {
      firstName: otherFirstName,
      lastName: otherLastName,
    } = familyAccount.primaryFamilyMember

    return firstName === otherFirstName && lastName === otherLastName
  }

  getFamilyAccounts = async () => {
    const { client } = this.props
    const { currentValue } = this.state
    const {
      data: { familyAccounts },
    } = await client.query({
      query: GET_FAMILY_ACCOUNTS,
      variables: {
        filter: {
          primaryFamilyMember: {
            $name: currentValue,
          },
        },
        sort: {
          primaryFamilyMember: {
            firstName: 1,
            lastName: 2,
          },
        },
      },
    })
    this.setState({
      familyAccounts,
    })

    return familyAccounts.map(familyAccount => {
      const { firstName, lastName } = familyAccount.primaryFamilyMember
      return {
        value: familyAccount,
        label: `${firstName} ${lastName}`,
      }
    })
  }

  handleInputChange = value => {
    this.setState({
      currentValue: value,
    })
  }

  createInvoiceHandler = async () => {
    const { client } = this.props
    const { selectedAccount } = this.state
    this.openSnack('Creating...', 'other')
    this.setState({ loading: true })

    const employeeId = Cookies.get(idName)
    const { data } = await client.mutate({
      mutation: CREATE_INVOICE,
      variables: {
        input: {
          familyAccountId: selectedAccount.id,
          price: 0,
          responsibleEmployeeId: employeeId,
        },
      },
    })

    if (data && data.createInvoice.invoiceNumber) {
      this.openSnack('Success!', 'success')
      setTimeout(() => {
        this.props.history.push('/invoice/' + data.createInvoice.invoiceNumber)
      }, 1000)
    } else {
      this.openSnack('Failed!', 'error')
      this.setState({ loading: false })
    }
  }

  openSnack = (message, messageType, windowReload) => {
    const { snackbar } = this.state
    const newSnackbar = {
      ...snackbar,
      open: true,
      messageType,
      message,
      windowReload,
    }
    this.setState({ snackbar: newSnackbar })
  }

  render() {
    const {
      currentValue,
      familyAccounts,
      selectedAccount,
      snackbar,
      loading,
    } = this.state

    return (
      <Flex style={{ height: '80vh' }}>
        <Paper style={{ width: '50%', margin: 'auto', marginTop: '10px' }}>
          <Flex
            column
            style={{
              align: 'center',
              flexGrow: 1,
            }}
            p="20px"
          >
            <Text variant="subtitle1" style={{ textAlign: 'center' }}>
              Choose family account
            </Text>
            <br />
            <AsyncSelect
              autoFocus={!selectedAccount}
              isLoading={!selectedAccount}
              loadOptions={
                currentValue.length >= 2 &&
                (() =>
                  new Promise(resolve => {
                    setTimeout(() => {
                      resolve(this.getFamilyAccounts())
                    }, 1000)
                  }))
              }
              menuIsOpen={currentValue.length >= 2}
              placeholder={'Search for existing primary family member...'}
              onChange={option => this.changeSelectedAccount(option.value)}
              onInputChange={this.handleInputChange}
              inputValue={currentValue}
              value={
                selectedAccount
                  ? {
                      value: selectedAccount,
                      label: `${selectedAccount.primaryFamilyMember.firstName} ${selectedAccount.primaryFamilyMember.lastName}`,
                    }
                  : null
              }
            />
            <br />
            {selectedAccount && (
              <div>
                <Text variant="subtitle1">Search Results</Text>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>First Name</TableCell>
                      <TableCell>Last Name</TableCell>
                      <TableCell>Phone Number</TableCell>
                      <TableCell>Email</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {familyAccounts.map(familyAccount => {
                      if (this.matchesPrimaryFamilyMember(familyAccount)) {
                        const {
                          firstName,
                          lastName,
                          phoneNumber,
                          email,
                        } = familyAccount.primaryFamilyMember
                        return (
                          <TableRow
                            hover
                            onClick={() =>
                              this.changeSelectedAccount(familyAccount)
                            }
                            selected={familyAccount.id === selectedAccount.id}
                            key={familyAccount.id}
                          >
                            <TableCell>{firstName}</TableCell>
                            <TableCell>{lastName}</TableCell>
                            <TableCell>
                              {convertPhoneNumber(phoneNumber)}
                            </TableCell>
                            <TableCell>{email}</TableCell>
                          </TableRow>
                        )
                      }
                      return null
                    })}
                  </TableBody>
                </Table>
                <br />
                <Flex direction={'row-reverse'} align={'center'}>
                  <Button
                    onClick={this.createInvoiceHandler}
                    disabled={loading}
                  >
                    Create
                  </Button>
                  <Button
                    color="secondary"
                    component={Link}
                    to={'/invoice'}
                    disabled={loading}
                    style={{ marginRight: '10px' }}
                  >
                    Cancel
                  </Button>
                </Flex>
              </div>
            )}
            {!selectedAccount && (
              <Flex direction={'row-reverse'} align={'center'}>
                <Button
                  color="secondary"
                  component={Link}
                  to={'/invoice'}
                  style={{ marginRight: '10px' }}
                >
                  Cancel
                </Button>
              </Flex>
            )}
          </Flex>
          <SnackbarNotification
            open={snackbar.open}
            handleClose={() => {
              this.setState({
                snackbar: {
                  ...snackbar,
                  open: false,
                },
              })
              if (snackbar.windowReload) window.location.reload()
            }}
            message={snackbar.message}
            messageType={snackbar.messageType}
          />
        </Paper>
      </Flex>
    )
  }
}

AddInvoiceView.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withApollo(withStyles(styles)(withRouter(AddInvoiceView)))
