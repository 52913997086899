import React, { useState, useEffect, useCallback } from 'react'
import { useQuery } from 'react-apollo'
import { Editor } from 'react-draft-wysiwyg'
import { makeStyles } from '@material-ui/core/styles'
import draftToHtml from 'draftjs-to-html'
import { EditorState, convertToRaw } from 'draft-js'

import {
  Flex,
  Text,
  TextField,
  DatePicker,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from '../../components'
import { GET_EMPLOYEES } from './queries'
import { rawToContentState } from './utils'

const useStyles = makeStyles(() => ({
  dialogPaper: {
    minHeight: '60vh',
  },
  textInput: {
    width: '20em',
    marginBottom: '1em',
    marginRight: '1em',
  },
  selectInput: {
    width: '12em',
    marginRight: '1em',
  },
  datePickerInput: {
    width: '12em',
    marginBottom: '1em',
    marginRight: '1em',
  },
  leftButton: {
    marginLeft: 'auto',
  },
  defaultButton: {
    marginLeft: '1em',
  },
}))

const NewsDialog = React.memo(
  ({
    dialogState,
    classId,
    employeeId,
    recipients,
    handleCloseDialog,
    createNewsMutation,
    updateNewsMutation,
    sendEmail,
    sendSMS,
  }) => {
    const [headline, setHeadline] = useState(dialogState.news.headline)
    const [isSMS, setIsSMS] = useState(dialogState.news.isTextMessage)
    const [isEmail, setIsEmail] = useState(dialogState.news.isEmailMessage)
    const [subject, setSubject] = useState(dialogState.news.subject)
    const [text, setText] = useState(
      dialogState.news.message
        ? EditorState.createWithContent(
            rawToContentState(dialogState.news.message)
          )
        : EditorState.createEmpty()
    )
    const [employee, setEmployee] = useState(
      dialogState.news.announcerId || employeeId
    )
    const [date, setDate] = useState(dialogState.news.publishDate)

    //Maybe lift these queries up...
    const { data } = useQuery(GET_EMPLOYEES)

    const classes = useStyles()

    const handleCreate = _ => {
      createNewsMutation({
        classroomId: classId,
        announcerId: employee,
        subject: subject,
        message: JSON.stringify(convertToRaw(text.getCurrentContent())),
        announceDateTime: date,
        headline: headline,
        likes: 0,
        isTextMessage: isSMS,
        isEmailMessage: isEmail,
      })
      if (isEmail && isEmail !== dialogState.news.isEmailMessage) {
        sendEmail({
          variables: {
            recipients: ['care@ardentacademy.com'],
            subject: `${recipients.code} announcement: ${subject}`,
            text: text.getCurrentContent().getPlainText('\u0001'),
            html: draftToHtml(convertToRaw(text.getCurrentContent())),
            bccAddresses: recipients.emails,
          },
        })
        sendEmail({
          variables: {
            recipients: ['care@ardentacademy.com'],
            subject: `Verify Student Emails for ${recipients.code}`,
            text: `Above message send to class ${recipients.code}: ${recipients.emails}`,
            html: `Message send to class ${recipients.code}: ${recipients.emails}`,
            bccAddresses: [],
          },
        })
      }
      if (isSMS && isSMS !== dialogState.news.isTextMessage) {
        const plainText = text.getCurrentContent().getPlainText('\u0001')
        recipients.phoneNumbers.forEach(number => {
          sendSMS({
            variables: {
              phoneNumber: number,
              message: plainText,
            },
          })
        })
        sendEmail({
          variables: {
            recipients: ['care@ardentacademy.com'],
            subject: `Verify Student Phones for ${recipients.code}`,
            text: `Messages texted to class ${recipients.code}: ${recipients.phoneNumbers}`,
            html: `Messages texted to class ${recipients.code}: ${recipients.phoneNumbers}`,
            bccAddresses: [],
          },
        })
      }
      handleCloseDialog()
    }

    const handleUpdate = _ => {
      if (dialogState.news.id && employee) {
        updateNewsMutation(dialogState.news.id, {
          classroomId: classId,
          announcerId: employee,
          subject: subject,
          message: JSON.stringify(convertToRaw(text.getCurrentContent())),
          headline: headline,
          announceDateTime: date,
          isTextMessage: isSMS,
          isEmailMessage: isEmail,
        })
      }
      if (isEmail && isEmail !== dialogState.news.isEmailMessage) {
        sendEmail({
          variables: {
            recipients: ['care@ardentacademy.com'],
            subject: subject,
            text: text.getCurrentContent().getPlainText('\u0001'),
            html: draftToHtml(convertToRaw(text.getCurrentContent())),
            bccAddresses: recipients.emails,
          },
        })
        sendEmail({
          variables: {
            recipients: ['care@ardentacademy.com'],
            subject: `Verify Student Emails for ${recipients.code}`,
            text: `Above message send to class ${recipients.code}: ${recipients.emails}`,
            html: `Message send to class ${recipients.code}: ${recipients.emails}`,
            bccAddresses: [],
          },
        })
      }
      if (isSMS && isSMS !== dialogState.news.isTextMessage) {
        const plainText = text.getCurrentContent().getPlainText('\u0001')
        recipients.phoneNumbers.forEach(number => {
          sendSMS({
            variables: {
              phoneNumber: number,
              message: plainText,
            },
          })
        })
        sendEmail({
          variables: {
            recipients: ['care@ardentacademy.com'],
            subject: `Verify Student Phones for ${recipients.code}`,
            text: `Messages texted to class ${recipients.code}: ${recipients.phoneNumbers}`,
            html: `Messages texted to class ${recipients.code}: ${recipients.phoneNumbers}`,
            bccAddresses: [],
          },
        })
      }
      handleCloseDialog()
    }

    const handleReset = useCallback(() => {
      setSubject(dialogState.news.subject)
      setText(
        dialogState.news.message
          ? EditorState.createWithContent(
              rawToContentState(dialogState.news.message)
            )
          : EditorState.createEmpty()
      )
      setEmployee(dialogState.news.announcerId || employeeId)
      setDate(dialogState.news.publishDate)
      setHeadline(dialogState.news.headline)
      setIsSMS(dialogState.news.isTextMessage)
      setIsEmail(dialogState.news.isEmailMessage)
    }, [dialogState, employeeId])

    const onEditorChange = value => {
      setText(value)
    }

    const onSubjectChange = e => {
      setSubject(e.target.value)
    }

    const handleHeadline = _ => {
      setHeadline(!headline)
    }

    const handleEmail = _ => {
      setIsEmail(!isEmail)
    }

    const handleSMS = _ => {
      setIsSMS(!isSMS)
    }

    useEffect(() => {
      handleReset()
    }, [handleReset])

    return (
      <Dialog
        open={dialogState.open}
        onClose={handleCloseDialog}
        fullWidth={true}
        maxWidth={'lg'}
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle>
          {dialogState.state === 'update'
            ? 'Update Announcement'
            : 'Create Announcement'}
        </DialogTitle>
        <Divider style={{ width: '100%' }} />
        <DialogContent>
          <Flex column mb={1}>
            <Flex
              mb={1}
              mt={1}
              style={{
                alignItems: 'center',
              }}
            >
              <TextField
                id={'announcement-subject'}
                label="Subject"
                value={subject || ''}
                onChange={onSubjectChange}
                className={classes.textInput}
              />
              {data && (
                <Select
                  id="announcement-instructor"
                  value={employee}
                  onChange={e => setEmployee(e.target.value)}
                  renderValue={id => {
                    const emp = data.employees.find(emp => emp.id === id)
                    return (
                      <Text>
                        {emp.firstName} {emp.lastName}
                      </Text>
                    )
                  }}
                  className={classes.selectInput}
                >
                  {data.employees.map(emp => (
                    <MenuItem key={emp.id} id={emp.id} value={emp.id}>
                      {emp.firstName} {emp.lastName}
                    </MenuItem>
                  ))}
                </Select>
              )}
              <DatePicker
                id={`publish-date`}
                label="Publish Date"
                value={date}
                onChange={e => setDate(e.target.value)}
                className={classes.datePickerInput}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={headline}
                    value={headline}
                    onChange={handleHeadline}
                    color={'primary'}
                  />
                }
                label={'Headline'}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isEmail}
                    value={isEmail}
                    onChange={handleEmail}
                    color={'primary'}
                  />
                }
                label={'Send Email'}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isSMS}
                    value={isSMS}
                    onChange={handleSMS}
                    color={'primary'}
                  />
                }
                label={'Send SMS'}
              />
            </Flex>
            <Flex
              className={'announcement-wrapper'}
              style={{ minHeight: '15.5em' }}
            >
              <Flex className={'announcement-editor'} />
              <Editor
                name="message"
                editorState={text}
                wrapperClassName={'announcement-wrapper'}
                editorClassName={'announcement-editor'}
                onEditorStateChange={onEditorChange}
                toolbarClassName="toolbar-class"
                editorStyle={{
                  border: '1px solid #f1f1f1',
                  overflow: 'wrap',
                  minHeight: '30vh',
                  maxHeight: '30vh',
                  padding: '1%',
                }}
                wrapperStyle={{
                  width: '100%',
                  height: '100%',
                  marginTop: '.5em',
                  marginBottom: '.5em',
                }}
              />
            </Flex>
            <Flex>
              <Button color={'secondary'} onClick={handleCloseDialog}>
                Cancel
              </Button>
              <Button className={classes.leftButton} onClick={handleReset}>
                Reset
              </Button>
              {dialogState.state === 'update' ? (
                <Button
                  className={classes.defaultButton}
                  onClick={handleUpdate}
                >
                  Update
                </Button>
              ) : (
                <Button
                  className={classes.defaultButton}
                  onClick={handleCreate}
                >
                  Create
                </Button>
              )}
            </Flex>
          </Flex>
        </DialogContent>
      </Dialog>
    )
  }
)

export default NewsDialog
