import React from 'react'
import { Query, withApollo, ApolloConsumer } from 'react-apollo'
import Composer from 'react-composer'
import { makeStyles } from '@material-ui/core/styles'

import { ErrorMessage, Flex, LoadingMessage } from '../../components'
import { GET_POST, GET_EMPLOYEES, GET_POST_TYPE_OPTIONS } from './queries'

const useStyles = makeStyles(() => ({
  dialogPaper: {
    minHeight: '60vh',
  },
  chipContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: '5px',
    margin: 0,
    //borderRadius: 60,
    alignItems: 'center',
    justifySelf: 'center',
    width: '100%',
  },
  chip: {
    margin: '5px',
  },
  formPaper: {
    flex: 'display',
    flexDirection: 'column',
    width: '49%',
  },
  textInput: {
    width: '73%',
    marginBottom: '0.5em',
  },
  gradeInput: {
    width: '100%',
  },
  employeeInput: {
    width: '24%',
    marginBottom: '0.5em',
  },
  selectInput: {
    width: '100%',
  },
  datePickerInput: {
    width: '100%',
  },
  leftButton: {
    marginLeft: 'auto',
  },
  defaultButton: {
    marginLeft: '1em',
  },
  AspectRatioContainer: {
    position: 'relative',
    paddingBottom: '56.25%',
    height: 0,
    overflow: 'hidden',
    width: '100%',
  },
  iframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
}))

const PostsDetailView = props => {
  const postId = props.match.params[0]

  /**
   * requerying these here
   * otherwise, it would crash if user went directly to url
   *
   * it should be cached anyway, so its probably fine requerying
   */
  return (
    <ApolloConsumer>
      {client => (
        <Composer
          components={[
            <Query query={GET_POST} variables={{ id: postId }}>
              {function() {
                return ''
              }}
            </Query>,
            <Query query={GET_EMPLOYEES}>
              {function() {
                return ''
              }}
            </Query>,
            <Query query={GET_POST_TYPE_OPTIONS}>
              {function() {
                return ''
              }}
            </Query>,
          ]}
        >
          {([
            { loading: postLoading, error: postError, data: postData },
            {
              loading: employeeLoading,
              error: employeeError,
              data: employeeData,
            },
            {
              loading: postTypesLoading,
              error: postTypesError,
              data: postTypesData,
            },
          ]) => {
            if (postLoading || employeeLoading || postTypesLoading)
              return <LoadingMessage />
            if (postError || employeeError || postTypesError)
              return (
                <ErrorMessage
                  error={postError || employeeError || postTypesError}
                />
              )
            const postTypes = postTypesData.__type.enumValues.map(
              enumValues => enumValues.name
            )
            return (
              <InnerPostsDetailView
                client={client}
                employees={employeeData.employees}
                postTypes={postTypes}
                data={postData.post}
              />
            )
          }}
        </Composer>
      )}
    </ApolloConsumer>
  )
}

const InnerPostsDetailView = props => {
  const { data } = props
  const classes = useStyles()

  return (
    <Flex justify={'center'} contentContainerStyle={{ alignItems: 'center' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          margin: '32px',
          maxWidth: '850px',
        }}
      >
        <div>
          <h2 style={{ wordBreak: 'break-word' }}>{data.title}</h2>
        </div>
        <div>
          <h5 style={{ color: 'grey', wordBreak: 'break-word' }}>
            {data.description}
          </h5>
        </div>
        {data.video ? (
          <div className={classes.AspectRatioContainer}>
            <iframe
              className={classes.iframe}
              title={data.video}
              src={`https://player.vimeo.com/video/${data.video}?title=0&portrait=0&byline=0`}
              width="460"
              height="320"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen="true"
              tabIndex="-1"
            />
          </div>
        ) : (
          <img
            src={data.imageUrl}
            alt={data.title}
            style={{ width: '100%', maxWidth: '850px' }}
          />
        )}
        <td dangerouslySetInnerHTML={{ __html: data.content }} />
      </div>
    </Flex>
  )
}

export default withApollo(PostsDetailView)
