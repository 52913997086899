import React, { Component } from 'react'
import { ApolloConsumer } from 'react-apollo'
import ReactTable from 'react-table'
import 'react-table/react-table.css'

import { missingRowColor, missingFARowColor } from '../../config'

import {
  convertPhoneNumber,
  arrayToObjectFilter,
  arrayToObjectSort,
} from '../../utils'
import { COUNT_STUDENTS, GET_STUDENTS } from './queries'
import { dateFilterMethod, convertToMDY } from '../../utils/datetime'

class StudentTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filterable: true,
      page: 0,
      pages: this.props.pages,
      pageSize: 25,
      students: [],
      sorted: [
        {
          id: 'lastLogin',
          desc: false,
        },
      ],
      filtered: this.props.filtered,
    }
  }

  getStatusFilter = () => {
    const statusFilterValue = this.state.filtered.find(
      filter => filter.id === 'enrollmentStatus'
    ).value
    return statusFilterValue === 'ALL'
      ? {}
      : { enrollmentStatus: statusFilterValue }
  }

  handleFetchData = async (client, state, instance) => {
    const { students } = this.state
    const { page, pageSize, filtered, sorted } = state
    const filteredStudents =
      filtered !== this.state.filtered || sorted !== this.state.sorted
        ? []
        : students
    this.setState({
      loading: true,
      filtered,
      sorted,
      students: filteredStudents,
      page:
        filtered !== this.state.filtered || sorted !== this.state.sorted
          ? 0
          : page,
      pageSize,
    })
    if (filtered !== this.state.filtered || pageSize !== this.state.pageSize) {
      await client
        .query({
          query: COUNT_STUDENTS,
          variables: {
            filter: arrayToObjectFilter(filtered),
          },
        })
        .then(({ data }) => {
          this.setState({
            pages: Math.ceil(data.countStudents / pageSize),
          })
        })
    }
    if (filteredStudents.length <= (page + 1) * pageSize) {
      await client
        .query({
          query: GET_STUDENTS,
          variables: {
            filter: arrayToObjectFilter(filtered),
            sort: arrayToObjectSort(sorted),
            page: {
              skip: filteredStudents.length,
              limit: pageSize,
            },
          },
        })
        .then(({ data }) => {
          const newStudents = data.students.map(student => {
            const primaryFamilyMember =
              student.familyAccount && student.familyAccount.primaryFamilyMember
            return {
              ...student,
              email: (primaryFamilyMember && primaryFamilyMember.email) || '',
              phoneNumber:
                (primaryFamilyMember && primaryFamilyMember.phoneNumber) || '',
            }
          })
          this.setState({
            students: [...filteredStudents, ...newStudents],
          })
        })
    }
    this.setState({ loading: false })
  }

  render() {
    const { checked } = this.props
    const columns = [
      {
        Cell: row => (
          <input type="checkbox" checked={checked[row.original.id]} />
        ),
        sortable: false,
        filterable: false,
        resizable: false,
        width: 28,
      },
      {
        Header: 'Status',
        accessor: 'enrollmentStatus',
        width: 135,
        Cell: props => {
          if (props.value === 'ACTIVE') {
            return <span style={{ color: '#27AE60' }}>{'ACTIVE'}</span>
          }
          if (props.value === 'INACTIVE') {
            return <span style={{ color: '#EB5757' }}>{'INACTIVE'}</span>
          }
          if (props.value === 'GRADUATED') {
            return <span style={{ color: '#00ADEF' }}>{'GRADUATED'}</span>
          }
          return <span>{props.value}</span>
        },
        filterMethod: (filter, row) => {
          if (filter.value !== 'ALL') {
            return row[filter.id] === filter.value
          }
          return true
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: '100%' }}
            value={filter ? filter.value : 'ALL'}
          >
            <option value="ALL">ALL</option>
            {this.props.statuses.map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        ),
      },
      {
        Header: 'Last Login',
        accessor: 'lastLogin',
        filterMethod: dateFilterMethod,
        Cell: props => <span>{props.value && convertToMDY(props.value)}</span>,
      },
      {
        Header: 'New Image',
        accessor: 'newImage',
        show: false,
        filterMethod: (filter, row) => {
          if (filter.value === 'ON') {
            return row[filter.id]
          }
          return true
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            value={filter ? filter.value : 'OFF'}
          >
            <option value="ON">ON</option>
            <option value="OFF">OFF</option>
          </select>
        ),
      },
      {
        Header: 'First Name',
        accessor: 'firstName',
      },
      {
        Header: 'Last Name',
        accessor: 'lastName',
        filterMethod: (filter, row) => {
          return (
            row[filter.id] &&
            row[filter.id].toLowerCase().startsWith(filter.value.toLowerCase())
          )
        },
      },
      {
        Header: 'Gender',
        accessor: 'gender',
        width: 66,
        Cell: props => (
          <span>
            {props.value === 'MALE'
              ? 'M'
              : props.value === 'FEMALE'
              ? 'F'
              : '-'}
          </span>
        ),
        filterMethod: (filter, row) => {
          if (filter.value === 'M') {
            return row[filter.id] === 'MALE'
          }
          if (filter.value === 'F') {
            return row[filter.id] === 'FEMALE'
          }
          return true
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: '100%' }}
            value={filter ? filter.value : 'ALL'}
          >
            <option value="ALL">ALL</option>
            <option value="MALE">M</option>
            <option value="FEMALE">F</option>
          </select>
        ),
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Phone Number',
        accessor: 'phoneNumber',
        Cell: props => (
          <span className="number">{convertPhoneNumber(props.value)}</span>
        ),
        minWidth: 110,
      },
      {
        Header: 'Grade',
        accessor: 'grade',
        width: 64,
        Cell: props => <span>{props.value === 0 ? 'K' : props.value}</span>,
        filterMethod: (filter, row) => {
          if (isNaN(filter.value)) {
            if (filter.value === 'ALL') {
              return true
            }
            if (filter.value === '-') {
              return row[filter.id] === null
            }
          }
          return row[filter.id] === parseInt(filter.value, 10)
        },
        Filter: ({ filter, onChange }) => {
          return (
            <select
              onChange={event => onChange(event.target.value)}
              style={{ width: '100%' }}
              value={filter ? filter.value : 'ALL'}
            >
              <option value="ALL">ALL</option>
              {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(i => (
                <option key={'gradeFilter'.concat(i)} value={i}>
                  {i === 0 ? 'K' : i}
                </option>
              ))}
              <option value="-">-</option>
            </select>
          )
        },
      },
      {
        Header: 'School',
        accessor: 'school',
      },
    ]

    const { handleSingleCheckboxChange, onRowClick } = this.props
    const {
      filtered,
      filterable,
      page,
      pages,
      pageSize,
      sorted,
      students,
    } = this.state

    return (
      <ApolloConsumer>
        {client => (
          <div align="left">
            <ReactTable
              filterable={filterable}
              data={students.slice(page * pageSize, (page + 1) * pageSize)}
              page={page}
              pageSize={pageSize}
              pages={pages}
              loading={this.state.loading}
              manual
              onFetchData={(state, instance) => {
                this.setState({ client })
                this.handleFetchData(client, state, instance)
              }}
              columns={columns}
              className="-highlight"
              defaultSorted={sorted}
              defaultFiltered={filtered}
              style={{ fontSize: '10pt' }}
              defaultFilterMethod={(filter, row) =>
                row[filter.id] &&
                row[filter.id]
                  .toString()
                  .toLowerCase()
                  .includes(filter.value.toString().toLowerCase())
              }
              getTdProps={(state, rowInfo, column, instance) => {
                return {
                  onClick: (e, handleOriginal) => {
                    if (column.Header && column.Header !== 'Email') {
                      onRowClick(rowInfo)
                    }
                    if (column.Header === undefined) {
                      //only the checkbox has an undefined header
                      handleSingleCheckboxChange(rowInfo.original.id)
                    }
                    if (handleOriginal) {
                      handleOriginal()
                    }
                  },
                }
              }}
              getTrProps={(state, rowInfo, column) => {
                let background = '#ffffff'
                if (
                  rowInfo &&
                  !(
                    rowInfo.row &&
                    rowInfo.row.enrollmentStatus &&
                    rowInfo.row.firstName &&
                    rowInfo.row.lastName &&
                    rowInfo.row.gender &&
                    (rowInfo.row.grade || rowInfo.row.grade === 0) &&
                    rowInfo.row.email &&
                    rowInfo.row.school &&
                    rowInfo.row.phoneNumber
                  )
                ) {
                  background = missingRowColor
                }
                if (rowInfo && rowInfo.original.familyAccount === null) {
                  background = missingFARowColor
                }
                return {
                  style: {
                    background,
                  },
                }
              }}
              getTheadProps={(state, rowInfo, column, instance) => {
                return {
                  style: {
                    fontWeight: 'bold',
                    borderBottom: '1px solid #E5E5E5',
                  },
                }
              }}
            />
          </div>
        )}
      </ApolloConsumer>
    )
  }
}

export default StudentTable
