import React, { Component, Fragment } from 'react'
import { Query } from 'react-apollo'
import { Redirect } from 'react-router-dom'

import ClassSelector from './ClassSelector'
import LessonSelector from './LessonSelector'
import CustomSelector from './CustomSelector'

import {
  DatePicker,
  ErrorMessage,
  Flex,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  TextField,
  withStyles,
  LoadingMessage,
  Radio,
  RadioGroup,
} from '../../components'
import { GET_OFFICE_EMPLOYEES } from '../../queries'

const styles = theme => ({
  menu: {
    width: '20%',
  },
  asterisk: {
    color: 'red',
  },
})

class TrialForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      opportunityId: props.opportunityId,
      trialId: props.trialId,
      classId: props.classId,
      lessonId: props.lessonId,
      session: 'CURRENT',
      status: props.status,
      notes: props.notes,
      toCreateTrial: props.toCreateTrial,
    }
  }

  returnToTrialTable = () => {
    this.setState({
      redirect: true,
      redirectTo: '/trial',
    })
  }

  handleChange = event => {
    const { name, value } = event.target
    this.setState({
      [name]: value,
    })
  }

  render() {
    const { redirect, redirectTo, session } = this.state
    const {
      classes,
      classId,
      fromTrialDetailView,
      handleChange,
      lessonId,
      notes,
      readOnly,
      status,
      statusOptions,
      toCreateTrial,
      trial,
      studentId,
    } = this.props
    const {
      followupStatus,
      followupDate,
      followupEmployeeId,
      followupResult,
    } = trial
    const toClassId = trial.toClassId
    const toLesson = trial.toLesson
    if (redirect) {
      return <Redirect push to={redirectTo} />
    }
    if (toCreateTrial) {
      return (
        <Flex column style={{ align: 'center', flexGrow: 1 }}>
          <Flex justify="space-evenly" className={classes.root}>
            <Flex grow={2} m="20px" style={{ maxWidth: '400px' }} column>
              <FormControl component="fieldset">
                <FormLabel component="legend">Session</FormLabel>
                <RadioGroup
                  aria-label="session"
                  name="session"
                  value={session}
                  onChange={this.handleChange}
                  style={{ display: 'flex', flexDirection: 'row' }}
                >
                  {['CURRENT', 'NEXT'].map(session => (
                    <FormControlLabel
                      key={session}
                      value={session}
                      control={<Radio color="primary" />}
                      label={session}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              <ClassSelector
                trial={trial}
                handleChange={handleChange}
                toCreateTrial={toCreateTrial}
                session={session}
                styles={classes}
                toClassId={toClassId}
                toLesson={toLesson}
                studentId={studentId}
                {...(classId ? { classId } : {})}
              />
              <br />
              <LessonSelector
                trial={trial}
                toClassId={toClassId}
                handleChange={handleChange}
                readOnly={readOnly}
                styles={styles}
                {...(classId ? { classId } : {})}
                selectedLessonId={lessonId}
              />
            </Flex>
            <Flex
              grow={2}
              m="20px"
              style={{ paddingTop: '58px', maxWidth: '400px' }}
              column
            >
              <CustomSelector
                trial={trial}
                label="Status"
                statusOptions={statusOptions}
                readOnly={readOnly}
                toCreateTrial={toCreateTrial}
                styles={classes}
                handleChange={handleChange}
                {...(status ? { selectedOptionId: status } : {})}
              />
              <br />
              <TextField
                name="notes"
                label="Notes"
                multiline
                maxRows="4"
                value={notes}
                onChange={handleChange}
              />
              <br />
            </Flex>
          </Flex>
        </Flex>
      )
    } else {
      return (
        <Flex column style={{ align: 'center', flexGrow: 1 }}>
          <Flex justify="space-evenly" className={classes.root}>
            <Flex grow={2} m="20px" style={{ maxWidth: '400px' }} column>
              <ClassSelector
                trial={trial}
                handleChange={handleChange}
                toCreateTrial={toCreateTrial}
                styles={classes}
                fromTrialDetailView
                toClassId={toClassId}
                toLesson={toLesson}
                studentId={studentId}
                {...(classId ? { classId } : {})}
              />
              <br />
              <LessonSelector
                trial={trial}
                toClassId={toClassId}
                handleChange={handleChange}
                readOnly={readOnly}
                styles={styles}
                fromTrialDetailView={fromTrialDetailView}
                {...(classId ? { classId } : {})}
                selectedLessonId={lessonId}
              />
              <br />
              <CustomSelector
                trial={trial}
                label="Status"
                statusOptions={statusOptions}
                readOnly={readOnly}
                toCreateTrial={toCreateTrial}
                styles={classes}
                handleChange={handleChange}
                {...(status ? { selectedOptionId: status } : {})}
              />
              <br />
              <TextField
                name="notes"
                label="Notes"
                multiline
                maxRows="4"
                value={notes}
                onChange={handleChange}
              />
              <br />
            </Flex>
            <Flex grow={2} m="20px" style={{ maxWidth: '400px' }} column>
              <FormLabel component="legend">Followup Status</FormLabel>
              <RadioGroup
                name="followupStatus"
                aria-label="followupStatus"
                label="Followup Status"
                value={followupStatus}
                onChange={handleChange}
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <FormControlLabel
                  value="Yes"
                  control={<Radio color="primary" />}
                  label="Yes"
                />
                <FormControlLabel
                  value="No"
                  control={<Radio color="secondary" />}
                  label="No"
                />
              </RadioGroup>
              <br />
              <DatePicker
                name="followupDate"
                label="Followup Date"
                value={followupDate}
                onChange={handleChange}
              />
              <br />
              <Query query={GET_OFFICE_EMPLOYEES}>
                {({ loading, error, data }) => {
                  if (loading) return <LoadingMessage />
                  if (error) return <ErrorMessage error={error} />
                  return (
                    <Fragment>
                      <TextField
                        name="followupEmployeeId"
                        label="Followup Employee"
                        select
                        fullWidth
                        value={followupEmployeeId}
                        onChange={handleChange}
                      >
                        {data.employees.map(employee => (
                          <MenuItem
                            key={employee.id}
                            value={employee.id}
                          >{`${employee.firstName} ${employee.lastName}`}</MenuItem>
                        ))}
                      </TextField>
                      <br />
                    </Fragment>
                  )
                }}
              </Query>
              <TextField
                name="followupResult"
                label="Followup Result"
                multiline
                maxRows="4"
                value={followupResult}
                onChange={handleChange}
              />
            </Flex>
          </Flex>
        </Flex>
      )
    }
  }
}

export default withStyles(styles)(TrialForm)
