import React, { Component } from 'react'
import { Query } from 'react-apollo'

import AddTaskPanels from './AddTaskPanels'
import { ErrorMessage, LoadingMessage, withStyles } from '../../components'
import { GET_OPPORTUNITY } from '../OpportunityView/queries'
import { GET_STUDENT_AND_FAMILY_NAMES } from '../StudentView/queries'

const styles = theme => ({
  root: {
    textAlign: 'left',
    margin: 'auto',
    width: '67%',
    minWidth: '600px',
    maxWidth: '1000px',
  },
  display1: {
    textAlign: 'left',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  expansionDetail: {
    overflow: 'visible',
  },
})

class AddTaskView extends Component {
  render() {
    if (this.props.opportunity) {
      const opportunityId = this.props.match.params[0]
      return (
        <Query
          fetchPolicy={'cache-and-network'}
          query={GET_OPPORTUNITY}
          variables={{ id: opportunityId }}
        >
          {({ loading, error, data }) => {
            if (loading) return <LoadingMessage />
            if (error) return <ErrorMessage error={error} />
            const { opportunity } = data
            return <AddTaskPanels {...this.props} opportunity={opportunity} />
          }}
        </Query>
      )
    }
    if (this.props.student) {
      const studentId = this.props.match.params[0]
      return (
        <Query
          fetchPolicy={'cache-and-network'}
          query={GET_STUDENT_AND_FAMILY_NAMES}
          variables={{ id: studentId }}
        >
          {({ loading, error, data }) => {
            if (loading) return <LoadingMessage />
            if (error) return <ErrorMessage error={error} />
            const { student } = data
            return <AddTaskPanels {...this.props} student={student} />
          }}
        </Query>
      )
    }
    return <AddTaskPanels {...this.props} />
  }
}

export default withStyles(styles)(AddTaskView)
