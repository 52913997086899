import React, { Component } from 'react'
import { withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import PropTypes from 'prop-types'
import AsyncSelect from 'react-select/async'

import {
  Flex,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Text,
} from '../components'

import withStyles from './Styles'

class ChooseStudentPanel extends Component {
  state = {
    currentValue: '',
  }
  matchesStudent = student => {
    const { firstName, lastName } = this.props.selectedStudent
    const { firstName: otherFirstName, lastName: otherLastName } = student

    return firstName === otherFirstName && lastName === otherLastName
  }

  getStudents = async () => {
    const { client, filter, ignoreList } = this.props
    const { currentValue } = this.state
    const {
      data: { students },
    } = await client.query({
      query: GET_STUDENTS,
      variables: {
        filter: {
          enrollmentStatus: 'ACTIVE',
          $name: currentValue,
          ...filter,
        },
        sort: {
          firstName: 1,
          lastName: 2,
        },
        page: {
          limit: 8,
        },
      },
    })

    this.setState({
      students,
    })

    return (ignoreList
      ? students.filter(students => !ignoreList.includes(students.id))
      : students
    ).map(student => {
      const { firstName, lastName } = student
      return {
        value: student,
        label: `${firstName} ${lastName}`,
      }
    })
  }

  handleInputChange = value => {
    this.setState({
      currentValue: value,
    })
  }

  render() {
    const { display, onChange, selectedStudent } = this.props

    const { currentValue, students } = this.state

    return display ? (
      <Flex
        column
        style={{
          align: 'center',
          flexGrow: 1,
        }}
      >
        <Text variant="subtitle1" style={{ textAlign: 'center' }}>
          Select a student
        </Text>
        <br />
        <AsyncSelect
          autoFocus={!selectedStudent}
          isLoading={!selectedStudent}
          loadOptions={currentValue.length >= 2 && this.getStudents}
          menuIsOpen={currentValue.length >= 2}
          placeholder={'Search...'}
          onChange={option =>
            onChange({
              target: { name: 'selectedStudent', value: option.value },
            })
          }
          onInputChange={this.handleInputChange}
          inputValue={currentValue}
          value={
            selectedStudent
              ? {
                  value: selectedStudent,
                  label: `${selectedStudent.firstName} ${selectedStudent.lastName}`,
                }
              : null
          }
        />
        <br />
        {selectedStudent && (
          <div>
            <Text variant="subtitle1">Search Results</Text>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>First Name</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>Email</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {students.map(student => {
                  if (this.matchesStudent(student)) {
                    const {
                      id,
                      firstName,
                      lastName,
                      phoneNumber,
                      email,
                    } = student
                    return (
                      <TableRow
                        hover
                        onClick={() =>
                          onChange({
                            target: {
                              name: 'selectedStudent',
                              value: student,
                            },
                          })
                        }
                        selected={id === selectedStudent.id}
                        key={id}
                      >
                        <TableCell>{firstName}</TableCell>
                        <TableCell>{lastName}</TableCell>
                        <TableCell>{phoneNumber}</TableCell>
                        <TableCell>{email}</TableCell>
                      </TableRow>
                    )
                  }
                  return null
                })}
              </TableBody>
            </Table>
          </div>
        )}
      </Flex>
    ) : (
      <div />
    )
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  input: {
    display: 'flex',
    padding: 0,
  },
  valueContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  noOptionsMessage: {
    fontSize: 16,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
})

const GET_STUDENTS = gql`
  query getStudents($filter: Filters, $sort: Sorts, $page: Pagination) {
    students(filter: $filter, sort: $sort, page: $page) {
      id
      firstName
      lastName
      phoneNumber
      email
      grade
    }
  }
`

ChooseStudentPanel.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withApollo(withStyles(styles)(ChooseStudentPanel))
