import gql from 'graphql-tag'

export const GET_QUALIFIES = gql`
  query getQualifies($filter: Filters, $sort: Sorts, $page: Pagination) {
    qualifies(filter: $filter, sort: $sort, page: $page) {
      id
      guardian {
        id
        firstName
        lastName
        phoneNumber
      }
      createdBy {
        id
        firstName
        lastName
      }
      student {
        id
        firstName
        lastName
        grade
        school
        email
      }
      centerLocation {
        id
        name
      }
      status
      method
      enrollment {
        id
        classroom {
          id
          code
        }
      }
      attendee {
        id
        overallGrade
      }
      resolvedDate
      results
    }
  }
`

export const GET_QUALIFY = gql`
  query getQualify($id: ID!) {
    qualify(id: $id) {
      id
      opportunity {
        id
        status
        openDate
        closeDate
        primaryInterest
        lastActivity {
          id
          method
        }
        lastContacted
        session
        year
        source
        notes
        stage
        student {
          id
          familyAccount {
            id
            primaryFamilyMember {
              id
              preferredLanguage
            }
          }
          firstName
          middleName
          lastName
          nickname
          gender
          birthdate
          grade
          school
          phoneNumber
          email
        }
        responsibleEmployee {
          id
          firstName
          lastName
        }
        createdBy {
          id
          firstName
          lastName
        }
      }
      guardian {
        id
        firstName
        lastName
        phoneNumber
      }
      createdBy {
        id
        firstName
        lastName
      }
      student {
        id
        firstName
        lastName
        grade
        school
        email
      }
      centerLocation {
        id
        name
      }
      status
      method
      enrollment {
        id
        classroom {
          id
          title
          code
        }
      }
      attendee {
        id
        overallGrade
        percentCompleted
        lesson {
          id
          count
          sum
        }
      }
      employee {
        id
      }
      resolvedDate
      results
    }
  }
`

export const GET_OFFICE_EMPLOYEES = gql`
  query getOfficeEmployees {
    employees(filter: { isOfficeUser: true }) {
      id
      firstName
      lastName
      status
    }
  }
`

export const GET_CENTER_LOCATIONS = gql`
  query getCenterLocations($filter: Filters) {
    centerLocations(filter: $filter) {
      id
      name
    }
  }
`

export const SIMPLE_GET_CLASSROOMS = gql`
  query getClasses($filter: Filters, $sort: Sorts) {
    classrooms(filter: $filter, sort: $sort) {
      id
      status
      title
      startDate
      endDate
      quarter
      course {
        id
        code
      }
    }
  }
`

export const CREATE_ENROLLMENT = gql`
  mutation enrollStudent($input: CreateEnrollmentInput!) {
    createEnrollment(input: $input) {
      id
      classroom {
        id
        lessons {
          id
          lessonPlan {
            id
            order
          }
        }
      }
    }
  }
`

export const CREATE_QUALIFY = gql`
  mutation createQualifying($input: CreateQualifyInput!) {
    createQualify(input: $input) {
      id
    }
  }
`

export const CREATE_ATTENDEE = gql`
  mutation makeAttendee($input: CreateAttendeeInput!) {
    createAttendee(input: $input) {
      id
    }
  }
`

export const GET_CLASSROOMS = gql`
  query getClassroooms($filter: Filters) {
    classrooms(filter: $filter) {
      id
      title
      code
      year
      quarter
    }
  }
`

export const UPDATE_QUALIFY = gql`
  mutation updateAssessment($id: ID!, $input: UpdateQualifyInput!) {
    updateQualify(id: $id, input: $input) {
      id
    }
  }
`

export const ARCHIVE_ATTENDEE = gql`
  mutation archiveAttendeeRecord($id: ID!) {
    archiveAttendee(id: $id) {
      id
    }
  }
`

export const ARCHIVE_ENROLLMENT = gql`
  mutation archiveEnrollmentRecord($id: ID!) {
    archiveEnrollment(id: $id) {
      id
    }
  }
`

export const ARCHIVE_QUALIFY = gql`
  mutation archiveQualifyRecord($id: ID!) {
    archiveQualify(id: $id) {
      id
    }
  }
`
