import React, { useState, useCallback } from 'react'
import { useQuery } from 'react-apollo'
import {
  Grid,
  Flex,
  LoadingMessage,
  ErrorMessage,
  Tabs,
  Tab,
  Text,
} from '../../components'
import LessonDetailSidebar from './LessonDetailSidebar'
import LessonFormContainer from './LessonEditForm'
import { GET_LESSONS } from './queries'

const LessonDetailView = React.memo(props => {
  const classId = props.match.params[0]

  const processLessons = useCallback(lessons => {
    return lessons
      .sort((a, b) => a.lessonPlan.order - b.lessonPlan.order)
      .map(lesson => ({
        id: lesson.id,
        title: lesson.lessonPlan.title,
        order: lesson.lessonPlan.order,
      }))
  }, [])

  const { loading, error, data } = useQuery(GET_LESSONS, {
    variables: { id: classId },
  })
  if (loading) return <LoadingMessage />
  if (error) return <ErrorMessage error={error} />
  const lessonData = processLessons(data.classroom.lessons)

  return (
    <LessonDetailContainer
      lessonData={lessonData}
      classId={classId}
      className={data.classroom.code}
    />
  )
})

const LessonDetailContainer = React.memo(
  ({ lessonData, classId, className }) => {
    const [lesson, setLesson] = useState(0)

    const handleLessonChange = useCallback((_e, v) => {
      setLesson(v)
    }, [])

    return (
      <Flex style={{ height: '100vh' }}>
        <LessonDetailSidebar className={className} />
        {lessonData ? (
          <Flex column>
            <Flex mt={'1.5em'} ml={'2.9em'}>
              <Text variant="h5" color="primary">
                {lessonData[lesson].order}. {lessonData[lesson].title}
              </Text>
            </Flex>
            <Grid
              container
              direction="row"
              overflow="scroll"
              style={{
                marginTop: '1.5em',
              }}
            >
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={lesson}
                onChange={handleLessonChange}
                indicatorColor="primary"
                textColor="primary"
                aria-label={'lesson-tabs'}
                style={{
                  borderRight: '1px solid',
                  marginRight: '2em',
                  height: '60vh',
                }}
              >
                {lessonData.map((lesson, index) => (
                  <Tab
                    key={`lesson-tab-${index}`}
                    id={`lesson-tab-${index}`}
                    label={`Lesson ${lesson.order}`}
                    value={index}
                    aria-controls={`vertical-tabpanel-${index}`}
                  />
                ))}
              </Tabs>
              <LessonFormContainer
                lessonId={lessonData[lesson].id}
                classId={classId}
              />
            </Grid>
          </Flex>
        ) : (
          <Flex mt={'1.5em'} ml={'2.9em'}>
            <Text variant="h5" color="secondary">
              This class does not have any lessons
            </Text>
          </Flex>
        )}
      </Flex>
    )
  }
)

export default LessonDetailView
