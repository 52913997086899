import React, { Component } from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ErrorMessage,
  LoadingMessage,
  TextField,
  MenuItem,
} from '../../components'
import { FormControl, Input, InputAdornment, InputLabel } from '@mui/material'

import { Query } from 'react-apollo'
import { GET_FAMILY_STUDENTS, GET_OFFERS } from './queries'
import OfferTable from './OfferTable'

class AddOfferForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errorMsg: '',
      chosenOffer: { id: '', unitPrice: 0, offerType: '' },
      chosenStudentId: null,
      chosenStudentName: '',
      quantity: 1,
      price: 0,
      editing: false,
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedItem !== this.props.selectedItem) {
      if (this.props.selectedItem === null) {
        this.setState({
          errorMsg: '',
          chosenOffer: { id: '', unitPrice: 0, offerType: '' },
          quantity: 0,
          price: 0,
          editing: false,
          chosenStudentId: null,
          chosenStudentName: '',
        })
      } else if (this.props.selectedItem.itemType === 'OFFER') {
        let item = this.props.selectedItem
        this.setState({
          errorMsg: '',
          chosenOffer: {
            id: item.offer.id,
            unitPrice: item.unitPrice,
            offerType: '',
          },
          quantity: item.quantity,
          price: item.price,
          editing: true,
          chosenStudentId: item.student ? item.student.id : null,
          chosenStudentName: item.student
            ? `${item.student.firstName} ${item.student.lastName}`
            : null,
        })
      }
    }
  }

  setChosenOffer = (id, unitPrice, offerType) => {
    this.setState({
      chosenOffer: { id, unitPrice, offerType },
      price: unitPrice * this.state.quantity,
      errorMsg: '',
    })
  }

  setQuantity = newQ => {
    this.setState({
      quantity: newQ,
      price: this.state.chosenOffer.unitPrice * newQ,
      errorMsg: '',
    })
  }

  handleSubmit = () => {
    const {
      chosenOffer,
      quantity,
      price,
      editing,
      chosenStudentId,
    } = this.state

    if (!(chosenOffer.id && chosenOffer.unitPrice && quantity && price)) {
      this.setState({ errorMsg: 'You must enter all fields' })
      return
    }

    if (
      ['CONTEST', 'TUTORING', 'COUNSELING'].includes(chosenOffer.offerType) &&
      chosenStudentId === null
    ) {
      this.setState({ errorMsg: 'You must enter all fields' })
      return
    }

    const input = {
      invoiceId: this.props.invoice.id,
      offerId: chosenOffer.id,
      isClassroom: false,
      itemType: 'OFFER',
      unitPrice: chosenOffer.unitPrice,
      quantity: parseFloat(quantity),
      price,
      studentId: chosenStudentId,
    }

    if (editing) {
      this.props.updateHandler(
        this.props.client,
        this.props.selectedItem.id,
        input
      )
    } else {
      this.props.addHandler(this.props.client, [input])
    }

    this.props.close()
  }

  render() {
    const {
      chosenOffer,
      quantity,
      price,
      errorMsg,
      editing,
      chosenStudentName,
    } = this.state
    return (
      <Dialog open={this.props.open} fullWidth={true} maxWidth={'lg'}>
        <DialogTitle>{'Add Offer'}</DialogTitle>
        <DialogContent>
          <div style={{ color: 'red' }}>{errorMsg}</div>
          <Query query={GET_OFFERS}>
            {({ loading, error, data }) => {
              if (loading) return <LoadingMessage />
              if (error) return <ErrorMessage error={error} />
              return (
                <OfferTable
                  offers={data.offers}
                  chosenOffer={chosenOffer}
                  changeOffer={this.setChosenOffer}
                />
              )
            }}
          </Query>
          <br />
        </DialogContent>
        <DialogActions style={{ paddingBottom: '10px', paddingRight: '10px' }}>
          {['CONTEST', 'TUTORING', 'COUNSELING'].includes(
            chosenOffer.offerType
          ) && (
            <Query
              fetchPolicy={'cache-and-network'}
              query={GET_FAMILY_STUDENTS}
              variables={{ id: this.props.invoice.familyAccountId }}
            >
              {({ loading, error, data }) => {
                if (loading) {
                  return <TextField label="Loading.." fullWidth disabled />
                }
                if (error || data.familyAccount.students.length === 0) {
                  return (
                    <TextField
                      label="No students to show.."
                      disabled
                      fullWidth
                    />
                  )
                }
                return (
                  <TextField
                    select
                    required
                    label="Choose student"
                    fullWidth
                    defaultValue=""
                    value={chosenStudentName || ''}
                    onChange={event => {
                      this.setState({
                        chosenStudentName: event.target.value,
                      })
                    }}
                  >
                    {data.familyAccount.students.map(stu => (
                      <MenuItem
                        key={stu.id}
                        value={`${stu.firstName} ${stu.lastName}`}
                        onClick={() =>
                          this.setState({ chosenStudentId: stu.id })
                        }
                      >
                        {`${stu.firstName} ${stu.lastName}`}
                      </MenuItem>
                    ))}
                  </TextField>
                )
              }}
            </Query>
          )}
          <TextField
            type="number"
            label="Quantity"
            value={quantity}
            inputProps={{ step: 0.1 }}
            onChange={e => this.setQuantity(e.target.value)}
          />
          <FormControl
            fullWidth
            variant="standard"
            // style={{ marginLeft: '0.7em', flex: 1 }}
          >
            <InputLabel htmlFor="standard-adornment-amount">Price</InputLabel>
            <Input
              id="standard-adornment-amount"
              value={price}
              onChange={event => {
                const price = event.target.value
                  ? parseFloat(event.target.value)
                  : 0.0
                this.setState({
                  price: price,
                })
              }}
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
            />
          </FormControl>
          <Button color="primary" onClick={this.handleSubmit}>
            {editing ? 'Update' : 'Create'}
          </Button>
          <Button onClick={this.props.close} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default AddOfferForm
