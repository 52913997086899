import React, { Fragment } from 'react'
import { Query } from 'react-apollo'

import {
  Checkbox,
  DatePicker,
  DateTimePicker,
  ErrorMessage,
  Flex,
  FormControlLabel,
  LoadingMessage,
  MenuItem,
  TextField,
} from '../../components'

import withStyles from '../../components/Styles'
import {
  GET_ACTIVITY_CONTACT_METHOD_OPTIONS_QUERY,
  GET_ACTIVITY_PRIORITY_OPTIONS_QUERY,
  GET_ACTIVITY_TYPES,
  GET_OFFICE_EMPLOYEES,
} from './queries'
import { GET_CENTER_LOCATIONS } from '../../queries'

const TaskForm = props => {
  const styles = props.classes
  const { followup, handleChange, logged, readOnly, task, toggleLogged } = props
  const {
    centerLocation,
    description,
    resolution,
    endDate,
    method,
    priority,
    resolvedDate,
    responsibleEmployeeId,
    type,
  } = task

  return (
    <Flex
      column
      style={{
        align: 'center',
        flexGrow: 1,
      }}
    >
      <form id="taskForm">
        <Flex justify="space-evenly">
          <Flex grow={2} m="20px" style={{ maxWidth: '300px' }} column>
            <Query query={GET_ACTIVITY_PRIORITY_OPTIONS_QUERY}>
              {({ loading, error, data }) => {
                if (loading) return <LoadingMessage />
                if (error) return <ErrorMessage error={error} />
                return (
                  <TextField
                    required={!followup}
                    name="priority"
                    label="Priority"
                    className={styles.textField}
                    select
                    value={priority}
                    onChange={props.handleChange}
                    SelectProps={{
                      MenuProps: {
                        className: styles.menu,
                      },
                    }}
                    InputLabelProps={{
                      shrink: !!priority && priority !== undefined,
                      formlabelclasses: {
                        asterisk: styles.asterisk,
                      },
                    }}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                  >
                    {data.__type.enumValues.map(({ name: option }) => (
                      <MenuItem
                        key={option
                          .replace(/(\B)[^ ]*/g, match => match.toLowerCase())
                          .replace(/^[^ ]/g, match => match.toUpperCase())}
                        value={option.toUpperCase().replace(/ /g, '_')}
                      >
                        {option === 'MED'
                          ? 'Medium'
                          : option
                              .replace(/(\B)[^ ]*/g, match =>
                                match.toLowerCase()
                              )
                              .replace(/^[^ ]/g, match => match.toUpperCase())}
                      </MenuItem>
                    ))}
                    ))}
                  </TextField>
                )
              }}
            </Query>
            <br />
            <Query query={GET_ACTIVITY_CONTACT_METHOD_OPTIONS_QUERY}>
              {({ loading, error, data }) => {
                if (loading) return <LoadingMessage />
                if (error) return <ErrorMessage error={error} />
                return (
                  <TextField
                    required={!followup}
                    name="method"
                    label="Contact Method"
                    className={styles.textField}
                    select
                    value={method || ''}
                    onChange={props.handleChange}
                    SelectProps={{
                      MenuProps: {
                        className: styles.menu,
                      },
                    }}
                    InputLabelProps={{
                      shrink: !!method && method !== undefined,
                      formlabelclasses: {
                        asterisk: styles.asterisk,
                      },
                    }}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                  >
                    {data.__type.enumValues.map(({ name: option }) => (
                      <MenuItem
                        key={option
                          .replace(/(\B)[^ ]*/g, match => match.toLowerCase())
                          .replace(/_/g, ' ')
                          .replace(/^[^ ]/g, match => match.toUpperCase())}
                        value={option.toUpperCase().replace(/ /g, '_')}
                      >
                        {option
                          .replace(/(\B)[^ ]*/g, match => match.toLowerCase())
                          .replace(/_/g, ' ')
                          .replace(/^[^ ]/g, match => match.toUpperCase())}
                      </MenuItem>
                    ))}
                  </TextField>
                )
              }}
            </Query>
            <br />
            <Query query={GET_OFFICE_EMPLOYEES}>
              {({ loading, error, data }) => {
                if (loading) return <LoadingMessage />
                if (error) return <ErrorMessage error={error} />
                const responsibleEmployees = data.employees
                  .filter(employee => employee.status === 'ACTIVE')
                  .map(employee => {
                    return {
                      name: `${employee.firstName} ${employee.lastName}`,
                      value: employee.id,
                    }
                  })

                return (
                  <TextField
                    name="responsibleEmployeeId"
                    label="Assign To"
                    value={responsibleEmployeeId || ''}
                    className={styles.textField}
                    select
                    onChange={props.handleChange}
                    SelectProps={{
                      MenuProps: {
                        className: styles.menu,
                      },
                    }}
                    InputLabelProps={{
                      shrink:
                        !!responsibleEmployeeId &&
                        responsibleEmployeeId !== undefined,
                    }}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                  >
                    {responsibleEmployees.map(option => (
                      <MenuItem key={option.name} value={option.value}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )
              }}
            </Query>
            <br />
            <Query query={GET_ACTIVITY_TYPES}>
              {({ loading, error, data }) => {
                if (loading) return <LoadingMessage />
                if (error) return <ErrorMessage error={error} />
                return (
                  <TextField
                    name="type"
                    label="Task Type"
                    className={styles.textField}
                    select
                    value={type || ''}
                    onChange={handleChange}
                    SelectProps={{
                      MenuProps: {
                        className: styles.menu,
                      },
                    }}
                    InputLabelProps={{
                      shrink: !!type && type !== undefined,
                      formlabelclasses: {
                        asterisk: styles.asterisk,
                      },
                    }}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                  >
                    {data.__type.enumValues.map(({ name: option }) => (
                      <MenuItem
                        key={option
                          .replace(/(\B)[^ ]*/g, match => match.toLowerCase())
                          .replace(/_/g, ' ')
                          .replace(/^[^ ]/g, match => match.toUpperCase())}
                        value={option}
                      >
                        {option
                          .replace(/(\B)[^ ]*/g, match => match.toLowerCase())
                          .replace(/_/g, ' ')
                          .replace(/^[^ ]/g, match => match.toUpperCase())}
                      </MenuItem>
                    ))}
                  </TextField>
                )
              }}
            </Query>
          </Flex>
          <Flex grow={2} m="20px" style={{ maxWidth: '300px' }} column>
            <DateTimePicker
              name="endDate"
              label="Due Date"
              value={endDate || null}
              onChange={handleChange}
              InputProps={{
                readOnly: readOnly,
              }}
              disabled={resolvedDate}
            />
            <br />
            {resolvedDate && (
              <DatePicker
                disabled
                id="resolvedDate"
                label="Close Date"
                value={resolvedDate}
                InputProps={{
                  readOnly: readOnly,
                }}
              />
            )}
            {resolvedDate && <br />}
            {method === 'IN_PERSON' && (
              <Query
                query={GET_CENTER_LOCATIONS}
                variables={{ filter: { isPublished: true } }}
              >
                {({ data, loading, error }) => {
                  if (loading) return <LoadingMessage />
                  if (error) return <ErrorMessage error={error} />

                  const ardentLocations = data.centerLocations

                  return (
                    <Fragment>
                      <TextField
                        name="centerLocation"
                        label="Center Location"
                        required
                        value={centerLocation}
                        className={styles.textField}
                        select
                        onChange={handleChange}
                        SelectProps={{
                          MenuProps: {
                            className: styles.menu,
                          },
                        }}
                        InputLabelProps={{
                          shrink: centerLocation,
                        }}
                      >
                        {ardentLocations.map(option => (
                          <MenuItem key={option.name} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                      <br />
                    </Fragment>
                  )
                }}
              </Query>
            )}
            <TextField
              name="description"
              label="Description"
              multiline
              maxRows="5"
              value={description || ''}
              className={styles.textField}
              fullWidth
              onChange={handleChange}
              InputProps={{
                readOnly: readOnly,
              }}
            />
            <TextField
              name="resolution"
              label="Resolution"
              multiline
              maxRows="5"
              value={resolution || ''}
              className={styles.textField}
              fullWidth
              onChange={handleChange}
              InputProps={{
                readOnly: readOnly,
              }}
            />
            {toggleLogged && <br />}
            {toggleLogged && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={logged}
                    onChange={toggleLogged}
                    value="Logged"
                    color="primary"
                  />
                }
                label="Task has already been completed"
              />
            )}
          </Flex>
        </Flex>
      </form>
    </Flex>
  )
}

const styles = theme => ({
  menu: {
    width: '20%',
  },
  asterisk: {
    color: 'red',
  },
})

export default withStyles(styles)(TaskForm)
