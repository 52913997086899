import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
// import AddInvoiceView from './AddInvoiceView'
import PaymentDetailedView from './PaymentDetailedView'
import PaymentTableView from './PaymentTableView'

class PaymentView extends Component {
  render() {
    const url = this.props.match.url
    return (
      <Switch>
        {/* <Route path={url + '/add-new-invoice'} component={AddInvoiceView} /> */}
        <Route path={url + '/*'} component={PaymentDetailedView} />
        <Route path={url} component={PaymentTableView} />
      </Switch>
    )
  }
}

export default PaymentView
