import React, { Component } from 'react'
import { Query } from 'react-apollo'
import { Redirect } from 'react-router-dom'

import TaskSidebar from './TaskSidebar'
import TaskTable from './TaskTable'

import { GET_ACTIVITIES } from './queries'

import {
  Button,
  ErrorMessage,
  Flex,
  LoadingMessage,
  Menu,
  MenuItem,
  SettingsIcon,
  Tooltip,
} from '../../components'

class TaskTableView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      redirect: false,
      showFilters: true,
      menuAnchor: null,
      sorted: [
        {
          id: 'endDate',
          desc: true,
        },
        {
          id: 'priority',
          desc: true,
        },
      ],
      filtered: [
        {
          id: 'status',
          value: 'OPEN',
        },
      ],
    }
  }

  toggleFilters = () => {
    this.setState(state => ({
      showFilters: !state.showFilters,
      menuAnchor: null,
    }))
  }

  onFilteredChange = filtered => {
    this.setState({
      filtered,
    })
  }

  menuOpen = event => {
    this.setState({
      menuAnchor: event.currentTarget,
    })
  }

  menuClose = () => {
    this.setState({
      menuAnchor: null,
    })
  }

  rowClick = rowInfo => {
    rowInfo &&
      this.setState({
        redirect: true,
        redirectTo: `/task/${rowInfo.original.id}`,
      })
  }

  menuButton = () => {
    return (
      <Flex p="5px">
        <Flex p="5px">
          <Tooltip>
            <Button color="default" aria-label="Menu" onClick={this.menuOpen}>
              <SettingsIcon />
            </Button>
          </Tooltip>
        </Flex>
        <Menu
          id="simple-menu"
          anchorEl={this.state.menuAnchor}
          open={Boolean(this.state.menuAnchor)}
          onClose={this.menuClose}
        >
          <MenuItem onClick={this.toggleFilters}>Toggle Filters</MenuItem>
        </Menu>
      </Flex>
    )
  }

  getStatusFilter = () => {
    for (const filter of this.state.filtered) {
      if (filter.id === 'status') {
        return filter.value === 'OPEN'
          ? { resolvedDate: { $exists: false } }
          : filter.value === 'CLOSED'
          ? { resolvedDate: { $exists: true } }
          : {}
      }
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirectTo} />
    }

    return (
      <Query
        fetchPolicy={'cache-and-network'}
        query={GET_ACTIVITIES}
        variables={{ filter: { ...this.getStatusFilter() } }}
      >
        {({ loading, error, data }) => {
          if (loading) return <LoadingMessage />
          if (error) return <ErrorMessage error={error} />

          const processedTasks = data.activities.map(task => {
            const {
              contact,
              createdBy,
              description,
              endDate,
              method,
              priority,
              responsibleEmployee,
              resolvedDate,
              startDate,
              student,
              type,
            } = task

            return {
              id: task.id,
              type,
              status: resolvedDate ? 'CLOSED' : 'OPEN',
              method,
              startDate,
              endDate,
              priority,
              resolvedDate,
              contact: contact && `${contact.firstName} ${contact.lastName}`,
              studentName:
                student && `${student.firstName} ${student.lastName}`,
              responsibleEmployee:
                responsibleEmployee &&
                `${responsibleEmployee.firstName} ${responsibleEmployee.lastName}`,
              createdBy:
                createdBy && `${createdBy.firstName} ${createdBy.lastName}`,
              description,
            }
          })

          return (
            <Flex>
              <TaskSidebar
                show={true}
                tasks={processedTasks}
                menu={this.menuButton}
              />
              <Flex align="left" column grow={1}>
                <TaskTable
                  tasks={processedTasks}
                  filterable={this.state.showFilters}
                  onRowClick={this.rowClick}
                  sorted={this.state.sorted}
                  filtered={this.state.filtered}
                  onFilteredChange={this.onFilteredChange}
                />
              </Flex>
            </Flex>
          )
        }}
      </Query>
    )
  }
}

export default TaskTableView
