import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import { Query } from 'react-apollo'

import AddFamilyView from './AddFamilyView'
import FamilyDetailView from './FamilyDetailView'
import FamilyTableView from './FamilyTableView'

import { ErrorMessage, LoadingMessage } from '../../components'

import { GET_FAMILY_STATUS_OPTIONS } from '../../queries'

class FamilyView extends Component {
  render() {
    const { url } = this.props.match
    return (
      <Query query={GET_FAMILY_STATUS_OPTIONS}>
        {({ loading, error, data }) => {
          if (loading) return <LoadingMessage />
          if (error) return <ErrorMessage error={error} />
          const statuses = data.__type.enumValues.map(({ name }) => name)
          return (
            <Switch>
              <Route
                path={`${url}/add-new-family`}
                render={props => <AddFamilyView {...props} />}
              />
              <Route
                path={`${url}/*`}
                render={props => <FamilyDetailView {...props} />}
              />
              <Route
                path={url}
                render={props => (
                  <FamilyTableView {...props} statuses={statuses} />
                )}
              />
            </Switch>
          )
        }}
      </Query>
    )
  }
}

export default FamilyView
