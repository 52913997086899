import React from 'react'
import {
  onTime,
  absent,
  leaveEarly,
  arriveLate,
  unknown,
  exempt,
  note,
} from '../../components/assests'
import { Button, CircularProgress } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import { Query, Mutation } from 'react-apollo'

import { IconMaker, Flex } from '../../components'
import { UPDATE_ATTENDEE, GET_STATUS_OPTIONS } from './queries'

const getImg = status => {
  switch (status) {
    case 'ONTIME':
      return onTime
    case 'ABSENT':
      return absent
    case 'EXEMPT':
      return exempt
    case 'EARLY':
      return leaveEarly
    case 'LATE':
      return arriveLate
    default:
      return unknown
  }
}

export const StatusIcon = ({ status, height = '30px', width = '30px' }) => {
  const img = getImg(status)
  return (
    <IconMaker src={img} height={height} width={width} alt={status + ' icon'} />
  )
}

export const ButtonIcon = ({ attendeeId, status, onClick, notePresent }) => {
  const [hover, setHover] = React.useState(false)

  return (
    <Flex
      align="center"
      style={{
        position: 'relative',
        padding: '10px 10px 10px 10px',
        backgroundColor: hover ? '#edf8ff' : 'transparent',
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Query query={GET_STATUS_OPTIONS}>
        {({ data: statusOptionsData, loading: statusLoading }) => (
          <Mutation mutation={UPDATE_ATTENDEE}>
            {(updateAttendee, { loading: mutatingAttendee }) => (
              <Button
                disableRipple
                style={{ backgroundColor: 'transparent' }}
                fullWidth
                onClick={() => {
                  if (statusLoading || mutatingAttendee) return

                  const statusOptions = statusOptionsData.__type.enumValues.map(
                    ({ name }) => name
                  )
                  const currentIndex = statusOptions.indexOf(status)
                  const newStatus =
                    statusOptions[(currentIndex + 1) % statusOptions.length]
                  updateAttendee({
                    variables: {
                      id: attendeeId,
                      input: {
                        status: newStatus,
                      },
                    },
                  })
                }}
              >
                {mutatingAttendee ? (
                  <CircularProgress size={24} />
                ) : (
                  <StatusIcon status={status} />
                )}
              </Button>
            )}
          </Mutation>
        )}
      </Query>

      <Button
        disableRipple
        onClick={onClick}
        style={{
          backgroundColor: 'transparent',
          position: 'absolute',
          right: 0,
          bottom: 0,
          display: hover ? 'block' : 'none',
        }}
      >
        <EditIcon fontSize="small" />
      </Button>

      {notePresent && (
        <Flex
          style={{
            position: 'absolute',
            right: 0,
            top: 0,
            paddingTop: '5px',
            paddingRight: '10px',
          }}
        >
          <IconMaker src={note} alt="note icon" width="15px" height="15px" />
        </Flex>
      )}
    </Flex>
  )
}
