import React from 'react'

import { Card, CardContent, Divider, Typography } from '@mui/material'

import Page from '../../components/Page'
import LoginForm from './LoginForm'

function LoginView() {
  return (
    <Page title="Login">
      <Card sx={{ maxWidth: '30%', overflow: 'visible', display: 'flex' }}>
        <CardContent>
          <Typography gutterBottom variant="h3">
            Sign in
          </Typography>
          <Typography variant="subtitle2">Sign into Ardent Office</Typography>
          <LoginForm />
          <Divider />
        </CardContent>
      </Card>
    </Page>
  )
}

export default LoginView
