import gql from 'graphql-tag'

//Queries
export const GET_REFERRALS = gql`
  query getReferrals($filter: Filters, $sort: Sorts, $page: Pagination) {
    referrals(filter: $filter, sort: $sort, page: $page) {
      id
      createdOn
      lastModified
      archivedOn
      referrer {
        id
        status
        primaryFamilyMember {
          id
          firstName
          lastName
          email
          phoneNumber
        }
      }
      referee {
        id
        status
        primaryFamilyMember {
          id
          firstName
          lastName
          email
          phoneNumber
        }
      }
      refereeFirstName
      refereeLastName
      status
      email
      phone
      expirationDate
    }
  }
`

export const COUNT_REFERRALS = gql`
  query countReferrals {
    countReferrals
  }
`

export const VERIFY_FAMILY_ACCOUNT = gql`
  query VerifyFamilyAccount($filter: Filters) {
    countFamilyMembers(filter: $filter)
  }
`

//mutations
export const UPDATE_REFERRAL = gql`
  mutation updateReferral($id: ID!, $input: UpdateReferralInput!) {
    updateReferral(id: $id, input: $input) {
      id
      createdOn
      lastModified
      archivedOn
      referrer {
        id
        status
        primaryFamilyMember {
          id
          firstName
          lastName
          email
          phoneNumber
        }
      }
      referee {
        id
        status
      }
      refereeFirstName
      refereeLastName
      status
      email
      phone
      expirationDate
    }
  }
`

export const CREATE_REFERRALS = gql`
  mutation createReferral($input: CreateReferralInput!) {
    createReferral(input: $input) {
      id
      createdOn
      lastModified
      archivedOn
      referrer {
        id
        status
        primaryFamilyMember {
          id
          firstName
          lastName
          email
          phoneNumber
        }
      }
      referee {
        id
        status
      }
      refereeFirstName
      refereeLastName
      status
      email
      phone
      expirationDate
    }
  }
`

export const ARCHIVE_REFERRALS = gql`
  mutation archiveReferral($id: ID!) {
    archiveReferral(id: $id) {
      id
    }
  }
`
