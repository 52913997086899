import React from 'react'
import { Flex, Text } from './'

const SidebarText = ({ classes, title, value, style }) => (
  <Flex justify="space-between">
    <Text classes={classes} style={{ ...style, padding: '8px', fontSize: 14 }}>
      {title}
    </Text>
    <Text classes={classes} style={{ ...style, padding: '8px', fontSize: 14 }}>
      {value}
    </Text>
  </Flex>
)

export default SidebarText
