import React, { Component } from 'react'
import { Query } from 'react-apollo'
import AddAssessmentPanels from './AddAssessmentPanels'
import { ErrorMessage, LoadingMessage, withStyles } from '../../components'
import { GET_OPPORTUNITY } from '../OpportunityView/queries'

const styles = theme => ({
  root: {
    textAlign: 'left',
    margin: 'auto',
    width: '67%',
    minWidth: '600px',
    maxWidth: '1000px',
  },
  display1: {
    textAlign: 'left',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  expansionDetail: {
    overflow: 'visible',
  },
})

class AddAssessmentView extends Component {
  render() {
    const opportunityId = this.props.match.params[0]
    return (
      <Query
        fetchPolicy={'cache-and-network'}
        query={GET_OPPORTUNITY}
        variables={{ id: opportunityId }}
      >
        {({ loading, error, data }) => {
          if (loading) return <LoadingMessage />
          if (error) return <ErrorMessage error={error} />
          const { opportunity } = data
          return (
            <AddAssessmentPanels {...this.props} opportunity={opportunity} />
          )
        }}
      </Query>
    )
  }
}

export default withStyles(styles)(AddAssessmentView)
