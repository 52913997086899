import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'

import { Button, Flex, withStyles } from '../../components'

import { sideBarStyles } from '../../utils/style'

class PaymentSidebar extends Component {
  constructor(props) {
    super(props)
    this.state = { redirect: false }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirectTo} />
    }
    return (
      <Flex
        column
        p="10px"
        style={{
          width: '15.38%',
          borderRight: `1px #aaaaaa solid`,
          maxWidth: '200px',
          overflowX: undefined,
          textAlign: 'left',
          backgroundColor: '#e5e5e5',
        }}
      >
        <Flex column grow={1} justify="space-between">
          <Flex column>
            <Button
              aria-label="AddClass"
              onClick={this.props.addClass}
              disabled={true}
            >
              Refund
            </Button>
            <br />
            <Button
              aria-label="void"
              onClick={() => this.props.updateInvoice({ status: 'ABANDONED' })}
              disabled={!this.state.enableArchive}
            >
              Void
            </Button>
            <Button
              aria-label="UpdatePayment"
              onClick={() => {
                this.props.openDialog()
              }}
            >
              Edit Payment
            </Button>
          </Flex>
        </Flex>
      </Flex>
    )
  }
}

export default withStyles(sideBarStyles)(PaymentSidebar)
