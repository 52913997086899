import React, { Component } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CheckCircleOutlineIcon,
  HighlightOffIcon,
  Radio,
  RadioGroup,
  FormControlLabel,
  RadioButtonUncheckedIcon,
  Flex,
  ProfilePicture,
  CheckBoxIcon,
  CheckBoxOutlineBlankIcon,
  SpellcheckIcon,
} from '../../components'
import { online_icon } from '../../components/assests'
import { StatusIcon } from '../Attendance/Icons'
import { IconMaker } from '../../components'
import { defaultImageUrl } from '../../utils/defaultImage'

const cellStyle = {
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: 'lightgrey',
  padding: '5px 5px 5px 5px',
  align: 'center',
}
class GradeDetailTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      toggle: 'percent',
    }
  }

  handleChange = event => {
    const { name, value } = event.target
    this.setState({
      [name]: value,
    })
  }

  render() {
    const titleColumns = [
      {
        Header: this.props.assignmentTitle,
        accessor: 'student',
        align: 'center',
        width: '500em',
        colSpan: 1,
      },
      {
        Header: `Submitted: ${this.props.numSubmissions}/${this.props.grades.length}`,
        accessor: 'submissions',
        align: 'center',
        colSpan: 2,
      },
      {
        Header: (
          <Flex style={{ justifyContent: 'center', flexGrow: 1 }}>
            {`Graded: `}
            <CheckCircleOutlineIcon color="primary" />
          </Flex>
        ),
        accessor: 'graded',
        align: 'center',
        colSpan: 2,
      },
      {
        Header: (
          <Flex style={{ justifyContent: 'center', flewGrow: 1 }}>
            <RadioGroup
              name="toggle"
              value={this.state.toggle}
              onChange={this.handleChange}
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <FormControlLabel
                value="percent"
                control={<Radio color="primary" />}
                label="Percent"
              />
              <FormControlLabel
                value="number"
                control={<Radio color="secondary" />}
                label="Number"
              />
            </RadioGroup>
          </Flex>
        ),
        accessor: 'toggle',
        align: 'center',
        colSpan: 3,
      },
      ...this.props.problems.map((problem, index) => {
        const percent = problem.total
          ? ((problem.answered * 100) / problem.total).toFixed(0)
          : ''
        return {
          Header:
            this.state.toggle === 'percent' ? percent + '%' : problem.answered,
          accessor: `${problem.id}`,
          align: 'center',
          colSpan: 1,
          style:
            this.state.toggle === 'percent'
              ? {
                  backgroundColor:
                    percent <= 50
                      ? percent > 0
                        ? 'lightcoral'
                        : 'white'
                      : percent <= 75
                      ? 'lightyellow'
                      : 'limegreen',
                }
              : {},
        }
      }),
    ]
    const columns = [
      {
        Header: 'Student Name',
        accessor: 'student',
        align: 'center',
        width: '500em',
      },
      {
        Header: <SpellcheckIcon color="secondary" />,
        accessor: 'graded',
        align: 'center',
      },
      {
        Header: <CheckCircleOutlineIcon color="primary" />,
        accessor: 'correct',
        align: 'center',
      },
      {
        Header: <HighlightOffIcon color="secondary" />,
        accessor: 'incorrect',
        align: 'center',
      },
      {
        Header: <RadioButtonUncheckedIcon color="disabled" />,
        accessor: 'unanswered',
        align: 'center',
      },
      {
        Header: <i className="material-icons">%</i>,
        accessor: 'percent',
        align: 'center',
      },
      {
        Header: <StatusIcon height="24px" width="24px" status="LATE" />,
        accessor: 'submittedLate',
        align: 'center',
      },
      {
        Header: <CheckBoxIcon color="primary" />,
        accessor: 'allCorrect',
        align: 'center',
      },
      ...this.props.problems.map((problem, index) => ({
        Header: `${index + 1}`,
        accessor: `${problem.id}`,
        align: 'center',
      })),
    ]
    return (
      <Table>
        <TableHead>
          <TableRow>
            {titleColumns.map(data => (
              <TableCell
                key={data.accessor}
                align={cellStyle.align}
                style={{ ...cellStyle, ...data.style }}
                colSpan={data.colSpan}
              >
                {data.Header}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            {columns.map(data => (
              <TableCell
                key={data.accessor}
                align={cellStyle.align}
                style={cellStyle}
              >
                {data.Header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {this.props.grades.map((grade, row) => (
            <TableRow
              key={row}
              hover={true}
              style={{
                backgroundColor:
                  grade.attendeeStatus === 'ABSENT' || grade.isMakeup
                    ? '#fad1d1'
                    : null,
              }}
            >
              {[
                {
                  key: 'student',
                  image: grade.student.imageUrl
                    ? grade.student.imageUrl
                    : defaultImageUrl,
                  value: grade.student.id,
                },
                { key: 'graded', value: grade.graded },
                { key: 'correct', value: `${grade.correct}` },
                { key: 'incorrect', value: `${grade.incorrect}` },
                { key: 'unanswered', value: `${grade.unanswered}` },
                { key: 'percent', value: `${grade.percent}` },
                { key: 'submittedLate', value: grade.submittedLate },
                { key: 'allCorrect', value: grade.allCorrect },
              ].map(column => {
                return (
                  <TableCell
                    key={column.key}
                    style={cellStyle}
                    align={cellStyle.align}
                    onClick={() =>
                      !this.props.readOnly &&
                      this.props.onChange(row, -1, column.value, column.key)
                    }
                  >
                    {column.key === 'student' ? (
                      <Flex align="center">
                        <ProfilePicture
                          src={column.image}
                          firstName={grade.student.firstName}
                          lastName={grade.student.lastName}
                          gender={grade.student.gender}
                        />
                        <Flex
                          style={{
                            marginLeft: '2em',
                            width: '100%',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Flex style={{ width: 'max-content' }}>
                            {`${grade.student.firstName} ${grade.student.lastName}`}
                          </Flex>
                          {grade.student.active ? (
                            <Flex style={{ marginLeft: '1em' }}>
                              <IconMaker
                                src={online_icon}
                                height={'20px'}
                                width={'20px'}
                                alt={'icon'}
                              />
                            </Flex>
                          ) : null}
                        </Flex>
                      </Flex>
                    ) : column.key === 'submittedLate' ? (
                      column.value ? (
                        <StatusIcon height="24px" width="24px" status="LATE" />
                      ) : (
                        <StatusIcon
                          height="24px"
                          width="24px"
                          status="ONTIME"
                        />
                      )
                    ) : column.key === 'allCorrect' ? (
                      column.value ? (
                        <CheckBoxIcon color="primary" />
                      ) : (
                        <CheckBoxOutlineBlankIcon color="disabled" />
                      )
                    ) : column.key === 'graded' ? (
                      column.value === null ? (
                        <CheckBoxOutlineBlankIcon color="disabled" />
                      ) : column.value ? (
                        <CheckBoxIcon color="primary" />
                      ) : (
                        <CheckBoxOutlineBlankIcon color="primary" />
                      )
                    ) : (
                      column.value
                    )}
                  </TableCell>
                )
              })}
              {grade.submission.map((res, probNum) => {
                return (
                  <TableCell
                    onClick={() =>
                      !this.props.readOnly &&
                      this.props.onChange(row, probNum, res)
                    }
                    key={probNum}
                    style={cellStyle}
                    align={cellStyle.align}
                  >
                    {res === null ? (
                      <RadioButtonUncheckedIcon color="disabled" />
                    ) : res === 0 ? (
                      <HighlightOffIcon color="secondary" />
                    ) : (
                      <CheckCircleOutlineIcon color="primary" />
                    )}
                  </TableCell>
                )
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    )
  }
}
export default GradeDetailTable
