import React, { Component } from 'react'
import { Query } from 'react-apollo'
import { Redirect } from 'react-router-dom'
import OpportunityTable from './OpportunityTable'
import OpportunitySidebar from './OpportunitySidebar'

import {
  Button,
  ErrorMessage,
  Flex,
  LoadingMessage,
  Menu,
  MenuItem,
  SettingsIcon,
  Tooltip,
} from '../../components'

import { GET_OPPORTUNITIES } from './queries'

class OpportunityTableView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      redirect: false,
      sidebar: true,
      showFilters: true,
      menuAnchor: null,
      sorted: [
        {
          id: 'openDate',
          desc: true,
        },
      ],
      filtered: [
        {
          id: 'status',
          value: 'OPEN',
        },
      ],
    }
  }

  toggleSidebar = () => {
    this.setState(state => ({
      sidebar: !state.sidebar,
      menuAnchor: null,
    }))
  }

  toggleFilters = () => {
    this.setState(state => ({
      showFilters: !state.showFilters,
      menuAnchor: null,
    }))
  }

  onFilteredChange = filtered => {
    this.setState({
      filtered,
    })
  }

  menuOpen = event => {
    this.setState({
      menuAnchor: event.currentTarget,
    })
  }

  menuClose = () => {
    this.setState({
      menuAnchor: null,
    })
  }

  rowClick = rowInfo => {
    rowInfo &&
      this.setState({
        redirect: true,
        redirectTo: `/opportunity/${rowInfo.original.id}`,
      })
  }

  menuButton = () => {
    const { menuAnchor } = this.state
    return (
      <Flex p="5px">
        <Flex p="5px">
          <Tooltip>
            <Button color="default" aria-label="Menu" onClick={this.menuOpen}>
              <SettingsIcon />
            </Button>
          </Tooltip>
        </Flex>
        <Menu
          id="simple-menu"
          anchorEl={menuAnchor}
          open={Boolean(menuAnchor)}
          onClose={this.menuClose}
        >
          <MenuItem onClick={this.toggleFilters}>Toggle Filters</MenuItem>
        </Menu>
      </Flex>
    )
  }

  render() {
    const {
      filtered,
      redirect,
      redirectTo,
      showFilters,
      sidebar,
      sorted,
    } = this.state
    if (redirect) {
      return <Redirect push to={redirectTo} />
    }

    const opportunityStatusFilter = filtered.find(filter => {
      return filter.id === 'status'
    }).value

    return (
      <Query
        query={GET_OPPORTUNITIES}
        variables={{
          filter: {
            status: {
              $match:
                opportunityStatusFilter !== 'ALL'
                  ? opportunityStatusFilter
                  : '',
            },
          },
        }}
        fetchPolicy={'cache-and-network'}
      >
        {({ loading, error, data }) => {
          if (loading) return <LoadingMessage />
          if (error) return <ErrorMessage error={error} />
          const processedOpportunities = data.opportunities.map(opportunity => {
            const {
              closeDate,
              createdBy,
              createdOn,
              lastContacted,
              notes,
              openDate,
              primaryInterest,
              responsibleEmployee,
              session,
              source,
              stage,
              status,
              student,
              year,
            } = opportunity
            return {
              id: opportunity.id,
              createdOn: createdOn,
              status: status,
              openDate: openDate,
              closeDate: closeDate,
              lastContacted: lastContacted,
              openTime:
                (closeDate ? new Date(closeDate) : Date.now()) -
                new Date(openDate),
              firstName: student && student.firstName,
              lastName: student && student.lastName,
              stage: stage,
              primaryInterest: primaryInterest,
              session: session,
              year: year,
              source: source,
              notes: notes,
              responsibleEmployee:
                responsibleEmployee &&
                `${responsibleEmployee.firstName} ${responsibleEmployee.lastName}`,
              creator:
                createdBy && `${createdBy.firstName} ${createdBy.lastName}`,
            }
          })
          const { stages, statuses } = this.props
          return (
            <Flex>
              {sidebar ? (
                <OpportunitySidebar
                  show={sidebar}
                  opportunities={data.opportunities}
                  stages={stages}
                  menu={this.menuButton}
                />
              ) : null}
              <Flex pl={sidebar ? '0px' : '20px'} align="left" column grow={1}>
                <OpportunityTable
                  opportunities={processedOpportunities}
                  stages={stages}
                  statuses={statuses}
                  filterable={showFilters}
                  onRowClick={this.rowClick}
                  sorted={sorted}
                  filtered={filtered}
                  onFilteredChange={this.onFilteredChange}
                />
              </Flex>
            </Flex>
          )
        }}
      </Query>
    )
  }
}

export default OpportunityTableView
