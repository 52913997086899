import React, { Component } from 'react'
import { Query } from 'react-apollo'

import LessonViewSidebar from './LessonViewSidebar'
import LessonTable from './LessonTable'

import {
  Button,
  Flex,
  LoadingMessage,
  ErrorMessage,
  Menu,
  MenuItem,
  SettingsIcon,
  Tooltip,
  Redirect,
} from '../../components'

import { GET_CLASSROOMS } from './queries'

import { formatTimeRange, convertToMDY } from '../../utils/datetime'

class LessonView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectAll: false,
      checked: [],
      disableEditButton: true,
      disableStatusButton: true,
      disableTrialButton: true,
      editDialog: {
        open: false,
        disableSave: true,
        classroomId: '',
      },
      statusDialog: {
        open: false,
        classrooms: '',
      },
      createDialog: {
        open: false,
      },
      redirect: false,
      showFilters: true,
      menuAnchor: null,
      sidebar: true,
      startDateString: new Date().toISOString(),
      endDateString: new Date(
        new Date().setDate(new Date().getDate() - 30)
      ).toISOString(),
      sorted: [
        {
          id: 'code',
        },
      ],
      filtered: [
        {
          id: 'status',
          value: 'OPENED',
        },
      ],
    }
    this.classesByOtherFilters = []
    this.classesFilteredByStatus = []
    this.sideBar = React.createRef()
    this.onlyFilterByStatus = true
    this.previousStatus = this.state.filtered[0].value
  }

  getStatusFilter = () => {
    for (const filter of this.state.filtered) {
      if (filter.id === 'status') {
        return filter.value === 'ALL' ? {} : { status: filter.value }
      }
    }
    return {}
  }

  toggleSidebar = () => {
    this.setState(state => ({
      sidebar: !state.sidebar,
      menuAnchor: null,
    }))
  }

  toggleFilters = () => {
    this.setState(state => ({
      showFilters: !state.showFilters,
      menuAnchor: null,
    }))
  }

  onFilteredChange = filtered => {
    this.setState({
      filtered,
    })
  }

  menuOpen = event => {
    this.setState({
      menuAnchor: event.currentTarget,
    })
  }

  menuClose = () => {
    this.setState({
      menuAnchor: null,
    })
  }

  rowClick = rowInfo => {
    rowInfo &&
      this.setState({
        redirect: true,
        redirectTo: `/lesson/${rowInfo.original.id}`,
      })
  }

  menuButton = () => {
    const { menuAnchor } = this.state
    return (
      <Flex p="5px">
        <Flex p="5px">
          <Tooltip>
            <Button color="default" aria-label="Menu" onClick={this.menuOpen}>
              <SettingsIcon />
            </Button>
          </Tooltip>
        </Flex>
        <Menu
          id="simple-menu"
          anchorEl={menuAnchor}
          open={Boolean(menuAnchor)}
          onClose={this.menuClose}
        >
          <MenuItem onClick={this.toggleFilters}>Toggle Filters</MenuItem>
        </Menu>
      </Flex>
    )
  }

  isStatusFilterChanged = currentStatus => {
    return currentStatus !== this.previousStatus
  }

  changeToFilterByStatusOnly = currentFilters => {
    let statusFilter = currentFilters.filter(filter => filter.id === 'status')
    if (this.isStatusFilterChanged(statusFilter[0].value)) {
      currentFilters = statusFilter
      this.onlyFilterByStatus = true
    }
    this.previousStatus = statusFilter[0].value
    return currentFilters
  }

  tableChangeHandler = (filters, filteredClasses) => {
    this.onlyFilterByStatus = false
    this.classesByOtherFilters = filteredClasses
    filters = this.changeToFilterByStatusOnly(filters)
    this.setState({
      filtered: filters,
    })
  }

  handleSingleCheckboxChange = index => {
    const { checked, selectAll } = this.state
    let newChecked = checked
    newChecked[index] = !checked[index]

    this.setState({
      selectAll: newChecked[index] || !selectAll,
      disableEditButton: !(newChecked.filter(i => i).length === 1),
      disableStatusButton: !(newChecked.filter(i => i).length >= 1),
      disableTrialButton: !newChecked.some(i => {
        return i
      }),
      checked: newChecked,
    })
  }

  openEditDialog = () => {
    const { checked } = this.state

    this.setState(state => ({
      editDialog: {
        ...state.editDialog,
        open: true,
        classroomId: this.classesFilteredByStatus[checked.findIndex(i => i)].id,
      },
    }))
  }

  render() {
    const {
      filtered,
      redirect,
      redirectTo,
      showFilters,
      sidebar,
      sorted,
    } = this.state
    if (redirect) {
      return <Redirect push to={redirectTo} />
    }
    return (
      <Query
        fetchPolicy={'cache-and-network'}
        query={GET_CLASSROOMS}
        variables={{
          filter: this.getStatusFilter(),
        }}
      >
        {({ loading, error, data, refetch }) => {
          if (loading) return <LoadingMessage />
          if (error) return <ErrorMessage error={error} />

          this.classesFilteredByStatus = data.classrooms.map(classroom => {
            const {
              status,
              title,
              code,
              endDate,
              id,
              centerLocation,
              quarter,
              session,
              allowTrial,
              year,
              startDate,
              finalAccessDate,
              meets,
              enrollmentCount,
              startTime,
              endTime,
              instructorDisplayNames,
            } = classroom

            const instructorName = instructorDisplayNames

            return {
              id: id,
              status: status,
              title: title.slice(title.indexOf('-') + 2, title.length),
              code: code,
              centerLocation: centerLocation && centerLocation.name,
              startDate: convertToMDY(startDate),
              endDate: convertToMDY(endDate),
              year: year,
              finalAccessDate: convertToMDY(finalAccessDate),
              quarter: quarter,
              session: session,
              day: meets,
              time: formatTimeRange(new Date(startTime), new Date(endTime)),
              enrollmentCount: enrollmentCount,
              instructor: instructorName,
              allowTrial: allowTrial ? '✓' : '',
            }
          })

          return (
            <Flex>
              {sidebar ? (
                <LessonViewSidebar
                  ref={this.sideBar}
                  show={sidebar}
                  classrooms={
                    this.onlyFilterByStatus
                      ? this.classesFilteredByStatus
                      : this.classesByOtherFilters
                  }
                  menu={this.menuButton}
                  disableEditButton={this.state.disableEditButton}
                  disableStatusButton={this.state.disableStatusButton}
                  disableTrialButton={this.state.disableTrialButton}
                  openTrialDialog={this.openTrialDialog}
                  openEditDialog={this.openEditDialog}
                  openStatusDialog={this.openStatusDialog}
                  openCreateDialog={this.openCreateDialog}
                />
              ) : null}
              <Flex pl={sidebar ? '0px' : '20px'} align="left" column grow={1}>
                <LessonTable
                  classes={this.classesFilteredByStatus}
                  updateClasses={this.tableChangeHandler}
                  filterable={showFilters}
                  onRowClick={this.rowClick}
                  sorted={sorted}
                  filtered={filtered}
                  onFilteredChange={this.onFilteredChange}
                  handleSingleCheckboxChange={this.handleSingleCheckboxChange}
                  checked={this.state.checked}
                />
              </Flex>
            </Flex>
          )
        }}
      </Query>
    )
  }
}

export default LessonView
