import React, { Component } from 'react'
import { Mutation } from 'react-apollo'
import { Redirect } from 'react-router-dom'

import {
  Button,
  AccordionForm,
  Flex,
  DateTimePicker,
  MenuItem,
  SnackbarNotification,
  Text,
  TextField,
} from '../../components'

import { CREATE_COUNSELING_MEETING } from './queries'

import Cookies from 'js-cookie'
import { idName } from '../../config'
import moment from 'moment-timezone'
class AddMeetingView extends Component {
  constructor(props) {
    super(props)

    this.state = {
      counselingId: this.props.match.params[0],
      employeeId: Cookies.get(idName),
      redirect: false,
      redirectTo: '',
      resolution: null,
      snackbar: {
        open: false,
        message: '',
        messageType: '',
      },
      ...this.props,
    }
  }

  returnToCounselingTable = () => {
    this.setState({
      redirect: true,
      redirectTo: `/counseling`,
    })
  }

  handleChange = event => {
    const { name, value } = event.target
    this.setState({
      [name]: value,
    })
  }

  handleSubmit = async CreateCounselingMeeting => {
    const {
      counselingId,
      employeeId,
      endDateTime,
      method,
      purpose,
      resolution,
      startDateTime,
    } = this.state
    await CreateCounselingMeeting({
      variables: {
        input: {
          createdById: employeeId,
          counselingEnrollmentId: counselingId,
          method,
          startDateTime,
          endDateTime: endDateTime || moment(startDateTime).add(1, 'hours'),
          purpose,
          resolution,
        },
      },
    })
    this.returnToCounselingTable()
  }

  render() {
    const {
      endDateTime,
      method,
      purpose,
      redirect,
      redirectTo,
      resolution,
      snackbar,
      startDateTime,
    } = this.state
    const { contactMethods } = this.props
    if (redirect) {
      return <Redirect push to={redirectTo} />
    }

    return (
      <Flex
        column
        grow={1}
        style={{
          textAlign: 'left',
          margin: 'auto',
          width: '67%',
          minWidth: '600px',
          maxWidth: '1000px',
        }}
      >
        <br />
        <Text variant="h5">Add Counseling Meeting</Text>
        <br />
        <AccordionForm
          expanded
          title="Add New Meeting"
          form={
            <Flex column style={{ align: 'center', flexGrow: 1 }}>
              <Flex justify="space-evenly">
                <Flex grow={2} m="20px" style={{ maxWidth: '300px' }} column>
                  <TextField
                    name="purpose"
                    label="Purpose"
                    value={purpose}
                    multiline
                    onChange={this.handleChange}
                  />
                  <br />
                  <TextField
                    name="resolution"
                    label="Resolution"
                    value={resolution}
                    multiline
                    onChange={this.handleChange}
                  />
                </Flex>
                <Flex grow={2} m="20px" style={{ maxWidth: '300px' }} column>
                  <TextField
                    name="method"
                    label="Contact Method"
                    value={method}
                    select
                    fullWidth
                    onChange={this.handleChange}
                  >
                    {contactMethods.map(option => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                  <br />
                  <DateTimePicker
                    name="startDateTime"
                    label="Start DateTime *"
                    value={startDateTime || null}
                    onChange={this.handleChange}
                  />
                  <br />
                  <DateTimePicker
                    name="endDateTime"
                    label="End DateTime"
                    value={endDateTime || null}
                    onChange={this.handleChange}
                  />
                  <Flex direction={'row-reverse'} p="10px">
                    <Mutation mutation={CREATE_COUNSELING_MEETING}>
                      {CreateCounselingMeeting => (
                        <Button
                          onClick={() => {
                            if (!startDateTime) {
                              this.setState({
                                snackbar: {
                                  open: true,
                                  message:
                                    'At least one required field is missing',
                                  messageType: 'error',
                                },
                              })
                            } else {
                              if (
                                window.confirm(
                                  'Are you sure you want to submit?'
                                )
                              ) {
                                this.handleSubmit(CreateCounselingMeeting)
                              }
                            }
                          }}
                          style={{ margin: '5px' }}
                        >
                          Submit
                        </Button>
                      )}
                    </Mutation>
                    <Button
                      color="secondary"
                      style={{ margin: '5px' }}
                      onClick={() => {
                        if (
                          window.confirm(
                            'Do you want to return to the counseling table?'
                          )
                        ) {
                          this.returnToCounselingTable()
                        }
                      }}
                    >
                      Cancel
                    </Button>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          }
        />
        <SnackbarNotification
          open={snackbar.open}
          handleClose={() => {
            this.setState({
              snackbar: {
                ...snackbar,
                open: false,
              },
            })
          }}
          message={snackbar.message}
          messageType={snackbar.messageType}
        />
      </Flex>
    )
  }
}

export default AddMeetingView
