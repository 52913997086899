import React from 'react'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'

import CreateTrialViewPanels from './CreateTrialViewPanels'
import { ErrorMessage, LoadingMessage, withStyles } from '../../components'

function CreateTrialView(props) {
  const opportunityId = props.match.params[0]
  return opportunityId ? (
    <Query
      fetchPolicy={'cache-and-network'}
      query={GET_OPPORTUNITY}
      variables={{ id: opportunityId }}
    >
      {({ loading, error, data }) => {
        if (loading) return <LoadingMessage />
        if (error) return <ErrorMessage error={error} />

        const { opportunity } = data
        return <CreateTrialViewPanels {...props} opportunity={opportunity} />
      }}
    </Query>
  ) : (
    <CreateTrialViewPanels {...props} />
  )
}

const styles = theme => ({
  root: {
    textAlign: 'left',
    margin: 'auto',
    width: '67%',
    minWidth: '600px',
    maxWidth: '1000px',
  },
  display1: {
    textAlign: 'left',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  expansionDetail: {
    overflow: 'visible',
  },
})

const GET_OPPORTUNITY = gql`
  query getOpportunity($id: ID!) {
    opportunity(id: $id) {
      id
      status
      session
      year
      source
      notes
      stage
      student {
        id
        firstName
        lastName
        phoneNumber
        email
      }
    }
  }
`

export default withStyles(styles)(CreateTrialView)
