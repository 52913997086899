import React from 'react'
import MuiTooltip from '@material-ui/core/Tooltip'

const Tooltip = ({ children }) => (
  <MuiTooltip enterDelay={500} title="Open Settings" placement="top-start">
    <div>{children}</div>
  </MuiTooltip>
)

export default Tooltip
