import React, { Component } from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import Cookies from 'js-cookie'
import { debug, idName } from '../../config'
import AddReconcilePage from './AddReconcilePage'
import ReconcileTableView from './ReconcileTableView'

class ReconcileView extends Component {
  render() {
    const url = this.props.match.url
    const employeeId = Cookies.get(idName)
    if (!debug && employeeId !== '7460b01e-59bc-4e0b-aac5-44e423aa46d7') {
      this.props.history.push('/')
    }

    return (
      <Switch>
        <Route
          path={url + '/add-reconciliation'}
          component={AddReconcilePage}
        />
        <Route path={url} component={ReconcileTableView} />
      </Switch>
    )
  }
}

export default withRouter(ReconcileView)
