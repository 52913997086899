import React from 'react'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import Composer from 'react-composer'

import CreateTrialView from './CreateTrialView'
import TrialTableView from './TrialTableView'
import TrialDetailView from './TrialDetailView'
import { Route, Switch } from 'react-router-dom'

import { ErrorMessage, LoadingMessage } from '../../components'

const TrialView = props => {
  const url = props.match.url
  return (
    <Composer
      components={[
        <Query query={GET_TRIAL_STATUS_OPTIONS_QUERY}>
          {function() {
            return ''
          }}
        </Query>,
        <Query query={GET_OPPORTUNITY_STATUS_OPTIONS_QUERY}>
          {function() {
            return ''
          }}
        </Query>,
      ]}
    >
      {([
        {
          loading: trialStatusLoading,
          error: trialStatusError,
          data: trialStatusData,
        },
        {
          loading: opportunityStatusLoading,
          error: opportunityStatusError,
          data: opportunityStatusData,
        },
      ]) => {
        if (trialStatusLoading || opportunityStatusLoading)
          return <LoadingMessage />
        if (trialStatusError || opportunityStatusError)
          return (
            <ErrorMessage error={trialStatusError || opportunityStatusError} />
          )
        const trialStatuses = trialStatusData.__type.enumValues.map(
          ({ name }) => name
        )
        const opportunityStatuses = opportunityStatusData.__type.enumValues.map(
          ({ name }) => name
        )
        return (
          <Switch>
            <Route
              path={`${url}/create-trial/*`}
              render={props => (
                <CreateTrialView {...props} trialStatuses={trialStatuses} />
              )}
            />
            <Route
              path={`${url}/create-trial`}
              render={props => (
                <CreateTrialView {...props} trialStatuses={trialStatuses} />
              )}
            />
            <Route
              path={`${url}/*`}
              render={props => (
                <TrialDetailView {...props} trialStatuses={trialStatuses} />
              )}
            />
            <Route
              path={url}
              render={props => (
                <TrialTableView
                  {...props}
                  trialStatuses={trialStatuses}
                  opportunityStatuses={opportunityStatuses}
                />
              )}
            />
          </Switch>
        )
      }}
    </Composer>
  )
}

export const GET_TRIAL_STATUS_OPTIONS_QUERY = gql`
  query getTrialStatusOptions {
    __type(name: "TrialStatus") {
      enumValues {
        name
      }
    }
  }
`

export const GET_OPPORTUNITY_STATUS_OPTIONS_QUERY = gql`
  query getOpportunityStatusOptions {
    __type(name: "OpportunityStatus") {
      enumValues {
        name
      }
    }
  }
`

export default TrialView
