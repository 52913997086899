import React from 'react'

import ReactTable from 'react-table'

import { dateFilterMethod } from '../../utils/datetime'

const ClassTable = ({
  classrooms,
  filtered,
  updateClasses,
  handleFilteredChange,
  handleRowClick,
  handleCheckboxChange,
  filterable,
  sorted,
  classes,
  classTable,
  checked,
}) => {
  const defaultSorted = [{ id: 'code' }]

  return (
    <div align="left">
      <ReactTable
        data={classrooms}
        defaultFiltered={filtered}
        onFilteredChange={handleFilteredChange}
        columns={columns}
        filterable={true}
        defaultSorted={defaultSorted}
        className="-highlight"
        style={{ fontSize: '10pt' }}
        defaultPageSize={25}
        defaultFilterMethod={(filter, row) =>
          row[filter.id] &&
          row[filter.id]
            .toString()
            .toLowerCase()
            .includes(filter.value.toString().toLowerCase())
        }
        getTdProps={(state, rowInfo, column, instance) => {
          return {
            onClick: event => {
              const classroomId = rowInfo.original.id
              if (column.id === 'checkbox') {
                handleCheckboxChange(classroomId)
              } else {
                handleRowClick(classroomId)
              }
            },
          }
        }}
        getTheadProps={(state, rowInfo, column, instance) => {
          return {
            style: {
              fontWeight: 'bold',
              borderBottom: '1px solid #E5E5E5',
            },
          }
        }}
      />
    </div>
  )
}

const columns = [
  {
    id: 'checkbox',
    accessor: '',
    Cell: props => <input type="checkbox" />,
    sortable: false,
    filterable: false,
    resizable: false,
    width: 28,
  },
  {
    Header: 'Status',
    accessor: 'status',
    width: 100,
    Cell: props => {
      return (
        <span
          style={{
            color:
              props.value === 'OPENED'
                ? '#27AE60'
                : props.value === 'CLOSED'
                ? '#EB5757'
                : '#000000',
          }}
        >
          {props.value}
        </span>
      )
    },
    filterMethod: (filter, row) => {
      if (filter.value !== 'ALL') {
        return row[filter.id] === filter.value
      }
      return true
    },
    Filter: ({ filter, onChange }) => (
      <select
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%' }}
        value={filter ? filter.value : 'ALL'}
      >
        <option value="ALL">ALL</option>
        <option value="DRAFT">Draft</option>
        <option value="PUBLISHED">Published</option>
        <option value="OPENED">Opened</option>
        <option value="CLOSED">Closed</option>
        <option value="CANCELLED">Cancelled</option>
      </select>
    ),
  },
  {
    Header: <span title="Trial Availability">T</span>,
    accessor: 'allowTrial',
    width: 45,
    Cell: row => <div style={{ textAlign: 'center' }}>{row.value}</div>,
  },
  {
    Header: 'Start Date',
    accessor: 'startDate',
    width: 100,
    filterMethod: dateFilterMethod,
  },
  {
    Header: 'End Date',
    accessor: 'endDate',
    width: 100,
    filterMethod: dateFilterMethod,
  },
  {
    Header: 'Year',
    accessor: 'year',
    width: 100,
  },
  {
    Header: 'Quarter',
    accessor: 'quarter',
    width: 100,
    Cell: props => {
      return <span>{props.value ? props.value : 'UNKNOWN'}</span>
    },
    filterMethod: (filter, row) => {
      if (filter.value !== 'ALL' && filter.value !== 'UNKNOWN') {
        return row[filter.id] === filter.value
      }
      if (filter.value === 'UNKNOWN') {
        return row[filter.id] === null
      }
      return true
    },
    Filter: ({ filter, onChange }) => (
      <select
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%' }}
        value={filter ? filter.value : 'ALL'}
      >
        <option value="ALL">ALL</option>
        <option value="Fall">Fall</option>
        <option value="Winter">Winter</option>
        <option value="Spring">Spring</option>
        <option value="PreSummer">PreSummer</option>
        <option value="Summer">Summer</option>
        <option value="OnDemand">OnDemand</option>
        <option value="Tutoring">Tutoring</option>
        <option value="Contest">Contest</option>
      </select>
    ),
  },
  {
    Header: 'Session',
    accessor: 'session',
    width: 100,
  },
  {
    Header: 'Class Code',
    accessor: 'code',
    width: 100,
  },
  {
    Header: 'Style',
    accessor: 'teachingStyle',
    width: 150,
    Filter: ({ filter, onChange }) => (
      <select
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%' }}
        value={filter ? filter.value : 'ALL'}
      >
        <option value="ALL">ALL</option>
        <option value="BLENDED">BLENDED</option>
        <option value="CLASSROOM">CLASSROOM</option>
        <option value="SELF_PACED">SELF PACED</option>
        <option value="SELF_SEQUENCED">SELF SEQUENCED</option>
        <option value="TUTORED">TUTORED</option>
        <option value="MENTORED">MENTORED</option>
      </select>
    ),
    filterMethod: (filter, row) => {
      if (filter.value !== 'ALL') {
        return row[filter.id] === filter.value
      }
      return true
    },
  },
  {
    Header: 'Class Title',
    accessor: 'title',
    width: 250,
  },
  {
    Header: 'Tuition',
    accessor: 'tuitionCents',
    Cell: props => <span>{`$${(props.value / 100).toFixed(2)}`}</span>,
    width: 100,
  },
  {
    Header: 'Enrollments',
    accessor: 'enrollmentCount',
    style: { textAlign: 'right' },
    width: 100,
  },
  {
    Header: 'Day',
    accessor: 'day',
    width: 100,
  },
  {
    Header: 'Time',
    accessor: 'time',
    width: 150,
  },
  {
    Header: 'Location',
    accessor: 'centerLocation',
    width: 150,
  },
  {
    Header: 'Instructor',
    accessor: 'instructorDisplayNames',
    width: 200,
  },
]

export default ClassTable
