import React from 'react'
import { MenuItem, TextField } from './'

function GradeSelector(props) {
  return (
    <TextField
      id="grade"
      name="grade"
      label="Grade"
      className={props.className}
      select
      value={props.value}
      onChange={props.onChange}
      SelectProps={props.SelectProps}
      disabled={props.disabled}
      style={props.style}
      InputLabelProps={{
        shrink: props.value !== '' && props.value !== undefined,
      }}
    >
      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(option => (
        <MenuItem key={option} value={option}>
          {option === 0 ? 'K' : option.toString()}
        </MenuItem>
      ))}
    </TextField>
  )
}

export default GradeSelector
