import React from 'react'
import { Query } from 'react-apollo'

import {
  ErrorMessage,
  Flex,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Input,
  InputLabel,
  LoadingMessage,
  MenuItem,
  Radio,
  RadioGroup,
  Text,
  TextField,
} from './'
import withStyles from './Styles'
import { PhoneNumberInput } from '../utils'
import { GET_LANGUAGE_PREFERENCE_OPTIONS_QUERY } from '../screens/FamilyView/queries'

const GuardianForm = props => {
  let languages = props.languages

  if (languages) return <InnerGuardianForm {...props} />
  return (
    <Query query={GET_LANGUAGE_PREFERENCE_OPTIONS_QUERY}>
      {({ loading, error, data }) => {
        if (loading) return <LoadingMessage />
        if (error) return <ErrorMessage error={error} />
        languages = data.__type.enumValues.map(({ name }) => name)
        return <InnerGuardianForm {...props} languages={languages} />
      }}
    </Query>
  )
}

const InnerGuardianForm = props => {
  const styles = props.classes
  const { handleChange, languages, primary, secondary, validation } = props
  return (
    <Flex
      column
      style={{
        align: 'center',
        flexGrow: 1,
      }}
    >
      <form
        id="addGuardianForm"
        className={styles.container}
        onSubmit={e => {
          e.preventDefault()
        }}
      >
        <Flex justify="space-evenly">
          <Flex grow={2} m="20px" style={{ maxWidth: '300px' }} column>
            <Text variant="h6">Primary Guardian (required)</Text>
            <TextField
              required
              InputLabelProps={{
                formlabelclasses: {
                  asterisk: styles.asterisk,
                },
              }}
              name="firstName"
              label="First Name"
              value={primary.firstName || ''}
              className={styles.textField}
              fullWidth
              onChange={handleChange('primary')}
            />
            <br />
            <TextField
              required
              InputLabelProps={{
                formlabelclasses: {
                  asterisk: styles.asterisk,
                },
              }}
              name="lastName"
              label="Last Name"
              value={primary.lastName || ''}
              className={styles.textField}
              fullWidth
              onChange={handleChange('primary')}
            />
            <br />
            <FormControl component="fieldset" className={styles.formControl}>
              <FormLabel component="legend">Relationship</FormLabel>
              <RadioGroup
                aria-label="relationship"
                name="relationship"
                className={styles.group}
                value={primary.relationship}
                onChange={handleChange('primary')}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'nowrap',
                }}
              >
                <FormControlLabel
                  value="Father"
                  control={<Radio color="primary" />}
                  label="Father"
                />
                <FormControlLabel
                  value="Mother"
                  control={<Radio color="secondary" />}
                  label="Mother"
                />
                <FormControlLabel
                  value="Guardian"
                  control={<Radio color="default" />}
                  label="Guardian"
                />
              </RadioGroup>
            </FormControl>
            <TextField
              required
              error={validation.guardianEmail.error}
              helperText={validation.guardianEmail.message}
              InputLabelProps={{
                formlabelclasses: {
                  asterisk: styles.asterisk,
                },
              }}
              name="email"
              label="Email"
              value={primary.email || ''}
              className={styles.textField}
              fullWidth
              onChange={handleChange('primary')}
            />
            <br />
            <FormControl required className={styles.textField}>
              <InputLabel
                error={validation.guardianPhoneNumber.error}
                shrink
                formlabelclasses={{
                  asterisk: styles.asterisk,
                }}
              >
                Phone #
              </InputLabel>
              <Input
                error={validation.guardianPhoneNumber.error}
                helperText={'there is an error'}
                value={primary.phoneNumber || ''}
                onChange={handleChange('primary')}
                name="phoneNumber"
                inputComponent={PhoneNumberInput}
              />
              {validation.guardianPhoneNumber.error && (
                <FormHelperText error>
                  {validation.guardianPhoneNumber.message}
                </FormHelperText>
              )}
            </FormControl>
            <br />
            <TextField
              name="preferredLanguage"
              label="Preferred Language"
              value={primary.preferredLanguage}
              className={styles.textField}
              select
              onChange={handleChange('primary')}
            >
              {languages.map(language => (
                <MenuItem id={language} value={language}>
                  {language}
                </MenuItem>
              ))}
            </TextField>
            <br />
            <Text variant="caption">
              Both email and phone number are required for the primary guardian
            </Text>
          </Flex>
          <Flex grow={2} m="20px" style={{ maxWidth: '300px' }} column>
            <Text variant="h6">Secondary Guardian (optional)</Text>
            <TextField
              name="firstName"
              label="First Name"
              value={secondary.firstName || ''}
              className={styles.textField}
              fullWidth
              onChange={handleChange('secondary')}
            />
            <br />
            <TextField
              name="lastName"
              label="Last Name"
              value={props.secondary.lastName || ''}
              className={styles.textField}
              fullWidth
              onChange={handleChange('secondary')}
            />
            <br />
            <FormControl component="fieldset" className={styles.formControl}>
              <FormLabel component="legend">Relationship</FormLabel>
              <RadioGroup
                aria-label="relationship"
                name="relationship"
                className={styles.group}
                value={secondary.relationship}
                onChange={handleChange('secondary')}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'nowrap',
                }}
              >
                <FormControlLabel
                  value="Father"
                  control={<Radio color="primary" />}
                  label="Father"
                />
                <FormControlLabel
                  value="Mother"
                  control={<Radio color="secondary" />}
                  label="Mother"
                />
                <FormControlLabel
                  value="Guardian"
                  control={<Radio color="default" />}
                  label="Guardian"
                />
              </RadioGroup>
            </FormControl>
            <TextField
              name="email"
              label="Email"
              value={secondary.email || ''}
              className={styles.textField}
              fullWidth
              onChange={handleChange('secondary')}
            />
            <br />
            <FormControl className={styles.textField}>
              <InputLabel shrink>Phone #</InputLabel>
              <Input
                value={secondary.phoneNumber || ''}
                onChange={handleChange('secondary')}
                name="phoneNumber"
                inputComponent={PhoneNumberInput}
              />
            </FormControl>
            <br />
            <TextField
              name="preferredLanguage"
              label="Preferred Language"
              value={secondary.preferredLanguage}
              className={styles.textField}
              select
              onChange={handleChange('secondary')}
            >
              {languages.map(language => (
                <MenuItem id={language} value={language}>
                  {language}
                </MenuItem>
              ))}
            </TextField>
            <br />
          </Flex>
        </Flex>
      </form>
    </Flex>
  )
}

const styles = theme => ({
  menu: {
    width: '20%',
  },
  asterisk: {
    color: 'red',
  },
})

export default withStyles(styles)(GuardianForm)
