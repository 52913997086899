import React, { Component } from 'react'
import { Query } from 'react-apollo'
import { Redirect } from 'react-router-dom'

import { ErrorMessage } from '../../components'
import { formatTimeRange } from '../../utils/datetime'
import HomeTuteeTable from './HomeTuteeTable'
import { GET_TUTOR_SESSIONS } from './queries'
import DaySelector from './DaySelector'
import LoadingTable from './LoadingTable'

class HomeTuteeTableView extends Component {
  constructor(props) {
    super(props)
    const now = new Date()
    const startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const endDate = new Date(startDate.getTime() + 1000 * 60 * 60 * 24)

    this.state = {
      now,
      startDate,
      endDate,
      redirect: false,
      sorted: [
        {
          id: 'timeRange',
        },
      ],
    }
  }

  rowClick = rowInfo => {
    console.log('rowInfo', rowInfo.original)
    rowInfo &&
      this.setState({
        redirect: true,
        redirectTo: `/tutor_student/tutor-schedule/${rowInfo.original.studentId} ${rowInfo.original.classroomId}`,
      })
  }

  getDayEasy = (day, date) => {
    var weekday = new Array(7)
    weekday[0] = 'SUN'
    weekday[1] = 'MON'
    weekday[2] = 'TUE'
    weekday[3] = 'WED'
    weekday[4] = 'THU'
    weekday[5] = 'FRI'
    weekday[6] = 'SAT'
    let stop = date.indexOf(',')
    return weekday[day] + ' ' + date.substring(0, stop)
  }

  goPrev = () => {
    var yesterday = new Date(
      this.state.startDate.valueOf() - 1000 * 60 * 60 * 24
    )
    const new_startDate = new Date(
      yesterday.getFullYear(),
      yesterday.getMonth(),
      yesterday.getDate()
    )
    const new_endDate = new Date(this.state.startDate.valueOf())

    this.setState({
      startDate: new_startDate,
      endDate: new_endDate,
      now: new_startDate,
    })
  }

  goToday = () => {
    const now = new Date()
    const new_startDate = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate()
    )
    const new_endDate = new Date(new_startDate.getTime() + 1000 * 60 * 60 * 24)
    this.setState({
      startDate: new_startDate,
      endDate: new_endDate,
      now: new_startDate,
    })
  }

  goNext = () => {
    var tomorrow = new Date(
      this.state.startDate.valueOf() + 1000 * 60 * 60 * 24
    )
    const new_startDate = new Date(
      tomorrow.getFullYear(),
      tomorrow.getMonth(),
      tomorrow.getDate()
    )
    const new_endDate = new Date(new_startDate.valueOf() + 1000 * 60 * 60 * 24)

    this.setState({
      startDate: new_startDate,
      endDate: new_endDate,
      now: new_startDate,
    })
  }

  render() {
    const { redirect, redirectTo, sorted } = this.state
    if (redirect) {
      return <Redirect push to={redirectTo} />
    }

    const startDate = this.state.startDate.toISOString()
    const endDate = this.state.endDate.toISOString()
    return (
      <Query
        fetchPolicy={'cache-and-network'}
        query={GET_TUTOR_SESSIONS}
        variables={{
          filter: {
            status: 'SCHEDULED',
            startDateTime: {
              $gteDate: startDate,
              $lteDate: endDate,
            },
          },
        }}
      >
        {({ loading, error, data, refetch }) => {
          if (loading)
            return (
              <LoadingTable
                type={'Tutees'}
                goToday={this.goToday}
                goPrev={this.goPrev}
                goNext={this.goNext}
                startDate={this.state.startDate}
                getDayEasy={this.getDayEasy}
              />
            )
          if (error) return <ErrorMessage error={error} />

          this.tutorSessions = data.tutorSessions.map(tutorSession => {
            const {
              id,
              student,
              classroom,
              startDateTime,
              duration,
              tutor,
            } = tutorSession

            const startTime = new Date(startDateTime)
            const endTime = new Date(startTime.getTime() + duration * 60000)
            const tutorFirstName = tutor ? tutor.firstName : ''
            const tutorLastName = tutor ? tutor.lastName : ''
            const studentFirstName = student ? student.firstName : ''
            const studentLastName = student ? student.lastName : ''
            return {
              id: id,
              student: `${studentFirstName} ${studentLastName}`,
              code: classroom.code,
              classroomId: classroom.id,
              studentId: student.id,
              timeRange: formatTimeRange(startTime, endTime),
              tutor: `${tutorLastName}, ${tutorFirstName}`,
            }
          })

          return (
            <React.Fragment>
              <DaySelector
                type={'Tutees'}
                today={this.goToday}
                prev={this.goPrev}
                next={this.goNext}
                day={this.state.startDate}
                getDayEasy={this.getDayEasy}
              />
              <HomeTuteeTable
                classes={this.tutorSessions}
                onRowClick={this.rowClick}
                sorted={sorted}
                onFilteredChange={this.onFilteredChange}
                handleSingleCheckboxChange={this.handleSingleCheckboxChange}
                checked={this.state.checked}
              />
            </React.Fragment>
          )
        }}
      </Query>
    )
  }
}

export default HomeTuteeTableView
