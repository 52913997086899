import React from 'react'

import {
  DatePicker as NoKeyboardDatePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
  KeyboardTimePicker,
} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'

const DatePicker = props => {
  const handleDateChange = date => {
    props.onChange({ target: { name: props.name, value: date } })
  }
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      {props.disableKeyboard ? (
        <NoKeyboardDatePicker
          {...props}
          emptyLabel=""
          showtodaybutton="false"
          placeholder="mm/dd/yyyy"
          format="MM/DD/YYYY"
          onChange={handleDateChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
      ) : (
        <KeyboardDatePicker
          {...props}
          emptyLabel=""
          showtodaybutton="false"
          placeholder="mm/dd/yyyy"
          format="MM/DD/YYYY"
          onChange={handleDateChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
    </MuiPickersUtilsProvider>
  )
}

const DateTimePicker = props => {
  const handleDateChange = date => {
    props.onChange({ target: { name: props.name, value: date } })
  }
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDateTimePicker
        {...props}
        emptyLabel=""
        showTodayButton
        placeholder="MM/DD/YYYY hh:mm a"
        format="MM/DD/YYYY hh:mm a"
        onChange={handleDateChange}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </MuiPickersUtilsProvider>
  )
}

const TimePicker = props => {
  const handleTimeChange = date => {
    props.onChange({ target: { name: props.name, value: date } })
  }
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardTimePicker
        {...props}
        placeholder="__:__ AM"
        mask="__:__ _M"
        onChange={handleTimeChange}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </MuiPickersUtilsProvider>
  )
}
export { DatePicker, DateTimePicker, TimePicker }
