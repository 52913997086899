import React, { Component, Fragment } from 'react'
import { Mutation } from 'react-apollo'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextEditor,
  TextField,
} from '../components'

import { debug } from '../config'
import { SEND_EMAIL_TO_FAMILY_MEMBER } from '../queries'

class EmailButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      emailMessage: '',
      subject: '',
      open: false,
    }
  }

  handleChange = event => {
    const { name, value } = event.target
    this.setState({
      [name]: value,
    })
  }

  handleClickOpen = () => {
    this.setState({
      open: true,
    })
  }
  handleClose = () => {
    this.setState({
      open: false,
    })
  }

  render() {
    const { label, familyMemberIds, style, title } = this.props
    const { emailMessage, open, subject } = this.state
    return (
      <Fragment>
        <Button style={style} onClick={this.handleClickOpen}>
          {title}
        </Button>
        <Mutation mutation={SEND_EMAIL_TO_FAMILY_MEMBER}>
          {(sendEmail, { data }) => (
            <Dialog open={open} onClose={this.handleClose}>
              <DialogTitle id="form-dialog-title">{title}</DialogTitle>
              <DialogContent>
                <DialogContentText>{label}</DialogContentText>
                <TextField
                  name="subject"
                  label="Subject"
                  fullWidth
                  required
                  style={{ paddingBottom: '10px' }}
                  value={subject || ''}
                  onChange={this.handleChange}
                />
                <TextEditor
                  name="emailMessage"
                  value={emailMessage || ''}
                  onChange={this.handleChange}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleClose} color="primary">
                  Cancel
                </Button>
                <Button
                  onClick={async () => {
                    let emailReturn = []
                    if (
                      window.confirm(
                        'Are you sure you want to send this email?'
                      )
                    ) {
                      await Promise.all(
                        familyMemberIds.map(async familyMemberId => {
                          const sendReturn = await sendEmail({
                            variables: {
                              familyMemberId: familyMemberId,
                              subject: subject,
                              textMessage: emailMessage.replace(
                                /<[^>]*>?/gm,
                                ''
                              ),
                              htmlMessage: emailMessage,
                              testRecipient: debug ? 'dev@ardentlabs.io' : '',
                            },
                          })
                          const data = sendReturn.data.sendEmailToFamilyMember
                          emailReturn.push(
                            data.message === 'SUCCESS'
                              ? ` Sent to ${data.familyMember.email}`
                              : ` ${data.familyMember.email} ${data.message}`
                          )
                        })
                      )
                      alert(emailReturn)
                      this.handleClose()
                    }
                  }}
                >
                  Send
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </Mutation>
      </Fragment>
    )
  }
}
export default EmailButton
