import React, { useState, useEffect } from 'react'
import ReactTable from 'react-table'
import { formatTimeRange } from '../../utils/datetime'
import { CheckBoxIcon } from '../../components'

const preProcessData = classrooms => {
  return classrooms.map(classroom => {
    return {
      id: classroom.id,
      code: classroom.code,
      title: classroom.title.slice(
        classroom.title.indexOf('-') + 2,
        classroom.title.length
      ),
      enrollmentCount: classroom.enrollmentCount,
      day: classroom.meets,
      time: formatTimeRange(
        new Date(classroom.startTime),
        new Date(classroom.endTime)
      ),
      centerLocation: classroom.centerLocation.name,
      classId: classroom.id,
      submitted: <CheckBoxIcon color="primary" />,
      graded: <CheckBoxIcon color="primary" />,
      avg_grade: <CheckBoxIcon color="primary" />,
    }
  })
}

const GradeTable = props => {
  const [data, setData] = useState(preProcessData(props.classrooms))

  useEffect(() => {
    setData(preProcessData(props.classrooms))
  }, [props.classrooms])

  const columns = [
    {
      Header: 'Class Code',
      accessor: 'code',
      width: 100,
    },
    {
      Header: 'Class Title',
      accessor: 'title',
      width: 250,
    },
    {
      Header: 'Enrollments',
      accessor: 'enrollmentCount',
      style: { textAlign: 'right' },
      width: 100,
    },
    {
      Header: 'Day',
      accessor: 'day',
      width: 100,
    },
    {
      Header: 'Time',
      accessor: 'time',
      width: 200,
    },
    {
      Header: 'Location',
      accessor: 'centerLocation',
      width: 100,
    },
    {
      Header: 'Submitted',
      accessor: 'submitted',
      width: 100,
    },
    {
      Header: 'Graded',
      accessor: 'graded',
      width: 100,
    },
    {
      Header: 'Average Grade',
      accessor: 'avg_grade',
      width: 150,
    },
  ]
  return (
    <div align="left">
      <ReactTable
        columns={columns}
        data={data}
        filterable={true}
        className="-highlight"
        defaultSorted={[{ id: 'code' }]}
        style={{ fontSize: '10pt' }}
        defaultPageSize={25}
        getTdProps={(state, rowInfo, column, instance) => {
          return {
            onClick: (e, handleOriginal) => {
              if (column.Header !== undefined) {
                props.onRowClick(rowInfo)
              }
              if (handleOriginal) {
                handleOriginal()
              }
            },
          }
        }}
        getTheadProps={(state, rowInfo, column, instance) => {
          return {
            style: {
              fontWeight: 'bold',
              borderBottom: '1px solid #E5E5E5',
            },
          }
        }}
      />
    </div>
  )
}

export default GradeTable
