export const formatInstructorNames = instructors => {
  const instructor =
    instructors && instructors.find(instructor => !instructor.isAssistant)
  return instructor
    ? `${instructor.employee.firstName} ${instructor.employee.lastName}`
    : ''
}

export const isInstructor = (classroom, employeeId) => {
  for (const instructor of classroom.instructors) {
    if (instructor.employee.id === employeeId) return true
  }
  return false
}
