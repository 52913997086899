import React, { Component } from 'react'
import { Query } from 'react-apollo'
import { Redirect } from 'react-router-dom'
import { ErrorMessage } from '../../components'

import { formatTimeRange, convertToMDY } from '../../utils/datetime'
import { GET_CLASSROOMS } from './queries'
import HomeClassTable from './HomeClassTable'
import DaySelector from './DaySelector'
import LoadingTable from './LoadingTable'

class HomeClassTableView extends Component {
  constructor(props) {
    super(props)
    const now = new Date()
    const startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const endDate = new Date(startDate.getTime() + 1000 * 60 * 60 * 24)

    this.state = {
      now,
      startDate,
      endDate,
      redirect: false,
      sorted: [
        {
          id: 'time',
        },
      ],
    }
  }

  rowClick = rowInfo => {
    rowInfo &&
      this.setState({
        redirect: true,
        redirectTo: `/classroom/${rowInfo.original.id}`,
      })
  }

  getDayEasy = (day, date) => {
    var weekday = new Array(7)
    weekday[0] = 'SUN'
    weekday[1] = 'MON'
    weekday[2] = 'TUE'
    weekday[3] = 'WED'
    weekday[4] = 'THU'
    weekday[5] = 'FRI'
    weekday[6] = 'SAT'
    let stop = date.indexOf(',')
    return weekday[day] + ' ' + date.substring(0, stop)
  }

  goPrev = () => {
    var yesterday = new Date(
      this.state.startDate.valueOf() - 1000 * 60 * 60 * 24
    )
    const new_startDate = new Date(
      yesterday.getFullYear(),
      yesterday.getMonth(),
      yesterday.getDate()
    )
    const new_endDate = new Date(this.state.startDate.valueOf())

    this.setState({
      startDate: new_startDate,
      endDate: new_endDate,
      now: new_startDate,
    })
  }

  goToday = () => {
    const now = new Date()
    const new_startDate = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate()
    )
    const new_endDate = new Date(new_startDate.getTime() + 1000 * 60 * 60 * 24)
    this.setState({
      startDate: new_startDate,
      endDate: new_endDate,
      now: new_startDate,
    })
  }

  goNext = () => {
    var tomorrow = new Date(
      this.state.startDate.valueOf() + 1000 * 60 * 60 * 24
    )
    const new_startDate = new Date(
      tomorrow.getFullYear(),
      tomorrow.getMonth(),
      tomorrow.getDate()
    )
    const new_endDate = new Date(new_startDate.valueOf() + 1000 * 60 * 60 * 24)

    this.setState({
      startDate: new_startDate,
      endDate: new_endDate,
      now: new_startDate,
    })
  }

  render() {
    const { redirect, redirectTo, sorted } = this.state
    if (redirect) {
      return <Redirect push to={redirectTo} />
    }

    const now = this.state.startDate

    const theDay = now.getDay()
    const today = new Date(now.valueOf() + 1000 * 60 * 60 * 16).toISOString()
    return (
      <Query
        fetchPolicy={'cache-and-network'}
        query={GET_CLASSROOMS}
        variables={{
          filter: {
            status: 'OPENED',
            teachingStyle: 'BLENDED',
            meetsOnSunday: theDay === 0,
            meetsOnMonday: theDay === 1,
            meetsOnTuesday: theDay === 2,
            meetsOnWednesday: theDay === 3,
            meetsOnThursday: theDay === 4,
            meetsOnFriday: theDay === 5,
            meetsOnSaturday: theDay === 6,
            startDate: {
              $lteDate: today,
            },
            endDate: {
              $gteDate: today,
            },
          },
        }}
      >
        {({ loading, error, data, refetch }) => {
          if (loading)
            return (
              <LoadingTable
                type={'Classes'}
                goToday={this.goToday}
                goPrev={this.goPrev}
                goNext={this.goNext}
                startDate={this.state.startDate}
                getDayEasy={this.getDayEasy}
              />
            )
          if (error) return <ErrorMessage error={error} />

          this.classesFilteredByDayOfWeek = data.classrooms.map(classroom => {
            const {
              id,
              title,
              code,
              endDate,
              centerLocation,
              startDate,
              meets,
              enrollmentCount,
              startTime,
              endTime,
              instructorDisplayNames,
            } = classroom

            const instructorName = instructorDisplayNames
            const meetingTime = formatTimeRange(
              new Date(startTime),
              new Date(endTime)
            )
            const newEndDate = convertToMDY(endDate)
            return {
              id: id,
              title: title.slice(title.indexOf('-') + 2, title.length),
              code: code,
              centerLocation: centerLocation && centerLocation.name,
              startDate: startDate,
              endDate: newEndDate,
              day: meets,
              time: meetingTime,
              enrollmentCount: enrollmentCount,
              instructor: instructorName,
            }
          })

          return (
            <React.Fragment>
              <DaySelector
                type={'Classes'}
                today={this.goToday}
                prev={this.goPrev}
                next={this.goNext}
                day={this.state.startDate}
                getDayEasy={this.getDayEasy}
              />

              <HomeClassTable
                classes={this.classesFilteredByDayOfWeek}
                updateClasses={this.tableChangeHandler}
                onRowClick={this.rowClick}
                sorted={sorted}
                checked={this.state.checked}
              />
            </React.Fragment>
          )
        }}
      </Query>
    )
  }
}

export default HomeClassTableView
