import React, { useState, useEffect } from 'react'
import { s3Config } from '../../config'
import { defaultImageUrl } from '../../utils/defaultImage'

import { AttendeeHeader } from '../Attendance/AttendanceDialog'
import { mapStatusToLabel } from '../Attendance/utils'
import { StatusIcon } from '../Attendance/Icons'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material'

const SESSION_STATUS = ['ONTIME', 'ABSENT', 'LATE', 'EARLY', 'SCHEDULED']

const TutorSessionAttendanceDialog = ({
  tutorSession,
  student,
  open,
  handleTutorSessionChange,
  handleClose,
  handleSubmit,
}) => {
  const [image, setImage] = useState(student.image)
  useEffect(() => {
    if (!image) {
      const fileKeyRe = RegExp(`^${s3Config.url}/(\\S+)$`)
      const fileKey = fileKeyRe.exec(defaultImageUrl)[1]
      setImage(fileKey)
    }
  }, [image])

  return (
    <Dialog fullWidth={true} open={open} onClose={handleClose}>
      <DialogTitle>
        <AttendeeHeader student={student} setImage={setImage} image={image} />
        <Divider />
      </DialogTitle>
      <DialogContent>
        <Box column>
          <Stack direction="row" justifyContent="space-evenly">
            {SESSION_STATUS.map(status => {
              return (
                <MenuItem
                  name="status"
                  value={status}
                  key={status}
                  selected={status === tutorSession.status ? true : false}
                  onClick={e => handleTutorSessionChange(e, status)}
                >
                  <Stack direction="column" alignItems="center">
                    <StatusIcon status={status} />
                    {mapStatusToLabel(status)}
                  </Stack>
                </MenuItem>
              )
            })}
          </Stack>
          <TextField
            onChange={handleTutorSessionChange}
            name={'summary'}
            label="Summary"
            value={tutorSession.summary || ''}
            multiline
            minRows={5}
            maxRows={5}
            fullWidth={true}
            style={{ marginTop: '2em' }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleClose}>
          Canceled
        </Button>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default TutorSessionAttendanceDialog
