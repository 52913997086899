import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

import IssueTableView from './IssueTableView'

class IssueViewRouter extends Component {
  render() {
    const url = this.props.match.url
    return (
      <Switch>
        <Route path={url} component={IssueTableView} />
      </Switch>
    )
  }
}

export default IssueViewRouter
