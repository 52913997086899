import React from 'react'
import AsyncSelect from 'react-select/async'

import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Divider,
  Flex,
  TextField,
  Text,
} from '../../components'

const ReferralAddDialog = props => {
  const {
    dialog,
    referrer,
    referrerName,
    refereeState,
    handleCloseAddDialog,
    fetchFamilyAccounts,
    handleCreateReferral,
    setReferrer,
    setReferrerName,
    handleRefereeChange,
  } = props

  const { firstName, lastName, email, phoneNumber } = refereeState

  return (
    <Dialog
      open={dialog.open}
      onClose={handleCloseAddDialog}
      fullWidth={true}
      maxWidth={'sm'}
    >
      <DialogTitle>Create Referral</DialogTitle>
      <Divider style={{ width: '100%' }} />
      <DialogContent style={{ height: '280px' }}>
        <Flex flex={1} direction="column" justify="space-evenly">
          <Text variant="subtitle1" style={{ textAlign: 'center' }}>
            Choose Referrer Family
          </Text>
          <AsyncSelect
            autoFocus={!referrer}
            isLoading={!referrer}
            loadOptions={
              referrerName.length >= 2 &&
              (() =>
                new Promise(resolve => {
                  setTimeout(() => {
                    resolve(fetchFamilyAccounts())
                  }, 1000)
                }))
            }
            menuIsOpen={referrerName.length >= 2}
            placeholder={'Search for existing primary family member...'}
            onChange={option => setReferrer(option.value)}
            onInputChange={setReferrerName}
            inputValue={referrerName}
            value={
              referrer
                ? {
                    value: referrer,
                    label: `${referrer.primaryFamilyMember.firstName} ${referrer.primaryFamilyMember.lastName}`,
                  }
                : null
            }
          />
          <Divider
            style={{ width: '100%', marginBottom: '15px', marginTop: '15px' }}
          />
          <Flex flex={1} justify="space-between">
            <TextField
              name="firstName"
              label="Referee First Name"
              style={{ width: '48%' }}
              value={firstName}
              onChange={handleRefereeChange}
            />
            <TextField
              name="lastName"
              label="Referee Last Name"
              style={{ width: '48%' }}
              value={lastName}
              onChange={handleRefereeChange}
            />
          </Flex>
          <Flex flex={1} justify="space-between">
            <TextField
              name="email"
              label="Referee Email"
              value={email}
              onChange={handleRefereeChange}
              fullWidth
            />
          </Flex>
          <Flex flex={1} justify="space-between">
            <TextField
              name="phoneNumber"
              label="Referee Phone Number"
              value={phoneNumber}
              onChange={handleRefereeChange}
              fullWidth
            />
          </Flex>
          <Divider style={{ width: '100%', marginTop: '30px' }} />
        </Flex>
      </DialogContent>
      <DialogActions>
        <Button style={{ marginBottom: '1em' }} onClick={handleCreateReferral}>
          Create Referral
        </Button>
        <Button
          style={{ marginBottom: '1em' }}
          color={'secondary'}
          onClick={handleCloseAddDialog}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ReferralAddDialog
