import React from 'react'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import { Box } from '@mui/material'
import { dateFilterMethod } from '../../utils/datetime'
import { convertToYMDHM } from '../../utils/datetime'

const TutorEmployeeTable = ({
  checked,
  tutors,
  page,
  pages,
  pageSize,
  sorted,
  filtered,
  onRowClick,
  handleFilteredChange,
  handleFetchData,
  handlePageChange,
  updateTable,
}) => {
  const defaultSorted = [{ id: 'lastName' }]

  const columns = [
    {
      Cell: row => <input type="checkbox" checked={checked[row.original.id]} />,
      sortable: false,
      filterable: false,
      resizable: false,
      width: 28,
    },
    {
      Header: 'Status',
      accessor: 'status',
      width: 135,
      Cell: props => {
        if (props.value === 'ACTIVE') {
          return <span style={{ color: '#27AE60' }}>{'ACTIVE'}</span>
        }
        if (props.value === 'INACTIVE') {
          return <span style={{ color: '#EB5757' }}>{'INACTIVE'}</span>
        }
        return <span>{props.value}</span>
      },
      filterMethod: (filter, row) => {
        if (filter.value !== 'ALL') {
          return row[filter.id] === filter.value
        }
        return true
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: '100%' }}
          value={filter ? filter.value : 'ALL'}
        >
          <option value="ALL">ALL</option>
          <option value="ACTIVE">ACTIVE</option>
          <option value="INACTIVE">INACTIVE</option>
        </select>
      ),
    },
    {
      Header: 'Last Login',
      accessor: 'lastLogin',
      filterMethod: dateFilterMethod,
      Cell: props => <span>{props.value && convertToYMDHM(props.value)}</span>,
    },
    {
      Header: 'First Name',
      accessor: 'firstName',
    },
    {
      Header: 'Last Name',
      accessor: 'lastName',
      filterMethod: (filter, row) => {
        return (
          row[filter.id] &&
          row[filter.id].toLowerCase().startsWith(filter.value.toLowerCase())
        )
      },
    },
    {
      Header: 'Gender',
      accessor: 'gender',
      width: 66,
      Cell: props => (
        <span>
          {props.value === 'MALE' ? 'M' : props.value === 'FEMALE' ? 'F' : '-'}
        </span>
      ),
      filterMethod: (filter, row) => {
        if (filter.value === 'M') {
          return row[filter.id] === 'MALE'
        }
        if (filter.value === 'F') {
          return row[filter.id] === 'FEMALE'
        }
        return true
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: '100%' }}
          value={filter ? filter.value : 'ALL'}
        >
          <option value="ALL">ALL</option>
          <option value="MALE">M</option>
          <option value="FEMALE">F</option>
        </select>
      ),
    },
    {
      Header: 'Phone',
      accessor: 'phone',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Position',
      accessor: 'position',
    },
  ]

  return (
    <Box align="left">
      <ReactTable
        data={tutors}
        defaultFiltered={filtered}
        onFilteredChange={handleFilteredChange}
        onPageChange={handlePageChange}
        columns={columns}
        filterable={true}
        defaultSorted={defaultSorted}
        className="-highlight"
        style={{ fontSize: '10pt' }}
        defaultPageSize={25}
        manual
        page={page}
        pageSize={pageSize}
        pages={pages}
        onFetchData={handleFetchData}
        defaultFilterMethod={(filter, row) =>
          row[filter.id] &&
          row[filter.id]
            .toString()
            .toLowerCase()
            .includes(filter.value.toString().toLowerCase())
        }
        getTdProps={(state, rowInfo, column, instance) => {
          return {
            onClick: (e, handleOriginal) => {
              if (column.Header && column.Header !== 'Email') {
                onRowClick(rowInfo)
              }
              if (handleOriginal) {
                handleOriginal()
              }
            },
          }
        }}
      />
    </Box>
  )
}

export default TutorEmployeeTable
