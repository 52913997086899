import React, { Component } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '../../components'
import { FormControl, Input, InputAdornment, InputLabel } from '@mui/material'

import { withApollo } from 'react-apollo'
import {
  DO_AUTHORIZE_REFUND,
  GET_AUTHORIZE_TRANSACTION,
  GET_PAYMENTS,
} from './queries'

class AddRefundForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errorMsg: '',
      maxAmount: this.props.credit.balance,
      refundAmount: 0,
      loading: false,
    }
  }

  processRefund = async amount => {
    const { credit, client } = this.props

    const { data } = await client.query({
      query: GET_PAYMENTS,
      variables: { id: credit.invoice.id },
    })
    const payments = data.invoice.payments

    let refundRecorder = []

    let i = 0
    while (amount > 0 && i < payments.length) {
      const payment = payments[i]
      if (payment.method === 'AUTHORIZE') {
        // const detailData = await getTransaction(payment.identification)
        let transaction

        try {
          const { data } = await client.query({
            query: GET_AUTHORIZE_TRANSACTION,
            variables: { transactionId: payment.identification },
          })
          transaction = data.getAuthorizeNetTransaction
        } catch (e) {
          i += 1
          continue
        }

        if (transaction.settleAmount > 0) {
          const singleRefund = Math.min(amount, transaction.settleAmount)
          // const fourDig = detailData.transaction.payment.creditCard.cardNumber

          // const refundData = await doRefund(
          //   credit.creditNumber,
          //   payment.identification,
          //   fourDig,
          //   singleRefund
          // )

          let refundResult
          try {
            const { data } = await client.mutate({
              mutation: DO_AUTHORIZE_REFUND,
              variables: {
                amount: `${singleRefund}`,
                invoiceId: credit.invoice.id,
                transactionId: transaction.transactionId,
                creditCardEnding: transaction.creditCard.cardNumber,
              },
            })
            refundResult = data.refundAuthorizeNetTransaction
          } catch (e) {
            i += 1
            continue
          }

          if (refundResult.success) {
            amount -= singleRefund
            refundRecorder.push({
              creditId: this.props.credit.id,
              payee:
                transaction.billTo.firstName +
                ' ' +
                transaction.billTo.lastName,
              method: 'AUTHORIZE',
              identification: refundResult.transactionId,
              amount: singleRefund,
            })
          }
        }
      }

      i += 1
    }
    if (amount !== 0) {
      alert(`Amount was not able to be refunded, left: $${amount.toFixed(2)}`)
    }

    return { amount, refundRecorder }
  }

  handleSubmit = async () => {
    const { credit } = this.props
    const { maxAmount, refundAmount } = this.state

    if (refundAmount > maxAmount) {
      this.setState({ errorMsg: 'Unreasonable amount' })
      return
    }
    this.props.openSnack('Loading...', 'other', false)
    this.setState({ errorMsg: '', loading: true })

    const { amount: leftOver, refundRecorder } = await this.processRefund(
      refundAmount
    )

    const trueRefundAmount = refundAmount - leftOver

    await this.props.updateCredit(credit.id, {
      balance: credit.balance - trueRefundAmount,
    })

    await this.props.createRefund(refundRecorder)

    this.setState({ loading: false })
    this.props.openSnack('Success! Refreshing...', 'success', true)
    this.props.close()
  }

  render() {
    const { maxAmount, refundAmount, loading, errorMsg } = this.state
    return (
      <Dialog open={this.props.open} fullWidth={true} maxWidth={'sm'}>
        <DialogTitle>{'Add Refund'}</DialogTitle>
        <DialogContent>
          <div style={{ color: 'red' }}>{errorMsg}</div>
          <br />
          <div>{`Current Balance: $${maxAmount.toFixed(2)}`}</div>
          <FormControl
            fullWidth
            variant="standard"
            style={{ marginLeft: '0.7em', flex: 1 }}
          >
            <InputLabel htmlFor="standard-adornment-amount">
              Refund Amount
            </InputLabel>
            <Input
              id="standard-adornment-amount"
              value={refundAmount}
              onChange={event => {
                this.setState({
                  refundAmount: event.target.value,
                })
              }}
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
            />
          </FormControl>
        </DialogContent>
        <DialogActions style={{ paddingBottom: '10px', paddingRight: '10px' }}>
          <Button
            color="primary"
            onClick={this.handleSubmit}
            disabled={loading}
          >
            {'Create'}
          </Button>
          <Button
            onClick={this.props.close}
            color="secondary"
            disabled={loading}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withApollo(AddRefundForm)
