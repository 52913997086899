import gql from 'graphql-tag'

export const GET_EMPLOYEES = gql`
  query getEmployees($filter: Filters, $sort: Sorts, $page: Pagination) {
    employees(filter: $filter, sort: $sort, page: $page) {
      id
      status
      lastLogin
      firstName
      lastName
      gender
      workEmail
      personalEmail
      mobileNumber
      position
      isTutor
    }
  }
`

export const GET_TUTOR_EMPLOYEES = gql`
  query getEmployees {
    employees(
      filter: { isTutor: true, status: "ACTIVE" }
      sort: { lastName: 1 }
    ) {
      id
      status
      firstName
      lastName
    }
  }
`

export const GET_TUTOR_SESSIONS = gql`
  query getTutorSessions($filter: Filters, $sort: Sorts, $page: Pagination) {
    tutorSessions(filter: $filter, sort: $sort, page: $page) {
      id
      classroom {
        id
        courseTitle
        courseCode
      }
      tutorAssignments {
        id
      }
      student {
        id
        firstName
        lastName
        image
        imageUrl
        school
        grade
      }
      tutor {
        id
        firstName
        lastName
      }
      startDateTime
      duration
      summary
      status
      order
      overallGrade
    }
  }
`

export const COUNT_EMPLOYEES = gql`
  query countEmployees($filter: Filters) {
    countEmployees(filter: $filter)
  }
`

export const UPDATE_EMPLOYEE = gql`
  mutation updateEmployee($id: ID!, $input: UpdateEmployeeInput!) {
    updateEmployee(id: $id, input: $input) {
      id
      isTutor
    }
  }
`

export const UPDATE_TUTOR_SESSION = gql`
  mutation UpdateTutorSession($id: ID!, $input: UpdateTutorSessionInput!) {
    updateTutorSession(id: $id, input: $input) {
      id
    }
  }
`

export const ARCHIVE_TUTOR_SESSION = gql`
  mutation ArchiveTutorSession($id: ID!) {
    archiveTutorSession(id: $id) {
      id
    }
  }
`
