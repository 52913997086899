import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { Radio } from '../../components'
import { formatYMDUTC } from '../../utils/datetime'
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
})

export default function CourseTable(props) {
  const classes = useStyles()
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Selected</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Location</TableCell>
            <TableCell>Start Date</TableCell>
            <TableCell>End Date</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Tuition</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.classes.map(row => (
            <TableRow
              hover
              key={`${row.code}`}
              onClick={() => {
                props.setCode(
                  row.code,
                  row.id,
                  row.tuitionCents / 100,
                  row.tuitionCents / 100,
                  row.earlyBirdtuitionCents / 100
                )
              }}
            >
              <TableCell component="th" scope="row">
                <Radio
                  checked={
                    props.chosenClass
                      ? row.code === props.chosenClass.code
                      : false
                  }
                />
              </TableCell>
              <TableCell>{row.title}</TableCell>
              <TableCell>{row.status}</TableCell>
              <TableCell>{row.centerLocation.name}</TableCell>
              <TableCell>{formatYMDUTC(row.startDate)}</TableCell>
              <TableCell>{formatYMDUTC(row.endDate)}</TableCell>
              <TableCell>{row.teachingStyle}</TableCell>
              <TableCell>{'$' + (row.tuitionCents / 100).toFixed(2)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
