import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'

import {
  Button,
  Divider,
  Flex,
  SidebarText,
  withStyles,
  DatePicker,
} from '../../components'
import Cookies from 'js-cookie'
import { idName } from '../../config'
import { sideBarStyles } from '../../utils/style'

class PaymentSidebar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      redirect: false,
      sidebar: props.sidebar,
    }
  }

  componentDidMount() {
    const employeeId = Cookies.get(idName)
    this.setState({
      enableArchive: employeeId === '7460b01e-59bc-4e0b-aac5-44e423aa46d7',
    })
  }

  addPayment = () => {
    this.setState({
      redirect: true,
      redirectTo: '/payment/receive-payment',
    })
  }

  countPayments = (targetValue, field) => {
    const { payments } = this.props
    const paymentsCount = payments.reduce((accumulator, payment) => {
      return accumulator + (payment[field] === targetValue ? 1 : 0)
    }, 0)
    return paymentsCount
  }

  countValues = targetField => {
    const { payments } = this.props
    let result = {}

    payments.forEach(element => {
      let elementValue =
        element[targetField] == null ? 'UNKNOWN' : element[targetField]
      result[elementValue] = result[elementValue] ? result[elementValue] + 1 : 1
    })
    return result
  }

  render() {
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirectTo} />
    }

    const {
      classes,
      payments,
      paymentQueryFilter,
      applyDateFilter,
      clearDateFilter,
    } = this.props

    const payMeths = this.countValues('method')

    return (
      <Flex
        column
        grow={0}
        shrink={0}
        overflow="hidden"
        p="10px"
        style={{
          width: '15.38%',
          borderRight: `1px #aaaaaa solid`,
          maxWidth: '200px',
          overflowX: undefined,
          flexShrink: 0,
          textAlign: 'left',
          backgroundColor: '#e5e5e5',
        }}
      >
        <Flex column grow={1} justify="space-between">
          <Flex column>
            <Button
              aria-label="AddInvoice"
              onClick={this.addPayment}
              disabled={true}
            >
              Receive Payment
            </Button>
            <SidebarText
              classes={{ root: classes.headline }}
              title="Detail View"
            />
            <SidebarText
              classes={{ root: classes.subheadingOne }}
              title="Filtered total:"
              value={payments.length}
            />
            <Divider />
            <SidebarText
              classes={{ root: classes.subheadingOne }}
              title="Pay Methods"
            />
            {Object.keys(payMeths).map(key => (
              <SidebarText
                key={key}
                classes={{ root: classes.subheadingTwo }}
                title={key}
                value={payMeths[key]}
              />
            ))}

            <Button
              color="secondary"
              onClick={this.props.archiveCheckedHandler}
              disabled={!this.state.enableArchive}
            >
              Archive selected
            </Button>
            <br />
            <DatePicker
              fullWidth
              name="startDate"
              label="Start Date"
              value={this.state.sidebar.startDate}
              onChange={e =>
                this.setState({
                  sidebar: {
                    ...this.state.sidebar,
                    startDate: e.target.value.toISOString(),
                  },
                })
              }
              required
            />
            <br />
            <DatePicker
              fullWidth
              name="endDate"
              label="End Date"
              value={this.state.sidebar.endDate}
              onChange={e =>
                this.setState({
                  sidebar: {
                    ...this.state.sidebar,
                    endDate: e.target.value.toISOString(),
                  },
                })
              }
              required
            />
            <br />
            <Button onClick={() => applyDateFilter(this.state.sidebar)}>
              Apply Date Filter
            </Button>
            <br />
            <Button onClick={() => clearDateFilter()}>Clear Date Filter</Button>
            <br />
            <Button onClick={this.props.toggleShowUnreconciled}>
              {paymentQueryFilter.reconciled === false
                ? 'Show All'
                : 'Show Unreconciled'}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    )
  }
}

export default withStyles(sideBarStyles)(PaymentSidebar)
