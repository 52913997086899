import React, { Component } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import '../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import htmlToDraft from 'html-to-draftjs'

class TextEditor extends Component {
  constructor(props) {
    super(props)
    const html = props.value
    const contentBlock = htmlToDraft(html)
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    )
    const editorState = EditorState.createWithContent(contentState)
    this.state = {
      editorState: editorState,
    }
  }

  onEditorStateChange = editorState => {
    this.setState({
      editorState,
    })
    //creating 'fake' event object
    this.props.onChange({
      target: {
        name: this.props.name,
        value: draftToHtml(convertToRaw(editorState.getCurrentContent())),
      },
    })
  }

  render() {
    const { editorState } = this.state
    return (
      <Editor
        readOnly={this.props.readOnly}
        editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={this.onEditorStateChange}
      />
    )
  }
}

export default TextEditor
