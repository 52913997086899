import React, { Fragment } from 'react'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import moment from 'moment-timezone'

import {
  ErrorMessage,
  Flex,
  LoadingMessage,
  MenuItem,
  TextField,
  FormControlLabel,
  Checkbox,
} from './'
import withStyles from './Styles'
import { expansionBarColor } from '../config'
import { convertToMDY, formatDayTimeRange } from '../utils/datetime'

function isLessonDisabled(lesson) {
  const previousWeek = moment().subtract(1, 'week')
  const startOn = moment(lesson.startOn)
  // remove false check in the future if we want to disable lessons
  return false && startOn < previousWeek
}

const MakeupForm = props => {
  const styles = props.classes
  const {
    fromClasses,
    fromMakeupDetail,
    handleChange,
    makeup,
    readOnly,
    showCreatedBy,
    toClasses,
    sendConfirmation,
    toggleConfirmation,
  } = props
  const {
    createdBy,
    fromClassId,
    fromLessonId,
    notes,
    status,
    toLessonId,
    toClassId,
  } = makeup

  return (
    <Flex
      column
      style={{
        align: 'center',
        flexGrow: 1,
      }}
    >
      <form id="addMakeupForm">
        <Flex justify="space-evenly">
          <Flex grow={2} m="20px" style={{ maxWidth: '400px' }} column>
            <TextField
              required
              InputProps={{
                readOnly: readOnly,
              }}
              name="fromClassId"
              label="From Class"
              value={fromClassId || ''}
              className={styles.textField}
              select
              onChange={handleChange}
              disabled={readOnly || fromMakeupDetail}
              SelectProps={{
                MenuProps: {
                  className: styles.menu,
                },
              }}
              InputLabelProps={{
                formlabelclasses: {
                  asterisk: styles.asterisk,
                },
                shrink: !!fromClassId && fromClassId !== undefined,
              }}
            >
              {fromClasses.map(fromClass => (
                <MenuItem key={fromClass.id} value={fromClass.id}>
                  {fromClass.title}
                </MenuItem>
              ))}
            </TextField>
            {fromClassId && <br />}
            {fromClassId && (
              <Query
                query={GET_LESSONS_FROM_CLASS}
                variables={{ id: fromClassId }}
              >
                {({ loading, error, data }) => {
                  if (loading) return <LoadingMessage />
                  if (error) return <ErrorMessage error={error} />

                  const lessonsCopy = [...data.classroom.lessons]

                  const fromLessons = lessonsCopy.sort(
                    (a, b) => a.lessonPlan.order - b.lessonPlan.order
                  )

                  const fromLessonChoices = fromLessons.map(lesson => {
                    return {
                      key: lesson.id,
                      value: lesson,
                    }
                  })

                  const filteredToClasses = toClasses.filter(
                    toClass => toClass.id !== fromClassId
                  )
                  if (
                    fromLessonId &&
                    status !== 'ABSENT' &&
                    filteredToClasses.length === 0
                  ) {
                    handleChange({
                      target: { name: 'status', value: 'ABSENT' },
                    })
                  }
                  return (
                    <Fragment>
                      <TextField
                        required
                        InputProps={{
                          readOnly: readOnly,
                        }}
                        name="fromLessonId"
                        label="From Lesson"
                        value={fromLessonId || ''}
                        className={styles.textField}
                        select
                        onChange={handleChange}
                        disabled={readOnly || fromMakeupDetail}
                        SelectProps={{
                          MenuProps: {
                            className: styles.menu,
                          },
                        }}
                        InputLabelProps={{
                          formlabelclasses: {
                            asterisk: styles.asterisk,
                          },
                          shrink: !!fromLessonId && fromLessonId !== undefined,
                        }}
                      >
                        {fromLessonChoices.map(lessonChoice => (
                          <MenuItem
                            key={lessonChoice.key}
                            value={lessonChoice.key}
                            disabled={isLessonDisabled(lessonChoice.value)}
                          >
                            {`${
                              lessonChoice.value.lessonPlan.order
                            }. ${convertToMDY(lessonChoice.value.startOn).slice(
                              0,
                              5
                            )}, ${formatDayTimeRange(
                              lessonChoice.value.startOn,
                              lessonChoice.value.endOn
                            )}`}
                          </MenuItem>
                        ))}
                      </TextField>
                      {fromLessonId && filteredToClasses.length !== 0 && (
                        <Fragment>
                          <br />
                          <TextField
                            required={status !== 'ABSENT' ? true : false}
                            InputProps={{
                              readOnly: readOnly,
                            }}
                            name="toClassId"
                            label="To Class"
                            value={toClassId || ''}
                            className={styles.textField}
                            select
                            onChange={handleChange}
                            disabled={
                              readOnly ||
                              status === 'ATTENDED' ||
                              status === 'ABSENT' ||
                              status === 'POSTCLASS' ||
                              fromMakeupDetail
                            }
                            SelectProps={{
                              MenuProps: {
                                className: styles.menu,
                              },
                            }}
                            InputLabelProps={{
                              formlabelclasses: {
                                asterisk: styles.asterisk,
                              },
                              shrink: toClassId,
                            }}
                          >
                            {filteredToClasses.map(toClass => (
                              <MenuItem key={toClass.id} value={toClass.id}>
                                {toClass.title}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Fragment>
                      )}
                      <br />
                      {toClassId && (
                        <Query
                          query={GET_LESSONS_FROM_CLASS}
                          variables={{ id: toClassId }}
                        >
                          {({ loading, error, data }) => {
                            if (loading) return <LoadingMessage />
                            if (error) return <ErrorMessage error={error} />
                            const fromLesson = fromLessons.find(
                              lesson => fromLessonId === lesson.id
                            )
                            const toLesson = data.classroom.lessons.find(
                              lesson =>
                                lesson.lessonPlan.order ===
                                fromLesson.lessonPlan.order
                            )
                            if (!toLessonId || toLessonId !== toLesson.id)
                              handleChange({
                                target: {
                                  name: 'toLessonId',
                                  value: toLesson.id,
                                },
                              })
                            return (
                              <TextField
                                required={status !== 'ABSENT' ? true : false}
                                InputProps={{ readOnly: readOnly }}
                                name="toLessonId"
                                label="To Lesson"
                                value={toLessonId || ''}
                                className={styles.textField}
                                onChange={handleChange}
                                select
                                disabled={
                                  readOnly ||
                                  status === 'ATTENDED' ||
                                  status === 'ABSENT' ||
                                  status === 'POSTCLASS' ||
                                  fromMakeupDetail
                                }
                                SelectProps={{
                                  MenuProps: {
                                    className: styles.menu,
                                  },
                                }}
                                InputLabelProps={{
                                  formlabelclasses: {
                                    asterisk: styles.asterisk,
                                  },
                                  shrink: toLessonId,
                                }}
                              >
                                <MenuItem key={toLesson.id} value={toLesson.id}>
                                  {`${
                                    toLesson.lessonPlan.order
                                  }. ${convertToMDY(toLesson.startOn).slice(
                                    0,
                                    5
                                  )}, ${formatDayTimeRange(
                                    toLesson.startOn,
                                    toLesson.endOn
                                  )}`}
                                </MenuItem>
                              </TextField>
                            )
                          }}
                        </Query>
                      )}
                    </Fragment>
                  )
                }}
              </Query>
            )}
          </Flex>
          <Flex grow={2} m={'20px'} style={{ maxWidth: '400px' }} column>
            <TextField
              required
              InputProps={{
                readOnly: readOnly,
              }}
              name="status"
              label="Status"
              value={status || ''}
              className={styles.textField}
              select
              onChange={handleChange}
              disabled={readOnly}
              SelectProps={{
                MenuProps: {
                  className: styles.menu,
                },
              }}
              InputLabelProps={{
                formlabelclasses: {
                  asterisk: styles.asterisk,
                },
                shrink: !!status && status !== undefined,
              }}
            >
              {['Scheduled', 'Attended', 'Absent'].map(option => (
                <MenuItem
                  key={option}
                  value={
                    option === 'Attended'
                      ? 'ATTENDED'
                      : option === 'Absent'
                      ? 'ABSENT'
                      : option.toUpperCase().replace(/ /g, '_')
                  }
                >
                  {option}
                </MenuItem>
              ))}
            </TextField>
            {showCreatedBy && <br />}
            {showCreatedBy && (
              <TextField
                InputProps={{
                  readOnly: true,
                }}
                id="createdBy"
                label="Created By"
                value={
                  (createdBy &&
                    `${createdBy.firstName} ${createdBy.lastName}`) ||
                  ''
                }
                className={styles.textField}
                InputLabelProps={{
                  shrink: !!createdBy && createdBy !== undefined,
                }}
              />
            )}
            <br />
            <TextField
              name="notes"
              label="Notes"
              InputProps={{
                readOnly: props.readOnly,
              }}
              multiline
              maxRows="4"
              value={notes || ''}
              className={styles.textField}
              onChange={handleChange}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={sendConfirmation}
                  onChange={toggleConfirmation}
                  color="primary"
                />
              }
              style={{ marginTop: '1em' }}
              label="Send makeup confirmation"
            />
          </Flex>
        </Flex>
      </form>
    </Flex>
  )
}

const styles = theme => ({
  menu: {
    width: '20%',
  },
  expSummary: {
    backgroundColor: expansionBarColor,
  },
  asterisk: {
    color: 'red',
  },
})

const GET_LESSONS_FROM_CLASS = gql`
  query getClassroom($id: ID!) {
    classroom(id: $id) {
      id
      centerLocation {
        id
        name
      }
      lessons(filter: { lessonPlan: { lessonPlanType: "INSTRUCTION" } }) {
        id
        startOn
        endOn
        lessonPlan {
          id
          order
          title
        }
      }
    }
  }
`

export default withStyles(styles)(MakeupForm)
