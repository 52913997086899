import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'

import {
  Button,
  Divider,
  Flex,
  SidebarText,
  withStyles,
} from '../../components'

import { sideBarStyles } from '../../utils/style'

class InvoiceSidebar extends Component {
  constructor(props) {
    super(props)
    this.state = { redirect: false }
  }

  render() {
    const { payments, balance } = this.props

    if (this.state.redirect) {
      return <Redirect push to={this.state.redirectTo} />
    }
    return (
      <Flex
        column
        p="10px"
        style={{
          width: '15.38%',
          borderRight: `1px #aaaaaa solid`,
          maxWidth: '200px',
          overflowX: undefined,
          textAlign: 'left',
          backgroundColor: '#e5e5e5',
        }}
      >
        <Flex column grow={1} justify="space-between">
          <Flex column>
            <Button aria-label="AddClass" onClick={this.props.addClass}>
              Add class
            </Button>
            <br />
            <Button aria-label="AddOffer" onClick={this.props.addOffer}>
              Add Offer
            </Button>
            <br />
            <Button
              aria-label="ApplyDiscount"
              onClick={this.props.applyDiscount}
            >
              Apply discount
            </Button>
            <br />
            <Button
              aria-label="AddPayment"
              onClick={this.props.addPayment}
              disabled={this.props.addPaymentDisabled || balance === 0}
            >
              Add payment
            </Button>
            <br />
            <Button
              aria-label="Credit"
              onClick={this.props.addRefund}
              disabled={payments.length === 0 || balance > 0}
            >
              Issue Credit
            </Button>

            {this.props.showRefundButton && <br />}
            {this.props.showRefundButton && (
              <Button aria-label="Refund" onClick={this.props.addPayment}>
                Refund
              </Button>
            )}

            <br />
            <Button
              aria-label="Enroll"
              onClick={() => this.props.enrollAll(this.props.client)}
            >
              Enroll
            </Button>

            <Divider />
            <SidebarText
              classes={{ root: this.props.classes.subheadingTwo }}
              title={'Balance'}
              style={{ color: this.props.currentCredit <= 0 ? 'green' : 'red' }}
              value={
                this.props.currentCredit
                  ? `$${Math.abs(this.props.currentCredit).toFixed(2)}`
                  : '$0.00'
              }
            />
            <br />
            <Button
              aria-label="Abandon"
              onClick={() => this.props.updateInvoice({ status: 'ABANDONED' })}
            >
              Abandon
            </Button>
          </Flex>
        </Flex>
      </Flex>
    )
  }
}

export default withStyles(sideBarStyles)(InvoiceSidebar)
