import React, { Component } from 'react'
import { Query } from 'react-apollo'
import Composer from 'react-composer'

import CreateOpportunityFromStudent from './CreateOpportunityFromStudent'
import AddOpportunityView from './AddOpportunityView'
import OpportunityDetailView from './OpportunityDetailView'
import OpportunityTableView from './OpportunityTableView'

import { Route, Switch } from 'react-router-dom'
import {
  GET_OPPORTUNITY_STAGE_OPTIONS_QUERY,
  GET_OPPORTUNITY_STATUS_OPTIONS_QUERY,
} from './queries'
import { LoadingMessage, ErrorMessage } from '../../components'
import { GET_LANGUAGE_PREFERENCE_OPTIONS_QUERY } from '../FamilyView/queries'

class OpportunityView extends Component {
  render() {
    const { url } = this.props.match
    return (
      <Composer
        components={[
          <Query query={GET_LANGUAGE_PREFERENCE_OPTIONS_QUERY}>
            {function() {
              return ''
            }}
          </Query>,
          <Query query={GET_OPPORTUNITY_STATUS_OPTIONS_QUERY}>
            {function() {
              return ''
            }}
          </Query>,
          <Query query={GET_OPPORTUNITY_STAGE_OPTIONS_QUERY}>
            {function() {
              return ''
            }}
          </Query>,
        ]}
      >
        {([
          {
            loading: languageLoading,
            error: languageError,
            data: languageData,
          },
          { loading: statusLoading, error: statusError, data: statusData },
          { loading: stageLoading, error: stageError, data: stageData },
        ]) => {
          if (languageLoading || statusLoading || stageLoading)
            return <LoadingMessage />
          if (languageError || statusError || stageError)
            return (
              <ErrorMessage
                error={languageError || statusError || stageError}
              />
            )
          const stages = stageData.__type.enumValues.map(({ name }) => name)
          const statuses = statusData.__type.enumValues.map(({ name }) => name)
          const languages = languageData.__type.enumValues.map(
            ({ name }) => name
          )
          return (
            <Switch>
              <Route
                path={`${url}/create-opportunity-from-student/*`}
                component={CreateOpportunityFromStudent}
              />
              <Route
                path={`${url}/add-new-opportunity`}
                render={props => (
                  <AddOpportunityView {...props} languages={languages} />
                )}
              />
              <Route
                path={`${url}/*`}
                render={props => (
                  <OpportunityDetailView
                    {...props}
                    stages={stages}
                    statuses={statuses}
                    languages={languages}
                  />
                )}
              />
              <Route
                path={url}
                render={props => (
                  <OpportunityTableView
                    {...props}
                    stages={stages}
                    statuses={statuses}
                  />
                )}
              />
            </Switch>
          )
        }}
      </Composer>
    )
  }
}

export default OpportunityView
