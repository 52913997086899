import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'

import {
  Button,
  Divider,
  Flex,
  SidebarText,
  withStyles,
} from '../../components'
import Cookies from 'js-cookie'
import { idName } from '../../config'
import { sideBarStyles } from '../../utils/style'

class CreditSidebar extends Component {
  constructor(props) {
    super(props)
    this.state = { redirect: false }
  }

  componentDidMount() {
    const employeeId = Cookies.get(idName)
    this.setState({
      enableArchive: employeeId === '7460b01e-59bc-4e0b-aac5-44e423aa46d7',
    })
  }

  openingBalance = () => {
    this.setState({
      redirect: true,
      redirectTo: '/credit/opening-balance',
    })
  }

  addCredits = () => {
    this.setState({
      redirect: true,
      redirectTo: '/credit/add-credit',
    })
  }

  referral = () => {
    this.setState({
      redirect: true,
      redirectTo: '/credit/referral',
    })
  }

  countCredits = (targetValue, field) => {
    const { credits } = this.props
    const creditsCount = credits.reduce((accumulator, credit) => {
      return accumulator + (credit[field] === targetValue ? 1 : 0)
    }, 0)
    return creditsCount
  }

  countValues = targetField => {
    const { credits } = this.props
    let result = {}

    credits.forEach(element => {
      let elementValue =
        element[targetField] == null ? 'UNKNOWN' : element[targetField]
      result[elementValue] = result[elementValue] ? result[elementValue] + 1 : 1
    })
    return result
  }

  render() {
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirectTo} />
    }

    const { classes, credits } = this.props

    const status = this.countValues('status')
    const creditType = this.countValues('creditType')

    return (
      <Flex
        column
        grow={0}
        shrink={0}
        overflow="hidden"
        p="10px"
        style={{
          width: '15.38%',
          borderRight: `1px #aaaaaa solid`,
          maxWidth: '200px',
          overflowX: undefined,
          flexShrink: 0,
          textAlign: 'left',
          backgroundColor: '#e5e5e5',
        }}
      >
        <Flex column grow={1} justify="space-between">
          <Flex column>
            <Button aria-label="OpeningBalance" onClick={this.openingBalance}>
              Opening Balance
            </Button>
            <br />
            <Button aria-label="AddCredit" onClick={this.addCredits}>
              Add Credit
            </Button>
            <br />
            {/* <Button aria-label="Referral" onClick={this.referral}>
              Referral
            </Button> */}
            <SidebarText
              classes={{ root: classes.headline }}
              title="Detail View"
            />
            <SidebarText
              classes={{ root: classes.subheadingOne }}
              title="Filtered total:"
              value={credits.length}
            />
            <Divider />
            <SidebarText
              classes={{ root: classes.subheadingOne }}
              title="Status"
            />
            {Object.keys(status).map(key => (
              <SidebarText
                key={key}
                classes={{ root: classes.subheadingTwo }}
                title={key}
                value={status[key]}
              />
            ))}
            <Divider />
            <SidebarText
              classes={{ root: classes.subheadingOne }}
              title="Credit Type"
            />
            {Object.keys(creditType).map(key => (
              <SidebarText
                key={key}
                classes={{ root: classes.subheadingTwo }}
                title={key}
                value={creditType[key]}
              />
            ))}
            <Divider />

            <Button
              color="secondary"
              onClick={this.props.archiveCheckedHandler}
              disabled={!this.state.enableArchive}
            >
              Archive selected
            </Button>
          </Flex>
        </Flex>
      </Flex>
    )
  }
}

export default withStyles(sideBarStyles)(CreditSidebar)
