import React, { Component } from 'react'
import { withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import PropTypes from 'prop-types'
import AsyncSelect from 'react-select/async'

import {
  Flex,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Text,
} from './'

import withStyles from './Styles'

class ChooseOpportunityPanel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentValue: '',
      selectedOpportunity: null,
    }
  }

  changeSelectedOpportunity = value => {
    this.setState({
      selectedOpportunity: value,
    })
    this.props.changeSelectedOpportunity(value)
  }

  matchesOpportunity = opportunity => {
    const { firstName, lastName } = this.state.selectedOpportunity.student
    const {
      firstName: otherFirstName,
      lastName: otherLastName,
    } = opportunity.student

    return firstName === otherFirstName && lastName === otherLastName
  }

  getOpportunities = async () => {
    const { client } = this.props
    const { currentValue } = this.state
    const {
      data: { opportunities },
    } = await client.query({
      query: GET_OPPORTUNITIES,
      variables: {
        filter: {
          status: { $match: 'OPEN' },
          student: { $name: currentValue },
        },
        sort: {
          student: {
            firstName: 1,
            lastName: 2,
          },
        },
        page: {
          limit: 8,
        },
      },
    })

    this.setState({
      opportunityStudents: opportunities,
    })

    return opportunities.map(opportunity => {
      const { firstName, lastName } = opportunity.student
      return {
        value: opportunity,
        label: `${firstName} ${lastName}`,
      }
    })
  }

  handleInputChange = value => {
    this.setState({
      currentValue: value,
    })
  }

  render() {
    const { selectedOpportunity } = this.props
    const { currentValue, opportunityStudents } = this.state

    return (
      <Flex
        column
        style={{
          align: 'center',
          flexGrow: 1,
        }}
      >
        <Text variant="subtitle1" align="center">
          Choose an opportunity*
        </Text>
        <br />
        <AsyncSelect
          autoFocus={!selectedOpportunity}
          isLoading={!selectedOpportunity}
          loadOptions={currentValue.length >= 2 && this.getOpportunities}
          menuIsOpen={currentValue.length >= 2}
          placeholder={'Search...'}
          onChange={option => this.changeSelectedOpportunity(option.value)}
          onInputChange={this.handleInputChange}
          inputValue={currentValue}
          value={
            selectedOpportunity
              ? {
                  value: selectedOpportunity,
                  label: `${selectedOpportunity.student.firstName} ${selectedOpportunity.student.lastName}`,
                }
              : null
          }
        />
        <br />
        {selectedOpportunity && (
          <div>
            <Text variant="subtitle1">Search Results</Text>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>First Name</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell>Session</TableCell>
                  <TableCell>Stage</TableCell>
                  <TableCell>Notes</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {opportunityStudents.map(opportunity => {
                  if (this.matchesOpportunity(opportunity)) {
                    const { firstName, lastName } = opportunity.student
                    const { id, session, stage, notes } = opportunity
                    return (
                      <TableRow
                        hover
                        onClick={() =>
                          this.changeSelectedOpportunity(opportunity)
                        }
                        selected={id === selectedOpportunity.id}
                        key={id}
                      >
                        <TableCell>{firstName}</TableCell>
                        <TableCell>{lastName}</TableCell>
                        <TableCell>{session}</TableCell>
                        <TableCell>{stage}</TableCell>
                        <TableCell>{notes}</TableCell>
                      </TableRow>
                    )
                  }
                  return null
                })}
              </TableBody>
            </Table>
          </div>
        )}
      </Flex>
    )
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  input: {
    display: 'flex',
    padding: 0,
  },
  valueContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  noOptionsMessage: {
    fontSize: 16,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
})

const GET_OPPORTUNITIES = gql`
  query getOpportunities($filter: Filters, $sort: Sorts, $page: Pagination) {
    opportunities(filter: $filter, sort: $sort, page: $page) {
      id
      status
      student {
        id
        firstName
        lastName
      }
      session
      stage
      notes
    }
  }
`

ChooseOpportunityPanel.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withApollo(withStyles(styles)(ChooseOpportunityPanel))
