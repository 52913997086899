import React, { useState, useMemo } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Divider,
  Flex,
  Text,
} from '../../components'
import { s3Config } from '../../config'
import { APPROVE_IMAGE } from './queries'
import { GET_STUDENT } from '../../queries'

const StudentApproveImageDialog = props => {
  const { client, dialog, student, handleCloseDialog } = props
  const [oldImage, setOldImage] = useState('')
  const [oldImageUrl, setOldImageUrl] = useState('')
  const [newImage, setNewImage] = useState('')
  const [newImageUrl, setNewImageUrl] = useState('')

  useMemo(() => {
    setOldImage(student.image)
    setNewImage(student.newImage)
    setOldImageUrl(`${s3Config.url}/${oldImage}`)
    setNewImageUrl(`${s3Config.url}/${newImage}`)
  }, [oldImage, newImage, student])

  const handleApproveImage = async () => {
    await client.mutate({
      mutation: APPROVE_IMAGE,
      variables: {
        studentId: student.id,
      },
      refetchQueries: [
        {
          query: GET_STUDENT,
          variables: { id: student.id },
        },
      ],
    })
    handleCloseDialog()
  }

  return (
    <Dialog
      open={dialog.open}
      onClose={handleCloseDialog}
      fullWidth={true}
      maxWidth={'sm'}
    >
      <DialogTitle>Approve Student Image</DialogTitle>
      <Divider style={{ width: '100%' }} />
      <DialogContent>
        <Flex
          justify={'space-evenly'}
          style={{ textAlign: 'center', align: 'center' }}
        >
          {oldImage && oldImageUrl && (
            <Flex column>
              <Text>Old Image</Text>
              <img
                src={oldImageUrl}
                alt={'oldImage'}
                style={{
                  minWidth: '16em',
                  minHeight: '16em',
                  maxWidth: '16em',
                  maxHeight: '16em',
                  border: '1px solid #f1f1f1',
                }}
              />
            </Flex>
          )}
          <Flex column>
            <Text>New Image</Text>
            <img
              src={newImageUrl}
              alt={'newImage'}
              style={{
                minWidth: '16em',
                minHeight: '16em',
                maxWidth: '16em',
                maxHeight: '16em',
                border: '1px solid #f1f1f1',
              }}
            />
          </Flex>
        </Flex>
      </DialogContent>
      <DialogActions>
        <Flex>
          <Button
            style={{ marginRight: '1em' }}
            onClick={handleCloseDialog}
            color="secondary"
          >
            Cancel
          </Button>
          <Button style={{ marginRight: '1em' }} onClick={handleApproveImage}>
            Approve
          </Button>
        </Flex>
      </DialogActions>
    </Dialog>
  )
}

export default StudentApproveImageDialog
