import React, { useState, useEffect, useCallback, useRef } from 'react'

import {
  Flex,
  Text,
  Paper,
  NotificationsIcon,
  ArrowForwardIosIcon,
  ArrowDropDownIcon,
  ArrowDropUpIcon,
  IconButton,
  Divider,
  Button,
} from '../../components'
import { formatDayDMY } from '../../utils/datetime'
import { rawToHtml } from './utils'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  cardsContainer: {
    minWidth: '30em',
    width: '100%',
    margin: '2.5em',
  },
  cardContainer: {
    width: '100%',
    height: heights => heights.full,
    transition: 'all 0.3s linear',
    border: '1px solid #e2e0e0',
    marginBottom: '2em',
    justifyContent: 'center',
  },
  headerContainer: {
    width: '100%',
    height: '3em',
    borderBottom: '1px solid #e2e0e0',
    alignItems: 'center',
  },
  arrowIcon: {
    width: '.7em',
    height: '.7em',
    marginTop: '.05em',
    marginLeft: '.5em',
    marginRight: '.5em',
  },
  announcementText: {
    marginLeft: 'auto',
    marginRight: '1em',
    color: '#e2e0e0',
  },
  headerText: {
    color: '#636363',
  },
  bodyContainer: {
    height: heights => heights.preContainer,
    transition: 'all 0.3s linear',
    width: '100%',
  },
  divder: {
    width: '100%',
  },
  footerContainer: {
    width: '100%',
    height: '3em',
    alignItems: 'center',
  },
  footerTextContainer: {
    marginLeft: '1em',
    marginRight: 'auto',
    width: '20em',
  },
  announcerText: {
    color: '#636363',
  },
  overFlowIconButton: {
    margin: 'auto',
    width: '1.8em',
    height: '1.8em',
  },
  arrowDropIcon: {
    width: '1.6em',
    height: '1.6em',
    align: 'center',
  },
  footerButtonContainer: {
    marginLeft: 'auto',
    width: 'auto',
  },
  updateButton: {
    marginLeft: 'auto',
    height: '2.5em',
  },
  archiveButton: {
    height: '2.5em',
    marginLeft: '.5em',
    marginRight: '.5em',
  },
})

const DEFAULT_CARD_HEIGHTS = {
  full: '16em',
  preContainer: '13em',
  pre: '11em',
}

const NewsDetailContainer = React.memo(
  ({
    news,
    handleArchive,
    handleUpdateDialog,
    handleSendEmail,
    handleSendSMS,
  }) => {
    const classes = useStyles()

    const isOverflowing = useCallback(e => {
      return e.offsetHeight < e.scrollHeight || e.offsetWidth < e.scrollWidth
    }, [])

    const headlineNews = news
      .filter(current => {
        return current.headline
      })
      .sort((a, b) => b.announceDateTime - a.announceDateTime)

    const normalNews = news
      .filter(current => {
        return !current.headline
      })
      .sort((a, b) => b.announceDateTime - a.announceDateTime)

    return (
      <Flex column className={classes.cardsContainer}>
        {headlineNews.map(announcement => {
          const announcer =
            announcement.announcer.firstName +
            ' ' +
            announcement.announcer.lastName
          const announceDate = formatDayDMY(announcement.announceDateTime)
          return (
            <NewsCard
              key={announcement.id}
              id={announcement.id}
              announcer={announcer}
              announcerId={announcement.announcer.id}
              headline={announcement.headline}
              subject={announcement.subject}
              message={rawToHtml(announcement.message)}
              rawMessage={announcement.message}
              announceDate={announceDate}
              rawAnnounceDate={announcement.announceDateTime}
              isTextMessage={announcement.isTextMessage}
              isEmailMessage={announcement.isEmailMessage}
              isOverflowing={isOverflowing}
              handleUpdateDialog={handleUpdateDialog}
              handleArchive={handleArchive}
              handleSendEmail={handleSendEmail}
              handleSendSMS={handleSendSMS}
            />
          )
        })}
        {normalNews.map(announcement => {
          const announcer =
            announcement.announcer.firstName +
            ' ' +
            announcement.announcer.lastName
          const announceDate = formatDayDMY(announcement.announceDateTime)
          return (
            <NewsCard
              key={announcement.id}
              id={announcement.id}
              announcer={announcer}
              announcerId={announcement.announcer.id}
              headline={announcement.headline}
              subject={announcement.subject}
              message={rawToHtml(announcement.message)}
              rawMessage={announcement.message}
              announceDate={announceDate}
              rawAnnounceDate={announcement.announceDateTime}
              isTextMessage={announcement.isTextMessage}
              isEmailMessage={announcement.isEmailMessage}
              isOverflowing={isOverflowing}
              handleUpdateDialog={handleUpdateDialog}
              handleArchive={handleArchive}
              handleSendEmail={handleSendEmail}
              handleSendSMS={handleSendSMS}
            />
          )
        })}
      </Flex>
    )
  }
)

const NewsCard = React.memo(
  ({
    id,
    announcer,
    announcerId,
    headline,
    subject,
    message,
    rawMessage,
    announceDate,
    rawAnnounceDate,
    isTextMessage,
    isEmailMessage,
    isOverflowing,
    handleUpdateDialog,
    handleArchive,
    handleSendEmail,
    handleSendSMS,
  }) => {
    const [overflow, setOverflow] = useState(null)
    const [heights, setHeights] = useState(DEFAULT_CARD_HEIGHTS)
    const [expand, setExpand] = useState(false)
    const pre = useRef(null)
    const classes = useStyles({ heights: heights })

    useEffect(() => {
      if (isOverflowing(pre.current)) {
        setOverflow(true)
      }
    }, [isOverflowing])

    useEffect(() => {
      if (expand) {
        //I'm not sure why but scrollheight isn't the full height
        //This is why i'm adding 50...
        setHeights({
          full: 50 + 80 + pre.current.scrollHeight,
          preContainer: 50 + 32 + pre.current.scrollHeight,
          pre: 50 + pre.current.scrollHeight,
        })
      } else {
        setHeights(DEFAULT_CARD_HEIGHTS)
      }
    }, [expand])

    return (
      <Paper square className={classes.cardContainer}>
        <Flex className={classes.headerContainer}>
          <NotificationsIcon
            style={{
              width: '1.3em',
              height: '1.3em',
              marginTop: '0em',
              marginLeft: '.5em',
              marginRight: '.5em',
              align: 'center',
              justifyContent: 'center',
              border: headline ? '2px solid #00adef' : '2px solid #e2e0e0',
              borderRadius: '50%',
              color: headline ? '#00adef' : '#e2e0e0',
            }}
          />
          <Text className={classes.headerText} variant={'subtitle1'}>
            Placeholder
          </Text>
          <ArrowForwardIosIcon className={classes.arrowIcon} />
          <Text className={classes.headerText} variant={'subtitle1'}>
            {subject}
          </Text>
          <Text className={classes.announcementText} variant={'subtitle1'}>
            {announceDate}
          </Text>
        </Flex>
        <Flex column className={classes.bodyContainer}>
          <pre
            style={{
              height: heights.pre,
              transition: 'all 0.3s linear',
              width: '98%',
              overflow: 'hidden',
              whiteSpace: 'initial',
              paddingRight: '1%',
              paddingLeft: '1%',
              textAlign: 'left',
            }}
            ref={ref => {
              pre.current = ref
            }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
          <Divider className={classes.divider} />
          <Flex className={classes.footerContainer}>
            <Flex className={classes.footerTextContainer}>
              <Text className={classes.announcerText} variant={'subtitle1'}>
                Announcer: {announcer}
              </Text>
            </Flex>
            {overflow && (
              <IconButton
                aria-label="expand-card"
                onClick={() => setExpand(!expand)}
                className={classes.overflowIconButton}
              >
                {expand ? (
                  <ArrowDropUpIcon className={classes.arrowDropIcon} />
                ) : (
                  <ArrowDropDownIcon className={classes.arrowDropIcon} />
                )}
              </IconButton>
            )}
            <Flex className={classes.footerButtonContainer}>
              <Button
                variant="text"
                color="primary"
                onClick={() => {
                  handleSendSMS(id, rawMessage)
                }}
                className={classes.updateButton}
              >
                Send Text
              </Button>
              <Button
                variant="text"
                color="primary"
                onClick={() => {
                  handleSendEmail(id, subject, rawMessage, message)
                }}
                className={classes.archiveButton}
              >
                Send Email
              </Button>
              <Button
                variant="text"
                color="primary"
                onClick={() => {
                  handleUpdateDialog(
                    id,
                    headline,
                    subject,
                    rawMessage,
                    announcerId,
                    rawAnnounceDate,
                    isTextMessage,
                    isEmailMessage
                  )
                }}
                className={classes.archiveButton}
              >
                Update
              </Button>
              <Button
                variant="text"
                color="secondary"
                onClick={() => handleArchive(id)}
                className={classes.archiveButton}
              >
                Archive
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Paper>
    )
  }
)

export default NewsDetailContainer
