import React from 'react'
import './App.css'
import 'typeface-roboto'

import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'
import { split } from 'apollo-link'

import { ApolloProvider } from 'react-apollo'
import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { BrowserRouter, Route } from 'react-router-dom'
import { onError } from 'apollo-link-error'

import { theme } from './theme'
import { ThemeProvider } from '@mui/material/styles'
import { apiUrl, domain, tokenName, idName } from './config'

import TopBar from './components/TopBar'
import TabBar from './components/TabBar'
import MainView from './components/MainView'

import { Provider as StoreProvider, useSelector } from 'react-redux'
import { configureStore } from './store'
import Cookies from 'js-cookie'

const httpLink = createHttpLink({
  uri: apiUrl + '/graphql',
})

const getToken = () => {
  return Cookies.get(tokenName)
}

let token
const authLink = setContext((_, { headers }) => {
  if (!token) token = getToken()

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      'cache-control': 'no-cache',
    },
  }
})

const resetToken = onError(({ networkError }) => {
  if (
    networkError &&
    networkError.name === 'ServerError' &&
    networkError.statusCode === 401
  ) {
    // remove cached token on 401 from the server
    const options = {
      domain: domain,
      path: '/',
      sameSite: 'lax',
    }
    Cookies.remove(tokenName, options)
    Cookies.remove(idName, options)
  }
})

const wsLink = new WebSocketLink({
  uri:
    apiUrl === 'https://ardent-api-next.ardentlabs.io'
      ? 'wss://ardent-api-next.ardentlabs.io/graphql'
      : 'wss://ardent-api.ardentlabs.io/graphql',
  options: {
    reconnect: true,
    connectionParams: () => {
      if (!token) token = getToken()
      return {
        reconnect: true,
        timeout: 30000,
        Authorization: token ? `Bearer ${token}` : '',
        authToken: token,
      }
    },
  },
})

export const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query)
    return kind === 'OperationDefinition' && operation === 'subscription'
  },
  wsLink,
  httpLink
)

export const client = new ApolloClient({
  link: authLink.concat(resetToken).concat(link),
  cache: new InMemoryCache(),
})

const saveSessionState = session => {
  try {
    const serializedSession = JSON.stringify(session)
    localStorage.setItem('ardentSession', serializedSession)
  } catch (err) {
    console.log('Failed to save item to local storage.')
  }
}

const loadSessionState = () => {
  try {
    const serialized = localStorage.getItem('ardentSession')
    const token = getToken()

    if (serialized === null || !token) {
      return {
        loggedIn: false,
        user: {
          role: 'GUEST',
        },
      }
    }
    return JSON.parse(serialized)
  } catch (err) {
    return {
      loggedIn: false,
      user: {
        role: 'GUEST',
      },
    }
  }
}

const persistedSession = loadSessionState()
const store = configureStore(persistedSession)

// every time the store updates, update the session stored in localStorage
store.subscribe(() => {
  saveSessionState(store.getState().session)
})

const InnerApp = props => {
  const session = useSelector(state => state.session)
  return (
    <>
      <TopBar />
      {session.loggedIn && <TabBar path={props.location.pathname} />}
      <MainView />
    </>
  )
}

const App = () => {
  return (
    <ApolloProvider client={client}>
      <StoreProvider store={store}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Route component={InnerApp} />
          </BrowserRouter>
        </ThemeProvider>
      </StoreProvider>
    </ApolloProvider>
  )
}

export default App
