import React, { Component } from 'react'
import { Query, withApollo } from 'react-apollo'
import { convertToMDY } from '../../utils/datetime'
import Composer from 'react-composer'
import { Link } from 'react-router-dom'
import {
  Button,
  CheckBoxIcon,
  CheckBoxOutlineBlankIcon,
  ErrorMessage,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Flex,
  LoadingMessage,
  SnackbarNotification,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Text,
} from '../../components'
import {
  GET_GRADES_FROM_ASSIGNMENT,
  GET_STUDENTS_FROM_ASSIGNMENT,
  UPDATE_SUBMISSION,
} from './queries'
import { SUBMIT_GRADES } from '../../queries'

const cellStyle = {
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: 'lightgrey',
  padding: '5px 5px 5px 5px',
  align: 'center',
}

class GradeStudentAssignment extends Component {
  render() {
    const assignmentId = this.props.match.params[0]
    const studentId = this.props.match.params[1]
    return (
      <Composer
        components={[
          <Query
            query={GET_GRADES_FROM_ASSIGNMENT}
            variables={{ id: assignmentId, studentId }}
            fetchPolicy="network-only"
          >
            {() => {}}
          </Query>,
          <Query
            query={GET_STUDENTS_FROM_ASSIGNMENT}
            variables={{ id: assignmentId }}
            fetchPolicy="network-only"
          >
            {() => {}}
          </Query>,
        ]}
      >
        {([
          { loading: gradesLoading, error: gradesError, data: gradesData },
          {
            loading: studentsLoading,
            error: studentsError,
            data: studentsData,
          },
        ]) => {
          if (gradesLoading || studentsLoading) return <LoadingMessage />
          if (gradesError || studentsError)
            return <ErrorMessage error={gradesError || studentsError} />

          const {
            assignment: {
              lesson: {
                classroom: { id: classroomId },
              },
              problemSet: { problems, title },
            },
            gradesForAssignment,
          } = gradesData
          const [grade] = gradesForAssignment
          const problemResponses = []

          for (const [i, problem] of Object.entries(problems)) {
            problemResponses[i] = {
              problemId: problem.id,
              correctAnswer: problem.correctAnswer,
              order: problem.order,
            }
            if (grade.submission && grade.submission.responses) {
              const response = grade.submission.responses.find(
                response => response.problem.order === problem.order
              )
              problemResponses[i] = {
                ...problemResponses[i],
                responseId: response && response.id,
                correct: response && response.correct,
                studentAnswer: (function() {
                  if (response && response.value) {
                    if (problem.problemType === 'MULTIPLE_CHOICE_ONE_ANSWER') {
                      return (Number(response.value) + 10)
                        .toString(36)
                        .toUpperCase()
                    } else if (
                      problem.problemType === 'MULTIPLE_CHOICE_MANY_ANSWERS'
                    ) {
                      let num = response.value.toString().split('')
                      num.forEach((digit, index) => {
                        num[index] = (Number(num[index]) + 10)
                          .toString(36)
                          .toUpperCase()
                      })
                      return num.join(',')
                    } else {
                      return response.value
                    }
                  }
                })(),
                problemType: problem.problemType,
              }
            }
          }
          const students = studentsData.gradesForAssignment
            .filter(a => a.submission !== null)
            .sort((a, b) => {
              if (a.student.lastName > b.student.lastName) return 1
              if (a.student.lastName < b.student.lastName) return -1
              return 0
            })
          return (
            <InnerGradeStudentAssignment
              {...this.props}
              assignmentId={assignmentId}
              classroomId={classroomId}
              problemResponses={problemResponses}
              student={grade.student}
              students={students}
              title={title}
            />
          )
        }}
      </Composer>
    )
  }
}

class InnerGradeStudentAssignment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      problemResponses: this.props.problemResponses,
      snackbar: {
        open: false,
        message: '',
        messageType: '',
      },
    }
  }

  getGradesAssignmentInput = () => {
    return {
      studentId: this.props.student.id,
      responses: this.state.problemResponses.map(response =>
        response.correct ? 1 : 0
      ),
    }
  }

  handleChange = (problemNum, key, value) => {
    switch (key) {
      case 'correct':
        let problemResponses = [...this.state.problemResponses]
        problemResponses[problemNum].correct = value === true ? false : true
        this.setState({
          problemResponses,
        })
        break
      default:
        return
    }
  }

  handleReset = async submissionId => {
    const { client } = this.props
    if (submissionId === null) {
      this.setState({
        snackbar: {
          open: true,
          message: 'No submission found!',
          messageType: 'error',
        },
      })
      return
    }

    await client.mutate({
      mutation: UPDATE_SUBMISSION,
      variables: {
        id: submissionId,
        input: {
          reviewed: false,
        },
      },
    })
    this.setState({
      snackbar: {
        open: true,
        message: 'Submission Reset Successfully',
        messageType: 'success',
      },
    })
  }

  handleSubmit = async () => {
    const { assignmentId, client } = this.props
    const { problemResponses } = this.state
    this.setState({
      snackbar: {
        open: true,
        message: 'Grade Submission in Progress',
        messageType: 'loading',
      },
    })
    const {
      data: {
        gradeAssignment: { success },
      },
    } = await client.mutate({
      mutation: SUBMIT_GRADES,
      variables: {
        aid: assignmentId,
        pids: problemResponses.map(response => response.problemId),
        input: this.getGradesAssignmentInput(),
      },
      refetchQueries: [
        {
          query: GET_GRADES_FROM_ASSIGNMENT,
          variables: {
            id: assignmentId,
          },
        },
      ],
    })
    this.setState({
      snackbar: {
        open: true,
        message: success ? 'Grades Submitted ✔' : 'error',
        messageType: success ? 'success' : 'error',
      },
    })
  }

  render() {
    const columns = [
      {
        Header: 'Problem',
        accessor: 'order',
        align: 'center',
        width: '500em',
      },
      {
        Header: 'Correct Answer',
        accessor: 'correctAnswer',
        align: 'center',
      },
      {
        Header: 'Student Answer',
        accessor: 'studentAnswer',
        align: 'center',
      },
      {
        Header: <CheckBoxIcon color="primary" />,
        accessor: 'correct',
        align: 'center',
      },
    ]
    const { assignmentId, student, students, title } = this.props
    const { problemResponses, snackbar } = this.state
    const studentIndex = students.findIndex(a => a.student.id === student.id)
    const disableGrading =
      studentIndex === -1 ||
      students[studentIndex].submission.submittedAt === null
    const submissionId =
      studentIndex !== -1 ? students[studentIndex].submission.id : null
    return (
      <Flex
        column
        grow={1}
        style={{
          textAlign: 'left',
          margin: 'auto',
          width: '67%',
          minWidth: '600px',
          maxWidth: '1000px',
        }}
      >
        <br />
        <Button
          onClick={() => {
            localStorage.setItem('refetch', true)
            this.props.history.goBack()
          }}
        >
          {'< back to Grade Assignment'}
        </Button>
        <br />
        <Flex justify="space-between">
          <Text variant="h4">{`Grading ${student.firstName} ${student.lastName}'s Assignment`}</Text>
          <Flex grow={0}>
            <Button disabled={studentIndex === -1 || studentIndex === 0}>
              <Link
                replace={true}
                to={
                  studentIndex !== -1 && studentIndex !== 0
                    ? `/grade/${assignmentId}/${
                        students[studentIndex - 1].student.id
                      }`
                    : ''
                }
              >
                {'< Prev'}
              </Link>
            </Button>
            <Button
              disabled={
                (studentIndex === -1 && students.length === 0) ||
                studentIndex === students.length - 1
              }
            >
              <Link
                replace={true}
                to={
                  studentIndex === -1 && students.length !== 0
                    ? `/grade/${assignmentId}/${students[0].student.id}`
                    : studentIndex !== students.length - 1
                    ? `/grade/${assignmentId}/${
                        students[studentIndex + 1].student.id
                      }`
                    : ''
                }
              >
                {'Next >'}
              </Link>
            </Button>
          </Flex>
        </Flex>
        <Flex style={{ marginTop: '1em' }}>
          <Text fontWeight="bold" style={{ marginRight: '.5em' }}>
            Created On:
          </Text>
          <Text fontWeight="bold" style={{ marginRight: '3em' }}>
            {convertToMDY(this.props.students[0].submission.createdOn)}
          </Text>
          <Text style={{ marginRight: '.5em' }}>Submitted At:</Text>
          <Text>
            {disableGrading
              ? 'Unsubmitted'
              : convertToMDY(this.props.students[0].submission.submittedAt)}
          </Text>
        </Flex>
        <Accordion defaultExpanded>
          <AccordionSummary title={`Assignment: ${title}`} />
          <AccordionDetails>
            <Flex
              column
              style={{
                align: 'center',
                flexGrow: 0,
                width: '100%',
              }}
              justify="space-evenly"
            >
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map(data => (
                      <TableCell
                        key={data.accessor}
                        align={cellStyle.align}
                        style={cellStyle}
                      >
                        {data.Header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {problemResponses.map(
                    ({ order, correctAnswer, studentAnswer, correct }, row) => (
                      <TableRow key={row} hover={true}>
                        {[
                          { key: 'order', value: order },
                          { key: 'correctAnswer', value: correctAnswer },
                          { key: 'studentAnswer', value: studentAnswer },
                          { key: 'correct', value: correct },
                        ].map(column => (
                          <TableCell
                            key={column.key}
                            align={cellStyle.align}
                            style={cellStyle}
                            onClick={() =>
                              !disableGrading &&
                              this.handleChange(row, column.key, column.value)
                            }
                          >
                            {column.key === 'correct' ? (
                              column.value ? (
                                <CheckBoxIcon color="primary" />
                              ) : (
                                <CheckBoxOutlineBlankIcon color="disabled" />
                              )
                            ) : (
                              column.value
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
              <Flex direction={'row-reverse'} align={'center'} p="10px">
                <Button onClick={this.handleSubmit}>Submit</Button>
                <Button
                  style={{ marginRight: 10 }}
                  onClick={() => this.handleReset(submissionId)}
                >
                  Reset
                </Button>
              </Flex>
            </Flex>
          </AccordionDetails>
        </Accordion>
        <SnackbarNotification
          open={snackbar.open}
          handleClose={() => {
            this.setState({
              snackbar: {
                ...snackbar,
                open: false,
              },
            })
          }}
          message={snackbar.message}
          messageType={snackbar.messageType}
        />
      </Flex>
    )
  }
}
export default withApollo(GradeStudentAssignment)
