import React, { Component } from 'react'

import ReactTable from 'react-table'

import CastForEducationIcon from '@material-ui/icons/CastForEducation'
import SchoolIcon from '@material-ui/icons/School'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import PersonIcon from '@material-ui/icons/Person'

class HomeTuteeTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectAll: false,
      checked: this.props.classes.map(() => {
        return false
      }),
    }
    this.classTable = React.createRef()
  }

  updateClassTableView = filters => {
    const filteredTable = this.classTable.current
    if (filteredTable) {
      const filteredClasses = filteredTable.getResolvedState().sortedData
      this.props.updateClasses(filters, filteredClasses)
    }
  }

  render() {
    const columns = [
      {
        Header: <SchoolIcon />,
        accessor: 'student',
        width: 120,
      },
      {
        Header: <CastForEducationIcon />,
        accessor: 'code',
        width: 80,
      },
      {
        Header: <AccessTimeIcon />,
        accessor: 'timeRange',
        width: 140,
        sortMethod: (a, b, desc) => {
          a = a === null || a === undefined ? -Infinity : a
          b = b === null || b === undefined ? -Infinity : b
          const first = a.charAt(6)
          const second = b.charAt(6)
          const time1 = a.substring(0, 2)
          const time2 = b.substring(0, 2)
          if (time1 === '12' && time2 !== '12') {
            if (second === 'P') {
              return -1
            } else {
              return 1
            }
          } else if (time2 === '12' && time1 !== '12') {
            if (first === 'P') {
              return 1
            } else {
              return -1
            }
          }
          if (first === second) {
            if (a > b) {
              return 1
            }
            if (a < b) {
              return -1
            }
            return 0
          } else {
            if (first === 'A') {
              return -1
            }
            return 1
          }
        },
      },
      {
        Header: <PersonIcon />,
        accessor: 'tutor',
        width: 150,
      },
    ]
    const { filtered, sorted, classes } = this.props
    return (
      <div align="left">
        <ReactTable
          ref={this.classTable}
          filterable={false}
          data={classes}
          columns={columns}
          className="-highlight"
          showPagination={false}
          defaultPageSize={25}
          defaultSorted={sorted}
          defaultFiltered={filtered}
          onFilteredChange={this.updateClassTableView}
          style={{ fontSize: '10pt', margin: '10px' }}
          defaultFilterMethod={(filter, row) =>
            row[filter.id] &&
            row[filter.id]
              .toString()
              .toLowerCase()
              .includes(filter.value.toString().toLowerCase())
          }
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              onClick: (e, handleOriginal) => {
                if (column.Header !== undefined) {
                  this.props.onRowClick(rowInfo)
                }
                if (handleOriginal) {
                  handleOriginal()
                }
              },
            }
          }}
          getTheadProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                fontWeight: 'bold',
                borderBottom: '1px solid #E5E5E5',
              },
            }
          }}
        />
      </div>
    )
  }
}

export default HomeTuteeTable
