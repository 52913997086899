import React from 'react'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material'

const RenewTutorSessionDialog = props => {
  const {
    dialog,
    student,
    classroom,
    sessions,
    unitPrice,
    handleUpdateInvoice,
    handleClose,
    handleSubmit,
  } = props

  const { open } = dialog
  const { courseCode, courseTitle } = classroom
  const studentName = `${student.firstName}  ${student.lastName}`
  const courseName = `${courseCode} - ${courseTitle}`

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'sm'}>
      <DialogTitle>
        <Box sx={{ flexDirection: 'column' }}>
          <Typography style={{ marginBottom: '1em' }}>
            Renew Renewal Invoice for {studentName}
          </Typography>
        </Box>
        <Divider />
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <FormControl component="fieldset" style={{ marginBottom: '1em' }}>
            <FormLabel component="legend">Course:</FormLabel>
            <Typography>{courseName}</Typography>
          </FormControl>
          <FormControl component="fieldset">
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <TextField
                onChange={event =>
                  handleUpdateInvoice({
                    name: 'sessions',
                    value: event.target.value,
                  })
                }
                value={sessions}
                sx={{ width: '25%', mt: 1 }}
                type="number"
                label={'Number of Sessions: '}
              />
              <TextField
                onChange={event =>
                  handleUpdateInvoice({
                    name: 'unitPrice',
                    value: event.target.value,
                  })
                }
                value={unitPrice}
                sx={{ width: '25%', mt: 1, ml: 3 }}
                label={'Unit Price: '}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Box>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={async () => handleSubmit()}>
          Create Invoice
        </Button>
        <Button variant="contained" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default RenewTutorSessionDialog
