import gql from 'graphql-tag'

export const UPDATE_TRIAL = gql`
  mutation updateTrial($id: ID!, $input: UpdateTrialInput!) {
    updateTrial(id: $id, input: $input) {
      id
    }
  }
`

export const CREATE_ENROLLMENT = gql`
  mutation enrollStudent($input: CreateEnrollmentInput!) {
    createEnrollment(input: $input) {
      id
      classroom {
        id
        lessons {
          id
          lessonPlan {
            id
            order
          }
        }
      }
    }
  }
`

export const CREATE_ATTENDEE = gql`
  mutation makeAttendee($input: CreateAttendeeInput!) {
    createAttendee(input: $input) {
      id
    }
  }
`
