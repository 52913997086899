import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import CreditDetailedView from './CreditDetailedView'
import CreditTableView from './CreditTableView'

import OpeningBalanceForm from './OpeningBalanceForm'
import ReferralForm from './ReferralForm'
import AddCreditForm from './AddCreditForm'

class CreditView extends Component {
  render() {
    const url = this.props.match.url
    return (
      <Switch>
        <Route path={url + '/add-credit'} component={AddCreditForm} />
        <Route path={url + '/opening-balance'} component={OpeningBalanceForm} />
        <Route path={url + '/referral'} component={ReferralForm} />
        <Route path={url + '/*'} component={CreditDetailedView} />
        <Route path={url} component={CreditTableView} />
      </Switch>
    )
  }
}

export default CreditView
