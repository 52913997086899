import gql from 'graphql-tag'

export const GET_CLASSROOMS_NAMES = gql`
  query getClasses($filter: Filters, $sort: Sorts) {
    classrooms(filter: $filter, sort: $sort) {
      id
      status
      session
      code
      title
      enrollmentCount
      startTime
      endTime
      meets
      centerLocation {
        id
        name
      }
    }
  }
`

export const GET_STUDENTS_FROM_ASSIGNMENT = gql`
  query getStudentsFromAssignment($id: ID!) {
    gradesForAssignment(
      id: $id
      fillEmpty: true
      fillUngraded: true
      fillIncomplete: true
    ) {
      student {
        id
        firstName
        lastName
        gender
      }
      submission {
        id
        submittedAt
      }
    }
  }
`

export const GET_GRADES_FROM_ASSIGNMENT = gql`
  query getGrades($id: ID!, $studentId: ID) {
    assignment(id: $id) {
      id
      lesson {
        id
        classroom {
          id
        }
      }
      problemSet {
        id
        autoGrading
        title
        order
        subOrder
        problems(sort: { order: 1 }) {
          id
          answer
          correctAnswer
          order
          problemType
        }
      }
    }
    gradesForAssignment(
      id: $id
      studentId: $studentId
      fillEmpty: true
      fillUngraded: true
      fillIncomplete: true
    ) {
      student {
        id
        firstName
        lastName
        imageUrl
        active
        gender
        attendees {
          id
          status
          isMakeup
          lesson {
            id
          }
        }
      }
      submission {
        id
        submittedAt
        submittedLate
        graded
        overallGrade
        responses {
          id
          percentCorrect
          sum
          count
          value
          problem {
            id
            order
          }
        }
      }
    }
  }
`

export const SUBMIT_GRADES = gql`
  mutation($aid: ID!, $pids: [ID], $input: [GradeAssignmentInput!]) {
    gradeAssignment(assignmentId: $aid, problemIds: $pids, inputs: $input) {
      success
      message
    }
  }
`

export const UPDATE_SUBMISSION = gql`
  mutation updateSubmission($id: ID!, $input: UpdateSubmissionInput!) {
    updateSubmission(id: $id, input: $input) {
      id
    }
  }
`

export const GET_CLASS_FROM_ASSIGNMENT = gql`
  query GetClassFromAssignment($id: ID!) {
    assignment(id: $id) {
      id
      classroom {
        id
      }
    }
  }
`
