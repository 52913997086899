import React, { Component } from 'react'
import { Query } from 'react-apollo'

import TaskDetailPanels from './TaskDetailPanels'
import { GET_ACTIVITY } from './queries'

import { ErrorMessage, LoadingMessage } from '../../components'

class TaskDetailView extends Component {
  render() {
    const taskId = this.props.match.params[0]
    return (
      <Query
        fetchPolicy={'cache-and-network'}
        query={GET_ACTIVITY}
        variables={{ id: taskId }}
      >
        {({ loading, error, data }) => {
          if (loading) return <LoadingMessage />
          if (error) return <ErrorMessage error={error} />
          const { activity } = data
          return <TaskDetailPanels task={activity} />
        }}
      </Query>
    )
  }
}

export default TaskDetailView
