import React, { Component } from 'react'
import { Query } from 'react-apollo'
import { Redirect } from 'react-router-dom'
import gql from 'graphql-tag'

import TrialTable from './TrialTable'
import TrialSidebar from './TrialSidebar'

import {
  Button,
  ErrorMessage,
  Flex,
  LoadingMessage,
  Menu,
  MenuItem,
  SettingsIcon,
  Tooltip,
} from '../../components'
import { formatTime, formatDay } from '../../utils/datetime'

const GET_TRIALS = gql`
  query getTrials($filter: Filters!) {
    trials(filter: $filter) {
      id
      status
      followupStatus
      followupDate
      followupEmployee {
        id
        firstName
        lastName
      }
      toLesson {
        id
        lessonPlan {
          id
          order
        }
        classroom {
          id
          centerLocation {
            id
            name
          }
          code
          instructors {
            id
            employee {
              id
              firstName
              lastName
            }
          }
        }
        startOn
        endOn
      }
      opportunity {
        id
        status
        student {
          id
          firstName
          lastName
          phoneNumber
        }
      }
    }
  }
`

class TrialTableView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      redirect: false,
      onlyShowRealData: false,
      sidebar: true,
      showFilters: true,
      menuAnchor: null,
      sorted: [
        {
          id: 'lessonDate',
          desc: true,
        },
      ],
    }
  }

  toggleSidebar = () => {
    this.setState(state => ({
      sidebar: !state.sidebar,
      menuAnchor: null,
    }))
  }

  toggleFilters = () => {
    this.setState(state => ({
      showFilters: !state.showFilters,
      menuAnchor: null,
    }))
  }

  onFilteredChange = filtered => {
    this.setState({
      filtered,
    })
  }

  menuOpen = event => {
    this.setState({
      menuAnchor: event.currentTarget,
    })
  }

  menuClose = () => {
    this.setState({
      menuAnchor: null,
    })
  }

  rowClick = rowInfo => {
    rowInfo &&
      this.setState({
        redirect: true,
        redirectTo: `/trial/${rowInfo.original.id}`,
      })
  }

  menuButton = () => {
    const { menuAnchor } = this.state
    return (
      <Flex p="5px">
        <Flex p="5px">
          <Tooltip>
            <Button color="default" aria-label="Menu" onClick={this.menuOpen}>
              <SettingsIcon />
            </Button>
          </Tooltip>
        </Flex>
        <Menu
          id="simple-menu"
          anchorEl={menuAnchor}
          open={Boolean(menuAnchor)}
          onClose={this.menuClose}
        >
          <MenuItem onClick={this.toggleFilters}>Toggle Filters</MenuItem>>
        </Menu>
      </Flex>
    )
  }

  findCurrentTrialDateRange = () => {
    let currentYear = new Date().getFullYear()
    let lastYear = currentYear - 1
    let nextYear = currentYear + 1
    let currentMonth = new Date().getMonth() + 1
    return 1 <= currentMonth && currentMonth <= 2
      ? [new Date('Nov 25 ' + lastYear), new Date('Mar 31 ' + currentYear)]
      : 3 <= currentMonth && currentMonth <= 5
      ? [new Date('Feb 20 ' + currentYear), new Date('May 31 ' + currentYear)]
      : 6 <= currentMonth && currentMonth <= 7
      ? [new Date('Jun 16 ' + currentYear), new Date('Aug 31 ' + currentYear)]
      : currentMonth === 8
      ? [new Date('Aug 1 ' + currentYear), new Date('Sep 30 ' + currentYear)]
      : 9 <= currentMonth && currentMonth <= 10
      ? [new Date('Sep 1 ' + currentYear), new Date('Nov 30 ' + currentYear)]
      : currentMonth === 11
      ? [new Date('Sep 1 ' + currentYear), new Date('Dec 31 ' + currentYear)]
      : currentMonth === 12
      ? [new Date('Nov 25 ' + currentYear), new Date('Mar 1 ' + nextYear)]
      : []
  }

  render() {
    const {
      filtered,
      redirect,
      redirectTo,
      showFilters,
      sidebar,
      sorted,
    } = this.state
    if (redirect) {
      return <Redirect push to={redirectTo} />
    }

    const currentTrialsRange = this.findCurrentTrialDateRange()
    const rangeStartDate = currentTrialsRange[0]

    return (
      <Query
        fetchPolicy="cache-and-network"
        query={GET_TRIALS}
        variables={{
          filter: {
            toLesson: {
              startOn: {
                $gteDate: rangeStartDate.toISOString(),
              },
            },
          },
        }}
      >
        {({ loading, error, data }) => {
          if (loading) return <LoadingMessage />
          if (error) {
            return <ErrorMessage error={error} />
          }
          const currentTrials = data.trials.map(trial => {
            const { toLesson } = trial
            const { student } = trial.opportunity
            const { firstName, lastName } = trial.followupEmployee
            const {
              firstName: instructorFirstName,
              lastName: instructorLastName,
            } =
              toLesson.classroom.instructors.length &&
              toLesson.classroom.instructors[0].employee
            return {
              id: trial.id,
              status: trial.status,
              enrollment: trial.opportunity.status,
              followupStatus: trial.followupStatus ? '✓' : '',
              followupDate: new Date(trial.followupDate),
              followupBy: `${firstName} ${lastName}`,
              code: toLesson.classroom.code,
              lessonNumber: toLesson.lessonPlan.order,
              lessonDay: formatDay(new Date(toLesson.startOn)),
              lessonDate: new Date(toLesson.startOn),
              lessonTime: formatTime(new Date(toLesson.startOn)),
              location: toLesson.classroom.centerLocation.name,
              instructor:
                instructorFirstName && instructorLastName
                  ? `${instructorFirstName} ${instructorLastName}`
                  : 'N/A',
              opportunity: trial.opportunity,
              firstName: student && student.firstName,
              lastName: student && student.lastName,

              phoneNumber: student && student.phoneNumber,
            }
          })

          return (
            <Flex>
              {sidebar ? (
                <TrialSidebar
                  show={sidebar}
                  trials={currentTrials}
                  menu={this.menuButton}
                />
              ) : null}
              <Flex pl={sidebar ? '0px' : '20px'} align="left" column grow={1}>
                <TrialTable
                  trials={currentTrials}
                  filterable={showFilters}
                  onRowClick={this.rowClick}
                  sorted={sorted}
                  filtered={filtered}
                  trialStatuses={this.props.trialStatuses}
                  opportunityStatuses={this.props.opportunityStatuses}
                  onFilteredChange={this.onFilteredChange}
                />
              </Flex>
            </Flex>
          )
        }}
      </Query>
    )
  }
}

export default TrialTableView
