import React, { Component } from 'react'
import ReactTable from 'react-table'

class OfferTable extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    const columns = [
      {
        Cell: row => {
          return (
            <input
              type="radio"
              checked={this.props.chosenOffer.id === row.original.id}
              onChange={() => {}}
            />
          )
        },
        sortable: false,
        filterable: false,
        resizable: false,
        width: 28,
      },
      {
        Header: 'Type',
        accessor: 'offerType',
        width: 100,
        filterMethod: (filter, row) => {
          if (filter.value !== 'ALL') {
            return row[filter.id].includes(filter.value)
          }
          return true
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: '100%' }}
            value={filter ? filter.value : 'all'}
          >
            <option value="ALL">All</option>
            <option value="COUNSELING">Counseling</option>
            <option value="TUTORING">Tutoring</option>
            <option value="CONTEST">Constest</option>'
            <option value="MISC">Misc</option>
          </select>
        ),
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Price',
        accessor: 'unitPrice',
        Cell: props => <span>{`$${props.value.toFixed(2)}`}</span>,
      },
    ]

    return (
      <ReactTable
        filterable={true}
        data={this.props.offers}
        columns={columns}
        className="-highlight"
        defaultPageSize={10}
        style={{ fontSize: '10pt' }}
        defaultFilterMethod={(filter, row) =>
          row[filter.id] !== null &&
          row[filter.id]
            .toString()
            .toLowerCase()
            .includes(filter.value.toString().toLowerCase())
        }
        defaultSorted={[
          {
            id: 'offerType',
            desc: false,
          },
          {
            id: 'name',
            desc: false,
          },
        ]}
        getTrProps={(state, rowInfo, column) => {
          let background = '#ffffff'
          return {
            style: {
              background,
            },
          }
        }}
        getTdProps={(state, rowInfo, column, instance) => {
          return {
            onClick: e => {
              this.props.changeOffer(
                rowInfo.original.id,
                rowInfo.original.unitPrice,
                rowInfo.original.offerType
              )
            },
          }
        }}
        getTheadProps={(state, rowInfo, column, instance) => {
          return {
            style: {
              fontWeight: 'bold',
              borderBottom: '1px solid #E5E5E5',
            },
          }
        }}
      />
    )
  }
}

export default OfferTable
