import gql from 'graphql-tag'

//Queries
export const GET_POSTS = gql`
  query getPosts($filter: Filters, $sort: Sorts, $page: Pagination) {
    posts(filter: $filter, sort: $sort, page: $page) {
      id
      postType
      employee {
        id
        imageUrl
      }
      title
      description
      content
      video
      viewCount
      image
      imageUrl
      published
      publishDate
      expirationDate
      favoriteCount
      classroomCodes
      createdOn
    }
  }
`

export const GET_POST = gql`
  query getPost($id: ID!) {
    post(id: $id) {
      id
      postType
      employee {
        id
        imageUrl
        firstName
        lastName
      }
      title
      description
      content
      video
      viewCount
      image
      imageUrl
      published
      publishDate
      expirationDate
      favoriteCount
      fromGrade
      toGrade
      classroomCodes
    }
  }
`

export const GET_EMPLOYEES = gql`
  query getEmployees {
    employees(
      filter: { isTeacher: true, status: "ACTIVE" }
      sort: { lastName: 1 }
    ) {
      id
      status
      firstName
      lastName
    }
  }
`

export const GET_POST_TYPE_OPTIONS = gql`
  query getPostTypeOptions {
    __type(name: "PostType") {
      enumValues {
        name
      }
    }
  }
`

//Mutations
export const CREATE_POST = gql`
  mutation createPost($input: CreatePostInput!) {
    createPost(input: $input) {
      id
      postType
      title
      description
      content
      image
      imageUrl
    }
  }
`

export const UPDATE_POST = gql`
  mutation updatePost($id: ID!, $input: UpdatePostInput!) {
    updatePost(id: $id, input: $input) {
      id
      postType
      title
      description
      content
      image
      imageUrl
      video
      publishDate
      expirationDate
      fromGrade
      toGrade
      classroomCodes
    }
  }
`

export const ARCHIVE_POST = gql`
  mutation archivePost($id: ID!) {
    archivePost(id: $id) {
      id
    }
  }
`
