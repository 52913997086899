import React, { Component, Fragment } from 'react'

import ReactTable from 'react-table'
import 'react-table/react-table.css'

import { convertToMDY, dateFilterMethod } from '../../utils/datetime'
import { interests } from '../../config'

class FamilyTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectAll: false,
      checked: props.opportunities.map(() => {
        return false
      }),
    }
  }

  handleSingleCheckboxChange = index => {
    const { checked, selectAll } = this.state
    let newChecked = checked
    newChecked[index] = !checked[index]
    this.setState({
      selectAll: newChecked[index] === false && selectAll ? false : selectAll,
      checked: newChecked,
    })
  }

  render() {
    const { checked } = this.state
    const { stages, statuses } = this.props
    const columns = [
      {
        Cell: row => (
          <input
            type="checkbox"
            checked={checked[row.index]}
            onChange={() => this.handleSingleCheckboxChange(row.index)}
          />
        ),
        sortable: false,
        filterable: false,
        resizable: false,
        width: 28,
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: 135,
        Cell: props => {
          if (props.value === 'OPEN') {
            return <span style={{ color: '#27AE60' }}>{'OPEN'}</span>
          }
          if (props.value === 'INACTIVE') {
            return <span style={{ color: '#EB5757' }}>{'WAITLISTED'}</span>
          }
          return <span>{props.value ? props.value : 'UNKNOWN'}</span>
        },
        filterMethod: (filter, row) => {
          if (filter.value !== 'ALL') {
            return row[filter.id] === filter.value
          }
          return true
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: '100%' }}
            value={filter ? filter.value : 'OPEN'}
          >
            <Fragment>
              <option value="ALL">ALL</option>
              {statuses.map(option => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Fragment>
          </select>
        ),
      },
      {
        Header: 'Open Date',
        accessor: 'openDate',
        Cell: props => <span>{props.value && convertToMDY(props.value)}</span>,
        width: 100,
        filterMethod: dateFilterMethod,
      },
      {
        Header: 'Days Open',
        accessor: 'openTime',
        style: { textAlign: 'right' },
        Cell: props => <span>{Math.round(props.value / 86400000)}</span>,
        filterMethod: (filter, row) => {
          return (
            row[filter.id] &&
            Math.round(row[filter.id] / 86400000)
              .toString()
              .startsWith(filter.value)
          )
        },
        width: 86,
      },
      {
        Header: 'Last Contacted',
        accessor: 'lastContacted',
        Cell: props => <span>{props.value && convertToMDY(props.value)}</span>,
        width: 125,
        filterMethod: dateFilterMethod,
      },
      {
        Header: 'First Name',
        accessor: 'firstName',
      },
      {
        Header: 'Last Name',
        accessor: 'lastName',
        filterMethod: (filter, row) => {
          return (
            row[filter.id] &&
            row[filter.id].toLowerCase().startsWith(filter.value.toLowerCase())
          )
        },
      },
      {
        Header: 'Stage',
        accessor: 'stage',
        Cell: props => {
          if (props.value === 'NEED_ASSESSMENT') {
            return <span>{'ASSESSMENT'}</span>
          }
          return <span>{props.value}</span>
        },
        filterMethod: (filter, row) => {
          if (filter.value !== 'ALL') {
            return row[filter.id] === filter.value
          }
          return true
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: '100%' }}
            value={filter ? filter.value : 'ALL'}
          >
            <Fragment>
              <option value="ALL">ALL</option>
              {stages.map(option => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Fragment>
          </select>
        ),
      },
      {
        Header: 'Interest',
        accessor: 'primaryInterest',
        filterMethod: (filter, row) => {
          if (filter.value !== 'ALL') {
            return row[filter.id] === filter.value
          }
          return true
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: '100%' }}
            value={filter ? filter.value : 'all'}
          >
            <option value="ALL">All</option>
            {interests.map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        ),
      },
      {
        Header: 'Session',
        accessor: 'session',
        Cell: props => (
          <span>
            {props.value
              ? props.original.year
                ? `${props.value} ${props.original.year.toString()}`
                : props.value
              : props.original.year}
          </span>
        ),
        width: 86,
      },
      {
        Header: 'Created by',
        accessor: 'creator',
      },
      {
        Header: 'Assigned to',
        accessor: 'responsibleEmployee',
      },
      {
        Header: 'Notes',
        accessor: 'notes',
        minWidth: 250,
      },
    ]

    const {
      filtered,
      filterable,
      onFilteredChange,
      onRowClick,
      opportunities,
      sorted,
    } = this.props
    return (
      <div align="left">
        <ReactTable
          filterable={filterable}
          data={opportunities}
          columns={columns}
          className="-highlight"
          defaultPageSize={25}
          defaultSorted={sorted}
          defaultFiltered={filtered}
          onFilteredChange={onFilteredChange}
          style={{ fontSize: '10pt' }}
          defaultFilterMethod={(filter, row) =>
            row[filter.id] &&
            row[filter.id]
              .toString()
              .toLowerCase()
              .includes(filter.value.toString().toLowerCase())
          }
          getTrProps={(state, rowInfo, column) => {
            let background = '#ffffff'
            return {
              style: {
                background,
              },
            }
          }}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              onClick: (e, handleOriginal) => {
                if (column.Header !== undefined) {
                  onRowClick(rowInfo)
                }
                if (handleOriginal) {
                  handleOriginal()
                }
              },
            }
          }}
          getTheadProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                fontWeight: 'bold',
                borderBottom: '1px solid #E5E5E5',
              },
            }
          }}
        />
      </div>
    )
  }
}

export default FamilyTable
