import React, { Component, createRef } from 'react'
import { withApollo } from 'react-apollo'
import ReactTable from 'react-table'

import { convertToMDY } from '../../utils/datetime'
import { GET_ISSUES, COUNT_ISSUES } from './queries'
import { arrayToObjectFilter, arrayToObjectSort } from './utils'
import { DatePicker } from '../../components'
import AttendanceDialog from '../Attendance/AttendanceDialog'

class IssueTable extends Component {
  constructor(props) {
    super(props)
    this.table = createRef()
    this.state = {
      data: this.processData(props.data),
      allStatues: ['ALL', ...this.props.statuses],
      filtered: [{ id: 'issueStatus', value: 'INITIATED' }],
      page: 0,
      pages: 1,
      pageSize: 25,
      loading: true,
      attendanceDialog: {
        open: false,
        attedeeId: null,
      },
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        data: this.processData(this.props.data),
        filtered: this.props.filtered,
      })
    }
  }

  processData = data => {
    if (data === undefined) {
      return []
    }
    const res = data.map(issue => {
      return {
        ...issue,
        reporter:
          issue.student &&
          issue.student.firstName + ' ' + issue.student.lastName,
        createdOn: issue.createdOn && convertToMDY(issue.createdOn),
        reportDate: issue.createdOn && convertToMDY(issue.createdOn),
        type: issue.issueType,
        firstName: issue.student ? issue.student.firstName : '',
        lastName: issue.student ? issue.student.lastName : '',
        courseCode: issue.classroom.code,
        employee:
          issue.responsibleEmployee &&
          issue.responsibleEmployee.firstName +
            ' ' +
            issue.responsibleEmployee.lastName,
        classroomId: issue.classroom.id,
        attendee: issue.attendee,
      }
    })
    return res
  }

  updateTable = () => {
    this.handleFetchData(this.table.current.state, null, true)
  }

  handleOpenDialog = row => {
    this.setState({
      attendanceDialog: {
        open: true,
        data: row,
      },
    })
  }

  handleCloseDialog = () => {
    this.setState({
      attendanceDialog: {
        open: false,
      },
    })
  }

  handleFetchData = async (tableState, instance, forceUpdate = false) => {
    const { client } = this.props
    const { loading } = this.state
    const { page, pageSize, filtered, sorted } = tableState

    if (
      loading ||
      filtered !== this.state.filtered ||
      page !== this.state.page ||
      pageSize !== this.state.pageSize ||
      sorted !== this.state.sorted
    ) {
      const filter = arrayToObjectFilter(filtered)
      const sort = arrayToObjectSort(sorted)
      const queries = []
      this.setState({ loading: true })
      queries.push(
        client.query({
          query: GET_ISSUES,
          fetchPolicy: forceUpdate ? 'network-only' : null,
          variables: {
            filter: {
              ...filter,
              issueType: {
                $match: 'ATTENDANCE',
              },
            },
            sort,
            page: { skip: page * pageSize, limit: pageSize },
          },
        })
      )
      queries.push(
        client.query({
          query: COUNT_ISSUES,
          fetchPolicy: forceUpdate ? 'network-only' : null,
          variables: {
            filter,
          },
        })
      )
      const [
        {
          data: { issues },
        },
        {
          data: { countIssues: count },
        },
      ] = await Promise.all(queries)
      this.setState({
        filtered,
        page,
        pageSize,
        pages: Math.ceil(count / pageSize),
        data: this.processData(issues),
      })
    }
    this.setState({ loading: false })
  }

  render() {
    const {
      data,
      filtered,
      allStatues,
      loading,
      page,
      pageSize,
      pages,
      attendanceDialog,
    } = this.state
    const columns = [
      {
        Header: 'Status',
        accessor: 'issueStatus',
        width: 150,
        filterMethod: (filter, row) => {
          if (filter.value !== 'ALL') {
            return row[filter.id] === filter.value
          }
          return true
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => {
              onChange(event.target.value)
            }}
            style={{ width: '100%' }}
            value={filter ? filter.value : 'ALL'}
          >
            {allStatues.map(statue => {
              return (
                <option key={statue} value={statue}>
                  {statue}
                </option>
              )
            })}
          </select>
        ),
      },
      {
        Header: 'Date',
        accessor: 'createdOn',
        width: 150,
        Filter: ({ filter, onChange }) => (
          <DatePicker
            onChange={event => onChange(event.target.value)}
            style={{ width: '100%' }}
            value={filter ? filter.value : null}
            clearable
            disableKeyboard
          />
        ),
      },
      {
        Header: 'Type',
        accessor: 'issueType',
        width: 150,
      },
      {
        Header: 'First Name',
        accessor: 'firstName',
        minWidth: 110,
      },
      {
        Header: 'Last Name',
        accessor: 'lastName',
        minWidth: 110,
      },
      {
        Header: 'Course',
        accessor: 'courseCode',
        minWidth: 100,
      },
      {
        Header: 'Employee',
        accessor: 'employee',
        width: 200,
      },
      {
        Header: 'Description',
        accessor: 'description',
        minWidth: 150,
      },
    ]
    return (
      <div align="left">
        <ReactTable
          ref={this.table}
          columns={columns}
          data={data}
          filterable={true}
          className="-highlight"
          // defaultSorted={sorted}
          defaultFiltered={filtered}
          style={{ fontSize: '10pt' }}
          defaultPageSize={25}
          manual
          defaultFilterMethod={(filter, row) =>
            row[filter.id] &&
            row[filter.id]
              .toString()
              .toLowerCase()
              .includes(filter.value.toString().toLowerCase())
          }
          loading={loading}
          page={page}
          pageSize={pageSize}
          pages={pages}
          onFetchData={this.handleFetchData}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              onClick: (e, handleOriginal) => {
                if (column.Header !== undefined) {
                  if (rowInfo) {
                    this.handleOpenDialog(rowInfo.original)
                  }
                }
                if (handleOriginal) {
                  handleOriginal()
                }
              },
            }
          }}
        />
        {attendanceDialog.open && (
          <AttendanceDialog
            open={attendanceDialog.open}
            handleClose={this.handleCloseDialog}
            attendeeId={attendanceDialog.data.attendee.id}
            classroomId={attendanceDialog.data.classroom.id}
            data={attendanceDialog.data.attendee}
            issue={attendanceDialog.data}
            refetch={this.updateTable}
          />
        )}
      </div>
    )
  }
}

export default withApollo(IssueTable)
