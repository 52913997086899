import React, { Component } from 'react'
import { Mutation } from 'react-apollo'
import { Redirect } from 'react-router-dom'

import {
  Button,
  Flex,
  OpportunityForm,
  Paper,
  SnackbarNotification,
  Text,
  withStyles,
} from '../../components'
import Cookies from 'js-cookie'
import { idName } from '../../config'
import { getDefaultSession } from '../../utils/defaultsession'

import { CREATE_OPPORTUNITY } from './queries'

const styles = theme => ({
  root: {
    textAlign: 'left',
    margin: 'auto',
    width: '67%',
    minWidth: '600px',
    maxWidth: '1000px',
  },
  display1: {
    textAlign: 'left',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
})

const defaultState = {
  tabValue: 0,
  redirectToOpportunityTable: false,
  opportunity: {
    lastContacted: new Date(),
    openDate: new Date(),
    openDays: 0,
    session: getDefaultSession(),
    stage: 'ASSESSMENT',
    stageDate: new Date(),
    stageDays: 0,
    status: 'OPEN',
    year:
      getDefaultSession() === 'Winter'
        ? new Date().getUTCFullYear() + 1
        : new Date().getUTCFullYear(),
  },
  snackbar: {
    open: false,
    message: '',
    messageType: '',
  },
}

class CreateOpportunityFromStudent extends Component {
  constructor(props) {
    super(props)
    this.state = defaultState
    this.state.opportunity.responsibleEmployeeId = Cookies.get(idName)
  }

  returnToOpportunityTable = () => {
    this.setState({
      redirectToOpportunityTable: true,
    })
  }

  handleOpportunityChange = event => {
    const { name, value } = event.target
    this.setState(state => ({
      opportunity: {
        ...state.opportunity,
        [name]: value,
      },
    }))
  }

  render() {
    const { opportunity, redirectToOpportunityTable, snackbar } = this.state
    if (redirectToOpportunityTable) {
      return <Redirect push to="/opportunity" />
    }
    const {
      status,
      primaryInterest,
      session,
      stage,
      year,
      openDate,
      lastContacted,
      source,
      notes,
      responsibleEmployeeId,
    } = opportunity
    const { classes } = this.props
    const employeeId = Cookies.get(idName)
    const studentId = this.props.match.params[0]

    const handleSubmission = async CreateOpportunityMutation => {
      await CreateOpportunityMutation({
        variables: {
          input: {
            status: status,
            primaryInterest: primaryInterest,
            session: session,
            year: parseInt(year, 10),
            stage: stage,
            openDate: new Date(openDate),
            lastContacted: lastContacted,
            source: source,
            createdById: employeeId,
            responsibleEmployeeId: responsibleEmployeeId,
            notes: notes,
            studentId: studentId,
          },
        },
      })
      this.returnToOpportunityTable()
    }

    return (
      <Flex column grow={1} className={classes.root}>
        <br />
        <Text className={classes.display1} variant="h4">
          Create New Opportunity from Existing Student
        </Text>
        <br />
        <Paper>
          <OpportunityForm
            opportunity={opportunity}
            handleChange={this.handleOpportunityChange}
            showSave={false}
          />

          <Flex direction={'row-reverse'} p="10px">
            <Mutation mutation={CREATE_OPPORTUNITY}>
              {CreateOpportunityFunction => (
                <Button
                  onClick={() => {
                    if (!primaryInterest) {
                      this.setState({
                        snackbar: {
                          open: true,
                          message: 'At least one required field is missing',
                          messageType: 'error',
                        },
                      })
                    } else {
                      if (
                        window.confirm(
                          'Are you sure you want to submit? All empty fields will be filled with default or null values'
                        )
                      ) {
                        handleSubmission(CreateOpportunityFunction)
                      }
                    }
                  }}
                >
                  Submit
                </Button>
              )}
            </Mutation>

            <Button
              color="secondary"
              aria-label="Cancel"
              className={classes.button}
              onClick={() => {
                if (
                  window.confirm(
                    'Do you want to return to the table of opportunities?'
                  )
                ) {
                  this.returnToOpportunityTable()
                }
              }}
              style={{ marginRight: '10px' }}
            >
              Cancel
            </Button>
          </Flex>
        </Paper>
        <SnackbarNotification
          open={snackbar.open}
          handleClose={() => {
            this.setState({
              snackbar: {
                ...snackbar,
                open: false,
              },
            })
          }}
          message={snackbar.message}
          messageType={snackbar.messageType}
        />
      </Flex>
    )
  }
}

export default withStyles(styles)(CreateOpportunityFromStudent)
