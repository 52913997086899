import React from 'react'

import {
  Flex,
  Radio,
  RadioGroup,
  FormHelperText,
  FormControlLabel,
  FormControl,
  FormLabel,
  GradeSelector,
  Input,
  InputLabel,
  DatePicker,
  TextField,
} from './'
import withStyles from './Styles'

import { PhoneNumberInput } from '../utils'
import { userName } from '../utils/username'

const StudentForm = props => {
  const styles = props.classes

  return (
    <Flex
      column
      style={{
        align: 'center',
        flexGrow: 1,
      }}
    >
      <form id="addStudentForm">
        <Flex justify="space-evenly">
          <Flex grow={2} m="20px" style={{ maxWidth: '300px' }} column>
            <TextField
              required={props.required}
              InputLabelProps={{
                formlabelclasses: {
                  asterisk: styles.asterisk,
                },
              }}
              name="firstName"
              label="First Name"
              value={props.student.firstName || ''}
              className={styles.textField}
              fullWidth
              onChange={props.handleChange}
              disabled={props.readOnly}
            />
            <br />
            <TextField
              name="middleName"
              label="Middle name"
              value={props.student.middleName || ''}
              className={styles.textField}
              fullWidth
              onChange={props.handleChange}
              disabled={props.readOnly}
            />
            <br />
            <TextField
              required={props.required}
              InputLabelProps={{
                formlabelclasses: {
                  asterisk: styles.asterisk,
                },
              }}
              name="lastName"
              label="Last Name"
              value={props.student.lastName || ''}
              className={styles.textField}
              fullWidth
              onChange={props.handleChange}
              disabled={props.readOnly}
            />
            <br />
            <TextField
              name="username"
              label="Username"
              value={
                props.student.firstName && props.student.lastName
                  ? userName(props.student.firstName, props.student.lastName)
                  : ''
              }
              className={styles.textField}
              fullWidth
              InputProps={{
                readOnly: true,
              }}
              disabled={props.readOnly}
            />
            <br />
            <FormControl component="fieldset" className={styles.formControl}>
              <FormLabel component="legend">Gender</FormLabel>
              <RadioGroup
                aria-label="gender"
                name="gender"
                className={styles.group}
                value={props.student.gender}
                onChange={props.handleChange}
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <FormControlLabel
                  value="MALE"
                  control={<Radio color="primary" />}
                  label="Male"
                  disabled={props.readOnly}
                />
                <FormControlLabel
                  value="FEMALE"
                  control={<Radio color="secondary" />}
                  label="Female"
                  disabled={props.readOnly}
                />
              </RadioGroup>
            </FormControl>
          </Flex>
          <Flex grow={2} m="20px" style={{ maxWidth: '300px' }} column>
            <DatePicker
              name="birthdate"
              label="Birthdate"
              value={props.student.birthdate || null}
              onChange={props.handleChange}
              disabled={props.readOnly}
            />
            <br />
            <Flex>
              <GradeSelector
                className={styles.textField}
                value={props.student.grade || ''}
                onChange={props.handleChange}
                SelectProps={{
                  MenuProps: {
                    className: styles.menu,
                  },
                }}
                disabled={props.readOnly}
                style={{ minWidth: '75px', paddingRight: '15px' }}
              />
              <TextField
                // required
                name="school"
                label="School"
                value={props.student.school || ''}
                className={styles.textField}
                fullWidth
                onChange={props.handleChange}
                disabled={props.readOnly}
              />
            </Flex>
            <br />
            <FormControl className={styles.textField}>
              <InputLabel shrink>Student Phone #</InputLabel>
              <Input
                disabled={!props.student.over13}
                value={props.student.phoneNumber}
                onChange={props.handleChange}
                name="phoneNumber"
                inputComponent={PhoneNumberInput}
              />
              <FormHelperText>
                Optional; only if the student is 13 or older
              </FormHelperText>
            </FormControl>
            <br />
            <TextField
              name="email"
              label="Student Email"
              value={props.student.email || ''}
              className={styles.textField}
              fullWidth
              disabled={!props.student.over13}
              onChange={props.handleChange}
              helperText="Optional; only if the student is 13 or older"
            />
            <br />
            <TextField
              name="preferredLanguage"
              label="Preferred Language"
              value={
                props.student.familyAccount
                  ? props.student.familyAccount.primaryFamilyMember
                      .preferredLanguage
                  : props.primaryGuardian &&
                    props.primaryGuardian.preferredLanguage
                  ? props.primaryGuardian.preferredLanguage
                  : 'ENGLISH'
              }
              className={styles.textField}
              onChange={props.handleChange}
              disabled
            />
          </Flex>
        </Flex>
      </form>
    </Flex>
  )
}

const styles = theme => ({
  menu: {
    width: '20%',
  },
  asterisk: {
    color: 'red',
  },
})

export default withStyles(styles)(StudentForm)
