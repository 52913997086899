import React, { Component } from 'react'

import ReactTable from 'react-table'

import { dateFilterMethod } from '../../utils/datetime'

class LessonTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectAll: false,
      checked: this.props.classes.map(() => {
        return false
      }),
    }
    this.classTable = React.createRef()
  }

  updateClassTableView = filters => {
    const filteredTable = this.classTable.current
    if (filteredTable) {
      const filteredClasses = filteredTable.getResolvedState().sortedData
      this.props.updateClasses(filters, filteredClasses)
    }
  }

  render() {
    const columns = [
      {
        Cell: row => (
          <input
            type="checkbox"
            checked={this.props.checked[row.index] ? true : false}
            onChange={() => {
              this.props.handleSingleCheckboxChange(row.index)
            }}
          />
        ),
        sortable: false,
        filterable: false,
        resizable: false,
        width: 28,
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: 100,
        Cell: props => {
          return (
            <span
              style={{
                color:
                  props.value === 'OPENED'
                    ? '#27AE60'
                    : props.value === 'CLOSED'
                    ? '#EB5757'
                    : '#000000',
              }}
            >
              {props.value}
            </span>
          )
        },
        filterMethod: (filter, row) => {
          if (filter.value !== 'ALL') {
            return row[filter.id] === filter.value
          }
          return true
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: '100%' }}
            value={filter ? filter.value : 'ALL'}
          >
            <option value="ALL">ALL</option>
            <option value="DRAFT">Draft</option>
            <option value="PUBLISHED">Published</option>
            <option value="OPENED">Opened</option>
            <option value="CLOSED">Closed</option>
            <option value="CANCELLED">Cancelled</option>
          </select>
        ),
      },
      {
        Header: <span title="Trial Availability">T</span>,
        accessor: 'allowTrial',
        width: 45,
        Cell: row => <div style={{ textAlign: 'center' }}>{row.value}</div>,
      },
      {
        Header: 'Start Date',
        accessor: 'startDate',
        width: 100,
        filterMethod: dateFilterMethod,
      },
      {
        Header: 'End Date',
        accessor: 'endDate',
        width: 100,
        filterMethod: dateFilterMethod,
      },
      {
        Header: 'Year',
        accessor: 'year',
        width: 100,
      },
      {
        Header: 'Quarter',
        accessor: 'quarter',
        width: 100,
        Cell: props => {
          return <span>{props.value ? props.value : 'UNKNOWN'}</span>
        },
        filterMethod: (filter, row) => {
          if (filter.value !== 'ALL' && filter.value !== 'UNKNOWN') {
            return row[filter.id] === filter.value
          }
          if (filter.value === 'UNKNOWN') {
            return row[filter.id] === null
          }
          return true
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: '100%' }}
            value={filter ? filter.value : 'ALL'}
          >
            <option value="ALL">ALL</option>
            <option value="Fall">Fall</option>
            <option value="Winter">Winter</option>
            <option value="Spring">Spring</option>
            <option value="PreSummer">PreSummer</option>
            <option value="Summer">Summer</option>
            <option value="OnDemand"></option>
            <option value="Tutoring">Tutoring</option>
            <option value="Contest">Contest</option>
          </select>
        ),
      },
      {
        Header: 'Session',
        accessor: 'session',
        width: 100,
      },
      {
        Header: 'Class Code',
        accessor: 'code',
        width: 100,
      },
      {
        Header: 'Class Title',
        accessor: 'title',
        width: 250,
      },
      {
        Header: 'Enrollments',
        accessor: 'enrollmentCount',
        style: { textAlign: 'right' },
        width: 100,
      },
      {
        Header: 'Day',
        accessor: 'day',
        width: 100,
      },
      {
        Header: 'Time',
        accessor: 'time',
        width: 150,
      },
      {
        Header: 'Location',
        accessor: 'centerLocation',
        width: 150,
      },
      {
        Header: 'Instructor',
        accessor: 'instructor',
        width: 200,
      },
    ]
    const { filterable, filtered, sorted, classes } = this.props
    return (
      <div align="left">
        <ReactTable
          ref={this.classTable}
          filterable={filterable}
          data={classes}
          columns={columns}
          className="-highlight"
          defaultPageSize={50}
          defaultSorted={sorted}
          defaultFiltered={filtered}
          onFilteredChange={this.updateClassTableView}
          style={{ fontSize: '10pt' }}
          defaultFilterMethod={(filter, row) =>
            row[filter.id] &&
            row[filter.id]
              .toString()
              .toLowerCase()
              .includes(filter.value.toString().toLowerCase())
          }
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              onClick: (e, handleOriginal) => {
                if (column.Header !== undefined) {
                  this.props.onRowClick(rowInfo)
                }
                if (handleOriginal) {
                  handleOriginal()
                }
              },
            }
          }}
          getTheadProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                fontWeight: 'bold',
                borderBottom: '1px solid #E5E5E5',
              },
            }
          }}
        />
      </div>
    )
  }
}

export default LessonTable
