import React from 'react'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import { makeStyles } from '@material-ui/core/styles'
import { StatusIcon } from '../Attendance/Icons'

import {
  Button,
  ButtonGroup,
  Divider,
  Flex,
  Paper,
  Text,
} from '../../components'

const TIME_PERIODS = [
  '8 a.m',
  '9 a.m',
  '10 a.m',
  '11 a.m',
  '12 p.m',
  '1 p.m',
  '2 p.m',
  '3 p.m',
  '4 p.m',
  '5 p.m',
  '6 p.m',
  '7 p.m',
]

const useStyles = makeStyles(() => ({
  iconBtn: {},
  icon: {},
}))

const getWeekItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  background: isDragging && 'lightgreen',
  display: 'flex',
  alignSelf: 'center',
  justifySelf: 'center',
  width: '90px',
  height: '60px',
  ...draggableStyle,
})

const getWeekListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  display: 'flex',
  flexDirection: 'row',
  width: '120px',
  height: '80px',
  alignItems: 'space-evenly',
  justifyContent: 'space-evenly',
})

export const TimeSlot = props => {
  const { id, session, handleOpenAttendanceDialog } = props
  const classes = useStyles()

  return (
    <Paper variant={'outlined'} style={{ width: '90px', height: '60px' }}>
      <Flex style={{ flexDirection: 'column' }}>
        <Flex
          style={{
            flexDirection: 'column',
            justifyContent: 'space-evenly',
          }}
        >
          <Text
            color="primary"
            style={{
              fontWeight: 'bold',
              fontSize: '15px',
            }}
          >
            {`${
              session.classroom.courseCode
            } - ${session.order.toString().padStart(2, '0')}`}
          </Text>
        </Flex>
        <Divider orientation="vertical" />
        <ButtonGroup
          variant="text"
          aria-label="split button"
          orientation="vertical"
        >
          <Button
            className={classes.iconButton}
            value={JSON.stringify({
              ...session,
              droppableId: id,
            })}
            color="primary"
            variant="text"
            aria-label="attendance-tutorsession-btn"
            onClick={handleOpenAttendanceDialog}
          >
            <StatusIcon status={session.status} />
          </Button>
        </ButtonGroup>
      </Flex>
    </Paper>
  )
}

// day schedule
// day container for schedules

// props:
// -> items: map of datetime to tutorsessions

// Container key:
// -> dateTime in ISOString~studentId

export const DaySchedule = props => {
  const { items, handleOpenAttendanceDialog } = props

  return (
    <div
      style={{
        display: 'flex',
        width: '1600px',
        flexDirection: 'column',
        alignItems: 'center',
        flexWrap: 'wrap',
      }}
    >
      <div
        style={{
          display: 'flex',
          width: '1600px',
          height: '40px',
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: '10px',
          border: '1px solid #f1f1f1',
        }}
      >
        <div
          variant={'outlined'}
          style={{
            display: 'flex',
            width: '120px',
            height: '40px',
            borderRight: '1px solid #f1f1f1',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Text
            color="primary"
            style={{ fontWeight: 'bold', textAlign: 'center' }}
          >
            Student
          </Text>
        </div>
        {TIME_PERIODS.map(time => (
          <div
            key={time}
            variant={'outlined'}
            style={{
              display: 'flex',
              width: '120px',
              height: '40px',
              borderRight: '1px solid #f1f1f1',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Text
              color="primary"
              style={{ fontWeight: 'bold', textAlign: 'center' }}
            >
              {time}
            </Text>
          </div>
        ))}
      </div>
      {Object.keys(items).map(studentId => {
        const itemKeys = Array.from(items[studentId].schedule.keys())
        return (
          <div
            key={studentId}
            style={{
              display: 'flex',
              width: '1600px',
              height: '80px',
              flexDirection: 'row',
              alignItems: 'center',
              marginBottom: '10px',
            }}
          >
            <div
              style={{
                width: '120px',
                height: '80px',
                borderRight: '1px solid #f1f1f1',
              }}
            >
              <Text color="primary" style={{ fontWeight: 'bold' }}>
                {items[studentId].student.firstName}{' '}
                {items[studentId].student.lastName}
              </Text>
            </div>
            {TIME_PERIODS.map((_time, index) => (
              <Droppable
                key={`${itemKeys[index]}~${studentId}`}
                droppableId={`${itemKeys[index]}~${studentId}`}
              >
                {(provided, snapshot) => (
                  <div
                    style={{
                      display: 'flex',
                      borderRight: '1px solid #f1f1f1',
                      alignItems: 'space-evenly',
                      justifyContent: 'space-evenly',
                      flexDirection: 'column',
                      width: '120px',
                      height: '80px',
                    }}
                  >
                    <div
                      ref={provided.innerRef}
                      style={getWeekListStyle(snapshot.isDraggingOver)}
                    >
                      {provided.placeholder}
                      {items[studentId].schedule
                        .get(itemKeys[index])
                        .map(item => {
                          return (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getWeekItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                >
                                  {provided.placeholder}
                                  <TimeSlot
                                    id={itemKeys[index]}
                                    session={item}
                                    handleOpenAttendanceDialog={
                                      handleOpenAttendanceDialog
                                    }
                                  />
                                </div>
                              )}
                            </Draggable>
                          )
                        })}
                    </div>
                  </div>
                )}
              </Droppable>
            ))}
          </div>
        )
      })}
    </div>
  )
}

export default DaySchedule
