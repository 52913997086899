import React from 'react'
import { Route, Switch } from 'react-router-dom'

import TutorStudentDetailView from './TutorStudentDetailView'
import TutorStudentTableView from './TutorStudentTableView'

const TutorStudentView = props => {
  const { url } = props.match

  return (
    <Switch>
      <Route
        path={`${url}/tutor-schedule/*`}
        render={props => <TutorStudentDetailView {...props} />}
      />
      <Route
        path={`${url}`}
        render={props => <TutorStudentTableView {...props} />}
      />
    </Switch>
  )
}

export default TutorStudentView
