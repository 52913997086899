import React from 'react'
import Select from 'react-select'

import { MenuItem, Text, TextField } from './'
import withStyles from './Styles'

const styles = theme => ({
  root: {
    height: 250,
  },
  input: {
    display: 'flex',
    padding: 0,
  },
  valueContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  noOptionsMessage: {
    fontSize: 16,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    fontSize: 16,
  },
})

const NoOptionsMessage = props => (
  <Text
    color="textSecondary"
    className={props.selectProps.classes.noOptionsMessage}
    {...props.innerProps}
  >
    {props.children}
  </Text>
)

const inputComponent = ({ inputRef, ...props }) => (
  <div ref={inputRef} {...props} />
)

const Control = props => (
  <TextField
    fullWidth
    InputProps={{
      inputComponent,
      inputProps: {
        className: props.selectProps.classes.input,
        ref: props.innerRef,
        children: props.children,
        ...props.innerProps,
      },
    }}
  />
)

const Option = props => (
  <MenuItem
    buttonRef={props.innerRef}
    selected={props.isFocused}
    component="div"
    style={{
      fontWeight: props.isSelected ? 500 : 400,
    }}
    {...props.innerProps}
  >
    {props.children}
  </MenuItem>
)

const Placeholder = props => (
  <Text
    color="textSecondary"
    className={props.selectProps.classes.placeholder}
    {...props.innerProps}
  >
    {props.children}
  </Text>
)

const SingleValue = props => (
  <Text className={props.selectProps.classes.singleValue} {...props.innerProps}>
    {props.children}
  </Text>
)

const AutocompleteField = props => {
  const ValueContainer = ({ selectProps, children }) => (
    <div
      style={props.styleProps ? props.styleProps : {}}
      className={selectProps.classes.valueContainer}
    >
      {children}
    </div>
  )

  const components = {
    Option,
    Control,
    NoOptionsMessage,
    Placeholder,
    SingleValue,
    ValueContainer,
  }
  return (
    <Select
      required={props.required}
      InputLabelProps={{
        formlabelclasses: props.required && {
          asterisk: styles.asterisk,
        },
      }}
      {...(props.selectedValue && props.selectedValue.value
        ? { value: props.selectedValue }
        : {})}
      classes={props.classes}
      options={props.options}
      components={components}
      onChange={selected => props.onChange(selected.value)}
      placeholder={props.placeholder || 'Search...'}
      cache={false}
      {...props}
    />
  )
}

export default withStyles(styles)(AutocompleteField)
