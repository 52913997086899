import React, { Fragment, useState } from 'react'
import { Box, IconButton, ListItemIcon, Menu, MenuItem } from '@mui/material'
import Logout from '@mui/icons-material/Logout'

import { useHistory } from 'react-router'
import { useDispatch } from 'react-redux'
import { domain, tokenName, idName } from '../config'
import { logout } from '../actions'
import Cookies from 'js-cookie'

const AccountMenu = ({ employee }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [anchorEl, setAnchorEl] = useState(null)

  const userName = employee.user_name
  const open = Boolean(anchorEl)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  function handleLogout(event) {
    const options = {
      domain,
      path: '/',
      sameSite: 'lax',
    }
    Cookies.remove(tokenName, options)
    Cookies.remove(idName, options)

    dispatch(logout())
    history.push('/')
  }

  return (
    <Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <IconButton
          onClick={handleClick}
          color="inherit"
          size="small"
          sx={{ mr: 2 }}
        >
          {userName}
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </Fragment>
  )
}

export default AccountMenu
