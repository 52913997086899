import React, { Component } from 'react'

import { Collapse, Accordion, AccordionDetails, AccordionSummary } from '.'

class AccordionForm extends Component {
  render() {
    const { className, title, form, overflow, expanded } = this.props

    return (
      <Accordion
        defaultExpanded={expanded ? true : false}
        collapseprops={{ style: overflow && { overflow: 'visible' } }}
      >
        <AccordionSummary title={title} className={className} />
        <Collapse in={expanded} timeout="auto" style={{ overflow: 'visible' }}>
          <AccordionDetails>{form}</AccordionDetails>
        </Collapse>
      </Accordion>
    )
  }
}

export default AccordionForm
