import React, { Component } from 'react'
import { Mutation, Query } from 'react-apollo'
import { Redirect, Link } from 'react-router-dom'

import {
  Button,
  ErrorMessage,
  AccordionForm,
  Flex,
  LoadingMessage,
  MakeupForm,
  StudentForm,
  Paper,
  SnackbarNotification,
  Text,
  withStyles,
} from '../../components'

import {
  GET_CLASSROOMS,
  GET_MAKEUP,
  GET_MAKEUPS,
  UPDATE_MAKEUP,
  ARCHIVE_MAKEUP,
} from './queries'
import { formatBirthday } from '../../utils/datetime'

const styles = theme => ({
  root: {
    textAlign: 'left',
    margin: 'auto',
    width: '67%',
    minWidth: '600px',
    maxWidth: '1000px',
  },
  display1: {
    textAlign: 'left',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  expansionDetail: {
    overflow: 'visible',
  },
})

class MakeupDetailView extends Component {
  render() {
    const makeupId = this.props.match.params[0]
    return (
      <Query
        fetchPolicy={'cache-and-network'}
        query={GET_MAKEUP}
        variables={{ id: makeupId }}
      >
        {({ loading, error, data }) => {
          if (loading) return <LoadingMessage />
          if (error) return <ErrorMessage error={error} />

          return <InnerMakeupDetailView {...this.props} makeup={data.makeup} />
        }}
      </Query>
    )
  }
}

class InnerMakeupDetailView extends Component {
  constructor(props) {
    super(props)
    const { fromLesson, toLesson, student } = props.makeup
    this.state = {
      dateString: new Date().toISOString(),
      redirect: false,
      redirectTo: '',
      makeup: {
        ...props.makeup,
        fromClass: fromLesson && fromLesson.classroom,
        toClass: toLesson && toLesson.classroom,
        fromClassId: fromLesson && fromLesson.classroom.id,
        toClassId: toLesson && toLesson.classroom.id,
        fromLessonId: fromLesson && fromLesson.id,
        toLessonId: toLesson ? toLesson.id : 'absent',
        student: {
          ...student,
          birthdate: formatBirthday(student.birthdate),
        },
      },
      snackbar: {
        open: false,
        message: '',
        messageType: '',
        willRedirect: false,
      },
    }
  }

  returnToMakeupTable = () => {
    this.setState({
      redirect: true,
      redirectTo: '/makeup',
    })
  }

  handleMakeupChange = event => {
    const { name, value } = event.target
    this.setState(state => ({
      makeup: {
        ...state.makeup,
        [name]: value,
      },
    }))
  }

  changeSelectedStudent = value => {
    this.setState({
      selectedStudent: value,
    })
  }

  isActiveClassroom = classroom => {
    return classroom.status === 'OPENED' || classroom.status === 'PUBLISHED'
  }

  render() {
    const { makeup, redirect, redirectTo, snackbar } = this.state
    const {
      fromClassId,
      fromLessonId,
      notes,
      status,
      student,
      toClassId,
      toLessonId,
    } = makeup
    if (redirect) {
      return <Redirect push to={redirectTo} />
    }

    const handleSubmission = async UMmutation => {
      await UMmutation({
        variables: {
          id: makeup.id,
          input: {
            fromLessonId: fromLessonId,
            toLessonId: toLessonId === 'absent' ? null : toLessonId,
            status: status,
            notes: notes,
          },
        },
        refetchQueries: [
          {
            query: GET_MAKEUPS,
          },
        ],
      })
      this.returnToMakeupTable()
    }

    const { firstName, lastName } = student
    return (
      <Flex
        column
        grow={1}
        style={{
          textAlign: 'left',
          margin: 'auto',
          width: '67%',
          minWidth: '600px',
          maxWidth: '1000px',
        }}
      >
        <br />
        <Link to="/makeup">{'< back to Makeup List'}</Link>
        <br />
        <Text variant="h4">{`Makeup for ${firstName} ${lastName}`}</Text>
        <br />
        <Flex>
          <Mutation mutation={ARCHIVE_MAKEUP}>
            {(archiveMakeup, { data }) => (
              <Button
                color="secondary"
                onClick={() => {
                  if (
                    window.confirm('Are you sure want to archive this makeup?')
                  ) {
                    archiveMakeup({
                      variables: {
                        id: makeup.id,
                      },
                      refetchQueries: [{ query: GET_MAKEUPS }],
                    })
                    this.setState({
                      snackbar: {
                        open: true,
                        message: 'Makeup archived',
                        messageType: 'success',
                        willRedirect: true,
                      },
                    })
                  }
                }}
              >
                Archive Makeup
              </Button>
            )}
          </Mutation>
          <Button
            component={Link}
            style={{ marginLeft: '10px' }}
            to={`/task/add-new-task-from-student/${student.id}`}
          >
            Create Makeup Work Task
          </Button>
        </Flex>
        <br />
        <Paper>
          <AccordionForm
            expanded
            title="Makeup Information"
            form={
              <Flex
                column
                style={{
                  align: 'center',
                  flexGrow: 1,
                }}
              >
                <Query
                  query={GET_CLASSROOMS}
                  variables={{
                    filter: {
                      $or: [{ id: fromClassId || '' }, { id: toClassId || '' }],
                    },
                  }}
                >
                  {({ loading, error, data }) => {
                    if (loading) return <LoadingMessage />
                    if (error) return <ErrorMessage error={error} />

                    const filteredClasses = data.classrooms

                    return (
                      <MakeupForm
                        showCreatedBy
                        makeup={makeup}
                        handleChange={this.handleMakeupChange}
                        fromClasses={filteredClasses}
                        toClasses={filteredClasses}
                        fromMakeupDetail
                      />
                    )
                  }}
                </Query>
                <Flex direction={'row-reverse'} align={'center'}>
                  <Mutation mutation={UPDATE_MAKEUP}>
                    {UMFunction => (
                      <Button
                        onClick={() => {
                          if (!status || !fromLessonId) {
                            this.setState({
                              snackbar: {
                                open: true,
                                message:
                                  'At least one required field is missing',
                                messageType: 'error',
                                willRedirect: false,
                              },
                            })
                          } else {
                            handleSubmission(UMFunction)
                          }
                        }}
                      >
                        Save Changes
                      </Button>
                    )}
                  </Mutation>
                  <Text style={{ marginRight: '10px' }}>
                    Make sure to verify all information before hitting save!
                  </Text>
                </Flex>
              </Flex>
            }
          />

          {student && (
            <AccordionForm
              expanded
              title="Student Detail (Read Only)"
              form={
                <StudentForm
                  readOnly
                  student={student}
                  handleChange={() => {}}
                />
              }
            />
          )}
        </Paper>
        <SnackbarNotification
          open={snackbar.open}
          handleClose={() => {
            this.setState({
              snackbar: {
                ...snackbar,
                open: false,
              },
            })
            if (snackbar.willRedirect) this.returnToMakeupTable()
          }}
          message={snackbar.message}
          messageType={snackbar.messageType}
        />
      </Flex>
    )
  }
}

export default withStyles(styles)(MakeupDetailView)
