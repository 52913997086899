import React, { Component, Fragment } from 'react'
import { Query, withApollo } from 'react-apollo'
import { Link, Redirect } from 'react-router-dom'
import { counselingSummaryEmailTemplate } from './Email'

import {
  GET_COUNSELING_MEETING,
  UPDATE_COUNSELING_MEETING,
  GET_COUNSELING_ENROLLMENT,
  COUNSELING_SUMMARY,
  SEND_COUNSELING_SUMMARY,
  ARCHIVE_COUNSELING_SUMMARY,
} from './queries'
import {
  Button,
  DatePicker,
  ErrorMessage,
  AccordionForm,
  Flex,
  LoadingMessage,
  MenuItem,
  Text,
  TextField,
} from '../../components'

import { debug } from '../../config'

const CounselingMeetingDetailView = props => {
  const counselingId = props.match.params[0]
  const meetingId = props.match.params[1]

  return (
    <Query
      fetchPolicy="cache-and-network"
      query={GET_COUNSELING_MEETING}
      variables={{ id: meetingId }}
    >
      {({ loading, error, data }) => {
        if (loading) return <LoadingMessage />
        if (error) return <ErrorMessage error={error} />
        return (
          <InnerCounselingMeetingDetailView
            {...props}
            counselingId={counselingId}
            counselingMeeting={data.counselingMeeting}
            meetingId={meetingId}
          />
        )
      }}
    </Query>
  )
}

class InnerCounselingMeetingDetailView extends Component {
  constructor(props) {
    super(props)
    this.state = { ...this.props.counselingMeeting }
  }

  handleChange = event => {
    const { name, value } = event.target
    this.setState({
      [name]: value,
    })
  }

  returnToCounselingDetail = () => {
    const { counselingId } = this.props
    this.setState({
      redirect: true,
      redirectTo: `/counseling/${counselingId}`,
    })
  }

  render() {
    const { contactMethods, counselingId, meetingId } = this.props
    const {
      endDateTime,
      method,
      purpose,
      redirect,
      redirectTo,
      resolution,
      startDateTime,
      counselingEnrollment,
    } = this.state

    if (redirect) {
      return <Redirect push to={redirectTo} />
    }

    return (
      <Flex
        column
        grow={1}
        style={{
          textAlign: 'left',
          margin: 'auto',
          width: '67%',
          minWidth: '600px',
          maxWidth: '1000px',
        }}
      >
        <br />
        <Link to={`/counseling/${counselingId}`}>
          {'< back to Counseling Detail'}
        </Link>
        <br />
        <Flex justify="space-between">
          <Flex column maxWidth="850px">
            <Text variant="h5">{`Counseling Meeting for ${counselingEnrollment.student.firstName} ${counselingEnrollment.student.lastName}`}</Text>
            <br />
            <Flex></Flex>
          </Flex>
        </Flex>
        <AccordionForm
          expanded
          title="Counseling Meeting Detail"
          form={
            <Flex column style={{ align: 'center', flexGrow: 1 }}>
              <Flex justify="space-evenly">
                <Flex grow={2} m="20px" style={{ maxWidth: '300px' }} column>
                  <TextField
                    name="method"
                    label="Method"
                    select
                    value={method}
                    onChange={this.handleChange}
                  >
                    {contactMethods.map(option => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                  <br />
                  <TextField
                    name="purpose"
                    label="Purpose"
                    value={purpose}
                    multiline
                    onChange={this.handleChange}
                  />
                  <br />
                  <TextField
                    name="resolution"
                    label="Resolution"
                    value={resolution}
                    multiline
                    onChange={this.handleChange}
                  />
                </Flex>
                <Flex grow={2} m="20px" style={{ maxWidth: '300px' }} column>
                  <DatePicker
                    name="startDateTime"
                    label="Start Date"
                    value={startDateTime || null}
                    onChange={this.handleChange}
                  />
                  <br />
                  <DatePicker
                    name="endDateTime"
                    label="End Date"
                    value={endDateTime || null}
                    onChange={this.handleChange}
                  />
                </Flex>
              </Flex>
              <Flex direction={'row-reverse'} align={'center'} p="10px">
                <Fragment>
                  <Button
                    onClick={async () => {
                      const { client } = this.props
                      const {
                        createdBy: employee,
                      } = this.props.counselingMeeting
                      const studentName = `${counselingEnrollment.student.firstName} ${counselingEnrollment.student.lastName}`
                      const message = counselingSummaryEmailTemplate({
                        studentName,
                        employeeName: `${employee.firstName} ${employee.lastName}`,
                        resolution,
                        startDateTime,
                        endDateTime,
                      })

                      if (window.confirm('Are you sure you want to save?')) {
                        const input = {
                          method,
                          purpose,
                          resolution,
                          startDateTime,
                          endDateTime,
                        }
                        await client.mutate({
                          mutation: UPDATE_COUNSELING_MEETING,
                          variables: {
                            id: meetingId,
                            input,
                          },
                          refetchQueries: [
                            {
                              query: GET_COUNSELING_ENROLLMENT,
                              variables: { id: counselingId },
                            },
                          ],
                        })

                        const { data: summaryData } = await client.mutate({
                          mutation: COUNSELING_SUMMARY,
                          variables: {
                            input: {
                              name: `CounselingSummary(${meetingId})`,
                              subject: `Counseling Meeting: ${purpose}: ${studentName}`,
                              text: message,
                              html: message,
                            },
                          },
                        })

                        await client.mutate({
                          mutation: SEND_COUNSELING_SUMMARY,
                          variables: {
                            recipients: debug
                              ? ['dev@ardentlabs.io']
                              : ['wendy.li@ardentacademy.com'],
                            bccAddresses: debug ? [] : [employee.workEmail],
                            templateName: `CounselingSummary(${meetingId})`,
                            templateData: {
                              counselingMeetingId: meetingId,
                            },
                          },
                        })

                        //archive template after using it
                        const { id: email_id } = summaryData.createEmailTemplate
                        await client.mutate({
                          mutation: ARCHIVE_COUNSELING_SUMMARY,
                          variables: {
                            id: email_id,
                          },
                        })

                        this.returnToCounselingDetail()
                      }
                    }}
                    style={{ margin: '5px' }}
                  >
                    Save Changes
                  </Button>
                  <Text style={{ marginRight: '10px' }}>
                    Make sure to verify all information before hitting save!
                  </Text>
                </Fragment>
              </Flex>
            </Flex>
          }
        />
      </Flex>
    )
  }
}

export default withApollo(CounselingMeetingDetailView)
