import gql from 'graphql-tag'

export const GET_CREDITS = gql`
  query getCredits {
    credits {
      id
      creditType
      invoice {
        id
        invoiceNumber
      }
      familyAccount {
        id
        primaryFamilyMember {
          id
          firstName
          lastName
          email
          phoneNumber
        }
      }
      responsibleEmployee {
        id
        firstName
        lastName
      }
      status
      creditNumber
      issueDate
      amount
      balance
    }
  }
`

export const GET_CREDIT = gql`
  query getCredit($filter: Filters!) {
    credits(filter: $filter) {
      id
      creditType
      invoice {
        id
        invoiceNumber
      }
      familyAccount {
        id
        balance
        primaryFamilyMember {
          id
          firstName
          lastName
          email
          phoneNumber
        }
      }
      refunds {
        id
        identification
        amount
        dateReceived
        method
      }
      creditNumber
      status
      issueDate
      amount
      balance
      refundable
      notes
    }
  }
`

export const GET_PAYMENTS = gql`
  query getPayments($id: ID!) {
    invoice(id: $id) {
      payments {
        id
        identification
        method
        amount
      }
    }
  }
`
export const UPDATE_CREDIT = gql`
  mutation updateCredit($id: ID!, $input: UpdateCreditInput!) {
    updateCredit(id: $id, input: $input) {
      id
    }
  }
`

export const ARCHIVE_CREDIT = gql`
  mutation archiveCredit($id: ID!) {
    archiveCredit(id: $id) {
      id
    }
  }
`

export const CREATE_REFUND = gql`
  mutation createRefund($input: CreateRefundInput!) {
    createRefund(input: $input) {
      id
    }
  }
`
export const GET_AUTHORIZE_TRANSACTION = gql`
  query getAuthorizeTransaction($transactionId: String!) {
    getAuthorizeNetTransaction(transactionId: $transactionId) {
      transactionId
      settleAmount
      creditCard {
        cardNumber
        cardType
      }
      billTo {
        firstName
        lastName
      }
    }
  }
`
export const DO_AUTHORIZE_REFUND = gql`
  mutation doAuthorizeRefund(
    $amount: String!
    $invoiceId: String!
    $transactionId: String!
    $creditCardEnding: String!
  ) {
    refundAuthorizeNetTransaction(
      amount: $amount
      invoiceId: $invoiceId
      transactionId: $transactionId
      creditCardEnding: $creditCardEnding
    ) {
      success
      errorMessage
      transactionId
      resultCode
      messages {
        code
        text
      }
    }
  }
`
