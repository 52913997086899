import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useLazyQuery } from 'react-apollo'
import { Paper } from '@mui/material'

import TutorEmployeeTable from './TutorEmployeeTable'
import TutorEmployeeSidebar from './TutorEmployeeSidebar'

import { COUNT_EMPLOYEES, GET_EMPLOYEES } from './queries'
import AddTutorDialog from './AddTutorDialog'
import { arrayToObjectFilter, arrayToObjectSort } from '../../utils'

const TutorEmployeeTableView = props => {
  const history = useHistory()

  const [totalTutors, setTotalTutors] = useState(0)
  const [tutors, setTutors] = useState([])
  const [filtered, setFiltered] = useState([{ id: 'status', value: 'ACTIVE' }])

  const [sorted, setSorted] = useState([
    {
      id: 'lastName',
      desc: false,
    },
  ])
  const [page, setPage] = useState(0)
  const [pages, setPages] = useState(1)
  const [pageSize] = useState(25)

  // const [selectAll, setSelectAll] = useState(false)
  const [checked] = useState({})
  const [openAddTutor, setOpenAddTutor] = useState(false)

  const [getPages] = useLazyQuery(COUNT_EMPLOYEES, {
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        ...arrayToObjectFilter(filtered),
        status: 'ACTIVE',
        isTutor: true,
      },
    },
    onCompleted: results => {
      const pages = Math.ceil(results.countEmployees / pageSize)
      setPages(pages)
      setTotalTutors(results.countEmployees)
    },
  })

  const [getTutors] = useLazyQuery(GET_EMPLOYEES, {
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        ...arrayToObjectFilter(filtered),
        status: 'ACTIVE',
        isTutor: true,
      },
      sort: arrayToObjectSort(sorted),
      page: { skip: page * pageSize, limit: pageSize },
    },
    onCompleted: results => {
      const tutors = results.employees.map(tutor => formatTutor(tutor))
      setTutors(tutors)
    },
  })

  useEffect(() => {
    getPages()
    getTutors()
  }, [getPages, getTutors])

  const handlePageChange = pageIndex => {
    setPage(pageIndex)
    getTutors()
  }

  const handleRowClick = rowInfo => {
    history.push(`/tutor_employees/${rowInfo.original.id}`)
  }

  const handleFetchData = (_, table) => {
    const { page, filtered, sorted } = table.state
    setPage(page)
    setFiltered(filtered)
    setSorted(sorted)
    getTutors()
  }

  const toggleAddTutorDialog = () => {
    setOpenAddTutor(!openAddTutor)
  }

  const handleSingleCheckboxChange = id => {
    const { checked, selectAll } = this.state
    checked[id] = !checked[id]
    this.setState({
      selectAll: !checked[id] && selectAll ? false : selectAll,
      checked,
    })
  }

  return (
    <Paper sx={{ display: 'flex' }}>
      <AddTutorDialog
        open={openAddTutor}
        close={toggleAddTutorDialog}
        client={props.client}
        // triggerRefetch={empRefetch}
      />
      <TutorEmployeeSidebar
        tutors={tutors}
        totalTutors={totalTutors}
        filtered={filtered}
        // statusCounts={{
        //   ACTIVE: activeData.countEmployees,
        //   INACTIVE: totalData.countEmployees - activeData.countEmployees,
        // }}
        // onFilteredChange={onFilteredChange}
        toggleAddTutorDialog={toggleAddTutorDialog}
      />
      <Paper sx={{ width: '100%' }}>
        <TutorEmployeeTable
          filterable={true}
          onRowClick={handleRowClick}
          tutors={tutors}
          sorted={sorted}
          pages={pages}
          checked={checked}
          // refetch={empRefetch}
          filtered={filtered}
          handleFetchData={handleFetchData}
          handlePageChange={handlePageChange}
          // onFilteredChange={onFilteredChange}
          handleSingleCheckboxChange={handleSingleCheckboxChange}

          // checked={checked}
        />
      </Paper>
    </Paper>
  )
}

const formatTutor = ({
  id,
  status,
  lastLogin,
  firstName,
  lastName,
  gender,
  workEmail,
  personalEmail,
  mobileNumber,
  position,
}) => ({
  id: id,
  status: status,
  lastLogin: lastLogin,
  firstName: firstName,
  lastName: lastName,
  gender: gender,
  email: workEmail,
  personalEmail: personalEmail,
  phone: mobileNumber,
  position: position,
})

export default TutorEmployeeTableView
