import React, { Component } from 'react'
import { Query, withApollo } from 'react-apollo'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Flex,
  MenuItem,
  Paper,
  TextField,
} from '../../components'
import { GET_DISCOUNT, GET_FAMILY_STUDENTS } from './queries'

class ApplyDiscountForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      editing: false,
      discounts: [],
      chosenStudentId: null,
      chosenStudentName: null,
    }
  }

  componentDidMount() {
    this.props.client.query({ query: GET_DISCOUNT }).then(({ data }) => {
      this.setState({
        discounts: data.discounts.map(d => {
          return {
            discountId: d.id,
            unitPrice: d.amount * -1,
            label: d.label,
            quantity: 0,
          }
        }),
      })
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedItem !== this.props.selectedItem) {
      if (this.props.selectedItem === null) {
        this.setState({
          editing: false,
          quantity: 1,
          selectName: '',
          discountId: null,
          price: 0,
          chosenStudentId: null,
          chosenStudentName: null,
        })
      } else if (this.props.selectedItem.itemType === 'DISCOUNT') {
        const {
          quantity,
          price,
          discount,
          student,
          unitPrice,
        } = this.props.selectedItem
        this.setState({
          editing: true,
          discounts: [
            {
              discountId: discount.id,
              label: discount.label,
              unitPrice,
              quantity,
              price,
            },
          ],
          chosenStudentId: student ? student.id : null,
          chosenStudentName: student
            ? `${student.firstName} ${student.lastName}`
            : null,
        })
      }
    }
  }

  handleSubmit = () => {
    const { editing, discounts, chosenStudentId } = this.state
    const { client } = this.props

    const dsc = discounts
      .filter(d => d.quantity !== 0)
      .map(d => {
        return {
          discountId: d.discountId,
          unitPrice: d.unitPrice,
          quantity: parseFloat(d.quantity),
          invoiceId: this.props.invoice.id,
          itemType: 'DISCOUNT',
          price: d.quantity * d.unitPrice,
          studentId: d.label === 'Learn More' ? chosenStudentId : null,
        }
      })

    if (!editing) {
      if (dsc.length === 0) {
        alert('Enter atleast 1 discount')
        return
      }

      this.props.addHandler(client, dsc)
    } else {
      if (!(dsc[0].discountId && dsc[0].quantity)) {
        alert('enter all fields')
        return
      }
      this.props.updateHandler(client, this.props.selectedItem.id, dsc[0])
    }
    this.props.close()
  }

  render() {
    const { editing, discounts, chosenStudentName } = this.state

    return (
      <Dialog open={this.props.open} fullWidth={true} maxWidth={'lg'}>
        <DialogTitle>{'Apply Discount'}</DialogTitle>
        <DialogContent>
          <Paper
            style={{
              padding: '15px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            {editing ? (
              <Query fetchPolicy={'cache-and-network'} query={GET_DISCOUNT}>
                {({ loading, error, data }) => {
                  if (loading) {
                    return (
                      <TextField
                        disabled
                        label="loading..."
                        style={{ width: '25%' }}
                      />
                    )
                  }
                  if (error) {
                    return (
                      <TextField
                        disabled
                        label="error"
                        style={{ width: '25%' }}
                      />
                    )
                  }
                  return (
                    <div>
                      <TextField
                        select
                        label="Select discount"
                        value={discounts[0].label}
                        onChange={e => {}}
                        style={{ width: '25%' }}
                      >
                        {data.discounts.map(d => (
                          <MenuItem
                            key={d.id}
                            value={d.label}
                            onClick={() => {
                              this.setState({
                                discounts: [
                                  {
                                    discountId: d.id,
                                    label: d.label,
                                    unitPrice: d.amount * -1,
                                  },
                                ],
                              })
                            }}
                          >
                            {`${d.label} $(${d.amount})`}
                          </MenuItem>
                        ))}
                      </TextField>
                      {discounts[0].label === 'Learn More' && (
                        <Query
                          fetchPolicy={'cache-and-network'}
                          query={GET_FAMILY_STUDENTS}
                          variables={{ id: this.props.invoice.familyAccountId }}
                        >
                          {({ loading, error, data }) => {
                            if (loading) {
                              return <TextField label="Loading.." disabled />
                            }
                            if (
                              error ||
                              data.familyAccount.students.length === 0
                            ) {
                              return (
                                <TextField
                                  label="No students to show.."
                                  disabled
                                />
                              )
                            }
                            return (
                              <TextField
                                select
                                required
                                label="Choose student"
                                defaultValue=""
                                value={chosenStudentName || ''}
                                onChange={event => {
                                  this.setState({
                                    chosenStudentName: event.target.value,
                                  })
                                }}
                                style={{ width: '20%' }}
                              >
                                {data.familyAccount.students.map(stu => (
                                  <MenuItem
                                    key={stu.id}
                                    value={`${stu.firstName} ${stu.lastName}`}
                                    onClick={() =>
                                      this.setState({ chosenStudentId: stu.id })
                                    }
                                  >
                                    {`${stu.firstName} ${stu.lastName}`}
                                  </MenuItem>
                                ))}
                              </TextField>
                            )
                          }}
                        </Query>
                      )}
                    </div>
                  )
                }}
              </Query>
            ) : (
              discounts.map((d, ind) => {
                return (
                  <Flex key={ind}>
                    <TextField disabled value={d.label} label={'Type'} />
                    <TextField
                      disabled
                      value={`$${d.unitPrice}`}
                      label={'Amount'}
                    />
                    <TextField
                      value={d.quantity}
                      label={'Quantity'}
                      type="number"
                      onChange={e => {
                        let { discounts } = this.state
                        discounts[ind].quantity = e.target.value
                        this.setState({ discounts: discounts })
                      }}
                    />
                    <TextField
                      disabled
                      value={d.quantity * d.unitPrice}
                      label="subtotal"
                    />
                    {d.label === 'Learn More' && (
                      <Query
                        fetchPolicy={'cache-and-network'}
                        query={GET_FAMILY_STUDENTS}
                        variables={{ id: this.props.invoice.familyAccountId }}
                      >
                        {({ loading, error, data }) => {
                          if (loading) {
                            return <TextField label="Loading.." disabled />
                          }
                          if (
                            error ||
                            data.familyAccount.students.length === 0
                          ) {
                            return (
                              <TextField
                                label="No students to show.."
                                disabled
                              />
                            )
                          }
                          return (
                            <TextField
                              select
                              required
                              label="Choose student"
                              defaultValue=""
                              value={chosenStudentName || ''}
                              onChange={event => {
                                this.setState({
                                  chosenStudentName: event.target.value,
                                })
                              }}
                              style={{ width: '20%' }}
                            >
                              {data.familyAccount.students.map(stu => (
                                <MenuItem
                                  key={stu.id}
                                  value={`${stu.firstName} ${stu.lastName}`}
                                  onClick={() =>
                                    this.setState({ chosenStudentId: stu.id })
                                  }
                                >
                                  {`${stu.firstName} ${stu.lastName}`}
                                </MenuItem>
                              ))}
                            </TextField>
                          )
                        }}
                      </Query>
                    )}
                  </Flex>
                )
              })
            )}
            <br />
            {editing && (
              <TextField
                label="Quantity"
                value={discounts[0].quantity}
                type="number"
                onChange={q =>
                  this.setState({
                    discounts: [{ ...discounts[0], quantity: q.target.value }],
                  })
                }
                style={{ width: '25%' }}
              />
            )}
          </Paper>
        </DialogContent>
        <DialogActions style={{ paddingBottom: '10px', paddingRight: '10px' }}>
          {editing && (
            <Button
              color="default"
              onClick={() => {
                this.props.delHandler(
                  this.props.client,
                  this.props.selectedItem.id
                )
                this.props.close()
              }}
            >
              {'Remove'}
            </Button>
          )}
          <Button color="primary" onClick={this.handleSubmit}>
            {editing ? 'Update' : 'Create'}
          </Button>
          <Button onClick={this.props.close} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withApollo(ApplyDiscountForm)
