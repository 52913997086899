import React, { Component } from 'react'
import { Query, withApollo } from 'react-apollo'
import { Link, withRouter } from 'react-router-dom'
import AsyncSelect from 'react-select/async'
import Cookies from 'js-cookie'
import { idName } from '../../config'

import {
  Button,
  Flex,
  ErrorMessage,
  LoadingMessage,
  MenuItem,
  Paper,
  SnackbarNotification,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Text,
  TextField,
} from '../../components'
import { Box, InputAdornment } from '@mui/material'

import { GET_DISCOUNT } from '../InvoiceView/queries'
import { convertPhoneNumber } from '../../utils'
import { GET_FAMILY_ACCOUNTS } from '../StudentView/queries'
import { CREATE_CREDIT } from '../InvoiceView/queries'

class AddCreditForm extends Component {
  state = {
    currentValue: '',
    selectedAccount: null,
    discountAmount: 0,
    selectedDiscount: null,
    quantity: 1,
    unitAmount: 0,
    totalAmount: 0,
    loading: false,
    notes: '',
    snackbar: {
      open: false,
      message: '',
      messageType: '',
      windowReload: false,
    },
  }

  changeSelectedAccount = value => {
    this.setState({
      selectedAccount: value,
    })
  }

  matchesPrimaryFamilyMember = familyAccount => {
    const {
      firstName,
      lastName,
    } = this.state.selectedAccount.primaryFamilyMember
    const {
      firstName: otherFirstName,
      lastName: otherLastName,
    } = familyAccount.primaryFamilyMember

    return firstName === otherFirstName && lastName === otherLastName
  }

  getFamilyAccounts = async () => {
    const { client } = this.props
    const { currentValue } = this.state
    const {
      data: { familyAccounts },
    } = await client.query({
      query: GET_FAMILY_ACCOUNTS,
      variables: {
        filter: {
          primaryFamilyMember: {
            $name: currentValue,
          },
        },
        sort: {
          primaryFamilyMember: {
            firstName: 1,
            lastName: 2,
          },
        },
      },
    })
    this.setState({
      familyAccounts,
    })

    if (familyAccounts.length === 0) {
      return [{ value: null, label: 'No Result' }]
    }

    return familyAccounts.map(familyAccount => {
      const { firstName, lastName } = familyAccount.primaryFamilyMember
      return {
        value: familyAccount,
        label: `${firstName} ${lastName}`,
      }
    })
  }

  handleInputChange = value => {
    this.setState({
      currentValue: value,
    })
  }

  createCreditHandler = async () => {
    const { client } = this.props
    const { selectedAccount, selectedDiscount, totalAmount, notes } = this.state

    if (!selectedAccount) {
      alert('choose a family first!')
      return
    }
    this.openSnack('Creating...', 'other')
    this.setState({ loading: true })

    const creditType =
      selectedDiscount.label === 'Withdrawal' ? 'WITHDRAWAL' : 'DISCOUNT'
    const creditAmount = totalAmount
    const employeeId = Cookies.get(idName)
    const refundable = ['Early Bird', 'Withdrawal'].includes(
      selectedDiscount.label
    )
    const { data, error } = await client.mutate({
      mutation: CREATE_CREDIT,
      variables: {
        input: {
          familyAccountId: selectedAccount.id,
          amount: creditAmount,
          issueDate: new Date().toISOString(),
          balance: creditAmount,
          creditType: creditType,
          refundable: refundable,
          responsibleEmployeeId: employeeId,
          notes: notes,
        },
      },
    })

    if (data && !error) {
      this.openSnack('Success!', 'success')
      setTimeout(() => {
        this.props.history.push('/credit/' + data.createCredit.creditNumber)
      }, 1000)
    } else {
      this.openSnack('Failed!', 'error')
      this.setState({ loading: false })
    }
  }

  openSnack = (message, messageType, windowReload) => {
    const { snackbar } = this.state
    const newSnackbar = {
      ...snackbar,
      open: true,
      messageType,
      message,
      windowReload,
    }
    this.setState({ snackbar: newSnackbar })
  }

  render() {
    const {
      currentValue,
      familyAccounts,
      selectedAccount,
      snackbar,
      loading,
      notes,
      quantity,
      unitAmount,
      selectedDiscount,
    } = this.state

    return (
      <Flex style={{ height: '80' }}>
        <Paper style={{ width: '50%', margin: 'auto', marginTop: '10px' }}>
          <Flex
            column
            style={{
              align: 'center',
              flexGrow: 1,
            }}
            p="20px"
          >
            <Text variant="subtitle1" style={{ textAlign: 'center' }}>
              Choose Family Account
            </Text>
            <br />
            <AsyncSelect
              autoFocus={!selectedAccount}
              isLoading={!selectedAccount}
              loadOptions={
                currentValue.length >= 2 &&
                (() =>
                  new Promise(resolve => {
                    setTimeout(() => {
                      resolve(this.getFamilyAccounts())
                    }, 1000)
                  }))
              }
              menuIsOpen={currentValue.length >= 2}
              placeholder={'Search for existing primary family member...'}
              onChange={option => this.changeSelectedAccount(option.value)}
              onInputChange={this.handleInputChange}
              inputValue={currentValue}
              value={
                selectedAccount
                  ? {
                      value: selectedAccount,
                      label: `${selectedAccount.primaryFamilyMember.firstName} ${selectedAccount.primaryFamilyMember.lastName}`,
                    }
                  : null
              }
            />
            <br />
            {selectedAccount && (
              <div>
                <Text variant="subtitle1">Search Results</Text>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>First Name</TableCell>
                      <TableCell>Last Name</TableCell>
                      <TableCell>Phone Number</TableCell>
                      <TableCell>Email</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {familyAccounts.map(familyAccount => {
                      if (this.matchesPrimaryFamilyMember(familyAccount)) {
                        const {
                          firstName,
                          lastName,
                          phoneNumber,
                          email,
                        } = familyAccount.primaryFamilyMember
                        return (
                          <TableRow
                            hover
                            onClick={() =>
                              this.changeSelectedAccount(familyAccount)
                            }
                            selected={familyAccount.id === selectedAccount.id}
                            key={familyAccount.id}
                          >
                            <TableCell>{firstName}</TableCell>
                            <TableCell>{lastName}</TableCell>
                            <TableCell>
                              {convertPhoneNumber(phoneNumber)}
                            </TableCell>
                            <TableCell>{email}</TableCell>
                          </TableRow>
                        )
                      }
                      return null
                    })}
                  </TableBody>
                </Table>
                <br />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                  }}
                >
                  <Query query={GET_DISCOUNT}>
                    {({ loading, error, data }) => {
                      if (loading) return <LoadingMessage />
                      if (error) return <ErrorMessage error={error} />

                      const discounts = data.discounts
                      return (
                        <Flex style={{ alignSelf: 'start' }}>
                          <TextField
                            id="select-discount-credit"
                            select
                            name="discount"
                            label="Select"
                            value={selectedDiscount || ''}
                            onClick={e =>
                              this.setState({
                                selectedDiscount: e.target.value,
                                unitAmount: e.target.value.amount,
                                totalAmount: e.target.value.amount * quantity,
                              })
                            }
                            style={{ marginRight: '10px' }}
                            helperText="Please select a Credit Type"
                          >
                            {discounts.map(discount => (
                              <MenuItem key={discount.id} value={discount}>
                                {`${discount.label}`}
                              </MenuItem>
                            ))}
                          </TextField>
                          <TextField
                            value={quantity}
                            label={'Quantity'}
                            disabled={
                              !selectedDiscount ||
                              (selectedDiscount &&
                                selectedDiscount.label === 'Withdrawal')
                            }
                            type="number"
                            onChange={e => {
                              if (e.target.value > 0) {
                                this.setState({
                                  quantity: e.target.value,
                                  totalAmount: unitAmount * e.target.value,
                                })
                              }
                            }}
                            style={{ width: '10%', marginRight: '10px' }}
                          />
                          <TextField
                            label="Unit"
                            id="standard-start-adornment"
                            disabled={
                              !selectedDiscount ||
                              (selectedDiscount &&
                                selectedDiscount.label !== 'Withdrawal')
                            }
                            variant="standard"
                            sx={{ ml: 2, width: '25%' }}
                            value={this.state.unitAmount}
                            onChange={event => {
                              this.setState({
                                unitAmount: event.target.value,
                                totalAmount: event.target.value * quantity,
                              })
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                            }}
                          />
                          <TextField
                            label="Total"
                            id="standard-start-adornment"
                            disabled={true}
                            variant="standard"
                            sx={{ ml: 2, width: '25%' }}
                            value={this.state.totalAmount}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Flex>
                      )
                    }}
                  </Query>
                  <TextField
                    multiline
                    minRows={4}
                    variant="filled"
                    value={notes}
                    onChange={v => this.setState({ notes: v.target.value })}
                    label="Notes"
                    fullWidth
                  />
                  <br />
                </div>
                <br />
                <Flex direction={'row-reverse'} align={'center'}>
                  <Button onClick={this.createCreditHandler} disabled={loading}>
                    Add Credits
                  </Button>
                  <Button
                    color="secondary"
                    component={Link}
                    to={'/credit'}
                    disabled={loading}
                    style={{ marginRight: '10px' }}
                  >
                    Cancel
                  </Button>
                </Flex>
              </div>
            )}
            {!selectedAccount && (
              <Flex direction={'row-reverse'} align={'center'}>
                <Button
                  color="secondary"
                  component={Link}
                  to={'/credit'}
                  style={{ marginRight: '10px' }}
                >
                  Cancel
                </Button>
              </Flex>
            )}
          </Flex>
          <SnackbarNotification
            open={snackbar.open}
            handleClose={() => {
              this.setState({
                snackbar: {
                  ...snackbar,
                  open: false,
                },
              })
              if (snackbar.windowReload) window.location.reload()
            }}
            message={snackbar.message}
            messageType={snackbar.messageType}
          />
        </Paper>
      </Flex>
    )
  }
}
export default withApollo(withRouter(AddCreditForm))
