import React, { Component } from 'react'
import { ApolloConsumer, Query } from 'react-apollo'
import Composer from 'react-composer'
import { Redirect } from 'react-router-dom'

import StudentTable from './StudentTable'
import StudentSidebar from './StudentSidebar'

import {
  Button,
  ErrorMessage,
  Flex,
  LoadingMessage,
  Menu,
  MenuItem,
  SettingsIcon,
  Tooltip,
} from '../../components'

import { COUNT_STUDENTS } from './queries'

class StudentTableView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      resolvedStatusCounts: {},
    }
  }

  getStatusCounts = async client => {
    const statusCounts = {}
    await Promise.all(
      this.props.statuses.map(async status => {
        const { data } = await client.query({
          query: COUNT_STUDENTS,
          variables: { filter: { enrollmentStatus: status } },
        })
        statusCounts[status] = data.countStudents
      })
    )
    return statusCounts
  }

  onStatusCountsChange = value => {
    this.setState({
      resolvedStatusCounts: value,
    })
  }

  render() {
    return (
      <ApolloConsumer>
        {client => (
          <InnerStudentTableView
            {...this.props}
            statusCounts={this.getStatusCounts(client)}
            resolvedStatusCounts={this.state.resolvedStatusCounts}
            onStatusCountsChange={this.onStatusCountsChange}
          />
        )}
      </ApolloConsumer>
    )
  }
}

class InnerStudentTableView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sidebar: true,
      showFilters: true,
      onlyShowRedRows: false,
      onlyShowRealData: false,
      selectAll: false,
      menuAnchor: null,
      checked: {},
      redirect: false,
      sorted: [
        {
          id: 'lastName',
          desc: false,
        },
      ],
      filtered: [
        {
          id: 'enrollmentStatus',
          value: 'ALL',
        },
        {
          id: 'newImage',
          value: 'OFF',
        },
      ],
    }
  }

  async componentDidMount() {
    await Promise.resolve(this.props.statusCounts).then(value => {
      this.props.onStatusCountsChange(value)
    })
  }

  handlePageChange = page => {
    const { loaded_pages } = this.state
    this.setState(
      page > loaded_pages ? { loaded_pages: page, page: page } : { page: page }
    )
  }

  handleSingleCheckboxChange = id => {
    const { checked, selectAll } = this.state
    checked[id] = !checked[id]
    this.setState({
      selectAll: !checked[id] && selectAll ? false : selectAll,
      checked,
    })
  }

  toggleSidebar = () => {
    this.setState(state => ({
      sidebar: !state.sidebar,
      menuAnchor: null,
    }))
  }

  toggleFilters = () => {
    this.setState(state => ({
      showFilters: !state.showFilters,
      menuAnchor: null,
    }))
  }

  toggleRedRows = () => {
    this.setState(state => ({
      onlyShowRedRows: !state.onlyShowRedRows,
      menuAnchor: null,
    }))
  }

  toggleRealData = () => {
    this.setState(state => ({
      onlyShowRealData: !state.onlyShowRealData,
      onlyShowRedRows: false,
      menuAnchor: null,
    }))
  }

  onFilteredChange = filtered => {
    this.setState({
      filtered,
      menuAnchor: null,
    })
  }

  menuOpen = event => {
    this.setState({
      menuAnchor: event.currentTarget,
    })
  }

  menuClose = () => {
    this.setState({
      menuAnchor: null,
    })
  }

  filterForRedRows = students => {
    return students.filter(
      student =>
        !(
          student &&
          student.enrollmentStatus &&
          student.firstName &&
          student.lastName &&
          student.gender &&
          (student.grade || student.grade === 0) &&
          student.email &&
          student.school &&
          student.phoneNumber
        )
    )
  }

  rowClick = rowInfo => {
    rowInfo &&
      this.setState({
        redirect: true,
        redirectTo: `/student/${rowInfo.original.id}`,
      })
  }

  menuButton = data => {
    const { menuAnchor, onlyShowRealData, onlyShowRedRows } = this.state
    return (
      <Flex p="5px">
        <Flex p="5px">
          <Tooltip>
            <Button color="default" aria-label="Menu" onClick={this.menuOpen}>
              <SettingsIcon />
            </Button>
          </Tooltip>
        </Flex>
        <Menu
          id="simple-menu"
          anchorEl={menuAnchor}
          open={Boolean(menuAnchor)}
          onClose={this.menuClose}
        >
          {/* <MenuItem onClick={this.toggleSidebar}>Toggle Sidebar</MenuItem> */}
          <MenuItem onClick={this.toggleFilters}>Toggle Filters</MenuItem>
          <MenuItem onClick={this.toggleRedRows}>
            {onlyShowRedRows
              ? 'Show All Students'
              : 'Only Show Students with missing info'}
          </MenuItem>
          <MenuItem onClick={this.toggleRealData}>
            {onlyShowRealData ? 'Include Test Data' : 'Only Show Real Data'}
          </MenuItem>
        </Menu>
      </Flex>
    )
  }

  getStatusFilter = () => {
    const statusFilterValue = this.state.filtered.find(
      filter => filter.id === 'enrollmentStatus'
    ).value
    return statusFilterValue === 'ALL'
      ? {}
      : { enrollmentStatus: statusFilterValue }
  }

  getApproveImageFilter = () => {
    const approveImageFilter = this.state.filtered.find(
      filter => filter.id === 'newImage'
    ).value
    return approveImageFilter === 'OFF' ? {} : { newImage: { $exists: true } }
  }

  render() {
    const {
      checked,
      filtered,
      redirect,
      redirectTo,
      showFilters,
      sidebar,
      sorted,
    } = this.state
    const statusCounts = this.props.resolvedStatusCounts
    if (redirect) {
      return <Redirect push to={redirectTo} />
    }

    return (
      <Composer
        components={[
          <Query
            fetchPolicy="cache-and-network"
            query={COUNT_STUDENTS}
            variables={{
              filter: {
                ...this.getStatusFilter(),
                ...this.getApproveImageFilter(),
              },
            }}
          >
            {function() {
              return ''
            }}
          </Query>,
          <Query
            fetchPolicy="cache-and-network"
            query={COUNT_STUDENTS}
            variables={{ filter: {} }}
          >
            {function() {
              return ''
            }}
          </Query>,
        ]}
      >
        {([
          { loading: countLoading, error: countError, data: countData },
          { loading: totalLoading, error: totalError, data: totalData },
        ]) => {
          if (countLoading || totalLoading) return <LoadingMessage />
          if (countError || totalError)
            return <ErrorMessage error={countError || totalError} />
          return (
            <Flex>
              {sidebar ? (
                <StudentSidebar
                  show={sidebar}
                  statusCounts={statusCounts}
                  totalStudentsCount={totalData.countStudents}
                  filtered={filtered}
                  onFilteredChange={this.onFilteredChange}
                />
              ) : null}
              <Flex pl={sidebar ? '0px' : '20px'} align="left" column grow={1}>
                <StudentTable
                  filterable={showFilters}
                  onRowClick={this.rowClick}
                  statuses={this.props.statuses}
                  sorted={sorted}
                  pages={Math.ceil(countData.countStudents / 25)}
                  filtered={filtered}
                  onFilteredChange={this.onFilteredChange}
                  checked={checked}
                  handleSingleCheckboxChange={this.handleSingleCheckboxChange}
                />
              </Flex>
            </Flex>
          )
        }}
      </Composer>
    )
  }
}

export default StudentTableView
