import React from 'react'
import { makeStyles } from '@mui/styles'
import { Button, Divider } from '@mui/material'

import { SidebarText, SidebarContainer } from '../../components'

const useStyles = makeStyles({
  headline: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#277897',
    paddingTop: '23px',
    paddingBottom: '5px',
  },
  headlineTwo: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#277897',
    paddingBottom: '10px',
    paddingTop: '5px',
  },
  subheadingOne: {
    color: '#277897',
    paddingTop: '5px',
  },
  subheadingTwo: {
    color: '#277897',
    paddingBottom: '5px',
  },
  selectorWithMargin: {
    margin: '20px 0px',
    width: '100%',
  },
})

const CreditDetailSidebar = ({ credit, openAddRefund }) => {
  const classes = useStyles()

  const { amount, guardian, refundable, totalBalance } = credit

  return (
    <SidebarContainer>
      <SidebarText
        className={classes.headline}
        title={'Family: '}
        value={guardian}
      />
      <SidebarText
        className={classes.subheadingTwo}
        title={`Total Balance:`}
        value={totalBalance}
      />
      <Divider />
      <SidebarText
        className={classes.subheadingTwo}
        title={'Current Credit:'}
        value={amount}
      />

      <Button
        aria-label="Refund"
        variant="contained"
        onClick={openAddRefund}
        disabled={true || !refundable}
      >
        Issue Refund
      </Button>
    </SidebarContainer>
  )
}

export default CreditDetailSidebar
