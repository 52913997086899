import React, { Component } from 'react'
import { Query } from 'react-apollo'

import CounselingForm from './CounselingForm'

import { GET_COUNSELING_STUDENTS } from './queries'
import {
  ChooseStudentPanel,
  ErrorMessage,
  AccordionForm,
  Flex,
  LoadingMessage,
  Text,
} from '../../components'

class CounselingAddStudentView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedStudent: null,
    }
  }

  handleChange = event => {
    const { name, value } = event.target
    this.setState({
      [name]: value,
    })
  }

  render() {
    const { selectedStudent } = this.state
    const { counselors, statuses } = this.props
    return (
      <Flex
        column
        grow={1}
        style={{
          textAlign: 'left',
          margin: 'auto',
          width: '67%',
          minWidth: '600px',
          maxWidth: '1000px',
        }}
      >
        <br />
        <Text variant="h5">Add Student to Counseling</Text>
        <br />
        <AccordionForm
          expanded
          title="Add Student"
          form={
            <Query
              fetchPolicy={'cache-and-network'}
              query={GET_COUNSELING_STUDENTS}
              variables={{ status: 'ACTIVE' }}
            >
              {({ loading, error, data }) => {
                if (loading) return <LoadingMessage />
                if (error) return <ErrorMessage error={error} />

                const counselingStudentIds = data.counselingEnrollments.map(
                  counselingEnrollment => counselingEnrollment.student.id
                )
                return (
                  <ChooseStudentPanel
                    display
                    filter={{
                      grade: { $gt: 6 },
                    }}
                    ignoreList={counselingStudentIds}
                    onChange={this.handleChange}
                    selectedStudent={selectedStudent}
                  />
                )
              }}
            </Query>
          }
        />
        {selectedStudent && (
          <AccordionForm
            expanded
            title="Counseling Information"
            form={
              <CounselingForm
                student={selectedStudent}
                statuses={statuses}
                counselors={counselors}
              />
            }
          />
        )}
      </Flex>
    )
  }
}

export default CounselingAddStudentView
