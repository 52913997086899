import React, { Component } from 'react'

import CourseTable from './CourseTable'

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Flex,
  MenuItem,
  Paper,
  TextField,
  FormControlLabel,
} from '../../components'
import { FormControl, Input, InputAdornment, InputLabel } from '@mui/material'

import { GET_CLASSROOM, GET_FAMILY_STUDENTS } from './queries'
import { Query } from 'react-apollo'

class AddClassroomForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      courseCode: '', //selected course code
      courseSearchError: false,
      errorMessage: '',
      chosenClass: null, //{code, id, tuition}
      chosenStudentId: '',
      chosenStudentName: '',
      quantity: 1,
      editMode: false,
      totalPrice: 0,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { chosenClass, quantity } = this.state
    if (
      chosenClass !== null &&
      (prevState.chosenClass !== chosenClass || prevState.quantity !== quantity)
    ) {
      this.setState({
        totalPrice:
          0.5 * chosenClass.tuition * quantity + 0.5 * chosenClass.tuition,
      })
    }

    if (prevProps.selectedItem !== this.props.selectedItem) {
      if (this.props.selectedItem === null) {
        this.setState({
          courseCode: '',
          courseSearchError: false,
          errorMessage: '',
          chosenClass: null,
          chosenStudentId: '',
          chosenStudentName: '',
          quantity: 1,
          editMode: false,
          totalPrice: 0,
        })
      } else if (this.props.selectedItem.itemType === 'CLASSROOM') {
        let item = this.props.selectedItem
        this.setState(
          {
            courseCode: item.classroom.course.code,
            courseSearchError: false,
            errorMessage: '',
            chosenClass: {
              code: item.classroom.code,
              id: item.classroom.id,
              tuition: item.unitPrice,
              earlyBird: item.earlyBird,
            },
            chosenStudentId: item.student.id,
            chosenStudentName: `${item.student.firstName} ${item.student.lastName}`,
            quantity: item.quantity,
            editMode: true,
          },
          this.courseSearchHandler
        )
      }
    }
  }

  courseSearchHandler = async () => {
    const { data } = await this.props.client.query({
      query: GET_CLASSROOM,
      variables: {
        filter: { code: this.state.courseCode },
        filter2: { $or: [{ status: 'OPENED' }, { status: 'PUBLISHED' }] },
      },
    })
    if (
      !data ||
      data.courses.length !== 1 ||
      data.courses[0].classrooms.length === 0
    ) {
      this.setState({
        courseSearchError: true,
        errorMessage: 'Cannot find any course matching the code',
      })
      return
    }
    this.setState({
      errorMessage: '',
      courseSearchError: false,
      classrooms: data.courses[0].classrooms,
    })
  }

  chooseClassHandler = (
    code,
    id,
    tuition,
    regularTuition,
    earlyBirdTuition
  ) => {
    this.setState({
      chosenClass: {
        code,
        id,
        tuition,
        regularTuition,
        earlyBirdTuition,
        earlyBird: false,
      },
    })
  }

  formSubmitChecker = () => {
    const { chosenClass, chosenStudentName, quantity } = this.state
    return chosenClass && chosenStudentName && quantity
  }

  toggleEarlybird = () => {
    const early = this.state.chosenClass.earlyBird
    this.setState({
      chosenClass: {
        ...this.state.chosenClass,
        earlyBird: !early,
        tuition: early
          ? this.state.chosenClass.regularTuition
          : this.state.chosenClass.earlyBirdTuition,
      },
      quantity: !early ? 1 : this.state.quantity,
    })
  }

  formSubmit = () => {
    if (!this.formSubmitChecker()) {
      this.setState({ errorMessage: 'You did not enter all required fields' })
      return
    }
    const {
      chosenClass,
      chosenStudentId,
      quantity,
      editMode,
      totalPrice,
    } = this.state
    this.setState({ errorMessage: '' })

    if (editMode) {
      this.props.updateHandler(this.props.client, this.props.selectedItem.id, {
        classroomId: chosenClass.id,
        studentId: chosenStudentId,
        unitPrice: chosenClass.tuition,
        quantity: parseFloat(quantity),
        price: totalPrice,
        invoiceId: this.props.invoice.id,
        itemType: 'CLASSROOM',
        earlyBird: chosenClass.earlyBird,
      })
    } else {
      this.props.addHandler(this.props.client, [
        {
          classroomId: chosenClass.id,
          studentId: chosenStudentId,
          unitPrice: chosenClass.tuition,
          quantity: parseFloat(quantity),
          price: totalPrice,
          invoiceId: this.props.invoice.id,
          itemType: 'CLASSROOM',
          earlyBird: chosenClass.earlyBird,
        },
      ])
    }

    this.props.close()
  }

  render() {
    const {
      courseCode,
      classrooms,
      errorMessage,
      chosenClass,
      chosenStudentName,
      quantity,
      editMode,
      totalPrice,
    } = this.state

    return (
      <Dialog open={this.props.open} fullWidth={true} maxWidth={'lg'}>
        <DialogTitle>{`Add class ${
          chosenClass
            ? `- ${chosenClass.code}`
            : '- please search for a course code and select a class'
        }`}</DialogTitle>

        <DialogContent>
          <div style={{ color: 'red' }}> {errorMessage} </div>
          <Flex justify="space-between" direction="column">
            <Flex style={{ marginBottom: '5px' }}>
              <Paper
                style={{ padding: '15px', width: '25%', marginRight: '15px' }}
              >
                <Query
                  fetchPolicy={'cache-and-network'}
                  query={GET_FAMILY_STUDENTS}
                  variables={{ id: this.props.invoice.familyAccountId }}
                >
                  {({ loading, error, data }) => {
                    if (loading) {
                      return <TextField label="Loading.." fullWidth disabled />
                    }
                    if (error || data.familyAccount.students.length === 0) {
                      return (
                        <TextField
                          label="No students to show.."
                          disabled
                          fullWidth
                        />
                      )
                    }
                    return (
                      <TextField
                        select
                        required
                        label="Choose student"
                        fullWidth
                        defaultValue=""
                        value={chosenStudentName || ''}
                        onChange={event => {
                          this.setState({
                            chosenStudentName: event.target.value,
                          })
                        }}
                      >
                        {data.familyAccount.students.map(stu => (
                          <MenuItem
                            key={stu.id}
                            value={`${stu.firstName} ${stu.lastName}`}
                            onClick={() =>
                              this.setState({ chosenStudentId: stu.id })
                            }
                          >
                            {`${stu.firstName} ${stu.lastName}`}
                          </MenuItem>
                        ))}
                      </TextField>
                    )
                  }}
                </Query>
              </Paper>
              <Paper style={{ padding: '15px', width: '45%' }}>
                <Flex justify="space-between">
                  <TextField
                    label="Course Code"
                    required
                    style={{ width: '100%' }}
                    value={courseCode}
                    onChange={event =>
                      this.setState({
                        courseCode: event.target.value.toUpperCase(),
                      })
                    }
                  />

                  <Button
                    onClick={this.courseSearchHandler}
                    variant="contained"
                    color="primary"
                  >
                    {'Search'}
                  </Button>
                </Flex>
              </Paper>
            </Flex>

            {classrooms && (
              <CourseTable
                classes={classrooms}
                chosenClass={chosenClass}
                setCode={this.chooseClassHandler}
              />
            )}
            <br />
            <Paper
              style={{
                alignSelf: 'right',
                padding: '15px',
                width: '40%',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!chosenClass}
                    defaultChecked={false}
                    checked={chosenClass ? chosenClass.earlyBird : false}
                    onChange={this.toggleEarlybird}
                    color="primary"
                  />
                }
                label="Earlybird"
              />
              <TextField
                label="Unit Price"
                required
                value={
                  chosenClass
                    ? chosenClass.tuition.toFixed(2)
                    : 'Select a class first'
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                disabled
              />
              <TextField
                label="Quantity"
                required
                disabled={chosenClass ? chosenClass.earlyBird : true}
                type="number"
                inputProps={{ step: 0.1 }}
                value={quantity}
                onChange={e => {
                  this.setState({
                    quantity: e.target.value > 1 ? 1 : e.target.value,
                  })
                }}
              />
            </Paper>
          </Flex>
        </DialogContent>
        <DialogActions style={{ paddingBottom: '10px', paddingRight: '10px' }}>
          <FormControl
            fullWidth
            sx={{ m: 1 }}
            variant="standard"
            style={{ width: '25%' }}
          >
            <InputLabel htmlFor="standard-adornment-amount">Total</InputLabel>
            <Input
              id="standard-adornment-amount"
              value={totalPrice}
              onChange={event => {
                const amount = event.target.value
                  ? parseFloat(event.target.value)
                  : 0.0
                this.setState({
                  totalPrice: amount,
                })
              }}
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
            />
          </FormControl>
          {editMode && (
            <Button
              onClick={() => {
                this.props.delHandler(
                  this.props.client,
                  this.props.selectedItem.id
                )
                this.props.close()
              }}
            >
              {'Remove'}
            </Button>
          )}
          <Button color="primary" onClick={this.formSubmit}>
            {editMode ? 'Update' : 'Create'}
          </Button>
          <Button onClick={this.props.close} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default AddClassroomForm
