import draftToHtml from 'draftjs-to-html'
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromRaw,
} from 'draft-js'

export const rawToContentState = raw => {
  try {
    const contentState = convertFromRaw(JSON.parse(raw))
    return contentState
  } catch (_) {
    return ContentState.createFromText(raw)
  }
}

export const rawToHtml = raw => {
  try {
    const contentState = EditorState.createWithContent(
      convertFromRaw(JSON.parse(raw))
    )
    return draftToHtml(convertToRaw(contentState.getCurrentContent()))
  } catch (_) {
    return null
  }
}
