import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

import NewsView from './NewsView'
import NewsDetailView from './NewsDetailView'

class NewsViewRouter extends Component {
  render() {
    const url = this.props.match.url
    return (
      <Switch>
        <Route path={`${url}/*`} component={NewsDetailView} />
        <Route path={url} component={NewsView} />
      </Switch>
    )
  }
}

export default NewsViewRouter
