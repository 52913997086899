import React from 'react'
import { Route, Switch } from 'react-router-dom'
import AssessmentDetailView from './AssessmentDetailView'
import AssessmentTableView from './AssessmentTableView'
import AddAssessmentView from './AddAssessmentView'

const AssessmentView = props => {
  const url = props.match.url
  return (
    <Switch>
      <Route
        path={`${url}/create-assessment/*`}
        render={props => <AddAssessmentView {...props} />}
      />
      <Route
        path={`${url}/*`}
        render={props => <AssessmentDetailView {...props} />}
      />
      <Route path={url} component={AssessmentTableView} />
    </Switch>
  )
}

export default AssessmentView
