import React, { Component } from 'react'
import { ARCHIVE_PAYMENT, GET_PAYMENTS } from './queries'
import { Query, withApollo } from 'react-apollo'
import {
  ErrorMessage,
  Flex,
  LoadingMessage,
  Redirect,
  SnackbarNotification,
} from '../../components'
import PaymentTable from './PaymentTable'
import PaymentSidebar from './PaymentSidebar'

class PaymentTableView extends Component {
  constructor(props) {
    super(props)
    const today = new Date()
    const startDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 7,
      0,
      0,
      0,
      0
    )
    const endDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      0,
      0,
      0,
      0
    )
    this.state = {
      redirect: false,
      sorted: [
        {
          id: 'createdOn',
          desc: true,
        },
      ],
      filtered: [
        {
          id: 'method',
          value: 'AUTHORIZE',
        },
      ],
      checked: [],
      invoices: [],
      snackbar: {
        open: false,
        message: '',
        messageType: '',
        windowReload: false,
      },
      sidebar: {
        startDate: startDate,
        endDate: endDate,
      },
      paymentQueryFilter: {
        reconciled: false,
        createdOn: {
          $gteDate: startDate,
          $lteDate: endDate,
        },
      },
    }
  }

  openSnack = (message, messageType, windowReload) => {
    const { snackbar } = this.state
    const newSnackbar = {
      ...snackbar,
      open: true,
      messageType,
      message,
      windowReload,
    }
    this.setState({ snackbar: newSnackbar })
  }

  onFilteredChange = filtered => {
    this.setState({
      filtered,
    })
  }

  rowClick = rowInfo => {
    rowInfo &&
      this.setState({
        redirect: true,
        redirectTo: `/payment/${rowInfo.original.identification}`,
      })
  }

  updateChecked = newChecked => {
    this.setState({ checked: newChecked })
  }

  archiveChecked = async () => {
    const { checked } = this.state

    let chked = checked.filter(ch => ch)
    if (chked.length === 0) {
      this.openSnack('No payment selected', 'other', false)
      return
    }

    for (let id of chked) {
      await this.props.client.mutate({
        mutation: ARCHIVE_PAYMENT,
        variables: { id },
      })
    }

    this.openSnack('Payment archived, reloading..', 'success', true)
  }

  toggleShowUnreconciled = () => {
    const { paymentQueryFilter } = this.state
    const { reconciled, ...rest } = paymentQueryFilter
    const newPaymentQueryFilter = { ...rest }
    if (reconciled === undefined) newPaymentQueryFilter.reconciled = false
    this.setState({ paymentQueryFilter: newPaymentQueryFilter })
  }

  applyDateFilter = sidebar => {
    const { paymentQueryFilter } = this.state
    const sDate = new Date(sidebar.startDate)
    const eDate = new Date(sidebar.endDate)
    const startDate = new Date(
      sDate.getFullYear(),
      sDate.getMonth(),
      sDate.getDate(),
      7,
      0,
      0,
      0
    )
    const endDate = new Date(
      eDate.getFullYear(),
      eDate.getMonth(),
      eDate.getDate() + 1,
      7,
      0,
      0,
      0
    )

    const newPaymentQueryFilter = {
      ...paymentQueryFilter,
      createdOn: {
        $gteDate: startDate,
        $lteDate: endDate,
      },
    }
    this.setState({ sidebar, paymentQueryFilter: newPaymentQueryFilter })
  }

  clearDateFilter = () => {
    const { dateReceived, ...rest } = this.state.paymentQueryFilter
    const newPaymentQueryFilter = {
      ...rest,
    }
    this.setState({ paymentQueryFilter: newPaymentQueryFilter })
  }

  render() {
    const {
      redirect,
      checked,
      snackbar,
      paymentQueryFilter,
      sidebar,
    } = this.state
    if (redirect) {
      return <Redirect push to={this.state.redirectTo} />
    }

    return (
      <Query query={GET_PAYMENTS} variables={{ filter: paymentQueryFilter }}>
        {({ loading, error, data, refetch }) => {
          if (loading) return <LoadingMessage />
          if (error) return <ErrorMessage error={error} />
          const processedPayments = data.payments.map(payment => {
            const { familyAccount } = payment.invoice
            return {
              ...payment,
              location: familyAccount.centerLocation
                ? familyAccount.centerLocation.name
                : null,
            }
          })
          return (
            <Flex>
              <PaymentSidebar
                show={true}
                payments={processedPayments}
                checked={checked}
                archiveCheckedHandler={this.archiveChecked}
                paymentQueryFilter={paymentQueryFilter}
                toggleShowUnreconciled={this.toggleShowUnreconciled}
                applyDateFilter={this.applyDateFilter}
                clearDateFilter={this.clearDateFilter}
                sidebar={sidebar}
              />
              <Flex align="left" column grow={1}>
                <PaymentTable
                  payments={processedPayments}
                  // filterable={true}
                  checked={checked}
                  updateChecked={this.updateChecked}
                  onRowClick={this.rowClick}
                  sorted={this.state.sorted}
                  filtered={this.state.filtered}
                  onFilteredChange={this.onFilteredChange}
                />
              </Flex>
              <SnackbarNotification
                open={snackbar.open}
                handleClose={() => {
                  this.setState({
                    snackbar: {
                      ...snackbar,
                      open: false,
                    },
                  })
                  if (snackbar.windowReload) refetch()
                }}
                message={snackbar.message}
                messageType={snackbar.messageType}
              />
            </Flex>
          )
        }}
      </Query>
    )
  }
}

export default withApollo(PaymentTableView)
