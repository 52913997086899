import gql from 'graphql-tag'

// QUERIES

export const GET_COUNSELING_ENROLLMENT = gql`
  query getCounselingEnrollment($id: ID!) {
    counselingEnrollment(id: $id) {
      id
      createdOn
      lastModified
      student {
        id
        firstName
        lastName
        middleName
        birthdate
        gender
        enrollments {
          id
          classroom {
            id
            centerLocation {
              id
              name
            }
            quarter
            year
            status
            course {
              id
              code
              title
            }
          }
        }
        imageUrl
        familyAccount {
          id
          status
          familyMembers {
            id
            firstName
            lastName
            relation
            email
            phoneNumber
            occupation
            educationLevel
          }
          primaryFamilyMember {
            id
            phoneNumber
            email
            occupation
            educationLevel
          }
          location {
            id
            street
            city
            state
            country
            zip
          }
        }
        grade
        school
      }
      counselor {
        id
        firstName
        lastName
      }
      nextMeeting {
        id
        startDateTime
      }
      lastMeeting {
        id
        startDateTime
      }
      meetings {
        id
        createdBy {
          id
          firstName
          lastName
        }
        method
        startDateTime
        endDateTime
        purpose
      }
      status
      startDate
      endDate
    }
  }
`

export const GET_COUNSELING_ENROLLMENTS = gql`
  query getCounselingEnrollments($filter: Filters) {
    counselingEnrollments(filter: $filter) {
      id
      createdOn
      lastModified
      student {
        id
        firstName
        lastName
        grade
        school
        username
        phoneNumber
        familyAccount {
          id
          primaryFamilyMember {
            id
          }
        }
      }
      counselor {
        id
        firstName
        lastName
      }
      nextMeeting {
        id
        startDateTime
      }
      lastMeeting {
        id
        startDateTime
      }
      status
      startDate
      endDate
    }
  }
`

export const GET_COUNSELING_STUDENTS = gql`
  query getCounselingEnrollments($filter: Filters) {
    counselingEnrollments(filter: $filter) {
      id
      student {
        id
      }
      status
    }
  }
`

export const GET_COUNSELING_MEETING = gql`
  query GetCounselingMeeting($id: ID!) {
    counselingMeeting(id: $id) {
      id
      method
      startDateTime
      endDateTime
      purpose
      resolution
      createdBy {
        id
        firstName
        lastName
      }
      counselingEnrollment {
        id
        student {
          id
          firstName
          lastName
        }
      }
    }
  }
`

export const GET_COUNSELORS = gql`
  query getCounselors {
    employees(filter: { isCollegeCounselor: true }) {
      id
      firstName
      lastName
    }
  }
`

export const GET_COUNSELING_ENROLLMENT_STATUS_OPTIONS = gql`
  query getCounselingEnrollmentStatusOptions {
    __type(name: "CounselingEnrollmentStatus") {
      enumValues {
        name
      }
    }
  }
`

// MUTATIONS

export const CREATE_COUNSELING_ENROLLMENT = gql`
  mutation createCounselingEnrollment(
    $input: CreateCounselingEnrollmentInput!
  ) {
    createCounselingEnrollment(input: $input) {
      id
    }
  }
`

export const CREATE_COUNSELING_MEETING = gql`
  mutation createCounselingMeeting($input: CreateCounselingMeetingInput!) {
    createCounselingMeeting(input: $input) {
      id
    }
  }
`

export const UPDATE_COUNSELING_ENROLLMENT = gql`
  mutation updateCounselingEnrollment(
    $id: ID!
    $input: UpdateCounselingEnrollmentInput!
  ) {
    updateCounselingEnrollment(id: $id, input: $input) {
      id
    }
  }
`

export const UPDATE_COUNSELING_MEETING = gql`
  mutation updateCounselingMeeting(
    $id: ID!
    $input: UpdateCounselingMeetingInput!
  ) {
    updateCounselingMeeting(id: $id, input: $input) {
      id
    }
  }
`

export const ARCHIVE_COUNSELING_ENROLLMENT = gql`
  mutation archiveCounselingEnrollment($id: ID!) {
    archiveCounselingEnrollment(id: $id) {
      id
    }
  }
`

export const COUNSELING_SUMMARY = gql`
  mutation counselingSummary($input: CreateEmailTemplateInput!) {
    createEmailTemplate(input: $input) {
      id
    }
  }
`

export const SEND_COUNSELING_SUMMARY = gql`
  mutation sendCounselingSummary(
    $recipients: [String]!
    $templateName: String!
    $templateData: OneDepthObject!
    $bccAddresses: [String]
  ) {
    sendTemplatedEmail(
      recipients: $recipients
      bccAddresses: $bccAddresses
      templateName: $templateName
      templateData: $templateData
    ) {
      message
    }
  }
`

export const ARCHIVE_COUNSELING_SUMMARY = gql`
  mutation archiveEmailTemplate($id: ID!) {
    archiveEmailTemplate(id: $id) {
      id
    }
  }
`
