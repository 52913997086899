import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

import ReferralView from './ReferralView'

class ReferralViewRouter extends Component {
  render() {
    const url = this.props.match.url
    return (
      <Switch>
        <Route path={url} component={ReferralView} />
      </Switch>
    )
  }
}

export default ReferralViewRouter
