import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

import GradeStudentAssignment from './GradeStudentAssignment'
import GradeDetailTableView from './GradeDetailTableView'
import GradeTableView from './GradeTableView'

class GradeView extends Component {
  render() {
    const url = this.props.match.url
    return (
      <Switch>
        <Route path={`${url}/*/*`} component={GradeStudentAssignment} />
        <Route path={`${url}/*`} component={GradeDetailTableView} />
        <Route path={url} component={GradeTableView} />
      </Switch>
    )
  }
}

export default GradeView
