import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import { Button, Flex, withStyles, DatePicker } from '../../components'
import { sideBarStyles } from '../../utils/style'

class ReconcileSidebar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sidebar: { ...this.props.sidebar },
    }
  }

  handleClearDateFilter = () => {
    const { queryFilter, onQueryFilterChange } = this.props
    const newFilter = { ...queryFilter }
    if (queryFilter['dateReconciled']) {
      const { $gteDate, $lteDate, ...rest } = queryFilter['dateReconciled']
      newFilter['dateReconciled'] = rest
      onQueryFilterChange(newFilter, this.state.sidebar)
    }
  }

  handleApplyDateFilter = () => {
    const { startDate, endDate } = this.state.sidebar
    const { queryFilter, onQueryFilterChange } = this.props
    const newFilter = { ...queryFilter }

    const $gteDate =
      startDate.slice(0, startDate.indexOf('T')) + 'T00:00:00.000Z'
    const $lteDate = endDate.slice(0, endDate.indexOf('T')) + 'T23:59:59.999Z'

    if (queryFilter['dateReconciled'])
      newFilter['dateReconciled'] = {
        ...queryFilter['dateReconciled'],
        $gteDate,
        $lteDate,
      }
    else newFilter['dateReconciled'] = { $gteDate, $lteDate }
    onQueryFilterChange(newFilter, this.state.sidebar)
  }

  handleShowReconciled = () => {
    const { queryFilter, onQueryFilterChange } = this.props
    const newFilter = { ...queryFilter }
    const newSidebar = { ...this.state.sidebar }

    if (queryFilter['dateReconciled'])
      newFilter['dateReconciled'] = {
        ...queryFilter['dateReconciled'],
        $exists: true,
      }
    else newFilter['dateReconciled'] = { $exists: true }

    if (
      queryFilter['dateReconciled'] &&
      queryFilter['dateReconciled']['$exists']
    )
      delete newFilter['dateReconciled']['$exists']

    newSidebar['showReconciled'] = !newSidebar['showReconciled']
    onQueryFilterChange(newFilter, newSidebar)
  }

  render() {
    return (
      <Flex
        column
        grow={0}
        shrink={0}
        overflow="hidden"
        p="10px"
        style={{
          width: '15.38%',
          borderRight: `1px #aaaaaa solid`,
          maxWidth: '200px',
          overflowX: undefined,
          flexShrink: 0,
          textAlign: 'left',
          backgroundColor: '#e5e5e5',
        }}
      >
        <Flex column grow={1} justify="space-between">
          <Flex column>
            <Button
              aria-label="AddInvoice"
              onClick={() =>
                this.props.history.push('reconciliation/add-reconciliation')
              }
            >
              Add New Reconciliation
            </Button>
            <br />
            <Button
              aria-label="ShowReconciled"
              onClick={() => this.handleShowReconciled()}
            >
              {this.state.sidebar.showReconciled
                ? 'Show All'
                : 'Show Reconciled'}
            </Button>
            <br />
            <DatePicker
              fullWidth
              name="startDate"
              label="Start Date"
              value={this.state.sidebar.startDate}
              onChange={e =>
                this.setState({
                  sidebar: {
                    ...this.state.sidebar,
                    startDate: e.target.value.toISOString(),
                  },
                })
              }
              required
            />
            <br />
            <DatePicker
              fullWidth
              name="endDate"
              label="End Date"
              value={this.state.sidebar.endDate}
              onChange={e =>
                this.setState({
                  sidebar: {
                    ...this.state.sidebar,
                    endDate: e.target.value.toISOString(),
                  },
                })
              }
              required
            />
            <br />
            <Button
              aria-label="ApplyDateFilter"
              onClick={() => this.handleApplyDateFilter()}
            >
              Apply Date Filter
            </Button>
            <br />
            <Button
              aria-label="ClearDateFilter"
              onClick={() => this.handleClearDateFilter()}
            >
              Clear Date Filter
            </Button>
          </Flex>
        </Flex>
      </Flex>
    )
  }
}

export default withStyles(sideBarStyles)(withRouter(ReconcileSidebar))
