import gql from 'graphql-tag'

export const GET_COURSES = gql`
  query getCourses {
    courses(sort: { code: 1 }) {
      id
      code
    }
  }
`

export const GET_CLASS = gql`
  query getClassroom($id: ID!) {
    classroom(id: $id) {
      id
      code
      enrollments {
        id
        student {
          id
          email
          phoneNumber
          familyAccount {
            primaryFamilyMember {
              email
              phoneNumber
            }
          }
        }
      }
    }
  }
`

export const GET_NEWS = gql`
  query getNews($filter: Filters!) {
    news(filter: $filter, sort: { annouceDateTime: -1 }) {
      id
      announcer {
        id
        firstName
        lastName
      }
      lesson {
        id
        lessonPlan {
          id
        }
      }
      subject
      message
      headline
      announceDateTime
      isTextMessage
      isEmailMessage
    }
  }
`

export const GET_LESSONS = gql`
  query getLessons($id: ID!) {
    classroom(id: $id) {
      id
      lessons(filter: { lessonPlan: { lessonPlanType: "INSTRUCTION" } }) {
        id
        lessonPlan {
          id
        }
      }
    }
  }
`

export const GET_LESSON = gql`
  query getLesson($id: ID!) {
    lesson(id: $id) {
      id
      startOn
      dueOn
      endOn
      previewOn
      webinarId
      webinarUrl
      officeHourDuration
      officeHourMeetingId
      primaryOfficeHourOn
      makeupOfficeHourOn
      lessonPlan {
        id
        title
        order
        description
        summary
        lessonPlanType
      }
      classroom {
        id
        instructors {
          id
          employee {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`

export const GET_CLASSROOMS = gql`
  query getClasses($filter: Filters!) {
    classrooms(filter: $filter) {
      id
      status
      startDate
      endDate
      quarter
      session
      year
      code
      title
      enrollmentCount
      meets
      startTime
      endTime
      finalAccessDate
      allowTrial
      centerLocation {
        id
        name
      }
      instructorDisplayNames
    }
  }
`
export const GET_EMPLOYEES = gql`
  query getEmployees {
    employees(
      filter: { isTeacher: true, status: "ACTIVE" }
      sort: { lastName: 1 }
    ) {
      id
      status
      firstName
      lastName
    }
  }
`

export const UPDATE_LESSON = gql`
  mutation UpdateLesson($id: ID!, $input: UpdateLessonInput!) {
    updateLesson(id: $id, input: $input) {
      previewOn
      startOn
      dueOn
      endOn
      primaryOfficeHourOn
      makeupOfficeHourOn
      officeHourDuration
      officeHourMeetingId
      webinarId
      webinarUrl
    }
  }
`

export const CREATE_NEWS = gql`
  mutation createNews($input: CreateNewsInput!) {
    createNews(input: $input) {
      id
      announcer {
        id
        firstName
        lastName
      }
      lesson {
        id
        lessonPlan {
          id
          title
          order
        }
      }
      subject
      message
      headline
      announceDateTime
      isTextMessage
      isEmailMessage
    }
  }
`

export const UPDATE_NEWS = gql`
  mutation UpdateNews($id: ID!, $input: UpdateNewsInput!) {
    updateNews(id: $id, input: $input) {
      id
      announcer {
        id
        firstName
        lastName
      }
      lesson {
        id
        lessonPlan {
          id
          title
          order
        }
      }
      subject
      message
      headline
      announceDateTime
      isTextMessage
      isEmailMessage
    }
  }
`

export const ARCHIVE_NEWS = gql`
  mutation archiveNews($id: ID!) {
    archiveNews(id: $id) {
      id
    }
  }
`

export const SEND_NEWS_WITH_EMAIL = gql`
  mutation sendEmail(
    $recipients: [String]!
    $subject: String!
    $text: String!
    $html: String
    $bccAddresses: [String]!
  ) {
    sendEmail(
      recipients: $recipients
      subject: $subject
      text: $text
      html: $html
      bccAddresses: $bccAddresses
    ) {
      message
      sesSendError
    }
  }
`

export const SEND_SMS = gql`
  mutation sendSMS($phoneNumber: String!, $message: String!) {
    sendSMS(phoneNumber: $phoneNumber, message: $message) {
      success
      message
    }
  }
`
