import React, { Component } from 'react'
import './InvoiceDetail.css'
import {
  Button,
  Flex,
  TextField,
  Checkbox,
  FormControlLabel,
  MenuItem,
} from '../../components'
import { withApollo } from 'react-apollo'
import { CHARGE_AUTHORIZE_CARD, CHARGE_PAYMENT_PROFILE } from './queries'

const validateCardCode = input => RegExp(/^\d{3}\d?$/).test(input)

const validateCardNumber = input =>
  RegExp(
    /^(?:4[0-9]{12}(?:[0-9]{3})?|(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|6(?:011|5[0-9]{2})[0-9]{12}|(?:2131|1800|35\d{3})\d{11})$/
  ).test(input)

const validateExpDate = input => RegExp(/^\d\d\/\d\d$/).test(input)

class AuthorizeForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      creditCardNum: '',
      displayCCNum: '',
      expDate: '',
      cvv: '',
      nameOnCard: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      country: 'USA',

      createProfile: false,

      badCCNum: false,
      badExpDate: false,
      badCvv: false,

      errMsg: '',

      selectedPaymentProfileName: '',
      selectedPaymentProfile: null,
      paymentProfiles: this.props.invoice.familyAccount.paymentProfiles,

      successfulPayment: false,
    }
  }

  handleTransactionResponse = response => {
    if (response.success) {
      this.props.setIdentification(response.transactionId)
      this.props.onSuccessPayment()
      this.setState(
        {
          succMsg: response.messages[0].text,
          successfulPayment: true,
        },
        () =>
          setTimeout(() => {
            this.setState({ succMsg: '' })
          }, 2000)
      )
    } else {
      this.setState({
        errMsg: 'Unsuccessful: ' + response.errorMessage,
      })
    }
  }

  handlePay = async () => {
    const {
      selectedPaymentProfile,
      creditCardNum,
      expDate,
      cvv,
      nameOnCard,
      address,
      city,
      state,
      zip,
      country,
      badCCNum,
      badExpDate,
      badCvv,
    } = this.state
    const { invoice, amount, summary } = this.props
    if (selectedPaymentProfile) {
      this.setState({ errMsg: '', loading: true })

      let res

      const vars = {
        amount: `${amount}`,
        invoiceId: invoice.id,
        orderSummary: summary,
        paymentProfileId: selectedPaymentProfile.id,
      }
      try {
        res = await this.props.client.mutate({
          mutation: CHARGE_PAYMENT_PROFILE,
          variables: vars,
        })
      } catch (e) {
        this.setState({ loading: false, errMsg: `${e}` })
        return
      }
      this.handleTransactionResponse(res.data.chargeAuthorizeNetCustomer)
      this.setState({ loading: false })
      return
    }

    if (
      !(
        creditCardNum &&
        expDate &&
        cvv &&
        nameOnCard &&
        !badCCNum &&
        !badExpDate &&
        !badCvv
      )
    ) {
      this.setState({ errMsg: 'Some fields are invalid' })
      return
    }

    this.setState({ errMsg: '', loading: true })

    const dateSplit = expDate.split('/')

    const formattedDate = '20' + dateSplit[1] + '-' + dateSplit[0]

    const nameSplit = nameOnCard.split(' ')

    let firstName

    if (nameSplit.length > 1) {
      firstName = nameSplit[0]
    }

    const lastName = nameSplit[nameSplit.length - 1]

    const vars = {
      amount: `${amount}`,
      invoiceId: invoice.id,
      orderSummary: summary,
      creditCard: {
        cardNumber: creditCardNum,
        expirationDate: formattedDate,
        cardCode: cvv,
      },
      billTo: {
        firstName,
        lastName,
        address,
        city,
        state,
        zip,
        country,
      },
      createProfile: this.state.createProfile,
    }
    let res

    try {
      res = await this.props.client.mutate({
        mutation: CHARGE_AUTHORIZE_CARD,
        variables: vars,
      })
    } catch (e) {
      this.setState({ loading: false, errMsg: `${e}` })
      return
    }

    this.handleTransactionResponse(res.data.chargeAuthorizeNetCard)

    this.setState({ loading: false })
  }

  setCreditCardNumber = v => {
    let result = this.cc_format(v)
    const glued = this.glueBackCreditCardNum(result)

    this.setState({
      displayCCNum: result,
      creditCardNum: glued,
      badCCNum: !validateCardNumber(glued),
    })
  }

  setExpDate = v => {
    const result = this.cc_expires_format(v)
    this.setState({ expDate: result, badExpDate: !validateExpDate(result) })
  }

  setCvv = v => {
    this.setState({ cvv: v, badCvv: !validateCardCode(v) })
  }

  cc_format = value => {
    var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
    var matches = v.match(/\d{4,16}/g)
    var match = (matches && matches[0]) || ''
    var parts = []

    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4))
    }

    if (parts.length) {
      return parts.join(' ')
    } else {
      return value
    }
  }

  cc_expires_format = string => {
    return string
      .replace(
        /[^0-9]/g,
        '' // To allow only numbers
      )

      .replace(
        /^([2-9])$/g,
        '0$1/' // To handle 3 > 03
      )
      .replace(
        /^(1{1})([3-9]{1})$/g,
        '0$1/$2' // 13 > 01/3
      )
      .replace(
        /^0{1,}/g,
        '0' // To handle 00 > 0
      )
      .replace(
        /^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g,
        '$1/$2' // To handle 113 > 11/3
      )
  }

  glueBackCreditCardNum = num => {
    return num.replaceAll(' ', '')
  }

  render() {
    const {
      loading,
      displayCCNum,
      expDate,
      cvv,
      nameOnCard,
      address,
      city,
      state,
      zip,
      createProfile,
      badCCNum,
      badExpDate,
      badCvv,
      errMsg,
      succMsg,
      selectedPaymentProfileName,
      selectedPaymentProfile,
      paymentProfiles,
      successfulPayment,
    } = this.state

    return (
      <Flex direction="column" flex={1}>
        {paymentProfiles && (
          <TextField
            select
            disabled={loading}
            fullWidth
            value={selectedPaymentProfileName}
            onChange={e =>
              this.setState({ selectedPaymentProfileName: e.target.value })
            }
            label="Choose a saved profile"
          >
            <MenuItem
              value={''}
              onClick={() => this.setState({ selectedPaymentProfile: null })}
            >
              {'Enter new payment'}
            </MenuItem>
            {paymentProfiles.map(p => {
              const name = p.cardType + ' ' + p.cardNumber
              return (
                <MenuItem
                  key={p.id}
                  value={name}
                  onClick={() => this.setState({ selectedPaymentProfile: p })}
                >
                  {name}
                </MenuItem>
              )
            })}
          </TextField>
        )}

        {selectedPaymentProfile ? (
          <Flex direction="column">
            <div style={{ color: 'red' }}>{errMsg}</div>
            <div style={{ color: 'green' }}>{succMsg}</div>
            <Button
              disabled={loading || successfulPayment}
              onClick={this.handlePay}
            >
              Pay with Authorize.net
            </Button>
          </Flex>
        ) : (
          <Flex direction="column" flex={1}>
            <div style={{ color: 'red' }}>{errMsg}</div>
            <div style={{ color: 'green' }}>{succMsg}</div>
            <Flex className="creditCardRow">
              <TextField
                disabled={loading}
                required
                label="Credit card number"
                value={displayCCNum}
                onChange={(e, v) => {
                  this.setCreditCardNumber(e.target.value)
                }}
                error={badCCNum}
                fullWidth
                helperText={badCCNum ? 'Invalid card number' : ''}
                style={{ marginRight: '10px' }}
              />
              <TextField
                disabled={loading}
                required
                label="Exp date"
                placeholder="MM/YY"
                value={expDate}
                onChange={e => this.setExpDate(e.target.value)}
                error={badExpDate}
                helperText={badExpDate ? 'Invalid Date' : ''}
              />
            </Flex>
            <Flex className="creditCardRow">
              <TextField
                disabled={loading}
                label="Name on card"
                value={nameOnCard}
                onChange={e => this.setState({ nameOnCard: e.target.value })}
                required
                style={{ flexBasis: '50%' }}
              />
              <TextField
                disabled={loading}
                required
                label="CVV"
                value={cvv}
                onChange={e => this.setCvv(e.target.value)}
                error={badCvv}
                helperText={badCvv ? 'Invalid Code' : ''}
                style={{ flexBasis: '20%' }}
              />
            </Flex>
            <Flex className="creditCardRow">
              <TextField
                disabled={loading}
                label="Address"
                value={address}
                onChange={e => this.setState({ address: e.target.value })}
                style={{ flexBasis: '50%' }}
              />
              <TextField
                disabled={loading}
                label="City"
                value={city}
                onChange={e => this.setState({ city: e.target.value })}
              />
            </Flex>
            <Flex className="creditCardRow">
              <TextField
                disabled={loading}
                label="State"
                value={state}
                onChange={e => this.setState({ state: e.target.value })}
              />
              <TextField
                disabled={loading}
                label="Zip code"
                value={zip}
                onChange={e => this.setState({ zip: e.target.value })}
              />
            </Flex>
            <br />
            <FormControlLabel
              disabled={loading}
              control={
                <Checkbox
                  checked={createProfile}
                  onChange={() =>
                    this.setState({ createProfile: !createProfile })
                  }
                />
              }
              label="Save payment information"
            />
            <Button
              disabled={loading || successfulPayment}
              onClick={this.handlePay}
            >
              Pay with Authorize.net
            </Button>
            <br />
          </Flex>
        )}
      </Flex>
    )
  }
}

export default withApollo(AuthorizeForm)
