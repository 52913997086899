// @flow
const {
  REACT_APP_NODE_ENV,
  REACT_APP_API_HOST,
  REACT_APP_LOGIN_URL,
  REACT_APP_S3_BUCKET,
  REACT_APP_S3_REGION,
  REACT_APP_TOKEN_NAME,
  REACT_APP_ID_NAME,
} = process.env

export const debug = REACT_APP_NODE_ENV !== 'production'

export const tokenName = REACT_APP_TOKEN_NAME || 'ardentoken'
export const idName = REACT_APP_ID_NAME || 'employeeId'

export const LOCAL_ARDENT_ACCOUNT_URL = 'http://localhost:3001/login'

export const domain = !REACT_APP_NODE_ENV ? 'localhost' : 'ardentlabs.io'

export const loginRedirect =
  REACT_APP_LOGIN_URL ||
  (debug
    ? 'https://account-next.ardentlabs.io/login'
    : 'https://account.ardentlabs.io/login')

export const apiUrl =
  REACT_APP_API_HOST ||
  (debug
    ? 'https://ardent-api-next.ardentlabs.io'
    : 'https://ardent-api.ardentlabs.io')

const bucket = REACT_APP_S3_BUCKET || 'solve-app-dev'
const region = REACT_APP_S3_REGION || 'us-west-1'

export const s3Config = {
  bucket,
  region,
  url: `https://${bucket}.s3-${region}.amazonaws.com`,
}

export const interests = [
  'Math',
  'Science (K-8)',
  'Coding',
  'Physics',
  'Biology',
  'Chemistry',
  'Writing',
  'Speech and Debate',
  'Test Prep (SAT/ACT/AP)',
  'College Counseling',
  'Tutoring',
  'Research',
  'Ardent Labs',
]

export const missingRowColor = '#fad1d1'
export const missingFARowColor = '#fad1fa'
export const expansionBarColor = '#f0f0f0'
export const accordionBarColor = '#f0f0f0'
export const CLASS_SCHEDULED = 'SCHEDULED'
export const TABLE_ROW_BACKGROUND_COLOR = '#ffffff'
export const TABLE_ROW_HIGHLIGHT_COLOR = '#fad1d1'
