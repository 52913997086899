import gql from 'graphql-tag'

export const GET_COURSES = gql`
  query getCourses {
    courses(sort: { code: 1 }) {
      id
      code
      title
    }
  }
`

export const GET_CLASSROOM = gql`
  query getClassroom($id: ID!) {
    classroom(id: $id) {
      id
      code
      course {
        id
        code
      }
      instanceNumber
      status
      centerLocation {
        id
        name
      }
      title
      quarter
      year
      session
      startTime
      startDate
      endTime
      endDate
      finalAccessDate
      videoId
      enrollmentCount
      meets
      meetsOnMonday
      meetsOnTuesday
      meetsOnWednesday
      meetsOnThursday
      meetsOnFriday
      meetsOnSaturday
      meetsOnSunday
      meetIsCustom
      allowTrial
      teachingStyle
      meetingsPerWeek
      onSolve
      onCatalog
      discountable
      enrollmentMax
      tuitionCents
      earlyBirdtuitionCents
      instructorDisplayNames
      enrollments {
        id
        createdOn
        status
        student {
          id
          username
          gender
          firstName
          lastName
          notes
          newStudent
          familyAccount {
            id
            primaryFamilyMember {
              id
              firstName
              lastName
              phoneNumber
              email
            }
          }
        }
        overallGrade
      }
    }
  }
`

export const GET_CLASSROOMS = gql`
  query getClasses($filter: Filters!) {
    classrooms(filter: $filter) {
      id
      status
      startDate
      endDate
      quarter
      session
      year
      code
      title
      enrollmentCount
      meets
      startTime
      endTime
      finalAccessDate
      allowTrial
      tuitionCents
      teachingStyle
      earlyBirdtuitionCents
      centerLocation {
        id
        name
      }
      instructorDisplayNames
      course {
        id
        code
      }
      instanceNumber
      videoId
      meetsOnMonday
      meetsOnTuesday
      meetsOnWednesday
      meetsOnThursday
      meetsOnFriday
      meetsOnSaturday
      meetsOnSunday
      meetIsCustom
      meetingsPerWeek
      onSolve
      onCatalog
      discountable
      enrollmentMax
    }
  }
`

export const GET_ENROLLMENTS_FROM_STUDENT = gql`
  query GetEnrollmentsFromStudent($id: ID!) {
    student(id: $id) {
      id
      enrollments {
        id
        classroom {
          id
          code
          quarter
          year
        }
      }
    }
  }
`
export const GET_EMPLOYEES = gql`
  query getEmployees {
    employees(
      filter: { isTeacher: true, status: "ACTIVE" }
      sort: { lastName: 1 }
    ) {
      id
      status
      firstName
      lastName
    }
  }
`

export const GET_CENTER_LOCATIONS = gql`
  query getCenterLocations {
    centerLocations(filter: {}) {
      id
      name
    }
  }
`

export const GET_STATUS_ENUM = gql`
  query getStatusEnum {
    __type(name: "ClassroomStatus") {
      name
      enumValues {
        name
      }
    }
  }
`

export const GET_TEACHING_STYLE_ENUM = gql`
  query getTeachingStyleEnum {
    __type(name: "TeachingStyle") {
      enumValues {
        name
      }
    }
  }
`

export const UPDATE_INSTRUCTOR_CLASSROOM = gql`
  mutation updateBoth(
    $instID: ID!
    $classID: ID!
    $instInput: UpdateInstructorInput!
    $classInput: UpdateClassroomInput!
  ) {
    updateInstructor(id: $instID, input: $instInput) {
      id
    }
    updateClassroom(id: $classID, input: $classInput) {
      id
    }
  }
`

export const TRANSFER_STUDENT = gql`
  mutation transferEnrollment($enrollmentId: ID!, $toClassroomId: ID!) {
    transferEnrollment(
      enrollmentId: $enrollmentId
      toClassroomId: $toClassroomId
    ) {
      message
    }
  }
`

export const UNENROLL_STUDENTS = gql`
  mutation UnenrollStudents($enrollmentIds: [String]!) {
    unenrollStudents(enrollmentIds: $enrollmentIds) {
      success
      message
    }
  }
`

export const CREATE_CLASSROOM = gql`
  mutation CreateClassroom($input: CreateClassroomInput!) {
    createClassroom(input: $input) {
      id
    }
  }
`

export const UPDATE_CLASSROOM = gql`
  mutation updateClassroom($id: ID!, $input: UpdateClassroomInput!) {
    updateClassroom(id: $id, input: $input) {
      id
    }
  }
`

export const CREATE_INSTRUCTOR = gql`
  mutation CreateInstructor($input: CreateInstructorInput!) {
    createInstructor(input: $input) {
      id
    }
  }
`

export const UPDATE_INSTRUCTOR = gql`
  mutation UpdateInstructor($id: ID!, $input: UpdateInstructorInput!) {
    updateInstructor(id: $id, input: $input) {
      id
    }
  }
`

export const ARCHIVE_INSTRUCTOR = gql`
  mutation ArchiveInstructor($id: ID!) {
    archiveInstructor(id: $id) {
      id
    }
  }
`
