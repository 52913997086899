import React from 'react'

import {
  Button,
  Divider,
  Flex,
  SidebarText,
  withStyles,
} from '../../components'

import { sideBarStyles } from '../../utils/style'

const ReferralViewSidebar = React.memo(
  ({
    referralsCount,
    selected,
    handleOpenAddDialog,
    handleOpenReferralInfo,
    handleArchiveReferral,
    handleOpenEditDialog,
    classes,
  }) => {
    return (
      <Flex
        column
        grow={1}
        shrink={0}
        p="10px"
        style={{
          width: '15.38%',
          borderRight: `1px '#aaaaaa' solid`,
          maxWidth: '200px',
          overflowX: undefined,
          flexShrink: 0,
          textAlign: 'left',
          backgroundColor: '#e5e5e5',
          height: '100vh',
        }}
      >
        <Flex column grow={1} justify="space-between">
          <Flex column>
            <SidebarText
              classes={{
                root: classes.headline,
              }}
              title={'Summary'}
            />
            <Divider />
            <SidebarText
              classes={{ root: classes.subheadingOne }}
              title="Total Referrals:"
              value={referralsCount}
            />
            <Divider style={{ marginBottom: '1em' }} />
            <Button
              disabled={!selected}
              style={{ marginBottom: '1em' }}
              onClick={handleOpenReferralInfo}
            >
              Referral Info
            </Button>
            <Button
              style={{ marginBottom: '1em' }}
              onClick={handleOpenAddDialog}
            >
              Create Referral
            </Button>
            <Button
              disabled={!selected}
              style={{ marginBottom: '1em' }}
              onClick={handleOpenEditDialog}
            >
              Edit Referral
            </Button>
            <Button
              style={{ marginBottom: '1em' }}
              color={'secondary'}
              onClick={handleArchiveReferral}
            >
              Archive Referral
            </Button>
          </Flex>
        </Flex>
      </Flex>
    )
  }
)

export default withStyles(sideBarStyles)(ReferralViewSidebar)
