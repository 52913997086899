import React from 'react'
import { Droppable, Draggable } from 'react-beautiful-dnd'

import {
  Divider,
  Flex,
  FormControl,
  FormControlLabel,
  SidebarText,
  Text,
  Paper,
  Switch,
  withStyles,
} from '../../components'
import { sideBarStyles } from '../../utils/style'

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  width: '180px',
  maxWidth: '180px',
  height: '64x',
  maxHeight: '64px',
  margin: '10px',
  alignItems: 'center',
  justifyConent: 'center',
  background: isDragging && 'lightgreen',
  ...draggableStyle,
})

const getSidebarListStyle = isDraggingOver => ({
  background: isDraggingOver && 'lightblue',
  flexDirection: 'column',
  display: 'flex',
  padding: 1,
  minHeight: '1000px',
  width: '210px',
  maxWidth: '210px',
  marginBottom: '15px',
  alignItems: 'center',
  justifyConent: 'center',
})

const UnscheduledItemSlot = React.memo(({ session }) => {
  return (
    <Paper
      elevation={3}
      style={{
        display: 'flex',
        width: '180px',
        height: '64px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Text color="secondary" style={{ fontWeight: 'bold' }}>
        {`${session.classroom.courseCode} - ${session.order
          .toString()
          .padStart(2, '0')}`}
      </Text>
      <Text color="secondary" style={{ fontWeight: 'bold' }}>
        {session.student.firstName} {session.student.lastName}
      </Text>
    </Paper>
  )
})

const TutorEmployeeDetailSidebar = props => {
  const {
    classes,
    scheduleType,
    handleScheduleChange,
    unscheduledItems,
  } = props

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        grow: 0,
        shrink: 0,
        padding: '10px',
        width: '210px',
        borderRight: `1px '#aaaaaa' solid`,
        maxWidth: '210px',
        flexShrink: 0,
        textAlign: 'left',
        backgroundColor: '#e5e5e5',
        height: '1000px',
        alignItems: 'center',
      }}
    >
      <Flex column grow={1} justify="space-between">
        <Flex mb={'10px'} justify={'center'}>
          <FormControl>
            <FormControlLabel
              className={classes.formControl}
              control={
                <Switch
                  aria-label={'switch-schedule'}
                  checked={scheduleType === 'week'}
                  onChange={handleScheduleChange}
                />
              }
              label={'Weekly'}
            />
          </FormControl>
        </Flex>
        <SidebarText
          classes={{
            root: classes.headline,
          }}
          title="Summary View"
        />
        <Divider style={{ width: '100%' }} />
        <SidebarText
          classes={{ root: classes.subheadingOne }}
          title="Total Unscheduled:"
          value={unscheduledItems.length}
        />
        <SidebarText
          classes={{ root: classes.subheadingOne }}
          title="Total Scheduled:"
          value={'Placeholder'}
        />
        <SidebarText
          classes={{
            root: classes.headline,
          }}
          title="Unscheduled Sessions"
        />
        <Divider style={{ width: '100%' }} />
      </Flex>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Droppable droppableId={'unscheduled'}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getSidebarListStyle(snapshot.isDraggingOver)}
            >
              {provided.placeholder}
              {unscheduledItems.map((session, index) => (
                <Draggable
                  key={session.id}
                  draggableId={session.id}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      {provided.placeholder}
                      <UnscheduledItemSlot session={session} />
                    </div>
                  )}
                </Draggable>
              ))}
            </div>
          )}
        </Droppable>
      </div>
    </div>
  )
}

export default withStyles(sideBarStyles)(TutorEmployeeDetailSidebar)
