import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'

import {
  Button,
  Divider,
  Flex,
  SidebarText,
  withStyles,
} from '../../components'
import { sideBarStyles } from '../../utils/style'

class StudentSidebar extends Component {
  constructor(props) {
    super(props)
    this.state = { redirect: false }
  }

  addStudent = () => {
    this.setState({
      redirect: true,
      redirectTo: '/student/choose-family-account',
    })
  }

  addNewStudent = () => {
    this.setState({
      redirect: true,
      redirectTo: '/opportunity/add-new-opportunity',
    })
  }

  render() {
    const { redirect, redirectTo } = this.state
    if (redirect) {
      return <Redirect push to={redirectTo} />
    }

    const { classes, show, statusCounts, totalStudentsCount } = this.props

    return (
      <Flex
        column
        grow={0}
        shrink={0}
        overflow="hidden"
        p="10px"
        style={{
          width: show ? '15.38%' : 0,
          borderRight: `${show ? '1px' : 0} ${'#aaaaaa'} solid`,
          maxWidth: show ? '200px' : 0,
          overflowX: show ? undefined : 'hidden',
          flexShrink: 0,
          textAlign: 'left',
          backgroundColor: '#e5e5e5',
        }}
      >
        <Flex column grow={1} justify="space-between">
          <Flex column>
            <Button aria-label="AddNewStudent" onClick={this.addNewStudent}>
              Add Student to New Family
            </Button>
            <br />
            <Button aria-label="AddStudent" onClick={this.addStudent}>
              Add Student to Existing Family
            </Button>
            <br />
            <Button
              aria-label="ApproveImages"
              onClick={() => {
                const index = this.props.filtered.findIndex(
                  filter => filter.id === 'newImage'
                )
                const newFiltered = this.props.filtered
                if (index > 0)
                  newFiltered[index].value =
                    newFiltered[index].value === 'ON' ? 'OFF' : 'ON'

                this.props.onFilteredChange(newFiltered)
              }}
            >
              {this.props.filtered.find(filter => filter.id === 'newImage')
                .value === 'ON'
                ? 'Show All'
                : 'Show Awaiting Image Approval'}
            </Button>
            <SidebarText
              classes={{ root: classes.headline }}
              title="Detail View"
            />
            <Divider />
            <SidebarText
              classes={{ root: classes.subheadingOne }}
              title="Total Students:"
              value={totalStudentsCount}
            />
            <SidebarText
              title="Prospective:"
              value={statusCounts['PROSPECT']}
            />
            <SidebarText title="Active:" value={statusCounts['ACTIVE']} />
            <SidebarText title="Inactive:" value={statusCounts['INACTIVE']} />
            <SidebarText title="Graduated:" value={statusCounts['GRADUATED']} />
            <SidebarText title="Closed:" value={statusCounts['CLOSED']} />
          </Flex>
        </Flex>
      </Flex>
    )
  }
}

export default withStyles(sideBarStyles)(StudentSidebar)
