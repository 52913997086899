import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'react-apollo'

import ClassTable from './ClassTable'
import ClassSidebar from './ClassSidebar'
import ClassDetailDialog from './ClassroomDetailDialog'
import EditClassroomDialog from './EditClassroomDialog'

import { Paper } from '@mui/material'
import { LoadingMessage, ErrorMessage } from '../../components'

import { GET_CLASSROOMS } from './queries'

import { formatTimeRange, convertToMDY } from '../../utils/datetime'
import { formatInstructorNames } from '../../utils/instructors'

const ClassTableView = props => {
  const history = useHistory()
  // const [sorted, setSorted] = useState([
  //   {
  //     id: 'code',
  //   },
  // ])
  const [filtered, setFiltered] = useState([
    {
      id: 'status',
      value: 'OPENED',
    },
  ])
  const [checked, setChecked] = useState(false)
  const [classroomId, setClassroomId] = useState(null)

  const [openClassroomDialog, setOpenClassroomDialog] = useState(false)

  const [editDialog] = useState({
    open: false,
    disableSave: true,
    classroomId: '',
  })

  const getStatusFilter = () => {
    for (const filter of filtered) {
      if (filter.id === 'status') {
        return filter.value === 'ALL' ? {} : { status: filter.value }
      }
    }
    return {}
  }

  const onFilteredChange = filtered => {
    setFiltered(filtered)
  }

  const handleRowClick = classroomId => {
    history.push(`/classroom/${classroomId}`)
  }

  const tableChangeHandler = (filters, filteredClasses) => {
    console.log('tableChangeHandler', filtered)
    // onlyFilterByStatus = false
    // classesByOtherFilters = filteredClasses
    // filters = changeToFilterByStatusOnly(filters)
    // setFiltered(filters)
  }

  const handleCheckboxChange = classroomId => {
    if (checked) {
      setClassroomId(null)
    } else {
      setClassroomId(classroomId)
    }
    setChecked(!checked)
  }

  const handleEditClassroom = () => {
    setOpenClassroomDialog(!openClassroomDialog)
  }

  const handleCreateClassroom = () => {
    setChecked(false)
    setClassroomId(null)
    setOpenClassroomDialog(!openClassroomDialog)
  }

  const { loading, error, data } = useQuery(GET_CLASSROOMS, {
    variables: {
      filter: getStatusFilter(),
    },
  })
  if (loading) return <LoadingMessage />
  if (error) return <ErrorMessage error={error} />
  const classrooms = data
    ? data.classrooms.map(classroom => formatClassroom(classroom))
    : []

  return (
    <Paper sx={{ display: 'flex' }}>
      <ClassSidebar
        classrooms={classrooms}
        disableEditButton={!checked}
        openEditDialog={handleEditClassroom}
        handleClassroomDialog={handleCreateClassroom}
      />
      <Paper sx={{ width: '100%' }}>
        <ClassTable
          classrooms={classrooms}
          // sorted={sorted}
          filtered={filtered}
          checked={checked}
          updateClasses={tableChangeHandler}
          handleRowClick={handleRowClick}
          handleFilteredChange={onFilteredChange}
          handleCheckboxChange={handleCheckboxChange}
        />
      </Paper>
      <ClassDetailDialog
        open={openClassroomDialog}
        classroom={{}}
        // refetch={refetch}
        title="Create Classroom"
        closeEditDialog={handleCreateClassroom}
      />
      <EditClassroomDialog
        open={openClassroomDialog}
        edits={editDialog.edits}
        classroomId={classroomId}
        // refetch={refetch}
        disableSave={editDialog.disableSave}
        closeEditDialog={handleEditClassroom}
      />
    </Paper>
  )
}

const formatClassroom = ({
  id,
  status,
  title,
  code,
  quarter,
  session,
  year,
  allowTrial,
  startDate,
  endDate,
  finalAccessDate,
  startTime,
  endTime,
  meets,
  centerLocation,
  enrollmentCount,
  instructors,
  tuitionCents,
  teachingStyle,
  instructorDisplayNames,
}) => ({
  id: id,
  status: status,
  title: title.slice(title.indexOf('-') + 2, title.length),
  code: code,
  quarter: quarter,
  session: session,
  year: year,
  day: meets,
  startDate: quarter === 'Tutoring' ? 'Any Time' : convertToMDY(startDate),
  endDate: quarter === 'Tutoring' ? 'Any Time' : convertToMDY(endDate),
  centerLocation: centerLocation && centerLocation.name,
  enrollmentCount: enrollmentCount,
  instructor: formatInstructorNames(instructors),
  instructorDisplayNames: instructorDisplayNames,
  finalAccessDate: convertToMDY(finalAccessDate),
  time:
    teachingStyle === 'MENTORED'
      ? '90 min/class'
      : quarter === 'Tutoring'
      ? '50 min/class'
      : formatTimeRange(new Date(startTime), new Date(endTime)),
  allowTrial: allowTrial ? '✓' : '',
  tuitionCents,
  teachingStyle,
})

export default ClassTableView
