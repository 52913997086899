import React, { Component } from 'react'
import { GET_CREDITS, ARCHIVE_CREDIT } from './queries'
import { Query, withApollo } from 'react-apollo'
import {
  ErrorMessage,
  Flex,
  LoadingMessage,
  Redirect,
  SnackbarNotification,
} from '../../components'
import CreditTable from './CreditTable'
import CreditSidebar from './CreditSidebar'
class CreditTableView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      redirect: false,
      sorted: [
        {
          id: 'issueDate',
          desc: true,
        },
      ],
      filtered: [
        {
          id: 'status',
          value: 'OPEN',
        },
      ],
      checked: [],
      invoices: [],
      snackbar: {
        open: false,
        message: '',
        messageType: '',
        windowReload: false,
      },
    }
  }

  openSnack = (message, messageType, windowReload) => {
    const { snackbar } = this.state
    const newSnackbar = {
      ...snackbar,
      open: true,
      messageType,
      message,
      windowReload,
    }
    this.setState({ snackbar: newSnackbar })
  }

  onFilteredChange = filtered => {
    this.setState({
      filtered,
    })
  }

  rowClick = rowInfo => {
    rowInfo &&
      this.setState({
        redirect: true,
        redirectTo: `/credit/${rowInfo.original.creditNumber}`,
      })
  }

  updateChecked = newChecked => {
    this.setState({ checked: newChecked })
  }

  archiveChecked = async () => {
    const { checked } = this.state

    let chked = checked.filter(ch => ch)
    if (chked.length === 0) {
      this.openSnack('No credit selected', 'other', false)
      return
    }

    for (let id of chked) {
      await this.props.client.mutate({
        mutation: ARCHIVE_CREDIT,
        variables: { id },
      })
    }

    this.setState({ checked: [] })

    this.openSnack('Credit memo archived, reloading..', 'success', true)
  }

  render() {
    const { redirect, checked, snackbar } = this.state
    if (redirect) {
      return <Redirect push to={this.state.redirectTo} />
    }
    return (
      <Query fetchPolicy={'cache-and-network'} query={GET_CREDITS}>
        {({ loading, error, data, refetch }) => {
          if (loading) return <LoadingMessage />
          if (error) return <ErrorMessage error={error} />

          const processedCredits = data.credits
          return (
            <Flex>
              <CreditSidebar
                show={true}
                credits={processedCredits}
                checked={checked}
                archiveCheckedHandler={this.archiveChecked}
              />
              <Flex align="left" column grow={1}>
                <CreditTable
                  credits={processedCredits}
                  // filterable={true}
                  checked={checked}
                  updateChecked={this.updateChecked}
                  onRowClick={this.rowClick}
                  sorted={this.state.sorted}
                  filtered={this.state.filtered}
                  onFilteredChange={this.onFilteredChange}
                />
              </Flex>
              <SnackbarNotification
                open={snackbar.open}
                handleClose={() => {
                  this.setState({
                    snackbar: {
                      ...snackbar,
                      open: false,
                    },
                  })
                  if (snackbar.windowReload) refetch()
                }}
                message={snackbar.message}
                messageType={snackbar.messageType}
              />
            </Flex>
          )
        }}
      </Query>
    )
  }
}

export default withApollo(CreditTableView)
