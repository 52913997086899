import React from 'react'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

import { Button, ButtonGroup } from '../../components'
import { Flex, Text } from '../../components'
import { WeekSchedule } from './WeekSchedule'
import { DaySchedule } from './DaySchedule'

const TutorEmployeeScheduleHeader = props => {
  const { scheduleType, dateLabel, handleNext, handlePrevious } = props
  return (
    <Flex
      grow={1}
      basis={1}
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: '30px',
      }}
    >
      <Text color="primary" style={{ fontSize: '20px', fontWeight: 'bold' }}>
        {scheduleType === 'week' ? 'Week Schedule' : 'Day Schedule'}
      </Text>
      <ButtonGroup
        size={'large'}
        aria-label={'large-week-button'}
        style={{ marginTop: '10px', marginLeft: 'auto' }}
      >
        <Button
          color={'primary'}
          aria-label={'previous-date'}
          onClick={handlePrevious}
        >
          <NavigateBeforeIcon />
        </Button>
        <Button color={'primary'}>{dateLabel}</Button>
        <Button color={'primary'} aria-label={'next-date'} onClick={handleNext}>
          <NavigateNextIcon />
        </Button>
      </ButtonGroup>
    </Flex>
  )
}

const TutorEmployeeSchedule = props => {
  const {
    tutors,
    week,
    time,
    scheduleType,
    scheduleItems,
    handleOpenDialog,
    handleOpenAttendanceDialog,
    handleChangeTutor,
    handleNextDay,
    handlePreviousDay,
    handleNextWeek,
    handlePreviousWeek,
  } = props

  const headerProps =
    scheduleType === 'week'
      ? {
          scheduleType,
          dateLabel: `${week[0].toLocaleString('default', {
            month: 'long',
            day: 'numeric',
          })} - ${week[6].toLocaleString('default', {
            month: 'long',
            day: 'numeric',
          })}`,
          handleNext: handleNextWeek,
          handlePrevious: handlePreviousWeek,
        }
      : {
          scheduleType,
          dateLabel: time[0].toLocaleString('default', {
            month: 'long',
            day: 'numeric',
          }),
          handleNext: handleNextDay,
          handlePrevious: handlePreviousDay,
        }

  return (
    <Flex grow={1} basis={1} style={{ flexDirection: 'column' }}>
      <TutorEmployeeScheduleHeader {...headerProps} />
      <Flex flex={9}>
        {scheduleType === 'week' ? (
          <WeekSchedule
            items={scheduleItems}
            tutors={tutors}
            week={week}
            handleOpenDialog={handleOpenDialog}
            handleOpenAttendanceDialog={handleOpenAttendanceDialog}
            handleChangeTutor={handleChangeTutor}
          />
        ) : (
          <DaySchedule
            items={scheduleItems}
            tutors={tutors}
            handleOpenAttendanceDialog={handleOpenAttendanceDialog}
          />
        )}
      </Flex>
    </Flex>
  )
}
export default TutorEmployeeSchedule
