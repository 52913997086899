import React, { Component, createRef } from 'react'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Flex,
  MenuItem,
  TextField,
  DateTimePicker,
} from '../../components'
import { FormControl, Input, InputAdornment, InputLabel } from '@mui/material'

import { convertToYMD } from '../../utils/datetime'
import AuthorizeForm from './AuthorizeForm'
import { GET_CREDITS } from './queries'

class AddPaymentForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      identification: '',
      note: '',
      date: convertToYMD(new Date()),
      errorMsg: '',
      method: 'AUTHORIZE',
      amount: this.props.invoice.balance,
      payee: `${this.props.invoice.familyAccount.primaryFamilyMember.firstName} ${this.props.invoice.familyAccount.primaryFamilyMember.lastName}`,
      summary: this.props.invoice.summary.slice(0, 255),
      editing: false,
    }
    this.textRef = createRef()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedItem !== this.props.selectedItem) {
      if (this.props.selectedItem === null) {
        this.setState({
          identification: '',
          note: '',
          date: convertToYMD(new Date()),
          errorMsg: '',
          method: '',
          amount: this.props.invoice.balance,
          payee: `${this.props.invoice.familyAccount.primaryFamilyMember.firstName} ${this.props.invoice.familyAccount.primaryFamilyMember.lastName}`,
          editing: false,
        })
      } else if (this.props.selectedItem.__typename === 'Payment') {
        let item = this.props.selectedItem
        this.setState({
          ...item,
          date: convertToYMD(new Date(item.dateReceived)),
          errorMsg: '',
          editing: true,
        })
      }
    }
  }

  recordFamilyAccountProfileId = profileId => {
    this.props.updateFamAcc(
      this.props.client,
      this.props.invoice.familyAccount.id,
      { customerProfile: profileId }
    )
  }

  deductCreditsByAmount = async overallAmount => {
    //used for applying credit payment to invoices
    //overall amount is the amount user want to pay with credits

    // find all usable credits for this familyAccount
    const { data } = await this.props.client.query({
      query: GET_CREDITS,
      variables: {
        filter: {
          familyAccountId: this.props.invoice.familyAccount.id,
          status: 'OPEN',
        },
      },
      fetchPolicy: 'no-cache',
    })

    let i = 0

    //this function assumes that overallAmount is positive
    //might not be good for updating/editing payments
    while (i !== data.credits.length && overallAmount !== 0) {
      const credit = data.credits[i]
      const deductPerCredit = Math.min(credit.balance, overallAmount)
      if (credit.balance === 0) {
        //nothing in current credit, go to next
        i++
        continue
      }
      overallAmount -= deductPerCredit

      await this.props.updateCreditHandler(this.props.client, credit.id, {
        balance: credit.balance - deductPerCredit,
      })

      i++
    }
  }

  handleSubmit = async () => {
    const {
      identification,
      note,
      date,
      payee,
      amount,
      method,
      editing,
    } = this.state

    const { invoice, selectedItem } = this.props

    if (!(method && identification && date && payee && amount)) {
      this.setState({ errorMsg: 'Enter all fields' })
      return
    }

    let creditOffset = editing ? amount - selectedItem.amount : amount
    if (
      method === 'CREDIT' &&
      creditOffset > Math.abs(invoice.familyAccount.currentCredit)
    ) {
      this.setState({
        errorMsg:
          'You cannot use more credit than you own: $' +
          Math.abs(invoice.familyAccount.currentCredit).toFixed(2),
      })
      return
    }
    let input = {
      invoiceId: invoice.id,
      dateReceived: date,
      payee,
      method: method,
      identification: identification,
      amount, //we use amount here all the time because even if we are editing, we are replacing the original amount with new amount, so no offset needed
      note,
    }
    if (method === 'CREDIT') {
      await this.deductCreditsByAmount(creditOffset)
    }
    if (editing) {
      await this.props.updatePayHandler(
        this.props.client,
        selectedItem.id,
        input
      )
    } else {
      await this.props.createPayHandler(this.props.client, input)
      this.setState({
        identification: '',
        note: '',
        date: convertToYMD(new Date()),
        errorMsg: '',
        method: '',
        amount: 0,
        payee: '',
      })
    }
    this.props.close()
  }
  render() {
    const {
      identification,
      note,
      date,
      payee,
      errorMsg,
      method,
      amount,
      summary,
      editing,
      paymentSuccess,
    } = this.state
    return (
      <Dialog open={this.props.open} fullWidth={true} maxWidth={'lg'}>
        <DialogTitle>{editing ? 'Update payment' : 'Add payment'}</DialogTitle>
        <DialogContent>
          <div style={{ color: 'red' }}>{errorMsg}</div>
          <Flex>
            <Flex direction="column" flex={1}>
              <TextField
                select
                disabled={editing}
                label="Payment method"
                value={method}
                onChange={e => {
                  let invoice = this.props.invoice
                  if (e.target.value === 'CREDIT') {
                    //adding some default fields for credit payment
                    this.setState({
                      method: e.target.value,
                      identification: invoice.invoiceNumber,
                      amount:
                        Math.abs(invoice.familyAccount.currentCredit) >
                        invoice.balance
                          ? invoice.balance
                          : Math.abs(invoice.familyAccount.currentCredit),
                    })
                  } else {
                    this.setState({
                      method: e.target.value,
                      amount: invoice.balance,
                      identification: '',
                    })
                  }
                }}
                style={{ width: '40%' }}
              >
                <MenuItem value={'AUTHORIZE'}>{'Authorize.net'}</MenuItem>

                {(this.props.selectedItem &&
                  this.props.selectedItem.method === 'CREDIT') ||
                (this.props.invoice.familyAccount.currentCredit < 0 &&
                  !this.props.invoice.payments.some(
                    pay => pay.method === 'CREDIT'
                  )) ? (
                  <MenuItem value={'CREDIT'}>{'Credit Balance'}</MenuItem>
                ) : (
                  ''
                )}
                <MenuItem value={'CHECK'}>{'Check'}</MenuItem>
                <MenuItem value={'CASH'}>{'Cash'}</MenuItem>
              </TextField>

              <br />
              {method === 'CREDIT' && (
                <div>
                  <div
                    style={{ color: 'red' }}
                  >{`Unpaid Amount: $${this.props.currentBalance}`}</div>
                  <div
                    style={{ color: 'green' }}
                  >{`Available Credit: $${Math.abs(
                    this.props.currentCredit
                  )}`}</div>
                </div>
              )}
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  label={method === 'CHECK' ? 'Check Number' : 'Confirmation #'}
                  disabled={editing}
                  value={identification}
                  onChange={e =>
                    this.setState({ identification: e.target.value })
                  }
                  style={{ width: '40%' }}
                />
                {!editing && method === 'AUTHORIZE' && identification && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginLeft: '1em',
                      color: 'green',
                    }}
                  >
                    Paid
                    <CheckCircleIcon />
                  </div>
                )}
              </div>
              <br />

              <DateTimePicker
                name="Payment Settlement Date"
                label="Payment Settlement Date"
                value={date || null}
                onChange={e => this.setState({ date: e.target.value })}
                style={{ width: '40%' }}
                required
              />
              <br />
              <FormControl
                fullWidth
                sx={{ m: 1 }}
                variant="standard"
                style={{ width: '40%' }}
              >
                <InputLabel htmlFor="standard-adornment-amount">
                  Amount
                </InputLabel>
                <Input
                  id="standard-adornment-amount"
                  value={amount}
                  onChange={event => {
                    const amount = event.target.value
                      ? parseFloat(event.target.value)
                      : 0.0
                    this.setState({ amount: amount })
                  }}
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                />
              </FormControl>
              <br />
              <TextField
                style={{ width: '40%' }}
                label="Payee"
                value={payee}
                onChange={e => this.setState({ payee: e.target.value })}
              />
              <br />
            </Flex>
            {!editing && method === 'AUTHORIZE' && amount > 0 && (
              <AuthorizeForm
                amount={amount}
                invoice={this.props.invoice}
                setIdentification={v => this.setState({ identification: v })}
                summary={summary}
                onSuccessPayment={() => this.setState({ paymentSuccess: true })}
                recordProfile={this.recordFamilyAccountProfileId}
              />
            )}
          </Flex>
          <Flex direction="row">
            <TextField
              multiline
              label="note"
              value={note}
              onChange={e => this.setState({ note: e.target.value })}
              minRows={5}
              variant="filled"
              style={{ flex: 1 }}
            />
            <div
              style={{
                position: 'relative',
                marginLeft: '2em',
                flex: 1,
                flexGrow: 1,
              }}
            >
              <Button
                style={{ position: 'absolute', right: 0, zIndex: 99 }}
                onClick={e => {
                  this.textRef.current.select()
                  document.execCommand('copy')
                  e.target.focus()
                }}
              >
                Copy
              </Button>
              <TextField
                multiline
                label="summary"
                value={summary}
                minRows={5}
                variant="filled"
                fullWidth
                inputRef={this.textRef}
              />
            </div>
          </Flex>

          <br />
        </DialogContent>
        <DialogActions style={{ paddingBottom: '10px', paddingRight: '10px' }}>
          <Button color="primary" onClick={this.handleSubmit}>
            {editing ? 'Update' : 'Create'}
          </Button>
          <Button
            onClick={this.props.close}
            disabled={paymentSuccess}
            color="secondary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default AddPaymentForm
