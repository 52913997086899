import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

import TaskDetailView from './TaskDetailView'
import TaskTableView from './TaskTableView'
import AddTaskView from './AddTaskView'

class TaskView extends Component {
  render() {
    const url = this.props.match.url
    return (
      <Switch>
        <Route
          path={url + '/add-new-task-from-opportunity/*/assessment'}
          render={props => (
            <AddTaskView
              {...props}
              opportunity
              defaultState={{
                method: 'IN_PERSON',
                type: 'QUALIFYING',
                stage: 'ASSESSMENT',
                centerLocation: 'd2fa88c1-d038-4013-aaf3-f461db3daf4b',
              }}
            />
          )}
        />
        <Route
          path={url + '/add-new-task-from-opportunity/*/recommendation'}
          render={props => (
            <AddTaskView
              {...props}
              opportunity
              defaultState={{ type: 'OPPORTUNITY', stage: 'RECOMMENDATION' }}
            />
          )}
        />
        <Route
          path={url + '/add-new-task-from-student/*'}
          render={props => <AddTaskView {...props} student />}
        />
        <Route
          path={url + '/add-new-task'}
          render={props => <AddTaskView {...props} />}
        />
        <Route path={url + '/*'} component={TaskDetailView} />
        <Route path={url} component={TaskTableView} />
      </Switch>
    )
  }
}

export default TaskView
