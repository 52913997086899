import gql from 'graphql-tag'

export const GET_INVOICES = gql`
  query getInvoices($filter: Filters!) {
    invoices(filter: $filter) {
      id
      invoiceNumber
      status
      price
      balance
      createdOn
      issueDate
      reconciled
      payments {
        id
        method
        payee
        dateReceived
      }
      items {
        id
      }
      familyAccount {
        id
        primaryFamilyMember {
          id
          firstName
          lastName
        }
      }
      responsibleEmployee {
        id
        firstName
        lastName
      }
    }
  }
`

export const GET_INVOICE = gql`
  query getInvoice($filter: Filters!) {
    invoices(filter: $filter) {
      id
      invoiceNumber
      issueDate
      status
      notes
      responsibleEmployee {
        id
        firstName
        lastName
      }
      familyAccount {
        id
        currentBalance
        currentCredit
        primaryFamilyMember {
          id
          firstName
          lastName
          email
          phoneNumber
        }
        paymentProfiles {
          id
          cardType
          cardNumber
        }
        customerId
      }
      items {
        id
        student {
          id
          firstName
          lastName
        }
        earlyBird
        classroom {
          title
          id
          code
          course {
            code
          }
        }
        discount {
          id
          label
        }
        offer {
          id
        }
        description
        unitPrice
        quantity
        price
        itemType
        enrolled
        withdrawal
        withdrawalAmount
      }
      payments {
        id
        identification
        dateReceived
        amount
        method
        payee
        note
        reconciled
      }
      balance
      price
      summary
    }
  }
`

export const CREATE_INVOICE = gql`
  mutation createInvoice($input: CreateInvoiceInput!) {
    createInvoice(input: $input) {
      id
      invoiceNumber
    }
  }
`

export const GET_PAYMENT_METHODS = gql`
  query getPaymentMethods {
    __type(name: "PaymentMethod") {
      enumValues {
        name
      }
    }
  }
`

export const GET_CLASSROOM = gql`
  query getCourse($filter: Filters!, $filter2: Filters) {
    courses(filter: $filter) {
      classrooms(filter: $filter2) {
        id
        status
        code
        title
        startDate
        endDate
        centerLocation {
          id
          name
        }
        teachingStyle
        earlyBirdtuitionCents
        tuitionCents
      }
    }
  }
`

export const GET_FAMILY_STUDENTS = gql`
  query getFamilyStudents($id: ID!) {
    familyAccount(id: $id) {
      students {
        id
        firstName
        lastName
        enrollments {
          classroom {
            code
          }
        }
      }
    }
  }
`

export const CREATE_ITEM = gql`
  mutation createItem($input: CreateItemInput!) {
    createItem(input: $input) {
      id
    }
  }
`

export const UPDATE_ITEM = gql`
  mutation updateItem($id: ID!, $input: UpdateItemInput!) {
    updateItem(id: $id, input: $input) {
      id
    }
  }
`

export const ARCHIVE_ITEM = gql`
  mutation archiveItem($id: ID!) {
    archiveItem(id: $id) {
      id
    }
  }
`
export const ARCHIVE_INVOICE = gql`
  mutation archiveInvoice($id: ID!) {
    archiveInvoice(id: $id) {
      id
    }
  }
`
export const GET_DISCOUNT = gql`
  query getDiscount {
    discounts {
      id
      label
      amount
    }
  }
`
export const UPDATE_FAMILY_ACCOUNT = gql`
  mutation updateFamilyAccount($id: ID!, $input: UpdateFamilyAccountInput!) {
    updateFamilyAccount(id: $id, input: $input) {
      id
    }
  }
`
export const CREATE_PAYMENT = gql`
  mutation createPayment($input: CreatePaymentInput!) {
    createPayment(input: $input) {
      id
    }
  }
`

export const UPDATE_PAYMENT = gql`
  mutation updatePayment($id: ID!, $input: UpdatePaymentInput!) {
    updatePayment(id: $id, input: $input) {
      id
    }
  }
`

export const GET_OFFERS = gql`
  query getOffers {
    offers {
      id
      name
      offerType
      unitPrice
    }
  }
`
export const CREATE_ENROLLMENT = gql`
  mutation enrollStudent($input: CreateEnrollmentInput!) {
    createEnrollment(input: $input) {
      id
    }
  }
`

export const UPDATE_INVOICE = gql`
  mutation updateInvoice($id: ID!, $input: UpdateInvoiceInput!) {
    updateInvoice(id: $id, input: $input) {
      id
    }
  }
`

export const CREATE_CREDIT = gql`
  mutation createCredit($input: CreateCreditInput!) {
    createCredit(input: $input) {
      id
      creditNumber
    }
  }
`

export const UPDATE_CREDIT = gql`
  mutation updateCredit($id: ID!, $input: UpdateCreditInput!) {
    updateCredit(id: $id, input: $input) {
      id
    }
  }
`

export const CHARGE_AUTHORIZE_CARD = gql`
  mutation chargeAuthorizeCard(
    $amount: String!
    $invoiceId: String!
    $orderSummary: String!
    $creditCard: AuthorizeNetCreditCardInput!
    $billTo: AuthorizeNetBillingAddressInput!
    $createProfile: Boolean
  ) {
    chargeAuthorizeNetCard(
      amount: $amount
      invoiceId: $invoiceId
      orderSummary: $orderSummary
      creditCard: $creditCard
      billTo: $billTo
      createProfile: $createProfile
    ) {
      success
      errorMessage
      transactionId
      resultCode
      messages {
        code
        text
      }
    }
  }
`

export const CHARGE_PAYMENT_PROFILE = gql`
  mutation chargePaymentProfile(
    $amount: String!
    $invoiceId: String!
    $orderSummary: String!
    $paymentProfileId: String!
  ) {
    chargeAuthorizeNetCustomer(
      amount: $amount
      invoiceId: $invoiceId
      orderSummary: $orderSummary
      paymentProfileId: $paymentProfileId
    ) {
      success
      errorMessage
      transactionId
      resultCode
      messages {
        code
        text
      }
    }
  }
`

export const GET_TRANSACTION_DETAILS = gql`
  query getTransactionDetails($id: String!) {
    getAuthorizeNetTransaction(transactionId: $id) {
      settleAmount
      creditCard {
        cardNumber
        cardType
      }
    }
  }
`

export const GET_EMPLOYEES = gql`
  query getEmployees {
    employees(
      filter: { isOfficeUser: true, status: "ACTIVE" }
      sort: { lastName: 1 }
    ) {
      id
      status
      firstName
      lastName
    }
  }
`

export const GET_CREDITS = gql`
  query getCredits($filter: Filters) {
    credits(filter: $filter) {
      id
      creditType
      status
      creditNumber
      issueDate
      amount
      balance
    }
  }
`
