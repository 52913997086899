import React, { useState } from 'react'

import {
  Button,
  DateTimePicker,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Flex,
  Paper,
  TextField,
} from '../../components'

const EditPaymentDialog = props => {
  const { client, open, closeEditDialog, payment, updatePayment } = props

  const [dateReceived, setDateReceived] = useState(payment.dateReceived || null)
  const [note, setNote] = useState(payment.note || null)

  return (
    <Dialog open={open} fullWidth={true} onClose={closeEditDialog}>
      <DialogTitle>Edit Payment</DialogTitle>
      <DialogContent>
        <Flex pb={'5px'} grow={2} justify="space-between">
          <Paper style={{ padding: '10px', width: '100%' }}>
            <Flex direction="row" justify="space-between" mb="5px">
              <DateTimePicker
                style={{ width: '98%' }}
                label="Date Received"
                required
                value={dateReceived}
                onChange={event => setDateReceived(event.target.value)}
              />
            </Flex>
            <Flex direction="row" justify="space-between">
              <TextField
                label="Note"
                style={{ width: '98%' }}
                value={note ? note : ''}
                onChange={event => setNote(event.target.value)}
                multiline
                minRows={6}
              />
            </Flex>
          </Paper>
        </Flex>
      </DialogContent>
      <DialogActions style={{ paddingBottom: '10px', paddingRight: '22px' }}>
        <Button onClick={closeEditDialog} color="secondary">
          Close
        </Button>
        <Button
          onClick={() => {
            updatePayment(client, payment.id, {
              dateReceived: new Date(dateReceived).toISOString(),
              note: note,
            })
            closeEditDialog()
          }}
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditPaymentDialog
