import React, { Component } from 'react'
import { withApollo } from 'react-apollo'
import { Redirect } from 'react-router-dom'
import GradeDetailSidebar from './GradeDetailSidebar'
import GradeDetailTable from './GradeDetailTable'
import { Flex, SnackbarNotification } from '../../components'
import { GET_GRADES_FROM_ASSIGNMENT, SUBMIT_GRADES } from './queries'

class GradeDetailTableView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      redirect: false,
      sidebar: false,
      classId: null,
      lessonId: localStorage.getItem('lesson'),
      assignmentId: localStorage.getItem('assignment'),
      shouldRefetch: localStorage.getItem('refetch'),
      grades: null,
      problems: null,
      autoGrading: null,
      edited: false,
      dayOfWeek: 'all',
      filter: { status: 'OPENED' },
      display: 'required',
      snackbar: {
        open: false,
        message: '',
        messageType: '',
      },
    }
  }

  calculatePercentages = grades => {
    const newGrades = grades.map(grade => {
      let unanswered = 0,
        correct = 0,
        incorrect = 0,
        points = 0,
        totalPoints = 0
      for (let i = 0; i < grade.submission.length; i++) {
        totalPoints += grade.submissionMaxPoints[i]
        if (grade.submission[i] === null) {
          unanswered++
        } else if (!grade.submission[i]) {
          incorrect++
        } else {
          correct++
          points += grade.submission[i]
        }
      }
      const percent = grade.graded ? `${grade.overallGrade}%` : 'N/A'
      return {
        ...grade,
        unanswered,
        correct,
        incorrect,
        points,
        totalPoints,
        percent,
      }
    })
    return newGrades
  }

  onChange = (rowNum, problemNum, nodeValue, key = '') => {
    let grades = [...this.state.grades]
    let problems = [...this.state.problems]
    switch (key) {
      case 'student':
        this.setState({
          redirect: true,
          redirectTo: `/grade/${this.state.assignmentId}/${nodeValue}`,
        })
        return
      case 'graded':
        grades[rowNum].graded = !nodeValue
        break
      case 'submittedLate':
        grades[rowNum].submittedLate = !nodeValue
        break
      case 'allCorrect':
        grades[rowNum].submission.fill(nodeValue ? null : 1)
        grades[rowNum].allCorrect = !nodeValue
        break
      default:
        grades[rowNum].submission[problemNum] =
          nodeValue === null ? 1 : nodeValue === 1 ? 0 : null
        problems[problemNum].answered =
          nodeValue === null
            ? problems[problemNum].answered
            : nodeValue === 1
            ? problems[problemNum].answered - 1
            : problems[problemNum].answered
        problems[problemNum].total =
          nodeValue === null
            ? problems[problemNum].total + 1
            : nodeValue === 1
            ? problems[problemNum].total
            : problems[problemNum].total - 1
    }
    grades = this.calculatePercentages(grades)
    this.setState({
      grades,
      problems,
      edited: true,
    })
  }

  onSideBarChange = (key, val) => {
    switch (key) {
      case 'lesson':
        localStorage.setItem('lesson', val)
        this.setState({
          lessonId: val === 'Select Lesson' ? null : val,
          assignmentId: null,
        })
        break
      case 'assignment':
        localStorage.setItem('assignment', val)
        this.onAssignmentChange(val)
        break
      case 'display':
        this.setState({ display: val })
        break
      default:
        break
    }
  }

  onAssignmentChange = async assignmentId => {
    const { client } = this.props
    const [completedSubmisisons] = await Promise.all([
      await client.query({
        query: GET_GRADES_FROM_ASSIGNMENT,
        variables: {
          id: assignmentId,
          lessonId: this.state.lessonId,
        },
      }),
    ])
    let data = completedSubmisisons.data
    this.formatAssignmentData(data, assignmentId)
  }

  formatAssignmentData = (data, assignmentId) => {
    const assignmentTitle =
      data.assignment.problemSet &&
      `${data.assignment.problemSet.order}. ${data.assignment.problemSet.title}`
    let grades = data.gradesForAssignment.map(grade => {
      const attendee = grade.student.attendees.find(
        attendee => attendee.lesson.id === this.state.lessonId
      )
      return {
        ...grade,
        graded:
          grade.submission &&
          grade.submission.submittedAt &&
          grade.submission.graded,
        submittedLate: grade.submission && grade.submission.submittedLate,
        attendeeStatus: attendee ? attendee.status : false,
        isMakeup: attendee ? attendee.isMakeup : false,
      }
    })
    grades.sort((a, b) => {
      if (a.student.lastName > b.student.lastName) return 1
      if (a.student.lastName < b.student.lastName) return -1
      return 0
    })

    const problems = [...data.assignment.problemSet.problems]
      .sort((a, b) => a.order - b.order)
      .map(problem => ({
        ...problem,
        answered: 0,
        total: 0,
      }))

    let numSubmissions = 0
    for (const [i, { submission }] of Object.entries(grades)) {
      grades[i].changed = new Array(problems.length).fill(null)
      grades[i].submission = new Array(problems.length).fill(null)
      grades[i].submissionMaxPoints = new Array(problems.length).fill(null)
      if (submission === null) continue
      numSubmissions++
      if (submission.responses === undefined) {
        grades[i].submission = submission
        grades[i].submissionMaxPoints = submission
        continue
      }
      grades[i].graded = submission.graded
      grades[i].overallGrade = submission.graded && submission.overallGrade
      let allCorrect =
        submission.responses.length === problems.length ? true : false
      for (const response of submission.responses) {
        grades[i].submission[response.problem && response.problem.order - 1] =
          response.sum
        grades[i].submissionMaxPoints[
          response.problem && response.problem.order - 1
        ] = response.count
        problems[
          response.problem && response.problem.order - 1
        ].answered += !!response.sum
        problems[response.problem && response.problem.order - 1].total++
        if (response.percentCorrect !== 100) {
          allCorrect = false
        }
      }
      grades[i].allCorrect = allCorrect
    }
    grades = this.calculatePercentages(grades)
    const unmodifiedProblems = JSON.parse(JSON.stringify(problems))
    const unmodifiedGrades = JSON.parse(JSON.stringify(grades)) //makes deep copy
    this.setState({
      assignmentId,
      assignmentTitle,
      autoGrading: data.assignment.problemSet.autoGrading,
      grades,
      problems,
      numSubmissions,
      unmodifiedGrades,
      unmodifiedProblems,
    })
  }

  getProblemIds = () => {
    return this.state.problems.map(problem => problem.id)
  }

  getGradesAssignmentInputs = () => {
    return this.state.grades.map(grade => {
      return {
        studentId: grade.student.id,
        submittedLate: grade.submittedLate,
        responses: grade.submission,
      }
    })
  }

  handleReset = () => {
    this.setState({
      grades: JSON.parse(JSON.stringify(this.state.unmodifiedGrades)),
      problems: JSON.parse(JSON.stringify(this.state.unmodifiedProblems)),
    })
  }

  handleRefetch = async () => {
    const { client } = this.props
    this.setState({
      snackbar: {
        open: true,
        message: 'Refreshing data',
        messageType: 'loading',
      },
    })
    let success = false
    await Promise.all([
      await client.query({
        query: GET_GRADES_FROM_ASSIGNMENT,
        variables: {
          id: this.state.assignmentId,
          lessonId: this.state.lessonId,
        },
        fetchPolicy: 'network-only',
        context: {
          headers: {
            'cache-control': 'no-cache',
          },
        },
      }),
    ]).then(response => {
      let data = response[0].data
      this.formatAssignmentData(data, this.state.assignmentId)
      success = true
    })
    this.setState({
      snackbar: {
        open: true,
        message: success ? 'Assignment data refreshed' : 'error',
        messageType: success ? 'success' : 'error',
      },
      edited: false,
    })
  }

  handleSubmission = async () => {
    const { client } = this.props
    this.setState({
      snackbar: {
        open: true,
        message: 'Grade Submission in Progress',
        messageType: 'loading',
      },
    })
    const {
      data: {
        gradeAssignment: { success },
      },
    } = await client.mutate({
      mutation: SUBMIT_GRADES,
      variables: {
        aid: this.state.assignmentId,
        pids: this.getProblemIds(),
        input: this.getGradesAssignmentInputs(),
      },
      refetchQueries: [
        {
          query: GET_GRADES_FROM_ASSIGNMENT,
          variables: {
            id: this.state.assignmentId,
            lessonId: this.state.lessonId,
          },
        },
      ],
    })
    this.setState({
      snackbar: {
        open: true,
        message: success ? 'Grades Submitted ✔' : 'error',
        messageType: success ? 'success' : 'error',
      },
      edited: false,
    })
  }

  render() {
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirectTo} />
    }
    if (this.state.shouldRefetch) {
      localStorage.setItem('refetch', false)
      this.setState({ shouldRefetch: false })
      this.onAssignmentChange(localStorage.getItem('assignment'))
    }
    const classroomId = this.props.match.params[0]
    const {
      // autoGrading,
      assignmentId,
      assignmentTitle,
      display,
      grades,
      lessonId,
      numSubmissions,
      problems,
      snackbar,
    } = this.state
    const temp_autoGrading = false //temp for editable to all graders, switch temp back to autoGrading later

    return (
      <Flex>
        <GradeDetailSidebar
          show={true}
          onSideBarChange={this.onSideBarChange}
          classId={classroomId}
          lessonId={lessonId}
          assignmentId={assignmentId}
          handleSubmission={this.handleSubmission}
          handleReset={this.handleReset}
          handleRefetch={this.handleRefetch}
          edited={this.state.edited}
          disableButtons={!(assignmentId && problems) || temp_autoGrading}
          display={display}
          live={this.state.live}
        />
        <Flex pl={'0px'} align="left" column grow={1}>
          {grades && (
            <GradeDetailTable
              assignmentId={assignmentId}
              assignmentTitle={assignmentTitle}
              grades={grades}
              numSubmissions={numSubmissions}
              problems={problems}
              onChange={this.onChange}
              readOnly={temp_autoGrading}
            />
          )}
        </Flex>
        <SnackbarNotification
          open={snackbar.open}
          handleClose={() =>
            this.setState({ snackbar: { ...snackbar, open: false } })
          }
          message={snackbar.message}
          messageType={snackbar.messageType}
        />
      </Flex>
    )
  }
}

export default withApollo(GradeDetailTableView)
