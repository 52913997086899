import React from 'react'
import { makeStyles } from '@mui/styles'

import { Button, SidebarText, SidebarContainer } from '../../components'

import { sideBarStyles } from '../../utils/style'

const useStyles = makeStyles(sideBarStyles)

const TutorEmployeeSidebar = ({
  tutors,
  totalTutors,
  toggleAddTutorDialog,
}) => {
  const classes = useStyles()

  return (
    <SidebarContainer>
      <Button
        onClick={() => {
          toggleAddTutorDialog()
        }}
      >
        Add Tutor
      </Button>
      <SidebarText
        classes={{ root: classes.subheadingOne }}
        title="Total Tutors:"
        value={totalTutors}
      />
    </SidebarContainer>
  )
}

export default TutorEmployeeSidebar
