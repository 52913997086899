import React, { Component } from 'react'
import { withApollo } from 'react-apollo'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import AsyncSelect from 'react-select/async'

import {
  Button,
  Flex,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Text,
  withStyles,
} from '../../components'

import { convertPhoneNumber } from '../../utils'
import { GET_FAMILY_ACCOUNTS } from './queries'

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  input: {
    display: 'flex',
    padding: 0,
  },
  valueContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  noOptionsMessage: {
    fontSize: 16,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
})

class ChooseFamilyAccountView extends Component {
  state = {
    currentValue: '',
    selectedAccount: null,
  }

  changeSelectedAccount = value => {
    this.setState({
      selectedAccount: value,
    })
  }

  matchesPrimaryFamilyMember = familyAccount => {
    const {
      firstName,
      lastName,
    } = this.state.selectedAccount.primaryFamilyMember
    const {
      firstName: otherFirstName,
      lastName: otherLastName,
    } = familyAccount.primaryFamilyMember

    return firstName === otherFirstName && lastName === otherLastName
  }

  getFamilyAccounts = async () => {
    const { client } = this.props
    const { currentValue } = this.state
    const {
      data: { familyAccounts },
    } = await client.query({
      query: GET_FAMILY_ACCOUNTS,
      variables: {
        filter: {
          primaryFamilyMember: {
            $name: currentValue,
          },
        },
        sort: {
          primaryFamilyMember: {
            firstName: 1,
            lastName: 2,
          },
        },
        page: {
          limit: 8,
        },
      },
    })

    this.setState({
      familyAccounts,
    })

    return familyAccounts.map(familyAccount => {
      const { firstName, lastName } = familyAccount.primaryFamilyMember
      return {
        value: familyAccount,
        label: `${firstName} ${lastName}`,
      }
    })
  }

  handleInputChange = value => {
    this.setState({
      currentValue: value,
    })
  }

  render() {
    const { currentValue, familyAccounts, selectedAccount } = this.state
    const { classes } = this.props

    return (
      <Flex
        column
        grow={1}
        className={classes.root}
        style={{
          textAlign: 'left',
          margin: 'auto',
          width: '67%',
          minWidth: '600px',
          maxWidth: '1000px',
        }}
      >
        <br />
        <Text variant="h6" style={{ textAlign: 'center' }}>
          Add new student to existing family account
        </Text>
        <br />
        <Paper>
          <Flex
            column
            style={{
              align: 'center',
              flexGrow: 1,
            }}
            p="20px"
          >
            <Text variant="subtitle1" style={{ textAlign: 'center' }}>
              Search for existing family member to link to the new Student
            </Text>
            <br />
            <AsyncSelect
              autoFocus={!selectedAccount}
              isLoading={!selectedAccount}
              loadOptions={
                currentValue.length >= 2 &&
                (() =>
                  new Promise(resolve => {
                    setTimeout(() => {
                      resolve(this.getFamilyAccounts())
                    }, 1000)
                  }))
              }
              menuIsOpen={currentValue.length >= 2}
              placeholder={'Search...'}
              onChange={option => this.changeSelectedAccount(option.value)}
              onInputChange={this.handleInputChange}
              inputValue={currentValue}
              value={
                selectedAccount
                  ? {
                      value: selectedAccount,
                      label: `${selectedAccount.primaryFamilyMember.firstName} ${selectedAccount.primaryFamilyMember.lastName}`,
                    }
                  : null
              }
            />
            <br />
            {selectedAccount && (
              <div>
                <Text variant="subtitle1">Search Results</Text>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>First Name</TableCell>
                      <TableCell>Last Name</TableCell>
                      <TableCell>Phone Number</TableCell>
                      <TableCell>Email</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {familyAccounts.map(familyAccount => {
                      if (this.matchesPrimaryFamilyMember(familyAccount)) {
                        const {
                          firstName,
                          lastName,
                          phoneNumber,
                          email,
                        } = familyAccount.primaryFamilyMember
                        return (
                          <TableRow
                            hover
                            onClick={() =>
                              this.changeSelectedAccount(familyAccount)
                            }
                            selected={familyAccount.id === selectedAccount.id}
                            key={familyAccount.id}
                          >
                            <TableCell>{firstName}</TableCell>
                            <TableCell>{lastName}</TableCell>
                            <TableCell>
                              {convertPhoneNumber(phoneNumber)}
                            </TableCell>
                            <TableCell>{email}</TableCell>
                          </TableRow>
                        )
                      }
                      return null
                    })}
                  </TableBody>
                </Table>
                <br />
                <Flex direction={'row-reverse'} align={'center'}>
                  <Button
                    component={Link}
                    to={`/student/add-new-student/${selectedAccount.id}`}
                  >
                    Next
                  </Button>
                  <Button
                    color="secondary"
                    component={Link}
                    to={'/student'}
                    style={{ marginRight: '10px' }}
                  >
                    Cancel
                  </Button>
                </Flex>
              </div>
            )}
            {!selectedAccount && (
              <Flex direction={'row-reverse'} align={'center'}>
                <Button
                  color="secondary"
                  component={Link}
                  to={'/student'}
                  style={{ marginRight: '10px' }}
                >
                  Cancel
                </Button>
              </Flex>
            )}
          </Flex>
        </Paper>
      </Flex>
    )
  }
}

ChooseFamilyAccountView.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withApollo(withStyles(styles)(ChooseFamilyAccountView))
