import React, { Component } from 'react'
import { Query, ApolloConsumer, Mutation } from 'react-apollo'
import Composer from 'react-composer'
import { Link, Redirect } from 'react-router-dom'
import { CountryRegionData } from 'react-country-region-selector'

import update from 'immutability-helper'
import CounselingForm from './CounselingForm'
import { convertToMDY, convertToHMA } from '../../utils/datetime'

import { GET_COUNSELING_ENROLLMENT } from './queries'

import {
  Button,
  Divider,
  ErrorMessage,
  AccordionForm,
  FormControl,
  FormControlLabel,
  FormLabel,
  Flex,
  GradeSelector,
  LoadingMessage,
  DatePicker,
  Input,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  SMSDialogButton,
  SnackbarNotification,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Text,
  TextField,
} from '../../components'

import {
  GET_FAMILY_ACCOUNT,
  GET_FAMILY_ACCOUNTS,
  GET_STUDENT,
  GET_STUDENTS,
  CREATE_LOCATION,
  UPDATE_LOCATION,
  UPDATE_STUDENT,
  UPDATE_FAMILY_MEMBER,
  UPDATE_FAMILY_ACCOUNT,
  UPDATE_OPPORTUNITY,
} from '../../queries'
import { expansionBarColor } from '../../config'
import { PhoneNumberInput, getRegions, numberInputValidate } from '../../utils'
import { formatBirthday } from '../../utils/datetime'

const CounselingDetailView = props => {
  const counselingId = props.match.params[0]
  return (
    <Query
      fetchPolicy="cache-and-network"
      query={GET_COUNSELING_ENROLLMENT}
      variables={{ id: counselingId }}
    >
      {({ loading, error, data }) => {
        if (loading) return <LoadingMessage />
        if (error) return <ErrorMessage error={error} />
        return (
          <InnerCounselingDetailView
            {...props}
            counselingEnrollment={data.counselingEnrollment}
          />
        )
      }}
    </Query>
  )
}
class InnerCounselingDetailView extends Component {
  constructor(props) {
    super(props)
    let student = props.counselingEnrollment.student
    let primaryFamilyMemberChanged = false
    const birthdate = new Date(student.birthdate) || new Date()
    const ageDifMs = Date.now() - birthdate.getTime()
    const ageDate = new Date(ageDifMs)
    if (!student.familyAccount.primaryFamilyMember) {
      if (student.familyAccount.familyMembers.length > 0)
        primaryFamilyMemberChanged = true
      student = {
        ...student,
        birthdate: student.birthdate && formatBirthday(student.birthdate),
        over13: Math.abs(ageDate.getUTCFullYear() - 1970) >= 13,
        familyAccount: {
          ...student.familyAccount,
          primaryFamilyMember:
            student.familyAccount.familyMembers.length > 0
              ? student.familyAccount.familyMembers[0]
              : {},
        },
      }
    }
    let needsLocationCreation = false
    if (!student.familyAccount.location) {
      needsLocationCreation = true
      student = {
        ...student,
        familyAccount: {
          ...student.familyAccount,
          location: {
            country: 'United States',
            state: 'California',
          },
        },
      }
    }
    this.state = {
      counselingId: this.props.match.params[0],
      studentChanged: false,
      studentTabValue: 0,
      primaryFamilyMemberChanged,
      needsLocationCreation,
      snackbar: {
        open: false,
        message: '',
        messageType: '',
      },
      student,
    }
  }

  handleTabChange = (event, value) => {
    this.setState({
      studentTabValue: value,
    })
  }

  handleChange = event => {
    const { name, value } = event.target
    const birthdate = new Date(value)
    const ageDifMs = Date.now() - birthdate.getTime()
    const ageDate = new Date(ageDifMs) // miliseconds from epoch

    this.setState(state => ({
      studentChanged: true,
      student: {
        ...state.student,
        [name]: name === 'phoneNumber' ? value.replace(/\D/g, '') : value,
        over13:
          name === 'birthdate'
            ? Math.abs(ageDate.getUTCFullYear() - 1970) >= 13
            : state.student.over13,
      },
    }))
  }

  handleFamilyMemberChange = index => event => {
    const {
      familyMembers,
      primaryFamilyMember,
    } = this.state.student.familyAccount

    const { name, value } = event.target

    this.setState(state => ({
      familyMemberChanged: true,
      student: {
        ...state.student,
        familyAccount: {
          ...state.student.familyAccount,
          familyMembers: update(state.student.familyAccount.familyMembers, {
            [index]: {
              [name]: {
                $set: name === 'phoneNumber' ? value.replace(/\D/g, '') : value,
              },
            },
          }),
          primaryFamilyMember:
            familyMembers[index].id === primaryFamilyMember.id
              ? {
                  ...state.student.familyAccount.primaryFamilyMember,
                  [name]:
                    name === 'phoneNumber' ? value.replace(/\D/g, '') : value,
                }
              : state.student.familyAccount.primaryFamilyMember,
        },
      },
    }))
  }

  handleFamilyMemberSubmission = async (UFMmutation, UFAmutation) => {
    const { student, primaryFamilyMemberChanged } = this.state
    const { familyAccount } = student
    familyAccount.familyMembers.map(async familyMember => {
      await UFMmutation({
        variables: {
          id: familyMember.id,
          input: {
            firstName: familyMember.firstName,
            lastName: familyMember.lastName,
            relation: familyMember.relation,
            phoneNumber: familyMember.phoneNumber,
            preferredLanguage: familyMember.preferredLanguage,
            email: familyMember.email,
            occupation: familyMember.occupation,
            educationLevel: familyMember.educationLevel,
          },
        },
        refetchQueries: [
          {
            query: GET_FAMILY_ACCOUNT,
            variables: {
              id: familyAccount.id,
            },
          },
        ],
      })
    })
    if (primaryFamilyMemberChanged) {
      await UFAmutation({
        variables: {
          id: familyAccount.id,
          input: {
            familyMemberId: familyAccount.primaryFamilyMember.id,
          },
          refetchQueries: [
            {
              query: GET_FAMILY_ACCOUNT,
              variables: {
                id: familyAccount.id,
              },
            },
            {
              query: GET_FAMILY_ACCOUNTS,
            },
          ],
        },
      })
    }
    this.setState({
      primaryFamilyMemberChanged: false,
      familyMemberChanged: false,
    })
  }

  handleFamilyAccountSubmission = async (UFAmutation, locationMutation) => {
    const { needsLocationCreation, student } = this.state
    const { familyAccount } = student
    const { location } = familyAccount
    const { city, country, state, street, zip } = location
    const locationReturn = await locationMutation({
      variables: {
        id: location.id,
        input: {
          street: street,
          city: city,
          country: country,
          state: state,
          zip: zip,
        },
      },
      refetchQueries: [
        {
          query: GET_FAMILY_ACCOUNT,
          variables: {
            id: familyAccount.id,
          },
        },
      ],
    })
    if (needsLocationCreation) {
      await UFAmutation({
        variables: {
          id: familyAccount.id,
          input: {
            status: familyAccount.status,
            locationId: locationReturn.data.createLocation.id,
          },
        },
        refetchQueries: [
          {
            query: GET_FAMILY_ACCOUNT,
            variables: {
              id: familyAccount.id,
            },
          },
        ],
      })
    } else {
      await UFAmutation({
        variables: {
          id: familyAccount.id,
          input: {
            status: familyAccount.status,
          },
        },
        refetchQueries: [
          {
            query: GET_FAMILY_ACCOUNT,
            variables: {
              id: familyAccount.id,
            },
          },
        ],
      })
    }
    this.setState({
      familyAccountChanged: false,
      needsLocationCreation: false,
    })
  }

  handleFamilyAccountChange = event => {
    const { name, value } = event.target
    this.setState(state => ({
      familyAccountChanged: true,
      student: {
        ...state.student,
        familyAccount: {
          ...state.student.familyAccount,
          [name]: value,
        },
      },
    }))
  }

  handleLocationChange = event => {
    const { name, value } = event.target
    let actualCountry = this.state.actualCountry
    if (name === 'country') {
      actualCountry = CountryRegionData.find(c => c[0] === value)
    }
    this.setState(state => ({
      actualCountry,
      familyAccountChanged: true,
      student: {
        ...state.student,
        familyAccount: {
          ...state.student.familyAccount,
          location: {
            ...state.student.familyAccount.location,
            [name]: value,
          },
        },
      },
    }))
  }

  setAsPrimary = familyMember => {
    this.setState(state => ({
      primaryFamilyMemberChanged: true,
      student: {
        ...state.student,
        familyAccount: {
          ...state.student.familyAccount,
          primaryFamilyMember: familyMember,
        },
      },
    }))
  }

  styles = theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: '5px',
      marginRight: '5px',
      padding: '5px',
      width: '20%',
    },
    menu: {
      width: '20%',
    },
    expSummary: {
      backgroundColor: expansionBarColor,
    },
  })

  render() {
    const {
      actualCountry,
      counselingId,
      familyAccountChanged,
      familyMemberChanged,
      needsLocationCreation,
      primaryFamilyMemberChanged,
      redirect,
      redirectTo,
      snackbar,
      student,
      studentChanged,
      studentTabValue,
    } = this.state
    const { counselingEnrollment, statuses, counselors } = this.props
    const {
      counselor,
      endDate,
      lastMeeting,
      meetings,
      nextMeeting,
      startDate,
      status,
    } = counselingEnrollment
    const { enrollments, familyAccount, imageUrl } = student
    const { familyMembers, primaryFamilyMember, location } = familyAccount
    if (redirect) {
      return <Redirect push to={redirectTo} />
    }
    return (
      <Flex
        column
        grow={1}
        style={{
          textAlign: 'left',
          margin: 'auto',
          width: '67%',
          minWidth: '600px',
          maxWidth: '1000px',
        }}
      >
        <br />
        <Link to="/counseling">{'< back to Counseling List'}</Link>
        <br />
        <Flex justify="space-between">
          <Flex column style={{ maxWidth: '850px' }}>
            <Text variant="h5">{`Counseling Detail for ${student.firstName} ${student.lastName}`}</Text>
            <br />
            <Flex>
              <Button
                aria-label="ca"
                component={Link}
                style={{ textAlign: 'center', marginLeft: '5px' }}
                to={`${counselingId}/add-meeting`}
              >
                Add Meeting
              </Button>
              <SMSDialogButton
                label={`Enter a text message that will be sent as a SMS to the student's guardian`}
                phoneNumbers={[primaryFamilyMember.phoneNumber]}
                style={{ marginLeft: '5px', marginRight: '5px' }}
                title="Text Guardian"
              />
            </Flex>
          </Flex>
          {imageUrl && (
            <img
              src={imageUrl}
              alt="student"
              style={{
                maxHeight: '100px',
                maxWidth: '100px',
              }}
            />
          )}
        </Flex>
        <AccordionForm
          expanded={meetings.length > 0}
          title="Counseling Meetings History"
          form={
            meetings.length > 0 ? (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Method</TableCell>
                    <TableCell>Start Date</TableCell>
                    <TableCell>Start Time</TableCell>
                    <TableCell>Created By</TableCell>
                    <TableCell>Counselor</TableCell>
                    <TableCell>Purpose</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {meetings
                    .sort((a, b) => {
                      if (b.startDateTime < a.startDateTime) return -1
                      if (b.startDateTime > a.startDateTime) return 1
                      return 0
                    })
                    .map(meeting => {
                      const {
                        method,
                        startDateTime,
                        createdBy,
                        purpose,
                      } = meeting
                      return (
                        <TableRow
                          hover
                          key={meeting.id}
                          onClick={() =>
                            this.setState({
                              redirect: true,
                              redirectTo: `/counseling/${counselingEnrollment.id}/meeting/${meeting.id}`,
                            })
                          }
                        >
                          <TableCell>{method}</TableCell>
                          <TableCell>{convertToMDY(startDateTime)}</TableCell>
                          <TableCell>{convertToHMA(startDateTime)}</TableCell>
                          <TableCell>
                            {createdBy &&
                              `${createdBy.firstName} ${createdBy.lastName}`}
                          </TableCell>
                          <TableCell>
                            {`${counselor.firstName} ${counselor.lastName}`}
                          </TableCell>
                          <TableCell>{purpose}</TableCell>
                        </TableRow>
                      )
                    })}
                </TableBody>
              </Table>
            ) : (
              <Text variant="h4">
                There are no counseling meetings associated with this student
              </Text>
            )
          }
        />
        <AccordionForm
          expanded
          title="Counseling Information"
          form={
            <CounselingForm
              {...this.props}
              counselingEnrollmentId={counselingEnrollment.id}
              student={student}
              startDate={startDate}
              endDate={endDate}
              lastContactDate={lastMeeting && lastMeeting.endDateTime}
              nextContactDate={nextMeeting && nextMeeting.endDateTime}
              status={status}
              statuses={statuses}
              counselorId={counselor.id}
              counselors={counselors}
              fromCounselingDetailView
            />
          }
        />
        <AccordionForm
          expanded
          title="Student Detail"
          form={
            <Flex
              column
              style={{
                align: 'center',
                flexGrow: 1,
              }}
            >
              <Tabs
                value={studentTabValue}
                onChange={this.handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                centered
                style={{
                  paddingBottom: '10px',
                }}
              >
                <Tab label="Student" />
                <Tab label="Guardians" />
                <Tab label="Family" />
              </Tabs>

              {studentTabValue === 0 && ( //Student tab selected
                <ApolloConsumer>
                  {client => (
                    <Composer
                      components={[
                        <Mutation mutation={UPDATE_STUDENT}>
                          {function() {
                            return ''
                          }}
                        </Mutation>,
                        <Mutation mutation={UPDATE_OPPORTUNITY}>
                          {function() {
                            return ''
                          }}
                        </Mutation>,
                      ]}
                    >
                      {([updateStudent, updateOpportunity]) => (
                        <Flex
                          column
                          style={{
                            align: 'center',
                            flexGrow: 1,
                          }}
                        >
                          <form
                            id="editStudentForm"
                            className={this.styles.container}
                            onSubmit={async e => {
                              e.preventDefault()
                              let input = {
                                firstName: student.firstName,
                                middleName: student.middleName,
                                lastName: student.lastName,
                                birthdate: student.birthdate
                                  ? new Date(student.birthdate)
                                  : null,
                                gender: student.gender,
                                grade: student.grade,
                                school: student.school,
                                email: student.email,
                                phoneNumber: student.phoneNumber,
                              }
                              updateStudent({
                                variables: {
                                  id: student.id,
                                  input,
                                },
                                refetchQueries: [
                                  {
                                    query: GET_STUDENT,
                                    variables: { id: student.id },
                                  },
                                  {
                                    query: GET_STUDENTS,
                                  },
                                ],
                              })
                              this.setState({
                                studentChanged: false,
                              })
                            }}
                          >
                            <Flex justify="space-evenly">
                              <Flex
                                grow={2}
                                m="20px"
                                style={{ maxWidth: '300px' }}
                                column
                              >
                                <TextField
                                  required
                                  name="firstName"
                                  label="First Name"
                                  value={student.firstName}
                                  className={this.styles.textField}
                                  fullWidth
                                  onChange={this.handleChange}
                                />
                                <br />
                                <TextField
                                  name="middleName"
                                  label="Middle Name"
                                  value={student.middleName || ''}
                                  className={this.styles.textField}
                                  fullWidth
                                  onChange={this.handleChange}
                                />
                                <br />
                                <TextField
                                  required
                                  name="lastName"
                                  label="Last Name"
                                  value={student.lastName}
                                  className={this.styles.textField}
                                  fullWidth
                                  onChange={this.handleChange}
                                />
                                <br />
                                <DatePicker
                                  name="birthdate"
                                  label="Birthdate"
                                  value={student.birthdate}
                                  onChange={this.handleChange}
                                />
                                <br />
                                <FormControl
                                  component="fieldset"
                                  className={this.styles.formControl}
                                >
                                  <FormLabel component="legend">
                                    Gender
                                  </FormLabel>
                                  <RadioGroup
                                    aria-label="gender"
                                    name="gender"
                                    className={this.styles.group}
                                    value={student.gender}
                                    onChange={this.handleChange}
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                    }}
                                  >
                                    <FormControlLabel
                                      value="MALE"
                                      control={<Radio color="primary" />}
                                      label="Male"
                                    />
                                    <FormControlLabel
                                      value="FEMALE"
                                      control={<Radio color="secondary" />}
                                      label="Female"
                                    />
                                  </RadioGroup>
                                </FormControl>
                                <Flex>
                                  <GradeSelector
                                    className={this.styles.textField}
                                    value={student.grade}
                                    onChange={this.handleChange}
                                    SelectProps={{
                                      MenuProps: {
                                        className: this.styles.menu,
                                      },
                                    }}
                                    style={{
                                      minWidth: '75px',
                                      paddingRight: '15px',
                                    }}
                                  />
                                  <TextField
                                    name="school"
                                    label="School"
                                    value={student.school}
                                    className={this.styles.textField}
                                    fullWidth
                                    onChange={this.handleChange}
                                  />
                                </Flex>
                              </Flex>
                              <Flex
                                grow={2}
                                m="20px"
                                style={{ maxWidth: '300px' }}
                                column
                              >
                                <br />
                                <FormControl className={this.styles.textField}>
                                  <InputLabel shrink>Phone #</InputLabel>
                                  <Input
                                    disabled={!student.over13}
                                    value={student.phoneNumber}
                                    onChange={this.handleChange}
                                    name="phoneNumber"
                                    inputComponent={PhoneNumberInput}
                                  />
                                </FormControl>
                                <br />
                                <TextField
                                  name="email"
                                  label="Email"
                                  value={student.email}
                                  className={this.styles.textField}
                                  fullWidth
                                  disabled={!student.over13}
                                  onChange={this.handleChange}
                                />
                                <br />
                                <Text variant="caption">
                                  Adding a custom phone number or email for
                                  Students is optional and only available if the
                                  student is 13 or older
                                </Text>
                              </Flex>
                            </Flex>
                            <Flex direction={'row-reverse'} align={'center'}>
                              <Button
                                disabled={!studentChanged}
                                aria-label="Submit"
                                type="submit"
                              >
                                Save Changes
                              </Button>
                              <Text style={{ marginRight: '10px' }}>
                                Make sure to verify all information before
                                hitting save!
                              </Text>
                            </Flex>
                          </form>
                        </Flex>
                      )}
                    </Composer>
                  )}
                </ApolloConsumer>
              )}

              {studentTabValue === 1 && (
                <Flex
                  column
                  style={{
                    align: 'center',
                    flexGrow: 1,
                  }}
                >
                  <Composer
                    components={[
                      <Mutation mutation={UPDATE_FAMILY_MEMBER}>
                        {function() {
                          return ''
                        }}
                      </Mutation>,
                      <Mutation mutation={UPDATE_FAMILY_ACCOUNT}>
                        {function() {
                          return ''
                        }}
                      </Mutation>,
                    ]}
                  >
                    {([UFMmutation, UFAmutation]) => (
                      <form
                        id="editFamilyMemberForm"
                        className={this.styles.container}
                        onSubmit={e => {
                          e.preventDefault()
                          if (
                            familyMembers.reduce(
                              (accumulator, currentValue) => {
                                return (
                                  accumulator &&
                                  (currentValue.phoneNumber.length === 0 ||
                                    currentValue.phoneNumber.length >= 10)
                                )
                              }
                            )
                          ) {
                            this.handleFamilyMemberSubmission(
                              UFMmutation,
                              UFAmutation
                            )
                          } else {
                            this.setState({
                              snackbar: {
                                open: true,
                                message:
                                  'At least one phone number has an improper length',
                                messageType: 'error',
                              },
                            })
                          }
                        }}
                      >
                        {[...familyAccount.familyMembers]
                          .sort((a, b) => {
                            if (
                              a.id ===
                              student.familyAccount.primaryFamilyMember.id
                            )
                              return -1
                            if (
                              b.id ===
                              student.familyAccount.primaryFamilyMember.id
                            )
                              return 1
                            return 0
                          })
                          .map((familyMember, index) => {
                            const isPrimary =
                              familyMember.id === primaryFamilyMember.id
                            return (
                              <div key={index}>
                                <Flex justify="space-evenly">
                                  <Flex
                                    grow={2}
                                    m="20px"
                                    style={{ maxWidth: '300px' }}
                                    column
                                  >
                                    {isPrimary ? (
                                      <Button aria-label="MakePrimary">
                                        Primary Guardian
                                      </Button>
                                    ) : (
                                      <Button
                                        color="default"
                                        aria-label="MakePrimary"
                                        onClick={() =>
                                          this.setAsPrimary(familyMember)
                                        }
                                      >
                                        Set As Primary Guardian
                                      </Button>
                                    )}
                                    <br />
                                    <TextField
                                      required
                                      name="firstName"
                                      label="First Name"
                                      value={familyMember.firstName}
                                      className={this.styles.textField}
                                      fullWidth
                                      onChange={this.handleFamilyMemberChange(
                                        index
                                      )}
                                    />
                                    <br />
                                    <TextField
                                      required
                                      name="lastName"
                                      label="Last Name"
                                      value={familyMember.lastName}
                                      className={this.styles.textField}
                                      fullWidth
                                      onChange={this.handleFamilyMemberChange(
                                        index
                                      )}
                                    />
                                    <br />
                                    <FormControl
                                      component="fieldset"
                                      className={this.styles.formControl}
                                    >
                                      <FormLabel component="legend">
                                        Relationship
                                      </FormLabel>
                                      <RadioGroup
                                        aria-label="relation"
                                        name="relation"
                                        className={this.styles.group}
                                        value={familyMember.relation}
                                        onChange={this.handleFamilyMemberChange(
                                          index
                                        )}
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                          flexWrap: 'nowrap',
                                        }}
                                      >
                                        <FormControlLabel
                                          value="Father"
                                          control={<Radio color="primary" />}
                                          label="Father"
                                        />
                                        <FormControlLabel
                                          value="Mother"
                                          control={<Radio color="secondary" />}
                                          label="Mother"
                                        />
                                        <FormControlLabel
                                          value="Guardian"
                                          control={<Radio color="default" />}
                                          label="Guardian"
                                        />
                                      </RadioGroup>
                                    </FormControl>
                                  </Flex>
                                  <Flex
                                    grow={2}
                                    m="20px"
                                    style={{ maxWidth: '300px' }}
                                    column
                                  >
                                    <TextField
                                      name="email"
                                      label="Email"
                                      value={familyMember.email}
                                      className={this.styles.textField}
                                      fullWidth
                                      onChange={this.handleFamilyMemberChange(
                                        index
                                      )}
                                    />
                                    <br />
                                    <FormControl
                                      className={this.styles.textField}
                                    >
                                      <InputLabel shrink>Phone #</InputLabel>
                                      <Input
                                        value={familyMember.phoneNumber}
                                        onChange={this.handleFamilyMemberChange(
                                          index
                                        )}
                                        name="phoneNumber"
                                        inputComponent={PhoneNumberInput}
                                      />
                                    </FormControl>
                                    <br />
                                    <TextField
                                      name="occupation"
                                      label="Occupation"
                                      value={familyMember.occupation}
                                      className={this.styles.textField}
                                      fullWidth
                                      onChange={this.handleFamilyMemberChange(
                                        index
                                      )}
                                    />
                                    <br />
                                    <TextField
                                      name="educationLevel"
                                      label="Education"
                                      value={familyMember.educationLevel || ''}
                                      className={this.styles.textField}
                                      fullWidth
                                      onChange={this.handleFamilyMemberChange(
                                        index
                                      )}
                                    />
                                  </Flex>
                                </Flex>
                                <Divider />
                                <br />
                              </div>
                            )
                          })}
                        <Flex direction={'row-reverse'} align={'center'}>
                          <Button
                            disabled={
                              !familyMemberChanged &&
                              !primaryFamilyMemberChanged
                            }
                            aria-label="Submit"
                            type="submit"
                          >
                            Save Changes
                          </Button>
                          <Text style={{ marginRight: '10px' }}>
                            Make sure to verify all information before hitting
                            save!
                          </Text>
                        </Flex>
                      </form>
                    )}
                  </Composer>
                </Flex>
              )}

              {studentTabValue === 2 && (
                <Flex
                  column
                  style={{
                    align: 'center',
                    flexGrow: 1,
                  }}
                >
                  <Composer
                    components={[
                      <Mutation mutation={UPDATE_FAMILY_ACCOUNT}>
                        {function() {
                          return ''
                        }}
                      </Mutation>,
                      <Mutation
                        mutation={
                          needsLocationCreation
                            ? CREATE_LOCATION
                            : UPDATE_LOCATION
                        }
                      >
                        {function() {
                          return ''
                        }}
                      </Mutation>,
                    ]}
                  >
                    {([UFAmutation, locationMutation]) => (
                      <form
                        id="editFamilyAccountForm"
                        className={this.styles.container}
                        onSubmit={e => {
                          e.preventDefault()
                          this.handleFamilyAccountSubmission(
                            UFAmutation,
                            locationMutation
                          )
                        }}
                      >
                        <Flex justify="space-evenly">
                          <Flex
                            grow={2}
                            m="20px"
                            style={{ maxWidth: '300px' }}
                            column
                          >
                            <TextField
                              name="status"
                              label="Status"
                              value={familyAccount.status}
                              className={this.styles.textField}
                              select
                              onChange={this.handleFamilyAccountChange}
                              SelectProps={{
                                MenuProps: {
                                  className: this.styles.menu,
                                },
                              }}
                              InputLabelProps={{
                                shrink: Boolean(familyAccount.status),
                              }}
                            >
                              {['ACTIVE', 'INACTIVE', 'PROSPECT', 'CLOSED'].map(
                                option => (
                                  <MenuItem key={option} value={option}>
                                    {option}
                                  </MenuItem>
                                )
                              )}
                            </TextField>
                            <br />
                            <TextField
                              required={needsLocationCreation}
                              name="street"
                              label="Address"
                              value={location.street}
                              className={this.styles.textField}
                              fullWidth
                              onChange={this.handleLocationChange}
                            />
                            <br />
                            <TextField
                              required={needsLocationCreation}
                              name="city"
                              label="City"
                              value={location.city}
                              className={this.styles.textField}
                              fullWidth
                              onChange={this.handleLocationChange}
                            />
                          </Flex>
                          <Flex
                            grow={2}
                            m="20px"
                            style={{ maxWidth: '300px' }}
                            column
                          >
                            <TextField
                              required={needsLocationCreation}
                              name="zip"
                              label="Zip Code"
                              value={location.zip}
                              className={this.styles.textField}
                              fullWidth
                              inputProps={{
                                maxLength: 5,
                                onKeyDown: numberInputValidate,
                              }}
                              pattern="[0-9]"
                              onChange={this.handleLocationChange}
                            />
                            <br />
                            <TextField
                              required={needsLocationCreation}
                              name="state"
                              label="State/Region"
                              value={location.state}
                              className={this.styles.textField}
                              SelectProps={{
                                MenuProps: {
                                  className: this.styles.menu,
                                },
                              }}
                              InputLabelProps={{
                                shrink: Boolean(location.state),
                              }}
                              onChange={this.handleLocationChange}
                            >
                              {getRegions(actualCountry).map(
                                (option, index) => (
                                  <MenuItem key={option} value={option}>
                                    {option}
                                  </MenuItem>
                                )
                              )}
                            </TextField>
                            <br />
                            <TextField
                              required={needsLocationCreation}
                              name="country"
                              label="Country"
                              value={location.country}
                              className={this.styles.textField}
                              select
                              onChange={this.handleLocationChange}
                              SelectProps={{
                                MenuProps: {
                                  className: this.styles.menu,
                                },
                              }}
                              InputLabelProps={{
                                shrink: Boolean(location.country),
                              }}
                            >
                              {CountryRegionData.map((option, index) => (
                                <MenuItem key={option[0]} value={option[0]}>
                                  {option[0]}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Flex>
                        </Flex>
                        <Flex direction={'row-reverse'} align={'center'}>
                          <Button
                            disabled={!familyAccountChanged}
                            aria-label="Submit"
                            type="submit"
                          >
                            Save Changes
                          </Button>
                          <Text style={{ marginRight: '10px' }}>
                            Make sure to verify all information before hitting
                            save!
                          </Text>
                        </Flex>
                      </form>
                    )}
                  </Composer>
                </Flex>
              )}
            </Flex>
          }
        />

        <AccordionForm
          expanded={enrollments.length > 0}
          title="Enrollments History"
          form={
            enrollments.length > 0 ? (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Status</TableCell>
                    <TableCell>Location</TableCell>
                    <TableCell>Quarter</TableCell>
                    <TableCell>Year</TableCell>
                    <TableCell>Course Code</TableCell>
                    <TableCell>Course Name</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {enrollments
                    .sort((a, b) => {
                      const quarters = {
                        Winter: 1,
                        Spring: 2,
                        PreSummer: 3,
                        Summer: 4,
                        Fall: 5,
                        OnDemand: 6,
                        Tutoring: 7,
                        Contest: 8,
                      }
                      return (
                        b.classroom.year - a.classroom.year ||
                        quarters[b.classroom.quarter] -
                          quarters[a.classroom.quarter]
                      )
                    })
                    .map(enrollment => {
                      const { classroom } = enrollment
                      return (
                        <TableRow
                          hover
                          key={enrollment.id}
                          onClick={() =>
                            this.setState({
                              redirect: true,
                              redirectTo: `/classroom/${classroom.id}`,
                            })
                          }
                        >
                          <TableCell>
                            {enrollment.classroom.status === 'OPENED' ? (
                              <span style={{ color: '#27AE60' }}>
                                {'Enrolled'}
                              </span>
                            ) : (
                              <span style={{ color: '#00ADEF' }}>
                                {'Completed'}
                              </span>
                            )}
                          </TableCell>
                          <TableCell>{classroom.centerLocation.name}</TableCell>
                          <TableCell>{classroom.quarter}</TableCell>
                          <TableCell>{classroom.year}</TableCell>
                          <TableCell>{classroom.course.code}</TableCell>
                          <TableCell>{classroom.course.title}</TableCell>
                        </TableRow>
                      )
                    })}
                </TableBody>
              </Table>
            ) : (
              <Text variant="h4">
                There are no enrollments associated with this student
              </Text>
            )
          }
        />
        <SnackbarNotification
          open={snackbar.open}
          handleClose={() => {
            this.setState({
              snackbar: {
                ...snackbar,
                open: false,
              },
            })
          }}
          message={snackbar.message}
          messageType={snackbar.messageType}
        />
      </Flex>
    )
  }
}

export default CounselingDetailView
