import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

import ClassDetailTableView from './ClassDetailTableView'
import ClassTableView from './ClassTableView'
import EnrollStudentView from './EnrollStudentView'

class ClassViewRouter extends Component {
  render() {
    const url = this.props.match.url
    return (
      <Switch>
        <Route path={`${url}/enroll-student/*`} component={EnrollStudentView} />
        <Route path={`${url}/*`} component={ClassDetailTableView} />
        <Route path={url} component={ClassTableView} />
      </Switch>
    )
  }
}

export default ClassViewRouter
