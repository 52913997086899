import React, { Component } from 'react'
import { Query } from 'react-apollo'
import Composer from 'react-composer'
import { Route, Switch } from 'react-router-dom'
import gql from 'graphql-tag'

import ChooseFamilyAccountView from './ChooseFamilyAccount'
import AddStudentView from './AddStudentView'
import StudentDetailView from './StudentDetailView'
import StudentTableView from './StudentTableView'
import ChangeFamilyView from './ChangeFamilyView'

import { ErrorMessage, LoadingMessage } from '../../components'

import { GET_LANGUAGE_PREFERENCE_OPTIONS_QUERY } from '../FamilyView/queries'

class StudentView extends Component {
  render() {
    const { url } = this.props.match
    return (
      <Composer
        components={[
          <Query query={GET_LANGUAGE_PREFERENCE_OPTIONS_QUERY}>
            {function() {
              return
            }}
          </Query>,
          <Query query={GET_ENROLLMENT_STATUS_OPTIONS}>
            {function() {
              return
            }}
          </Query>,
        ]}
      >
        {([
          {
            loading: languagesLoading,
            error: languagesError,
            data: languagesData,
          },
          {
            loading: statusesLoading,
            error: statusesError,
            data: statusesData,
          },
        ]) => {
          if (languagesLoading || statusesLoading) return <LoadingMessage />
          if (languagesError || statusesError)
            return <ErrorMessage error={languagesError || statusesError} />
          const languages = languagesData.__type.enumValues.map(
            ({ name }) => name
          )
          const statuses = statusesData.__type.enumValues.map(
            ({ name }) => name
          )

          return (
            <Switch>
              <Route
                path={`${url}/choose-family-account`}
                render={props => <ChooseFamilyAccountView {...props} />}
              />
              <Route
                path={`${url}/*/change-family-account`}
                render={props => <ChangeFamilyView {...props} />}
              />
              <Route
                path={`${url}/add-new-student/*`}
                render={props => <AddStudentView {...props} />}
              />
              <Route
                path={`${url}/*`}
                render={props => (
                  <StudentDetailView {...props} languages={languages} />
                )}
              />
              <Route
                path={`${url}`}
                render={props => (
                  <StudentTableView {...props} statuses={statuses} />
                )}
              />
            </Switch>
          )
        }}
      </Composer>
    )
  }
}

const GET_ENROLLMENT_STATUS_OPTIONS = gql`
  query getEnrollmentStatusOptions {
    __type(name: "EnrollmentStatus") {
      enumValues {
        name
      }
    }
  }
`

export default StudentView
