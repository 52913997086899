import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import FavoriteIcon from '@material-ui/icons/Favorite'

import {
  Button,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions,
  Flex,
  Text,
  VisibilityOutlinedIcon,
} from '../../components'

const useStyles = makeStyles(() => ({
  cardWrapper: {
    boxShadow: '0 2px 5px 0 rgba(0, 0, 0, .13)',
    display: 'flex',
    flexDirection: 'column',
    width: '380px',
    margin: '20px',
  },
  media: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  aspectRatioContainer: {
    position: 'relative',
    paddingBottom: '56.25%',
    height: 0,
    overflow: 'hidden',
    width: '100%',
  },
  icon: {
    marginLeft: '4px',
  },
}))

const PostsViewCard = React.memo(props => {
  const classes = useStyles()
  const { post, setRedirect, handleOpenEditDialog } = props
  const {
    description,
    favoriteCount,
    id,
    imageUrl,
    published,
    title,
    viewCount,
  } = post

  return (
    <Card className={classes.cardWrapper}>
      <Flex style={{ height: '378px' }}>
        <CardActionArea onClick={() => setRedirect(id)}>
          <div className={classes.aspectRatioContainer}>
            <CardMedia
              className={classes.media}
              image={imageUrl}
              title={title}
            />
          </div>
          <CardContent>
            <Text gutterBottom variant="h6" component="h2">
              {!published && (
                <span
                  style={{
                    color: 'red',
                    marginRight: '8px',
                    fontSize: 12,
                  }}
                >
                  UNPUBLISHED
                </span>
              )}
              {title}
            </Text>
            <Text
              variant="body2"
              style={{ textAlign: 'left', minHeight: '94px' }}
              color="textSecondary"
              component="p"
            >
              {description}
            </Text>
          </CardContent>
        </CardActionArea>
      </Flex>
      <CardActions className={classes.bottom}>
        <Flex>
          <FavoriteIcon color="primary" />
          <Text color="primary" className={classes.icon}>
            {favoriteCount}
          </Text>
        </Flex>
        <Flex>
          <VisibilityOutlinedIcon color="primary" className={classes.icon} />
          <Text color="primary" className={classes.icon}>
            {viewCount}
          </Text>
        </Flex>
        <Button
          onClick={() => handleOpenEditDialog(post)}
          style={{ marginLeft: 'auto' }}
        >
          Edit
        </Button>
      </CardActions>
    </Card>
  )
})

export default PostsViewCard
