import gql from 'graphql-tag'

export const GET_TUTOR_SESSIONS = gql`
  query getTutorSessions($filter: Filters!) {
    tutorSessions(filter: $filter) {
      id
      student {
        id
        firstName
        lastName
      }
      classroom {
        id
        code
      }
      startDateTime
      duration
      tutor {
        id
        firstName
        lastName
      }
    }
  }
`

export const GET_CLASSROOMS = gql`
  query getClasses($filter: Filters!) {
    classrooms(filter: $filter) {
      id
      startDate
      endDate
      code
      title
      enrollmentCount
      meets
      startTime
      endTime
      instructorDisplayNames
    }
  }
`

export const CORE_EMP_FIELDS = gql`
  fragment CoreEmpFields on Employee {
    id
    image
    firstName
    lastName
  }
`

export const LIVE_CHAT = gql`
  ${CORE_EMP_FIELDS}
  subscription subscribeChat($cgi: ID!) {
    liveChat(chatGroupId: $cgi) {
      chatGroup {
        id
        name
      }
      chatMessage {
        id
        text
        createdOn
      }
      chatParticipation {
        id
        chatGroup {
          id
        }
        userId
        userType
        associatedUser {
          ...CoreEmpFields
        }
      }
    }
  }
`

export const ALL_CHATS = gql`
  ${CORE_EMP_FIELDS}
  query getChats($filter: Filters, $sort: Sorts, $page: Pagination) {
    chatMessages(filter: $filter, sort: $sort, page: $page) {
      id
      text
      createdOn
      chatParticipation {
        id
        chatGroup {
          id
        }
        userId
        userType
        associatedUser {
          ...CoreEmpFields
        }
      }
    }
  }
`

export const CREATE_CHAT = gql`
  mutation createChat($input: CreateChatMessageInput!) {
    createChatMessage(input: $input) {
      id
      text
    }
  }
`

export const COUNT_CHATS = gql`
  query countChats($filter: Filters) {
    countChatMessages(filter: $filter)
  }
`

export const CHAT_PARTICIPATION = gql`
  query getChatParticipation($filter: Filters) {
    chatParticipations(filter: $filter) {
      id
      userId
    }
  }
`
