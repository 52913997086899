import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'

import {
  Button,
  Divider,
  Flex,
  SidebarText,
  SnackbarNotification,
  withStyles,
} from '../../components'

import { sideBarStyles } from '../../utils/style'

class TutorClassSidebar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      redirect: false,
      classId: '',
      snackbar: {
        open: false,
        message: '',
        messageType: '',
      },
    }
  }

  updateClassId = event => {
    this.setState({ ...this.state, classId: event.target.value })
  }

  render() {
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirectTo} />
    }
    const { snackbar } = this.state

    const {
      classes,
      classrooms,
      disableEditButton,
      disableStatusButton,
      openEditDialog,
      openStatusDialog,
      openCreateDialog,
      show,
    } = this.props

    const totalClasses = classrooms.length

    const totalStudents = classrooms.reduce((accumulator, classroom) => {
      return (
        accumulator +
        (classroom.enrollmentCount !== null ? classroom.enrollmentCount : 0)
      )
    }, 0)

    const mathOStudents = classrooms.reduce((accumulator, classroom) => {
      return (
        accumulator +
        ((classroom.code.startsWith('M2') || classroom.code.startsWith('M3')) &&
        classroom.enrollmentCount !== null
          ? classroom.enrollmentCount
          : 0)
      )
    }, 0)

    const mathAStudents = classrooms.reduce((accumulator, classroom) => {
      return (
        accumulator +
        (classroom.code.startsWith('M1') && classroom.enrollmentCount !== null
          ? classroom.enrollmentCount
          : 0)
      )
    }, 0)

    const biologyStudents = classrooms.reduce((accumulator, classroom) => {
      return (
        accumulator +
        (classroom.code.startsWith('B') && classroom.enrollmentCount !== null
          ? classroom.enrollmentCount
          : 0)
      )
    }, 0)

    const physicsStudents = classrooms.reduce((accumulator, classroom) => {
      return (
        accumulator +
        (classroom.code.startsWith('N') && classroom.enrollmentCount !== null
          ? classroom.enrollmentCount
          : 0)
      )
    }, 0)

    const scienceStudents = classrooms.reduce((accumulator, classroom) => {
      return (
        accumulator +
        (classroom.code.startsWith('S') && classroom.enrollmentCount !== null
          ? classroom.enrollmentCount
          : 0)
      )
    }, 0)

    const codingStudents = classrooms.reduce((accumulator, classroom) => {
      return (
        accumulator +
        (classroom.code.startsWith('P') && classroom.enrollmentCount !== null
          ? classroom.enrollmentCount
          : 0)
      )
    }, 0)

    const techStudents = classrooms.reduce((accumulator, classroom) => {
      return (
        accumulator +
        (classroom.code.startsWith('T') && classroom.enrollmentCount !== null
          ? classroom.enrollmentCount
          : 0)
      )
    }, 0)

    const englishStudents = classrooms.reduce((accumulator, classroom) => {
      return (
        accumulator +
        (classroom.code.startsWith('E') && classroom.enrollmentCount !== null
          ? classroom.enrollmentCount
          : 0)
      )
    }, 0)

    const speechStudents = classrooms.reduce((accumulator, classroom) => {
      return (
        accumulator +
        (classroom.code.startsWith('D') && classroom.enrollmentCount !== null
          ? classroom.enrollmentCount
          : 0)
      )
    }, 0)

    const researchStudents = classrooms.reduce((accumulator, classroom) => {
      return (
        accumulator +
        (classroom.code.startsWith('A') && classroom.enrollmentCount !== null
          ? classroom.enrollmentCount
          : 0)
      )
    }, 0)

    return (
      <Flex
        column
        grow={0}
        shrink={0}
        overflow="hidden"
        p="10px"
        style={{
          width: show ? '15.38%' : 0,
          borderRight: `${show ? '1px' : 0} ${'#aaaaaa'} solid`,
          maxWidth: show ? '200px' : 0,
          overflowX: show ? undefined : 'hidden',
          flexShrink: 0,
          textAlign: 'left',
          backgroundColor: '#e5e5e5',
        }}
      >
        <Flex column grow={1} justify="space-between">
          <Flex column>
            <Button
              disabled={disableEditButton}
              color="secondary"
              onClick={openEditDialog}
            >
              Edit Classroom
            </Button>
            <br />
            <Button
              disabled={disableStatusButton}
              color="secondary"
              onClick={openStatusDialog}
            >
              Change Status
            </Button>
            <br />
            <Button color="secondary" onClick={openCreateDialog}>
              Create Classroom
            </Button>
            <SidebarText
              classes={{
                root: classes.headline,
              }}
              title="Summary View"
            />
            <Divider />
            <SidebarText
              classes={{ root: classes.subheadingOne }}
              title="Total Classes:"
              value={totalClasses}
            />
            <SidebarText
              classes={{ root: classes.subheadingOne }}
              title="Total Students:"
              value={totalStudents}
            />
            <Divider />
            <SidebarText title="Math Olympiad:" value={mathOStudents} />
            <SidebarText title="Math Acceleration:" value={mathAStudents} />
            <SidebarText title="Biology:" value={biologyStudents} />
            <SidebarText title="Physics:" value={physicsStudents} />
            <SidebarText title="Science:" value={scienceStudents} />
            <SidebarText title="Coding:" value={codingStudents} />
            <SidebarText title="Technology:" value={techStudents} />
            <SidebarText title="English:" value={englishStudents} />
            <SidebarText title="Speech & Debate:" value={speechStudents} />
            <SidebarText title="Research:" value={researchStudents} />
          </Flex>
          {this.props.menu()}
        </Flex>
        <SnackbarNotification
          open={snackbar.open}
          handleClose={() => {
            this.setState({
              snackbar: {
                ...snackbar,
                open: false,
              },
            })
          }}
          message={snackbar.message}
          messageType={snackbar.messageType}
        />
      </Flex>
    )
  }
}

export default withStyles(sideBarStyles)(TutorClassSidebar)
