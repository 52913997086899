import React, { Component } from 'react'
import { Query } from 'react-apollo'
import Composer from 'react-composer'

import IssueSidebar from './IssueSidebar'
import IssueTable from './IssueTable'
import { ErrorMessage, Flex, LoadingMessage } from '../../components'
import {
  GET_ISSUE_STATUS_OPTIONS,
  GET_ISSUE_SEVERITY_OPTIONS,
  GET_ISSUE_PRIORITY_OPTIONS,
  GET_TECH_EMPLOYEES,
  COUNT_ISSUES,
} from './queries'

class IssueTableView extends Component {
  render() {
    return (
      <Composer
        components={[
          <Query query={GET_ISSUE_STATUS_OPTIONS}>{() => {}}</Query>,
          <Query query={GET_ISSUE_SEVERITY_OPTIONS}>{() => {}}</Query>,
          <Query query={GET_ISSUE_PRIORITY_OPTIONS}>{() => {}}</Query>,
          <Query query={GET_TECH_EMPLOYEES}>{() => {}}</Query>,
          <Query
            query={COUNT_ISSUES}
            variables={{
              filter: {
                issueType: 'ATTENDANCE',
              },
            }}
          >
            {() => {}}
          </Query>,
        ]}
      >
        {([
          { loading: statusLoading, error: statusError, data: statusData },
          {
            loading: severityLoading,
            error: severityError,
            data: severityData,
          },
          {
            loading: priorityLoading,
            error: priorityError,
            data: priorityData,
          },
          {
            loading: employeeLoading,
            error: employeeError,
            data: employeeData,
          },
          { loading: countLoading, error: countError, data: countData },
        ]) => {
          if (
            statusLoading ||
            severityLoading ||
            priorityLoading ||
            employeeLoading ||
            countLoading
          )
            return <LoadingMessage />
          if (
            statusError ||
            severityError ||
            priorityError ||
            employeeError ||
            countError
          )
            return (
              <ErrorMessage
                error={
                  statusError ||
                  severityError ||
                  priorityError ||
                  employeeError ||
                  countError
                }
              />
            )
          const statuses = statusData.__type.enumValues.map(({ name }) => name)
          const severities = severityData.__type.enumValues.map(
            ({ name }) => name
          )
          const priorities = priorityData.__type.enumValues.map(
            ({ name }) => name
          )
          const totalCount = countData.countIssues
          return (
            <Flex>
              <IssueSidebar statuses={statuses} totalCount={totalCount} />
              <Flex column grow={1}>
                <IssueTable
                  employees={employeeData.employees}
                  statuses={statuses}
                  severities={severities}
                  priorities={priorities}
                  changeFilter={this.changeFilter}
                />
              </Flex>
            </Flex>
          )
        }}
      </Composer>
    )
  }
}
export default IssueTableView
