import React, { Component } from 'react'
import { Query, ApolloConsumer } from 'react-apollo'
import {
  ARCHIVE_ITEM,
  CREATE_CREDIT,
  CREATE_ITEM,
  CREATE_PAYMENT,
  GET_INVOICE,
  UPDATE_CREDIT,
  UPDATE_FAMILY_ACCOUNT,
  UPDATE_INVOICE,
  UPDATE_ITEM,
  UPDATE_PAYMENT,
} from './queries'

import {
  ErrorMessage,
  Flex,
  LoadingMessage,
  SnackbarNotification,
} from '../../components'
import InvoiceDetailedPanel from './InvoiceDetailedPanel'
import InvoiceDetailSidebar from './InvoiceDetailSidebar'
import AddClassroomForm from './AddClassroomForm'
import ApplyDiscountForm from './ApplyDiscountForm'
import AddPaymentForm from './AddPaymentForm'
import { Redirect, withRouter } from 'react-router-dom'
import AddOfferForm from './AddOfferForm'
import { CREATE_ENROLLMENT } from '../../queries'
import AddRefundForm from './AddRefundForm'

class InvoiceDetailedView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openAddClass: false,
      openApplyDiscount: false,
      openAddPayment: false,
      openAddOffer: false,
      openAddRefund: false,
      snackbar: {
        open: false,
        message: '',
        messageType: '',
        windowReload: false,
      },
      selectedItem: null,
    }
  }

  openSnack = (message, messageType, windowReload) => {
    const { snackbar } = this.state
    const newSnackbar = {
      ...snackbar,
      open: true,
      messageType,
      message,
      windowReload,
    }
    this.setState({ snackbar: newSnackbar })
  }

  addItem = async (client, inputs) => {
    this.openSnack('Adding item...', 'other', false)

    for (let input of inputs) {
      await client.mutate({
        mutation: CREATE_ITEM,
        variables: {
          input,
        },
      })
    }
    this.openSnack('Success! Refreshing...', 'success', true)
  }

  updateItem = async (client, id, input) => {
    this.openSnack('Updating item...', 'other', false)
    const { data } = await client.mutate({
      mutation: UPDATE_ITEM,
      variables: {
        id,
        input,
      },
    })

    if (data && data.updateItem.id) {
      this.openSnack('Success! Refreshing...', 'success', true)
    } else {
      this.openSnack('Failed! Refreshing...', 'error', true)
    }
  }

  archiveItem = async (client, id) => {
    this.openSnack('Deleting item...', 'other', false)

    const { data } = await client.mutate({
      mutation: ARCHIVE_ITEM,
      variables: { id },
    })

    if (data && data.archiveItem.id) {
      this.openSnack('Success! Refreshing...', 'success', true)
    } else {
      this.openSnack('Failed! Refreshing...', 'error', true)
    }
  }

  withdrawItems = async (client, items) => {
    for (let item of items) {
      await client.mutate({
        mutation: UPDATE_ITEM,
        variables: {
          id: item.id,
          input: { withdrawal: true, withdrawalAmount: item.withdrawalAmount },
        },
      })
    }
  }

  updateInvoice = async (client, id, input, refresh = false) => {
    this.openSnack('Updating invoice..', 'other', false)
    await client.mutate({
      mutation: UPDATE_INVOICE,
      variables: { id, input },
    })
    this.openSnack(
      'Success!' + (refresh ? ' Refreshing...' : ''),
      'success',
      refresh
    )
  }

  updateFamilyAccount = async (client, id, input, refresh = false) => {
    this.openSnack('Updating payments...', 'other', false)
    await client.mutate({
      mutation: UPDATE_FAMILY_ACCOUNT,
      variables: { id, input },
    })
    this.openSnack(
      'Success!' + (refresh ? ' Refreshing...' : ''),
      'success',
      refresh
    )
  }

  createPayment = async (client, input) => {
    this.openSnack('Creating payments...', 'other')
    await client.mutate({ mutation: CREATE_PAYMENT, variables: { input } })
    this.openSnack('Success! Refreshing..', 'success', true)
  }

  updatePayment = async (client, id, input) => {
    this.openSnack('Updating payments...', 'other')
    await client.mutate({ mutation: UPDATE_PAYMENT, variables: { id, input } })
    this.openSnack('Success! Refreshing..', 'success', true)
  }

  enrollSingle = async (client, itemId, studentId, classroomId) => {
    const { errors } = await client.mutate({
      mutation: CREATE_ENROLLMENT,
      variables: { input: { studentId, classroomId, status: 'PAID' } },
      errorPolicy: 'all',
    })
    if (errors && errors.length !== 0) {
      for (let error of errors) {
        alert(`${error.message}, student: ${studentId}, class: ${classroomId}`)
      }
    }
    await client.mutate({
      mutation: UPDATE_ITEM,
      variables: {
        id: itemId,
        input: { enrolled: true },
      },
    })
  }

  enrollStudents = async (client, items) => {
    //loop through items, if isClassroom && not yet enrolled, enroll.
    this.openSnack('Enrolling students..', 'other')
    for (let item of items) {
      if (item.itemType === 'CLASSROOM' && !item.enrolled && !item.withdrawal) {
        await this.enrollSingle(
          client,
          item.id,
          item.student.id,
          item.classroom.id
        )
      }
    }
    this.openSnack('Success! Refreshing..', 'success', true)
  }

  createCredit = async (client, input) => {
    const { data } = await client.mutate({
      mutation: CREATE_CREDIT,
      variables: { input },
    })
    return data.createCredit.creditNumber
  }

  updateCredit = async (client, id, input) => {
    await client.mutate({ mutation: UPDATE_CREDIT, variables: { id, input } })
  }

  render() {
    const {
      openAddClass,
      openApplyDiscount,
      openAddPayment,
      openAddOffer,
      openAddRefund,
      selectedItem,
      snackbar,
    } = this.state
    return (
      <Query
        fetchPolicy={'cache-and-network'}
        query={GET_INVOICE}
        variables={{ filter: { invoiceNumber: this.props.match.params[0] } }}
      >
        {({ loading, error, data, refetch }) => {
          if (loading) return <LoadingMessage />
          if (error) return <ErrorMessage error={error} />

          if (data.invoices.length !== 1) {
            return <Redirect to="/invoice" />
          }

          let temp = data.invoices[0]

          let invoice = {
            ...temp,
            familyAccountId: temp.familyAccount ? temp.familyAccount.id : null,
            primaryFamilyMember: temp.familyAccount
              ? temp.familyAccount.primaryFamilyMember
              : null,
          }
          return (
            <ApolloConsumer>
              {client => (
                <div
                  style={{
                    display: 'flex',
                    minHeight: '88vh',
                  }}
                >
                  <AddClassroomForm
                    client={client}
                    selectedItem={selectedItem}
                    open={openAddClass}
                    close={() => this.setState({ openAddClass: false })}
                    invoice={invoice}
                    addHandler={this.addItem}
                    updateHandler={this.updateItem}
                    delHandler={this.archiveItem}
                  />
                  <ApplyDiscountForm
                    client={client}
                    invoice={invoice}
                    selectedItem={selectedItem}
                    open={openApplyDiscount}
                    addHandler={this.addItem}
                    updateHandler={this.updateItem}
                    delHandler={this.archiveItem}
                    close={() => this.setState({ openApplyDiscount: false })}
                  />
                  <AddPaymentForm
                    client={client}
                    open={openAddPayment}
                    invoice={invoice}
                    selectedItem={selectedItem}
                    currentBalance={invoice.familyAccount.currentBalance}
                    currentCredit={invoice.familyAccount.currentCredit}
                    updateFamAcc={this.updateFamilyAccount}
                    createPayHandler={this.createPayment}
                    updatePayHandler={this.updatePayment}
                    updateCreditHandler={this.updateCredit}
                    close={() => this.setState({ openAddPayment: false })}
                  />
                  <AddOfferForm
                    client={client}
                    open={openAddOffer}
                    selectedItem={selectedItem}
                    invoice={invoice}
                    addHandler={this.addItem}
                    updateHandler={this.updateItem}
                    close={() => this.setState({ openAddOffer: false })}
                  />
                  <AddRefundForm
                    client={client}
                    open={openAddRefund}
                    selectedItem={selectedItem}
                    invoice={invoice}
                    withdrawItems={this.withdrawItems}
                    updateInvoice={this.updateInvoice}
                    openSnack={this.openSnack}
                    createCredit={this.createCredit}
                    close={() => this.setState({ openAddRefund: false })}
                  />
                  <InvoiceDetailSidebar
                    client={client}
                    addClass={() => {
                      this.setState({ selectedItem: null, openAddClass: true })
                    }}
                    applyDiscount={() =>
                      this.setState({
                        selectedItem: null,
                        openApplyDiscount: true,
                      })
                    }
                    addPayment={() =>
                      this.setState({
                        selectedItem: null,
                        openAddPayment: true,
                      })
                    }
                    addPaymentDisabled={invoice.balance <= 0}
                    addOffer={() =>
                      this.setState({ selectedItem: null, openAddOffer: true })
                    }
                    addRefund={() => {
                      this.setState({ selectedItem: null, openAddRefund: true })
                    }}
                    currentCredit={invoice.familyAccount.currentCredit}
                    enrollAll={client => {
                      this.enrollStudents(client, invoice.items)
                    }}
                    updateInvoice={input => {
                      this.updateInvoice(client, invoice.id, input)
                    }}
                    payments={invoice.payments}
                    balance={invoice.balance}
                    showRefundButton={invoice.balance < 0}
                  />
                  <Flex align="left" column grow={1}>
                    <InvoiceDetailedPanel
                      client={client}
                      invoice={invoice}
                      updateInvoice={this.updateInvoice}
                      setSelectedItem={item => {
                        this.setState({
                          selectedItem: item,
                        })

                        switch (item.itemType) {
                          case 'CLASSROOM':
                            this.setState({ openAddClass: true })
                            return
                          case 'DISCOUNT':
                            this.setState({ openApplyDiscount: true })
                            return
                          case 'OFFER':
                            this.setState({ openAddOffer: true })
                            return
                          default:
                            this.setState({ openAddPayment: true })
                        }
                      }}
                    />
                  </Flex>
                  <SnackbarNotification
                    open={snackbar.open}
                    handleClose={() => {
                      this.setState({
                        snackbar: {
                          ...snackbar,
                          open: false,
                        },
                      })
                      if (snackbar.windowReload) refetch()
                    }}
                    message={snackbar.message}
                    messageType={snackbar.messageType}
                  />
                </div>
              )}
            </ApolloConsumer>
          )
        }}
      </Query>
    )
  }
}

export default withRouter(InvoiceDetailedView)
