import React, { Component } from 'react'
import Button from './Button'
import Flex from './Flex'
import Text from './Text'

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hasError: false,
    }
  }

  componentDidCatch(error, errorInfo) {
    console.error(error)

    this.setState({
      hasError: true,
    })
  }

  render() {
    const { hasError } = this.state

    if (hasError) {
      return (
        <Flex column>
          <Text color="error">Unexpected render error</Text>
          <Button
            onClick={() =>
              this.setState({
                hasError: false,
              })
            }
          >
            Retry
          </Button>
        </Flex>
      )
    }

    return this.props.children
  }
}
