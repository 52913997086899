import React from 'react'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import { Box } from '@mui/material'

import { convertPhoneNumber } from '../../utils'
import { convertToYMDHM } from '../../utils/datetime'

const toBeScheduledColor = '#ccffcc'
const toBeRenewedColor = '#ffcccc'
const renewInvoiceSentColor = '#fdb573'
const toTakeAttendanceColor = '#ffffcc'

const statuses = ['ACTIVE', 'COMPLETED', 'INCOMPLET']

const TutorStudentTable = ({
  checked,
  students,
  page,
  pages,
  pageSize,
  sorted,
  filtered,
  onRowClick,
  handleFilteredChange,
  handleFetchData,
  handlePageChange,
  updateTable,
}) => {
  const defaultSorted = [{ id: 'lastName' }]

  const columns = [
    {
      Header: 'Status',
      accessor: 'completionStatus',
      width: 135,
      Cell: props => {
        if (props.value === 'ACTIVE') {
          return <span style={{ color: '#27AE60' }}>{props.value}</span>
        }
        if (props.value === 'INCOMPLET') {
          return <span style={{ color: '#EB5757' }}>{props.value}</span>
        }
        return <span>{props.value}</span>
      },
      filterMethod: (filter, row) => {
        if (filter.value !== 'ALL') {
          return row[filter.id] === filter.value
        }
        return true
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: '100%' }}
          value={filter ? filter.value : 'ALL'}
        >
          <option value="ALL">ALL</option>
          {statuses.map(option => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      ),
    },
    {
      Header: 'Class',
      accessor: 'classCode',
      sortable: true,
      width: 100,
      Cell: props => <span>{props.value}</span>,
    },
    {
      Header: 'Sessions',
      accessor: 'sessions',
      style: { textAlign: 'center' },
      width: 100,
      Cell: props => <span>{props.value}</span>,
    },
    {
      Header: 'Next Session',
      accessor: 'nextSession',
      style: { textAlign: 'center' },
      width: 160,
      Cell: props => <span>{props.value}</span>,
    },
    {
      Header: 'First Name',
      accessor: 'firstName',
    },
    {
      Header: 'Last Name',
      accessor: 'lastName',
      filterMethod: (filter, row) => {
        return (
          row[filter.id] &&
          row[filter.id].toLowerCase().startsWith(filter.value.toLowerCase())
        )
      },
    },
    {
      Header: 'Gender',
      accessor: 'gender',
      width: 66,
      Cell: props => (
        <span>
          {props.value === 'MALE' ? 'M' : props.value === 'FEMALE' ? 'F' : '-'}
        </span>
      ),
      filterMethod: (filter, row) => {
        if (filter.value === 'M') {
          return row[filter.id] === 'MALE'
        }
        if (filter.value === 'F') {
          return row[filter.id] === 'FEMALE'
        }
        return true
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: '100%' }}
          value={filter ? filter.value : 'ALL'}
        >
          <option value="ALL">ALL</option>
          <option value="MALE">M</option>
          <option value="FEMALE">F</option>
        </select>
      ),
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Phone Number',
      accessor: 'phoneNumber',
      Cell: props => (
        <span className="number">{convertPhoneNumber(props.value)}</span>
      ),
      minWidth: 110,
    },
    {
      Header: 'Grade',
      accessor: 'grade',
      width: 64,
      Cell: props => <span>{props.value === 0 ? 'K' : props.value}</span>,
      filterMethod: (filter, row) => {
        if (isNaN(filter.value)) {
          if (filter.value === 'ALL') {
            return true
          }
          if (filter.value === '-') {
            return row[filter.id] === null
          }
        }
        return row[filter.id] === parseInt(filter.value, 10)
      },
      Filter: ({ filter, onChange }) => {
        return (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: '100%' }}
            value={filter ? filter.value : 'ALL'}
          >
            <option value="ALL">ALL</option>
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(i => (
              <option key={'gradeFilter'.concat(i)} value={i}>
                {i === 0 ? 'K' : i}
              </option>
            ))}
            <option value="-">-</option>
          </select>
        )
      },
    },
    {
      Header: 'School',
      accessor: 'school',
    },
  ]

  return (
    <Box align="left">
      <ReactTable
        data={students}
        defaultFiltered={filtered}
        onFilteredChange={handleFilteredChange}
        onPageChange={handlePageChange}
        columns={columns}
        filterable={true}
        defaultSorted={defaultSorted}
        className="-highlight"
        style={{ fontSize: '10pt' }}
        defaultPageSize={25}
        manual
        page={page}
        pageSize={pageSize}
        pages={pages}
        onFetchData={handleFetchData}
        defaultFilterMethod={(filter, row) =>
          row[filter.id] &&
          row[filter.id]
            .toString()
            .toLowerCase()
            .includes(filter.value.toString().toLowerCase())
        }
        getTdProps={(state, rowInfo, column, instance) => {
          return {
            onClick: (e, handleOriginal) => {
              if (column.Header && column.Header !== 'Email') {
                onRowClick(rowInfo)
              }
              // if (column.Header === undefined) {
              //   //only the checkbox has an undefined header
              //   console.log(rowInfo.original)
              //   handleSingleCheckboxChange(rowInfo.original)
              // }
              if (handleOriginal) {
                handleOriginal()
              }
            },
          }
        }}
        getTrProps={(state, rowInfo, column) => {
          let background = '#ffffff'
          if (!(rowInfo && rowInfo.original.nextSession)) {
            return {
              style: {
                background,
              },
            }
          }
          const { nextSession } = rowInfo.original
          if (nextSession === 'TO BE SCHEDULED') {
            background = toBeScheduledColor
          }
          if (nextSession === 'TO BE RENEWED') {
            background = toBeRenewedColor
          }
          if (nextSession === 'RENEW INVOICE SENT') {
            background = renewInvoiceSentColor
          }
          if (nextSession < convertToYMDHM(Date())) {
            background = toTakeAttendanceColor
          }
          return {
            style: {
              background,
            },
          }
        }}
        getTheadProps={(state, rowInfo, column, instance) => {
          return {
            style: {
              fontWeight: 'bold',
              borderBottom: '1px solid #E5E5E5',
            },
          }
        }}
      />
    </Box>
  )
}

export default TutorStudentTable
