import React, { Component } from 'react'
import { Mutation, withApollo } from 'react-apollo'
import Composer from 'react-composer'
import { Redirect } from 'react-router-dom'

import AddAssessmentForm from './AddAssessmentForm'
import {
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Flex,
  OpportunityForm,
  SnackbarNotification,
  StudentForm,
  Paper,
  Text,
} from '../../components'
import Cookies from 'js-cookie'
import { idName } from '../../config'
import { CREATE_QUALIFY, CREATE_ATTENDEE, CREATE_ENROLLMENT } from './queries'
import { UPDATE_OPPORTUNITY, GET_CENTER_LOCATION } from '../../queries'

class AddAssessmentPanels extends Component {
  constructor(props) {
    super(props)
    const { student, opportunity } = this.props
    const employeeId = Cookies.get(idName)
    this.state = {
      redirect: false,
      redirectTo: '',
      assessment: {
        method: 'ONLINE',
        //default centerlocation to online
        centerLocation: 'd2fa88c1-d038-4013-aaf3-f461db3daf4b',
        responsibleEmployeeId: employeeId,
        classroomId: '',
        status: 'SCHEDULED',
        results: '',
      },
      opportunity: opportunity,
      snackbar: {
        open: false,
        message: '',
        messageType: '',
      },
      student: student || (opportunity && opportunity.student),
      employeeId,
    }
  }

  returnToAssessmentTable = () => {
    this.setState({
      redirect: true,
      redirectTo: '/assessment',
    })
  }

  handleChange = async event => {
    const { client } = this.props
    const { name, value } = event.target
    const assessmentState = {
      assessment: { ...this.state.assessment, [name]: value },
    }
    if (name === 'centerLocation') {
      const {
        data: {
          centerLocation: { managers },
        },
      } = await client.query({
        query: GET_CENTER_LOCATION,
        variables: {
          id: value,
        },
      })
      if (managers.length > 0) {
        assessmentState.assessment.responsibleEmployeeId = managers[0]
      }
    }
    this.setState(assessmentState)
  }

  render() {
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirectTo} />
    }

    const { classes } = this.props
    const {
      employeeId,
      opportunity,
      snackbar,
      student,
      assessment,
    } = this.state

    const handleSubmission = async (
      OpporMutation,
      EnrollmentMutation,
      CreateAttendeeFunction,
      CreateQualifyFunction
    ) => {
      const assessment = this.state.assessment
      if (opportunity) {
        let enrollmentData = null
        let enrollmentId = null
        let lessonId = null
        let attendeeResponse = null
        if (
          !assessment.classroom ||
          !assessment.centerLocation ||
          !assessment.method
        ) {
          window.alert('Classroom is not selected!')
        } else {
          await EnrollmentMutation({
            variables: {
              input: {
                studentId: student.id,
                classroomId: assessment.classroom,
                status: 'QUALIFY',
              },
            },
          }).then(enrollResponse => {
            enrollmentData = enrollResponse.data.createEnrollment
            enrollmentId = enrollmentData.id
            let lesson = enrollmentData.classroom.lessons.find(
              x => x.lessonPlan.order === 0
            )
            if (lesson) lessonId = lesson.id
          })
          //Only create attendee if introduction lesson exists
          if (lessonId) {
            await CreateAttendeeFunction({
              variables: {
                input: {
                  lessonId: lessonId,
                  studentId: student
                    ? student.id
                    : opportunity && opportunity.student.id,
                },
              },
            }).then(attendResponse => {
              attendeeResponse = attendResponse.data.createAttendee
            })
          }
          //This may create a qualify with a null attendence if introduction lesson does not exist
          await CreateQualifyFunction({
            variables: {
              input: {
                createdById: employeeId,
                employeeId: assessment.responsibleEmployeeId,
                opportunityId: opportunity && opportunity.id,
                studentId: student
                  ? student.id
                  : opportunity && opportunity.student.id,
                centerLocationId: assessment.centerLocation,
                status: 'SCHEDULED',
                method: assessment.method,
                enrollmentId: enrollmentId,
                attendeeId: attendeeResponse && attendeeResponse.id,
              },
            },
          })
        }
      }
      this.returnToAssessmentTable()
    }

    return (
      <Flex column grow={1} className={classes.root}>
        <br />
        <Text className={classes.display1} variant="h4">
          Create New Assessment for {student.firstName} {student.lastName}
        </Text>
        <br />
        <Paper>
          <Accordion defaultExpanded>
            <AccordionSummary title="Add Assessment" />
            <AccordionDetails>
              <AddAssessmentForm
                assessment={assessment}
                opportunity={opportunity}
                handleChange={this.handleChange}
              />
            </AccordionDetails>
          </Accordion>
          {opportunity && (
            <Accordion defaultExpanded>
              <AccordionSummary title="Opportunity Detail (Read Only)" />
              <AccordionDetails>
                <OpportunityForm
                  readOnly
                  showStatus
                  showCreatedBy
                  opportunity={opportunity}
                  handleChange={() => {}}
                />
              </AccordionDetails>
            </Accordion>
          )}
          {student && (
            <Accordion defaultExpanded={!opportunity}>
              <AccordionSummary title="Student Detail (Read Only)" />
              <AccordionDetails>
                <StudentForm
                  readOnly
                  student={student}
                  handleChange={() => {}}
                />
              </AccordionDetails>
            </Accordion>
          )}

          <Flex direction={'row-reverse'} p="10px">
            <Composer
              components={[
                <Mutation mutation={UPDATE_OPPORTUNITY} />,
                <Mutation mutation={CREATE_ENROLLMENT} />,
                <Mutation mutation={CREATE_ATTENDEE} />,
                <Mutation mutation={CREATE_QUALIFY} />,
              ]}
            >
              {([
                OpporFunction,
                CreateEnrollmentFunction,
                CreateAttendeeFunction,
                CreateQualifyFunction,
              ]) => (
                <Button
                  color="primary"
                  onClick={() => {
                    if (window.confirm('Are you sure you want to submit?')) {
                      handleSubmission(
                        OpporFunction,
                        CreateEnrollmentFunction,
                        CreateAttendeeFunction,
                        CreateQualifyFunction
                      )
                    }
                  }}
                >
                  Submit
                </Button>
              )}
            </Composer>
            <Button
              color="secondary"
              className={classes.button}
              onClick={() => {
                this.returnToAssessmentTable()
              }}
              style={{ marginRight: '10px' }}
            >
              Cancel
            </Button>
          </Flex>
        </Paper>
        <SnackbarNotification
          open={snackbar.open}
          handleClose={() => {
            this.setState({
              snackbar: {
                ...snackbar,
                open: false,
              },
            })
          }}
          message={snackbar.message}
          messageType={snackbar.messageType}
        />
      </Flex>
    )
  }
}

export default withApollo(AddAssessmentPanels)
