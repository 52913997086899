import React, { useState, useEffect } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState } from 'draft-js'
import { makeStyles } from '@material-ui/core/styles'

import {
  Button,
  DatePicker,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Divider,
  Flex,
  InputLabel,
  MenuItem,
  Text,
  TextField,
  Paper,
  Select,
  ImageFileUploader,
} from '../../components'
import { htmlToContentState } from '../../utils/draftEditor'
import { debug, s3Config } from '../../config'

const useStyles = makeStyles(() => ({
  dialogPaper: {
    minHeight: '60vh',
    //quickfix
    minWidth: '1020px',
  },
  formPaper: {
    flex: 'display',
    flexDirection: 'column',
    width: '49%',
  },
  textInput: {
    width: '74%',
    marginBottom: '1em',
  },
  gradeInput: {
    width: '100%',
  },
  employeeInput: {
    width: '24%',
    marginBottom: '1em',
  },
  selectInput: {
    width: '100%',
  },
  datePickerInput: {
    width: '100%',
  },
  leftButton: {
    marginLeft: 'auto',
  },
  defaultButton: {
    marginLeft: '1em',
  },
}))

const handleDefaultEmployee = () => {
  if (!debug) {
    return '8d88b20f-c679-40f9-8873-911a845df6eb'
  } else {
    return '7460b01e-59bc-4e0b-aac5-44e423aa46d7'
  }
}

const PostsDialog = props => {
  const {
    client,
    dialog,
    employees,
    postTypes,
    handleCloseDialog,
    createPost,
    updatePost,
    archivePost,
  } = props
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  //default employee as InfoArdent
  const [employee, setEmployee] = useState(handleDefaultEmployee())
  const [publishDate, setPublishDate] = useState(new Date())
  const [expirationDate, setExpirationDate] = useState(new Date())
  const [postType, setPostType] = useState('NEWS')
  const [video, setVideo] = useState('')
  const [toGrade, setToGrade] = useState(1)
  const [fromGrade, setFromGrade] = useState(1)
  const [content, setContent] = useState(EditorState.createEmpty())
  const [image, setImage] = useState('')
  const [imageUrl, setImageUrl] = useState('')
  const [classroomCodes, setClassroomCodes] = useState('')

  const classes = useStyles()

  useEffect(() => {
    if (dialog.state === 'edit') {
      const { data } = dialog
      setTitle(data.title)
      setDescription(data.description)
      setEmployee(data.employee.id)
      setPublishDate(new Date(data.publishDate))
      setExpirationDate(new Date(data.expirationDate))
      setVideo(data.video)
      setPostType(data.postType)
      setToGrade(data.toGrade)
      setFromGrade(data.fromGrade)
      setContent(
        data.content
          ? EditorState.createWithContent(htmlToContentState(data.content))
          : EditorState.createEmpty()
      )
      setImage(data.image)
      setImageUrl(data.imageUrl)
      setClassroomCodes(
        data.classroomCodes ? data.classroomCodes.toString() : ''
      )
    } else {
      handleResetDialog()
    }
  }, [dialog])

  useEffect(() => {
    setImageUrl(`${s3Config.url}/${image}`)
  }, [image])

  const onEditorChange = value => {
    setContent(value)
  }

  const handleResetDialog = () => {
    setTitle('')
    setDescription('')
    setEmployee(handleDefaultEmployee())
    setPublishDate(new Date())
    setExpirationDate(new Date())
    setVideo('')
    setToGrade('')
    setFromGrade('')
    setContent(EditorState.createEmpty())
    setImage('')
    setImageUrl('')
    setClassroomCodes('')
  }

  const handleButtonFork = e => {
    const postInput = {
      postType,
      title,
      description,
      content,
      image,
      publishDate,
      expirationDate,
      video,
      fromGrade,
      toGrade,
      employeeId: employee,
    }
    const promotedClasses = new Set(
      classroomCodes
        .split(',')
        .map(code => code.trim())
        .filter(c => c.length > 0)
    )
    postInput['classroomCodes'] =
      promotedClasses.size > 0 ? Array.from(promotedClasses) : null
    if (dialog.state === 'edit') {
      updatePost(dialog.data.id, postInput, client)
    } else {
      createPost(postInput, client)
    }
    handleCloseDialog()
  }

  const handleArchiveDialog = () => {
    archivePost(dialog.data.id, client)
    handleCloseDialog()
  }

  return (
    <Dialog
      open={dialog.open}
      onClose={handleCloseDialog}
      fullWidth={true}
      maxWidth={'lg'}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle>
        {dialog.state === 'create' ? 'Create Post' : 'Edit Post'}
      </DialogTitle>
      <Divider style={{ width: '100%' }} />
      <DialogContent>
        <Flex style={{ height: '13%', marginBottom: '1%' }}>
          <Paper
            style={{
              flex: 'display',
              flexDirection: 'column',
              width: '100%',
            }}
          ></Paper>
        </Flex>
        <Flex column justify={'space-between'} style={{ height: '86%' }}>
          <Paper
            style={{
              flex: 'display',
              flexDirection: 'column',
              width: '100%',
              marginBottom: '1%',
            }}
          >
            <Flex column style={{ margin: '1em' }}>
              <Flex justify={'space-between'}>
                <TextField
                  id={'posts-title'}
                  label="Title"
                  value={title}
                  required
                  onChange={e => {
                    setTitle(e.target.value)
                  }}
                  className={classes.textInput}
                />
                {employees && (
                  <Select
                    id="posts-employee"
                    label="Employee"
                    value={employee}
                    onChange={e => setEmployee(e.target.value)}
                    renderValue={id => {
                      const emp = employees.find(emp => emp.id === id)
                      return (
                        <Text>
                          {emp.firstName} {emp.lastName}
                        </Text>
                      )
                    }}
                    className={classes.employeeInput}
                  >
                    {employees.map(emp => (
                      <MenuItem key={emp.id} id={emp.id} value={emp.id}>
                        {emp.firstName} {emp.lastName}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </Flex>
              <Flex>
                <ImageFileUploader
                  alt="Post Image"
                  style={{
                    minWidth: '32em',
                    minHeight: '18em',
                    maxWidth: '32em',
                    maxHeight: '18em',
                    border: '1px solid #f1f1f1',
                  }}
                  client={client}
                  setImage={setImage}
                  imageKey={image}
                  src={imageUrl}
                />
                <Flex
                  justify={'space-between'}
                  style={{
                    marginLeft: '1em',
                    width: '100%',
                  }}
                >
                  <Flex column style={{ width: '69%' }}>
                    <TextField
                      id={'post-description'}
                      label="Description"
                      value={description || ''}
                      onChange={e => setDescription(e.target.value)}
                      fullWidth
                      multiline
                      minRows={12}
                    />
                    <TextField
                      id={'post-promoted-classes'}
                      label="Promoted Classes"
                      value={classroomCodes || ''}
                      onChange={e => setClassroomCodes(e.target.value)}
                      fullWidth
                    />
                  </Flex>
                  <Flex
                    column
                    justify={'space-between'}
                    style={{
                      width: '28%',
                    }}
                  >
                    <DatePicker
                      id={`publish-date`}
                      label="Publish Date"
                      required
                      value={publishDate || ''}
                      onChange={e => setPublishDate(e.target.value)}
                      className={classes.datePickerInput}
                    />
                    <DatePicker
                      id={`expiration-date`}
                      label="Expiration Date"
                      required
                      value={expirationDate}
                      onChange={e => setExpirationDate(e.target.value)}
                      className={classes.datePickerInput}
                    />
                    {postTypes && (
                      <React.Fragment>
                        <InputLabel id="posttype">Post Type</InputLabel>
                        <Select
                          id="posttype"
                          labelId="posttype"
                          value={postType}
                          onChange={e => setPostType(e.target.value)}
                          className={classes.selectInput}
                        >
                          {postTypes.map(postType => (
                            <MenuItem
                              key={postType}
                              id={postType}
                              value={postType || ''}
                            >
                              {postType}
                            </MenuItem>
                          ))}
                        </Select>
                      </React.Fragment>
                    )}
                    <TextField
                      id={'from-video'}
                      label="Video Code"
                      value={video || ''}
                      onChange={e => {
                        setVideo(e.target.value)
                      }}
                      className={classes.textInput}
                    />
                    <TextField
                      id={'from-grade'}
                      label="From Grade"
                      required
                      value={fromGrade}
                      onChange={e => {
                        setFromGrade(e.target.value)
                      }}
                      className={classes.gradeInput}
                    />
                    <TextField
                      id={'to-grade'}
                      label="To Grade"
                      required
                      value={toGrade}
                      onChange={e => {
                        setToGrade(e.target.value)
                      }}
                      className={classes.gradeInput}
                    />
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Paper>
          <Paper
            style={{
              flex: 'display',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <Flex className={'content-wrapper'}>
              <Editor
                name="content"
                editorState={content}
                wrapperClassName={'content-wrapper'}
                editorClassName={'content-editor'}
                onEditorStateChange={onEditorChange}
                toolbarClassName="toolbar-class"
                editorStyle={{
                  overflow: 'wrap',
                  minHeight: '45vh',
                  maxHeight: '45vh',
                  paddingRight: '1em',
                  paddingLeft: '1em',
                }}
                wrapperStyle={{
                  width: '100%',
                  height: '100%',
                }}
              />
            </Flex>
          </Paper>
        </Flex>
      </DialogContent>
      <DialogActions>
        {dialog.state === 'edit' && (
          <Button
            style={{ marginRight: '69%' }}
            onClick={handleArchiveDialog}
            color="secondary"
          >
            Archive
          </Button>
        )}
        <Flex>
          <Button
            style={{ marginRight: '1em' }}
            onClick={handleCloseDialog}
            color="secondary"
          >
            Cancel
          </Button>
          <Button style={{ marginRight: '1em' }} onClick={handleResetDialog}>
            Reset
          </Button>
          <Button style={{ marginRight: '1em' }} onClick={handleButtonFork}>
            Submit
          </Button>
        </Flex>
      </DialogActions>
    </Dialog>
  )
}

export default PostsDialog
