import React, { Component } from 'react'
import { withApollo } from 'react-apollo'
import ReactTable from 'react-table'
import 'react-table/react-table.css'

import { COUNT_FAMILY_MEMBERS, GET_FAMILY_MEMBERS } from './queries'
import {
  convertPhoneNumber,
  arrayToObjectFamilyMemberFilter,
  arrayToObjectFamilyMemberSort,
} from '../../utils'
import {
  convertToMDY,
  convertToYMDHM,
  dateFilterMethod,
} from '../../utils/datetime'
import {
  TABLE_ROW_BACKGROUND_COLOR,
  TABLE_ROW_HIGHLIGHT_COLOR,
} from '../../config'

class FamilyTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      pages: this.props.pages,
      pageSize: 25,
      loaded_pages: 0,
      familyMembers: [],
      sorted: [
        {
          id: 'createdOn',
          desc: true,
        },
      ],
      filtered: this.props.filtered,
      selectAll: false,
      checked: {},
    }
  }

  getStatusFilter = () => {
    const statusFilterValue = this.state.filtered.find(
      filter => filter.id === 'status'
    ).value
    return statusFilterValue === 'ALL'
      ? {}
      : { enrollmentStatus: statusFilterValue }
  }

  handleFetchData = async (state, instance) => {
    const client = this.props.client
    const { familyMembers } = this.state
    const { page, pageSize, filtered, sorted } = state
    const filteredFamilyMembers =
      filtered !== this.state.filtered || sorted !== this.state.sorted
        ? []
        : familyMembers
    this.setState({
      loading: true,
      filtered,
      sorted,
      familyMembers: filteredFamilyMembers,
      page:
        filtered !== this.state.filtered || sorted !== this.state.sorted
          ? 0
          : page,
      pageSize,
    })
    if (filtered !== this.state.filtered || pageSize !== this.state.pageSize) {
      await client
        .query({
          query: COUNT_FAMILY_MEMBERS,
          variables: { filter: arrayToObjectFamilyMemberFilter(filtered) }, // need to change filter method
        })
        .then(({ data }) => {
          this.setState({
            pages: Math.ceil(data.countFamilyMembers / pageSize),
          })
        })
    }
    if (filteredFamilyMembers.length <= (page + 1) * pageSize) {
      await client
        .query({
          query: GET_FAMILY_MEMBERS,
          variables: {
            filter: arrayToObjectFamilyMemberFilter(filtered),
            sort: arrayToObjectFamilyMemberSort(sorted),
            page: {
              skip: filteredFamilyMembers.length,
              limit: pageSize,
            },
          },
        })
        .then(({ data }) => {
          let newFamilyMemberss = data.familyMembers.map(familyMember => {
            const status =
              familyMember && familyMember.familyAccount
                ? familyMember.familyAccount.status
                : 'UNKNOWN'
            const location =
              familyMember &&
              familyMember.familyAccount &&
              familyMember.familyAccount.location
            const isPrimaryFamilyMember =
              familyMember.id ===
              familyMember.familyAccount.primaryFamilyMember.id

            return {
              ...familyMember,
              status: status,
              city: location && location.city,
              isPrimaryFamilyMember: isPrimaryFamilyMember,
            }
          })
          if (filtered.find(f => f.id === 'lastLogin')) {
            const loginFilter = filtered.find(f => f.id === 'lastLogin')
            newFamilyMemberss = newFamilyMemberss.filter(fm =>
              fm.lastLogin ? fm.lastLogin.startsWith(loginFilter.value) : false
            )
          }
          if (filtered.find(f => f.id === 'createdOn')) {
            const loginFilter = filtered.find(f => f.id === 'createdOn')
            newFamilyMemberss = newFamilyMemberss.filter(fm =>
              fm.createdOn
                ? convertToMDY(fm.createdOn).startsWith(loginFilter.value)
                : false
            )
          }
          this.setState({
            familyMembers: [...filteredFamilyMembers, ...newFamilyMemberss],
          })
        })
    }
    this.setState({ loading: false })
  }

  handleSingleCheckboxChange = id => {
    const { checked, selectAll } = this.state
    checked[id] = !checked[id]
    this.setState({
      selectAll: !checked[id] && selectAll ? false : selectAll,
      checked,
    })
    this.props.setCheckedId(checked)
  }

  render() {
    const { checked } = this.state

    const columns = [
      {
        Cell: row => (
          <input
            type="checkbox"
            checked={checked[row.original.id]}
            onChange={() => this.handleSingleCheckboxChange(row.original.id)}
          />
        ),
        sortable: false,
        filterable: false,
        resizable: false,
        width: 28,
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: 135,
        Cell: props => {
          if (props.value === 'ACTIVE') {
            return <span style={{ color: '#27AE60' }}>{'ACTIVE'}</span>
          }
          if (props.value === 'INACTIVE') {
            return <span style={{ color: '#EB5757' }}>{'INACTIVE'}</span>
          }
          return <span>{props.value}</span>
        },
        filterMethod: (filter, row) => {
          if (filter.value !== 'ALL') {
            return row[filter.id] === filter.value
          }
          return true
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: '100%' }}
            value={filter ? filter.value : 'ALL'}
          >
            <option value="ALL">ALL</option>
            {this.props.statuses.map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        ),
      },
      {
        Header: 'Last Login',
        accessor: 'lastLogin',
        Cell: props => (
          <span>{props.value && convertToYMDHM(props.value)}</span>
        ),
        filterMethod: dateFilterMethod,
      },
      {
        Header: 'First Name',
        accessor: 'firstName',
      },
      {
        Header: 'Last Name',
        accessor: 'lastName',
        filterMethod: (filter, row) => {
          return (
            row[filter.id] &&
            row[filter.id].toLowerCase().startsWith(filter.value.toLowerCase())
          )
        },
      },
      {
        Header: 'Relationship',
        accessor: 'relation',
      },
      {
        Header: 'P',
        accessor: 'isPrimaryFamilyMember',
        Cell: props => (
          <span>
            <input type="checkbox" checked={props.value} readOnly={true} />
          </span>
        ),
        sortable: false,
        filterable: false,
        resizable: false,
        width: 33,
      },
      {
        Header: 'Language',
        accessor: 'preferredLanguage',
      },
      {
        Header: 'Phone Number',
        accessor: 'phoneNumber',
        Cell: props => (
          <span className="number">{convertPhoneNumber(props.value)}</span>
        ),
        minWidth: 110,
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'City',
        accessor: 'city',
      },
      {
        Header: 'Created On',
        accessor: 'createdOn',
        Cell: props => <span>{props.value && convertToMDY(props.value)}</span>,
        filterMethod: dateFilterMethod,
      },
    ]

    const { filterable, onRowClick } = this.props
    const {
      filtered,
      page,
      pages,
      pageSize,
      sorted,
      familyMembers,
    } = this.state

    return (
      <div align="left">
        <ReactTable
          filterable={filterable}
          data={familyMembers.slice(page * pageSize, (page + 1) * pageSize)}
          page={page}
          pageSize={pageSize}
          pages={pages}
          loading={this.state.loading}
          manual
          onFetchData={(state, instance) => {
            this.handleFetchData(state, instance)
          }}
          columns={columns}
          className="-highlight"
          defaultSorted={sorted}
          defaultFiltered={filtered}
          style={{ fontSize: '10pt' }}
          defaultFilterMethod={(filter, row) =>
            row[filter.id] &&
            row[filter.id]
              .toString()
              .toLowerCase()
              .includes(filter.value.toString().toLowerCase())
          }
          getTrProps={(state, rowInfo, column) => {
            // const backgroundWhite = '#ffffff' // duplicate TABLE_ROW_BACKGROUND_COLOR
            const backgroundLightGreen = '#effff4'
            let afterDate = new Date()
            afterDate.setDate(afterDate.getDate() - 7)

            return {
              style: {
                // Combine duplicate props
                background: rowInfo
                  ? !rowInfo.row.email && !rowInfo.row.phoneNumber
                    ? TABLE_ROW_HIGHLIGHT_COLOR
                    : new Date(rowInfo.original.createdOn) >= afterDate
                    ? backgroundLightGreen
                    : TABLE_ROW_BACKGROUND_COLOR
                  : TABLE_ROW_BACKGROUND_COLOR,

                // rowInfo && new Date(rowInfo.original.createdOn) >= afterDate
                //   ? backgroundLightGreen
                //   : backgroundWhite,
                // rowInfo && !rowInfo.row.email && !rowInfo.row.phoneNumber
                // ? TABLE_ROW_HIGHLIGHT_COLOR
                // : TABLE_ROW_BACKGROUND_COLOR,
              },
            }
          }}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              onClick: (e, handleOriginal) => {
                if (column.Header !== undefined && column.Header !== 'Email') {
                  onRowClick(rowInfo)
                }
                if (handleOriginal) {
                  handleOriginal()
                }
              },
            }
          }}
          getTheadProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                fontWeight: 'bold',
                borderBottom: '1px solid #E5E5E5',
              },
            }
          }}
        />
      </div>
    )
  }
}

export default withApollo(FamilyTable)
