import React from 'react'

import { CircularProgress, ErrorIcon, InfoIcon } from './'
import { CheckCircleIcon } from './Icons'

import { Slide, Snackbar, SnackbarContent } from '@mui/material'

const SnackbarNotification = props => {
  function colorSwitch() {
    switch (props.messageType) {
      case 'error':
        return 'firebrick'
      case 'success':
        return 'mediumseagreen'
      default:
        return 'royalblue'
    }
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={props.open}
      autoHideDuration={props.messageType === 'loading' ? 10000 : 2600}
      onClose={props.handleClose}
      TransitionComponent={Slide}
    >
      <SnackbarContent
        style={{ backgroundColor: colorSwitch() }}
        message={
          <span>
            <span
              style={{
                fontSize: '10px',
                opacity: '0.8',
                marginRight: '8px',
                marginLeft: '5px',
              }}
            >
              {(() => {
                switch (props.messageType) {
                  case 'error':
                    return <ErrorIcon />
                  case 'success':
                    return <CheckCircleIcon />
                  case 'loading':
                    return <CircularProgress />
                  default:
                    return <InfoIcon />
                }
              })()}
            </span>
            {props.message}
          </span>
        }
      />
    </Snackbar>
  )
}
export default SnackbarNotification
