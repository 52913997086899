import React, { useEffect, useRef, useState } from 'react'
import { Query, withApollo, useQuery } from 'react-apollo'

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material'

import { AddIcon, ClearIcon, IconButton } from '../../components/Icons'

import {
  DatePicker,
  DateTimePicker,
  ErrorMessage,
  Flex,
  LoadingMessage,
} from '../../components'

import {
  CREATE_CLASSROOM,
  GET_COURSES,
  GET_INSTRUCTORS,
  GET_CENTER_LOCATIONS,
  UPDATE_CLASSROOM,
  CREATE_INSTRUCTOR,
  UPDATE_INSTRUCTOR,
  ARCHIVE_INSTRUCTOR,
} from './queries'

const ClassroomDetailDialog = props => {
  const { open, closeEditDialog, classroom, classroomId, isEdit } = props

  const [status, setStatus] = useState(classroom.status || 'DRAFT')
  const [centerLocationId, setCenterLocationId] = useState(
    classroom.centerLocation && classroom.centerLocation.id
  )
  const [course, setCourse] = useState(null)
  const [courseCode, setCourseCode] = useState(
    classroom.course && classroom.course.code
  )
  const [title, setTitle] = useState(classroom.title)
  const [instanceNumber, setInstanceNumber] = useState(classroom.instanceNumber)
  const [code, setCode] = useState(classroom.code)
  const [year, setYear] = useState(classroom.year)
  const [quarter, setQuarter] = useState(classroom.quarter)
  const [session, setSession] = useState(classroom.session)
  const [enrollmentMax, setEnrollmentMax] = useState(
    classroom.enrollmentMax || 15
  )
  const [instructors, setInstructors] = useState(classroom.instructors || [])
  const [startDate, setStartDate] = useState(classroom.startDate || null)
  const [endDate, setEndDate] = useState(classroom.endDate || null)
  const [finalDate, setFinalDate] = useState(classroom.finalAccessDate || null)
  const [videoId, setVideoId] = useState(classroom.videoId || null)
  const [meetsOnMonday, setMeetsOnMonday] = useState(
    classroom.meetsOnMonday || false
  )
  const [meetsOnTuesday, setMeetsOnTuesday] = useState(
    classroom.meetsOnTuesday || false
  )
  const [meetsOnWednesday, setMeetsOnWednesday] = useState(
    classroom.meetsOnWednesday || false
  )
  const [meetsOnThursday, setMeetsOnThursday] = useState(
    classroom.meetsOnThursday || false
  )
  const [meetsOnFriday, setMeetsOnFriday] = useState(
    classroom.meetsOnFriday || false
  )
  const [meetsOnSaturday, setMeetsOnSaturday] = useState(
    classroom.meetsOnSaturday || false
  )
  const [meetsOnSunday, setMeetsOnSunday] = useState(
    classroom.meetsOnSunday || false
  )
  const [meetIsCustom, setMeetIsCustom] = useState(
    classroom.meetIsCustom || false
  )

  const [onSolve, setOnSolve] = useState(classroom.onSolve || false)
  const [allowTrial, setAllowTrial] = useState(classroom.allowTrial || false)

  const [onCatalog, setOnCatalog] = useState(classroom.onCatalog || false)
  const [discountable, setDiscountable] = useState(
    classroom.discountable || false
  )

  const [teachingStyle, setTeachingStyle] = useState(
    classroom.teachingStyle || 'BLENDED'
  )
  const [meetingsPerWeek, setMeetingsPerWeek] = useState(
    classroom.meetingsPerWeek
  )

  const [leadInstructor, setLeadInstructor] = useState(classroom.leadInstructor)

  const [tuition, setTuition] = useState(
    classroom.tuitionCents ? classroom.tuitionCents / 100 : 0
  )

  const [earlyBirdTuition, setEarlyBirdTuition] = useState(
    classroom.earlyBirdtuitionCents
      ? classroom.earlyBirdtuitionCents / 100
      : classroom.tuition
      ? classroom.tuition > 100000
        ? classroom.tuition / 100 - 200
        : classroom.tuition / 100 - 50
      : 0
  )

  const firstRender = useRef(true)

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      return
    }
  })

  const { loading, error, data: courseData } = useQuery(GET_COURSES)
  const [courses, setCourses] = useState()
  useEffect(() => {
    if (!loading && courseData) {
      const { courses } = courseData
      setCourses(courses)
    }
  }, [loading, courseData])

  const {
    loading: loadingInstructors,
    error: errorInstructors,
    data: instructorsData,
  } = useQuery(GET_INSTRUCTORS)
  const [allInstructors, setAllInstructors] = useState()
  useEffect(() => {
    if (!loadingInstructors && instructorsData) {
      const responsibleEmployees = instructorsData.employees.map(employee => {
        return {
          name: `${employee.firstName} ${employee.lastName}`,
          id: employee.id,
        }
      })
      setAllInstructors(responsibleEmployees)
    }
  }, [loadingInstructors, instructorsData])

  if (loading || loadingInstructors) return <LoadingMessage />
  if (error || errorInstructors) return <ErrorMessage error={error} />

  const formValidation = () => {
    if (!course || !centerLocationId || !quarter || !startDate || !endDate) {
      return true
    }
    return false
  }

  const handleSubmit = async () => {
    const { client } = props
    const mutations = []
    let classId = classroomId

    if (isEdit) {
      await client.mutate({
        mutation: UPDATE_CLASSROOM,
        variables: {
          id: classId,
          input: {
            status,
            quarter,
            session,
            enrollmentMax,
            centerLocationId,
            startDate: new Date(startDate).toISOString(),
            startTime: new Date(startDate).toISOString(),
            endDate: new Date(endDate).toISOString(),
            endTime: new Date(endDate).toISOString(),
            finalAccessDate: new Date(finalDate).toISOString(),
            meetsOnMonday,
            meetsOnTuesday,
            meetsOnWednesday,
            meetsOnThursday,
            meetsOnFriday,
            meetsOnSaturday,
            meetsOnSunday,
            meetIsCustom,
            teachingStyle,
            meetingsPerWeek,
            leadInstructor,
            onSolve,
            onCatalog,
            allowTrial,
            discountable,
            title,
            videoId,
            tuitionCents: tuition * 100,
            earlyBirdtuitionCents: earlyBirdTuition * 100,
          },
        },
      })
    } else {
      const { data } = await client.mutate({
        mutation: CREATE_CLASSROOM,
        variables: {
          input: {
            status,
            courseId: course.id,
            instanceNumber: parseInt(instanceNumber),
            quarter,
            session,
            enrollmentMax,
            centerLocationId,
            startDate,
            startTime: startDate,
            endDate,
            endTime: endDate,
            meetsOnMonday,
            meetsOnTuesday,
            meetsOnWednesday,
            meetsOnThursday,
            meetsOnFriday,
            meetsOnSaturday,
            meetsOnSunday,
            meetIsCustom,
            leadInstructor,
            onSolve,
            onCatalog,
            discountable,
            allowTrial,
            teachingStyle,
            meetingsPerWeek,
            videoId,
            tuitionCents: tuition * 100,
            earlyBirdtuitionCents: earlyBirdTuition * 100,
          },
        },
      })
      classId = data.createClassroom.id
    }

    for (const instructor of instructors) {
      if (!instructor.employee) continue
      if (!instructor.id) {
        // Added instructors
        if (instructor.archived) continue // Added then deleted; do nothing in db
        mutations.push(
          client.mutate({
            mutation: CREATE_INSTRUCTOR,
            variables: {
              input: {
                classroomId: classId,
                employeeId: instructor.employee.id,
              },
            },
          })
        )
      } else if (instructor.archived) {
        mutations.push(
          client.mutate({
            mutation: ARCHIVE_INSTRUCTOR,
            variables: {
              id: instructor.id,
            },
          })
        )
      } else {
        mutations.push(
          client.mutate({
            mutation: UPDATE_INSTRUCTOR,
            variables: {
              id: instructor.id,
              input: {
                employeeId: instructor.employee.id,
              },
            },
          })
        )
      }
    }

    await Promise.all(mutations)

    // props.refetch()
    closeEditDialog()
  }

  return (
    <Dialog open={open} fullWidth={true} maxWidth={'lg'}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <Flex p={'20px'} grow={2} justify="space-between">
          <Paper style={{ padding: '15px', width: '45%' }}>
            <QuarterYearSession
              status={status}
              quarter={quarter}
              year={year}
              session={session}
              setStatus={setStatus}
              setQuarter={setQuarter}
              setYear={setYear}
              setSession={setSession}
            />
            <Classroom
              courses={courses}
              isEdit={isEdit}
              course={course}
              courseCode={courseCode}
              instanceNumber={instanceNumber}
              code={code}
              quarter={quarter}
              title={isEdit ? title : getClassroomTitle(year, quarter, code)}
              leadInstructor={leadInstructor}
              setCourseCode={setCourseCode}
              setCourse={setCourse}
              setInstanceNumber={setInstanceNumber}
              setCode={setCode}
              setTitle={setTitle}
              setLeadInstructor={setLeadInstructor}
            />
            <Tuitions
              enrollmentMax={enrollmentMax}
              tuition={tuition}
              earlyBirdTuition={earlyBirdTuition}
              teachingStyle={teachingStyle}
              setEnrollmentMax={setEnrollmentMax}
              setTuition={setTuition}
              setEarlyBirdTuition={setEarlyBirdTuition}
              setTeachingStyle={setTeachingStyle}
            />
            <Flex column align="stretch">
              {instructors.map(
                (instructor, i) =>
                  !instructor.archived && (
                    <Flex key={`${instructor}_${i}`}>
                      <TextField
                        label="Instructor"
                        variant="standard"
                        name="instructor"
                        value={
                          (instructor.employee && instructor.employee.id) || ''
                        }
                        style={{ width: '40%' }}
                        select
                        onChange={event => {
                          const instrIndex = instructors.findIndex(
                            instr =>
                              instr.employee &&
                              instr.employee.id === event.target.value
                          )
                          const tempInstructors = [...instructors]
                          if (instrIndex === -1) {
                            tempInstructors[i] = {
                              ...tempInstructors[i],
                              employee: { id: event.target.value },
                            }
                          } else {
                            tempInstructors[instrIndex].archived = false
                          }
                          setInstructors(tempInstructors)
                        }}
                      >
                        {allInstructors &&
                          allInstructors.map((option, i) => (
                            <MenuItem key={`${option}_${i}`} value={option.id}>
                              {option.name}
                            </MenuItem>
                          ))}
                      </TextField>
                      <IconButton
                        color="secondary"
                        onClick={() => {
                          const tempInstructors = [...instructors]
                          tempInstructors[i].archived = true
                          setInstructors(tempInstructors)
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    </Flex>
                  )
              )}
              <br />
              <Flex alignSelf={'left'}>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => setInstructors([...instructors, {}])}
                >
                  <AddIcon />
                  Add Instructor
                </Button>
              </Flex>
            </Flex>
          </Paper>
          <Paper style={{ padding: '15px', width: '45%' }}>
            <Flex direction="row" justify="space-between">
              <Query query={GET_CENTER_LOCATIONS}>
                {({ loading, error, data }) => {
                  if (loading) return <LoadingMessage />
                  if (error) return <ErrorMessage error={error} />

                  const locations = data.centerLocations.map(location => {
                    return {
                      name: location.name,
                      value: location.id,
                    }
                  })

                  return (
                    <TextField
                      label="Center Location"
                      variant="standard"
                      style={{ width: '48%' }}
                      required
                      name="centerLocationID"
                      value={centerLocationId || ''}
                      onChange={event =>
                        setCenterLocationId(event.target.value)
                      }
                      select
                    >
                      {locations.map(option => (
                        <MenuItem key={option.name} value={option.value}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )
                }}
              </Query>
              <TextField
                label="Video Id"
                style={{ width: '48%' }}
                variant="standard"
                value={videoId ? videoId : ''}
                onChange={event => setVideoId(event.target.value)}
              />
            </Flex>
            <br />
            <br />
            <Flex direction="row" justify="space-between">
              <DateTimePicker
                style={{ width: '48%' }}
                label="Start Date & Time"
                required
                value={startDate}
                onChange={event => setStartDate(event.target.value)}
              />
              <DateTimePicker
                style={{ width: '48%' }}
                label="End Date & Time"
                required
                value={endDate}
                onChange={event => setEndDate(event.target.value)}
              />
            </Flex>
            <br />
            <Flex direction="row" justify="space-between">
              <DatePicker
                style={{ width: '48%' }}
                label="Final Access Date"
                required
                value={finalDate}
                onChange={event => setFinalDate(event.target.value)}
              />
              <TextField
                label="Tutor Meetings Per Week"
                variant="standard"
                value={meetingsPerWeek || 0}
                style={{ width: '48%' }}
                onChange={event =>
                  setMeetingsPerWeek(
                    event.target.value && parseInt(event.target.value)
                  )
                }
              />
            </Flex>
            <FormLabel style={{ fontSize: '.75em', opacity: '.7' }}>
              Days of the Week
            </FormLabel>
            <Flex direction="row" justify="space-evenly">
              <Flex direction="column">
                {[
                  {
                    day: 'Monday',
                    value: meetsOnMonday,
                    onChange: setMeetsOnMonday,
                  },
                  {
                    day: 'Tuesday',
                    value: meetsOnTuesday,
                    onChange: setMeetsOnTuesday,
                  },
                  {
                    day: 'Wednesday',
                    value: meetsOnWednesday,
                    onChange: setMeetsOnWednesday,
                  },
                ].map(current => (
                  <FormControlLabel
                    // disabled
                    key={current.day}
                    control={
                      <Checkbox
                        checked={current.value}
                        onChange={event =>
                          current.onChange(event.target.checked)
                        }
                      />
                    }
                    label={current.day}
                  />
                ))}
              </Flex>
              <Flex direction="column">
                {[
                  {
                    day: 'Thursday',
                    value: meetsOnThursday,
                    onChange: setMeetsOnThursday,
                  },
                  {
                    day: 'Friday',
                    value: meetsOnFriday,
                    onChange: setMeetsOnFriday,
                  },
                  {
                    day: 'Saturday',
                    value: meetsOnSaturday,
                    onChange: setMeetsOnSaturday,
                  },
                ].map(current => (
                  <FormControlLabel
                    key={current.day}
                    control={
                      <Checkbox
                        checked={current.value}
                        onChange={event =>
                          current.onChange(event.target.checked)
                        }
                      />
                    }
                    label={current.day}
                  />
                ))}
              </Flex>
              <Flex direction="column">
                {[
                  {
                    day: 'Sunday',
                    value: meetsOnSunday,
                    onChange: setMeetsOnSunday,
                  },
                  {
                    day: 'Custom',
                    value: meetIsCustom,
                    onChange: setMeetIsCustom,
                  },
                ].map(current => (
                  <FormControlLabel
                    key={current.day}
                    control={
                      <Checkbox
                        checked={current.value}
                        onChange={event =>
                          current.onChange(event.target.checked)
                        }
                      />
                    }
                    label={current.day}
                  />
                ))}
              </Flex>
            </Flex>
            <br />
            <Flex direction="row" justify="space-between">
              <p style={{ opacity: '.45' }}>On Solve: </p>
              <RadioGroup
                aria-label="on solve"
                name="onSolve"
                value={onSolve && onSolve.toString() === 'true' ? true : false}
                style={{ display: 'flex', flexDirection: 'row' }}
                onChange={event =>
                  setOnSolve(
                    event.target.value.toString() === 'true' ? true : false
                  )
                }
              >
                <FormControlLabel
                  value={true}
                  control={<Radio color="primary" />}
                  label="Yes"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio color="secondary" />}
                  label="No"
                />
              </RadioGroup>

              <p style={{ opacity: '.45' }}>Allow Trial: </p>
              <RadioGroup
                aria-label="allow Trial"
                name="AllowTrial"
                value={
                  allowTrial && allowTrial.toString() === 'true' ? true : false
                }
                onChange={event =>
                  setAllowTrial(
                    event.target.value.toString() === 'true' ? true : false
                  )
                }
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio color="primary" />}
                  label="Yes"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio color="secondary" />}
                  label="No"
                />
              </RadioGroup>
            </Flex>
            <Flex direction="row" justify="space-between">
              <p style={{ opacity: '.45' }}>On Catalog: </p>
              <RadioGroup
                aria-label="on catalog"
                name="onCatalog"
                value={
                  onCatalog && onCatalog.toString() === 'true' ? true : false
                }
                style={{ display: 'flex', flexDirection: 'row' }}
                onChange={event =>
                  setOnCatalog(
                    event.target.value.toString() === 'true' ? true : false
                  )
                }
              >
                <FormControlLabel
                  value={true}
                  control={<Radio color="primary" />}
                  label="Yes"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio color="secondary" />}
                  label="No"
                />
              </RadioGroup>

              <p style={{ opacity: '.45' }}>Allow Discount: </p>
              <RadioGroup
                aria-label="allow discount"
                name="Discountable"
                value={
                  discountable && discountable.toString() === 'true'
                    ? true
                    : false
                }
                onChange={event =>
                  setDiscountable(
                    event.target.value.toString() === 'true' ? true : false
                  )
                }
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio color="primary" />}
                  label="Yes"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio color="secondary" />}
                  label="No"
                />
              </RadioGroup>
            </Flex>
          </Paper>
        </Flex>
      </DialogContent>
      <DialogActions style={{ paddingBottom: '10px', paddingRight: '10px' }}>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disabled={isEdit ? firstRender.current : formValidation()}
        >
          {isEdit ? 'Save' : 'Create'}
        </Button>
        <Button onClick={closeEditDialog} color="secondary" variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const Tuitions = ({
  enrollmentMax,
  tuition,
  earlyBirdTuition,
  teachingStyle,
  setEnrollmentMax,
  setTuition,
  setEarlyBirdTuition,
  setTeachingStyle,
}) => {
  const teachingStyles = [
    'BLENDED',
    'CLASSROOM',
    'SELF_PACED',
    'TUTORED',
    'MENTORED',
    'COMPETITION',
  ]
  return (
    <Flex direction="row" justify="space-between">
      <TextField
        label="Maximum"
        variant="standard"
        value={enrollmentMax}
        sx={{ width: '25%' }}
        onChange={event =>
          setEnrollmentMax(event.target.value && parseInt(event.target.value))
        }
      />
      <TextField
        label="Tuition"
        id="standard-start-adornment"
        variant="standard"
        sx={{ ml: 2, width: '25%' }}
        value={tuition}
        onChange={event => {
          setTuition(event.target.value)
        }}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
      />
      <TextField
        label="Early Bird"
        id="standard-start-adornment"
        variant="standard"
        sx={{ ml: 2, width: '25%' }}
        value={earlyBirdTuition}
        onChange={event => {
          setEarlyBirdTuition(event.target.value)
        }}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
      />
      <TextField
        select
        label="Teaching Style"
        variant="standard"
        sx={{ ml: 2, width: '25%' }}
        value={teachingStyle}
        onChange={event => setTeachingStyle(event.target.value)}
      >
        {teachingStyles.map(style => (
          <MenuItem key={style} value={style}>
            {style}
          </MenuItem>
        ))}
      </TextField>
    </Flex>
  )
}

const Classroom = ({
  courses,
  isEdit,
  course,
  courseCode,
  instanceNumber,
  code,
  title,
  year,
  quarter,
  leadInstructor,
  setCourseCode,
  setCourse,
  setInstanceNumber,
  setCode,
  setTitle,
  setLeadInstructor,
}) => {
  return (
    <Box column>
      <Box sx={{ display: 'flex', flexDirection: 'row', py: 1, width: '100%' }}>
        {isEdit ? (
          <TextField
            disabled
            label="Course Code"
            variant="standard"
            style={{ width: '100%' }}
            value={courseCode}
            onChange={event => setCourseCode(event.target.value)}
          />
        ) : (
          <TextField
            select
            label="Course"
            variant="standard"
            required
            sx={{ width: '40%' }}
            value={course ? course : ''}
            onChange={event => setCourse(event.target.value)}
            InputLabelProps={{ shrink: true }}
          >
            {courses.map(course => (
              <MenuItem key={course.id} value={course}>
                {course.code}
              </MenuItem>
            ))}
          </TextField>
        )}
        <TextField
          required
          disabled={isEdit}
          label="Instance"
          variant="standard"
          type="number"
          sx={{ ml: 2, width: '30%' }}
          value={instanceNumber}
          onChange={event => setInstanceNumber(event.target.value)}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          disabled
          label="Class Code"
          variant="standard"
          sx={{ ml: 2, width: '30%' }}
          value={isEdit ? code : getClassroomCode(course, instanceNumber)}
          onChange={event => setCode(event.target.value)}
          InputLabelProps={{ shrink: true }}
        />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', py: 1, width: '100%' }}>
        <TextField
          label="Classroom Title"
          variant="standard"
          sx={{ width: '100%' }}
          value={
            isEdit
              ? title
              : getClassroomTitle(year, quarter, code, course, instanceNumber)
          }
          onChange={event => setTitle(event.target.value)}
          InputLabelProps={{ shrink: true }}
        />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', py: 1, width: '100%' }}>
        <TextField
          label="Lead Instructor"
          style={{ width: '100%' }}
          variant="standard"
          value={leadInstructor ? leadInstructor : ''}
          onChange={event => setLeadInstructor(event.target.value)}
          InputLabelProps={{ shrink: true }}
        />
      </Box>
    </Box>
  )
}

const QuarterYearSession = ({
  status,
  quarter,
  year,
  session,
  setStatus,
  setQuarter,
  setYear,
  setSession,
}) => {
  const statuses = ['DRAFT', 'PUBLISHED', 'OPENED', 'CLOSED', 'CANCELLED']
  const quarters = [
    'Summer',
    'Fall',
    'Winter',
    'PreSummer',
    'Spring',
    'OnDemand',
    'Tutoring',
    'Contest',
  ]
  const sessions = [
    'N/A',
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
  ]
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', py: 1, width: '100%' }}>
      <TextField
        select
        name="status"
        label="Status"
        variant="standard"
        sx={{ width: '30%' }}
        value={status || ''}
        onChange={event => setStatus(event.target.value)}
      >
        {statuses.map(status => (
          <MenuItem key={status} value={status}>
            {status}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        select
        required
        sx={{ ml: 2, width: '30%' }}
        name="quarter"
        label="Quarter"
        variant="standard"
        value={quarter || ''}
        onChange={event => setQuarter(event.target.value)}
      >
        {quarters.map(quarter => (
          <MenuItem key={quarter} value={quarter}>
            {quarter}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        disabled
        sx={{ ml: 2, width: '20%' }}
        name="year"
        label="Year"
        variant="standard"
        value={
          year ||
          new Date().getUTCFullYear() + (new Date().getUTCMonth() > 8 ? 1 : 0)
        }
        onChange={event => setYear(event.target.value)}
      />
      <TextField
        select
        required
        sx={{ ml: 2, width: '20%' }}
        name="session"
        label="Session"
        variant="standard"
        value={session || ''}
        onChange={event => setSession(event.target.value)}
      >
        {sessions.map(session => (
          <MenuItem key={session} value={session}>
            {session}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  )
}

const getClassroomCode = (course, instanceNumber) => {
  if (!course || !instanceNumber) return ''

  if (instanceNumber >= 10) {
    return `${course.code}${instanceNumber}`
  } else {
    return `${course.code}0${instanceNumber}`
  }
}

const getClassroomTitle = (year, quarter, code, course, instanceNumber) => {
  year = new Date().getUTCFullYear() + (new Date().getUTCMonth() > 8 ? 1 : 0)

  let title = ''
  if (quarter) {
    title += quarter + ' ' + year + ': '
  }
  if (code) {
    title += code + ' - '
  }
  if (!course || !instanceNumber) return title

  title += course.code
  if (instanceNumber < 10) {
    title += '0'
  }
  title += instanceNumber
  title += ' - ' + course.title

  return title
}

export default withApollo(ClassroomDetailDialog)
