import React, { useState, useEffect, useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import { useLazyQuery, useMutation, useQuery } from 'react-apollo'
import Cookies from 'js-cookie'
import { idName } from '../../config'

import moment from 'moment-timezone'
import { convertToYMD } from '../../utils/datetime'
import { DragDropContext } from 'react-beautiful-dnd'

import TutorStudentDetailSidebar from './TutorStudentDetailSidebar'
import TutorStudentDialog from './TutorStudentDialog'
import TutorStudentSchedule from './TutorStudentSchedule'
import TutorSessionAttendanceDialog from './TutorSessionAttendanceDialog'
import AddTutorSessionDialog from './AddTutorSessionDialog'
import RenewTutorSessionDialog from './RenewTutorSessionDialog'

import {
  ErrorMessage,
  LoadingMessage,
  SnackbarNotification,
} from '../../components'

import { Box } from '@mui/material'

import {
  UPDATE_ENROLLMENT,
  RENEW_TUTOR_SESSIONS,
  CREATE_TUTOR_SESSIONS,
  UPDATE_TUTOR_SESSION,
  GET_ENROLLMENT,
  GET_TUTOR_SESSIONS_BY_MONTH,
  GET_TUTOR_SESSIONS,
  GET_TUTORS,
  ARCHIVE_TUTOR_SESSION,
} from './queries'

const DEFAULT_DIALOG_STATE = {
  open: false,
}

const DEFAULT_CREATE_DIALOG_STATE = {
  ...DEFAULT_DIALOG_STATE,
  data: {
    sessions: 1,
  },
}

const DEFAULT_RENEWAL_DIALOG_STATE = {
  ...DEFAULT_DIALOG_STATE,
  data: {
    sessions: 4,
    unitPrice: '75',
  },
}

const DEFAULT_SNACKBAR_STATE = {
  open: false,
  message: '',
  messageType: '',
}

const getThisMonth = startDate => {
  const result = []

  let today = new Date(startDate.getFullYear(), startDate.getMonth(), 0)
  let endDate = moment(today)
    .add(1, 'months')
    .toDate()

  if (today.getDay() !== 6)
    today = new Date(
      today.getTime() - (today.getDay() + 1) * 24 * 60 * 60 * 1000
    )

  if (endDate.getDay() === 6)
    endDate = new Date(endDate.getTime() + 6 * 24 * 60 * 60 * 1000)
  else
    endDate = new Date(
      endDate.getTime() + (5 - endDate.getDay()) * 24 * 60 * 60 * 1000
    )

  while (today <= endDate) {
    today.setDate(today.getDate() + 1)
    result.push(new Date(today))
  }
  return result
}

//combine the date and given time to a single date object
const getDateTime = (date, time) => {
  const dateTime = new Date(date)
  const timeObject = new Date(time)
  dateTime.setHours(timeObject.getHours())
  dateTime.setMinutes(timeObject.getMinutes())
  dateTime.setSeconds(timeObject.getSeconds())
  return dateTime
}

const TutorStudentDetailView = props => {
  const paramArray = props.match.params[0].split(' ')
  const [studentId] = useState(paramArray[0])
  const [classroomId] = useState(paramArray[1])
  const [enrollmentId] = useState(paramArray[2])

  const [student, setStudent] = useState(null)
  const [classroom, setClassroom] = useState(null)
  const [tutorSessions, setTutorSessions] = useState([])
  const [enrolledOn, setEnrolledOn] = useState('')
  const [completionStatus, setCompletionStatus] = useState('')

  //selectable lists
  const [unscheduledSessions, setUnscheduledSessions] = useState(null)

  //selectables
  const [tutorSession, setTutorSession] = useState(null)
  const [selectedMonth, setSelectedMonth] = useState(getThisMonth(new Date()))

  //draggable
  const [calendarItems, setCalendarItems] = useState(null)

  //rest
  const [dialog, setDialog] = useState(DEFAULT_DIALOG_STATE)
  const [attendanceDialog, setAttendanceDialog] = useState(DEFAULT_DIALOG_STATE)
  const [createTutorSessionDialog, setCreateTutorSessionDialog] = useState(
    DEFAULT_CREATE_DIALOG_STATE
  )
  const [createRenewalInvoiceDialog, setCreateRenewalInvoiceDialog] = useState(
    DEFAULT_RENEWAL_DIALOG_STATE
  )
  const [snackbar, setSnackbar] = useState(DEFAULT_SNACKBAR_STATE)

  const formatCalenderItem = useCallback(
    sessionsData => {
      const cMap = new Map()
      selectedMonth.forEach(date => cMap.set(date.toISOString(), []))
      sessionsData.forEach(s => {
        const date = new Date(
          new Date(s.startDateTime).toDateString()
        ).toISOString()
        cMap.get(date).push({ ...s })
      })
      return cMap
    },
    [selectedMonth]
  )

  const { loading: loadingEnrollment, data } = useQuery(GET_ENROLLMENT, {
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        studentId: studentId,
        classroomId: classroomId,
      },
    },
  })
  useEffect(() => {
    if (!loadingEnrollment && data && data.enrollments.length > 0) {
      setEnrolledOn(convertToYMD(data.enrollments[0].createdOn))
      setCompletionStatus(data.enrollments[0].completionStatus)
    }
  }, [loadingEnrollment, data])

  const [getTutorSessionsByMonth] = useLazyQuery(GET_TUTOR_SESSIONS_BY_MONTH, {
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        studentId: studentId,
        classroomId: classroomId,
        $or: [
          {
            $and: [
              {
                startDateTime: {
                  $lteDate: selectedMonth[
                    selectedMonth.length - 1
                  ].toISOString(),
                },
              },
              {
                startDateTime: { $gteDate: selectedMonth[0].toISOString() },
              },
            ],
          },
          {
            $and: [{ status: 'UNKNOWN' }],
          },
        ],
      },
    },
    onCompleted: results => {
      setCalendarItems(
        formatCalenderItem(
          results.tutorSessions.filter(ts => ts.status !== 'UNKNOWN')
        )
      )
      setUnscheduledSessions(
        results.tutorSessions.filter(ts => ts.status === 'UNKNOWN') || []
      )
    },
  })

  const handleFetchTutorSessions = useCallback(async () => {
    if (selectedMonth) {
      getTutorSessionsByMonth()
    }
  }, [getTutorSessionsByMonth, selectedMonth])

  const handleNextMonth = () => {
    if (selectedMonth) {
      const currentMonthDay = selectedMonth[10]
      const nextMonthDay = moment(currentMonthDay).add(1, 'months')
      setSelectedMonth(getThisMonth(nextMonthDay.toDate()))
      setUnscheduledSessions(null)
      setCalendarItems(null)
    }
  }

  const handlePreviousMonth = () => {
    if (selectedMonth) {
      const currentMonthDay = selectedMonth[10]
      const nextMonthDay = moment(currentMonthDay).subtract(1, 'months')
      setSelectedMonth(getThisMonth(nextMonthDay.toDate()))
      setUnscheduledSessions(null)
      setCalendarItems(null)
    }
  }

  const handleTutorSessionChange = (e, status = undefined) => {
    //hacky way to handle status with material-ui's menu item component
    if (status) {
      setTutorSession({
        ...tutorSession,
        status: status,
      })
      return
    }

    if (e.target.name === 'updateAll') {
      setTutorSession({
        ...tutorSession,
        updateAll: e.target.checked,
      })
      return
    }

    setTutorSession({
      ...tutorSession,
      [(e.currentTarget && e.currentTarget.name) || e.target.name]:
        (e.currentTarget && e.currentTarget.value) || e.target.value,
    })
  }

  const [updateTutorSession] = useMutation(UPDATE_TUTOR_SESSION)
  const [createTutorSessions] = useMutation(CREATE_TUTOR_SESSIONS)
  const [renewTutorSessions] = useMutation(RENEW_TUTOR_SESSIONS)
  const [archiveTutorSession] = useMutation(ARCHIVE_TUTOR_SESSION)
  const [updateEnrollment] = useMutation(UPDATE_ENROLLMENT)

  const handleDetailedUpdateTutorSession = async () => {
    //combine the date and time for the updated tutorSession
    tutorSession.startDateTime = getDateTime(
      tutorSession.startDateTime,
      tutorSession.startTime
    ).toISOString()

    //find the local session item from our map and update it
    const day = new Date(tutorSession.startDateTime)
    day.setHours(0, 0, 0, 0)
    calendarItems.set(
      day.toISOString(),
      calendarItems
        .get(day.toISOString())
        .filter(ts => ts.id !== tutorSession.id)
    )

    calendarItems.get(day.toISOString()).push(tutorSession)
    const res = await updateTutorSession({
      variables: {
        id: tutorSession.id,
        input: {
          startDateTime: tutorSession.startDateTime,
          status: tutorSession.status,
          duration: parseInt(tutorSession.duration),
          summary: tutorSession.summary,
          order: parseInt(tutorSession.order),
          updateAll: tutorSession.updateAll ? tutorSession.updateAll : false,
        },
      },
    })
    return !!res
  }

  const handleAssignTutorSession = async (id, day, unassign = false) => {
    const res = await updateTutorSession({
      variables: {
        id: id,
        input: {
          startDateTime: new Date(day).toISOString(), //change later
          status: unassign ? 'UNKNOWN' : 'SCHEDULED',
          updateAll: true,
        },
      },
    })
    return !!res
  }

  const handleCreateTutorSession = async () => {
    const { data } = createTutorSessionDialog
    const { sessions } = data

    const createSessions = async () => {
      return await createTutorSessions({
        variables: {
          studentId: student.id,
          classroomId: classroomId,
          numberOfTutorSessions: sessions,
        },
      })
    }

    if (classroomId && sessions) {
      handleCreateTutorSessionCloseDialog()
      handleSnackbarOperation(
        `Creating ${sessions} tutor sessions`,
        `Created ${sessions} tutor sessions`,
        'Creating tutor sessions failed',
        createSessions
      )
    }
  }

  const handleCreateRenewalInvoice = async () => {
    const { data } = createRenewalInvoiceDialog
    const { sessions, unitPrice } = data
    const employeeId = Cookies.get(idName)

    const createInvoice = async () => {
      return await renewTutorSessions({
        variables: {
          employeeId: employeeId,
          enrollmentId: enrollmentId,
          quantity: parseInt(sessions),
          unitPrice: parseInt(unitPrice),
        },
      })
    }

    if (classroomId && sessions) {
      handleCreateRenewalInvoiceCloseDialog()
      handleSnackbarOperation(
        `Creating invoice for ${sessions} tutor sessions`,
        `Created invoice for ${sessions} tutor sessions`,
        'Creating tutor sessions invoice failed',
        createInvoice
      )
    }
  }

  const handleArchiveTutorSession = async () => {
    const archiveSession = async () => {
      return await archiveTutorSession({
        variables: {
          id: tutorSession.id,
        },
      })
    }

    handleCloseDialog()
    handleSnackbarOperation(
      `Archiving tutor session`,
      `Archived tutor session`,
      'Archiving tutor session failed',
      archiveSession
    )
  }

  const handleOpenDialog = useCallback(() => {
    setDialog({
      open: true,
    })
  }, [])

  const handleCloseDialog = useCallback(() => {
    setDialog({
      open: false,
    })
  }, [])

  const handleSnackbarOperation = async (
    loadingMsg,
    SuccessMsg,
    ErrorMsg,
    operation
  ) => {
    setSnackbar({
      open: true,
      message: loadingMsg,
      messageType: 'loading',
    })
    const success = await operation()
    if (success) {
      setSnackbar({
        open: true,
        message: SuccessMsg,
        messageType: 'success',
      })
    } else {
      setSnackbar({
        open: true,
        message: ErrorMsg,
        messageType: 'error',
      })
    }
    await handleFetchTutorSessions()
  }

  const handleCloseSnackbar = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    })
  }

  const handleUpdateTutorSession = () => {
    handleCloseDialog()
    handleCloseAttendeeDialog()
    handleSnackbarOperation(
      'Updating tutor session',
      'Updated tutor session',
      'Updating tutor session failed',
      handleDetailedUpdateTutorSession
    )
  }

  const handleChangeTutor = (id, tutor) => {
    const changeTutor = async () => {
      const res = updateTutorSession({
        variables: {
          id: id,
          input: {
            employeeId: tutor.id,
            updateAll: true,
          },
        },
      })
      return !!res
    }
    handleSnackbarOperation(
      'Changing tutor',
      'Changing tutor',
      'Changing tutor failed',
      changeTutor
    )
  }

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    width: '180px',
    maxWidth: '180px',
    height: '36.5px',
    maxHeight: '36.5px',
    margin: '10px',
    alignItems: 'center',
    justifyConent: 'center',
    background: isDragging && 'lightgreen',
    ...draggableStyle,
  })

  const getSidebarListStyle = isDraggingOver => ({
    background: isDraggingOver && 'lightblue',
    flexDirection: 'column',
    display: 'flex',
    padding: 1,
    minHeight: '1000px',
    width: '210px',
    maxWidth: '210px',
    marginBottom: '15px',
    alignItems: 'center',
    justifyConent: 'center',
  })

  const onDragEnd = async result => {
    const { source, destination, draggableId } = result
    if (!destination) {
      return null
    }

    const droppableSrc = source.droppableId
    const droppableDest = destination.droppableId

    //don't need state changes with objects...
    if (droppableSrc !== droppableDest) {
      //unscheduled -> scheduled
      if (droppableSrc === 'unscheduled') {
        //swap containers
        calendarItems
          .get(droppableDest)
          .push(unscheduledSessions.find(a => a.id === draggableId))
        setUnscheduledSessions(
          unscheduledSessions.filter(a => a.id !== draggableId)
        )

        //update local tutorsession obj
        calendarItems.get(droppableDest)[
          calendarItems.get(droppableDest).length - 1
        ].startDateTime = getDateTime(
          droppableDest,
          calendarItems.get(droppableDest)[
            calendarItems.get(droppableDest).length - 1
          ].startDateTime
        )
        calendarItems.get(droppableDest)[
          calendarItems.get(droppableDest).length - 1
        ].status = 'SCHEDULED'

        //updating tutorsession obj
        await handleAssignTutorSession(
          draggableId,
          calendarItems.get(droppableDest)[
            calendarItems.get(droppableDest).length - 1
          ].startDateTime
        )
      } else if (droppableDest === 'unscheduled') {
        //scheduled -> unscheduled
        //swap containers
        unscheduledSessions.push(
          calendarItems.get(droppableSrc).find(a => a.id === draggableId)
        )
        calendarItems.set(
          droppableSrc,
          calendarItems.get(droppableSrc).filter(a => a.id !== draggableId)
        )

        //updating tutorsession obj
        await handleAssignTutorSession(
          draggableId,
          unscheduledSessions[unscheduledSessions.length - 1].startDateTime,
          true
        )
      } else {
        //scheduled -> scheduled
        //swap containers
        calendarItems
          .get(droppableDest)
          .push(calendarItems.get(droppableSrc).find(a => a.id === draggableId))
        calendarItems.set(
          droppableSrc,
          calendarItems.get(droppableSrc).filter(a => a.id !== draggableId)
        )

        //update local tutorsession obj
        calendarItems.get(droppableDest)[
          calendarItems.get(droppableDest).length - 1
        ].startDateTime = getDateTime(
          droppableDest,
          calendarItems.get(droppableDest)[
            calendarItems.get(droppableDest).length - 1
          ].startDateTime
        )
        calendarItems.get(droppableDest)[
          calendarItems.get(droppableDest).length - 1
        ].status = 'SCHEDULED'

        //updating tutorsession obj
        await handleAssignTutorSession(
          draggableId,
          calendarItems.get(droppableDest)[
            calendarItems.get(droppableDest).length - 1
          ].startDateTime
        )
      }
      await handleFetchTutorSessions()
    }
  }

  const handleSelectSession = useCallback(
    e => {
      const parsedSession = JSON.parse(e.currentTarget.value)
      parsedSession.startTime = parsedSession.startDateTime
      setTutorSession(parsedSession)
      handleOpenDialog()
    },
    [handleOpenDialog]
  )

  const handleOpenAttendeeDialog = useCallback(e => {
    const parsedSession = JSON.parse(e.currentTarget.value)
    parsedSession.startTime = parsedSession.startDateTime
    setTutorSession(parsedSession)
    setAttendanceDialog({
      open: true,
    })
  }, [])

  const handleCloseAttendeeDialog = useCallback(() => {
    setAttendanceDialog({
      open: false,
    })
  }, [])

  const handleOpenCreateDialog = () => {
    setCreateTutorSessionDialog({
      ...createTutorSessionDialog,
      open: true,
    })
  }

  const handleCreateTutorSessionCloseDialog = () => {
    setCreateTutorSessionDialog({
      ...createTutorSessionDialog,
      open: false,
    })
  }

  const handleOpenRenewalDialog = () => {
    setCreateRenewalInvoiceDialog({
      ...createRenewalInvoiceDialog,
      open: true,
    })
  }

  const handleCreateRenewalInvoiceCloseDialog = () => {
    setCreateRenewalInvoiceDialog({
      ...createRenewalInvoiceDialog,
      open: false,
    })
  }

  const handleUpdateSessions = ({ name, value }) => {
    setCreateTutorSessionDialog({
      ...createTutorSessionDialog,
      data: {
        ...createTutorSessionDialog.data,
        [name]: value,
      },
    })
  }

  const handleUpdateInvoice = ({ name, value }) => {
    setCreateRenewalInvoiceDialog({
      ...createRenewalInvoiceDialog,
      data: {
        ...createRenewalInvoiceDialog.data,
        [name]: value,
      },
    })
  }

  const getTutorSessions = useQuery(GET_TUTOR_SESSIONS, {
    fetchPolicy: 'network-only',
    variables: {
      studentId: studentId,
      classroomId: classroomId,
      filter: {
        classroomId: classroomId,
        studentId: studentId,
      },
    },
    onCompleted: results => {
      setStudent(results.student)
      setClassroom(results.classroom)
      setTutorSessions(results.tutorSessions)
    },
  })

  useEffect(() => {
    handleFetchTutorSessions()
  }, [handleFetchTutorSessions, selectedMonth])

  const {
    loading: loadingTutors,
    error: errorTutors,
    data: dataTutors,
  } = useQuery(GET_TUTORS)
  const { loading, error } = getTutorSessions
  if (loading || loadingTutors) return <LoadingMessage />
  if (error || errorTutors) return <ErrorMessage error={errorTutors} />
  const tutors = dataTutors.employees

  const handleCompletionStatus = async completionStatus => {
    const isCompleted = completionStatus === 'COMPLETED'
    await updateEnrollment({
      variables: {
        id: enrollmentId,
        input: {
          isCompleted: isCompleted,
          completionStatus: completionStatus,
        },
      },
    })
    setCompletionStatus(completionStatus)
  }

  const handleRenewal = () => {
    alert('handle renewal')
  }

  return (
    <Box sx={{ display: 'flex', height: '100%' }}>
      <DragDropContext onDragEnd={onDragEnd}>
        {unscheduledSessions && (
          <>
            <TutorStudentDetailSidebar
              unscheduledSessions={unscheduledSessions}
              classroom={classroom}
              enrolledOn={enrolledOn}
              tutorSessions={tutorSessions}
              completionStatus={completionStatus}
              handleOpenCreateDialog={handleOpenCreateDialog}
              handleOpenRenewalDialog={handleOpenRenewalDialog}
              handleCompletionStatus={handleCompletionStatus}
              handleRenewal={handleRenewal}
              getSidebarListStyle={getSidebarListStyle}
              getItemStyle={getItemStyle}
            />
          </>
        )}
        <Box
          sx={{
            display: 'flex',
            align: 'left',
            flexDirection: 'column',
            marginLeft: '30px',
            grow: 1,
          }}
        >
          {calendarItems && (
            <TutorStudentSchedule
              student={student}
              tutors={tutors}
              calendarItems={calendarItems}
              selectedMonth={selectedMonth}
              handleChangeTutor={handleChangeTutor}
              handlePreviousMonth={handlePreviousMonth}
              handleNextMonth={handleNextMonth}
              handleSelectSession={handleSelectSession}
              handleOpenAttendeeDialog={handleOpenAttendeeDialog}
            />
          )}
        </Box>
        <SnackbarNotification
          open={snackbar.open}
          handleClose={handleCloseSnackbar}
          message={snackbar.message}
          messageType={snackbar.messageType}
        />
      </DragDropContext>
      {tutorSession && student && (
        <>
          <TutorStudentDialog
            dialog={dialog}
            tutorSession={tutorSession}
            student={student}
            handleTutorSessionChange={handleTutorSessionChange}
            handleCloseDialog={handleCloseDialog}
            handleSubmit={handleUpdateTutorSession}
            handleArchive={handleArchiveTutorSession}
          />
          <TutorSessionAttendanceDialog
            open={attendanceDialog.open}
            tutorSession={tutorSession}
            student={student}
            handleClose={handleCloseAttendeeDialog}
            handleTutorSessionChange={handleTutorSessionChange}
            handleSubmit={handleUpdateTutorSession}
          />
        </>
      )}
      {student && classroom && (
        <AddTutorSessionDialog
          dialog={createTutorSessionDialog}
          open={createTutorSessionDialog.open}
          sessions={createTutorSessionDialog.data.sessions}
          student={student}
          classroom={classroom}
          handleUpdateSessions={handleUpdateSessions}
          handleClose={handleCreateTutorSessionCloseDialog}
          handleSubmit={handleCreateTutorSession}
        />
      )}
      {student && classroom && (
        <RenewTutorSessionDialog
          dialog={createRenewalInvoiceDialog}
          open={createRenewalInvoiceDialog.open}
          sessions={createRenewalInvoiceDialog.data.sessions}
          unitPrice={createRenewalInvoiceDialog.data.unitPrice}
          student={student}
          classroom={classroom}
          handleUpdateInvoice={handleUpdateInvoice}
          handleClose={handleCreateRenewalInvoiceCloseDialog}
          handleSubmit={handleCreateRenewalInvoice}
        />
      )}
    </Box>
  )
}

export default withRouter(TutorStudentDetailView)
