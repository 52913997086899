import gql from 'graphql-tag'

export const GET_FAMILY_ACCOUNTS = gql`
  query getFilteredFamilyAccounts(
    $filter: Filters!
    $sort: Sorts
    $page: Pagination
  ) {
    familyAccounts(filter: $filter, sort: $sort, page: $page) {
      id
      primaryFamilyMember {
        id
        firstName
        lastName
        phoneNumber
        email
      }
    }
  }
`

export const REQUEST_STUDENT_BY_USERNAME = gql`
  query RequestStudentByUsername($username: String!) {
    requestStudentByUsername(username: $username) {
      exists
      firstName
      lastName
    }
  }
`

export const GET_STUDENT_AND_FAMILY_NAMES = gql`
  query getStudent($id: ID!) {
    student(id: $id) {
      id
      firstName
      lastName
      familyAccount {
        id
        status
        primaryFamilyMember {
          id
          firstName
          lastName
        }
      }
    }
  }
`

export const GET_STUDENT = gql`
  query getStudent($id: ID!) {
    student(id: $id) {
      id
      enrollmentStatus
      firstName
      middleName
      lastName
      nickname
      gender
      grade
      school
      username
      phoneNumber
      email
      tShirtSize
      learningStyle
      birthdate
      imageUrl
      enrollments(sort: { createdOn: -1 }) {
        id
        classroom {
          id
          quarter
          year
          centerLocation {
            id
            name
          }
          course {
            id
            code
            title
          }
        }
      }
      familyAccount {
        id
        status
        location {
          id
          street
          city
          zip
          state
          country
        }
        primaryFamilyMember {
          id
          firstName
          lastName
          relation
          email
          phoneNumber
          occupation
          educationLevel
        }
        familyMembers {
          id
          firstName
          lastName
          relation
          email
          phoneNumber
          occupation
          educationLevel
        }
      }
      opportunities {
        id
        status
        primaryInterest
        session
        year
        closeDate
        source
        notes
        createdBy {
          id
          firstName
          lastName
        }
        responsibleEmployee {
          id
          firstName
          lastName
        }
      }
    }
  }
`

export const GET_STUDENTS = gql`
  query getStudents($filter: Filters, $sort: Sorts, $page: Pagination) {
    students(filter: $filter, sort: $sort, page: $page) {
      id
      enrollmentStatus
      firstName
      lastName
      gender
      grade
      phoneNumber
      email
      school
      lastLogin
      newImage
      familyAccount {
        id
        primaryFamilyMember {
          id
          email
          phoneNumber
        }
      }
    }
  }
`

export const COUNT_STUDENTS = gql`
  query countStudents($filter: Filters) {
    countStudents(filter: $filter)
  }
`

export const UPDATE_STUDENT = gql`
  mutation UpdateStudent($id: ID!, $input: UpdateStudentInput!) {
    updateStudent(id: $id, input: $input) {
      id
    }
  }
`

export const APPROVE_IMAGE = gql`
  mutation ApproveImage($studentId: String!) {
    approveNewImage(studentId: $studentId) {
      success
      error
    }
  }
`
