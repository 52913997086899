import gql from 'graphql-tag'
import { GraphQLEnumType } from 'graphql'

export const checkType = new GraphQLEnumType({
  name: 'checkType',
  values: {
    Office: { value: 'Office' },
    Instructor: { value: 'Instructor' },
  },
})

export const EMPLOYEE_LOGIN = gql`
  mutation employeeLogin(
    $email: String!
    $password: String!
    $checkType: checkType!
  ) {
    employeeLogin(email: $email, password: $password, checkType: $checkType) {
      success
      message
      token
      employee {
        id
        position
        firstName
        lastName
        imageUrl
        workEmail
        personalEmail
      }
    }
  }
`
