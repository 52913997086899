import React, { Fragment, useState } from 'react'
import {
  GET_LESSONS_FROM_CLASS,
  GET_ASSIGNMENTS_FROM_LESSON,
} from '../../queries'
import { Query } from 'react-apollo'
import {
  MenuItem,
  Flex,
  ErrorMessage,
  Select,
  FormControl,
  InputLabel,
  LoadingMessage,
  List,
  ListItem,
  ListItemText,
  Text,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '../../components'
const classStyle = { marginBottom: '10px', borderBottom: '1px solid gray' }
const marginStyle = { marginTop: '10px', marginBottom: '10px' }
const classLabelStyle = {
  fontSize: '13px',
  marginTop: '10px',
  marginBottom: '5px',
}

function Lessons(props) {
  const lessons = props.lessons ? [...props.lessons] : []
  if (props.lessons) {
    lessons.sort((a, b) => {
      return a.lessonPlan.order - b.lessonPlan.order
    })
  }
  if (props.lessonId === null) {
    const date = new Date().toISOString()
    let lessonId //latest finished Lesson's ID
    for (const lesson of lessons) {
      if (lesson.startOn <= date) {
        lessonId = lesson.id
      }
    }
    props.onSideBarChange('lesson', lessonId)
  }
  return (
    <FormControl style={marginStyle}>
      <InputLabel>Lessons:</InputLabel>
      <Select
        value={props.lessonId ? props.lessonId : ''}
        onChange={event => props.onSideBarChange('lesson', event.target.value)}
      >
        {lessons && lessons.length ? (
          lessons
            .map(data => (
              <MenuItem key={data.id} value={data.id}>
                {data.lessonPlan
                  ? `${data.lessonPlan.order}. ${data.lessonPlan.title}`
                  : 'No Lesson Plan Found'}
              </MenuItem>
            ))
            .sort()
        ) : (
          <MenuItem value="No Lesson For This Class" disabled>
            No Lesson For This Class...
          </MenuItem>
        )}
      </Select>
    </FormControl>
  )
}

function Assignments(props) {
  const assignments = props.assignments ? [...props.assignments] : []

  if (props.assignments) {
    assignments.sort((a, b) => {
      return a.problemSet.order - b.problemSet.order
    })
  }
  if (props.assignmentId === null && assignments.length !== 0) {
    props.onSideBarChange('assignment', assignments[0].id)
  }
  const [currentAssignment, setAssignment] = useState(
    assignments.length === 0 ? 0 : assignments[0].id
  )

  return (
    <div>
      <FormControl>
        <InputLabel style={{ fontSize: 12 }}>Assignment:</InputLabel>
      </FormControl>
      <List
        style={marginStyle}
        component="nav"
        aria-label="secondary mailbox folder"
      >
        {assignments.length !== 0 ? (
          (props.display === 'required'
            ? assignments.filter(
                data =>
                  data.problemSet.kind === 'HOMEWORK' ||
                  data.problemSet.kind === 'QUIZ'
              )
            : assignments.filter(
                data =>
                  data.problemSet.kind !== 'HOMEWORK' &&
                  data.problemSet.kind !== 'QUIZ'
              )
          ).map(data => (
            <ListItem
              button
              id={data.id}
              key={data.id}
              value={data.id}
              onClick={() => {
                props.onSideBarChange('assignment', data.id)
                setAssignment(data.id)
              }}
              selected={data.id === currentAssignment}
            >
              <ListItemText
                primary={
                  data.problemSet
                    ? `${data.problemSet.order}. ${data.problemSet.title}`
                    : 'No Problem Set Found'
                }
              />
            </ListItem>
          ))
        ) : (
          <ListItem disabled>
            <ListItemText primary="No Assignment For This Lesson..." />
          </ListItem>
        )}
      </List>
    </div>
  )
}

function GradeAssignmentSelector(props) {
  return (
    <Flex column style={{ width: '100%' }}>
      <Query
        query={GET_LESSONS_FROM_CLASS}
        variables={{
          id: props.classId,
        }}
      >
        {({ loading, error, data }) => {
          if (loading) return <LoadingMessage />
          if (error) return <ErrorMessage />
          return (
            <Fragment>
              <InputLabel style={classLabelStyle}>Class:</InputLabel>
              <Text style={classStyle}>{data.classroom.title}</Text>
              <Lessons
                key="lessonSelector"
                lessons={data.classroom.lessons}
                lessonId={props.lessonId}
                onSideBarChange={(type, val) =>
                  props.onSideBarChange(type, val)
                }
              />
            </Fragment>
          )
        }}
      </Query>
      <FormControl component="fieldset">
        <FormLabel style={{ fontSize: 12, marginTop: '10px' }}>
          Display:
        </FormLabel>
        <RadioGroup
          aria-label="display"
          name="display"
          value={props.display}
          onChange={event =>
            props.onSideBarChange('display', event.target.value)
          }
          style={{ display: 'flex', flexDirection: 'row' }}
        >
          <FormControlLabel
            value="required"
            control={<Radio color="primary" />}
            label="Required"
          />
          <FormControlLabel
            value="optional"
            control={<Radio color="secondary" />}
            label="Optional"
          />
        </RadioGroup>
      </FormControl>
      {props.lessonId !== 'undefined' && (
        <Query
          query={GET_ASSIGNMENTS_FROM_LESSON}
          variables={{
            id: props.lessonId,
          }}
          skip={!props.lessonId}
        >
          {({ loading, error, data }) => {
            if (!data) return null
            if (loading) return <LoadingMessage />
            if (error) return <ErrorMessage />
            return (
              <Assignments
                key="assignmentSelector"
                assignments={data.lesson.assignments}
                assignmentId={props.assignmentId}
                onSideBarChange={(type, val) =>
                  props.onSideBarChange(type, val)
                }
                display={props.display}
              />
            )
          }}
        </Query>
      )}
    </Flex>
  )
}

export default GradeAssignmentSelector
