import React, { Fragment } from 'react'
import { sideBarStyles } from '../../utils/style'
import {
  Divider,
  Flex,
  SidebarText,
  withStyles,
  Text,
  Switch,
} from '../../components'

function GradeSidebar(props) {
  const { classes, filterByInstructor, onFilterByInstructorChanged } = props
  const totalClasses = props.classrooms.length

  const totalStudents = props.classrooms.reduce((accumulator, classroom) => {
    return (
      accumulator +
      (classroom.enrollmentCount !== null ? classroom.enrollmentCount : 0)
    )
  }, 0)

  const mathOStudents = props.classrooms.reduce((accumulator, classroom) => {
    return (
      accumulator +
      ((classroom.code.startsWith('M2') || classroom.code.startsWith('M3')) &&
      classroom.enrollmentCount !== null
        ? classroom.enrollmentCount
        : 0)
    )
  }, 0)

  const mathAStudents = props.classrooms.reduce((accumulator, classroom) => {
    return (
      accumulator +
      (classroom.code.startsWith('M1') && classroom.enrollmentCount !== null
        ? classroom.enrollmentCount
        : 0)
    )
  }, 0)

  const biologyStudents = props.classrooms.reduce((accumulator, classroom) => {
    return (
      accumulator +
      (classroom.code.startsWith('B') && classroom.enrollmentCount !== null
        ? classroom.enrollmentCount
        : 0)
    )
  }, 0)

  const physicsStudents = props.classrooms.reduce((accumulator, classroom) => {
    return (
      accumulator +
      (classroom.code.startsWith('N') && classroom.enrollmentCount !== null
        ? classroom.enrollmentCount
        : 0)
    )
  }, 0)

  const scienceStudents = props.classrooms.reduce((accumulator, classroom) => {
    return (
      accumulator +
      (classroom.code.startsWith('S') && classroom.enrollmentCount !== null
        ? classroom.enrollmentCount
        : 0)
    )
  }, 0)

  const codingStudents = props.classrooms.reduce((accumulator, classroom) => {
    return (
      accumulator +
      (classroom.code.startsWith('P') && classroom.enrollmentCount !== null
        ? classroom.enrollmentCount
        : 0)
    )
  }, 0)

  const techStudents = props.classrooms.reduce((accumulator, classroom) => {
    return (
      accumulator +
      (classroom.code.startsWith('T') && classroom.enrollmentCount !== null
        ? classroom.enrollmentCount
        : 0)
    )
  }, 0)

  const englishStudents = props.classrooms.reduce((accumulator, classroom) => {
    return (
      accumulator +
      (classroom.code.startsWith('E') && classroom.enrollmentCount !== null
        ? classroom.enrollmentCount
        : 0)
    )
  }, 0)

  const speechStudents = props.classrooms.reduce((accumulator, classroom) => {
    return (
      accumulator +
      (classroom.code.startsWith('D') && classroom.enrollmentCount !== null
        ? classroom.enrollmentCount
        : 0)
    )
  }, 0)

  const researchStudents = props.classrooms.reduce((accumulator, classroom) => {
    return (
      accumulator +
      (classroom.code.startsWith('A') && classroom.enrollmentCount !== null
        ? classroom.enrollmentCount
        : 0)
    )
  }, 0)

  return (
    <Flex
      column
      grow={0}
      shrink={0}
      overflow="hidden"
      p="10px"
      style={{
        width: '15.38%',
        borderRight: '1px #aaaaaa solid',
        maxWidth: '200px',
        overflowX: undefined,
        flexShrink: 0,
        textAlign: 'left',
        backgroundColor: '#e5e5e5',
      }}
    >
      <Flex column grow={1} justify="space-between">
        <Flex column>
          <Flex align="center">
            <Fragment>
              <Text style={{ fontSize: '12px' }}>My Classes</Text>
              <Switch
                color="default"
                checked={!filterByInstructor}
                onChange={(_, checked) => onFilterByInstructorChanged(!checked)}
              />
              <Text style={{ fontSize: '12px' }}>All Classes</Text>
            </Fragment>
          </Flex>
          <SidebarText
            classes={{
              root: classes.headline,
            }}
            title="Summary View"
          />
          <Divider />
          <SidebarText
            classes={{ root: classes.subheadingOne }}
            title="Total Classes:"
            value={totalClasses}
          />
          <SidebarText
            classes={{ root: classes.subheadingOne }}
            title="Total Students:"
            value={totalStudents}
          />
          <Divider />
          <SidebarText title="Math Olympiad:" value={mathOStudents} />
          <SidebarText title="Math Acceleration:" value={mathAStudents} />
          <SidebarText title="Biology:" value={biologyStudents} />
          <SidebarText title="Physics:" value={physicsStudents} />
          <SidebarText title="Science:" value={scienceStudents} />
          <SidebarText title="Coding:" value={codingStudents} />
          <SidebarText title="Technology:" value={techStudents} />
          <SidebarText title="English:" value={englishStudents} />
          <SidebarText title="Speech & Debate:" value={speechStudents} />
          <SidebarText title="Research:" value={researchStudents} />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default withStyles(sideBarStyles)(GradeSidebar)
