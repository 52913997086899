import React, { Component } from 'react'
import { Query, ApolloConsumer } from 'react-apollo'
import {
  ARCHIVE_ITEM,
  CREATE_CREDIT,
  CREATE_ITEM,
  CREATE_PAYMENT,
  GET_PAYMENT,
  UPDATE_FAMILY_ACCOUNT,
  UPDATE_INVOICE,
  UPDATE_ITEM,
  UPDATE_PAYMENT,
} from './queries'

import {
  ErrorMessage,
  Flex,
  LoadingMessage,
  SnackbarNotification,
} from '../../components'
import EditPaymentDialog from './EditPaymentDialog'
import PaymentDetailedPanel from './PaymentDetailedPanel'
import PaymentDetailSidebar from './PaymentDetailSidebar'
// import AddClassroomForm from './AddClassroomForm'
// import ApplyDiscountForm from './ApplyDiscountForm'
// import AddPaymentForm from './AddPaymentForm'
import { Redirect, withRouter } from 'react-router-dom'
// import AddOfferForm from './AddOfferForm'
// import AddRefundForm from './AddRefundForm'

class PaymentDetailedView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openAddClass: false,
      openApplyDiscount: false,
      openAddPayment: false,
      openAddOffer: false,
      openAddRefund: false,
      snackbar: {
        open: false,
        message: '',
        messageType: '',
        windowReload: false,
      },
      editDialog: { open: false },
      selectedItem: null,
    }
  }

  openDialog = () => {
    this.setState(state => ({
      editDialog: { ...state.editDialog, open: true },
    }))
  }

  closeDialog = () => {
    this.setState(state => ({
      editDialog: { ...state.editDialog, open: false },
    }))
  }

  openSnack = (message, messageType, windowReload) => {
    const { snackbar } = this.state
    const newSnackbar = {
      ...snackbar,
      open: true,
      messageType,
      message,
      windowReload,
    }
    this.setState({ snackbar: newSnackbar })
  }

  addItem = async (client, inputs) => {
    this.openSnack('Adding item...', 'other', false)

    for (let input of inputs) {
      await client.mutate({
        mutation: CREATE_ITEM,
        variables: {
          input,
        },
      })
    }
    this.openSnack('Success! Refreshing...', 'success', true)
  }

  updateItem = async (client, id, input) => {
    this.openSnack('Updating item...', 'other', false)
    const { data } = await client.mutate({
      mutation: UPDATE_ITEM,
      variables: {
        id,
        input,
      },
    })

    if (data && data.updateItem.id) {
      this.openSnack('Success! Refreshing...', 'success', true)
    } else {
      this.openSnack('Failed! Refreshing...', 'error', true)
    }
  }

  archiveItem = async (client, id) => {
    this.openSnack('Deleting item...', 'other', false)

    const { data } = await client.mutate({
      mutation: ARCHIVE_ITEM,
      variables: { id },
    })

    if (data && data.archiveItem.id) {
      this.openSnack('Success! Refreshing...', 'success', true)
    } else {
      this.openSnack('Failed! Refreshing...', 'error', true)
    }
  }

  withdrawItems = async (client, items) => {
    for (let item of items) {
      await client.mutate({
        mutation: UPDATE_ITEM,
        variables: {
          id: item.id,
          input: { withdrawal: true, withdrawalAmount: item.withdrawalAmount },
        },
      })
    }
  }

  updateInvoice = async (client, id, input, refresh = false) => {
    this.openSnack('Updating invoice..', 'other', false)
    await client.mutate({
      mutation: UPDATE_INVOICE,
      variables: { id, input },
    })
    this.openSnack(
      'Success!' + (refresh ? ' Refreshing...' : ''),
      'success',
      refresh
    )
  }

  updateFamilyAccount = async (client, id, input, refresh = false) => {
    this.openSnack('Updating payments...', 'other', false)
    await client.mutate({
      mutation: UPDATE_FAMILY_ACCOUNT,
      variables: { id, input },
    })
    this.openSnack(
      'Success!' + (refresh ? ' Refreshing...' : ''),
      'success',
      refresh
    )
  }

  createPayment = async (client, input) => {
    this.openSnack('Creating payments...', 'other')
    await client.mutate({ mutation: CREATE_PAYMENT, variables: { input } })
    this.openSnack('Success! Refreshing..', 'success', true)
  }

  updatePayment = async (client, id, input) => {
    this.openSnack('Updating payments...', 'other')
    await client.mutate({ mutation: UPDATE_PAYMENT, variables: { id, input } })
    this.openSnack('Success! Refreshing..', 'success', true)
  }

  enrollStudents = async (client, items) => {
    //loop through items, if isClassroom && not yet enrolled, enroll.
    this.openSnack('Enrolling students..', 'other')
    for (let item of items) {
      if (item.itemType === 'CLASSROOM' && !item.enrolled) {
        await this.enrollSingle(
          client,
          item.id,
          item.student.id,
          item.classroom.id
        )
      }
    }
    this.openSnack('Success! Refreshing..', 'success', true)
  }

  createCredit = async (client, input) => {
    const { data } = await client.mutate({
      mutation: CREATE_CREDIT,
      variables: { input },
    })
    return data.createCredit.creditNumber
  }

  render() {
    const { snackbar, editDialog } = this.state
    return (
      <Query
        fetchPolicy={'cache-and-network'}
        query={GET_PAYMENT}
        variables={{ filter: { identification: this.props.match.params[0] } }}
      >
        {({ loading, error, data, refetch }) => {
          if (loading) return <LoadingMessage />
          if (error) return <ErrorMessage error={error} />

          if (data.payments.length !== 1) {
            return <Redirect to="/payment" />
          }

          let temp = data.payments[0]

          let payment = {
            ...temp,
            familyAccountId: temp.invoice.familyAccount
              ? temp.invoice.familyAccount.id
              : null,
            primaryFamilyMember: temp.invoice.familyAccount
              ? temp.invoice.familyAccount.primaryFamilyMember
              : null,
          }
          return (
            <ApolloConsumer>
              {client => (
                <div
                  style={{
                    display: 'flex',
                    minHeight: '88vh',
                  }}
                >
                  <PaymentDetailSidebar
                    client={client}
                    openDialog={this.openDialog}
                  />
                  <Flex align="left" column grow={1}>
                    <PaymentDetailedPanel
                      payment={payment}
                      setSelectedItem={item => {
                        this.setState({
                          selectedItem: item,
                        })

                        switch (item.itemType) {
                          case 'CLASSROOM':
                            this.setState({ openAddClass: true })
                            return
                          case 'DISCOUNT':
                            this.setState({ openApplyDiscount: true })
                            return
                          case 'OFFER':
                            this.setState({ openAddOffer: true })
                            return
                          default:
                            this.setState({ openAddPayment: true })
                        }
                      }}
                    />
                  </Flex>
                  <EditPaymentDialog
                    client={client}
                    open={editDialog.open}
                    closeEditDialog={this.closeDialog}
                    payment={payment}
                    updatePayment={this.updatePayment}
                  />
                  <SnackbarNotification
                    open={snackbar.open}
                    handleClose={() => {
                      this.setState({
                        snackbar: {
                          ...snackbar,
                          open: false,
                        },
                      })
                      if (snackbar.windowReload) refetch()
                    }}
                    message={snackbar.message}
                    messageType={snackbar.messageType}
                  />
                </div>
              )}
            </ApolloConsumer>
          )
        }}
      </Query>
    )
  }
}

export default withRouter(PaymentDetailedView)
