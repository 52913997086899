import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { Mutation } from 'react-apollo'
import TransferStudentDialog from './TransferStudentDialog'

import {
  Button,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Flex,
  SidebarText,
  SnackbarNotification,
  Text,
  withStyles,
  MessageButton,
} from '../../components'

import { formatTimeRange, convertToMDY } from '../../utils/datetime'
import { sideBarStyles } from '../../utils/style'
import { UNENROLL_STUDENTS } from './queries'

class TutorClassDetailSidebar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      redirect: false,
      snackbar: {
        open: false,
        message: '',
        messageType: '',
        windowReload: false,
      },
      dialog: {
        open: false,
        message: '',
        names: [],
      },
      transferDialog: {
        open: false,
        message: '',
      },
      enrollmentId: '',
      transferToClassId: '',
      isSameCourse: '',
    }
  }

  enrollStudent = () => {
    this.setState({
      redirect: true,
      redirectTo: `/tutor_class/enroll-student/${this.props.classroom.id}`,
    })
  }

  handleChange = event => {
    const { name, value } = event.target
    this.setState({
      [name]: value,
    })
  }

  handleTransferStudentSubmission = async (confirm, loading, message) => {
    const { snackbar, transferDialog } = this.state
    const transferDialogState =
      !confirm || loading
        ? { ...transferDialog, open: false }
        : { ...transferDialog }
    const snackbarState = confirm
      ? {
          ...snackbar,
          open: true,
          message: loading
            ? 'Student transfer in progress'
            : message === 'SUCCESS'
            ? `Student has been transfered successfully`
            : `${message}`,
          messageType: loading
            ? 'loading'
            : message === 'SUCCESS'
            ? 'success'
            : 'error',
        }
      : { ...snackbar }

    this.setState({
      transferDialog: transferDialogState,
      snackbar: snackbarState,
    })
  }

  render() {
    const {
      dialog,
      isSameCourse,
      redirect,
      redirectTo,
      snackbar,
      transferToClassId,
      transferDialog,
    } = this.state
    if (redirect) {
      return <Redirect push to={redirectTo} />
    }

    const {
      checkedStudents,
      classes,
      classroom,
      menu,
      show,
      students,
      toggleRenewals,
    } = this.props
    const {
      code,
      enrollmentCount,
      enrollmentMax,
      endDate,
      endTime,
      instructorDisplayNames,
      meets,
      startDate,
      startTime,
      tuitionCents,
    } = classroom

    const timeOfClass = formatTimeRange(new Date(startTime), new Date(endTime))
    const convertedStartDate = convertToMDY(startDate)
    const convertedEndDate = convertToMDY(endDate)
    const instructorName = instructorDisplayNames

    return (
      <Flex
        column
        grow={0}
        shrink={0}
        overflow="hidden"
        p="10px"
        style={{
          width: show ? '15.38%' : 0,
          borderRight: `${show ? '1px' : 0} ${'#aaaaaa'} solid`,
          maxWidth: show ? '200px' : 0,
          overflowX: show ? undefined : 'hidden',
          flexShrink: 0,
          textAlign: 'left',
          backgroundColor: '#e5e5e5',
        }}
      >
        <Flex column grow={1} justify="space-between">
          <Flex column>
            <Text
              classes={{
                root: classes.headlineTwo,
              }}
            >
              {classroom.code}
            </Text>

            <Dialog
              open={dialog.open}
              maxWidth={'sm'}
              onClose={() => {
                this.setState({
                  dialog: {
                    ...dialog,
                    open: false,
                  },
                })
              }}
            >
              <DialogTitle>Missing Students</DialogTitle>
              <DialogContent style={{ width: '250px' }}>
                {dialog.names ? (
                  dialog.names.map((item, i) => <Text key={i}>{item}</Text>)
                ) : (
                  <Text>No missing students.</Text>
                )}
              </DialogContent>
              <DialogActions
                style={{ paddingBottom: '10px', paddingRight: '10px' }}
              >
                <Button
                  onClick={() => {
                    this.setState({
                      dialog: {
                        ...dialog,
                        open: false,
                      },
                    })
                    window.location.reload()
                  }}
                  color="secondary"
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
            <br />
            <Button
              onClick={this.enrollStudent}
              disabled={enrollmentCount >= enrollmentMax}
            >
              Enroll Student
            </Button>
            <br />
            <Button
              onClick={() => {
                this.setState({
                  transferDialog: {
                    ...transferDialog,
                    open: true,
                  },
                })
              }}
            >
              Transfer Student
            </Button>
            <TransferStudentDialog
              classroom={classroom}
              enrollmentId={this.state.enrollmentId}
              open={transferDialog.open}
              handleChange={this.handleChange}
              handleTransferStudentSubmission={
                this.handleTransferStudentSubmission
              }
              isSameCourse={isSameCourse}
              transferToClassId={transferToClassId}
              closeDialog={() => {
                this.setState({
                  transferDialog: {
                    ...transferDialog,
                    open: false,
                  },
                })
              }}
            />
            <br />
            <Mutation mutation={UNENROLL_STUDENTS}>
              {unenrollStudents => (
                <Button
                  onClick={async () => {
                    if (
                      window.confirm(
                        "Are you sure you want to unenroll every marked student? This can't be undone."
                      )
                    ) {
                      const enrollmentIds = checkedStudents.map(
                        student => student.enrollmentId
                      )
                      await unenrollStudents({
                        variables: {
                          enrollmentIds,
                        },
                      })
                      this.setState({
                        snackbar: {
                          open: true,
                          message: 'Unenroll Complete',
                          messageType: 'success',
                          windowReload: true,
                        },
                      })
                    }
                  }}
                >
                  Unenroll Students
                </Button>
              )}
            </Mutation>

            <br />
            <MessageButton
              title="Message Students"
              label={'Enter a message you would like to send'}
              students={(checkedStudents.length === 0
                ? students
                : checkedStudents
              ).map(student => ({
                firstName: student.firstName,
                lastName: student.lastName,
                familyMemberId: student.familyMemberId,
                phoneNumber: student.phoneNumber,
                username: student.username,
              }))}
              instructorName={instructorName}
            />
            <br />
            <Button onClick={() => toggleRenewals(classroom, students)}>
              SHOW RENEWALS
            </Button>
            <br />
            <SidebarText
              classes={{ root: classes.headline }}
              title="Detail View"
            />
            <Divider />
            <SidebarText
              classes={{ root: classes.subheadingOne }}
              title="Total Students:"
              value={enrollmentCount}
            />
            <SidebarText
              title="Max Enrollment: "
              value={enrollmentMax || 'None'}
            />
            <SidebarText title="Class Code: " value={code} />
            <SidebarText title="Day of the Week: " value={meets} />
            <SidebarText title="Time: " value={timeOfClass} />
            <SidebarText title="Starting Date: " value={convertedStartDate} />
            <SidebarText title="Ending Date: " value={convertedEndDate} />
            <SidebarText
              title="Location: "
              value={classroom.centerLocation.name}
            />
            <SidebarText title="Instructor: " value={instructorName} />
            <SidebarText
              title="Tuition: "
              value={`$${(tuitionCents / 100).toFixed(2)}`}
            />
          </Flex>
          {menu()}
        </Flex>
        <SnackbarNotification
          open={snackbar.open}
          handleClose={() => {
            this.setState({
              snackbar: {
                ...snackbar,
                open: false,
              },
            })
            if (snackbar.windowReload) window.location.reload()
          }}
          message={snackbar.message}
          messageType={snackbar.messageType}
        />
      </Flex>
    )
  }
}

export default withStyles(sideBarStyles)(TutorClassDetailSidebar)
