import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'

import ReactTable from 'react-table'

import DateAdapter from '@mui/lab/AdapterMoment'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DateTimePicker from '@mui/lab/DateTimePicker'
import {
  Box,
  Button,
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'

const CreditDetailedPanel = ({ creditInfo, updateCredit }) => {
  const [editingInfo, setEditingInfo] = useState(false)

  const [issueDate, setIssueDate] = useState(creditInfo.issueDate)
  const [status, setStatus] = useState(creditInfo.status)
  const [amount, setAmount] = useState(creditInfo.amount)
  const [balance, setBalance] = useState(creditInfo.balance)
  const [notes, setNotes] = useState(creditInfo.notes)

  const history = useHistory()

  const handleUpdateCredit = () => {
    const newBalance = status === 'EXPIRED' ? balance - amount : balance
    const newAmount = status === 'EXPIRED' ? 0 : amount
    updateCredit(creditInfo.id, {
      issueDate: issueDate,
      amount: newAmount,
      balance: newBalance,
      notes: notes,
      status: status,
    })
  }

  const adjustAmount = newAmount => {
    const change = newAmount - amount
    setAmount(newAmount)
    setBalance(balance + change)
  }

  const handleIssueDate = value => {
    console.log('in event', value)
    setIssueDate(value)
  }

  const handleStatusChange = event => {
    setStatus(event.target.value)
  }

  const itemColumns = [
    {
      Header: '#',
      Cell: props => props.index + 1,
      width: 30,
    },
    {
      Header: 'trans ID',
      accessor: 'identification',
    },
    {
      Header: 'Amount',
      accessor: 'amount',
    },
    {
      Header: 'Received date',
      accessor: 'dateReceived',
    },
    {
      Header: 'Method',
      accessor: 'method',
    },
  ]

  const { creditNumber, invoice, email, guardian, phone, refunds } = creditInfo
  return (
    <Box
      column
      style={{
        align: 'center',
        flexGrow: 1,
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h4">Credit</Typography>
        <Button variant="contained" onClick={() => history.push('/credit')}>
          back
        </Button>
      </div>
      <div className="innerRowDiv">
        <Typography className="innerRowText">{`ID: ${creditNumber}`}</Typography>
        {invoice && (
          <Typography className="innerRowText">
            Linked Invoice:{' '}
            <Link to={`/invoice/${invoice.invoiceNumber}`}>
              {invoice.invoiceNumber}
            </Link>
          </Typography>
        )}
        <Typography className="innerRowText">{`User: ${guardian}`}</Typography>
        <Typography className="innerRowText">{`Email: ${email}`}</Typography>
        <Typography className="innerRowText">{`Phone: ${phone}`}</Typography>
        <div style={{ display: 'flex' }}>
          <LocalizationProvider dateAdapter={DateAdapter}>
            <DateTimePicker
              name="issueDate"
              label="Issue Date"
              value={issueDate}
              onChange={handleIssueDate}
              renderInput={params => <TextField {...params} />}
              disabled={!editingInfo}
            />{' '}
          </LocalizationProvider>
        </div>
        <FormControl
          variant="standard"
          disabled={!editingInfo}
          sx={{ m: 1, minWidth: 120 }}
        >
          <InputLabel id="demo-simple-select-standard-label">Status</InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={status}
            onChange={handleStatusChange}
            label="Status"
          >
            <MenuItem value={'OPEN'}>OPEN</MenuItem>
            <MenuItem value={'PAID'}>PAID</MenuItem>
            <MenuItem value={'VOID'}>VOID</MenuItem>
            <MenuItem value={'EXPIRED'}>EXPIRED</MenuItem>
            <MenuItem value={'USED'}>USED</MenuItem>
            <MenuItem value={'REFUNDED'}>REFUNDED</MenuItem>
          </Select>
        </FormControl>
        <div style={{ display: 'flex' }}>
          <FormControl
            fullWidth
            sx={{ m: 1 }}
            variant="standard"
            disabled={!editingInfo}
            style={{ width: '75%' }}
          >
            <InputLabel htmlFor="standard-adornment-amount">
              New Credit Amount
            </InputLabel>
            <Input
              id="standard-adornment-amount"
              value={amount}
              onChange={event =>
                adjustAmount(
                  event.target.value ? parseFloat(event.target.value) : 0.0
                )
              }
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
            />
          </FormControl>
        </div>
        <div style={{ display: 'flex' }}>
          <TextField
            label="Notes"
            multiline
            minRows={4}
            disabled={!editingInfo}
            value={notes}
            inputProps={{ style: { paddingTop: '0px' } }}
            onChange={e => setNotes(e.target.value)}
            style={{ width: '300px' }}
          />
        </div>
        <br />
        <div style={{ display: 'flex' }}>
          <Button
            style={{ marginRight: '5px' }}
            variant="contained"
            onClick={() => {
              if (editingInfo) {
                handleUpdateCredit()
                setEditingInfo(!editingInfo)
              } else setEditingInfo(!editingInfo)
            }}
          >
            {editingInfo ? 'Save' : 'Edit'}
          </Button>
          {editingInfo && (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setEditingInfo(!editingInfo)}
            >
              Cancel
            </Button>
          )}
        </div>
      </div>
      <div>
        {refunds.length > 0 && (
          <div className="tableDiv">
            <Typography variant="h4" style={{ padding: '5px' }}>
              {'Refunds'}
            </Typography>
            <ReactTable
              data={refunds}
              columns={itemColumns}
              defaultPageSize={Math.min(10, refunds.length)}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
              }}
            >
              <Typography className="innerRowText">
                {`Refunded Total: $${refunds.reduce(
                  (counter, ref) => counter + ref.amount,
                  0
                )}`}
              </Typography>
            </div>
          </div>
        )}
      </div>
    </Box>
  )
}

export default CreditDetailedPanel
