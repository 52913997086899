import React, { Component } from 'react'
import { Query } from 'react-apollo'
import ReactTable from 'react-table'

import { GET_ACTIVITY_TYPES } from './queries'
import { LoadingMessage, ErrorMessage } from '../../components'
import { convertToMDY, dateFilterMethod } from '../../utils/datetime'

class TaskTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectAll: false,
      checked: this.props.tasks.map(() => {
        return false
      }),
    }
  }

  handleSingleCheckboxChange = index => {
    const { checked, selectAll } = this.state
    let newChecked = checked
    newChecked[index] = !checked[index]
    this.setState(
      !newChecked[index] && selectAll
        ? {
            checked: newChecked,
            selectAll: false,
          }
        : { checked: newChecked }
    )
  }

  render() {
    const columns = [
      {
        Cell: row => (
          <input
            type="checkbox"
            checked={this.state.checked[row.index]}
            onChange={() => this.handleSingleCheckboxChange(row.index)}
          />
        ),
        sortable: false,
        filterable: false,
        resizable: false,
        width: 28,
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: 100,
        Cell: props => {
          return (
            <span
              style={{
                color: props.value === 'OPEN' ? '#27AE60' : '#EB5757',
              }}
            >
              {props.value}
            </span>
          )
        },
        filterMethod: (filter, row) => {
          if (filter.value !== 'ALL') {
            return row[filter.id] === filter.value
          }
          return true
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: '100%' }}
            value={filter ? filter.value : 'all'}
          >
            <option value="ALL">All</option>
            <option value="OPEN">Open</option>
            <option value="CLOSED">Closed</option>
          </select>
        ),
      },
      {
        Header: 'Type',
        accessor: 'type',
        width: 135,
        Cell: props => {
          return <span>{props.value ? props.value : 'UNKNOWN'}</span>
        },
        filterMethod: (filter, row) => {
          if (filter.value !== 'ALL' && filter.value !== 'UNKNOWN') {
            return row[filter.id] === filter.value
          }
          if (filter.value === 'UNKNOWN') {
            return row[filter.id] === null
          }
          return true
        },
        Filter: ({ filter, onChange }) => (
          <Query query={GET_ACTIVITY_TYPES}>
            {({ data, loading, error }) => {
              if (loading) return <LoadingMessage />
              if (error) return <ErrorMessage />
              return (
                <select
                  onChange={event => onChange(event.target.value)}
                  style={{ width: '100%' }}
                  value={filter ? filter.value : 'all'}
                >
                  <option value="ALL">ALL</option>
                  {data.__type.enumValues.map(({ name }) => (
                    <option key={name} value={name}>
                      {name.substring(0, 1) + name.substring(1).toLowerCase()}
                    </option>
                  ))}
                </select>
              )
            }}
          </Query>
        ),
      },
      {
        Header: 'Priority',
        accessor: 'priority',
        width: 100,
        Cell: props => {
          if (props.value === 'LOW') {
            return <span style={{ color: '#27AE60' }}>{'LOW'}</span>
          }
          if (props.value === 'MED') {
            return <span style={{ color: '#00ADEF' }}>{'MEDIUM'}</span>
          }
          if (props.value === 'HIGH') {
            return <span style={{ color: '#EBA357' }}>{'HIGH'}</span>
          }
          if (props.value === 'CRITICAL') {
            return <span style={{ color: '#EB5757' }}>{'CRITICAL'}</span>
          }
          return <span>{props.value}</span>
        },
        filterMethod: (filter, row) => {
          if (filter.value.charAt(0) === '↑') {
            if (filter.value === '↑LOW') {
              return row[filter.id] !== null
            }
            if (filter.value === '↑MED') {
              return row[filter.id] !== null && row[filter.id] !== 'LOW'
            }
            if (filter.value === '↑HIGH') {
              return (
                row[filter.id] !== null &&
                row[filter.id] !== 'LOW' &&
                row[filter.id] !== 'MED'
              )
            }
          } else {
            if (filter.value !== 'ALL') {
              return row[filter.id] === filter.value
            }
          }
          return true
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: '100%' }}
            value={filter ? filter.value : 'all'}
          >
            <option value="ALL">All</option>
            <option value="LOW">Low</option>
            <option value="MED">Medium</option>
            <option value="HIGH">High</option>
            <option value="CRITICAL">Critical</option>
            <option value="↑LOW">Low ↑</option>
            <option value="↑MED">Medium ↑</option>
            <option value="↑HIGH">High ↑</option>
          </select>
        ),
        sortMethod: (a, b, desc) => {
          let a_priority = 0
          let b_priority = 0
          switch (a) {
            case 'LOW':
              a_priority = 1
              break
            case 'MED':
              a_priority = 2
              break
            case 'HIGH':
              a_priority = 3
              break
            case 'CRITICAL':
              a_priority = 4
              break
            default:
              break
          }
          switch (b) {
            case 'LOW':
              b_priority = 1
              break
            case 'MED':
              b_priority = 2
              break
            case 'HIGH':
              b_priority = 3
              break
            case 'CRITICAL':
              b_priority = 4
              break
            default:
              break
          }
          if (a_priority > b_priority) {
            return 1
          }
          if (a_priority < b_priority) {
            return -1
          }
          return 0
        },
      },
      {
        Header: 'Method',
        accessor: 'method',
        width: 135,
        Cell: props => {
          return <span>{props.value ? props.value : 'UNKNOWN'}</span>
        },
        filterMethod: (filter, row) => {
          if (filter.value !== 'ALL' && filter.value !== 'UNKNOWN') {
            return row[filter.id] === filter.value
          }
          if (filter.value === 'UNKNOWN') {
            return row[filter.id] === null
          }
          return true
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: '100%' }}
            value={filter ? filter.value : 'all'}
          >
            <option value="ALL">All</option>
            <option value="CALL">Call</option>
            <option value="EMAIL">Email</option>
            <option value="IN_PERSON">In Person</option>
            <option value="UNKNOWN">UNKNOWN</option>
          </select>
        ),
      },
      {
        Header: 'Guardian',
        accessor: 'contact',
      },
      {
        Header: 'Student',
        accessor: 'studentName',
      },
      {
        Header: 'Created By',
        accessor: 'createdBy',
      },
      {
        Header: 'Assigned to',
        accessor: 'responsibleEmployee',
      },
      {
        Header: 'Due Date',
        accessor: 'endDate',
        Cell: props => <span>{props.value && convertToMDY(props.value)}</span>,
        width: 100,
        filterMethod: dateFilterMethod,
      },
      {
        Header: 'Close Date',
        accessor: 'resolvedDate',
        Cell: props => <span>{props.value && convertToMDY(props.value)}</span>,
        width: 100,
        filterMethod: dateFilterMethod,
      },
      {
        Header: 'Description',
        accessor: 'description',
        minWidth: 250,
      },
    ]
    const { tasks, filterable, filtered, onFilteredChange, sorted } = this.props
    return (
      <div align="left">
        <ReactTable
          filterable={filterable}
          data={tasks}
          columns={columns}
          className="-highlight"
          defaultPageSize={25}
          defaultSorted={sorted}
          defaultFiltered={filtered}
          onFilteredChange={onFilteredChange}
          style={{ fontSize: '10pt' }}
          defaultFilterMethod={(filter, row) =>
            row[filter.id] &&
            row[filter.id]
              .toString()
              .toLowerCase()
              .includes(filter.value.toString().toLowerCase())
          }
          getTrProps={(state, rowInfo, column) => {
            let background = '#ffffff'
            return {
              style: {
                background,
              },
            }
          }}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              onClick: (e, handleOriginal) => {
                if (column.Header !== undefined) {
                  this.props.onRowClick(rowInfo)
                }
                if (handleOriginal) {
                  handleOriginal()
                }
              },
            }
          }}
          getTheadProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                fontWeight: 'bold',
                borderBottom: '1px solid #E5E5E5',
              },
            }
          }}
        />
      </div>
    )
  }
}

export default TaskTable
