import gql from 'graphql-tag'

export const GET_FAMILY_ACCOUNTS = gql`
  query getFilteredFamilyAccounts(
    $filter: Filters!
    $sort: Sorts
    $page: Pagination
  ) {
    familyAccounts(filter: $filter, sort: $sort, page: $page) {
      id
      primaryFamilyMember {
        id
        firstName
        lastName
        phoneNumber
        email
      }
    }
  }
`

export const REQUEST_STUDENT_BY_USERNAME = gql`
  query RequestStudentByUsername($username: String!) {
    requestStudentByUsername(username: $username) {
      exists
      firstName
      lastName
    }
  }
`

export const GET_STUDENT_AND_FAMILY_NAMES = gql`
  query getStudent($id: ID!) {
    student(id: $id) {
      id
      firstName
      lastName
      familyAccount {
        id
        status
        primaryFamilyMember {
          id
          firstName
          lastName
        }
      }
    }
  }
`

export const GET_STUDENT = gql`
  query getStudent($id: ID!) {
    student(id: $id) {
      id
      enrollmentStatus
      firstName
      middleName
      lastName
      nickname
      gender
      grade
      school
      username
      phoneNumber
      email
      tShirtSize
      learningStyle
      birthdate
      imageUrl
      isTutored
      enrollments(sort: { createdOn: -1 }) {
        id
        classroom {
          id
          quarter
          year
          centerLocation {
            id
            name
          }
          course {
            id
            code
            title
          }
        }
      }
      familyAccount {
        id
        status
        location {
          id
          street
          city
          zip
          state
          country
        }
        primaryFamilyMember {
          id
          firstName
          lastName
          relation
          email
          phoneNumber
          occupation
          educationLevel
        }
        familyMembers {
          id
          firstName
          lastName
          relation
          email
          phoneNumber
          occupation
          educationLevel
        }
      }
      opportunities {
        id
        status
        primaryInterest
        session
        year
        closeDate
        source
        notes
        createdBy {
          id
          firstName
          lastName
        }
        responsibleEmployee {
          id
          firstName
          lastName
        }
      }
    }
  }
`

export const GET_STUDENTS = gql`
  query getStudents($filter: Filters, $sort: Sorts, $page: Pagination) {
    students(filter: $filter, sort: $sort, page: $page) {
      id
      enrollmentStatus
      firstName
      lastName
      gender
      grade
      phoneNumber
      email
      school
      lastLogin
      newImage
      isTutored
      familyAccount {
        id
        primaryFamilyMember {
          id
          email
          phoneNumber
        }
      }
      enrollments {
        classroom {
          id
          quarter
        }
      }
    }
  }
`

export const GET_TUTOR_ENROLLMENTS = gql`
  query getEnrollments($filter: Filters, $sort: Sorts, $page: Pagination) {
    enrollments(filter: $filter, sort: $sort, page: $page) {
      id
      status
      totalTutorSessionCount
      scheduledTutorSessionCount
      nextSessionDateTime
      completionStatus
      createdOn
      classroom {
        id
        code
      }
      student {
        id
        firstName
        lastName
        gender
        grade
        school
        lastLogin
        familyAccount {
          id
          primaryFamilyMember {
            id
            email
            phoneNumber
          }
        }
      }
    }
  }
`

export const GET_PAYMENT = gql`
  query getItems($filter: Filters) {
    items(filter: $filter) {
      id
      classroom {
        id
        code
      }
      student {
        id
      }
      invoice {
        id
        status
        invoiceNumber
        price
        balance
        payments {
          method
          amount
        }
      }
    }
  }
`

export const GET_ENROLLMENT = gql`
  query getEnrollment($filter: Filters) {
    enrollments(filter: $filter) {
      id
      createdOn
      status
      completionStatus
    }
  }
`

export const GET_TUTOR_SESSIONS = gql`
  query getTutorSessions($studentId: ID!, $classroomId: ID!, $filter: Filters) {
    student(id: $studentId) {
      id
      firstName
      lastName
      image
      imageUrl
      school
      grade
    }
    classroom(id: $classroomId) {
      id
      courseTitle
      courseCode
    }
    enrollments(filter: { studentId: $studentId, classroomId: $classroomId }) {
      createdOn
      count
    }
    tutorSessions(filter: $filter) {
      id
      status
      order
    }
  }
`

export const GET_TUTOR_SESSIONS_BY_MONTH = gql`
  query getTutorSessionsByMonth($filter: Filters) {
    tutorSessions(filter: $filter) {
      id
      tutor {
        id
        firstName
        lastName
      }
      startDateTime
      duration
      summary
      status
      order
      overallGrade
      updateAll
    }
  }
`

export const GET_TUTORS = gql`
  query getEmployees {
    employees(
      filter: { isTutor: true, status: "ACTIVE" }
      sort: { lastName: 1 }
    ) {
      id
      status
      firstName
      lastName
    }
  }
`

export const COUNT_STUDENTS = gql`
  query countStudents($filter: Filters) {
    countEnrollments(filter: $filter)
  }
`

export const UPDATE_STUDENT = gql`
  mutation UpdateStudent($id: ID!, $input: UpdateStudentInput!) {
    updateStudent(id: $id, input: $input) {
      id
    }
  }
`

export const APPROVE_IMAGE = gql`
  mutation ApproveImage($studentId: String!) {
    approveNewImage(studentId: $studentId) {
      success
      error
    }
  }
`

export const UPDATE_TUTOR_SESSION = gql`
  mutation UpdateTutorSession($id: ID!, $input: UpdateTutorSessionInput!) {
    updateTutorSession(id: $id, input: $input) {
      id
    }
  }
`

export const RENEW_TUTOR_SESSIONS = gql`
  mutation renewSubscription(
    $employeeId: String!
    $enrollmentId: String!
    $quantity: Int!
    $unitPrice: Int!
  ) {
    renewSubscription(
      employeeId: $employeeId
      enrollmentId: $enrollmentId
      quantity: $quantity
      unitPrice: $unitPrice
    ) {
      success
      message
    }
  }
`

export const UPDATE_ENROLLMENT = gql`
  mutation UpdateEnrollment($id: ID!, $input: UpdateEnrollmentInput!) {
    updateEnrollment(id: $id, input: $input) {
      id
    }
  }
`

export const ARCHIVE_TUTOR_SESSION = gql`
  mutation ArchiveTutorSession($id: ID!) {
    archiveTutorSession(id: $id) {
      id
    }
  }
`

export const CREATE_TUTOR_SESSIONS = gql`
  mutation createTutorSessions(
    $studentId: String!
    $classroomId: String!
    $numberOfTutorSessions: Int!
  ) {
    createTutorSessions(
      studentId: $studentId
      classroomId: $classroomId
      numberOfTutorSessions: $numberOfTutorSessions
    ) {
      success
      errorMessage
    }
  }
`
