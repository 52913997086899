import React from 'react'
import Text from './Text'

const LoadingMessage = ({ padding = '20px', variant = 'h5' }) => (
  <Text style={{ padding: padding }} variant={variant}>
    Loading...
  </Text>
)

export default LoadingMessage
