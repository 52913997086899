import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'

import { Divider, Flex, SidebarText, withStyles } from '../../components'

import { sideBarStyles } from '../../utils/style'

class NewsViewSidebar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      redirect: false,
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirectTo} />
    }

    const { classes } = this.props

    return (
      <Flex
        column
        grow={1}
        shrink={0}
        p="10px"
        style={{
          width: '15.38%',
          borderRight: `1px '#aaaaaa' solid`,
          maxWidth: '200px',
          overflowX: undefined,
          flexShrink: 0,
          textAlign: 'left',
          backgroundColor: '#e5e5e5',
          height: '100vh',
        }}
      >
        <Flex column grow={1} justify="space-between">
          <Flex column>
            <SidebarText
              classes={{
                root: classes.headline,
              }}
              title={'Summary'}
            />
            <Divider style={{ marginBottom: '1em' }} />
          </Flex>
        </Flex>
      </Flex>
    )
  }
}

export default withStyles(sideBarStyles)(NewsViewSidebar)
