import React, { Component } from 'react'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import { PermPhoneMsgIcon } from '../../components/Icons'
import {
  TABLE_ROW_HIGHLIGHT_COLOR,
  TABLE_ROW_BACKGROUND_COLOR,
  CLASS_SCHEDULED,
} from '../../config'
import { convertPhoneNumber } from '../../utils'
import { convertToMDY, dateFilterMethod } from '../../utils/datetime'

class TrialsTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectAll: false,
      checked: this.props.trials.map(() => {
        return false
      }),
    }
  }

  handleSingleCheckboxChange = index => {
    const { checked, selectAll } = this.state
    let newChecked = checked
    newChecked[index] = !checked[index]
    this.setState(state => ({
      checked: newChecked,
      selectAll:
        newChecked[index] === false && selectAll ? false : state.selectAll,
    }))
  }

  render() {
    const { checked } = this.state
    const columns = [
      {
        Cell: row => (
          <input
            type="checkbox"
            checked={checked[row.index]}
            onChange={() => this.handleSingleCheckboxChange(row.index)}
          />
        ),
        sortable: false,
        filterable: false,
        resizable: false,
        width: 28,
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: 135,
        Cell: props => <span>{props.value ? props.value : 'SCHEDULED'}</span>,
        filterMethod: (filter, row) => {
          if (filter.value !== 'ALL') {
            return row[filter.id] === filter.value
          }
          if (filter.value === 'UNKNOWN') {
            return row[filter.id] === null
          }
          return true
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: '100%' }}
            value={filter ? filter.value : 'ALL'}
          >
            <option value="ALL">ALL</option>
            {this.props.trialStatuses
              .filter(option => option !== 'RESCHEDULED')
              .map(option => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
          </select>
        ),
      },
      {
        Header: 'Enrollment',
        accessor: 'enrollment',
        width: 120,
        Cell: props => {
          return (
            <span
              style={{
                color:
                  props.value === 'ENROLLED'
                    ? 'blue'
                    : props.value === 'OPEN'
                    ? 'green'
                    : props.value === 'LOST'
                    ? 'red'
                    : 'black',
              }}
            >
              {props.value}
            </span>
          )
        },
        filterMethod: (filter, row) => {
          if (filter.value !== 'ALL') {
            return row[filter.id] === filter.value
          }
          return true
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: '100%' }}
            value={filter ? filter.value : 'ALL'}
          >
            <option value="ALL">ALL</option>
            {this.props.opportunityStatuses.map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        ),
      },
      {
        Header: <PermPhoneMsgIcon style={{ fontSize: '10pt' }} />,
        accessor: 'followupStatus',
        width: 45,
        Cell: row => <div style={{ textAlign: 'center' }}>{row.value}</div>,
      },
      {
        Header: 'Followup Date',
        accessor: 'followupDate',
        sortMethod: (date1, date2) => {
          return date1 - date2
        },
        Cell: row => convertToMDY(row.value),
        filterMethod: dateFilterMethod,
        width: 110,
      },
      {
        Header: 'Followup By',
        accessor: 'followupBy',
        width: 120,
      },
      {
        Header: 'Class',
        accessor: 'code',
        width: 100,
      },
      {
        Header: 'Instructor',
        accessor: 'instructor',
        width: 120,
      },
      {
        Header: 'Lesson',
        accessor: 'lessonNumber',
        width: 64,
        filterMethod: (filter, row) => {
          if (isNaN(filter.value)) {
            if (filter.value === 'ALL') {
              return true
            }
            if (filter.value === '-') {
              return row[filter.id] === null
            }
          }
          return row[filter.id] === parseInt(filter.value, 10)
        },
        Filter: ({ filter, onChange }) => {
          return (
            <select
              onChange={event => onChange(event.target.value)}
              style={{ width: '100%' }}
              value={filter ? filter.value : 'ALL'}
            >
              <option value="ALL">All</option>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => (
                <option key={`gradeFilter${i}`} value={i}>
                  {i}
                </option>
              ))}
              <option value="-">-</option>
            </select>
          )
        },
      },
      {
        Header: 'Day',
        accessor: 'lessonDay',
        width: 80,
      },
      {
        Header: 'Date',
        accessor: 'lessonDate',
        width: 100,
        sortMethod: (date1, date2) => {
          return date1 - date2
        },
        Cell: row => convertToMDY(row.value),
        filterMethod: dateFilterMethod,
      },
      {
        Header: 'Time',
        accessor: 'lessonTime',
        width: 100,
      },
      {
        Header: 'Location',
        accessor: 'location',
        width: 80,
      },
      {
        Header: 'First Name',
        accessor: 'firstName',
      },
      {
        Header: 'Last Name',
        accessor: 'lastName',
        filterMethod: (filter, row) => {
          return (
            row[filter.id] &&
            row[filter.id].toLowerCase().startsWith(filter.value.toLowerCase())
          )
        },
      },
      {
        Header: 'Phone Number',
        accessor: 'phoneNumber',
        Cell: props => (
          <span className="number">{convertPhoneNumber(props.value)}</span>
        ),
        width: 110,
      },
    ]

    const {
      filtered,
      filterable,
      sorted,
      trials,
      onFilteredChange,
      onRowClick,
    } = this.props
    return (
      <div align="left">
        <ReactTable
          filterable={filterable}
          data={trials}
          columns={columns}
          className="-highlight"
          defaultPageSize={25}
          defaultSorted={sorted}
          defaultFiltered={filtered}
          onFilteredChange={onFilteredChange}
          style={{ fontSize: '10pt' }}
          defaultFilterMethod={(filter, row) =>
            row[filter.id] &&
            row[filter.id]
              .toString()
              .toLowerCase()
              .includes(filter.value.toString().toLowerCase())
          }
          getTrProps={(state, rowInfo, column) => {
            if (
              rowInfo &&
              rowInfo.row.status === CLASS_SCHEDULED &&
              (rowInfo.row.lessonDate < new Date() ||
                rowInfo.row.followupDate < new Date())
            ) {
              return { style: { background: TABLE_ROW_HIGHLIGHT_COLOR } }
            }
            return { style: { background: TABLE_ROW_BACKGROUND_COLOR } }
          }}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              onClick: (e, handleOriginal) => {
                if (column.Header !== undefined) {
                  onRowClick(rowInfo)
                }
                if (handleOriginal) {
                  handleOriginal()
                }
              },
            }
          }}
          getTheadProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                fontWeight: 'bold',
                borderBottom: '1px solid #E5E5E5',
              },
            }
          }}
        />
      </div>
    )
  }
}

export default TrialsTable
