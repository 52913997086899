export const userName = (firstName, lastName) => {
  return firstName + lastName[0] + (Math.floor(Math.random() * 899) + 100)
}

export const randomPassword = length => {
  const charSet =
    'abcdefghjkmnpqrstuvwxyzABCDEFGHJKMNPQRSTUVWXYZ23456789^!"$%&()=?*+~#'
  return Array.apply(null, Array(length || 8))
    .map(function() {
      return charSet.charAt(Math.random() * charSet.length)
    })
    .join('')
}
