import React, { Component } from 'react'
import { Query, withApollo } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import {
  Button,
  DatePicker,
  ErrorMessage,
  Flex,
  LoadingMessage,
  MenuItem,
  SnackbarNotification,
  TextField,
} from '../../components'
import PaymentTable from './PaymentTable'
import { CREATE_RECONCILIATION, GET_PAYMENTS, UPDATE_PAYMENT } from './queries'

class AddReconcilePage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      input: {
        account: 'AUTHORIZE',
        fromDate: new Date().toISOString(),
        toDate: new Date().toISOString(),
        amount: 0,
        dateReconciled: new Date().toISOString(),
      },
      selectedPayments: [],
      sorted: [],
      filtered: [],
      errorMsg: '',
      loading: false,
      snackbar: {
        open: false,
        message: '',
        messageType: '',
        windowReload: false,
      },
    }
  }

  openSnack = (message, messageType, windowReload) => {
    const { snackbar } = this.state
    const newSnackbar = {
      ...snackbar,
      open: true,
      messageType,
      message,
      windowReload,
    }
    this.setState({ snackbar: newSnackbar })
  }

  onFilteredChange = filtered => {
    this.setState({
      filtered,
    })
  }

  handleSubmit = async () => {
    const { input, selectedPayments } = this.state

    if (!input.fromDate || !input.toDate) {
      this.setState({ errorMsg: 'Enter all fields' })
      return
    }
    if (selectedPayments.length === 0) {
      this.setState({ errorMsg: 'Select aleast 1 payment' })
      return
    }
    this.setState({ errorMsg: '', loading: true })
    this.openSnack('Creating...', 'other', false)

    try {
      const { data } = await this.props.client.mutate({
        mutation: CREATE_RECONCILIATION,
        variables: { input: { ...input } },
      })

      const reconId = data.createReconciliation.id

      if (!reconId) {
        this.setState({
          loading: false,
          errorMsg: 'Failed to create Reconciliation',
        })
        return
      }

      for (let payment of selectedPayments) {
        await this.props.client.mutate({
          mutation: UPDATE_PAYMENT,
          variables: {
            id: payment.id,
            input: { reconciled: true, reconciliationId: reconId },
          },
        })
      }
    } catch (e) {
      this.setState({ loading: false, errorMsg: `${e}` })
      return
    }

    // this.setState({ loading: false, selectedPayments: [] })
    this.openSnack('Success!', 'success', true)
    setTimeout(() => window.location.reload(), 2000)
  }

  render() {
    const { input, errorMsg, loading, snackbar } = this.state
    return (
      <div style={{ flex: 1, display: 'flex' }}>
        <div>
          <form
            onSubmit={e => {
              e.preventDefault()
              this.handleSubmit()
            }}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '300px',
              padding: '24px',
            }}
          >
            <div style={{ color: 'red' }}>{errorMsg}</div>
            <TextField
              select
              label="Account"
              required
              value={input.account}
              fullWidth
              onChange={e =>
                this.setState({ input: { ...input, account: e.target.value } })
              }
              style={{ minWidth: '200px' }}
              disabled={loading}
            >
              {['AUTHORIZE', 'CASH', 'CHECK', 'CREDIT', 'CREDITCARD'].map(
                account => (
                  <MenuItem value={account}>{account}</MenuItem>
                )
              )}
            </TextField>
            <br />
            <DatePicker
              fullWidth
              name="dateReconciled"
              label="Date Reconciled"
              value={input.dateReconciled}
              onChange={e =>
                this.setState({
                  input: {
                    ...input,
                    dateReconciled: e.target.value.toISOString(),
                  },
                })
              }
              required
              disabled={loading}
            />
            <br />
            <DatePicker
              fullWidth
              name="FromDate"
              label="From Date"
              value={input.fromDate}
              onChange={e =>
                this.setState({
                  input: { ...input, fromDate: e.target.value.toISOString() },
                })
              }
              required
              disabled={loading}
            />
            <br />
            <DatePicker
              fullWidth
              name="ToDate"
              label="To Date"
              value={input.toDate}
              onChange={e =>
                this.setState({
                  input: { ...input, toDate: e.target.value.toISOString() },
                })
              }
              required
              disabled={loading}
            />
            <br />
            <TextField
              label="Amount"
              disabled
              value={`$ ${input.amount.toFixed(2)}`}
            />
            <br />
            <Button type="Submit" disabled={loading}>
              Reconcile
            </Button>
            <br />
            <Button onClick={() => this.props.history.push('/reconciliation')}>
              Back
            </Button>
          </form>
        </div>
        <Query
          query={GET_PAYMENTS}
          variables={{ filter: { reconciled: false } }}
        >
          {({ data, loading, error, refetch }) => {
            if (loading) return <LoadingMessage />
            if (error) return <ErrorMessage error={error} />
            return (
              <Flex align="left" column grow={1}>
                <PaymentTable
                  selected={this.state.selectedPayments}
                  payments={data.payments.filter(
                    p => p.method === input.account
                  )}
                  updateSelected={v => {
                    const filtered = v.filter(e => e)
                    this.setState({
                      selectedPayments: filtered,
                      input: {
                        ...input,
                        amount: filtered.reduce(
                          (accum, cur) => accum + cur.amount,
                          0
                        ),
                      },
                    })
                  }}
                  sorted={this.state.sorted}
                  filtered={this.state.filtered}
                  onFilteredChange={this.onFilteredChange}
                />
                <SnackbarNotification
                  open={snackbar.open}
                  handleClose={() => {
                    this.setState({
                      snackbar: {
                        ...snackbar,
                        open: false,
                      },
                    })
                    if (snackbar.windowReload) refetch()
                  }}
                  message={snackbar.message}
                  messageType={snackbar.messageType}
                />
              </Flex>
            )
          }}
        </Query>
      </div>
    )
  }
}

export default withApollo(withRouter(AddReconcilePage))
