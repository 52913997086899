import React from 'react'
import PropTypes from 'prop-types'
import MuiButton from '@material-ui/core/Button'

const Button = ({ children, label, ...otherProps }) => (
  <MuiButton
    variant="contained"
    color="primary"
    aria-label={label}
    {...otherProps}
  >
    {label || children}
  </MuiButton>
)

Button.propTypes = {
  label: PropTypes.string,
}

export default Button
