import React, { Component } from 'react'
import { Mutation, withApollo } from 'react-apollo'
import Composer from 'react-composer'
import { CountryRegionData } from 'react-country-region-selector'
import { Redirect } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import {
  Button,
  DemographicForm,
  AccordionForm,
  Flex,
  GuardianForm,
  OpportunityForm,
  Paper,
  SnackbarNotification,
  StudentForm,
  Text,
} from '../../components'

import { getDefaultSession } from '../../utils/defaultsession'
import { isValidPhoneNumber } from '../../utils/phonenumber'
import { userName } from '../../utils/username'

import {
  CREATE_LOCATION,
  CREATE_FAMILY_ACCOUNT,
  CREATE_FAMILY_MEMBER,
  CREATE_STUDENT,
  CREATE_OPPORTUNITY,
  UPDATE_FAMILY_ACCOUNT,
  GET_FAMILY_ACCOUNTS,
  GET_STUDENTS,
  GET_OPPORTUNITIES,
  //GET_FAMILY_MEMBER_BY_FILTER,
} from '../../queries'

import { GET_STUDENT } from './queries'
import Cookies from 'js-cookie'
import { idName } from '../../config'
import { COUNT_FAMILY_MEMBERS } from '../FamilyView/queries'

const styles = theme => ({
  root: {
    textAlign: 'left',
    margin: 'auto',
    width: '67%',
    minWidth: '600px',
    maxWidth: '1000px',
  },
  display1: {
    textAlign: 'left',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
})

const defaultState = {
  tabValue: 0,
  redirectToOpportunityTable: false,
  guardians: {
    primary: { phoneNumber: '', preferredLanguage: 'ENGLISH' },
    secondary: { phoneNumber: '', preferredLanguage: 'ENGLISH' },
  },
  student: {
    enrollmentStatus: 'PROSPECT',
    tShirtSize: 'N/A',
    over13: false,
    phoneNumber: '',
  },
  opportunity: {
    lastContacted: new Date(),
    openDate: new Date(),
    openDays: 0,
    session: getDefaultSession(),
    stage: 'ASSESSMENT',
    stageDate: new Date(),
    stageDays: 0,
    status: 'OPEN',
    year:
      getDefaultSession() === 'Winter'
        ? new Date().getUTCFullYear() + 1
        : new Date().getUTCFullYear(),
  },
  validation: {
    guardianEmail: {
      error: false,
      message: '',
    },
    guardianPhoneNumber: {
      error: false,
      message: '',
    },
  },
  //Default Family Information
  demographic: {
    status: 'PROSPECT',
    city: 'Irvine',
    zip: '92612',
    country: CountryRegionData.find(c => c[1] === 'US'),
    region: 'California',
  },
  snackbar: {
    open: false,
    message: '',
    messageType: '',
  },
}

class AddOpportunityView extends Component {
  constructor(props) {
    super(props)
    this.state = defaultState
    //Assign opportunity to who created it by default
    this.state.opportunity.responsibleEmployeeId = Cookies.get(idName)
  }

  validate = async (field, value, guardian = false) => {
    const { client } = this.props

    // ensure that phoneNumber and Email are not currently being used
    if (guardian && field === 'phoneNumber') {
      const phoneNumberString = value.replace(/\D+/g, '')
      if (phoneNumberString === '') {
        this.setState(state => ({
          validation: {
            ...state.validation,
            guardianPhoneNumber: {
              error: true,
              message: 'Phone Number is a required field',
            },
          },
        }))
      } else {
        const { data } = await client.query({
          query: COUNT_FAMILY_MEMBERS,
          variables: {
            filter: {
              phoneNumber: phoneNumberString,
            },
          },
        })

        this.setState(state => ({
          validation: {
            ...state.validation,
            guardianPhoneNumber: {
              error: data.countFamilyMembers || null,
              message: data.countFamilyMembers
                ? 'Phone Number is already in use'
                : '',
            },
          },
        }))
      }
    }
    if (guardian && field === 'email') {
      if (value === '') {
        this.setState(state => ({
          validation: {
            ...state.validation,
            guardianEmail: {
              error: true,
              message: 'Email is a required field',
            },
          },
        }))
      } else {
        const { data } = await client.query({
          query: COUNT_FAMILY_MEMBERS,
          variables: {
            filter: {
              email: value,
            },
          },
        })
        this.setState(state => ({
          validation: {
            ...state.validation,
            guardianEmail: {
              error: data.countFamilyMembers || null,
              message: data.countFamilyMembers ? 'Email is already in use' : '',
            },
          },
        }))
      }
    }
  }

  isValid = () => {
    const { validation } = this.state
    for (const field of Object.values(validation)) {
      if (field.error) {
        return false
      }
    }
    return true
  }

  returnToOpportunityTable = () => {
    this.setState({
      redirect: true,
      redirectTo: '/opportunity',
    })
  }

  redirectToOpportunityDetail = id => {
    this.setState({
      redirect: true,
      redirectTo: `/opportunity/${id}`,
    })
  }

  getStepContent = step => {
    switch (step) {
      case 0:
        return (
          <StudentForm
            required
            student={this.state.student}
            handleChange={this.handleStudentChange}
          />
        )
      case 1:
        return (
          <DemographicForm
            demographic={this.state.demographic}
            handleChange={this.handleDemographicChange}
          />
        )
      case 2:
        return (
          <GuardianForm
            primary={this.state.guardians.primary}
            secondary={this.state.guardians.secondary}
            handleChange={this.handleGuardianChange}
            languages={this.props.languages}
            validation={this.state.validation}
          />
        )
      case 3:
        return (
          <OpportunityForm
            opportunity={this.state.opportunity}
            handleChange={this.handleOpportunityChange}
            showSave={false}
          />
        )
      default:
        return 'Unknown step'
    }
  }

  handleGuardianChange = guardian => event => {
    const { name, value } = event.target
    this.validate(name, value, true)
    // if guardian isn't 'primary', it must be 'secondary'
    this.setState(state => ({
      guardians:
        guardian === 'primary'
          ? {
              ...state.guardians,
              primary: {
                ...state.guardians.primary,
                [name]:
                  name === 'phoneNumber' ? value.replace(/\D/g, '') : value,
              },
            }
          : {
              ...state.guardians,
              secondary: {
                ...state.guardians.secondary,
                [name]:
                  name === 'phoneNumber' ? value.replace(/\D/g, '') : value,
              },
            },
    }))
  }

  handleStudentChange = event => {
    const { name, value } = event.target
    const birthdate = new Date(value)
    const ageDifMs = Date.now() - birthdate.getTime()
    const ageDate = new Date(ageDifMs) // miliseconds from epoch
    this.setState(state => ({
      student:
        name === 'birthdate'
          ? {
              ...state.student,
              [name]: value,
              over13: Math.abs(ageDate.getUTCFullYear() - 1970) >= 13,
            }
          : {
              ...state.student,
              [name]: name === 'phoneNumber' ? value.replace(/\D/g, '') : value,
            },
    }))
  }

  handleOpportunityChange = event => {
    const { name, value } = event.target
    this.setState(state => ({
      opportunity: {
        ...state.opportunity,
        [name]: value,
      },
    }))
  }

  handleDemographicChange = event => {
    const { name, value } = event.target
    this.setState(state => ({
      demographic: {
        ...state.demographic,
        [name]: value,
      },
    }))
  }

  handleTabChange = (event, value) => {
    this.setState({
      tabValue: value,
    })
  }

  checkRequiredFields = () => {
    const { guardians, opportunity, student } = this.state
    if (
      !(
        opportunity.primaryInterest &&
        student.firstName &&
        student.lastName &&
        guardians.primary.firstName &&
        guardians.primary.lastName &&
        guardians.primary.email &&
        guardians.primary.phoneNumber
      )
    ) {
      this.setState({
        snackbar: {
          open: true,
          message: 'At least one required field is missing',
          messageType: 'error',
        },
      })
      return true
    }

    if (
      !(
        isValidPhoneNumber(guardians.primary.phoneNumber) &&
        isValidPhoneNumber(guardians.secondary.phoneNumber) &&
        isValidPhoneNumber(student.phoneNumber)
      )
    ) {
      this.setState({
        snackbar: {
          open: true,
          message: 'At least one phone number is an improper length',
          messageType: 'error',
        },
      })
      return true
    }

    return false
  }

  checkErrors = async () => {
    const { client } = this.props
    const error = this.checkRequiredFields()

    if (!error) {
      //Check is the email already exists
      // const { data: emails } = await client.query({
      //   query: GET_FAMILY_MEMBER_BY_FILTER,
      //   variables: { filter: { email: this.state.guardians.primary.email } },
      // })
      // if (emails.familyMembers.length !== 0) {
      //   alert(
      //     `Error: ${
      //       this.state.guardians.primary.email
      //     } is already in use. Please choose a different email`
      //   )
      //   return { error: true }
      // }

      //Check is the phoneNumber already exists
      // const { data: phoneNumbers } = await client.query({
      //   query: GET_FAMILY_MEMBER_BY_FILTER,
      //   variables: {
      //     filter: {
      //       phoneNumber: this.state.guardians.primary.phoneNumber,
      //     },
      //   },
      // })
      // if (phoneNumbers.familyMembers.length !== 0) {
      //   alert('Error: Phone Number is already in use')
      //   return { error: true }
      // }

      //Check is the username already exists
      const { firstName, lastName } = this.state.student
      const username = userName(firstName, lastName)
      const { data: usernames } = await client.query({
        query: GET_STUDENT,
        variables: { filter: { username: username } },
      })

      if (usernames.students.length !== 0) {
        this.setState({
          snackbar: {
            open: true,
            message: 'Default student username is already in use',
            messageType: 'error',
          },
        })
        return { error: true }
      }

      return { error: false, username }
    }
    return { error }
  }

  render() {
    const {
      demographic,
      guardians,
      opportunity,
      redirect,
      redirectTo,
      snackbar,
      student,
    } = this.state
    const {
      address,
      ardentCenter,
      city,
      country,
      maritalStatus,
      referrals,
      region,
      zip,
    } = demographic
    if (redirect) {
      return <Redirect push to={redirectTo} />
    }

    const { classes } = this.props
    const employeeId = Cookies.get(idName)
    const handleSubmission = async (
      CreateLocationFunction,
      CreateFamilyAccountFunction,
      CreateFamilyMemberFunction,
      CreateStudentFunction,
      CreateOpportunityFunction,
      UpdateFamilyAccountFunction,
      StudentUsername
    ) => {
      const CLreturn = await CreateLocationFunction({
        variables: {
          input: {
            street: address || '',
            city: city || '',
            state: region || '',
            country: country ? country[0] : '',
            zip: zip,
          },
        },
      })
      const CFAreturn = await CreateFamilyAccountFunction({
        variables: {
          input: {
            status: demographic.status,
            maritalStatus: maritalStatus,
            locationId: CLreturn.data.createLocation.id,
            referrals: referrals,
            centerLocationId: ardentCenter,
          },
        },
      })
      const familyAccountId = CFAreturn.data.createFamilyAccount.id
      const PrimaryCFMreturn = await CreateFamilyMemberFunction({
        variables: {
          input: {
            familyAccountId,
            firstName: guardians.primary.firstName,
            lastName: guardians.primary.lastName,
            preferredLanguage: guardians.primary.preferredLanguage,
            relation: guardians.primary.relationship,
            phoneNumber: guardians.primary.phoneNumber,
            email: guardians.primary.email,
          },
        },
      })
      // let SecondaryCFMreturn = null;
      if (guardians.secondary.firstName && guardians.secondary.lastName) {
        await CreateFamilyMemberFunction({
          variables: {
            input: {
              familyAccountId,
              firstName: guardians.secondary.firstName,
              lastName: guardians.secondary.lastName,
              preferredLanguage: guardians.secondary.preferredLanguage,
              relation: guardians.secondary.relationship,
              phoneNumber: guardians.secondary.phoneNumber,
              email: guardians.secondary.email,
            },
          },
        })
      }
      const CSreturn = await CreateStudentFunction({
        variables: {
          input: {
            familyAccountId,
            enrollmentStatus: student.enrollmentStatus,
            firstName: student.firstName,
            lastName: student.lastName,
            nickname: student.nickname,
            birthdate: new Date(student.birthdate),
            gender: student.gender,
            grade: student.grade,
            school: student.school,
            email: student.email,
            phoneNumber: student.phoneNumber,
            tShirtSize:
              student.tShirtSize !== 'N/A' ? student.tShirtSize : null,
            username: StudentUsername,
            password: 'student',
          },
        },
      })
      const COreturn = await CreateOpportunityFunction({
        variables: {
          input: {
            status: opportunity.status,
            primaryInterest: opportunity.primaryInterest,
            session: opportunity.session,
            year: parseInt(opportunity.year, 10),
            stage: opportunity.stage,
            openDate: new Date(opportunity.openDate),
            lastContacted: opportunity.lastContacted,
            source: opportunity.source,
            createdById: employeeId,
            responsibleEmployeeId: opportunity.responsibleEmployeeId,
            notes: opportunity.notes,
            studentId: CSreturn.data.createStudent.id,
          },
        },
      })
      await UpdateFamilyAccountFunction({
        variables: {
          id: familyAccountId,
          input: {
            primaryFamilyMemberId: PrimaryCFMreturn.data.createFamilyMember.id,
            familyMemberId: PrimaryCFMreturn.data.createFamilyMember.id,
            // SecondaryCFMreturn &&
            // SecondaryCFMreturn.data.createFamilyMember.id
          },
          refetchQueries: [
            {
              query: GET_OPPORTUNITIES,
            },
            {
              query: GET_FAMILY_ACCOUNTS,
            },
            {
              query: GET_STUDENTS,
            },
          ],
        },
      })
      this.redirectToOpportunityDetail(COreturn.data.createOpportunity.id)
    }

    return (
      <Flex column grow={1} className={classes.root}>
        <br />
        <Text className={classes.display1} variant="h4">
          Create New Opportunity
        </Text>
        <br />
        <Paper>
          <AccordionForm
            expanded
            title="Opportunity"
            form={this.getStepContent(3)}
          />
          <AccordionForm
            expanded
            title="Student"
            form={this.getStepContent(0)}
          />
          <AccordionForm
            expanded
            title="Guardian"
            form={this.getStepContent(2)}
          />
          <AccordionForm
            expanded
            title="Address"
            form={this.getStepContent(1)}
          />
          <Flex direction={'row-reverse'} p="10px">
            <Composer
              components={[
                <Mutation mutation={CREATE_LOCATION} />,
                <Mutation mutation={CREATE_FAMILY_ACCOUNT} />,
                <Mutation mutation={CREATE_FAMILY_MEMBER} />,
                <Mutation mutation={CREATE_STUDENT} />,
                <Mutation mutation={CREATE_OPPORTUNITY} />,
                <Mutation mutation={UPDATE_FAMILY_ACCOUNT} />,
              ]}
            >
              {([
                CreateLocationFunction,
                CreateFamilyAccountFunction,
                CreateFamilyMemberFunction,
                CreateStudentFunction,
                CreateOpportunityFunction,
                UpdateFamilyAccountFunction,
              ]) => (
                <Button
                  onClick={() => {
                    this.checkErrors().then(
                      ({ error, username: StudentUsername }) => {
                        if (
                          !error &&
                          window.confirm(
                            'Are you sure you want to submit? All empty fields will be filled with default or null values'
                          )
                        ) {
                          handleSubmission(
                            CreateLocationFunction,
                            CreateFamilyAccountFunction,
                            CreateFamilyMemberFunction,
                            CreateStudentFunction,
                            CreateOpportunityFunction,
                            UpdateFamilyAccountFunction,
                            StudentUsername
                          )
                        }
                      }
                    )
                  }}
                >
                  Submit
                </Button>
              )}
            </Composer>
            <Button
              color="secondary"
              aria-label="Cancel"
              className={classes.button}
              onClick={() => {
                if (
                  window.confirm(
                    'Do you want to return to the table of opportunities?'
                  )
                ) {
                  this.returnToOpportunityTable()
                }
              }}
              style={{ marginRight: '10px' }}
            >
              Cancel
            </Button>
          </Flex>
        </Paper>
        <SnackbarNotification
          open={snackbar.open}
          handleClose={() => {
            this.setState({
              snackbar: {
                ...snackbar,
                open: false,
              },
            })
          }}
          message={snackbar.message}
          messageType={snackbar.messageType}
        />
      </Flex>
    )
  }
}

export default withApollo(withStyles(styles)(AddOpportunityView))
