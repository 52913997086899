import React from 'react'

import Accordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Text from './Text'
import { accordionBarColor } from '../config'

const AccordionSummary = ({ title, ...otherProps }) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon />}
    style={{
      backgroundColor: accordionBarColor,
    }}
    {...otherProps}
  >
    <Text variant="subtitle1"> {title} </Text>
  </MuiAccordionSummary>
)

export { Accordion, AccordionSummary, AccordionDetails, ExpandMoreIcon }
