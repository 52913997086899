import React from 'react'
import { Query } from 'react-apollo'
import { CountryRegionData } from 'react-country-region-selector'
import gql from 'graphql-tag'

import { ErrorMessage, Flex, LoadingMessage, MenuItem, TextField } from './'
import { getRegions } from '../utils'

const DemographicForm = props => {
  const { demographic, handleChange } = props
  const {
    address,
    ardentCenter,
    city,
    country,
    notes,
    region,
    zip,
  } = demographic
  return (
    <Flex
      column
      style={{
        align: 'center',
        flexGrow: 1,
      }}
    >
      <form
        id="addDemographicForm"
        className={styles.container}
        onSubmit={e => {
          e.preventDefault()
        }}
      >
        <Flex justify="space-evenly">
          <Flex grow={2} m="20px" style={{ maxWidth: '300px' }} column>
            <TextField
              name="address"
              label="Address"
              value={address || ''}
              className={styles.textField}
              fullWidth
              onChange={handleChange}
            />
            <br />
            <TextField
              name="city"
              label="City"
              value={city || ''}
              className={styles.textField}
              fullWidth
              onChange={handleChange}
            />
            <br />
            <TextField
              name="zip"
              label="Zip Code"
              value={zip || ''}
              className={styles.textField}
              fullWidth
              onChange={handleChange}
            />
            <br />
            <Query
              query={GET_CENTER_LOCATIONS}
              variables={{ filter: { isPublished: true } }}
            >
              {({ data, loading, error }) => {
                if (loading) return <LoadingMessage />
                if (error) return <ErrorMessage error={error} />

                const ardentLocations = data.centerLocations.map(
                  ({ id, name }) => ({
                    name,
                    value: id,
                  })
                )

                return (
                  <TextField
                    name="ardentCenter"
                    label="Ardent Center"
                    value={ardentCenter}
                    className={styles.textField}
                    select
                    onChange={handleChange}
                    SelectProps={{
                      MenuProps: {
                        className: styles.menu,
                      },
                    }}
                    InputLabelProps={{
                      shrink: ardentCenter,
                    }}
                  >
                    {ardentLocations.map(option => (
                      <MenuItem key={option.name} value={option.value}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )
              }}
            </Query>
          </Flex>
          <Flex grow={2} m="20px" style={{ maxWidth: '300px' }} column>
            <TextField
              name="country"
              label="Country"
              value={country || ''}
              className={styles.textField}
              select
              onChange={handleChange}
              SelectProps={{
                MenuProps: {
                  className: styles.menu,
                },
              }}
              InputLabelProps={{
                shrink: country,
              }}
            >
              {CountryRegionData.map((option, index) => (
                <MenuItem key={option[0]} value={option}>
                  {option[0]}
                </MenuItem>
              ))}
            </TextField>
            <br />
            <TextField
              name="region"
              label="State/Region"
              value={region || ''}
              className={styles.textField}
              select
              onChange={handleChange}
              SelectProps={{
                MenuProps: {
                  className: styles.menu,
                },
              }}
              InputLabelProps={{
                shrink: region,
              }}
            >
              {getRegions(country).map((option, index) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <br />
            <TextField
              name="notes"
              label="Notes"
              multiline
              maxRows="4"
              value={notes || ''}
              className={styles.textField}
              fullWidth
              onChange={handleChange}
            />
          </Flex>
        </Flex>
      </form>
    </Flex>
  )
}

const GET_CENTER_LOCATIONS = gql`
  query getCenterLocations($filter: Filters) {
    centerLocations(filter: $filter) {
      id
      name
    }
  }
`

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: '5px',
    marginRight: '5px',
    padding: '5px',
    width: '20%',
  },
  menu: {
    width: '20%',
  },
})

export default DemographicForm
