import React, { useState, useEffect, useCallback, useReducer } from 'react'
import { withApollo } from 'react-apollo'

import {
  GET_REFERRALS,
  COUNT_REFERRALS,
  CREATE_REFERRALS,
  UPDATE_REFERRAL,
  ARCHIVE_REFERRALS,
  VERIFY_FAMILY_ACCOUNT,
} from './queries'
import { GET_FAMILY_ACCOUNTS } from '../StudentView/queries'
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  Flex,
  SnackbarNotification,
  Text,
} from '../../components'
import ReferralViewSidebar from './ReferralViewSidebar'
import ReferralTable from './ReferralTable'
import ReferralInfoDialog from './ReferralInfoDialog'
import ReferralAddDialog from './ReferralAddDialog'
import { convertPhoneNumber } from '../../utils'

// const INITIAL_REFERRAL = {
//   firstName: '',
//   lastName: '',
//   createdOn: new Date(),
//   expiresOn: new Date(),
//   status: '',
//   email: '',
//   phoneNumber: '',
// }

const INITIAL_REFEREE = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
}

const INITIAL_SNACKBAR = {
  open: false,
  message: '',
  messageType: '',
}

const referralReducer = (state, { key, value }) => {
  return { ...state, [key]: value }
}

const validateEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

const ContinueDialog = React.memo(
  ({ dialog, text, handleSubmit, handleClose }) => {
    return (
      <Dialog open={dialog.open} onClose={handleClose} maxWidth={'sm'}>
        <DialogTitle>
          <Text variant="subtitle1" style={{ textAlign: 'center' }}>
            {text}
          </Text>
        </DialogTitle>
        <Divider style={{ width: '100%' }} />
        <DialogActions>
          <Button onClick={handleSubmit}>Ok</Button>
          <Button color={'secondary'} onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
)

const ReferralView = props => {
  // const [referralState, referralDispatch] = useReducer(
  //   referralReducer,
  //   INITIAL_REFERRAL
  // )

  //add dialog states
  const [, setFamilyAccounts] = useState([])
  const [modal, setModal] = useState({
    open: false,
    continue: false,
  })
  const [addDialog, setAddDialog] = useState({
    open: false,
  })
  const [refereeFA, setRefereeFA] = useState(null)
  const [referrerFA, setReferrerFA] = useState(null)
  const [refereeName, setRefereeName] = useState('')
  const [referrerName, setReferrerName] = useState('')
  const [refereeState, refereeDispatch] = useReducer(
    referralReducer,
    INITIAL_REFEREE
  )

  //snackbar
  const [snackbar, setSnackbar] = useState(INITIAL_SNACKBAR)

  //table
  const [referrals, setReferrals] = useState([])
  const [referralCount, setReferralCount] = useState(0)
  const [selected, setSelected] = useState(null)
  const [filterable] = useState(true)
  const [sorted] = useState([
    {
      id: 'creationDate',
      desc: false,
    },
  ])
  const [filtered] = useState([
    {
      id: 'status',
      value: 'INITIATED',
    },
  ])
  // const [pageSize, setPageSize] = useState(25)
  // const [page, setPage] = useState(0)
  // const [pages, setPages] = useState()

  //info dialog
  const [dialog, setDialog] = useState({
    open: false,
  })

  // const getStatusFilter = () => {
  //   const statusFilterValue = this.state.filtered.find(
  //     filter => filter.id === 'enrollmentStatus'
  //   ).value
  //   return statusFilterValue === 'ALL'
  //     ? {}
  //     : { enrollmentStatus: statusFilterValue }
  // }

  const formatReferrals = useCallback(data => {
    return data.map(referral => {
      const {
        id,
        status,
        createdOn,
        lastModified,
        email,
        phone,
        referrer,
        referee,
      } = referral
      const firstName =
        referee && referee.primaryFamilyMember
          ? referee.primaryFamilyMember.firstName
          : ''
      const lastName =
        referee && referee.primaryFamilyMember
          ? referee.primaryFamilyMember.lastName
          : ''
      const referrerName = `${referrer.primaryFamilyMember.firstName} ${referrer.primaryFamilyMember.lastName}`
      return {
        ...referral,
        id: id,
        status: status,
        createdOn: createdOn,
        lastModified: lastModified,
        email: email,
        firstName: firstName,
        lastName: lastName,
        phoneNumber: phone || 'N/A',
        referrerName: referrerName,
      }
    })
  }, [])

  const fetchReferrals = useCallback(async () => {
    const client = props.client
    const { data: data1 } = await client.query({
      query: GET_REFERRALS,
    })
    setReferrals(formatReferrals(data1.referrals))
  }, [props.client, formatReferrals])

  const fetchReferralCount = useCallback(async () => {
    const { data } = await props.client.query({
      query: COUNT_REFERRALS,
    })
    setReferralCount(data.countReferrals)
  }, [props.client])

  //TODO: remove referred from family account status
  const handleArchiveReferral = async () => {
    if (selected) {
      handleSnackbarLoading('Archiving referral')
      const { data } = await props.client.mutate({
        mutation: ARCHIVE_REFERRALS,
        variables: {
          id: selected.id,
        },
      })
      if (data.archiveReferral) {
        handleRemoveReferral(data.archiveReferral.id)
        handleSnackbarSuccess('Archived referral')
        setSelected(null)
      } else {
        handleSnackbarError('Archiving referral failed')
      }
    }
  }

  const fetchFamilyAccounts = async () => {
    const { client } = props
    const {
      data: { familyAccounts },
    } = await client.query({
      query: GET_FAMILY_ACCOUNTS,
      variables: {
        filter: {
          primaryFamilyMember: {
            $name: referrerName,
          },
        },
        sort: {
          primaryFamilyMember: {
            firstName: 1,
            lastName: 2,
          },
        },
      },
    })
    setFamilyAccounts(familyAccounts)
    return familyAccounts.map(familyAccount => {
      const { firstName, lastName } = familyAccount.primaryFamilyMember
      return {
        value: familyAccount,
        label: `${firstName} ${lastName}`,
      }
    })
  }

  const handleRemoveReferral = referralId => {
    setReferrals(referrals.filter(ref => ref.id !== referralId))
  }

  const handleCloseSnackbar = useCallback(() => {
    setSnackbar(INITIAL_SNACKBAR)
  }, [])

  const handleSnackbarLoading = useCallback(message => {
    setSnackbar({
      open: true,
      message,
      messageType: 'loading',
    })
  }, [])

  const handleSnackbarSuccess = useCallback(message => {
    setSnackbar({
      open: true,
      message,
      messageType: 'successs',
    })
  }, [])

  const handleSnackbarError = useCallback(message => {
    setSnackbar({
      open: true,
      message,
      messageType: 'error',
    })
  }, [])

  const handleCloseDialog = () => {
    setDialog({
      ...dialog,
      open: false,
    })
  }

  const updateReferralTable = () => {}

  const handleOpenReferralInfo = () => {
    setDialog({
      ...dialog,
      open: true,
    })
  }

  const handleSelect = row => {
    if (selected && selected.index === row.index) {
      setSelected(null)
    } else {
      setSelected({
        ...row.original,
        index: row.index,
      })
    }
  }

  const handleOpenAddDialog = () => {
    setAddDialog({
      ...addDialog,
      open: true,
    })
  }

  const handleCloseAddDialog = () => {
    setAddDialog({
      ...addDialog,
      open: false,
    })
  }

  const verifyFamilyAccount = async () => {
    const { email, phoneNumber } = refereeState
    if (validateEmail(email)) {
      const filter = { $or: [] }
      email && filter['$or'].push({ email: email })
      phoneNumber && filter['$or'].push({ phone: phoneNumber })

      const { data: emailData } = await props.client.query({
        query: VERIFY_FAMILY_ACCOUNT,
        variables: {
          filter: filter,
        },
      })
      if (emailData.countFamilyMembers) {
        handleCloseAddDialog()
        handleOpenModal()
      } else {
        await handleCreateReferral()
      }
    } else {
      handleCloseAddDialog()
    }
  }

  const handleCreateReferral = async () => {
    const { firstName, lastName, email, phoneNumber } = refereeState
    const { client } = props
    handleSnackbarLoading('Creating referral')
    const data = await client.mutate({
      mutation: CREATE_REFERRALS,
      variables: {
        input: {
          referrerId: referrerFA.id,
          refereeFirstName: firstName,
          refereeLastName: lastName,
          status: 'INITIATED',
          email: email,
          phone: convertPhoneNumber(phoneNumber) || null,
        },
      },
    })
    if (data) {
      handleSnackbarSuccess('Created referral')
      const newReferral = formatReferrals([data.data.createReferral])
      referrals.push(newReferral[0])
      setReferrals([...referrals])
      setReferralCount(referralCount + 1)
      handleCloseAddDialog()
    } else {
      handleSnackbarError('Creating referral failed')
    }
  }

  const handleOpenEditDialog = () => {
    setDialog({
      ...dialog,
      type: 'edit',
      open: true,
    })
  }

  const handleChangeSelected = e => {
    setSelected({
      ...selected,
      [e.target.name]: e.target.value,
    })
  }

  const handleUpdateReferral = async () => {
    const { client } = props
    handleSnackbarLoading('Updating referral')
    if (dialog.data) {
      const {
        data: { data },
      } = await client.mutate({
        mutation: UPDATE_REFERRAL,
        variables: {
          id: dialog.data.id,
          input: dialog.data,
        },
      })
      if (data) handleSnackbarSuccess('Updating referral')
    } else {
      handleSnackbarError('Updating referral failed')
    }
  }

  const handleRefereeChange = e => {
    refereeDispatch({ key: e.target.name, value: e.target.value })
  }

  const handleOpenModal = () => {
    setModal({
      open: true,
      continue: false,
    })
  }

  const handleCloseModal = () => {
    setModal({
      ...modal,
      open: false,
    })
  }

  // const handlePressModal = proceed => {
  //   setModal({
  //     open: false,
  //     continue: proceed,
  //   })
  // }

  //Initial query
  useEffect(() => {
    fetchReferrals()
    fetchReferralCount()
  }, [fetchReferrals, fetchReferralCount])

  return (
    <Flex>
      <ReferralViewSidebar
        referralsCount={referralCount}
        selected={selected}
        handleOpenReferralInfo={handleOpenReferralInfo}
        handleOpenEditDialog={handleOpenEditDialog}
        handleArchiveReferral={handleArchiveReferral}
        handleOpenAddDialog={handleOpenAddDialog}
      />
      <Flex align="left" column grow={1}>
        <ReferralTable
          selected={selected}
          referrals={referrals}
          sorted={sorted}
          filterable={filterable}
          filtered={filtered}
          updateReferralTable={updateReferralTable}
          fetchReferrals={fetchReferrals}
          handleSelect={handleSelect}
        />
      </Flex>
      {selected && (
        <ReferralInfoDialog
          dialog={dialog}
          data={selected}
          handleUpdateReferral={handleUpdateReferral}
          handleCloseDialog={handleCloseDialog}
          handleChangeSelected={handleChangeSelected}
        />
      )}
      <ReferralAddDialog
        dialog={addDialog}
        referee={refereeFA}
        referrer={referrerFA}
        referrerName={referrerName}
        refereeName={refereeName}
        refereeState={refereeState}
        setRefereeName={setRefereeName}
        setReferrerName={setReferrerName}
        setReferee={setRefereeFA}
        setReferrer={setReferrerFA}
        handleCloseAddDialog={handleCloseAddDialog}
        fetchFamilyAccounts={fetchFamilyAccounts}
        handleCreateReferral={verifyFamilyAccount}
        handleRefereeChange={handleRefereeChange}
      />
      <SnackbarNotification
        open={snackbar.open}
        handleClose={handleCloseSnackbar}
        message={snackbar.message}
        messageType={snackbar.messageType}
      />
      <ContinueDialog
        dialog={modal}
        text={
          'A referee account with this email or phone number already exists. Do you still want to add?'
        }
        handleClose={handleCloseModal}
      />
    </Flex>
  )
}

export default withApollo(ReferralView)
