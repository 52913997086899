import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { Box, Collapse, Tab, Tabs, Tooltip } from '@mui/material'

import withStyles from './Styles'

import HomeIcon from '@mui/icons-material/Home'
import InboxIcon from '@mui/icons-material/Inbox'
import AssessmentIcon from '@mui/icons-material/Assessment'
import EventAvailableIcon from '@mui/icons-material/EventAvailable'
import AssignmentIcon from '@mui/icons-material/Assignment'
import ContactsIcon from '@mui/icons-material/Contacts'
import PersonIcon from '@mui/icons-material/Person'
import EventIcon from '@mui/icons-material/Event'
import EventBusyIcon from '@mui/icons-material/EventBusy'
import HowToRegIcon from '@mui/icons-material/HowToReg'
import ScheduleIcon from '@mui/icons-material/Schedule'
import GradeIcon from '@mui/icons-material/Grade'
import SchoolIcon from '@mui/icons-material/School'
import ErrorIcon from '@mui/icons-material/Error'
import AnnouncementIcon from '@mui/icons-material/Announcement'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import ReceiptIcon from '@mui/icons-material/Receipt'
import PaymentIcon from '@mui/icons-material/Payment'
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark'
import CompareArrowsIcon from '@mui/icons-material/CompareArrows'
import TitleIcon from '@mui/icons-material/Title'
import ContactMailIcon from '@mui/icons-material/ContactMail'

let tabs = [
  'home',
  'opportunity',
  'assessment',
  'trial',
  'task',
  'family',
  'student',
  'classroom',
  'makeup',
  'attendance',
  'grade',
  'lesson',
  'counseling',
  'issue',
  'announcement',
  'posts',
  'referral',
  'finance',
  'tutor',
]

const TabBar = props => {
  const [openFinance, setOpenFinance] = useState(false)
  const [openTutor, setOpenTutor] = useState(false)
  const iconMap = new Map([
    ['Home', <HomeIcon />],
    ['Opportunity', <InboxIcon />],
    ['Assessment', <AssessmentIcon />],
    ['Trial', <EventAvailableIcon />],
    ['Task', <AssignmentIcon />],
    ['Family', <ContactsIcon />],
    ['Student', <PersonIcon />],
    ['Class', <EventIcon />],
    ['Makeup', <EventBusyIcon />],
    ['Attendance', <HowToRegIcon />],
    ['Grade', <GradeIcon />],
    ['Lesson', <ScheduleIcon />],
    ['Counseling', <SchoolIcon />],
    ['Issue', <ErrorIcon />],
    ['Announcement', <AnnouncementIcon />],
    ['Posts', <CollectionsBookmarkIcon />],
    ['Referral', <ContactMailIcon />],
    ['Finance', <AttachMoneyIcon />],
    ['Tutor', <TitleIcon />],
  ])

  const financeIconMap = new Map([
    ['invoice', <ReceiptIcon />],
    ['payment', <PaymentIcon />],
    ['credit', <AccountBalanceIcon />],
    ['reconciliation', <CompareArrowsIcon />],
  ])

  const tutorIconMap = new Map([
    ['tutor_student', <PersonIcon />],
    ['tutor_class', <EventIcon />],
    ['tutor_employees', <TitleIcon />],
  ])

  const determineTabValue = () => {
    const matchedPath = props.path.match(/^\/[\w]*/)[0].substring(1)

    const index = tabs.indexOf(matchedPath)

    return index === -1 ? false : index
  }

  const determineCollapseTabValue = keys => {
    const matchedPath = props.path.match(/^\/[\w]*/)[0].substring(1)
    const ind = keys.indexOf(matchedPath)
    return ind >= 0 ? ind : false
  }

  const { classes } = props
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          classes={{
            root: classes.tabsRoot,
            indicator: classes.tabsIndicator,
          }}
          value={determineTabValue()}
          onChange={(e, v) => {
            switch (v) {
              case iconMap.size - 1:
                setOpenFinance(false)
                setOpenTutor(!openTutor)
                break
              case iconMap.size - 2:
                setOpenTutor(false)
                setOpenFinance(!openFinance)
                break
              default:
                setOpenFinance(false)
                setOpenTutor(false)
            }
          }}
          variant="scrollable"
        >
          {[...iconMap.keys()].map(tabName => {
            if (tabName === 'Finance' || tabName === 'Tutor') {
              return (
                <Tab
                  classes={{ root: classes.tabRoot }}
                  style={{ pointerEvents: 'auto' }}
                  label={
                    <Tooltip title={tabName}>{iconMap.get(tabName)}</Tooltip>
                  }
                  key={tabName}
                />
              )
            }
            return (
              <Tab
                classes={{ root: classes.tabRoot }}
                style={{ pointerEvents: 'auto' }}
                label={
                  <Tooltip title={tabName}>{iconMap.get(tabName)}</Tooltip>
                }
                component={Link}
                key={tabName}
                to={`/${
                  tabName === 'Class' ? 'classroom' : tabName.toLowerCase()
                }`}
              />
            )
          })}
        </Tabs>

        <Collapse in={openFinance}>
          <Tabs
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
            value={determineCollapseTabValue([...financeIconMap.keys()])}
            variant="scrollable"
          >
            {[...financeIconMap.keys()].map(tabName => {
              return (
                <Tab
                  classes={{ root: classes.tabRoot }}
                  style={{ pointerEvents: 'auto' }}
                  label={
                    <Tooltip title={tabName}>
                      {financeIconMap.get(tabName)}
                    </Tooltip>
                  }
                  component={Link}
                  key={tabName}
                  to={`/${tabName.toLowerCase()}`}
                />
              )
            })}
          </Tabs>
        </Collapse>

        <Collapse in={openTutor}>
          <Tabs
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
            value={determineCollapseTabValue([...tutorIconMap.keys()])}
            variant="scrollable"
          >
            {[...tutorIconMap.keys()].map(tabName => {
              return (
                <Tab
                  classes={{ root: classes.tabRoot }}
                  style={{ pointerEvents: 'auto' }}
                  label={
                    <Tooltip title={tabName}>
                      {tutorIconMap.get(tabName)}
                    </Tooltip>
                  }
                  component={Link}
                  key={tabName}
                  to={`/${tabName.toLowerCase()}`}
                />
              )
            })}
          </Tabs>
        </Collapse>
      </Box>
    </Box>
  )
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
  tabsRoot: {
    maxHeight: '48px',
  },
  tabsIndicator: {
    backgroundColor: theme.palette.primary.main,
  },
  tabRoot: {
    maxHeight: '24px',
    minWidth: '70px',
  },
})

export default withStyles(styles)(TabBar)
