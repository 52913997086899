import React, { Component } from 'react'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import { convertToMDY, dateFilterMethod } from '../../utils/datetime'

import {
  TABLE_ROW_HIGHLIGHT_COLOR,
  TABLE_ROW_BACKGROUND_COLOR,
  CLASS_SCHEDULED,
} from '../../config'

class MakeupTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectAll: false,
      checked: this.props.makeups.map(() => {
        return false
      }),
    }
  }

  handleSingleCheckboxChange = index => {
    const { checked, selectAll } = this.state
    let newChecked = checked
    newChecked[index] = !checked[index]
    this.setState({
      selectAll: !newChecked[index] && selectAll ? false : selectAll,
      checked: newChecked,
    })
    let newIdArray = []
    for (const each in this.state.checked) {
      if (this.state.checked[each]) {
        newIdArray.push(this.props.makeups[each].id)
      }
    }
    this.props.setCheckedId(newIdArray)
  }

  render() {
    const { checked } = this.state
    const columns = [
      {
        Cell: row => (
          <input
            type="checkbox"
            checked={checked[row.index]}
            onChange={() => this.handleSingleCheckboxChange(row.index)}
          />
        ),
        sortable: false,
        filterable: false,
        resizable: false,
        maxWidth: 28,
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: 135,
        Cell: props => {
          return <span>{props.value ? props.value : 'UNKNOWN'}</span>
        },
        filterMethod: (filter, row) => {
          if (filter.value !== 'ALL') {
            let filterValue = filter.value
            return row[filter.id] === filterValue
          }
          if (filter.value === 'UNKNOWN') {
            return row[filter.id] === null
          }
          return true
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: '100%' }}
            value={filter ? filter.value : 'all'}
          >
            <option value="ALL">All</option>
            <option value="SCHEDULED">SCHEDULED</option>
            <option value="ATTENDED">ATTENDED</option>
            <option value="ABSENT">ABSENT</option>
          </select>
        ),
      },
      {
        Header: 'First Name',
        accessor: 'firstName',
      },
      {
        Header: 'Last Name',
        accessor: 'lastName',
        filterMethod: (filter, row) => {
          return (
            row[filter.id] &&
            row[filter.id].toLowerCase().startsWith(filter.value.toLowerCase())
          )
        },
      },
      {
        Header: 'Class',
        accessor: 'fromClass',
        width: 85,
      },
      {
        Header: 'Lesson',
        accessor: 'fromLesson',
        width: 60,
      },
      {
        Header: 'From Date',
        accessor: 'fromDate',
        Cell: props => <span>{props.value && convertToMDY(props.value)}</span>,
        width: 120,
        filterMethod: dateFilterMethod,
      },
      {
        Header: 'To Class',
        accessor: 'toClass',
        Cell: props => <span>{props.value || 'Absent'}</span>,
        width: 85,
      },
      {
        Header: 'To Date',
        accessor: 'toDate',
        Cell: props => (
          <span>{(props.value && convertToMDY(props.value)) || 'Absent'}</span>
        ),
        width: 120,
        filterMethod: dateFilterMethod,
      },
      {
        Header: 'To Time',
        accessor: 'toTime',
        width: 180,
      },
      {
        Header: 'Created by',
        accessor: 'createdBy',
      },
      {
        Header: 'Requested On',
        accessor: 'createdOn',
        Cell: props => <span>{props.value && convertToMDY(props.value)}</span>,
        width: 120,
        filterMethod: dateFilterMethod,
      },
    ]

    const {
      filtered,
      filterable,
      makeups,
      onFilteredChange,
      onRowClick,
      sorted,
    } = this.props

    return (
      <div align="left">
        <ReactTable
          filterable={filterable}
          data={makeups}
          columns={columns}
          className="-highlight"
          defaultPageSize={25}
          defaultSorted={sorted}
          defaultFiltered={filtered}
          onFilteredChange={onFilteredChange}
          style={{ fontSize: '10pt' }}
          defaultFilterMethod={(filter, row) =>
            row[filter.id] &&
            row[filter.id]
              .toString()
              .toLowerCase()
              .includes(filter.value.toString().toLowerCase())
          }
          getTrProps={(state, rowInfo, column) => {
            if (
              rowInfo &&
              rowInfo.row.status === CLASS_SCHEDULED &&
              rowInfo.row.startDate < new Date()
            ) {
              return { style: { background: TABLE_ROW_HIGHLIGHT_COLOR } }
            }
            return { style: { background: TABLE_ROW_BACKGROUND_COLOR } }
          }}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              onClick: (e, handleOriginal) => {
                if (column.Header && column.Header !== 'Email') {
                  onRowClick(rowInfo)
                }
                if (handleOriginal) {
                  handleOriginal()
                }
              },
            }
          }}
          getTheadProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                fontWeight: 'bold',
                borderBottom: '1px solid #E5E5E5',
              },
            }
          }}
        />
      </div>
    )
  }
}

export default MakeupTable
