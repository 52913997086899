import React, { useEffect, useState } from 'react'

import { Box, Button, Divider, Paper, Stack, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { sideBarStyles } from '../../utils/style'

import { Droppable, Draggable } from 'react-beautiful-dnd'

import { SidebarText, SidebarContainer } from '../../components'

const useStyles = makeStyles(sideBarStyles)

const TutorStudentDetailSidebar = ({
  unscheduledSessions,
  classroom,
  enrolledOn,
  completionStatus,
  tutorSessions,
  paymentInfo,
  getItemStyle,
  getSidebarListStyle,
  handleCompletionStatus,
  handleOpenCreateDialog,
  handleOpenRenewalDialog,
}) => {
  const classes = useStyles()

  const [status, setStatus] = useState(completionStatus)
  const [disableCompletion, setDisableCompletion] = useState(true)
  const [disableReactivate, setDisableReactivate] = useState(true)
  const [disableIncomplete, setDisableIncompete] = useState(true)
  const [totalTutorSessions, setTotalTutorSessions] = useState(0)
  const [totalCompleted, setTotalCompleted] = useState(0)
  const [totalNotCompleted, setTotalNotCompleted] = useState(0)
  const [totalUnscheduled, setTotalUnscheduled] = useState(0)
  const [tobeScheduled, setTobeScheduled] = useState()

  const courseCode = classroom ? classroom.courseCode : ''
  const courseTitle = classroom ? classroom.courseTitle : ''

  useEffect(() => {
    const totalCompleted = tutorSessions.reduce((accumulator, tutorSession) => {
      return (
        accumulator +
        (tutorSession.status !== 'SCHEDULED' &&
        tutorSession.status !== 'UNKNOWN'
          ? 1
          : 0)
      )
    }, 0)
    const totalNotCompleted = tutorSessions.reduce(
      (accumulator, tutorSession) => {
        return accumulator + (tutorSession.status === 'SCHEDULED' ? 1 : 0)
      },
      0
    )
    const totalUnscheduled = tutorSessions.reduce(
      (accumulator, tutorSession) => {
        return accumulator + (tutorSession.status === 'UNKNOWN' ? 1 : 0)
      },
      0
    )

    setTotalTutorSessions(tutorSessions.length)
    setTotalCompleted(totalCompleted)
    setTotalNotCompleted(totalNotCompleted)
    setTotalUnscheduled(totalUnscheduled)
    setTobeScheduled(totalUnscheduled > 0)
  }, [tutorSessions])

  useEffect(() => {
    setDisableCompletion(
      status === 'COMPLETED' ||
        (status === 'ACTIVE' && (totalNotCompleted > 0 || totalUnscheduled > 0))
    )
    setDisableIncompete(
      status === 'INCOMPLET' ||
        (status === 'ACTIVE' && (totalNotCompleted > 0 || totalUnscheduled > 0))
    )
    setDisableReactivate(status === 'ACTIVE')
  }, [status, totalNotCompleted, totalUnscheduled])

  const onCompletionStatus = status => {
    handleCompletionStatus(status)
    setStatus(status)
  }

  return (
    <SidebarContainer>
      <Stack spacing={1} direction="column">
        <Button
          variant="contained"
          fullWidth={true}
          onClick={handleOpenCreateDialog}
        >
          Create Tutor Session
        </Button>{' '}
        <SidebarText
          classes={{ root: classes.headline }}
          title="Status:"
          value={status}
        />
        <Button
          variant="contained"
          disabled={disableCompletion}
          fullWidth={true}
          onClick={handleOpenRenewalDialog}
        >
          Create Renewal Invoice
        </Button>
        <Button
          variant="contained"
          disabled={disableReactivate}
          fullWidth={true}
          onClick={() => onCompletionStatus('ACTIVE')}
        >
          Reactivate Class
        </Button>
        <Button
          variant="contained"
          disabled={disableCompletion}
          fullWidth={true}
          onClick={() => onCompletionStatus('COMPLETED')}
        >
          Class Completed
        </Button>
        <Button
          variant="contained"
          disabled={disableIncomplete}
          fullWidth={true}
          onClick={() => onCompletionStatus('INCOMPLET')}
        >
          Class Incomplete
        </Button>
      </Stack>
      <SidebarText
        classes={{
          root: classes.headline,
        }}
        title="Class Information"
      />
      <SidebarText
        classes={{ root: classes.subheadingTwo }}
        title="Course Code: "
        value={courseCode}
      />
      <SidebarText
        classes={{ root: classes.subheadingTwo }}
        title="Title:"
        value={courseTitle}
      />
      <SidebarText
        classes={{ root: classes.subheadingTwo }}
        title="Enrolled On:"
        value={enrolledOn}
      />
      <Divider style={{ width: '100%' }} />
      <SidebarText
        classes={{
          root: classes.headline,
        }}
        title="Session Summary"
      />
      <SidebarText
        classes={{ root: classes.subheadingTwo }}
        title="Total Paid:"
        value={totalTutorSessions}
      />
      <SidebarText
        classes={{ root: classes.subheadingTwo }}
        title="Total Completed:"
        value={totalCompleted}
      />
      <SidebarText
        classes={{ root: classes.subheadingTwo }}
        title="Total Outstanding:"
        value={totalNotCompleted}
      />
      <SidebarText
        classes={{ root: classes.subheadingTwo }}
        title="Total Unscheduled:"
        value={totalUnscheduled}
      />
      <Divider style={{ width: '100%' }} />
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {tobeScheduled && (
          <SidebarText
            classes={{
              root: classes.headline,
            }}
            title="Drag a session onto calendar to schedule"
          />
        )}
        <Droppable droppableId={'unscheduled'}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getSidebarListStyle(snapshot.isDraggingOver)}
            >
              {provided.placeholder}
              {unscheduledSessions &&
                unscheduledSessions
                  .sort((a, b) => {
                    return a.order - b.order
                  })
                  .map((session, index) => (
                    <Draggable
                      key={session.id}
                      draggableId={session.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          {provided.placeholder}
                          <Paper
                            elevation={3}
                            style={{
                              display: 'flex',
                              width: '180px',
                              height: '36.5px',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <Typography
                              color="secondary"
                              style={{ fontWeight: 'bold' }}
                            >
                              {`${courseCode} - ${session.order
                                .toString()
                                .padStart(2, '0')}`}
                            </Typography>
                          </Paper>
                        </div>
                      )}
                    </Draggable>
                  ))}
            </div>
          )}
        </Droppable>
      </Box>
    </SidebarContainer>
  )
}

export default TutorStudentDetailSidebar
