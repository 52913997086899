import React, { Component } from 'react'
import { Query } from 'react-apollo'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Flex,
  TextField,
  LoadingMessage,
  MenuItem,
} from '../../components'
import { GET_EMPLOYEES, UPDATE_EMPLOYEE } from './queries'
import { withApollo } from 'react-apollo'

class AddTutorDialog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errorMsg: null,
      newTutorId: null,
    }
  }

  handleSubmit = async () => {
    const { client } = this.props
    const { newTutorId } = this.state
    if (!newTutorId) this.setState({ errorMsg: 'No Employee Selected' })
    else {
      await client.mutate({
        mutation: UPDATE_EMPLOYEE,
        variables: {
          id: newTutorId,
          input: { isTutor: true },
        },
      })
      this.setState({ errorMsg: null, newTutorId: null })
      this.props.triggerRefetch()
      this.props.close()
    }
  }

  render() {
    const { newTutorId, errorMsg } = this.state
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.close}
        fullWidth={true}
      >
        <DialogTitle>{'Add Tutor'}</DialogTitle>
        <DialogContent>
          {errorMsg && <div style={{ color: 'red' }}>{errorMsg}</div>}
          <Query
            query={GET_EMPLOYEES}
            fetchPolicy={'no-cache'}
            variables={{
              filter: {
                status: 'ACTIVE',
                $or: [{ isTutor: { $exists: false } }, { isTutor: false }],
              },
              sort: { firstName: 1 },
            }}
          >
            {({ loading, data, error, refetch }) => {
              if (loading) return <LoadingMessage />
              else if (error) this.setState({ errorMsg: error })
              const activeEmployees = data.employees
              return (
                <Flex column>
                  <TextField
                    label="Select Employee"
                    name="tutor"
                    select
                    value={newTutorId || ''}
                    onChange={event =>
                      this.setState({ newTutorId: event.target.value })
                    }
                  >
                    {activeEmployees.map(e => (
                      <MenuItem key={e.id} value={e.id}>
                        {e.firstName + ' ' + e.lastName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Flex>
              )
            }}
          </Query>
        </DialogContent>
        <DialogActions style={{ paddingBottom: '10px', paddingRight: '10px' }}>
          <Button color="primary" onClick={this.handleSubmit}>
            Add
          </Button>
          <Button onClick={this.props.close} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withApollo(AddTutorDialog)
