import React, { Component } from 'react'
import { Mutation, Query, withApollo } from 'react-apollo'
import { Link, Redirect } from 'react-router-dom'

import {
  Button,
  ErrorMessage,
  AccordionForm,
  Flex,
  LoadingMessage,
  SnackbarNotification,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Text,
  SMSDialogButton,
  StudentDetailForm,
  ImageFileUploader,
} from '../../components'

import {
  GET_STUDENT,
  UPDATE_STUDENT,
  ARCHIVE_STUDENT,
  GET_FAMILY_ACCOUNT,
  GET_STUDENTS,
} from '../../queries'
import StudentApproveImageDialog from './StudentApproveImageDialog'

class StudentDetailView extends Component {
  render() {
    return (
      <Query
        fetchPolicy={'cache-and-network'}
        query={GET_STUDENT}
        variables={{ id: this.props.match.params[0] }}
      >
        {({ loading, error, data }) => {
          if (loading) return <LoadingMessage />
          if (error) return <ErrorMessage error={error} />
          !data.student.familyAccount &&
            window.alert(
              'Error: this student has currently has no assigned family account. Please choose a family account to assign to this student or go into the tab and make a new family.'
            )
          return data.student.familyAccount ? (
            <InnerStudentDetailView {...this.props} student={data.student} />
          ) : (
            <Redirect
              push
              to={`/student/${data.student.id}/change-family-account`}
            />
          )
        }}
      </Query>
    )
  }
}

class InnerStudentDetailView extends Component {
  constructor(props) {
    super(props)
    let student = props.student

    this.state = {
      redirect: false,
      snackbar: {
        open: false,
        message: '',
        messageType: '',
        willReload: 0, //0 false, 1 reload, 2 redirect
      },
      student: {
        ...student,
      },
      dialog: {
        open: false,
      },
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.student.image !== this.props.student.image) {
      this.setState({
        student: this.props.student,
      })
    }
  }

  isEnrolled = status => {
    return status === 'OPENED' || status === 'PUBLISHED'
  }

  updateProfileImage = async newImageKey => {
    const { client, student } = this.props
    const { id } = student
    await client.mutate({
      mutation: UPDATE_STUDENT,
      variables: {
        id: id,
        input: {
          image: newImageKey,
        },
      },
      refetchQueries: [
        {
          query: GET_STUDENT,
          variables: { id: this.props.match.params[0] },
        },
      ],
    })
  }

  handleCloseDialog = () => {
    const { dialog } = this.state
    dialog.open = false
    this.setState({ dialog })
  }

  handleOpenDialog = () => {
    const { dialog } = this.state
    dialog.open = true
    this.setState({ dialog })
  }

  render() {
    const { redirect, redirectTo, snackbar, student } = this.state
    const {
      enrollments,
      familyAccount,
      image,
      imageUrl,
      newImage,
      newImageUrl,
      opportunities,
    } = student
    const { primaryFamilyMember } = familyAccount
    if (redirect) {
      return <Redirect push to={redirectTo} />
    }
    return (
      <Flex
        column
        grow={1}
        style={{
          textAlign: 'left',
          margin: 'auto',
          width: '67%',
          minWidth: '600px',
          maxWidth: '1000px',
        }}
      >
        <br />
        <Link to="/student">{'< back to Student List'}</Link>
        <br />
        <Flex justify="space-between">
          <Flex column maxwidth="850px">
            <Text variant="h4">
              {`${student.firstName} ${student.lastName}`}
            </Text>
            <br />
            <Flex>
              <Mutation mutation={ARCHIVE_STUDENT}>
                {(archiveStudent, { data }) => (
                  <Button
                    color="secondary"
                    aria-label="Archive"
                    onClick={() => {
                      if (
                        window.confirm(
                          'Are you sure want to archive this student?'
                        )
                      ) {
                        archiveStudent({
                          variables: {
                            id: student.id,
                          },
                          refetchQueries: [
                            { query: GET_STUDENTS },
                            {
                              query: GET_FAMILY_ACCOUNT,
                              variables: {
                                id: familyAccount.id,
                              },
                            },
                          ],
                        })
                        this.setState({
                          snackbar: {
                            open: true,
                            message: 'Student archived',
                            messageType: 'success',
                            willReload: 2, //redirect
                          },
                        })
                      }
                    }}
                  >
                    Archive Student
                  </Button>
                )}
              </Mutation>
              <Button
                aria-label="cfa"
                component={Link}
                style={{ textAlign: 'center', marginLeft: '5px' }}
                to={`/student/${student.id}/change-family-account`}
              >
                Change Family
              </Button>
              <Button
                aria-label="co"
                component={Link}
                style={{ textAlign: 'center', marginLeft: '5px' }}
                to={`/opportunity/create-opportunity-from-student/${student.id}`}
              >
                Create Opportunity
              </Button>
              <Button
                aria-label="ca"
                component={Link}
                style={{ textAlign: 'center', marginLeft: '5px' }}
                to={`/task/add-new-task-from-student/${student.id}`}
              >
                Create Task
              </Button>
              <Button
                aria-label="ca"
                component={Link}
                style={{ textAlign: 'center', marginLeft: '5px' }}
                to={`/makeup/schedule-makeup/${student.id}`}
              >
                Schedule Makeup
              </Button>
              <SMSDialogButton
                label={`Enter a text message that will be sent as a SMS to the student's guardian`}
                phoneNumbers={[primaryFamilyMember.phoneNumber]}
                style={{ marginLeft: '5px', marginRight: '5px' }}
                title="Text Guardian"
              />
              {newImage !== null && newImageUrl !== null && (
                <Button
                  aria-label="asi"
                  style={{ textAlign: 'center', marginRight: '5px' }}
                  onClick={this.handleOpenDialog}
                >
                  Approve Student Image
                </Button>
              )}
            </Flex>
          </Flex>
          {image && imageUrl && (
            <ImageFileUploader
              setImage={newImg => {
                this.updateProfileImage(newImg)
              }}
              client={this.props.client}
              imageKey={image}
              src={imageUrl}
              alt="student"
              style={{
                maxHeight: '100px',
                maxWidth: '100px',
              }}
            />
          )}
        </Flex>
        <StudentApproveImageDialog
          dialog={this.state.dialog}
          client={this.props.client}
          handleCloseDialog={this.handleCloseDialog}
          student={student}
        />
        <AccordionForm
          expanded
          title="Student Info"
          form={<StudentDetailForm student={student} />}
        />

        <AccordionForm
          expanded={opportunities.length > 0}
          title="Opportunities Info"
          form={
            opportunities.length > 0 ? (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Status</TableCell>
                    <TableCell>Session</TableCell>
                    <TableCell>Primary Interest</TableCell>
                    <TableCell>Assigned To</TableCell>
                    <TableCell>Created By</TableCell>
                    <TableCell>Notes</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {opportunities.map(opportunity => {
                    return (
                      <TableRow
                        hover
                        key={opportunity.id}
                        onClick={() =>
                          this.setState({
                            redirect: true,
                            redirectTo: `/opportunity/${opportunity.id}`,
                          })
                        }
                      >
                        <TableCell>{opportunity.status}</TableCell>
                        <TableCell>
                          {opportunity.session &&
                            `${opportunity.session} ${opportunity.year}`}
                        </TableCell>
                        <TableCell>{opportunity.primaryInterest}</TableCell>
                        <TableCell>
                          {opportunity.responsibleEmployee &&
                            `${opportunity.responsibleEmployee.firstName} ${opportunity.responsibleEmployee.lastName}`}
                        </TableCell>
                        <TableCell>
                          {opportunity.createdBy &&
                            `${opportunity.createdBy.firstName} ${opportunity.createdBy.lastName}`}
                        </TableCell>
                        <TableCell>{opportunity.notes}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            ) : (
              <Text variant="h4">
                There are no opportunities associated with this student
              </Text>
            )
          }
        />

        <AccordionForm
          expanded={enrollments.length > 0}
          title="Enrollments Info"
          form={
            enrollments.length > 0 ? (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Status</TableCell>
                    <TableCell>Location</TableCell>
                    <TableCell>Quarter</TableCell>
                    <TableCell>Year</TableCell>
                    <TableCell>Course Code</TableCell>
                    <TableCell>Course Name</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {enrollments.map(enrollment => {
                    const { classroom } = enrollment
                    return (
                      <TableRow
                        hover
                        key={enrollment.id}
                        onClick={() =>
                          this.setState({
                            redirect: true,
                            redirectTo: `/classroom/${classroom.id}`,
                          })
                        }
                      >
                        <TableCell>
                          {this.isEnrolled(enrollment.classroom.status) ? (
                            <span style={{ color: '#27AE60' }}>
                              {'Enrolled'}
                            </span>
                          ) : (
                            <span style={{ color: '#00ADEF' }}>
                              {'Completed'}
                            </span>
                          )}
                        </TableCell>
                        <TableCell>{classroom.centerLocation.name}</TableCell>
                        <TableCell>{classroom.quarter}</TableCell>
                        <TableCell>{classroom.year}</TableCell>
                        <TableCell>{classroom.course.code}</TableCell>
                        <TableCell>{classroom.course.title}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            ) : (
              <Text variant="h4">
                There are no enrollments associated with this student
              </Text>
            )
          }
        />
        <SnackbarNotification
          open={snackbar.open}
          handleClose={() => {
            this.setState({
              snackbar: {
                ...snackbar,
                open: false,
              },
            })
            switch (snackbar.willReload) {
              case 0: //do nothing
                break
              case 1: //reload
                window.reload()
                break
              case 2: //redirect
                this.returnToStudentTable()
                break
              case 3: //window.location
                window.location.reload()
                break
              default:
                break
            }
          }}
          message={snackbar.message}
          messageType={snackbar.messageType}
        />
      </Flex>
    )
  }
}

export default withApollo(StudentDetailView)
