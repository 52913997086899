import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'

import {
  Button,
  Divider,
  Flex,
  SidebarText,
  withStyles,
} from '../../components'
import { sideBarStyles } from '../../utils/style'

class TrialSidebar extends Component {
  constructor(props) {
    super(props)
    this.state = { redirect: false }
  }

  scheduleTrial = () => {
    this.setState({
      redirect: true,
      redirectTo: '/trial/create-trial',
    })
  }

  render() {
    const { redirect, redirectTo } = this.state
    if (redirect) {
      return <Redirect push to={redirectTo} />
    }

    const { classes, menu, show, trials } = this.props

    const trialByLesson = new Array(10).fill(0)
    trials.forEach(element => {
      trialByLesson[(element.lessonNumber - 1) % 10]++
    })

    return (
      <Flex
        column
        grow={0}
        shrink={0}
        overflow="hidden"
        p="10px"
        style={{
          width: show ? '15.38%' : 0,
          borderRight: `${show ? '1px' : 0} ${'#aaaaaa'} solid`,
          maxWidth: show ? '200px' : 0,
          overflowX: show ? undefined : 'hidden',
          flexShrink: 0,
          textAlign: 'left',
          backgroundColor: '#e5e5e5',
        }}
      >
        <Flex column grow={1} justify="space-between">
          <Flex column>
            <Button
              aria-label="ScheduleTrial"
              disabled={true}
              onClick={this.scheduleTrial}
            >
              Schedule Trial
            </Button>
            <SidebarText
              classes={{ root: classes.headline }}
              title="Detail View"
            />
            <Divider />
            <SidebarText
              classes={{ root: classes.subheadingOne }}
              title="Total Trials:"
              value={trials.length}
            />
            {trialByLesson.map((tbw, index) => {
              if (!tbw) return ''
              return (
                <SidebarText
                  key={index}
                  title={`Lesson ${index + 1}:`}
                  value={tbw}
                />
              )
            })}
          </Flex>
          {menu()}
        </Flex>
      </Flex>
    )
  }
}

export default withStyles(sideBarStyles)(TrialSidebar)
