import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

import PostsView from './PostsView'
import PostsDetailView from './PostsDetailView'

class PostsViewRouter extends Component {
  render() {
    const url = this.props.match.url
    return (
      <Switch>
        <Route path={`${url}/*`} component={PostsDetailView} />
        <Route path={url} component={PostsView} />
      </Switch>
    )
  }
}

export default PostsViewRouter
