import React, { Component } from 'react'
import Flex from './Flex'

import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core'
import { expansionBarColor } from '../config'
import { GET_LESSONS_FROM_CLASS } from '../queries'
import { Query } from 'react-apollo'
import { convertToMDY, formatDayTimeRange } from '../utils/datetime'
import Typography from '@material-ui/core/Typography'

class TrialForm extends Component {
  isTrialComplete = () => {
    return this.props.trial.status === 'ATTENDED'
  }
  isTrialNoShow = () => {
    return this.props.trial.status === 'NOSHOW'
  }

  render() {
    const styles = this.props.classes

    return (
      <Flex
        column
        style={{
          align: 'center',
          flexGrow: 1,
        }}
      >
        <form id="addTrialForm">
          <Flex justify="space-evenly">
            <Flex grow={2} m={'7px'} style={{ maxWidth: '400px' }} column>
              <TextField
                required
                select
                id="toClass"
                label="To Class"
                value={this.props.trial && this.props.trial.toClassId}
                className={styles.textField}
                onChange={this.props.handleChange('toClassId')}
                disabled={
                  this.isTrialComplete() ||
                  this.isTrialNoShow() ||
                  this.props.fromTrialDetailView
                }
                SelectProps={{
                  MenuProps: {
                    className: styles.menu,
                  },
                }}
                InputLabelProps={{
                  formlabelclasses: {
                    asterisk: styles.asterisk,
                  },
                  shrink: this.props.trial.toClassId !== undefined,
                }}
              >
                {this.props.fromTrialDetailView && (
                  <MenuItem
                    key={this.props.trial.toClassId}
                    value={this.props.trial.toClassId}
                  >
                    {this.props.trial.toLesson.classroom.title}
                  </MenuItem>
                )}
                {this.props.toClasses.map(toClass => (
                  <MenuItem key={toClass.id} value={toClass.id}>
                    {toClass.title}
                  </MenuItem>
                ))}
              </TextField>
              <br />

              {this.props.trial.toClassId && (
                <Query
                  query={GET_LESSONS_FROM_CLASS}
                  variables={{ id: this.props.trial.toClassId }}
                >
                  {({ loading, error, data }) => {
                    if (loading)
                      return (
                        <Typography style={{ padding: '20px' }} variant="h5">
                          Loading...
                        </Typography>
                      )
                    if (error)
                      return (
                        <TextField
                          required
                          InputProps={{
                            readOnly: this.props.readOnly,
                          }}
                          id="toLesson"
                          label="To Lesson"
                          value={this.props.trial.toLessonId}
                          className={styles.textField}
                          select
                          onChange={this.props.handleChange('toLessonId')}
                          disabled={
                            this.props.readOnly ||
                            this.props.fromTrialDetailView
                          }
                          SelectProps={{
                            MenuProps: {
                              className: styles.menu,
                            },
                          }}
                          InputLabelProps={{
                            formlabelclasses: {
                              asterisk: styles.asterisk,
                            },
                            shrink: this.props.trial.toLessonId !== undefined,
                          }}
                        ></TextField>
                      )
                    const lessonsCopy = [...data.classroom.lessons]
                    if (lessonsCopy.length <= 0) {
                      alert(
                        'The classroom you selected has no lessons. Please select another one.'
                      )
                    }
                    const toLessons = lessonsCopy.sort(
                      (a, b) => a.lessonPlan.order - b.lessonPlan.order
                    )

                    const toLessonChoices = toLessons.map(lesson => {
                      return {
                        key: lesson.id,
                        value: lesson,
                      }
                    })
                    return (
                      <TextField
                        required
                        InputProps={{
                          readOnly: this.props.readOnly,
                        }}
                        id="toLesson"
                        label="To Lesson"
                        value={this.props.trial.toLessonId}
                        className={styles.textField}
                        select
                        onChange={this.props.handleChange('toLessonId')}
                        disabled={
                          this.props.readOnly ||
                          this.isTrialComplete() ||
                          this.isTrialNoShow() ||
                          this.props.fromTrialDetailView
                        }
                        SelectProps={{
                          MenuProps: {
                            className: styles.menu,
                          },
                        }}
                        InputLabelProps={{
                          formlabelclasses: {
                            asterisk: styles.asterisk,
                          },
                          shrink: this.props.trial.toLessonId !== undefined,
                        }}
                      >
                        {toLessonChoices.map(lessonChoice => (
                          <MenuItem
                            key={lessonChoice.key}
                            value={lessonChoice.key}
                            // disabled={
                            //   new Date(lessonChoice.value.startOn) < new Date()
                            // }
                          >
                            {''
                              .concat(lessonChoice.value.lessonPlan.order)
                              .concat('. ')
                              .concat(convertToMDY(lessonChoice.value.startOn))
                              .concat(', ')
                              .concat(
                                formatDayTimeRange(
                                  new Date(lessonChoice.value.startOn),
                                  new Date(lessonChoice.value.endOn)
                                )
                              )}
                          </MenuItem>
                        ))}
                      </TextField>
                    )
                  }}
                </Query>
              )}
            </Flex>
            <Flex grow={2} m={'7px'} style={{ maxWidth: '400px' }} column>
              <TextField
                required
                InputProps={{
                  readOnly: this.props.readOnly,
                }}
                id="status"
                label="Status"
                value={this.props.trial.status}
                className={styles.textField}
                select
                onChange={this.props.handleChange('status')}
                disabled={this.props.readOnly}
                SelectProps={{
                  MenuProps: {
                    className: styles.menu,
                  },
                }}
                InputLabelProps={{
                  formlabelclasses: {
                    asterisk: styles.asterisk,
                  },
                  shrink: this.props.trial.status !== undefined,
                }}
              >
                {['Scheduled', 'Rescheduled', 'Noshow', 'Completed'].map(
                  option => (
                    <MenuItem
                      key={option}
                      value={option.toUpperCase().replace(/ /g, '_')}
                    >
                      {option}
                    </MenuItem>
                  )
                )}
              </TextField>
              <br />
              {this.props.showCreatedBy && (
                <TextField
                  InputProps={{
                    readOnly: true,
                  }}
                  id="createdBy"
                  label="Created By"
                  value={
                    this.props.trial.createdBy &&
                    this.props.trial.createdBy.firstName
                      .concat(' ')
                      .concat(this.props.trial.createdBy.lastName)
                  }
                  className={styles.textField}
                  InputLabelProps={{
                    shrink: this.props.trial.createdBy !== null,
                  }}
                />
              )}
              {this.props.showCreatedBy && <br />}
              <TextField
                id="notes"
                label="Notes"
                InputProps={{
                  readOnly: this.props.readOnly,
                }}
                multiline
                maxRows="4"
                value={this.props.trial.notes}
                className={styles.textField}
                onChange={this.props.handleChange('notes')}
              />
            </Flex>
          </Flex>
        </form>
      </Flex>
    )
  }
}

const styles = theme => ({
  menu: {
    width: '20%',
  },
  expSummary: {
    backgroundColor: expansionBarColor,
  },
  asterisk: {
    color: 'red',
  },
})

export default withStyles(styles)(TrialForm)
