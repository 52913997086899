import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Flex,
  ProfilePicture,
} from '../../components'
import { ButtonIcon } from './Icons'
import { cleanUpData, getTitles, NonIconColumns } from './utils'
import AttendanceDialog from './AttendanceDialog'

const TableCellStyle = {
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: 'lightgrey',
  padding: '10px 10px 10px 10px',
}
const AttendanceTable = props => {
  const [open, setOpen] = React.useState(false)
  const [id, setId] = React.useState('')
  const handleClickOpen = id => {
    setOpen(true)
    setId(id)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const { classroom } = props.data
  const { lessons } = classroom
  lessons.sort((a, b) => a.lessonPlan.order - b.lessonPlan.order)

  const data = cleanUpData(lessons)
  const titles = getTitles(lessons)
  const lightArdentBlue = '#E7F0FD'

  //Sorting students by last name
  data.sort((a, b) => a.student.lastName.localeCompare(b.student.lastName))

  return (
    <Table stickyHeader style={{ width: 'auto' }}>
      <TableHead>
        <TableRow>
          {titles.map(column => (
            <TableCell
              key={column.accessor}
              align={column.align}
              style={TableCellStyle}
            >
              {column.Header}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((row, index) => (
          <TableRow key={index}>
            {titles.map(column => {
              const value = row[column.accessor]
              if (column.accessor === 'student')
                return (
                  <TableCell
                    key={column.accessor}
                    align={column.align}
                    style={{ ...TableCellStyle, width: '300px' }}
                  >
                    <Flex align="center">
                      <ProfilePicture
                        src={value.image}
                        firstName={value.firstName}
                        lastName={value.lastName}
                        gender={value.gender}
                      />
                      <Flex style={{ marginLeft: '2em' }}>
                        {value.lastName + ', ' + value.firstName}
                      </Flex>
                    </Flex>
                  </TableCell>
                )
              if (NonIconColumns(column))
                return (
                  <TableCell
                    key={column.accessor}
                    align={column.align}
                    style={{ ...TableCellStyle, width: '100px' }}
                  >
                    {value}
                  </TableCell>
                )
              return (
                <TableCell
                  key={column.accessor}
                  align={column.align}
                  style={{
                    ...TableCellStyle,
                    backgroundColor: value.isMakeup && lightArdentBlue,
                    padding: '0px',
                    width: '150px',
                  }}
                >
                  <ButtonIcon
                    attendeeId={value.id}
                    status={value.status}
                    onClick={() => handleClickOpen(value.id)}
                    notePresent={value.note === null ? false : true}
                  />
                </TableCell>
              )
            })}
          </TableRow>
        ))}
      </TableBody>
      {open && (
        <AttendanceDialog
          open={open}
          handleClose={handleClose}
          attendeeId={id}
          classroomId={props.id}
          data={data}
          refetch={props.refetch}
        />
      )}
    </Table>
  )
}

export default AttendanceTable
