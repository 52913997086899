import React, { Component } from 'react'
import ReactTable from 'react-table'
import { convertToMDY } from '../../utils/datetime'

export default class PaymentTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: new Array(props.payments.length),
    }
  }

  render() {
    const columns = [
      {
        Cell: row =>
          this.props.readOnly ? (
            <div></div>
          ) : (
            <input
              type="checkbox"
              checked={this.state.selected[row.index] || false}
              onChange={() => {
                let temp = this.state.selected
                temp[row.index] = temp[row.index] ? undefined : row.original
                this.props.updateSelected(temp)
                this.setState({ checked: temp })
              }}
            />
          ),
        sortable: false,
        filterable: false,
        resizable: false,
        width: 28,
      },
      {
        Header: 'Trans ID',
        accessor: 'identification',
      },
      {
        Header: 'Invoice #',
        accessor: 'invoice',
        Cell: row => <div>{row.value.invoiceNumber}</div>,
      },
      {
        Header: 'Payee',
        accessor: 'payee',
      },
      {
        Header: 'Date received',
        accessor: 'dateReceived',
        Cell: props => (
          <span>{props.value ? convertToMDY(props.value) : 'N/A'}</span>
        ),
        width: 150,
      },
      {
        Header: 'Amount',
        accessor: 'amount',
      },
    ]
    const { filtered, sorted, onFilteredChange } = this.props
    return (
      <div align="left">
        <ReactTable
          filterable={true}
          data={this.props.payments}
          columns={columns}
          className="-highlight"
          defaultPageSize={25}
          defaultSorted={sorted}
          defaultFiltered={filtered}
          onFilteredChange={onFilteredChange}
          style={{ fontSize: '10pt' }}
          defaultFilterMethod={(filter, row) =>
            row[filter.id] !== null &&
            row[filter.id]
              .toString()
              .toLowerCase()
              .includes(filter.value.toString().toLowerCase())
          }
          getTrProps={(state, rowInfo, column) => {
            let background = '#ffffff'
            return {
              style: {
                background,
              },
            }
          }}
          getTheadProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                fontWeight: 'bold',
                borderBottom: '1px solid #E5E5E5',
              },
            }
          }}
        />
      </div>
    )
  }
}
