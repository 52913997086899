import React, { Component } from 'react'
import { Mutation, Query, withApollo } from 'react-apollo'
import Composer from 'react-composer'
import { Redirect } from 'react-router-dom'
import { CountryRegionData } from 'react-country-region-selector'
import update from 'immutability-helper'

import {
  Button,
  DatePicker,
  Divider,
  ErrorMessage,
  Flex,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormHelperText,
  GradeSelector,
  Input,
  InputLabel,
  LoadingMessage,
  MenuItem,
  Radio,
  RadioGroup,
  SnackbarNotification,
  Tab,
  Tabs,
  Text,
  TextField,
} from './'

import { expansionBarColor } from '../config'
import { PhoneNumberInput, getRegions, numberInputValidate } from '../utils'
import { formatBirthday } from '../utils/datetime'
import { isValidPhoneNumber } from '../utils/phonenumber'

import {
  CREATE_LOCATION,
  UPDATE_LOCATION,
  UPDATE_STUDENT,
  UPDATE_FAMILY_MEMBER,
  UPDATE_FAMILY_ACCOUNT,
  UPDATE_OPPORTUNITY,
  GET_FAMILY_ACCOUNT,
  GET_STUDENTS,
  GET_FAMILY_ACCOUNTS,
  GET_FILTERED_FAMILY_ACCOUNTS,
} from '../queries'

import {
  GET_STUDENT,
  REQUEST_STUDENT_BY_USERNAME,
} from '../screens/StudentView/queries'

const StudentDetailForm = props => {
  return (
    <Query query={GET_STUDENT} variables={{ id: props.student.id }}>
      {({ loading, error, data }) => {
        if (loading) return <LoadingMessage />
        if (error) return <ErrorMessage />
        return <InnerStudentDetailForm {...props} student={data.student} />
      }}
    </Query>
  )
}

class InnerStudentDetailForm extends Component {
  constructor(props) {
    super(props)
    let student = props.student
    let primaryFamilyMemberChanged = false
    if (!student.familyAccount.primaryFamilyMember) {
      if (student.familyAccount.familyMembers.length > 0)
        primaryFamilyMemberChanged = true
      student = {
        ...student,
        familyAccount: {
          ...student.familyAccount,
          primaryFamilyMember:
            student.familyAccount.familyMembers.length > 0
              ? student.familyAccount.familyMembers[0]
              : {},
        },
      }
    }
    let needsLocationCreation = false
    if (!student.familyAccount.location) {
      needsLocationCreation = true
      student = {
        ...student,
        familyAccount: {
          ...student.familyAccount,
          location: {
            country: 'United States',
            state: 'California',
          },
        },
      }
    }
    //  const birthdate = new Date(student.birthdate);
    const birthdate = new Date(student.birthdate) //convertToCenterDate(student.birthdate);
    const ageDifMs = Date.now() - birthdate.getTime()
    const ageDate = new Date(ageDifMs)
    this.state = {
      redirect: false,
      studentTabValue: 0,
      studentChanged: false,
      familyMemberChanged: false,
      familyAccountChanged: false,
      primaryFamilyMemberChanged,
      needsLocationCreation,
      student: {
        ...student,
        birthdate: student.birthdate && formatBirthday(student.birthdate),
        over13: student.birthdate
          ? Math.abs(ageDate.getUTCFullYear() - 1970) >= 13
          : false,
        password: '',
      },
      actualCountry: CountryRegionData.find(
        c => c[0] === student.familyAccount.location.country
      ),
      studentValidation: {
        username: {
          error: false,
          message: '',
        },
        phoneNumber: {
          error: false,
          message: '',
        },
        email: {
          error: false,
          message: '',
        },
      },
      guardianValidation: {
        email: {
          error: false,
          message: '',
        },
        phoneNumber: {
          error: false,
          message: '',
        },
      },
      snackbar: {
        open: false,
        message: '',
        messageType: '',
        willReload: 0, //0 false, 1 reload, 2 redirect
      },
    }
  }

  checkMissing = missingList => {
    const notFound = []
    for (const [key, value] of Object.entries(missingList)) {
      if (!value) notFound.push(key)
    }
    return notFound.length !== 0
      ? window.confirm(
          `The following fields are empty. Are you sure you want to create the student in eLearning? \n${notFound.join(
            ', '
          )}`
        )
      : true
  }

  validateStudent = async (field, value) => {
    const { client } = this.props
    const { student } = this.state

    const sameStudent = (existingFirstName, existingLastName) => {
      return (
        existingFirstName === student.firstName &&
        existingLastName === student.lastName
      )
    }
    if (field === 'username') {
      const { data } = await client.query({
        query: REQUEST_STUDENT_BY_USERNAME,
        variables: {
          username: value,
        },
      })
      const {
        exists,
        firstName: existingFirstName,
        lastName: existingLastName,
      } = data.requestStudentByUsername
      const error = exists && !sameStudent(existingFirstName, existingLastName)
      this.setState(state => ({
        studentValidation: {
          ...state.studentValidation,
          username: {
            error,
            message: error ? 'Username is already in use' : '',
          },
        },
      }))
    }
    if (field === 'phoneNumber') {
      const phoneNumberString = value.replace(/\D+/g, '')

      const { data } = await client.query({
        query: GET_STUDENTS,
        variables: {
          filter: {
            phoneNumber: phoneNumberString,
          },
        },
      })
      if (!isValidPhoneNumber(phoneNumberString)) {
        this.setState(state => ({
          studentValidation: {
            ...state.studentValidation,
            phoneNumber: {
              error: true,
              message: 'Improper phone number length',
            },
          },
        }))
      } else {
        this.setState(state => ({
          studentValidation: {
            ...state.studentValidation,
            phoneNumber: {
              error: data.students.length || null,
              message: data.students.length
                ? 'Phone Number is already in use'
                : '',
            },
          },
        }))
      }
    }
    if (field === 'email') {
      const { data } = await client.query({
        query: GET_STUDENTS,
        variables: {
          filter: {
            email: value,
          },
        },
      })
      this.setState(state => ({
        studentValidation: {
          ...state.studentValidation,
          email: {
            error: data.students.length || null,
            message: data.students.length ? 'Email is already in use' : '',
          },
        },
      }))
    }
  }

  isValidStudent = () => {
    const { studentValidation } = this.state
    for (const field of Object.values(studentValidation)) {
      if (field.error) {
        return false
      }
    }
    return true
  }

  validateGuardian = async (field, value) => {
    const { client } = this.props

    if (field === 'email') {
      const { data } = await client.query({
        query: GET_FILTERED_FAMILY_ACCOUNTS,
        variables: {
          filter: {
            primaryFamilyMember: {
              email: value,
            },
          },
        },
      })
      this.setState(state => ({
        guardianValidation: {
          ...state.guardianValidation,
          email: {
            error: data.familyAccounts.length || null,
            message: data.familyAccounts.length
              ? 'Email is already in use'
              : '',
          },
        },
      }))
    }

    if (field === 'phoneNumber') {
      const phoneNumberString = value.replace(/\D+/g, '')

      const { data } = await client.query({
        query: GET_FILTERED_FAMILY_ACCOUNTS,
        variables: {
          filter: {
            primaryFamilyMember: {
              phoneNumber: phoneNumberString,
            },
          },
        },
      })
      if (!isValidPhoneNumber(phoneNumberString)) {
        this.setState(state => ({
          guardianValidation: {
            ...state.guardianValidation,
            phoneNumber: {
              error: true,
              message: 'Improper phone number length',
            },
          },
        }))
      } else {
        this.setState(state => ({
          guardianValidation: {
            ...state.guardianValidation,
            phoneNumber: {
              error: data.familyAccounts.length || null,
              message: data.familyAccounts.length
                ? 'Phone Number is already in use'
                : '',
            },
          },
        }))
      }
    }
  }

  isValidGuardian = () => {
    const { guardianValidation } = this.state
    for (const field of Object.values(guardianValidation)) {
      if (field.error) {
        return false
      }
    }
    return true
  }

  handleTabChange = (event, value) => {
    this.setState({
      studentTabValue: value,
    })
  }

  handleChange = event => {
    const { name, value } = event.target
    const birthdate = new Date(value)
    const ageDifMs = Date.now() - birthdate.getTime()
    const ageDate = new Date(ageDifMs) // miliseconds from epoch

    this.validateStudent(name, value)

    this.setState(state => ({
      studentChanged: true,
      student: {
        ...state.student,
        [name]: name === 'phoneNumber' ? value.replace(/\D/g, '') : value,
        over13:
          name === 'birthdate'
            ? Math.abs(ageDate.getUTCFullYear() - 1970) >= 13
            : state.student.over13,
      },
    }))
  }

  handleFamilyMemberChange = index => event => {
    const {
      familyMembers,
      primaryFamilyMember,
    } = this.state.student.familyAccount

    const { name, value } = event.target
    this.validateGuardian(name, value)

    this.setState(state => ({
      familyMemberChanged: true,
      student: {
        ...state.student,
        familyAccount: {
          ...state.student.familyAccount,
          familyMembers: update(state.student.familyAccount.familyMembers, {
            [index]: {
              [name]: {
                $set: name === 'phoneNumber' ? value.replace(/\D/g, '') : value,
              },
            },
          }),
          primaryFamilyMember:
            familyMembers[index].id === primaryFamilyMember.id
              ? {
                  ...state.student.familyAccount.primaryFamilyMember,
                  [name]:
                    name === 'phoneNumber' ? value.replace(/\D/g, '') : value,
                }
              : state.student.familyAccount.primaryFamilyMember,
        },
      },
    }))
  }

  handleFamilyAccountChange = event => {
    const { name, value } = event.target
    this.setState(state => ({
      familyAccountChanged: true,
      student: {
        ...state.student,
        familyAccount: {
          ...state.student.familyAccount,
          [name]: value,
        },
      },
    }))
  }

  handleLocationChange = event => {
    const { name, value } = event.target
    let actualCountry = this.state.actualCountry
    if (name === 'country') {
      actualCountry = CountryRegionData.find(c => c[0] === value)
    }
    this.setState(state => ({
      actualCountry,
      familyAccountChanged: true,
      student: {
        ...state.student,
        familyAccount: {
          ...state.student.familyAccount,
          location: {
            ...state.student.familyAccount.location,
            [name]: value,
          },
        },
      },
    }))
  }

  setAsPrimary = familyMember => {
    this.setState(state => ({
      primaryFamilyMemberChanged: true,
      student: {
        ...state.student,
        familyAccount: {
          ...state.student.familyAccount,
          primaryFamilyMember: familyMember,
        },
      },
    }))
  }

  returnToStudentTable = () => {
    this.setState({
      redirect: true,
      redirectTo: '/student',
    })
  }

  handleFamilyMemberSubmission = async (UFMmutation, UFAmutation) => {
    const { primaryFamilyMemberChanged, student } = this.state
    const { familyAccount } = student
    familyAccount.familyMembers.map(async familyMember => {
      await UFMmutation({
        variables: {
          id: familyMember.id,
          input: {
            firstName: familyMember.firstName,
            lastName: familyMember.lastName,
            relation: familyMember.relation,
            phoneNumber: familyMember.phoneNumber,
            email: familyMember.email,
            occupation: familyMember.occupation,
            educationLevel: familyMember.educationLevel,
          },
        },
        refetchQueries: primaryFamilyMemberChanged
          ? []
          : [
              {
                query: GET_FAMILY_ACCOUNT,
                variables: { id: familyAccount.id },
              },
              {
                query: GET_FAMILY_ACCOUNTS,
              },
            ],
      })
    })
    if (primaryFamilyMemberChanged) {
      await UFAmutation({
        variables: {
          id: familyAccount.id,
          input: {
            familyMemberId: familyAccount.primaryFamilyMember.id,
            primaryFamilyMemberId: familyAccount.primaryFamilyMember.id,
          },
        },
        refetchQueries: [
          {
            query: GET_FAMILY_ACCOUNT,
            variables: { id: familyAccount.id },
          },
          {
            query: GET_FAMILY_ACCOUNTS,
          },
        ],
      })
    }
    this.setState({
      primaryFamilyMemberChanged: false,
      familyMemberChanged: false,
    })
  }

  handleFamilyAccountSubmission = async (UFAmutation, locationMutation) => {
    const { needsLocationCreation, student } = this.state
    const { familyAccount } = student
    const { location } = familyAccount
    const locationReturn = await locationMutation({
      variables: {
        id: location.id,
        input: {
          street: location.street,
          city: location.city,
          country: location.country,
          state: location.state,
          zip: location.zip,
        },
      },
    })

    if (needsLocationCreation) {
      await UFAmutation({
        variables: {
          id: familyAccount.id,
          input: {
            status: familyAccount.status,
            locationId: locationReturn.data.createLocation.id,
          },
        },
        refetchQueries: [
          {
            query: GET_FAMILY_ACCOUNT,
            variables: { id: familyAccount.id },
          },
          {
            query: GET_FAMILY_ACCOUNTS,
          },
        ],
      })
    } else {
      await UFAmutation({
        variables: {
          id: familyAccount.id,
          input: {
            status: familyAccount.status,
          },
        },
        refetchQueries: [
          {
            query: GET_FAMILY_ACCOUNT,
            variables: { id: familyAccount.id },
          },
          {
            query: GET_FAMILY_ACCOUNTS,
          },
        ],
      })
    }
    this.setState({
      familyAccountChanged: false,
      needsLocationCreation: false,
    })
  }

  styles = theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: '5px',
      marginRight: '5px',
      padding: '5px',
      width: '20%',
    },
    menu: {
      width: '20%',
    },
    expSummary: {
      backgroundColor: expansionBarColor,
    },
  })

  render() {
    const { client } = this.props
    const {
      actualCountry,
      familyAccountChanged,
      familyMemberChanged,
      needsLocationCreation,
      primaryFamilyMemberChanged,
      redirect,
      redirectTo,
      snackbar,
      student,
      studentChanged,
      studentTabValue,
      studentValidation,
      guardianValidation,
    } = this.state
    const { enrollmentStatus, familyAccount } = student
    const { familyMembers, location, primaryFamilyMember } = familyAccount
    if (redirect) {
      return <Redirect push to={redirectTo} />
    }

    return (
      <Flex
        column
        grow={1}
        style={{
          textAlign: 'left',
          margin: 'auto',
          width: '67%',
          minWidth: '600px',
          maxWidth: '1000px',
        }}
      >
        <Flex
          column
          style={{
            align: 'center',
            flexGrow: 1,
          }}
        >
          <Tabs
            value={studentTabValue}
            onChange={this.handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            centered
            style={{
              paddingBottom: '10px',
            }}
          >
            <Tab label="Student" />
            <Tab label="Guardians" />
            <Tab label="Family" />
          </Tabs>

          {studentTabValue === 0 && ( //Student tab selected
            <Composer
              components={[
                <Mutation mutation={UPDATE_STUDENT}>
                  {function() {
                    return ''
                  }}
                </Mutation>,
                <Mutation mutation={UPDATE_OPPORTUNITY}>
                  {function() {
                    return ''
                  }}
                </Mutation>,
              ]}
            >
              {([updateStudent, updateOpportunity]) => (
                <Flex
                  column
                  style={{
                    align: 'center',
                    flexGrow: 1,
                  }}
                >
                  <form
                    id="editStudentForm"
                    className={this.styles.container}
                    onSubmit={async e => {
                      e.preventDefault()
                      let input = {
                        enrollmentStatus: enrollmentStatus,
                        firstName: student.firstName,
                        middleName: student.middleName,
                        lastName: student.lastName,
                        username: student.username,
                        birthdate: student.birthdate
                          ? new Date(student.birthdate)
                          : null,
                        gender: student.gender,
                        grade: student.grade,
                        school: student.school,
                        email: student.email,
                        phoneNumber: student.phoneNumber,
                        tShirtSize: student.tShirtSize,
                      }

                      if (student.password)
                        input = {
                          ...input,
                          password: student.password,
                        }

                      const { data } = await client.query({
                        query: GET_STUDENTS,
                        variables: {
                          filter: {
                            username: student.username,
                          },
                        },
                      })
                      if (
                        data.students.length !== 0 &&
                        data.students[0].id !== student.id
                      ) {
                        alert('Error: Username already in use')
                      } else {
                        updateStudent({
                          variables: {
                            id: student.id,
                            input,
                          },
                          refetchQueries: [
                            {
                              query: GET_STUDENT,
                              variables: { id: student.id },
                            },
                            {
                              query: GET_STUDENTS,
                            },
                          ],
                        })
                        this.setState({
                          studentChanged: false,
                        })
                      }
                    }}
                  >
                    <Flex justify="space-evenly">
                      <Flex
                        grow={2}
                        m="20px"
                        style={{ maxWidth: '300px' }}
                        column
                      >
                        <TextField
                          required
                          name="firstName"
                          label="First Name"
                          value={student.firstName}
                          className={this.styles.textField}
                          fullWidth
                          onChange={this.handleChange}
                        />
                        <br />
                        <TextField
                          name="middleName"
                          label="Middle Name"
                          value={student.middleName || ''}
                          className={this.styles.textField}
                          fullWidth
                          onChange={this.handleChange}
                        />
                        <br />
                        <TextField
                          required
                          name="lastName"
                          label="Last Name"
                          value={student.lastName}
                          className={this.styles.textField}
                          fullWidth
                          onChange={this.handleChange}
                        />
                        <br />
                        <TextField
                          error={studentValidation.username.error}
                          name="username"
                          label="Username"
                          value={student.username}
                          className={this.styles.textField}
                          fullWidth
                          onChange={this.handleChange}
                          helperText={studentValidation.username.message}
                        />
                        <br />
                        <TextField
                          name="password"
                          label="Password"
                          value={student.password}
                          className={this.styles.textField}
                          fullWidth
                          onChange={this.handleChange}
                        />
                        <br />
                        <DatePicker
                          name="birthdate"
                          label="Birthdate"
                          value={student.birthdate}
                          onChange={this.handleChange}
                        />
                        <br />
                        <FormControl
                          component="fieldset"
                          className={this.styles.formControl}
                        >
                          <FormLabel component="legend">Gender</FormLabel>
                          <RadioGroup
                            aria-label="gender"
                            name="gender"
                            className={this.styles.group}
                            value={student.gender}
                            onChange={this.handleChange}
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                            }}
                          >
                            <FormControlLabel
                              value="MALE"
                              control={<Radio color="primary" />}
                              label="Male"
                            />
                            <FormControlLabel
                              value="FEMALE"
                              control={<Radio color="secondary" />}
                              label="Female"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Flex>
                      <Flex
                        grow={2}
                        m="20px"
                        style={{ maxWidth: '300px' }}
                        column
                      >
                        <TextField
                          required
                          name="enrollmentStatus"
                          label="Enrollment Status"
                          value={student.enrollmentStatus}
                          className={this.styles.textField}
                          select
                          onChange={this.handleChange}
                          SelectProps={{
                            MenuProps: {
                              className: this.styles.menu,
                            },
                          }}
                          InputLabelProps={{
                            shrink:
                              !!student.enrollmentStatus &&
                              student.enrollmentStatus !== undefined,
                          }}
                        >
                          {[
                            'PROSPECT',
                            'ACTIVE',
                            'INACTIVE',
                            'GRADUATED',
                            'CLOSED',
                          ].map(option => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </TextField>
                        <br />
                        <Flex>
                          <GradeSelector
                            className={this.styles.textField}
                            value={student.grade}
                            onChange={this.handleChange}
                            SelectProps={{
                              MenuProps: {
                                className: this.styles.menu,
                              },
                            }}
                            style={{
                              minWidth: '75px',
                              paddingRight: '15px',
                            }}
                          />
                          <TextField
                            name="school"
                            label="School"
                            value={student.school}
                            className={this.styles.textField}
                            fullWidth
                            onChange={this.handleChange}
                          />
                        </Flex>
                        <br />
                        <TextField
                          name="tShirtSize"
                          label="T-Shirt Size"
                          className={this.styles.textField}
                          select
                          value={student.tShirtSize || ''}
                          onChange={this.handleChange}
                          SelectProps={{
                            MenuProps: {
                              className: this.styles.menu,
                            },
                          }}
                          InputLabelProps={{
                            shrink:
                              !!student.tShirtSize &&
                              student.tShirtSize !== undefined,
                          }}
                        >
                          <MenuItem key={'selectTSSNull'} value={'N/A'}>
                            N/A
                          </MenuItem>
                          {[
                            'Youth Small',
                            'Youth Medium',
                            'Youth Large',
                            'Adult Small',
                            'Adult Medium',
                            'Adult Large',
                          ].map(option => (
                            <MenuItem
                              key={option}
                              value={option.toUpperCase().replace(/ /g, '_')}
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </TextField>
                        <br />
                        <br />
                        <FormControl className={this.styles.textField}>
                          <InputLabel
                            error={studentValidation.phoneNumber.error}
                            shrink
                          >
                            Phone #
                          </InputLabel>
                          <Input
                            disabled={!student.over13}
                            value={student.phoneNumber}
                            onChange={this.handleChange}
                            name="phoneNumber"
                            inputComponent={PhoneNumberInput}
                            error={studentValidation.phoneNumber.error}
                          />
                          {studentValidation.phoneNumber.error && (
                            <FormHelperText error>
                              {studentValidation.phoneNumber.message}
                            </FormHelperText>
                          )}
                        </FormControl>
                        <br />
                        <TextField
                          error={studentValidation.email.error}
                          name="email"
                          label="Email"
                          value={student.email}
                          className={this.styles.textField}
                          fullWidth
                          disabled={!student.over13}
                          onChange={this.handleChange}
                          helperText={studentValidation.email.message}
                        />
                        <br />
                        <Text variant="caption">
                          Adding a custom phone number or email for Students is
                          optional and only available if the student is 13 or
                          older
                        </Text>
                      </Flex>
                    </Flex>
                    <Flex direction={'row-reverse'} align={'center'}>
                      <Button
                        disabled={!studentChanged}
                        aria-label="Submit"
                        type="submit"
                      >
                        Save Changes
                      </Button>
                      <Text style={{ marginRight: '10px' }}>
                        Make sure to verify all information before hitting save!
                      </Text>
                    </Flex>
                  </form>
                </Flex>
              )}
            </Composer>
          )}

          {studentTabValue === 1 && (
            <Flex
              column
              style={{
                align: 'center',
                flexGrow: 1,
              }}
            >
              <Composer
                components={[
                  <Mutation mutation={UPDATE_FAMILY_MEMBER}>
                    {function() {
                      return ''
                    }}
                  </Mutation>,
                  <Mutation mutation={UPDATE_FAMILY_ACCOUNT}>
                    {function() {
                      return ''
                    }}
                  </Mutation>,
                ]}
              >
                {([UFMmutation, UFAmutation]) => (
                  <form
                    id="editFamilyMemberForm"
                    className={this.styles.container}
                    onSubmit={e => {
                      e.preventDefault()
                      if (
                        familyMembers.reduce((accumulator, currentValue) => {
                          return (
                            accumulator &&
                            (currentValue.phoneNumber.length === 0 ||
                              currentValue.phoneNumber.length >= 10)
                          )
                        })
                      ) {
                        this.handleFamilyMemberSubmission(
                          UFMmutation,
                          UFAmutation
                        )
                      } else {
                        alert(
                          'At least one phone number has an improper length'
                        )
                      }
                    }}
                  >
                    {[...familyAccount.familyMembers]
                      .sort((a, b) => {
                        if (
                          a.id === student.familyAccount.primaryFamilyMember.id
                        )
                          return -1
                        if (
                          b.id === student.familyAccount.primaryFamilyMember.id
                        )
                          return 1
                        return 0
                      })
                      .map((familyMember, index) => {
                        const isPrimary =
                          familyMember.id === primaryFamilyMember.id
                        return (
                          <div key={`${familyMember}_${index}`}>
                            <Flex justify="space-evenly">
                              <Flex
                                grow={2}
                                m="20px"
                                style={{ maxWidth: '300px' }}
                                column
                              >
                                {isPrimary ? (
                                  <Button aria-label="MakePrimary">
                                    Primary Guardian
                                  </Button>
                                ) : (
                                  <Button
                                    color="default"
                                    aria-label="MakePrimary"
                                    onClick={() =>
                                      this.setAsPrimary(familyMember)
                                    }
                                  >
                                    Set As Primary Guardian
                                  </Button>
                                )}
                                <br />
                                <TextField
                                  required
                                  name="firstName"
                                  label="First Name"
                                  value={familyMember.firstName}
                                  className={this.styles.textField}
                                  fullWidth
                                  onChange={this.handleFamilyMemberChange(
                                    index
                                  )}
                                />
                                <br />
                                <TextField
                                  required
                                  name="lastName"
                                  label="Last Name"
                                  value={familyMember.lastName}
                                  className={this.styles.textField}
                                  fullWidth
                                  onChange={this.handleFamilyMemberChange(
                                    index
                                  )}
                                />
                                <br />
                                <FormControl
                                  component="fieldset"
                                  className={this.styles.formControl}
                                >
                                  <FormLabel component="legend">
                                    Relationship
                                  </FormLabel>
                                  <RadioGroup
                                    aria-label="relationship"
                                    name="relation"
                                    className={this.styles.group}
                                    value={familyMember.relation}
                                    onChange={this.handleFamilyMemberChange(
                                      index
                                    )}
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      flexWrap: 'nowrap',
                                    }}
                                  >
                                    <FormControlLabel
                                      value="Father"
                                      control={<Radio color="primary" />}
                                      label="Father"
                                    />
                                    <FormControlLabel
                                      value="Mother"
                                      control={<Radio color="secondary" />}
                                      label="Mother"
                                    />
                                    <FormControlLabel
                                      value="Guardian"
                                      control={<Radio color="default" />}
                                      label="Guardian"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </Flex>
                              <Flex
                                grow={2}
                                m="20px"
                                style={{ maxWidth: '300px' }}
                                column
                              >
                                <TextField
                                  error={guardianValidation.email.error}
                                  name="email"
                                  label="Email"
                                  value={familyMember.email}
                                  className={this.styles.textField}
                                  fullWidth
                                  onChange={this.handleFamilyMemberChange(
                                    index
                                  )}
                                  helperText={guardianValidation.email.message}
                                />
                                <br />
                                <FormControl className={this.styles.textField}>
                                  <InputLabel
                                    error={guardianValidation.phoneNumber.error}
                                    shrink
                                  >
                                    Phone #
                                  </InputLabel>
                                  <Input
                                    value={familyMember.phoneNumber}
                                    onChange={this.handleFamilyMemberChange(
                                      index
                                    )}
                                    name="phoneNumber"
                                    inputComponent={PhoneNumberInput}
                                    error={guardianValidation.phoneNumber.error}
                                  />
                                  {guardianValidation.phoneNumber.error && (
                                    <FormHelperText error>
                                      {guardianValidation.phoneNumber.message}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                                <br />
                                <TextField
                                  name="occupation"
                                  label="Occupation"
                                  value={familyMember.occupation || ''}
                                  className={this.styles.textField}
                                  fullWidth
                                  onChange={this.handleFamilyMemberChange(
                                    index
                                  )}
                                />
                                <br />
                                <TextField
                                  name="educationLevel"
                                  label="Education"
                                  value={familyMember.educationLevel || ''}
                                  className={this.styles.textField}
                                  fullWidth
                                  onChange={this.handleFamilyMemberChange(
                                    index
                                  )}
                                />
                              </Flex>
                            </Flex>
                            <Divider />
                            <br />
                          </div>
                        )
                      })}
                    <Flex direction={'row-reverse'} align={'center'}>
                      <Button
                        disabled={
                          !familyMemberChanged && !primaryFamilyMemberChanged
                        }
                        aria-label="Submit"
                        type="submit"
                      >
                        Save Changes
                      </Button>
                      <Text style={{ marginRight: '10px' }}>
                        Make sure to verify all information before hitting save!
                      </Text>
                    </Flex>
                  </form>
                )}
              </Composer>
            </Flex>
          )}

          {studentTabValue === 2 && (
            <Flex
              column
              style={{
                align: 'center',
                flexGrow: 1,
              }}
            >
              <Composer
                components={[
                  <Mutation mutation={UPDATE_FAMILY_ACCOUNT}>
                    {function() {
                      return ''
                    }}
                  </Mutation>,
                  <Mutation
                    mutation={
                      needsLocationCreation ? CREATE_LOCATION : UPDATE_LOCATION
                    }
                  >
                    {function() {
                      return ''
                    }}
                  </Mutation>,
                ]}
              >
                {([UFAmutation, locationMutation]) => (
                  <form
                    id="editFamilyAccountForm"
                    className={this.styles.container}
                    onSubmit={e => {
                      e.preventDefault()
                      this.handleFamilyAccountSubmission(
                        UFAmutation,
                        locationMutation
                      )
                    }}
                  >
                    <Flex justify="space-evenly">
                      <Flex
                        grow={2}
                        m="20px"
                        style={{ maxWidth: '300px' }}
                        column
                      >
                        <TextField
                          name="status"
                          label="Status"
                          value={familyAccount.status || ''}
                          className={this.styles.textField}
                          select
                          onChange={this.handleFamilyAccountChange}
                          SelectProps={{
                            MenuProps: {
                              className: this.styles.menu,
                            },
                          }}
                          InputLabelProps={{
                            shrink:
                              !!familyAccount.status &&
                              familyAccount.status !== undefined,
                          }}
                        >
                          {['ACTIVE', 'INACTIVE', 'PROSPECT', 'CLOSED'].map(
                            option => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            )
                          )}
                        </TextField>
                        <br />
                        <TextField
                          required={needsLocationCreation}
                          name="street"
                          label="Address"
                          value={location.street}
                          className={this.styles.textField}
                          fullWidth
                          onChange={this.handleLocationChange}
                        />
                        <br />
                        <TextField
                          required={needsLocationCreation}
                          name="city"
                          label="City"
                          value={location.city}
                          className={this.styles.textField}
                          fullWidth
                          onChange={this.handleLocationChange}
                        />
                      </Flex>
                      <Flex
                        grow={2}
                        m="20px"
                        style={{ maxWidth: '300px' }}
                        column
                      >
                        <TextField
                          required={needsLocationCreation}
                          name="zip"
                          label="Zip Code"
                          value={location.zip}
                          className={this.styles.textField}
                          fullWidth
                          inputProps={{
                            maxLength: 5,
                            onKeyDown: numberInputValidate,
                          }}
                          pattern="[0-9]"
                          onChange={this.handleLocationChange}
                        />
                        <br />
                        <TextField
                          required={needsLocationCreation}
                          name="state"
                          label="State/Region"
                          value={location.state || ''}
                          className={this.styles.textField}
                          select
                          onChange={this.handleLocationChange}
                          SelectProps={{
                            MenuProps: {
                              className: this.styles.menu,
                            },
                          }}
                          InputLabelProps={{
                            shrink:
                              !!location.state && location.state !== undefined,
                          }}
                        >
                          {getRegions(actualCountry).map((option, index) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </TextField>
                        <br />
                        <TextField
                          required={needsLocationCreation}
                          name="country"
                          label="Country"
                          value={location.country || ''}
                          className={this.styles.textField}
                          select
                          onChange={this.handleLocationChange}
                          SelectProps={{
                            MenuProps: {
                              className: this.styles.menu,
                            },
                          }}
                          InputLabelProps={{
                            shrink:
                              !!location.country &&
                              location.country !== undefined,
                          }}
                        >
                          {CountryRegionData.map((option, index) => (
                            <MenuItem key={option[0]} value={option[0]}>
                              {option[0]}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Flex>
                    </Flex>
                    <Flex direction={'row-reverse'} align={'center'}>
                      <Button
                        disabled={!familyAccountChanged}
                        aria-label="Submit"
                        type="submit"
                      >
                        Save Changes
                      </Button>
                      <Text style={{ marginRight: '10px' }}>
                        Make sure to verify all information before hitting save!
                      </Text>
                    </Flex>
                  </form>
                )}
              </Composer>
            </Flex>
          )}
        </Flex>
        <SnackbarNotification
          open={snackbar.open}
          handleClose={() => {
            this.setState({
              snackbar: {
                ...snackbar,
                open: false,
              },
            })
            switch (snackbar.willReload) {
              case 0: //do nothing
                break
              case 1: //reload
                window.reload()
                break
              case 2: //redirect
                this.returnToStudentTable()
                break
              case 3: //window.location
                window.location.reload()
                break
              default:
                break
            }
          }}
          message={snackbar.message}
          messageType={snackbar.messageType}
        />
      </Flex>
    )
  }
}

export default withApollo(StudentDetailForm)
