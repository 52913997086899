import React from 'react'
import { Mutation, Query } from 'react-apollo'
import Composer from 'react-composer'
import { CountryRegionData } from 'react-country-region-selector'
import { expansionBarColor } from '../config'
import gql from 'graphql-tag'

import {
  Button,
  DeleteIcon,
  Divider,
  ErrorMessage,
  Flex,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormHelperText,
  IconButton,
  Input,
  InputLabel,
  LoadingMessage,
  MenuItem,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  Text,
  TextField,
} from '../components'

import { PhoneNumberInput, getRegions } from '../utils'

const FamilyDetailForm = props => {
  const {
    actualCountry,
    family,
    familyAccountChanged,
    familyMemberChanged,
    familyTabValue,
    needsLocationCreation,
    primaryFamilyMemberChanged,
    validation,
    // functions
    deleteFamilyMember,
    onChange,
    onFamilyAccountSubmission,
    onFamilyMemberChange,
    onFamilyMemberSubmission,
    onLocationChange,
    onTabChange,
    setAsPrimary,
    isValid,
    onRequestLogin,
  } = props
  const { ardentCenter, status, location } = family
  const { city, country, state, street, zip } = location

  return (
    <Flex
      column
      style={{
        align: 'center',
        flexGrow: 1,
      }}
    >
      <Tabs
        value={familyTabValue}
        onChange={onTabChange}
        indicatorColor="primary"
        textColor="primary"
        centered
        style={{
          paddingBottom: '10px',
        }}
      >
        <Tab label="Family Members" />
        <Tab label="Family Information" />
      </Tabs>

      {familyTabValue === 0 && ( // Family Members Tab
        <Flex
          column
          style={{
            align: 'center',
            flexGrow: 1,
          }}
        >
          <Composer
            components={[
              <Mutation mutation={CREATE_FAMILY_MEMBER}>
                {function() {
                  return ''
                }}
              </Mutation>,
              <Mutation mutation={UPDATE_FAMILY_MEMBER}>
                {function() {
                  return ''
                }}
              </Mutation>,
              <Mutation mutation={UPDATE_FAMILY_ACCOUNT}>
                {function() {
                  return ''
                }}
              </Mutation>,
              <Mutation mutation={ARCHIVE_FAMILY_MEMBER}>
                {function() {
                  return ''
                }}
              </Mutation>,
              <Mutation mutation={UPDATE_STUDENT}>
                {function() {
                  return ''
                }}
              </Mutation>,
            ]}
          >
            {([
              CFMmutation,
              UFMmutation,
              UFAmutation,
              AFMmutation,
              USmutation,
            ]) => (
              <form
                id="editFamilyMemberForm"
                className={styles.container}
                onSubmit={async e => {
                  e.preventDefault()
                  if (
                    (family.primaryFamilyMember.phoneNumber.length >= 10 ||
                      (family.primaryFamilyMember.email &&
                        family.primaryFamilyMember.email.length !== 0)) &&
                    family.familyMembers.reduce((accumulator, currentValue) => {
                      return (
                        accumulator &&
                        (currentValue.phoneNumber.length === 0 ||
                          currentValue.phoneNumber.length >= 10)
                      )
                    })
                  ) {
                    onFamilyMemberSubmission(
                      CFMmutation,
                      UFMmutation,
                      UFAmutation,
                      AFMmutation,
                      USmutation
                    )
                  } else {
                    alert(
                      'Error: The primary family member needs either an email or a phone number.'
                    )
                  }
                }}
              >
                {family.familyMembers.map((familyMember, index) => {
                  const isPrimary =
                    familyMember.id === family.primaryFamilyMember.id
                  const {
                    educationLevel,
                    email,
                    firstName,
                    lastName,
                    preferredLanguage,
                    phoneNumber,
                    relation,
                  } = familyMember
                  return (
                    <div key={`familyMember${index}`}>
                      <Flex justify="space-evenly">
                        <Flex
                          grow={2}
                          m="20px"
                          style={{ maxWidth: '300px' }}
                          column
                        >
                          {isPrimary ? (
                            <Button>Primary Guardian</Button>
                          ) : (
                            <Button
                              color="default"
                              onClick={() => {
                                if (firstName && lastName) {
                                  setAsPrimary(familyMember)
                                } else {
                                  alert(
                                    'A guardian needs a first and last name to be a primary guardian'
                                  )
                                }
                              }}
                            >
                              Set As Primary Guardian
                            </Button>
                          )}
                          <br />
                          <TextField
                            required
                            name="firstName"
                            label="First Name"
                            value={firstName}
                            className={styles.textField}
                            fullWidth
                            onChange={onFamilyMemberChange(index)}
                          />
                          <br />
                          <TextField
                            required
                            name="lastName"
                            label="Last Name"
                            value={lastName}
                            className={styles.textField}
                            fullWidth
                            onChange={onFamilyMemberChange(index)}
                          />
                          <br />
                          <FormControl
                            component="fieldset"
                            className={styles.formControl}
                          >
                            <FormLabel component="legend">
                              Relationship
                            </FormLabel>
                            <RadioGroup
                              aria-label="relationship"
                              name="relation"
                              className={styles.group}
                              value={relation}
                              onChange={onFamilyMemberChange(index)}
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'nowrap',
                              }}
                            >
                              <FormControlLabel
                                value="Father"
                                control={<Radio color="primary" />}
                                label="Father"
                              />
                              <FormControlLabel
                                value="Mother"
                                control={<Radio color="secondary" />}
                                label="Mother"
                              />
                              <FormControlLabel
                                value="Guardian"
                                control={<Radio color="default" />}
                                label="Guardian"
                              />
                            </RadioGroup>
                          </FormControl>
                          <Button
                            onClick={() => onRequestLogin(familyMember.id)}
                          >
                            Log in as this user
                          </Button>
                        </Flex>
                        <Flex
                          grow={2}
                          m="20px"
                          style={{ maxWidth: '300px' }}
                          column
                        >
                          <TextField
                            error={validation.email.error}
                            name="email"
                            label="Email"
                            value={email}
                            className={styles.textField}
                            fullWidth
                            required
                            onChange={onFamilyMemberChange(index)}
                            helperText={validation.email.message}
                          />
                          <br />
                          <FormControl required className={styles.textField}>
                            <InputLabel
                              error={validation.phoneNumber.error}
                              shrink
                            >
                              Phone #
                            </InputLabel>
                            <Input
                              value={phoneNumber}
                              onChange={onFamilyMemberChange(index)}
                              name="phoneNumber"
                              inputComponent={PhoneNumberInput}
                              error={validation.phoneNumber.error}
                            />
                            {validation.phoneNumber.error && (
                              <FormHelperText error>
                                {validation.phoneNumber.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                          <br />
                          <Query query={GET_PREFERRED_LANGUAGE_OPTIONS}>
                            {({ loading, error, data }) => {
                              if (loading) return <LoadingMessage />
                              if (error) return <ErrorMessage error={error} />
                              return (
                                <TextField
                                  name="preferredLanguage"
                                  label="Preferred Language"
                                  value={preferredLanguage}
                                  className={styles.textField}
                                  select
                                  fullWidth
                                  onChange={onFamilyMemberChange(index)}
                                  SelectProps={{
                                    MenuProps: {
                                      className: styles.menu,
                                    },
                                  }}
                                  InputLabelProps={{
                                    shrink: Boolean(preferredLanguage),
                                  }}
                                >
                                  {data.__type.enumValues.map(
                                    ({ name: preferredLanguage }) => (
                                      <MenuItem
                                        key={preferredLanguage}
                                        value={preferredLanguage}
                                      >
                                        {preferredLanguage}
                                      </MenuItem>
                                    )
                                  )}
                                </TextField>
                              )
                            }}
                          </Query>
                          <br />
                          <TextField
                            name="educationLevel"
                            label="Education"
                            value={educationLevel || ''}
                            className={styles.textField}
                            fullWidth
                            onChange={onFamilyMemberChange(index)}
                          />
                        </Flex>
                        <Flex
                          style={{
                            maxHeight: '40px',
                            paddingTop: '20px',
                          }}
                        >
                          <IconButton
                            variant="contained"
                            color="secondary"
                            aria-label="Delete"
                            onClick={() => {
                              if (isPrimary) {
                                alert(
                                  'Error: You can not delete primary guardians. Please change another guardian to the primary if you wish to delete this guardian'
                                )
                              } else {
                                if (
                                  window.confirm(
                                    "Are you sure you want to delete this family member? You can't undo this action."
                                  )
                                ) {
                                  deleteFamilyMember(index)
                                }
                              }
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Flex>
                      </Flex>
                      <Divider />
                      <br />
                    </div>
                  )
                })}
                <Flex direction={'row-reverse'} align={'center'}>
                  <Button
                    disabled={
                      (!familyMemberChanged && !primaryFamilyMemberChanged) ||
                      !isValid()
                    }
                    aria-label="Submit"
                    type="submit"
                  >
                    Save Changes
                  </Button>
                  <Text style={{ marginRight: '10px' }}>
                    Make sure to verify all information before hitting save!
                  </Text>
                </Flex>
              </form>
            )}
          </Composer>
        </Flex>
      )}

      {familyTabValue === 1 && ( // Family Information Tab
        <Flex
          column
          style={{
            align: 'center',
            flexGrow: 1,
          }}
        >
          <Composer
            components={[
              <Mutation mutation={UPDATE_FAMILY_ACCOUNT}>
                {function() {
                  return ''
                }}
              </Mutation>,
              <Mutation
                mutation={
                  needsLocationCreation ? CREATE_LOCATION : UPDATE_LOCATION
                }
              >
                {function() {
                  return ''
                }}
              </Mutation>,
            ]}
          >
            {([UFAmutation, locationMutation]) => (
              <form
                id="editFamilyInfoForm"
                className={styles.container}
                onSubmit={e => {
                  e.preventDefault()
                  onFamilyAccountSubmission(UFAmutation, locationMutation)
                }}
              >
                <Flex justify="space-evenly">
                  <Flex grow={2} m="20px" style={{ maxWidth: '300px' }} column>
                    <Query query={GET_FAMILY_STATUS_OPTIONS}>
                      {({ loading, error, data }) => {
                        if (loading) return <LoadingMessage />
                        if (error) return <ErrorMessage error={error} />
                        return (
                          <TextField
                            name="status"
                            label="Status"
                            value={status}
                            className={styles.textField}
                            select
                            onChange={onChange}
                            SelectProps={{
                              MenuProps: {
                                className: styles.menu,
                              },
                            }}
                            InputLabelProps={{
                              shrink: status !== undefined,
                            }}
                          >
                            {data.__type.enumValues.map(
                              ({ name: statusOption }) => (
                                <MenuItem
                                  key={statusOption}
                                  value={statusOption}
                                >
                                  {statusOption}
                                </MenuItem>
                              )
                            )}
                          </TextField>
                        )
                      }}
                    </Query>
                    <br />
                    <TextField
                      required={needsLocationCreation}
                      name="street"
                      label="Address"
                      value={street}
                      className={styles.textField}
                      fullWidth
                      onChange={onLocationChange}
                    />
                    <br />
                    <TextField
                      required={needsLocationCreation}
                      name="city"
                      label="City"
                      value={city}
                      className={styles.textField}
                      fullWidth
                      onChange={onLocationChange}
                    />
                    <br />
                    <Query
                      query={GET_CENTER_LOCATIONS}
                      variables={{ filter: { isPublished: true } }}
                    >
                      {({ data, loading, error }) => {
                        if (loading) return <LoadingMessage />
                        if (error) return <ErrorMessage error={error} />

                        const ardentLocations = data.centerLocations.map(
                          ({ id, name }) => ({
                            name,
                            value: id,
                          })
                        )
                        return (
                          <TextField
                            name="ardentCenter"
                            label="Ardent Center"
                            value={ardentCenter || ''}
                            className={styles.textField}
                            select
                            onChange={onChange}
                            SelectProps={{
                              MenuProps: {
                                className: styles.menu,
                              },
                            }}
                            InputLabelProps={{
                              shrink:
                                ardentCenter && ardentCenter !== undefined,
                            }}
                          >
                            {ardentLocations.map(option => (
                              <MenuItem key={option.name} value={option.value}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        )
                      }}
                    </Query>
                  </Flex>
                  <Flex grow={2} m="20px" style={{ maxWidth: '300px' }} column>
                    <TextField
                      required={needsLocationCreation}
                      name="zip"
                      label="Zip Code"
                      value={zip}
                      className={styles.textField}
                      fullWidth
                      onChange={onLocationChange}
                    />
                    <br />
                    <TextField
                      required={needsLocationCreation}
                      name="country"
                      label="Country"
                      value={country}
                      className={styles.textField}
                      select
                      onChange={onLocationChange}
                      SelectProps={{
                        MenuProps: {
                          className: styles.menu,
                        },
                      }}
                      InputLabelProps={{
                        shrink: country !== undefined,
                      }}
                    >
                      {CountryRegionData.map((option, index) => (
                        <MenuItem key={option[0]} value={option[0]}>
                          {option[0]}
                        </MenuItem>
                      ))}
                    </TextField>
                    <br />
                    <TextField
                      required={needsLocationCreation}
                      name="state"
                      label="State/Region"
                      value={state}
                      className={styles.textField}
                      select
                      onChange={onLocationChange}
                      SelectProps={{
                        MenuProps: {
                          className: styles.menu,
                        },
                      }}
                      InputLabelProps={{
                        shrink: state !== undefined,
                      }}
                    >
                      {getRegions(actualCountry).map((option, index) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Flex>
                </Flex>
                <Flex direction={'row-reverse'} align={'center'}>
                  <Button
                    disabled={!familyAccountChanged}
                    aria-label="Submit"
                    type="submit"
                  >
                    Save Changes
                  </Button>
                  <Text style={{ marginRight: '10px' }}>
                    Make sure to verify all information before hitting save!
                  </Text>
                </Flex>
              </form>
            )}
          </Composer>
        </Flex>
      )}
    </Flex>
  )
}

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: '5px',
    marginRight: '5px',
    padding: '5px',
    width: '20%',
  },
  menu: {
    width: '20%',
  },
  expSummary: {
    backgroundColor: expansionBarColor,
  },
})

const ARCHIVE_FAMILY_MEMBER = gql`
  mutation archiveFamilyMember($id: ID!) {
    archiveFamilyMember(id: $id) {
      id
    }
  }
`
const CREATE_FAMILY_MEMBER = gql`
  mutation createFamilyMember($input: CreateFamilyMemberInput!) {
    createFamilyMember(input: $input) {
      id
    }
  }
`
const CREATE_LOCATION = gql`
  mutation createLocation($input: CreateLocationInput!) {
    createLocation(input: $input) {
      id
    }
  }
`
const GET_CENTER_LOCATIONS = gql`
  query getCenterLocations($filter: Filters) {
    centerLocations(filter: $filter) {
      id
      name
    }
  }
`
const GET_PREFERRED_LANGUAGE_OPTIONS = gql`
  query getPreferredLanguageOptions {
    __type(name: "LanguagePreference") {
      enumValues {
        name
      }
    }
  }
`

const GET_FAMILY_STATUS_OPTIONS = gql`
  query getFamilyStatusOptions {
    __type(name: "FamilyStatus") {
      enumValues {
        name
      }
    }
  }
`

const UPDATE_FAMILY_ACCOUNT = gql`
  mutation updateFamilyAccount($id: ID!, $input: UpdateFamilyAccountInput!) {
    updateFamilyAccount(id: $id, input: $input) {
      id
    }
  }
`
const UPDATE_FAMILY_MEMBER = gql`
  mutation updateFamilyMember($id: ID!, $input: UpdateFamilyMemberInput!) {
    updateFamilyMember(id: $id, input: $input) {
      id
    }
  }
`

const UPDATE_LOCATION = gql`
  mutation updateLocation($id: ID!, $input: UpdateLocationInput!) {
    updateLocation(id: $id, input: $input) {
      id
    }
  }
`
const UPDATE_STUDENT = gql`
  mutation updateStudent($id: ID!, $input: UpdateStudentInput!) {
    updateStudent(id: $id, input: $input) {
      id
    }
  }
`

export default FamilyDetailForm
