import gql from 'graphql-tag'

export const GET_CLASSROOMS = gql`
  query getClassrooms($filter: Filters!) {
    classrooms(filter: $filter) {
      id
      code
    }
  }
`

export const GET_LOCATIONS = gql`
  query getLocations {
    centerLocations {
      id
      name
    }
  }
`

export const GET_LESSONS = gql`
  query classroom($id: ID!) {
    classroom(id: $id) {
      id
      lessons(filter: { lessonPlan: { lessonPlanType: "INSTRUCTION" } }) {
        id
        startOn
        lessonPlan {
          id
          order
          title
        }
        attendees {
          id
          status
          note
          timeAdjustment
          isMakeup
          student {
            id
            firstName
            lastName
            imageUrl
            image
            gender
          }
        }
      }
    }
  }
`

export const GET_ATTENDEE = gql`
  query attendee($id: ID!) {
    attendee(id: $id) {
      id
      status
      note
      timeAdjustment
      student {
        id
        imageUrl
        image
        firstName
        lastName
        grade
        school
        gender
      }
    }
  }
`

export const REPAIR_ATTENDEE = gql`
  mutation repairAttendanceRecord($id: String!) {
    createAttendeeRecords(classroomId: $id) {
      success
      errorMessage
    }
  }
`

export const UPDATE_ATTENDEE = gql`
  mutation updateAttendee($id: ID!, $input: UpdateAttendeeInput!) {
    updateAttendee(id: $id, input: $input) {
      id
      status
    }
  }
`

export const UPDATE_STUDENT = gql`
  mutation updateStudent($id: ID!, $input: UpdateStudentInput!) {
    updateStudent(id: $id, input: $input) {
      id
    }
  }
`

export const GET_STATUS_OPTIONS = gql`
  query status {
    __type(name: "attendeeStatus") {
      enumValues {
        name
      }
    }
  }
`

export const GET_CENTER_LOCATION_ID = gql`
  query getCenterLocationID($filter: Filters!) {
    centerLocations(filter: $filter) {
      id
    }
  }
`

export const UPDATE_ATTENDANCE_ISSUE = gql`
  mutation updateAttendanceIssue($id: ID!, $input: UpdateIssueInput!) {
    updateIssue(id: $id, input: $input) {
      id
    }
  }
`

export const ARCHIVE_ATTENDANCE_ISSUE = gql`
  mutation archiveAttendanceIssue($id: ID!) {
    archiveIssue(id: $id) {
      id
    }
  }
`
