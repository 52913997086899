import React from 'react'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '../../components'

const EnrollmentHistory = props =>
  props.enrollments.length > 0 ? (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            {[
              'Status',
              'Location',
              'Quarter',
              'Year',
              'Course Code',
              'Course Name',
            ].map(cell => (
              <TableCell>{cell}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.enrollments.map(enrollment => {
            const { classroom } = enrollment
            return (
              <TableRow
                hover
                key={enrollment.id}
                onClick={() => props.onClick(classroom.id)}
              >
                <TableCell>
                  {enrollment.classroom.open ? (
                    <span style={{ color: '#27AE60' }}>{'Enrolled'}</span>
                  ) : (
                    <span style={{ color: '#00ADEF' }}>{'Completed'}</span>
                  )}
                </TableCell>
                <TableCell>{classroom.centerLocation.name}</TableCell>
                <TableCell>{classroom.quarter}</TableCell>
                <TableCell>{classroom.year}</TableCell>
                <TableCell>{classroom.course.code}</TableCell>
                <TableCell>{classroom.course.title}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </div>
  ) : (
    <div>
      <p variant="h4">There are no enrollments asscociated with this student</p>
    </div>
  )

export default EnrollmentHistory
