import React from 'react'

import withStyles from './Styles'

import { AppBar, Box, Toolbar, Typography } from '@mui/material'

import { version } from '../../package.json'

import AccountMenu from './AccountMenu'
import { debug } from '../config'

const TopBar = props => {
  const session = JSON.parse(localStorage.getItem('ardentSession'))
  if (!session) return null

  const { user } = session
  return (
    <Box>
      <AppBar position="static">
        <Toolbar variant="dense">
          <Typography align="left" variant="h6" flexGrow="1">
            {debug
              ? `Ardent Office Test Server v${version}`
              : `Ardent Office v${version}`}
          </Typography>
          {user && (
            <Box>
              <AccountMenu employee={user} />
            </Box>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  )
}

const styles = {
  root: {
    flexGrow: 1,
  },
}

export default withStyles(styles)(TopBar)
