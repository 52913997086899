import React, { Component } from 'react'
import { Flex, Button } from '../../components'

class GradeButtons extends Component {
  render() {
    const LIGHT_BLUE = '#bbdefb'
    const { disabled, edited, onReset, onSubmit, onRefetch } = this.props
    return (
      <Flex column grow={1}>
        <Button
          aria-label="sync"
          disabled={disabled || !edited}
          onClick={() => onSubmit()}
          style={{
            backgroundColor: disabled || edited ? null : LIGHT_BLUE,
            marginBottom: 10,
            marginTop: 10,
          }}
        >
          Submit
        </Button>
        <Button
          disabled={disabled || !edited}
          onClick={onReset}
          aria-label="resetOption"
          style={{
            backgroundColor: disabled || edited ? null : LIGHT_BLUE,
            marginBottom: 10,
            marginTop: 10,
          }}
        >
          Reset
        </Button>
        <Button
          disabled={disabled}
          onClick={onRefetch}
          aria-label="refreshOption"
          style={{
            backgroundColor: disabled ? LIGHT_BLUE : null,
            marginBottom: 10,
            marginTop: 10,
          }}
        >
          Refresh
        </Button>
      </Flex>
    )
  }
}

export default GradeButtons
