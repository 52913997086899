import React from 'react'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  MenuItem,
  Typography,
} from '@mui/material'

const SESSION_OPTIONS = [1, 2, 4, 8, 10]

const AddTutorSessionDialog = props => {
  const {
    open,
    student,
    classroom,
    sessions,
    handleUpdateSessions,
    handleClose,
    handleSubmit,
  } = props

  const { courseCode, courseTitle } = classroom
  const studentName = `${student.firstName}  ${student.lastName}`
  const classroomTitle = `${courseCode} - ${courseTitle}`
  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'sm'}>
      <DialogTitle>
        <Box sx={{ flexDirection: 'column' }}>
          <Typography style={{ marginBottom: '1em' }}>
            Creating Tutoring Sessions for {studentName}
          </Typography>
        </Box>
        <Divider />
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <FormControl component="fieldset" style={{ marginBottom: '1em' }}>
            <FormLabel component="legend">Course:</FormLabel>
            <Typography>{classroomTitle}</Typography>
          </FormControl>
          <FormControl component="fieldset">
            <FormLabel component="legend">Number of Sessions</FormLabel>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              {SESSION_OPTIONS.map(option => {
                return (
                  <MenuItem
                    key={option}
                    value={option}
                    selected={sessions === option}
                    onClick={e =>
                      handleUpdateSessions({
                        name: 'sessions',
                        value: e.target.value,
                      })
                    }
                  >
                    {option}
                  </MenuItem>
                )
              })}
            </Box>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={async () => handleSubmit()}>
          Create Session
        </Button>
        <Button variant="contained" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddTutorSessionDialog
