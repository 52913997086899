import React, { Component } from 'react'
import { Query } from 'react-apollo'
import { Redirect } from 'react-router-dom'
import MakeupTable from './MakeupTable'
import MakeupSidebar from './MakeupSidebar'

import {
  Button,
  ErrorMessage,
  Flex,
  LoadingMessage,
  Menu,
  MenuItem,
  SettingsIcon,
  Tooltip,
} from '../../components'

import { GET_MAKEUPS } from './queries'

import { formatDayTimeRange } from '../../utils/datetime'
class MakeupTableView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      redirect: false,
      sidebar: true,
      showFilters: true,
      menuAnchor: null,
      sorted: [
        {
          id: 'fromLesson',
          desc: false,
        },
      ],
      filtered: [{ id: 'status', value: 'SCHEDULED' }],
      checkedIds: [],
    }
  }

  toggleSidebar = () => {
    this.setState(state => ({
      sidebar: !state.sidebar,
      menuAnchor: null,
    }))
  }

  toggleFilters = () => {
    this.setState(state => ({
      showFilters: !state.showFilters,
      menuAnchor: null,
    }))
  }

  onFilteredChange = filtered => {
    this.setState({
      filtered,
    })
  }

  menuOpen = event => {
    this.setState({
      menuAnchor: event.currentTarget,
    })
  }

  menuClose = () => {
    this.setState({
      menuAnchor: null,
    })
  }

  rowClick = rowInfo => {
    rowInfo &&
      this.setState({
        redirect: true,
        redirectTo: `/makeup/${rowInfo.original.id}`,
      })
  }

  menuButton = () => {
    const { menuAnchor } = this.state
    return (
      <Flex p="5px">
        <Flex p="5px">
          <Tooltip>
            <Button color="default" aria-label="Menu" onClick={this.menuOpen}>
              <SettingsIcon />
            </Button>
          </Tooltip>
        </Flex>
        <Menu
          id="simple-menu"
          anchorEl={menuAnchor}
          open={Boolean(menuAnchor)}
          onClose={this.menuClose}
        >
          <MenuItem onClick={this.toggleFilters}>Toggle Filters</MenuItem>
        </Menu>
      </Flex>
    )
  }

  findCurrentMakeupDateRange = () => {
    let currentYear = new Date().getFullYear()
    let lastYear = currentYear - 1
    let nextYear = currentYear + 1
    let currentMonth = new Date().getMonth() + 1
    return 1 <= currentMonth && currentMonth <= 2
      ? [new Date('Nov 25 ' + lastYear), new Date('Mar 31 ' + currentYear)]
      : 3 <= currentMonth && currentMonth <= 5
      ? [new Date('Feb 20 ' + currentYear), new Date('May 31 ' + currentYear)]
      : 6 <= currentMonth && currentMonth <= 7
      ? [new Date('Jun 16 ' + currentYear), new Date('Aug 31 ' + currentYear)]
      : currentMonth === 8
      ? [new Date('Aug 1 ' + currentYear), new Date('Sep 30 ' + currentYear)]
      : 9 <= currentMonth && currentMonth <= 10
      ? [new Date('Sep 1 ' + currentYear), new Date('Nov 30 ' + currentYear)]
      : currentMonth === 11
      ? [new Date('Sep 1 ' + currentYear), new Date('Dec 31 ' + currentYear)]
      : currentMonth === 12
      ? [new Date('Nov 25 ' + currentYear), new Date('Mar 1 ' + nextYear)]
      : []
  }

  setCheckedId = ids => {
    this.setState({
      checkedIds: ids,
    })
  }

  render() {
    const {
      checkedIds,
      filtered,
      redirect,
      redirectTo,
      showFilters,
      sidebar,
      sorted,
    } = this.state
    if (redirect) {
      return <Redirect push to={redirectTo} />
    }

    const currentMakeupsRange = this.findCurrentMakeupDateRange()
    const rangeStartDate = currentMakeupsRange[0]
    const rangeEndDate = currentMakeupsRange[1]
    const statusFilter = filtered.find(filter => filter.id === 'status').value

    return (
      <Query
        fetchPolicy={'cache-and-network'}
        query={GET_MAKEUPS}
        variables={{
          filter: {
            fromLesson: {
              startOn: {
                $gteDate: rangeStartDate.toISOString(),
              },
              endOn: {
                $lteDate: rangeEndDate.toISOString(),
              },
            },
            status: statusFilter === 'ALL' ? null : statusFilter,
          },
          page: {},
        }}
      >
        {({ loading, error, data }) => {
          if (loading) return <LoadingMessage />
          if (error) {
            return <ErrorMessage error={error} />
          }

          const processedMakeups = data.makeups.map(makeup => {
            const {
              createdBy,
              createdOn,
              fromLesson,
              status,
              student,
              toLesson,
            } = makeup
            const fromDate = new Date(fromLesson.startOn)
            const startDate = toLesson && new Date(toLesson.startOn)
            const endDate = toLesson && new Date(toLesson.endOn)
            return {
              id: makeup.id,
              createdOn: new Date(createdOn),
              status: status,
              firstName: student && student.firstName,
              lastName: student && student.lastName,
              fromDate,
              fromLesson:
                fromLesson &&
                fromLesson.lessonPlan &&
                fromLesson.lessonPlan.order,
              fromClass:
                fromLesson && fromLesson.classroom && fromLesson.classroom.code,
              toDate: startDate,
              toTime:
                startDate && endDate && formatDayTimeRange(startDate, endDate),
              toLesson:
                toLesson && toLesson.lessonPlan && toLesson.lessonPlan.order,
              toClass:
                toLesson && toLesson.classroom && toLesson.classroom.code,
              createdBy:
                createdBy && `${createdBy.firstName} ${createdBy.lastName}`,
            }
          })

          return (
            <Flex>
              {sidebar && (
                <MakeupSidebar
                  show={sidebar}
                  makeups={processedMakeups}
                  menu={this.menuButton}
                  checkedIds={checkedIds}
                />
              )}
              <Flex pl={sidebar ? '0px' : '20px'} align="left" column grow={1}>
                <MakeupTable
                  makeups={processedMakeups}
                  filterable={showFilters}
                  onRowClick={this.rowClick}
                  sorted={sorted}
                  filtered={filtered}
                  onFilteredChange={this.onFilteredChange}
                  setCheckedId={this.setCheckedId}
                />
              </Flex>
            </Flex>
          )
        }}
      </Query>
    )
  }
}

export default MakeupTableView
